import Avatar from '@/newComponents/Avatar';
import Button from '@/newComponents/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import Rating from 'react-rating';

type Props = {
  id: number;
  profileImage: string;
  name: string;
  email: string;
  rating: number;
  role?: string;
};

const UserInfo = ({ id, email, name, profileImage, rating, role }: Props) => {
  return (
    <UserInfoStyled>
      <div className="avatar">
        <Avatar avatar={profileImage} size="md" />
      </div>
      <div className="info">
        <div className="info">
          <div className="title">{name}</div>
          <div className="email">{email}</div>
          <Rating
            emptySymbol={<FontAwesomeIcon icon={['far', 'star']} />}
            fullSymbol={
              <FontAwesomeIcon icon={['fas', 'star']} color="#F1C40F" />
            }
            initialRating={rating}
            readonly
          />
          {role && <Button variant="tag">{role}</Button>}
        </div>
      </div>
    </UserInfoStyled>
  );
};

export default UserInfo;

const UserInfoStyled = styled.div`
  display: flex;
  width: 100%;
  padding: 18px 24px;

  & .avatar {
    margin-right: 18px;
  }
  & .info {
    & .title {
      font-size: 14px;
      margin-bottom: 8px;
    }
    & .email {
      font-size: 12px;
      margin-bottom: 8px;
    }
    & .rating {
    }
  }
`;
