import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Rating from 'react-rating';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMailForward, faMessage } from '@fortawesome/free-solid-svg-icons';
import Checkbox from '@/newComponents/Checkbox';
import { devices } from '@/utils/theme';
import { Info } from './styles';
import Button from '@/newComponents/Button';

export type TenantType = {
  firstName: string;
  lastName: string;
  avatar: string;
  id: number;
  bookingId: number;
  rating: number;
  conversationId?: number;
  totalMessageCount?: number;
};
type ButtonType = {
  action: () => void;
  label: string;
  loading?: boolean;
  isDanger?: boolean;
};

type Props = {
  tenant: TenantType;
  onSelect?: (e: React.ChangeEvent<Element>) => void;
  isChecked?: boolean;
  isReadOnly?: boolean;
  isSmall?: boolean;
  disableViewBtn?: boolean;
  button?: ButtonType | Array<ButtonType> | null;
  tag?: string;
};

const IndividualUser = ({
  tenant,
  onSelect,
  isChecked = false,
  isReadOnly = false,
  isSmall = false,
  disableViewBtn = false,
  button,
  tag,
}: Props) => {
  const navigate = useNavigate();
  const gotoUserProfile = (userId: number) => {
    navigate(`/profile/${userId}/tenant`);
  };

  return (
    <IndividualUserStyled>
      <div className="wrapper">
        {!isReadOnly && (
          <Checkbox
            onChange={e => onSelect && onSelect(e)}
            isChecked={isChecked}
            value={tenant.bookingId}
          />
        )}
        <div className="user-info-container">
          <Avatar src={tenant.avatar} alt={tenant.firstName} />
          <div className="info">
            <Info variant="h2">{`${tenant.firstName} ${tenant.lastName}`}</Info>
            {/* @ts-ignore */}
            <Rating
              emptySymbol={<FontAwesomeIcon icon={['far', 'star']} />}
              fullSymbol={
                <FontAwesomeIcon icon={['fas', 'star']} color="#efe410" />
              }
              initialRating={tenant.rating}
              readonly
            />
            {tenant?.conversationId &&
              tenant?.totalMessageCount &&
              tenant?.totalMessageCount > 0 &&
              !isSmall && (
                <div
                  onClick={() => navigate(`/chats/${tenant?.conversationId}`)}
                  className="messageViewBtn"
                >
                  <FontAwesomeIcon
                    icon={faMessage}
                    color="#fff"
                    strokeWidth={20}
                    stroke="#000"
                  />
                  <span title="Total Chat Count">
                    {tenant?.totalMessageCount}
                  </span>
                </div>
              )}
          </div>
        </div>
      </div>
      {!disableViewBtn && (
        <>
          {!button ? (
            <ViewProfileButton
              radius="round"
              onClick={() => gotoUserProfile(tenant.id)}
            >
              View Profile
            </ViewProfileButton>
          ) : Array.isArray(button) ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: '5px',
              }}
            >
              {button.map((btn: ButtonType) => (
                <ViewProfileButton
                  radius="round"
                  size="sm"
                  onClick={btn.action}
                  isLoading={btn.loading}
                  $isDanger={btn.isDanger}
                >
                  {btn.label}
                </ViewProfileButton>
              ))}
            </div>
          ) : (
            <ViewProfileButton
              radius="round"
              size="sm"
              onClick={button.action}
              isLoading={button.loading}
            >
              {button.label}
            </ViewProfileButton>
          )}
        </>
      )}
    </IndividualUserStyled>
  );
};

export default IndividualUser;

const IndividualUserStyled = styled.div`
  padding: 12px;
  background: #f0f0f0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${devices.laptop} {
    padding: 16px 30px;
  }

  & .wrapper {
    display: flex;
    gap: 16px;

    @media ${devices.laptop} {
      gap: 20px;
    }
  }

  & .user-info-container {
    display: flex;
    gap: 16px;

    @media ${devices.laptop} {
      gap: 20px;
    }
    & .info {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }
  }

  & .messageViewBtn {
    cursor: pointer;
    padding: 5px 5px;
    border-radius: 6px;

    display: inline-block;
    &:hover {
      background: #0002;
    }

    & span {
      margin-left: 5px;
      font-size: 16px;
    }

    & svg {
      height: 18px;
    }
  }
`;

const Avatar = styled.img`
  height: 42px;
  width: 42px;
  border-radius: 50%;
  border: 1px solid #949494;

  @media ${devices.laptop} {
    height: 56px;
    width: 56px;
  }
`;

const ViewProfileButton = styled(Button)<{ $isDanger?: boolean }>`
  font-size: 12px;
  line-height: 25px;
  background-color: ${({ $isDanger }) => ($isDanger ? '#dd5050' : '#00de90')};
`;
