const initialState = {
  loading: {
    acceptBookingLoading: false,
    declineBookingLoading: false,
    cancelBookingLoading: false,
    bookingRequestLoading: false,
    reviewLoading: false,
  },
  dashboard: {},
  loggedInUser: null,
  isAppReady: false,
  myCoords: {
    lat: null,
    lng: null,
  },
  calendars: [],
  currentLocation: null,
  notifications: [],
  notificationPagination: {},
  unReadNotifications: [],
  pagination: {},
  property: {},
  scrapeProperty: {},
  properties: [],
  browseProperties: [],
  propertiesRooms: [],
  inventoryCategories: [],
  policies: [],

  itineraries: [],
  itineraryDetails: {},
  similarProperties: [],
  propertyRules: [],
  booking: {},
  bookings: [],
  bookingRequests: [],
  bookingCompleted: [],
  bookingRequestByProperty: {},
  job: {},
  jobs: [],
  jobsPagination: {
    page: 0,
    limit: 15,
  },
  similarJobs: [],
  proposals: [],
  proposal: {},
  contracts: [],
  activeTrips: [],
  trips: [],
  cards: [],
  chats: [],
  conversations: [],
  marketConversations: [],
  employmentHistories: [],
  rentalHistories: [],
  educations: [],
  bookmarks: [],
  myTenants: [],
  tenantProfile: {},
  transactions: [],
  landlordReportIssues: [],
  myReportIssues: [],
  reportDetails: {},
  myAppointments: [],
  reminders: [],
  marketProducts: [],
  marketProduct: {},
  marketComments: [],
  webbrowseproperties: [],
  webbrowseServices: [],
  searchProfiles: [],
  desired: {},
  features: [],
  categories: [],
  bidding: {},
  allCategorySubCategory: [],
  propertyPagination: {
    limit: 15,
    page: 0,
    search: "",
  },
  activeProductMenu: "",
  authModal: { status: false, type: undefined },
  accountDocuments: [],
};

export default initialState;

export type InitialStoreStateType = typeof initialState;
// navigate({
//   pathname: '/report-issues',
//   //@ts-ignore
//   search: createSearchParams<{
//     propertyId: number;
//   }>({
//     propertyId: propertyId,
//   }).toString(),
// })
