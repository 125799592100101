import { ReturnType } from "./types";

export default {
  getUtilityCategories: (): ReturnType => {
    return {
      method: "get",
      url: "/v1/admin/utility/categories",
    };
  },

  getUtilityProviders: (utilityCategoryId: number): ReturnType => {
    return {
      method: "get",
      url: "/v1/admin/utility-providers",
      params: {
        utilityCategoryId,
      },
    };
  },
};
