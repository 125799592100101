import * as yup from 'yup';

yup.addMethod(yup.string, 'expiryDate', function (errorMessage) {
  return this.test(`test-expiry-date`, errorMessage, function (value) {
    const { path, createError } = this;

    if (
      !value ||
      value?.length < 4 ||
      +value?.substr(0, 2) > 12 ||
      +value?.substr(2, 2) > 26 ||
      +value?.substr(2, 2) < 21
    ) {
      return createError({ path, message: errorMessage });
    }
    return true;
  });
});
const schema = {
  account: yup.object().shape({
    accountType: yup.string().required('Please select account type'),
    country: yup.string().required('Please select country'),
    phone: yup.number().required('Please enter phone number'),
    companyName: yup.string().when('accountType', {
      is: 'company',
      then: yup.string().required('Please enter company name'),
    }),
    companyEmail: yup.string().when('accountType', {
      is: 'company',
      then: yup
        .string()
        .email('Please enter valid email')
        .required('Please enter email'),
    }),
    companyCountry: yup.string().when('accountType', {
      is: 'company',
      then: yup.string().required('Please select company country'),
    }),
    companyPhone: yup.string().when('accountType', {
      is: 'company',
      then: yup.string().required('Please enter company phone'),
    }),
    companyAddress: yup.string().when('accountType', {
      is: 'company',
      then: yup.string().required('Please enter company address'),
    }),
  }),
  role: yup.object().shape({
    role: yup.string().required('Please select one role'),
    RTB: yup
      .string()
      .nullable()
      .when('role', {
        is: 'landlord',
        then: yup.string().required('RTB number is required'),
      }),
  }),
  creditCard: yup.object().shape({
    // cardName: yup.string().required('Please enter card name'),
    cardNumber: yup
      .string()
      .required('Please enter card number')
      .test('len', 'Please enter valid credit card', val => val?.length === 16),

    expiryDate: yup
      .string()
      ['expiryDate']('Please enter valid expiry date')
      .required('Please enter expiry month'),

    cvc: yup
      .string()
      .required('Please enter cvv')
      .test('len', 'Please enter valid cvv', val => !!val && val?.length > 2),
  }),
  features: yup.object().shape({
    features: yup
      .array()
      .min(1, 'Please select features')
      .required('Please select features'),
  }),
  location: yup.object().shape({
    address: yup.string().required('Please select address'),
  }),

  photos: yup.object().shape({
    images: yup
      .array()
      .min(1, 'Please upload photos')
      .required('Please upload photos'),
  }),

  documents: yup.object().shape({
    avatar: yup.mixed().required('Please upload profile picture'),
  }),

  profile: yup.object().shape({
    categories: yup
      .array()
      .min(1, 'Please select categories')
      .required('Please select categories'),
    bio: yup.string().required('Please enter bio'),
    website: yup.string(),
  }),
};
export default schema;
