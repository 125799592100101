import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import MapComponent from '@/newComponents/Map';
import { devices } from '@/utils/theme';
import FilterModel from './FilterModal';
import LoadingPlaceholder from '@shared/web/components/LoadingPlaceholder';
import { Apis, Utils } from '@shared/front';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useRoot } from '@/RootProvider';
import NoData from '@/newComponents/NoData';
import JobCard from '@/newComponents/JobCard';
import { useNavigate } from 'react-router-dom';
import Button from '@/newComponents/Button';
import FilterTag from '@/newComponents/FilterTags';
import SearchFilterInput from '@/newComponents/SearchFilterInput';
import { debounce } from 'lodash';
import MarketSkeleton from '@/containers/MarketPlaces/Skeleton';
import BrowseJobSkeleton from './BrowseJobSkeleton';

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 90px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: 'center';
  gap: 25px;

  @media (${devices.laptop}) {
    flex-direction: row;
  }
`;

const MapWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  @media (${devices.laptop}) {
    height: calc(100vh - 180px);
    width: calc((2 / 5) * 100%);
  }
`;

const SearchFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e0e0e0;
  max-width: 100%;
  @media (${devices.laptop}) {
    height: calc(100vh - 180px);
    max-width: calc(100% - (2 / 5) * 100% - 30px);
  }
`;

const FilterInfoWrapperStyled = styled.div<{ isPublic?: Boolean }>`
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const JobsContainer = styled.div`
  height: calc(100vh - 90px - 100px - 20px);
  width: 100%;
  overflow-y: auto;
  padding: 0px;

  @media (${devices.laptop}) {
    width: calc((3 / 5) * 100%);
    padding: 12px;
  }
`;

const JobsListing = styled.div`
  display: grid;
  width: 100%;

  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 60px 20px;
`;

const ClearAllButton = styled(Button)`
  color: #949494;
  height: auto;
  font-size: 12px;
  line-height: 25px;
  padding: 2px 12px;
  background-color: #ebebeb;
`;

const BrowseJobs = () => {
  const { auth } = useRoot();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const browserServices = useSelector(state => state.webbrowseServices);
  const browserSevicesPagination = useSelector(state => state.pagination);
  const [params, setParams] = useState({
    title: '',
    page: 0,
    dataLength: 1,
    limit: 10,
    sort: '',
    priceType: '',
  });
  const [searchValue, setSearchValue] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalInputValue, setModalInputValue] = useState('');
  const formikRef = useRef();
  const searchRef = useRef();

  const getAllJobs = async () => {
    setLoading(true);
    try {
      await Utils.request(
        Apis.Services.getAllBrowserServiceForWeb({
          params: params,
          action: params.page > 0 ? 'update' : 'set',
        }),
      );
      setLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    getAllJobs();
  }, [params]);
  const fetchMyBookmarks = () => {
    if (auth?.id) {
      Utils.request(Apis.Bookmark.checkBookmark('set'));
    }
  };

  useEffect(() => {
    fetchMyBookmarks();
  }, []);
  const searchJobByTitle = (value: string) => {
    setParams({ ...params, title: value });
  };

  const searchByAddressDebounced = useRef(
    debounce(value => {
      searchJobByTitle(value);
    }, 500),
  ).current;
  console.log('bs', browserServices);
  return (
    <Container>
      <SearchFilterWrapper>
        <SearchFilterInput
          value={searchValue}
          name="title"
          inputType="filter"
          placeholder="Search by Job title"
          onChange={e => {
            setSearchValue(e.target.value);
            searchByAddressDebounced(e.target.value);
          }}
          onSearch={searchJobByTitle}
          onCancel={() => {
            setSearchValue('');
            searchJobByTitle('');
          }}
          filterModal={
            <FilterModel
              initialValues={params}
              isModalOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              onSubmit={values => {
                setParams({ ...params, ...values });
                setIsModalOpen(false);
              }}
              onReset={() => {
                setParams({
                  title: '',
                  limit: 10,
                  page: 0,
                  dataLength: 1,
                  sort: '',
                  priceType: '',
                });
              }}
              formikRef={formikRef}
            />
          }
          onFilter={() => setIsModalOpen(true)}
        />
        <FilterInfoWrapperStyled>
          <FilterInfo
            params={params}
            setParams={setParams}
            formikRef={formikRef}
            searchRef={searchRef}
          />
        </FilterInfoWrapperStyled>
      </SearchFilterWrapper>

      <Content>
        <JobsContainer id="scrollableDiv">
          {loading && params?.page === 0 && <BrowseJobSkeleton />}

          {!loading && params?.page.length === 0 ? (
            <BrowseJobSkeleton />
          ) : (
            <InfiniteScroll
              dataLength={params.dataLength * 10}
              loader={<h4></h4>}
              style={{ overflow: 'hidden', paddingBottom: '150px' }}
              next={() => {
                setParams({
                  ...params,
                  page: params.page + 1,
                  dataLength: params.dataLength + 1,
                });
              }}
              hasMore={browserSevicesPagination?.lastPage >= params.page}
              scrollableTarget="scrollableDiv"
            >
              <JobsListing>
                {browserServices?.map(job => (
                  <JobCard
                    title={job.title}
                    rate={job.price}
                    address={job.address}
                    description={job.desc}
                    tags={job.categories}
                    onClick={() => navigate(`/jobs/${job.id}`)}
                    priceType={job?.priceType}
                  />
                ))}
              </JobsListing>
            </InfiniteScroll>
          )}
          {loading && params?.page > 0 && <BrowseJobSkeleton />}
        </JobsContainer>
        <MapWrapper>
          <MapComponent coordinates={browserServices || []} />
        </MapWrapper>
      </Content>
    </Container>
  );
};

export default BrowseJobs;

const FilterInfo = ({ params, setParams, formikRef, searchRef }: any) => {
  const convertObjectToArray = params => {
    const result = [];

    for (let key in params) {
      if (
        key !== 'limit' &&
        key !== 'title' &&
        key !== 'page' &&
        key !== 'dataLength'
      ) {
        const value = params[key];
        if (value !== null && value !== '') {
          result.push({ [key]: params[key] });
        }
      }
    }
    return result;
  };
  const filterInfo = convertObjectToArray(params);

  return (
    <>
      {filterInfo.length > 0 && (
        <ClearAllButton
          variant="gray"
          radius="round"
          onClick={() => {
            setParams({
              title: '',
              limit: 10,
              page: 0,
              dataLength: 1,
            });
            formikRef.current?.resetForm();
            searchRef.current?.setFieldValue('title', '');
          }}
        >
          Clear All
        </ClearAllButton>
      )}

      {filterInfo.length > 0 &&
        filterInfo?.filter(Boolean)?.map(item => {
          const key = Object.keys(item)[0];
          const value = item[key];
          const getSortLabel = (value: string) => {
            if (value === 'price_desc') {
              return 'High to Low';
            } else if (value === 'price_asc') {
              return 'Low to High';
            } else {
              return value;
            }
          };
          return (
            <FilterTag
              filterType={key}
              filterSubType={getSortLabel(value)}
              onClose={() => {
                setParams({
                  ...params,
                  [key]: null,
                });
                formikRef?.current.setFieldValue(key, null);
              }}
            />
          );
        })}
    </>
  );
};
