import React, { useEffect, useState } from 'react';
import { Apis, Socket, Store, Utils } from '@shared/front';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Common } from '@shared/common';
import ListingWrapper from '@/newComponents/ListingLayouts/ListingWrapper';
import ListingHeader from '@/newComponents/ListingLayouts/ListingHeader';
import InfiniteScroll from 'react-infinite-scroll-component';
import Typography from '@/newComponents/Typography';
import NotificationIcon from '../../assets/notification.png';
import { ButtonEndWrapperStyled } from '@/styles/shareableStyle';
import Button from '@/newComponents/Button';
import { useRoot } from '@/RootProvider';
import { request } from '@shared/front/utils';
import Modal from '@/newComponents/Modal';
import { useResponsive } from '@/hooks/useResponsive';

const INITIAL_PARAMS = {
  page: 0,
  limit: 20,
};
const NotificationWrapper = styled.div``;
const NotificationInfoWrapper = styled.div``;
const NotificationItem = styled.div`
  display: flex;
  column-gap: 15px;
`;
const NotificationItems = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;
const NotificationImg = styled.img`
  width: 30px;
  height: 30px;
`;
const NotificationInfo = styled.div`
  display: flex;
  column-gap: 10px;
`;
const NotificationLeft = styled.div``;
const NotificationRight = styled.div``;
const UnRead = styled.div`
  background: red;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-top: 8px;
`;
const Notifications = () => {
  const { auth } = useRoot();
  const { isTablet } = useResponsive();
  const navigate = useNavigate();
  const notifications: any[] = useSelector(Store.Select.notifications);
  const pagination: any = useSelector(Store.Select.notificationPagination);
  const unReadNotification: any = useSelector<any>(
    Store.Select.unReadNotifications,
  );
  const [params, setParams] = useState<any>(INITIAL_PARAMS);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const groupNotifications = Common.groupNotifications(notifications);
  const [markAllRead, setMarkAllRead] = useState({
    status: false,
    loading: false,
  });

  const readNotification = async (id: number) => {
    updateReadNotifications(id);

    await Utils.request(
      Apis.Notification.updateNotification(
        {
          isRead: true,
        },
        id,
      ),
    );
  };
  const fetchMore = () => {
    if (!!hasMore && params?.page < pagination?.lastPage) {
      const updateParams = { ...params, page: params?.page + 1 };
      setParams(updateParams);
      Socket.getUserNotifications(updateParams);
    } else {
      setHasMore(false);
    }
  };

  useEffect(() => {
    Socket.getUserNotifications(INITIAL_PARAMS);
  }, []);

  const updateReadNotifications = (notId: number) => {
    const updatedNotifications = notifications.map((nt: any) => {
      if (notId === nt.id) {
        return {
          ...nt,
          isRead: true,
        };
      }
      return nt;
    });
    Store.store.dispatch(
      Store.Actions.set('notifications', updatedNotifications),
    );
    Store.store.dispatch(
      Store.Actions.set(
        'unReadNotifications',
        unReadNotification.filter((unNot: any) => unNot?.id !== notId),
      ),
    );
  };

  const unReadNotificationsCount = unReadNotification.filter(
    (x: any) => x.authId === auth?.id,
  ).length;

  const onMarkAllAsRead = () => {
    if (unReadNotificationsCount > 0) {
      setMarkAllRead(prev => ({ ...prev, status: true }));
    }
  };

  const handleMarkAsReadAll = async () => {
    try {
      setMarkAllRead(prev => ({ ...prev, loading: true }));
      await request(Apis.Notification.readAllNotifications([]));
      Store.store.dispatch(Store.Actions.set('unReadNotifications', []));
      const updateParams = { ...params, page: 0 };
      setParams(updateParams);
      Socket.getUserNotifications(updateParams);
      onCLoseReadAllModal();
    } catch (error) {
    } finally {
      setMarkAllRead(prev => ({ ...prev, loading: false }));
    }
  };

  const onCLoseReadAllModal = () => {
    setMarkAllRead({
      loading: false,
      status: false,
    });
  };

  return (
    <>
      <ListingWrapper>
        <ListingHeader title={'Notification'} />
        {unReadNotificationsCount > 0 && (
          <ButtonEndWrapperStyled>
            <Button
              size="sm"
              radius="arc"
              onClick={onMarkAllAsRead}
              isLoading={markAllRead.loading}
              isDisabled={markAllRead.loading}
            >
              Read All
            </Button>
          </ButtonEndWrapperStyled>
        )}
        <InfiniteScroll
          dataLength={notifications?.length}
          hasMore={hasMore}
          next={fetchMore}
          loader=""
        >
          <NotificationWrapper>
            {Object.entries(groupNotifications)?.map(([date, items], index) => (
              <NotificationInfoWrapper>
                <Typography
                  variant="p"
                  color="#1677FF"
                  style={{ padding: '10px 0px' }}
                >
                  {moment(date).format('MMM DD, YYYY')}
                </Typography>
                <NotificationItems>
                  {items?.map((item: any) => (
                    <NotificationItem
                      key={item?.id}
                      onClick={() => {
                        if (!item?.isRead) {
                          readNotification(item?.id);
                        }
                        navigate(item?.webRoute);
                      }}
                    >
                      <NotificationImg src={NotificationIcon} />
                      <NotificationInfo>
                        <NotificationLeft>
                          <Typography variant="p" color="#1f1f1f">
                            {item?.title}
                          </Typography>
                          <Typography
                            variant="p"
                            color="#1f1f1f"
                            style={{ fontSize: 12 }}
                          >
                            {moment(item?.createdAt).fromNow()}
                          </Typography>
                        </NotificationLeft>
                        {!item?.isRead && (
                          <NotificationRight>
                            <UnRead />
                          </NotificationRight>
                        )}
                      </NotificationInfo>
                    </NotificationItem>
                  ))}
                </NotificationItems>
              </NotificationInfoWrapper>
            ))}
          </NotificationWrapper>
        </InfiniteScroll>
      </ListingWrapper>
      <Modal
        title="Mark As Read"
        open={markAllRead.status}
        onClose={onCLoseReadAllModal}
        style={{ maxWidth: isTablet ? '500px' : '100%' }}
      >
        <div>
          <Typography style={{ color: '#333' }}>
            Are you sure you want to mark all as read?
          </Typography>
          <Typography style={{ color: '#666' }}>
            If you mark all notifications as read you cannot undo it.
          </Typography>
          <ButtonEndWrapperStyled style={{ marginTop: '30px' }}>
            <Button variant="gray" onClick={onCLoseReadAllModal}>
              Cancel
            </Button>
            <Button
              onClick={handleMarkAsReadAll}
              isLoading={markAllRead.loading}
              isDisabled={markAllRead.loading}
            >
              Mark as read
            </Button>
          </ButtonEndWrapperStyled>
        </div>
      </Modal>
    </>
  );
};

export default Notifications;
