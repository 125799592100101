import React, { useState } from 'react';
import RefProfile from '../RefProfile';
import { Info, InfoLabel } from '../style';
import Contract from '../Contract';
import { FeedbackReviewModal } from '../bookingModals';
import { DescContainer, FeedbackPrompt, StyledButton } from './styles';
import styled from 'styled-components';
import { devices } from '@/utils/theme';
import Rating from 'react-rating';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReferenceImage from '../ReferenceImage';
import { useRoot } from '@/RootProvider';
import moment from 'moment';

type Props = {
  booking: any;
  isLandlord: boolean;
  user: any;
  getBooking: Function;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media ${devices.laptop} {
    gap: 30px;
  }
`;

const SelectedTenentView = ({
  booking,
  isLandlord,
  user,
  getBooking,
}: Props) => {
  const [isReviewModal, setReviewModalVisibility] = useState<boolean | string>(
    false,
  );
  const { auth } = useRoot();

  const reviewForMe = booking?.reviews?.find(
    item => item.revieweeId === user?.id,
  );
  const reviewByMe = booking?.reviews?.find(
    item => item.revieweeId !== user?.id && !item.propertyId,
  );
  const propertyReview = booking?.reviews?.find(
    item => item.revieweeId !== user?.id && item.propertyId,
  );

  const contractEndDateDiffWithNow = moment(
    new Date(booking?.contractEndDate),
  ).diff(Date.now(), 'days');

  const showReferenceSection =
    auth?.id === booking?.tenantId &&
    contractEndDateDiffWithNow <= 30 &&
    booking.status === 'Completed';

  return (
    <Container>
      <RefProfile
        profile={isLandlord ? booking?.tenant : booking?.landlord}
        type={isLandlord ? 'tenant' : 'landlord'}
        isMessage={booking?.currentStep > 0}
        bookingId={booking?.id}
      />
      {booking?.currentStep > 2 && <Contract booking={booking} />}
      {booking.status === 'Completed' && (
        <div>
          <DescContainer>
            <InfoLabel variant="p">Payment Method</InfoLabel>
            <Info variant="p">{booking?.contract?.paymentMood}</Info>
          </DescContainer>
          <DescContainer>
            <InfoLabel variant="p">Contract Type</InfoLabel>
            <Info variant="p">
              {booking?.contract?.isSystemGenerate
                ? 'System Generated'
                : 'Manual Contract'}
            </Info>
          </DescContainer>
          <DescContainer>
            <InfoLabel variant="p">
              Your feedback to {user?.role === 'tenant' ? 'Landlord' : 'Tenant'}
            </InfoLabel>
            {!reviewByMe ? (
              <FeedbackPrompt>
                <Info variant="p">
                  You have not provided your feedback to the{' '}
                  {user?.role === 'tenant' ? 'Landlord' : 'Tenant'} yet.
                </Info>
                <StyledButton
                  variant="gray"
                  radius="round"
                  onClick={() =>
                    setReviewModalVisibility(
                      user?.role === 'tenant' ? 'landlord' : 'tenant',
                    )
                  }
                >
                  Give feedback
                </StyledButton>
              </FeedbackPrompt>
            ) : (
              <>
                {/* <Rate value={reviewByMe.rating} disabled /> */}
                <Rating
                  emptySymbol={<FontAwesomeIcon icon={['far', 'star']} />}
                  fullSymbol={
                    <FontAwesomeIcon icon={['fas', 'star']} color="#efe410" />
                  }
                  initialRating={reviewByMe.rating}
                  readonly
                />
                <Info variant="p">{reviewByMe.review}</Info>
              </>
            )}
          </DescContainer>
          <DescContainer>
            <InfoLabel variant="p">
              {user?.role === 'tenant' ? 'Your' : 'Tenant'} review for property
            </InfoLabel>
            {!propertyReview ? (
              <FeedbackPrompt>
                <Info variant="p">
                  {user?.role === 'tenant' ? 'You have' : 'Tenant has'} not
                  provided review to the property yet.
                </Info>
                {user?.role === 'tenant' && (
                  <StyledButton
                    variant="gray"
                    radius="round"
                    onClick={() => setReviewModalVisibility('property')}
                  >
                    Give feedback
                  </StyledButton>
                )}
              </FeedbackPrompt>
            ) : (
              <div>
                {/* <Rate value={propertyReview.rating} disabled /> */}
                <Rating
                  emptySymbol={<FontAwesomeIcon icon={['far', 'star']} />}
                  fullSymbol={
                    <FontAwesomeIcon icon={['fas', 'star']} color="#efe410" />
                  }
                  initialRating={propertyReview.rating}
                  readonly
                />
                <Info variant="p">{propertyReview.review}</Info>
              </div>
            )}
          </DescContainer>
          <DescContainer>
            <InfoLabel variant="p">
              {user?.role === 'tenant' ? 'Landlord' : 'Tenant'} feedback for you
            </InfoLabel>
            {!reviewForMe ? (
              <Info variant="p">
                {user?.role === 'tenant' ? 'Landlord' : 'Tenant'} has not
                provided feedback yet.
              </Info>
            ) : (
              <>
                {/* <Rate value={reviewForMe.rating} disabled /> */}
                <Rating
                  emptySymbol={<FontAwesomeIcon icon={['far', 'star']} />}
                  fullSymbol={
                    <FontAwesomeIcon icon={['fas', 'star']} color="#efe410" />
                  }
                  initialRating={reviewForMe.rating}
                  readonly
                />
                <Info variant="p">{reviewForMe.review}</Info>
              </>
            )}
          </DescContainer>
        </div>
      )}
      <FeedbackReviewModal
        booking={booking}
        isReviewModal={isReviewModal}
        onClose={() => setReviewModalVisibility(false)}
        user={user}
        getBooking={getBooking}
      />
      {showReferenceSection && (
        <ReferenceImage propertyId={booking?.propertyId} booking={booking} />
      )}
    </Container>
  );
};

export default SelectedTenentView;
