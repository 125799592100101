import { RootType, useRoot } from '@/RootProvider';
import Loading from '@/newComponents/Loading';
import Modal from '@/newComponents/Modal';
import NoData from '@/newComponents/NoData';
import Typography from '@/newComponents/Typography';
import s3Uploader from '@/utils/s3Uploader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Socket, Store, Utils } from '@shared/front';
import { Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { ModalFooter } from '../PropertyView/style';
import {
  AppointmentWrapper,
  InputDateTime,
  MessageActionWrapper,
  MessageBox,
  MessageDateAndTime,
  MessageImage,
  MessageImageWrapper,
  MessageInput,
  MessageItem,
  MessageItemWrapper,
  MessageList,
  MessageViewHeader,
  MessageViewWrapper,
  RemoveUploadImage,
  SendMessageActionWrapper,
  SendMessageBox,
  SendMessageButton,
  SendMessageWrapper,
  StyledButton,
  UploadImage,
  UploadImageItem,
  UploadImageWrapper,
  UserImage,
  UserOnlineStatus,
} from './style';
import DefaultUser from '../../assets/defaultuser.png';

interface ChatViewProps {
  currentConversation: any;
  messageLoading: boolean;
  setParams?: any;
  params?: any;
}

const today = moment(new Date()).add(1, 'day').format('YYYY-MM-DDTHH:mm');

const ChatView = ({
  currentConversation,
  messageLoading,
  params,
  setParams,
}: ChatViewProps) => {
  const { auth: user }: RootType = useRoot();
  const chatRef: any = useRef(null);
  const galleryRef: any = useRef(null);
  const sendButtonRef = useRef<HTMLButtonElement>(null);
  const chats: any[] = useSelector(Store.Select.chats);
  const pagination: any = useSelector(Store.Select.pagination);
  const [visible, setVisible] = useState<boolean>(false);
  const [appointmentVisible, setAppointmentVisible] = useState<boolean>(false);
  const [appointmentType, setAppointmentType] = useState<string>('');
  const [currentSelectedChat, setCurrentSelectedChat] = useState<any>(null);
  const [images, setImages] = useState<any[]>([]);
  const [initialValues, setInitialValues] = useState<any>({
    message: '',
  });
  const [appointmentInitialValues, setAppointmentInitialValues] = useState<{
    appointmentDate: string;
  }>({
    appointmentDate: '',
  });
  const [appoinmentDateSelectorType, setAppoinmentDateSelectorType] =
    useState('text');
  const dateTimeRef = useRef<HTMLInputElement>(null);

  const [sendLoading, setSendLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState(true);
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const toUpdateChats: any[] = [];

  useEffect(() => {
    // Create a Moment object for the desired date (1 day from now)
    const newAppointmentDate = moment(new Date())
      .add(1, 'day')
      .format('YYYY-MM-DDTHH:mm');

    // Update the state with appointmentDate as a string
    setAppointmentInitialValues({ appointmentDate: newAppointmentDate });
  }, []);

  const formatChats = chats
    .map((chat, index) => {
      if (chat.chatBy !== user?.role && !chat.isRead) {
        toUpdateChats.push({ ...chat, isDelivered: true, isRead: true });
      }
      const prevChat = chats[index - 1];
      const nextChat = chats[index + 1];

      let isFollowedMessage = false;
      let isLastFollowedMessage = false;
      let isFirstFollowedMessage = false;

      if (nextChat) {
        if (nextChat.chatBy !== chat.chatBy) {
          isFirstFollowedMessage = true;
        } else {
          isFollowedMessage = true;
        }
      }
      if (prevChat) {
        if (prevChat.chatBy !== chat.chatBy) {
          isLastFollowedMessage = true;
        } else {
          isFollowedMessage = true;
        }
      }

      if (!nextChat) {
        isFirstFollowedMessage = true;
      }
      if (!prevChat) {
        isLastFollowedMessage = true;
      }
      let isMe = false;
      if (chat?.chatBy === 'buyer' || chat?.chatBy === 'seller') {
        if (chat?.chatBy === 'buyer') {
          isMe = user?.id === currentConversation?.buyer?.buyerId;
        } else if (chat?.chatBy === 'seller') {
          isMe = user?.id === currentConversation?.seller?.sellerId;
        }
      } else {
        isMe = user?.role === chat?.chatBy;
      }
      return {
        ...chat,
        isChatByMe: isMe,
        isFollowedMessage,
        isFirstFollowedMessage: isFollowedMessage && isFirstFollowedMessage,
        isLastFollowedMessage: isFollowedMessage && isLastFollowedMessage,
      };
    })
    .filter(x => x.conversationId === currentConversation?.id);

  const handleAppointment = (chat: any, type: string) => {
    setVisible(true);
    setAppointmentType(type);
    setCurrentSelectedChat(chat);
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const files = Array.from(e.target.files || []);

    try {
      const uploadedImages = await Promise.all(
        files.map(async file => {
          const key = await s3Uploader(file);
          return key;
        }),
      );
      setImages(images => [...images, ...uploadedImages]);
    } catch (error) {
      console.error('Error uploading files:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleAcceptOrRejectAppointment = async () => {
    try {
      setLoading(true);
      Socket.appointmentAcceptReject({
        chatId: currentSelectedChat?.id,
        isAppointmentAccept: appointmentType,
      });
      await Socket.addChat({
        conversationId: currentConversation?.id,
        message: `${appointmentType} an appointment for the ${
          currentConversation?.propertyAddress
        } on ${moment(currentSelectedChat?.appoinmentDate).format(
          'DD MMM,YYYY',
        )},at ${moment(currentSelectedChat?.appoinmentDate).format('hh:mm A')}`,
      });
      setLoading(false);
      setVisible(false);
    } catch (err) {
      toast.error('Failed');
    }
  };

  const sendMessage = async (values: any) => {
    try {
      const message = !!images?.length
        ? JSON.stringify(images)
        : values?.message;
      if (!message || !message.trim()) {
        return toast.warning('Cannot send empty message.');
      }
      const chatData: any = {
        message: message,
        conversationId: currentConversation.id,
      };
      if (currentConversation?.buyer) {
        chatData.messageBy =
          currentConversation?.buyer?.buyerId === user?.id ? 'buyer' : 'seller';
      }
      await Socket.addChat(chatData);

      setImages([]);
    } catch (err) {
      console.log(err);
    }
  };

  const createAppointmentEvent = async (values: any) => {
    try {
      const date = moment(values.appointmentDate).format('YYYY-MM-DD');
      const time = moment(values.appointmentDate).format('h:mm a');
      const dateTime = `${date} ${moment(time, 'h:mm a').format('HH:mm:ss')}`;
      let chatData: any = {
        conversationId: currentConversation.id,
        appoinmentDate: moment(dateTime),
        appoinmentCreatedBy: user?.role,
      };

      if (currentConversation?.buyer) {
        chatData = {
          ...chatData,
          messageBy:
            currentConversation?.buyer?.buyerId === user?.id
              ? 'buyer'
              : 'seller',
        };
      }
      await Socket.addChat(chatData);
      setAppointmentVisible(false);
    } catch (err) {
      console.log(err);
    }
  };

  const loadMoreItems = () => {
    if (!!hasMore && params?.page < pagination?.lastPage) {
      const updateParams = { ...params, page: params?.page + 1 };
      setHasMore(true);
      setParams(updateParams);
      Socket.getConversationChats({
        conversationId: currentConversation?.id,
        ...updateParams,
      });
    } else {
      setHasMore(false);
    }
  };

  useEffect(() => {
    setHasMore(true);
  }, [chats]);

  useEffect(() => {
    if (Array.isArray(chats) && chats.length > 0) {
      const unReadChats: any[] = getUnreadChatsForUser();
      const chatToUpdate: any[] = unReadChats.map((ch: any) => {
        Store.store.dispatch(
          Store.Actions.update('conversations', (prevData: any) => {
            const updatedPrev = prevData.map((x: typeof prevData[0]) => {
              if (x.id === ch?.conversationId && ch?.id === x.chats[0].id) {
                return {
                  ...x,
                  chats: [{ ...ch, isRead: true }],
                };
              }
              return x;
            });

            return updatedPrev;
          }),
        );
        return {
          ...ch,
          isRead: true,
        };
      });

      if (chatToUpdate.length > 0) {
        Socket.updateChats(chatToUpdate);
      }
    }
  }, [chats]);

  const getUnreadChatsForUser = () => {
    return chats.filter((ch: any) => {
      if (
        !ch.isRead &&
        ch.chatBy !== user?.role &&
        ch.conversationId === currentConversation?.id
      ) {
        return true;
      }
      return false;
    });
  };

  return (
    <>
      <MessageViewWrapper>
        <MessageViewHeader>
          <div style={{ position: 'relative' }}>
            <img
              src={currentConversation?.avatar || DefaultUser}
              alt=""
              style={{
                height: '21px',
                width: '21px',
                borderRadius: '50%',
              }}
            />
            <UserOnlineStatus child active={!!currentConversation?.isActive} />
          </div>

          <Typography variant="h2" size="sm" style={{ fontWeight: 600 }}>
            {!!currentConversation?.buyer &&
            currentConversation?.chats[0]?.chatBy === 'buyer'
              ? currentConversation?.seller?.fullName
              : !!currentConversation?.seller &&
                currentConversation?.chats[0]?.chatBy === 'seller'
              ? currentConversation?.buyer?.fullName
              : currentConversation?.fullName}
          </Typography>
          {currentConversation?.propertyAddress && (
            <Typography variant="p" size="sm">
              ({currentConversation?.propertyAddress})
            </Typography>
          )}
        </MessageViewHeader>
        <MessageList
          id="scrollableDiv"
          style={{
            height: ' calc(100% - 80px)',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column-reverse',
          }}
          ref={chatRef}
        >
          {!messageLoading &&
            !!currentConversation &&
            formatChats.length === 0 && (
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <NoData
                  title={
                    <>
                      <Typography
                        variant="p"
                        style={{
                          textAlign: 'center',
                          fontWeight: '600',
                          marginBottom: '10px',
                        }}
                      >
                        No chats available.
                      </Typography>
                      <Typography
                        variant="p"
                        style={{
                          textAlign: 'center',
                          fontWeight: '400',
                          fontSize: '20px',
                        }}
                      >
                        You can start by saying: Hi!, Hello!, What's up?"
                      </Typography>
                    </>
                  }
                />
              </div>
            )}
          {!!messageLoading ? (
            <>
              <Loading />
            </>
          ) : (
            <InfiniteScroll
              dataLength={chats?.length}
              scrollableTarget="scrollableDiv"
              inverse={true}
              next={loadMoreItems}
              loader={''}
              hasMore={hasMore}
              style={{
                display: 'flex',
                flexDirection: 'column-reverse',
                gap: '16px',
              }}
            >
              {formatChats?.map((chat: any) => {
                const checkJsonOrNot = Utils.isJsonString(chat?.message);
                const message = checkJsonOrNot ? checkJsonOrNot : chat?.message;
                const appointmentStatus = chat?.appointmentStatus;
                return (
                  <MessageItem key={chat?.id}>
                    <MessageItemWrapper active={chat?.isChatByMe}>
                      {typeof message === 'string' ||
                      typeof message === 'number' ? (
                        <>
                          <MessageBox active={chat?.isChatByMe}>
                            <Typography
                              variant="p"
                              color={!!chat?.isChatByMe ? 'white' : 'black'}
                              style={{ whiteSpace: 'pre-wrap' }}
                            >
                              {chat.message}
                            </Typography>
                          </MessageBox>
                        </>
                      ) : !!message?.length ? (
                        <>
                          <MessageImageWrapper>
                            {message?.map((item: any) => (
                              <MessageImage src={item?.url || item} />
                            ))}
                          </MessageImageWrapper>
                        </>
                      ) : appointmentStatus === 'Processing' ||
                        chat?.chatBy === user?.role ? (
                        <>
                          <MessageBox active={chat?.isChatByMe}>
                            <Typography
                              variant="p"
                              color={!!chat?.isChatByMe ? 'white' : 'black'}
                            >
                              {`${
                                chat?.chatBy === user?.role
                                  ? 'I would like to'
                                  : currentConversation?.fullName
                              } schedule an appointment for the ${
                                currentConversation?.propertyAddress
                              } on ${moment(chat?.appoinmentDate).format(
                                'DD MMM,YYYY',
                              )},at ${moment(chat?.appoinmentDate).format(
                                'hh:mm A',
                              )}`}
                            </Typography>
                            {!chat.isChatByMe && (
                              <AppointmentWrapper>
                                <StyledButton
                                  radius="round"
                                  variant="primary"
                                  onClick={() => {
                                    handleAppointment(chat, 'Accepted');
                                  }}
                                >
                                  Accept
                                </StyledButton>
                                <StyledButton
                                  radius="round"
                                  variant="danger"
                                  onClick={() => {
                                    handleAppointment(chat, 'Rejected');
                                  }}
                                >
                                  Reject
                                </StyledButton>
                              </AppointmentWrapper>
                            )}
                          </MessageBox>
                        </>
                      ) : null}
                    </MessageItemWrapper>
                    <MessageItemWrapper active={chat?.isChatByMe}>
                      {!chat.isChatByMe &&
                        chat?.appointmentStatus === 'Processing' && (
                          <MessageDateAndTime variant="p">
                            {moment(chat.createdAt).format(
                              'MMM DD, YYYY @ hh:mm A',
                            )}
                          </MessageDateAndTime>
                        )}
                    </MessageItemWrapper>
                    <MessageItemWrapper active={chat?.isChatByMe}>
                      {chat.isChatByMe &&
                        chat?.appointmentStatus === 'Processing' && (
                          <MessageDateAndTime variant="p">
                            {/* {moment(chat?.createdAt).utc().fromNow(true)} */}
                            {/* {`, `} */}
                            {moment(chat.createdAt).format(
                              'MMM DD, YYYY @ hh:mm A',
                            )}
                          </MessageDateAndTime>
                        )}
                    </MessageItemWrapper>
                  </MessageItem>
                );
              })}
            </InfiniteScroll>
          )}
        </MessageList>
        <SendMessageWrapper>
          <Formik
            validateOnBlur={false}
            initialValues={initialValues}
            onSubmit={async (values, { resetForm }) => {
              // setSendLoading(true);
              await sendMessage(values);
              // setSendLoading(false);
              resetForm();
              chatRef.current.scrollTop = chatRef.current.scrollHeight;
            }}
          >
            {props => {
              return (
                <Form onSubmit={props.handleSubmit}>
                  {!!images?.length && (
                    <UploadImageWrapper>
                      {images?.map((item: any, index: number) => (
                        <UploadImageItem>
                          <UploadImage src={item} />
                          <RemoveUploadImage
                            onClick={() => {
                              const updateImage = images?.filter(
                                (image, i) => i !== index,
                              );
                              setImages(updateImage);
                            }}
                          >
                            <FontAwesomeIcon
                              icon="times"
                              color="white"
                              style={{ fontSize: 10 }}
                            />
                          </RemoveUploadImage>
                        </UploadImageItem>
                      ))}
                    </UploadImageWrapper>
                  )}
                  <MessageActionWrapper>
                    <SendMessageBox>
                      <MessageInput
                        placeholder="Message . . ."
                        value={props.values.message}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e.target.value !== '\n') {
                            props.setFieldValue('message', e.target.value);
                          }
                        }}
                        onKeyPress={(
                          e: React.KeyboardEvent<HTMLButtonElement>,
                        ) => {
                          if (
                            e.key === 'Enter' &&
                            !e.shiftKey &&
                            sendButtonRef?.current
                          ) {
                            sendButtonRef?.current?.click();
                          }
                        }}
                      />
                    </SendMessageBox>
                    <SendMessageActionWrapper>
                      <input
                        ref={galleryRef}
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleFileUpload}
                        multiple
                      />
                      <SendMessageButton
                        type="button"
                        onClick={() => {
                          galleryRef.current.click();
                        }}
                      >
                        <FontAwesomeIcon icon="image" color="white" />
                      </SendMessageButton>
                      <SendMessageButton
                        type="button"
                        onClick={() => {
                          setAppointmentVisible(true);
                        }}
                      >
                        <FontAwesomeIcon icon="plus" color="white" />
                      </SendMessageButton>
                      <SendMessageButton
                        disabled={sendLoading}
                        ref={sendButtonRef}
                      >
                        <FontAwesomeIcon icon="chevron-right" color="white" />
                      </SendMessageButton>
                    </SendMessageActionWrapper>
                  </MessageActionWrapper>
                </Form>
              );
            }}
          </Formik>
        </SendMessageWrapper>
      </MessageViewWrapper>
      {!!visible && (
        <Modal
          isCentered
          size={`sm`}
          open={visible}
          title={`Are you Sure ?`}
          onClose={() => {
            setVisible(false);
            setAppointmentType('');
            setCurrentSelectedChat(null);
          }}
        >
          <Typography variant="p">
            You want to {appointmentType === 'Accepted' ? 'Accept' : 'Reject'}{' '}
            Appointment for {currentConversation?.propertyAddress} on{' '}
            {moment(currentSelectedChat.createdAt).format('YYYY-MM-DD hh:mm A')}
          </Typography>
          <ModalFooter>
            <StyledButton
              radius="round"
              style={{ marginLeft: 'auto', marginRight: 20 }}
              onClick={handleAcceptOrRejectAppointment}
              isLoading={loading}
              isDisabled={loading}
            >
              Yes
            </StyledButton>
            <StyledButton
              radius="round"
              variant="gray"
              onClick={() => {
                setVisible(false);
                setAppointmentType('');
                setCurrentSelectedChat(null);
              }}
            >
              No
            </StyledButton>
          </ModalFooter>
        </Modal>
      )}
      {!!appointmentVisible && (
        <Modal
          size="sm"
          isCentered
          title="Create Appointment"
          open={appointmentVisible}
          onClose={() => {
            setAppointmentVisible(false);
          }}
          closeOnBackdropClick={false}
        >
          <Formik
            initialValues={appointmentInitialValues}
            onSubmit={async (values, { resetForm }) => {
              setLoading(true);
              await createAppointmentEvent(values);
              setLoading(false);
              resetForm();
            }}
            validateOnBlur={false}
            validationSchema={yup.object().shape({
              appointmentDate: yup
                .date()
                .min(
                  new Date(),
                  'Appointment date must be greater than the current date',
                )
                .required('Appointment date and time are required'),
            })}
            enableReinitialize
          >
            {({
              errors,
              setFieldValue,
              handleSubmit,
              values,
            }: FormikProps<typeof appointmentInitialValues>) => {
              console.log({ values });

              return (
                <Form onSubmit={handleSubmit}>
                  <InputDateTime
                    ref={dateTimeRef}
                    type={appoinmentDateSelectorType}
                    onFocus={() => {
                      setAppoinmentDateSelectorType('datetime-local');
                      setTimeout(() => {
                        if (dateTimeRef.current) {
                          dateTimeRef.current.showPicker();
                        }
                      }, 50);
                    }}
                    onBlur={() => {
                      setAppoinmentDateSelectorType('text');
                    }}
                    onClick={() => {
                      if (dateTimeRef.current) {
                        dateTimeRef.current.showPicker();
                      }
                    }}
                    required
                    error={!!errors?.appointmentDate}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue(
                        'appointmentDate',
                        moment(e.target.value).format('YYYY-MM-DDTHH:mm'),
                      );
                    }}
                    format={'yyyy-mm-ddTHH:MM'}
                    placeholder={
                      moment(values.appointmentDate).format(
                        'DD/MM/yyyy, hh:mm A, dddd',
                      ) || 'Select appointment date and time'
                    }
                    value={
                      appoinmentDateSelectorType === 'text'
                        ? moment(values.appointmentDate).format(
                            'DD/MM/yyyy, hh:mm A',
                          )
                        : values.appointmentDate
                    }
                    min={today}
                  />
                  {!!errors?.appointmentDate && (
                    <Typography
                      variant="p"
                      style={{ fontSize: 12, color: 'red' }}
                    >
                      {errors?.appointmentDate}
                    </Typography>
                  )}
                  <ModalFooter>
                    <StyledButton
                      radius="round"
                      style={{ marginLeft: 'auto', marginRight: 20 }}
                      isLoading={loading}
                      isDisabled={loading}
                    >
                      Send
                    </StyledButton>
                    <StyledButton
                      radius="round"
                      variant="gray"
                      onClick={() => {
                        setAppointmentVisible(false);
                      }}
                    >
                      Cancel
                    </StyledButton>
                  </ModalFooter>
                </Form>
              );
            }}
          </Formik>
        </Modal>
      )}
    </>
  );
};

export default ChatView;
