import Button from '@/newComponents/Button';
import Divider from '@/newComponents/Divider';
import ProductLargeCard from '@/newComponents/ProductLargeCard';
import Typography from '@/newComponents/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Apis, Store, Utils } from '@shared/front';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import MyMarketplaceSkeleton from '../Skeleton/MyMarketplaceSkeleton';
import EmptyPage from '../Empty';

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 60px;
  background-color: white;
  z-index: 99;
  background: #fff;
  & .title {
    font-size: 1.6rem;
    font-weight: 500;
  }
  padding: 20px 0px 10px 0px;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 20px;
`;

const MyMarketPlaces = () => {
  const InitialParams: any = {
    page: 0,
    limit: 10,
  };
  const [params, setParams] = useState<any>(InitialParams);
  const navigate = useNavigate();
  const products: any[] = useSelector(Store.Select.marketProducts);
  const [loading, setLoading] = useState(false);

  const getMyMarketProduct = async () => {
    setLoading(true);
    await Utils.request(Apis.Market.getMyMarketProducts('set', params));
    setLoading(false);
  };

  useEffect(() => {
    getMyMarketProduct();
  }, []);

  return (
    <>
      <TitleContainer>
        <Typography className="title" variant="p">
          My Product
        </Typography>
        <Button
          variant="gray"
          radius="round"
          bgColor="red"
          onClick={() => navigate('/mymarketplace/add')}
        >
          <Typography variant={'p'} size={'sm'}>
            <FontAwesomeIcon icon="plus" style={{ marginRight: 5 }} /> Add
            Product
          </Typography>
        </Button>
      </TitleContainer>

      <ProductContainer>
        {loading && <MyMarketplaceSkeleton />}
        {!loading && products?.length === 0 && <EmptyPage />}
        {products?.length > 0 &&
          products?.map((product: any) => {
            return (
              <div>
                <ProductLargeCard
                  address={product?.address}
                  title={product?.title}
                  imageURL={product?.productImage?.[0]?.url}
                  productId={product?.id}
                  tag={{
                    label: product?.status,
                    color: product?.status == 'Active' ? '#00DE90' : '#FF7171',
                  }}
                  createdAt={product?.createdAt}
                />
                <Divider />
              </div>
            );
          })}
      </ProductContainer>
    </>
  );
};

export default MyMarketPlaces;

const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
