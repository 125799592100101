export const DEV_USERS = [
  {
    name: 'Eoin',
    landlord: 'eoin@largum.com',
    tenant: 'eoin+1@largum.com',
    service: 'eoin+2@largum.com',
  },
  {
    name: 'Colin',
    landlord: 'colinjtravers@gmail.com',
    tenant: 'colinjtravers+1@gmail.com',
    service: 'colinjtravers+2@gmail.com',
  },

  {
    name: 'Ananta',
    landlord: 'ianantashrestha@gmail.com',
    tenant: 'ianantashrestha+1@gmail.com',
    service: 'ianantashrestha+2@gmail.com',
  },
  {
    name: 'Shiva',
    landlord: 'shibuchapagain12@gmail.com',
    tenant: 'shibuchapagain12+1@gmail.com',
    service: 'shibuchapagain12+2@gmail.com',
  },
  {
    name: 'Bikram',
    landlord: 'ikramghimire@gmail.com',
    tenant: 'ikramghimire+1@gmail.com',
    service: 'ikramghimire+2@gmail.com',
  },
  {
    name: 'Biju',
    landlord: 'shrebiju06@gmail.com',
    tenant: 'shrebiju06+1@gmail.com',
    service: 'shrebiju06+2@gmail.com',
  },
  {
    name: 'Nakul',
    landlord: 'nayanbudhathoki@gmail.com',
    tenant: 'nayanbudhathoki+1@gmail.com',
    service: 'nayanbudhathoki+2@gmail.com',
  },
  {
    name: 'Sakul',
    landlord: 'sakulbudhathoki977@gmail.com',
    tenant: 'sakulbudhathoki977+1@gmail.com',
    service: 'sakulbudhathoki977+2@gmail.com',
  },
  {
    name: 'Nitish',
    landlord: 'nitishrajak.rj@gmail.com',
    tenant: 'nitishrajak.rj+1@gmail.com',
    service: 'nitishrajak.rj+2@gmail.com',
  },
];
