import React from 'react';
import styled from 'styled-components';
import Typography from '../Typography';
import { devices } from '@/utils/theme';

export interface IStepItem {
  step: number;
  title: string;
  status: 'finish' | 'process' | 'wait';
}

const StepItem = ({ step, title, status }: IStepItem) => {
  return (
    <Container>
      <DotsContainer>
        <Dot $status={status} />
      </DotsContainer>
      <TextContainer $isActive={status === 'process'}>
        <StepCount variant="p">STEP {step}</StepCount>
        <StepTitle variant="p">{title}</StepTitle>
        <StepStatus variant="p">
          {status === 'finish'
            ? 'Completed'
            : status === 'process'
            ? 'Processing'
            : 'Pending'}
        </StepStatus>
      </TextContainer>
    </Container>
  );
};

export default StepItem;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const DotsContainer = styled.div`
  display: flex;
  align-items: center;
  z-index: 2;
`;

export const Dot = styled.div<{ $status: IStepItem['status'] }>`
  height: 9px;
  width: 9px;
  border-radius: 50%;
  background-color: ${({ $status }) =>
    $status === 'finish'
      ? '#00de90'
      : $status === 'process'
      ? '#52c1ff'
      : '#e0e0e0'};
`;

const TextContainer = styled.div<{ $isActive: boolean }>`
  background-color: #f5f5f5;
  padding: 8px;
  border-radius: 5px;
  display: ${({ $isActive }) => ($isActive ? 'block' : 'none')};

  @media ${devices.laptop} {
    padding: 0;
    display: block;
  }
`;

const StepCount = styled(Typography)`
  font-size: 10px;
  line-height: 25px;
  color: #949494;
`;

const StepTitle = styled(Typography)`
  font-size: 12px;
  line-height: 25px;
  text-transform: uppercase;
`;

const StepStatus = styled(StepCount)``;
