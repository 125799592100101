import Input from '@/newComponents/Input';
import styled from 'styled-components';

export const CancelDeletionContainerStyled = styled.div`
  padding: 30px 30px;
  margin: 40px 0px 100px;
  background: ${({ theme }) => theme.colors.materialColors.lightBlue.bg};
  border: 1px solid ${({ theme }) => theme.colors.materialColors.lightBlue.text};
  border-radius: 10px;
`;
