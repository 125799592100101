import * as yup from 'yup';
export const basicInfo = yup.object().shape({
  propertyType: yup.string().required('Select property type.'),
  price: yup.string().required('Enter your price.'),
  targetPrice: yup.string().required('Enter target price.'),
  distance: yup.string().required('Enter commute distance.'),
  time: yup.string().required('Enter commute time.'),
  moveStatus: yup.string().required('Select move in status.'),
  paidType: yup.string().required('Select payment type.'),
});

export const location = yup.object().shape({
  address: yup.string().required('Select address.'),
});

export const features = yup.object().shape({
  amenities: yup
    .array()
    .of(yup.string())
    .min(1, 'Select at least one feature.'),
});
