// import { useResponsive } from '@/hooks/useResponsive';
import Divider from '@/newComponents/Divider';
import FilterWithMenu from '@/newComponents/FilterWithMenu';
import NoData from '@/newComponents/NoData';
import Typography from '@/newComponents/Typography';
import { Apis, Store, Utils } from '@shared/front';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import Listing from './Listing';
import MarketSkeleton from './Skeleton';
// import EmptyPage from '../Empty';
// import { devices } from '@/utils/theme';

const MarketPlace = () => {
  const [loading, setLoading] = useState(false);
  // const { isTablet } = useResponsive();
  const isLessThanThousand = useMediaQuery({ query: '(min-width:1000px)' });
  const [hasMore, setHasMore] = useState(true);

  const [selectedCategory, setSelectedCategory] = useState({
    item: 'ALL',
    subItem: undefined,
  });

  const allCategorySubCategory: any[] = useSelector(
    Store.Select.allCategorySubCategory,
  );

  const products: any[] = useSelector(Store.Select.marketProducts);
  const productPagination: any = useSelector(Store.Select.pagination);

  const [params, setParams] = useState<any>({
    page: 0,
    limit: 10,
    sort: '',
    title: '',
    condition: '',
    categoryId: '',
    subCategoryId: '',
  });

  const getAllMarketProducts = async () => {
    setLoading(true);
    try {
      if (params.page === 0) {
        await Utils.request(Apis.Market.getMarketProducts('set', params));
      } else {
        await Utils.request(Apis.Market.getMarketProducts('update', params));
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllCategory = async () => {
    await Utils.request(Apis.Market.getAllSubCategory());
  };

  useEffect(() => {
    getAllMarketProducts();
  }, [params]);

  useEffect(() => {
    getAllCategory();
  }, []);

  const handleSubCategory = (value: any) => {
    setHasMore(true);
    setParams((prev: any) => ({
      ...prev,
      page: 0,
      categoryId: value?.categoryId,
      subCategoryId: value?.id,
    }));
  };

  const handleBrowserAll = () => {
    setHasMore(true);
    setSelectedCategory({ item: 'All', subItem: undefined });
    setParams(prev => ({
      ...prev,
      categoryId: '',
      subCategoryId: '',
      title: '',
    }));
  };

  return (
    <MarketPlaceWrapper isTablet={isLessThanThousand}>
      <FilterWithMenu
        title="Categories"
        searchPlaceholder="Search a product"
        items={allCategorySubCategory}
        onSelectItem={(value: any) => {
          setSelectedCategory({ item: value?.category, subItem: value?.title });
          handleSubCategory(value);
        }}
        onSelectCategory={(value: any) => {
          setSelectedCategory({ item: value?.category, subItem: undefined });

          handleSubCategory({ categoryId: value?.id, id: undefined });
        }}
        browserAll={handleBrowserAll}
        setParams={setParams}
        params={params}
        hasMore={hasMore}
        setHasMore={setHasMore}
      />

      <div style={{ flex: '1' }}>
        <CategoryTitleWrapper>
          <Typography variant="h4" size="md" fontWeight="extrabold">
            {selectedCategory?.item}
          </Typography>
          <Typography variant="span" size="xsm" fontWeight="thin">
            {selectedCategory?.subItem}
          </Typography>
          <Divider
            style={{
              margin: '10px 0px ',
            }}
          />
        </CategoryTitleWrapper>
        {/* {loading && params?.page === 0 && <MarketSkeleton />}
        {!loading && !products?.length && <EmptyPage />}

        <Listing
          products={products}
          productPagination={productPagination}
          params={params}
          setParams={setParams}
          hasMore={hasMore}
          setHasMore={setHasMore}
        />
        {loading && params?.page > 0 && <MarketSkeleton />}
        {!loading && !products?.length && <NoData />} */}
        {loading && <MarketSkeleton />}
        {!loading && products?.length === 0 && <NoData />}
        {!loading && products?.length > 0 && (
          <Listing
            products={products}
            productPagination={productPagination}
            params={params}
            setParams={setParams}
            hasMore={hasMore}
            setHasMore={setHasMore}
          />
        )}
        {loading && params?.page > 0 && <MarketSkeleton />}
      </div>
    </MarketPlaceWrapper>
  );
};

export default MarketPlace;

const MarketPlaceWrapper = styled.div<{ isTablet: boolean }>`
  margin-top: 30px;
  display: flex;
  flex-direction: ${({ isTablet }) => (!isTablet ? 'column' : 'row')};
  margin-top: ${({ isTablet }) => (!isTablet ? '20px' : '10px')};
  position: relative;
  gap: 0px;

  @media (min-width: 1000px) {
    gap: 40px;
  }
`;
const CategoryTitleWrapper = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: calc(60px + 50px);
  padding-top: 20px;
  background-color: #fff;
  margin-bottom: 30px;
  z-index: 99;

  @media (min-width: 1000px) {
    top: 60px;
  }
`;
