import React, { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';
import Button from './Button';
import { devices } from '../utils/theme';

interface IStepItem {
  key: number;
  label: string;
  children: ReactNode;
  status?: 'completed' | 'active' | 'pending';
}

interface IStepperProps {
  activeStep: number;
  stepItems: IStepItem[];
  style?: CSSProperties;
  className?: string;
  onTabClick?: (data: IStepItem) => void;
  enableTabClick?: boolean;
}

const StepperContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media ${devices.laptop} {
    flex-direction: row;
  }
`;

const StepperTabs = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0 0;

  border-bottom: 1px solid #e0e0e0;

  @media ${devices.mobileXS} {
    margin: 10px 20px 0;
  }

  @media ${devices.laptop} {
    /* width: 30%; */
    flex-direction: column;
    justify-content: start;
    gap: 20px;
    padding: 15px 0px 15px 40px;
    margin: 0;
    border-bottom: none;
    border-right: 1px solid #e0e0e0;
  }
`;

const Tab = styled.button<{ $isActive: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  border: none;
  background: none;
  padding: 0 0 10px;
  // cursor: ${({ $isActive }) => ($isActive ? 'pointer' : 'not-allowed')};
  cursor: pointer;
  color: ${({ $isActive }) => ($isActive ? '#333' : '#949494')};
  font-size: 10px;
  line-height: 25px;
  text-align: left;
  border-bottom: ${({ $isActive }) => $isActive && '5px solid blue'};

  @media ${devices.mobileXS} {
    font-size: 12px;
    line-height: 25px;
  }

  @media ${devices.laptop} {
    border-bottom: none;
    border-right: ${({ $isActive }) => $isActive && '5px solid blue'};
    flex-direction: row;
    padding: 0 20px 0px 0px;
    gap: 20px;
    font-size: 14px;
    line-height: 30px;
  }
`;

const StepperContent = styled.div`
  margin: 30px 0 0;

  @media ${devices.mobileXS} {
    margin: 30px 20px 0;
  }

  @media ${devices.laptop} {
    margin: 30px 30px 0 45px;
    width: 70%;
  }
`;

const StatusButton = styled(Button)<{
  $status?: 'completed' | 'active' | 'pending';
}>`
  font-size: 10px;
  height: 15px;
  color: #333333;
  background-color: ${({ $status }) =>
    $status &&
    ($status === 'completed'
      ? '#00de90'
      : $status === 'active'
      ? '#a1a3a5'
      : $status === 'pending'
      ? '#efb880'
      : '')};
`;

const Stepper = ({
  activeStep,
  stepItems,
  style,
  className,
  enableTabClick = false,
  onTabClick,
  ...rest
}: IStepperProps) => {
  return (
    <StepperContainer className={className} style={{ ...style }} {...rest}>
      <StepperTabs>
        {stepItems.map((item, index) => (
          <Tab
            key={`tab-${item.key}`}
            $isActive={activeStep === index}
            // disabled={activeStep === index}
            onClick={() => {
              if (!!onTabClick && enableTabClick) {
                onTabClick(item);
              }
            }}
          >
            {item.label}
            {!!item.status && (
              <StatusButton variant="tag" radius="round" $status={item.status}>
                {item.status}
              </StatusButton>
            )}
          </Tab>
        ))}
      </StepperTabs>
      <StepperContent>
        {stepItems.map((item, index) => activeStep === index && item.children)}
      </StepperContent>
    </StepperContainer>
  );
};

export default Stepper;
