import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import React, { useState } from 'react';
import styled from 'styled-components';

interface MapProps {
  style?: React.CSSProperties;
  coordinates: Array<any>;
  MarkerImage?: string;
  readOnly?: boolean;
  onDrags?: (coords: any) => void;
  google?: string;
}

const MapComponent = ({
  coordinates,
  MarkerImage,
  readOnly = false,
  onDrags,
  style,
  google,
  ...restProps
}: MapProps) => {
  const [isMapReady, setMapReady] = useState(false);
  const handleOnDrag = (coords: any) => {
    if (!!onDrags) {
      onDrags(coords);
    }
  };

  return (
    <MapWrapper style={style} {...restProps}>
      <Map
        style
        google={google}
        // @ts-ignore
        mapTypeControl={false}
        onReady={(props, map) => {
          setMapReady(true);
        }}
        zoom={readOnly ? 12 : 10}
        zoomControl={!readOnly}
        fullscreenControl={!readOnly}
        streetViewControl={!readOnly}
        draggable={!readOnly}
        // containerStyle={{
        //   position: 'relative',
        // }}
        center={{
          lat: coordinates?.[0]?.latitude,
          lng: coordinates?.[0]?.longitude,
        }}
      >
        {isMapReady &&
          coordinates?.map(coordinate => (
            <Marker
              key={coordinate?.id}
              // @ts-ignore
              position={{
                lat: coordinate?.latitude,
                lng: coordinate?.longitude,
              }}
              // draggable
              onDragEnd={(coords: any) => {
                handleOnDrag(coords);
              }}
            />
          ))}
      </Map>
    </MapWrapper>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.GOOGLE_MAPS_API || '',
})(MapComponent);

const MapWrapper = styled.div`
  position: relative;
  min-height: 300px;
`;
