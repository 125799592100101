import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { capitalize } from 'lodash';
import { useRoot } from '@/RootProvider';
import request from '@shared/front/utils/request';
import {
  Info,
  InfoLabel,
  ProfileContainer,
  ProfileInfoContainer,
  SectionTitle,
} from './style';
import { devices } from '@/utils/theme';
import { StyledButton } from './bookingStepsView/styles';
import Rating from 'react-rating';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Avatar = styled.img`
  height: 42px;
  width: 42px;
  border-radius: 50%;
  border: 1px solid #949494;

  @media ${devices.laptop} {
    height: 56px;
    width: 56px;
  }
`;

const RefProfile = ({ profile, type, isMessage, bookingId }) => {
  // @ts-ignore

  const { auth } = useRoot();
  const navigate = useNavigate();

  const handleMessage = async ({
    landlordId,
    tenantId,
  }: {
    landlordId?: number;
    tenantId?: number;
  }) => {
    const data = {
      [`${auth?.role}Id`]: auth?.id,
      [`${profile.role}Id`]: profile.id,
      chatType: 'booking',
      bookingId: bookingId,
    };

    const messageId = await request({
      method: 'post',
      url: `/v1/common/message`,
      data,
    });

    navigate(`/chats/${messageId}`);
  };

  return (
    <div>
      <SectionTitle variant="p">{capitalize(type)} Profile</SectionTitle>
      <ProfileContainer>
        <ProfileInfoContainer
          role="button"
          onClick={() => {
            navigate(`/profile/${profile.id}/${profile.role}`);
          }}
        >
          <Avatar
            src={
              profile?.avatar ||
              'https://img.freepik.com/free-icon/user_318-159711.jpg'
            }
          />
          <div>
            <InfoLabel variant="p">
              {profile?.firstName} {profile?.lastName}
            </InfoLabel>
            <Info variant="p">{profile?.country}</Info>
            <Rating
              emptySymbol={<FontAwesomeIcon icon={['far', 'star']} />}
              fullSymbol={
                <FontAwesomeIcon icon={['fas', 'star']} color="#efe410" />
              }
              initialRating={profile?.rating}
              readonly
            />
          </div>
        </ProfileInfoContainer>
        {isMessage && (
          <StyledButton
            variant="gray"
            radius="round"
            onClick={() =>
              handleMessage({
                tenantId: auth?.role === 'tenant' ? auth.id : profile.id,
                landlordId: auth?.role === 'landlord' ? auth.id : profile.id,
              })
            }
          >
            Message {capitalize(type)}
          </StyledButton>
        )}
      </ProfileContainer>
    </div>
  );
};

export default RefProfile;
