import Typography from '@/newComponents/Typography';
import { Apis, Store, Utils } from '@shared/front';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  IssueDetailsContainer,
  IssueTitleStyled,
  StyledTag,
  TitleContainer,
} from './styles';
import Alert from '@/newComponents/Alert';
import SideProfile from '@shared/web/components/SideProfile';
import moment from 'moment';

const RESOLVED_ISSUE_DESCRIPTION =
  'This issue has already been resolved. See the information of resolver below.';
const PROCESSING_ISSUE_DESCRIPTION =
  'This issue is under processing. You will be notified once it is resolved.';

const IssueDetails = () => {
  const { id: issueId } = useParams();
  const issueDetails = useSelector(Store.Select.reportDetails);
  const getIssueDetails = async () => {
    await Utils.request(Apis.ReportIssue.getReportById(+issueId));
  };

  useEffect(() => {
    getIssueDetails();
  }, []);

  return (
    <IssueDetailsContainer>
      <TitleContainer>
        <IssueTitleStyled variant="h1">{issueDetails.title}</IssueTitleStyled>
        <StyledTag
          radius="round"
          variant="tag"
          bgColor={issueDetails.priority === 'High' ? 'red' : '#2061f8'}
        >
          {issueDetails.priority}
        </StyledTag>
      </TitleContainer>
      <Alert
        type={issueDetails.status === 'Completed' ? 'success' : 'info'}
        description={
          issueDetails.status === 'Completed'
            ? RESOLVED_ISSUE_DESCRIPTION
            : PROCESSING_ISSUE_DESCRIPTION
        }
        showIcon
      />
      <Typography variant="p">{issueDetails.desc}</Typography>
      {issueDetails.solvedUser && (
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <div>
            <p>Resolved By</p>
            <SideProfile
              profileData={issueDetails.solvedUser}
              role={issueDetails.solvedUser.role}
              isProfileViewable
            />
            <p>
              Resolved on:{' '}
              {moment(issueDetails.solvedDate).format('YYYY, MMM DD')}
            </p>
          </div>
        </div>
      )}
    </IssueDetailsContainer>
  );
};

export default IssueDetails;
