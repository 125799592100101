import Button from '@/newComponents/Button';
import Modal from '@/newComponents/Modal';
import Typography from '@/newComponents/Typography';
import { ButtonEndWrapperStyled } from '@/styles/shareableStyle';
import { devices } from '@/utils/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Apis, Store, Utils } from '@shared/front';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import DeleteIconNew from '../../../assets/delete-icon-new.svg';
import EditIconNew from '../../../assets/edit-icon-new.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media ${devices.laptop} {
    gap: 16px;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media ${devices.laptop} {
    gap: 20px;
  }
`;

const Address = styled(Typography)`
  font-size: 16px;
  font-weight: 500;

  @media ${devices.laptop} {
    font-size: 26px;
  }

  @media ${devices.laptopXL} {
    font-size: 32px;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media ${devices.laptop} {
    gap: 12px;
  }

  @media ${devices.laptopXL} {
    gap: 16px;
  }
`;

const Info = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  color: #949494;
  @media ${devices.laptop} {
    font-size: 14px;
  }
`;

const InfoLabel = styled(Info)`
  color: #333;
`;

const FeaturesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;

  & .feature {
    font-weight: 500;
    border: 1px solid #dbdbdb;
    padding: 6px 12px;
    border-radius: 30px;
    font-size: 13px;
  }
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const EditDeleteButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const UnStyledButton = styled(Button)`
  all: unset;
  cursor: pointer;
`;

const DesireList = () => {
  const navigate = useNavigate();
  const userDesired: any = useSelector(Store.Select.desired);
  const [deleteOwnDesire, setDeleteOwnDesire] = useState({
    modalStatus: false,
    message: '',
    deleting: false,
  });
  const getDesired = async () => {
    try {
      await Utils.request(Apis.Auth.getOwnUserDesires());
    } catch (err) {}
  };

  useEffect(() => {
    getDesired();
  }, []);

  const trades = userDesired?.trades?.key?.split(',');

  const handleDeleteOwnDesire = async (desireId: number) => {
    try {
      setDeleteOwnDesire(prev => ({
        ...prev,
        deleting: true,
      }));
      const response = await Utils.request(
        Apis.Auth.deleteOwnDesires(desireId),
      );
      if (response) {
        toast.success('Desired service info removed.');
        onCloseDeleteModal();
        Store.store.dispatch(Store.Actions.reset('desired'));
      }
    } catch (error) {
      toast.error('Failed to remove desired service info.');
    } finally {
      setDeleteOwnDesire(prev => ({
        ...prev,
        deleting: false,
      }));
    }
  };

  const onCloseDeleteModal = () => {
    setDeleteOwnDesire({
      deleting: false,
      message: '',
      modalStatus: false,
    });
  };

  const onDeleteOwnDesire = () => {
    setDeleteOwnDesire(prev => ({
      ...prev,
      modalStatus: true,
      message:
        'Are you sure you want to delete your service desired information ?',
    }));
  };

  if (!userDesired?.id) {
    return (
      <Container>
        <TitleContainer>
          <Typography
            variant="h3"
            fontWeight={'extrabold'}
            style={{
              fontSize: '16px',
            }}
          >
            Desired Service
          </Typography>

          <EditDeleteButtonsContainer>
            <UnStyledButton onClick={() => navigate('/serviceDesired/add')}>
              <FontAwesomeIcon icon="fa-solid fa-plus" />
            </UnStyledButton>
          </EditDeleteButtonsContainer>
        </TitleContainer>
        <Typography variant="p" size="sm" color="#666">
          You have not set any desired service features.
        </Typography>
      </Container>
    );
  }

  return (
    <>
      <Container>
        <TitleContainer>
          <Typography
            variant="h3"
            fontWeight={'extrabold'}
            style={{
              fontSize: '16px',
            }}
          >
            Desired Service
          </Typography>

          <EditDeleteButtonsContainer>
            <UnStyledButton
              onClick={() => {
                navigate('/serviceDesired/edit');
              }}
            >
              <img src={EditIconNew} alt="Edit" />
            </UnStyledButton>

            <UnStyledButton onClick={onDeleteOwnDesire}>
              <img src={DeleteIconNew} alt="Edit" />
            </UnStyledButton>
          </EditDeleteButtonsContainer>
        </TitleContainer>
        <InfoWrapper>
          <InfoContainer>
            <InfoLabel variant="p">
              Location&nbsp;&nbsp;&nbsp;
              <Info variant="span">{userDesired?.address || 'N/A'}</Info>
            </InfoLabel>
            <InfoLabel variant="p">
              Location From&nbsp;&nbsp;&nbsp;
              <Info variant="span">{userDesired?.desiredFrom || 'N/A'}</Info>
            </InfoLabel>
            <InfoLabel variant="p">
              Location To&nbsp;&nbsp;&nbsp;
              <Info variant="span">{userDesired?.desiredTo || 'N/A'}</Info>
            </InfoLabel>
            <InfoLabel variant="p">
              Move Date&nbsp;&nbsp;&nbsp;
              <Info variant="span">{userDesired?.moveStatus || 'N/A'}</Info>
            </InfoLabel>
            <InfoLabel variant="p">
              Price&nbsp;&nbsp;&nbsp;
              {userDesired?.price ? (
                <Info variant="span">&euro; {userDesired?.price}</Info>
              ) : (
                <Info variant="span"> N/A</Info>
              )}
            </InfoLabel>
            <InfoLabel variant="p">
              Target Price&nbsp;&nbsp;&nbsp;
              {userDesired?.targetPrice ? (
                <Info variant="span">&euro; {userDesired?.targetPrice}</Info>
              ) : (
                <Info variant="p">N/A</Info>
              )}
            </InfoLabel>
            <InfoLabel variant="p">
              Commute Distance&nbsp;&nbsp;&nbsp;
              <Info variant="span">
                {userDesired?.distance ? userDesired?.distance + ' KM' : 'N/A'}
              </Info>
            </InfoLabel>
            <InfoLabel variant="p">
              Commute Time&nbsp;&nbsp;&nbsp;
              <Info variant="span">
                {userDesired?.time ? userDesired?.time + ' mins' : 'N/A'}
              </Info>
            </InfoLabel>
            <InfoLabel variant="p">
              Payment Type&nbsp;&nbsp;&nbsp;
              <Info variant="span">{userDesired?.paidType || 'N/A'}</Info>
            </InfoLabel>
            <InfoLabel variant="p">
              Property Type&nbsp;&nbsp;&nbsp;
              <Info variant="span">{userDesired?.propertyType || 'N/A'}</Info>
            </InfoLabel>
          </InfoContainer>
          <FeaturesContainer>
            {Array.isArray(trades) &&
              trades.map((trade: string) => (
                <Typography variant="span" key={trade} className="feature">
                  {trade}
                </Typography>
              ))}
          </FeaturesContainer>
        </InfoWrapper>
      </Container>

      <Modal
        open={deleteOwnDesire?.modalStatus}
        onClose={onCloseDeleteModal}
        title="Delete"
        size={'sm'}
      >
        <Typography variant="p"> {deleteOwnDesire?.message}</Typography>
        <br />
        <ButtonEndWrapperStyled>
          <Button variant="gray" onClick={onCloseDeleteModal}>
            Cancel
          </Button>
          <Button
            variant="danger"
            isDisabled={deleteOwnDesire?.deleting}
            isLoading={deleteOwnDesire?.deleting}
            onClick={() => handleDeleteOwnDesire(userDesired?.id)}
          >
            Delete
          </Button>
        </ButtonEndWrapperStyled>
      </Modal>
    </>
  );
};

export default DesireList;
