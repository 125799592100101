import { useResponsive } from '@/hooks/useResponsive';
import Divider from '@/newComponents/Divider';
import SkeletonLoading from '@/newComponents/Skeleton';
import { devices } from '@/utils/theme';
import React from 'react';
import styled from 'styled-components';

const JobViewSkeleton = ({ ...rest }) => {
  const { isTablet } = useResponsive();
  return (
    <Wrapper>
      {[1, 2].map(item => {
        return (
          <div>
            <div className="card" key={item}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: isTablet ? 'column' : 'column',
                    gap: isTablet ? '20px' : '10px',
                  }}
                >
                  <SkeletonLoading
                    width={isTablet ? 150 : 150}
                    height={30}
                    borderRadius={10}
                  />
                  <div>
                    <SkeletonLoading
                      height={30}
                      width={250}
                      style={{ marginTop: isTablet ? '12px' : '4px' }}
                    />
                    <SkeletonLoading
                      borderRadius={10}
                      height={30}
                      width={140}
                      style={{ marginTop: isTablet ? '20px' : '5px' }}
                    />
                    <SkeletonLoading
                      borderRadius={10}
                      height={30}
                      width={140}
                      style={{ marginTop: isTablet ? '20px' : '5px' }}
                    />
                    <SkeletonLoading
                      borderRadius={10}
                      height={30}
                      width={140}
                      style={{ marginTop: isTablet ? '20px' : '5px' }}
                    />
                    <SkeletonLoading
                      borderRadius={10}
                      height={30}
                      width={140}
                      style={{ marginTop: isTablet ? '20px' : '5px' }}
                    />
                    <SkeletonLoading
                      borderRadius={10}
                      height={30}
                      width={140}
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        marginTop: '20px',
                      }}
                    >
                      <SkeletonLoading
                        borderRadius={10}
                        height={300}
                        width={900}
                      />
                      <SkeletonLoading
                        borderRadius={10}
                        height={30}
                        width={30}
                      />
                    </div>
                  </div>
                </div>
                <SkeletonLoading borderRadius={10} height={300} width={300} />
              </div>
              {/* {isTablet && (
                <SkeletonLoading height={30} width={80} borderRadius={10} />
              )} */}
            </div>
            {isTablet && <Divider style={{ marginTop: '20px' }} />}
          </div>
        );
      })}
    </Wrapper>
  );
};

export default JobViewSkeleton;

const Wrapper = styled.div`
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media ${devices.tablet} {
    flex-direction: column;
    gap: 20px;
  }
  .card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    align-items: center;
    margin-top: 20px;
  }
`;

const Container = styled.div``;
