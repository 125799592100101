import React from 'react';
import styled from 'styled-components';

const Divider = ({
  orientation = 'horizontal',
  style,
  bgColor,
  space = '18px 0px',
}: DividerProps) => {
  return (
    <DividerWrapper
      orientation={orientation}
      style={style}
      bgColor={bgColor}
      space={space}
    ></DividerWrapper>
  );
};

export default Divider;

const DividerWrapper = styled.div<{
  orientation: string;
  bgColor: string;
  space: string;
}>`
  width: ${props => (props.orientation === 'vertical' ? '1px' : '100%')};
  height: ${props => (props.orientation === 'horizontal' ? '1px' : '100%')};
  background-color: ${props => props.bgColor || props.theme?.colors?.divider};
  margin: ${({ space }) => space};
`;

interface DividerProps {
  orientation?: 'vertical' | 'horizontal';
  style?: React.CSSProperties;
  bgColor?: string;
  space?: string;
}
