import styled from 'styled-components';

export const ProfileWrapper = styled.div`
  flex-direction: column;
  background-color: #ffffff;
  width: 100%;
  min-width: 160px;
  flex-shrink: 0;
  border-radius: 5px;
`;
