import InputField from '@shared/web/components/InputField';
import VerticalRadio from '@shared/web/components/verticalRadio';
import { generateSteps } from '@shared/front/formBuilder';
import { Formik } from 'formik';
import { stringify } from 'querystring';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import ContractForm from './ContractForm';
import useAPI from '@/hooks/api';
import DatePickerField from '@shared/web/components/DatePicker';
import { toast } from 'react-toastify';
import MaskInputField from '@shared/web/components/MaskInputField';

const GenerateContract = () => {
  const [initialValues, setInitialValues] = useState<any>({});
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [stepLoading, setStepLoading] = useState<boolean>(false);
  const [getBookingRequest, { loading, data: bookingData }] = useAPI();
  const [makeGenerateContractRequest, { loading: generateContractLoading }] =
    useAPI();
  const [searchParams] = useSearchParams();
  const formRef = useRef<any>();
  const navigate = useNavigate();
  const bookingID = searchParams.get('bookingID');
  const propertyId = bookingData?.property?.id;
  console.log(bookingData, 'booking data');
  const getBooking = async () => {
    getBookingRequest({
      method: 'get',
      url: `/v1/bookings/${bookingID}`,
    });
  };
  const handleGenerateContract = async () => {
    const formikValue = formRef.current.values;
    try {
      await makeGenerateContractRequest({
        method: 'post',
        url: '/v1/contract-generate/contracts',
        data: {
          bookingId: bookingID && +bookingID,
          tenantId: bookingData?.tenant?.id,
          propertyId: bookingData?.property?.id,
          data: formikValue,
          isSystemGenerate: true,
        },
        // data: {
        //   bookingId: 315,
        //   tenantId: 11,
        //   landlordId: 9,
        //   propertyId: 482,
        //   isSystemGenerate: true,
        //   paymentMood: 'Manual',
        //   paymentChargeDay: 15,
        //   data: {
        //     startDate: '2023-04-11 12:44 AM',
        //     endDate: '2023-04-16 12:02:44 AM',
        //     escrowAmount: '499',
        //     latePaymentCharge: '5',
        //     aggrementDate: '2023-03-29',
        //   },
        // },
      });
      navigate(`/bookings/${propertyId}`);
    } catch (error: any) {
      toast.error(error);
    }
  };

  const formSteps = generateSteps({
    Components: {
      RadioBoxGroup: VerticalRadio,
      Date: DatePickerField,
      Input: InputField,
      MaskInput: MaskInputField,
    },
    initialValues,
  });
  const currentForm: any = formSteps[currentStep];
  const handleSubmitForm = (values: any) => {
    setStepLoading(true);
    setTimeout(async () => {
      if (currentStep < formSteps?.length - 1) {
        setCurrentStep(currentStep + 1);
        setStepLoading(false);
      } else {
        await handleGenerateContract();
        setStepLoading(false);
      }
    }, 500);
  };
  const formStepsLength = formSteps?.length;

  useEffect(() => {
    getBooking();
  }, [bookingID]);

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        propertyAddress: bookingData?.property?.address,
        tenantFullName: bookingData?.tenant?.fullName,
        tenantPhoneNo: bookingData?.tenant?.phone,
        tenantEmail: bookingData?.tenant?.email,
        ...initialValues,
      }}
      enableReinitialize
      validationSchema={currentForm?.validations}
      initialTouched={{ leaseTermData: true }}
      onSubmit={values => {
        handleSubmitForm(values);
      }}
    >
      {formikProps => {
        return (
          <>
            <ContractForm
              formikProps={formikProps}
              initialValues={initialValues}
              currentForm={currentForm}
              setCurrentStep={setCurrentStep}
              currentStep={currentStep}
              errors={formikProps?.errors}
              formStepsLength={formStepsLength}
              setInitialValues={setInitialValues}
              contractLoading={generateContractLoading}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default GenerateContract;
