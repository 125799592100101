import { Apis, Store, Utils } from '@shared/front';
import DatePickerField from '@shared/web/components/DatePicker';
import Modal from '@/newComponents/Modal';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useRoot } from '@/RootProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { devices } from '@/utils/theme';
import { useResponsive } from '@/hooks/useResponsive';
import EditIconNew from '../../../assets/edit-icon-new.svg';
import DeleteIconNew from '../../../assets/delete-icon-new.svg';
import {
  AddButton,
  EditDeleteButtonsContainer,
  Info,
  InfoLabel,
  UnStyledButton,
  Title,
  StyledButton,
  StyledLabel,
  SaveButtonContainer,
} from '../index.styles';
import Typography from '@/newComponents/Typography';
import Input from '@/newComponents/Input';
import DatePicker from '@/newComponents/DatePicker';
import DatePickerCustomized from '@/newComponents/DatePicker';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  @media ${devices.laptop} {
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
  }

  & .info-wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
`;
const Education = ({
  educationalData,
  profileId: userId,
  profileRole,
}: any) => {
  const { auth, checkAuth } = useRoot();
  const role = auth.role;
  const formikRef = useRef();

  const [initialState, setInitialState] = useState({
    id: '',
    school: '',
    degree: '',
    fieldOfStudy: '',
    startDate: new Date(),
    endDate: new Date(),
    isActive: true,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [itemId, setItemId] = useState();
  const educations: any = useSelector(Store.Select.educations);

  const { isTablet } = useResponsive();
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOkDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };
  const showDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };
  const handleCancelDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const getEducationHistories = async () => {
    await Utils.request(Apis.Education.getEducation());
  };

  const getEducationHistoriesById = async (id: number) => {
    const dataById = await Utils.request(Apis.Education.getEducationById(id));

    if (!dataById) return;
    const startDate = !dataById?.startYear
      ? new Date()
      : new Date(`${dataById?.startYear}-${dataById?.startMonth}`);
    const endDate = !dataById?.endYear
      ? new Date()
      : new Date(`${dataById?.endYear}-${dataById?.endMonth}`);

    setInitialState({
      ...dataById,
      startDate: isValidDate(startDate) ? startDate : new Date(),
      endDate: isValidDate(endDate) ? endDate : new Date(),
    });
  };

  const isValidDate = (date: any) => date instanceof Date && !isNaN(date);

  useEffect(() => {
    auth?.id && getEducationHistoriesById(auth?.id);
  }, [auth?.id]);

  const handleCreateUpdate = async (formData: any, id: number) => {
    try {
      const finalData = {
        isActive: !!formData?.isActive,
        startYear: moment(formData?.startDate).format('yyyy'),
        startMonth: moment(formData?.startDate).format('MM-DD'),
        endYear: moment(formData?.endDate).format('yyyy'),
        endMonth: moment(formData?.endDate).format('MM-DD'),
        school: formData?.school,
        degree: formData?.degree,
        fieldOfStudy: formData?.fieldOfStudy,
      };

      const response = await Utils.request(
        Apis.Education.createOrUpdateEducation(id, finalData),
      );
      if (response.status === 200 || response.status === 201) {
        alert(
          'Education history has been successfully ' +
            (id ? 'updated.' : 'added.'),
        );
      }
    } catch (error) {
      console.error(error);
    }
    getEducationHistories();
  };

  const handleDelete = async id => {
    await Utils.request(Apis.Education.deleteEducation(id));
    getEducationHistories();
  };

  useEffect(() => {
    getEducationHistories();
  }, []);

  return (
    <>
      <Container>
        <Title variant="p">
          {role === 'service' || profileRole === 'service'
            ? 'Qualifications and Associations'
            : 'Education'}
        </Title>

        {!userId && (
          <AddButton onClick={showModal} variant="gray">
            <FontAwesomeIcon icon="fa-solid fa-plus" />
          </AddButton>
        )}
        <Modal
          title="Add Education"
          open={isModalOpen}
          onClose={() => {
            handleCancel();
            setInitialState({
              id: '',
              school: '',
              degree: '',
              fieldOfStudy: '',
              startDate: new Date(),
              endDate: new Date(),
              isActive: true,
            });
          }}
        >
          <Formik
            innerRef={formikRef}
            initialValues={initialState}
            onSubmit={(values, { resetForm }) => {
              handleCreateUpdate(values, !!values?.id ? values?.id : null);
              resetForm();
              handleOk();
            }}
            enableReinitialize
            validationSchema={yup.object().shape({
              school: yup.string().required('Please provide your school name.'),
              degree: yup.string().required('Please provide your degree.'),
              fieldOfStudy: yup
                .string()
                .required('Please provide field of study.'),
              startDate: yup.string().required('Please select start date.'),
              endDate: yup.string().required('Please select end date.'),
            })}
          >
            {({
              isSubmitting,
              errors,
              touched,
              values,
              setFieldValue,
              handleSubmit,
              initialValues,
              resetForm,
            }) => {
              return (
                <Form>
                  <Field
                    name="school"
                    component={Input}
                    label="School"
                    onChange={e => setFieldValue('school', e.target.value)}
                    error={errors?.school}
                    value={values?.school}
                  />
                  <Field
                    name="degree"
                    component={Input}
                    label="Degree"
                    onChange={e => setFieldValue('degree', e.target.value)}
                    error={errors?.degree}
                    value={values?.degree}
                  />
                  <Field
                    name="fieldOfStudy"
                    component={Input}
                    label="Field of Study"
                    onChange={e =>
                      setFieldValue('fieldOfStudy', e.target.value)
                    }
                    error={errors?.fieldOfStudy}
                    value={values?.fieldOfStudy}
                  />
                  <div>
                    <StyledLabel>Start Date</StyledLabel>
                    <Field
                      name="startDate"
                      component={DatePickerCustomized}
                      value={values?.startDate}
                    />
                  </div>
                  <div>
                    <StyledLabel>End Date</StyledLabel>
                    <Field
                      name="endDate"
                      component={DatePickerCustomized}
                      value={values?.endDate}
                    />
                  </div>
                  <SaveButtonContainer>
                    <StyledButton
                      htmlType="submit"
                      radius="round"
                      isLoading={false}
                      isDisabled={false}
                    >
                      Add
                    </StyledButton>
                  </SaveButtonContainer>
                </Form>
              );
            }}
          </Formik>
        </Modal>
      </Container>
      <Modal
        title="Education History"
        style={{ maxWidth: isTablet ? '500px' : '100%' }}
        size={isTablet ? 'md' : 'lg'}
        open={isDeleteModalOpen}
        onClose={() => {
          handleOkDeleteModal();
        }}
      >
        <Typography variant="p" style={{ fontSize: '14px' }}>
          Do you want to delete this education history?
        </Typography>
        <SaveButtonContainer>
          <StyledButton
            onClick={handleCancelDeleteModal}
            variant="gray"
            radius="round"
          >
            Cancel
          </StyledButton>
          <StyledButton
            variant="danger"
            radius="round"
            onClick={() => {
              handleDelete(itemId);
              handleOkDeleteModal();
            }}
            style={{ marginLeft: '16px' }}
          >
            Delete
          </StyledButton>
        </SaveButtonContainer>
      </Modal>
      {(userId && userId !== auth.id ? educationalData : educations)?.map(
        (item: any) => {
          return (
            <DetailsContainer key={item.id}>
              <div className="info-wrapper">
                <InfoLabel variant="p">
                  School <Info variant="span">{item.school}</Info>
                </InfoLabel>
                <InfoLabel variant="p">
                  Degree <Info variant="span">{item.degree}</Info>
                </InfoLabel>
                <InfoLabel variant="p">
                  Field Of Studies{' '}
                  <Info variant="span">{item.fieldOfStudy}</Info>
                </InfoLabel>

                {item.startMonth && item.endMonth && (
                  <InfoLabel variant="p">
                    Date{' '}
                    <Info variant="span">
                      {moment(item.startYear + '=' + item.startMonth).format(
                        'YYYY-MM-DD',
                      )}
                    </Info>{' '}
                    to{' '}
                    <Info variant="span">
                      {moment(item.endYear + '=' + item.endMonth).format(
                        'YYYY-MM-DD',
                      )}
                    </Info>
                  </InfoLabel>
                )}
              </div>

              {!userId && (
                <EditDeleteButtonsContainer>
                  <UnStyledButton
                    onClick={() => {
                      showModal();
                      getEducationHistoriesById(item?.id);
                    }}
                  >
                    <FontAwesomeIcon icon="pencil" color="blue" />
                  </UnStyledButton>

                  <UnStyledButton
                    onClick={() => {
                      showDeleteModal();
                      setItemId(item.id);
                    }}
                  >
                    <img src={DeleteIconNew} alt="Delete" />
                  </UnStyledButton>
                </EditDeleteButtonsContainer>
              )}
            </DetailsContainer>
          );
        },
      )}
    </>
  );
};

export default Education;
