import { FormikErrors, FormikTouched } from 'formik';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { ThemeType } from '@/utils/theme';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import Error from '@/components/Error';
import Typography from './Typography';
import { capitalize } from 'lodash';

export const DATE_FORMAT = 'YYYY-MM-DD';

type FieldType = {
  name: string;
  value: any;
  onChange: (e: React.ChangeEvent<any>) => void;
  onBlur: (e: React.FocusEvent<any, Element>) => void;
  [rest: string]: any;
};

type MetaType = {
  errors?: FormikErrors<object> | undefined;
  touched?: FormikTouched<object> | undefined;
  setFieldValue: Function;
};

type DateFieldType = {
  field: FieldType;
  form: MetaType;
  format?: string;
  border?: keyof ThemeType['sizes'];
  placeholder: string;
  background?: string;
  picker: string;
  onChange?: any;
  label?: string;
};

type StyledDateFieldType = {
  border: keyof ThemeType['sizes'];
  hasError: boolean;
  background: string;
};

const StyledDateField = styled.div<StyledDateFieldType>`
  & .react-datepicker-wrapper {
    width: 100%;
  }
  & .react-datepicker__input-container .react-datepicker__calendar-icon {
    padding: 13px;
  }
  & .date-picker {
    width: 100%;
    padding: 10px 30px;
    border: 1px solid #dbdbdd;
    border-radius: 6px;
    font-family: inherit;
  }

  & .react-datepicker {
    width: calc(100% - 40px);
    max-width: 600px;
  }
  & .react-datepicker-popper {
    width: calc(100%);
  }

  & .react-datepicker__month-container {
    float: center;
    width: calc(100%);

    & .react-datepicker__header {
      background-color: #2061f8;
    }
    & .react-datepicker__current-month {
      color: #fcfcfc;
      font-size: 1.2rem;
    }
    & .react-datepicker__day-names {
      & .react-datepicker__day-name {
        color: #fff;
      }
    }

    & .react-datepicker__day--selected {
      color: #fcfcfc;
    }
  }

  & .react-datepicker__day-name,
  & .react-datepicker__day,
  & .react-datepicker__time-name {
    color: #000;
    font-weight: 500;
    display: inline-block;
    width: calc(100% / 8);
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
    font-size: 14px;

    &--disabled {
      color: #dbdbdb;
    }
  }

  & .react-datepicker__day--outside-month {
    color: rgba(0, 0, 0, 0.4);
    font-weight: 300;
  }
`;

const DatePickerCustomized: React.FC<DateFieldType> = ({
  field: { name, value, onBlur, onChange: fomikHandleChange },
  form: { errors, touched, setFieldValue, submitCount },
  border = 'md',
  placeholder = '',
  format,
  picker,
  onChange,
  background = 'rgb(242, 242, 242, 1)',
  label,
  ...restProps
}: DateFieldType) => {
  const handleChange = (date: any) => {
    const formattedDate = moment(date).format(DATE_FORMAT);
    setFieldValue(name, date);
    setFieldValue(name + '-formatted', formattedDate);
  };
  const hasError = errors?.[name] && (touched?.[name] || !!submitCount);

  return (
    <StyledDateField
      border={border}
      placeholder={placeholder}
      hasError={hasError}
      background={background}
    >
      <Typography>{label}</Typography>
      <DatePicker
        onBlur={onBlur}
        name={name}
        selected={value}
        onChange={onChange || handleChange}
        dateFormat={'yyyy/MM/dd'}
        className="date-picker"
        isClearable
        closeOnScroll={true}
        showIcon
        {...restProps}
      />
      {hasError && (
        <Error
          message={
            errors && errors[name].includes('null')
              ? 'Date is required.'
              : errors[name]
          }
        />
      )}
    </StyledDateField>
  );
};
export default DatePickerCustomized;
