import InputField from '@shared/web/components/InputField';
import Button from '@/newComponents/Button';
// import Typography from '@/newComponents/Typography';
import { ErrorMessage, Field, Form, Formik, useFormik } from 'formik';
import React, { useRef, useState } from 'react';
import * as yup from 'yup';
import useAPI from '@/hooks/api';
// import { Console } from 'console';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { devices } from '@/utils/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import validation from './validation';
// import AppButton from '@shared/web/components/Button';
// import Error from '@shared/web/components/Error';
import {
  SaveButtonContainer,
  StyledButton,
  StyledLabel,
  Title,
} from './index.styles';

const SpecificationBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media ${devices.laptop} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 26px;
  }
`;

const AddButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 6px;
`;

const AddButton = styled(Button)`
  width: fit-content;
  min-width: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;

  &:hover {
    background-color: #d0d0d0;
  }
`;
const CloseIcon = styled(FontAwesomeIcon)`
  color: red;
  background-color: white;
  border-radius: 20px;

  position: absolute;
  top: 30px;
  right: -10px;

  font-size: 20px;
  cursor: pointer;
`;
// const CloseButton = styled(Button)`
//   width: 10px;
//   border-radius: 50%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background-color: ${({ theme }) => theme.colors.danger};
// `;
const ErrorMessageStyle = styled(ErrorMessage)`
  color: red;
`;

const Specification = ({ initialValues, onSubmitStep }) => {
  // const { id } = useParams();
  // const formik = useFormik({ initialValues: initialValues });
  const [handleSubmitRequest, { loading, error }] = useAPI();

  const handleSubmitForm = async (
    specification,
    validateForm,
    isDraft?: boolean,
  ) => {
    const url = '/v1/marketPlace/products';
    const updateUrl = '/v1/marketPlace/products';
    await validateForm().then(async errors => {
      if (Object.keys(errors).length === 0) {
        try {
          const data = await handleSubmitRequest({
            method: !!initialValues.id ? 'patch' : 'post',
            url: !!initialValues.id ? `${updateUrl}/${initialValues.id}` : url,
            data: {
              specification: JSON.stringify({ specification: specification }),
            },
          });
          onSubmitStep({ data, isDraft });
        } catch (error) {
          console.log('Error submitting form', error);
        }
      } else {
      }
    });
  };

  const getInitialValues = () => {
    if (!!initialValues.specification) {
      const specs = JSON.parse(initialValues?.specification);
      if (
        Array.isArray(specs.specification) &&
        specs?.specification?.length > 0
      ) {
        return specs;
      }
    }
    return {
      specification: [{ title: '', value: '' }],
    };
  };
  const validationSchema = yup.object().shape({
    specification: yup.array().of(
      yup.object().shape({
        title: yup.string().required('Title is required'),
        value: yup.string().required('Value is required'),
      }),
    ),
  });

  return (
    <div>
      <Formik
        initialValues={getInitialValues()}
        onSubmit={() => {}}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values, setFieldValue, errors, validateForm }) => {
          const handleAddMore = () => {
            setFieldValue('specification', [
              ...values.specification,
              { title: '', value: '' },
            ]);
          };
          const handleRemoveField = () => {
            const updatedSpecification = [...values.specification];
            updatedSpecification.pop();
            setFieldValue('specification', updatedSpecification);
          };
          console.log({ initialValues, values });

          return (
            <>
              <Form style={{ maxWidth: '700px' }}>
                <Title variant="h1">Specifications</Title>
                <AddButtonContainer>
                  <AddButton variant="gray" onClick={() => handleAddMore()}>
                    <FontAwesomeIcon icon={'plus'} />
                  </AddButton>
                </AddButtonContainer>
                {values?.specification?.map((item: any, index: any) => {
                  const isLastItem = index === values.specification.length - 1;
                  return (
                    <SpecificationBox key={index}>
                      <div style={{ width: '100%' }}>
                        <StyledLabel>Title</StyledLabel>
                        <Field
                          placeholder="Add a title"
                          name={`specification[${index}].title`}
                          component={InputField}
                        />
                        <ErrorMessageStyle
                          name={`specification[${index}].title`}
                          component="div"
                        />
                      </div>
                      <div style={{ width: '100%', position: 'relative' }}>
                        <StyledLabel>Value</StyledLabel>
                        <Field
                          placeholder="Value"
                          name={`specification[${index}].value`}
                          component={InputField}
                        />
                        <ErrorMessageStyle
                          name={`specification[${index}].value`}
                          component="div"
                        />
                        {isLastItem && values.specification.length > 1 && (
                          <CloseIcon
                            icon="times-circle"
                            onClick={() => handleRemoveField()}
                          />
                        )}
                      </div>
                    </SpecificationBox>
                  );
                })}

                <SaveButtonContainer>
                  <StyledButton
                    radius="round"
                    onClick={() =>
                      handleSubmitForm(values.specification, validateForm)
                    }
                  >
                    Save
                  </StyledButton>
                </SaveButtonContainer>
                {Object.keys(errors).length > 0 && (
                  <div style={{ color: 'red' }}>
                    Please add title and value before submitting
                  </div>
                )}
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default Specification;
