import Button from '@/newComponents/Button';
import Modal from '@/newComponents/Modal';
import Flex from '@shared/web/components/Flex';
import React, { CSSProperties, ReactNode } from 'react';

type Props = {
  onClose: () => void;
  onAction: () => void;
  isLoading?: boolean;
  children?: ReactNode;
  title?: string;
  isVisible: boolean;
  modalStyle?: CSSProperties;
};

const DeleteModal = ({
  onAction,
  onClose,
  children,
  isLoading,
  title,
  isVisible,
  modalStyle,
}: Props) => {
  return (
    <Modal
      onClose={onClose}
      open={isVisible}
      closeOnBackdropClick={false}
      isCentered
      title={title || 'Delete'}
      size="sm"
      style={{ maxWidth: '400px', ...modalStyle }}
    >
      {children}
      <Flex
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap="20px"
        margin={'30px 0px 0px'}
        padding={'0px 30px'}
      >
        <Button variant="gray" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="danger" isLoading={isLoading} onClick={onAction}>
          Delete
        </Button>
      </Flex>
    </Modal>
  );
};

export default DeleteModal;
