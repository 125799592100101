import React from 'react';
import NoDataSvg from '../../src/assets/empty.svg';
import styled from 'styled-components';

import Typography from './Typography';
const EmptyImage = styled.img<{ size: string }>`
  width: ${({ size }) => size}px;
  height: auto;
`;
const EmptyPageWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const NoData = ({ type, title, size }: any) => {
  return (
    <EmptyPageWrapper>
      <EmptyImage src={NoDataSvg} alt="Empty" size={!!size ? size : '275'} />

      <Typography variant="h1" size="sm" style={{ marginTop: '16px' }}>
        {!!title ? title : ' No data available right now'}
      </Typography>
    </EmptyPageWrapper>
  );
};

export default NoData;
