import React from 'react';
import { SectionWrapper, SectionTitle } from './style';
import Map from '@/newComponents/Map';
interface MapViewProps {
  coordinates: Array<any>;
}
const GOOGLE_MAPS_KEY = process.env.GOOGLE_MAPS_API || '';
const MapView = ({ coordinates }: MapViewProps) => {
  return (
    <SectionWrapper>
      <SectionTitle>Location</SectionTitle>
      <Map coordinates={coordinates} />
    </SectionWrapper>
  );
};

export default MapView;
