import React from "react";
import styled from "styled-components";
import { Row, Typography, Divider, Timeline } from "antd";
import { devices } from "../../../packages/web/src/utils/theme";
import Rating from "react-rating";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RentalHistoryWrapper = styled.div`
  border: 1px solid #ddd;
  border-radius: 20px;
  padding: 10px;
  @media${devices.tablet} {
    padding: 20px;
  }
`;
const RentalHistoryContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media${devices.tablet} {
    justify-content: flex-start;
  }
`;
const RentalHistoryDetail = styled.div`
  display: flex;
  flex-direction: column;
  @media${devices.tablet} {
    margin-right: 40px;
  }
`;
const RentalHistoryRating = styled.div`
  display: flex;
  flex-direction: column;
`;
const RentalHistory = ({ tenantData }) => {
  console.log({ tenantData });

  return (
    <RentalHistoryWrapper>
      <Typography.Title level={4}>
        Rental History ({tenantData?.length} Total Properities)
      </Typography.Title>
      {console.log(tenantData?.data, "testtenantData")}
      <Divider style={{ borderWidth: "2px" }} />
      <Timeline>
        {tenantData?.map((item) => (
          <Timeline.Item>
            <RentalHistoryContent>
              <RentalHistoryDetail>
                <Typography.Title level={5}>{item?.id}</Typography.Title>
                <Typography.Text>Salt Lake City, UT 84106</Typography.Text>
                <Typography.Text>Feb 2020 - Jan 2021</Typography.Text>
                <Typography.Text>Rent: $2300 /month</Typography.Text>
              </RentalHistoryDetail>
              <RentalHistoryRating>
                <Rating
                  emptySymbol={<FontAwesomeIcon icon={["far", "star"]} />}
                  fullSymbol={
                    <FontAwesomeIcon icon={["fas", "star"]} color="#00ffff" />
                  }
                  initialRating={2.5}
                  readonly
                />
              </RentalHistoryRating>
            </RentalHistoryContent>
          </Timeline.Item>
        ))}
      </Timeline>
    </RentalHistoryWrapper>
  );
};

export default RentalHistory;
