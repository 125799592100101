function getReportModules(role?: string | null) {
  const modules = [
    // role === "tenant" && {
    //   label: "Property",
    //   value: "Property",
    // },
    role === "service" && {
      label: "Service",
      value: "Service",
    },
    {
      label: "Chat",
      value: "Chat",
    },
    {
      label: "Calendar",
      value: "Calender",
    },
    // {
    //   label: "Contract",
    //   value: "Contract",
    // },
    {
      label: "Transaction",
      value: "Transaction",
    },
  ].filter(Boolean);

  return modules;
}

export default getReportModules;
