import Button from '@/newComponents/Button';
import Typography from '@/newComponents/Typography';
import { devices } from '@/utils/theme';
import { Form } from 'formik';
import styled from 'styled-components';

export const StyledTag = styled(Button)`
  cursor: default;
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media ${devices.laptop} {
    gap: 40px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledButton = styled(Button)`
  font-size: 12px;
  line-height: 25px;

  @media ${devices.laptop} {
    font-size: 14px;
    line-height: 30px;
  }
`;

// Issue Details Styles

export const IssueDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media ${devices.laptop} {
    gap: 40px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

export const IssueTitleStyled = styled(Typography)`
  font-size: 24px;
  line-height: 36px;

  @media ${devices.laptop} {
    font-size: 30px;
    line-height: 40px;
  }
`;
