import React, { useRef, useEffect, useMemo } from 'react';
import FeatureCard from '../FeatureCard';
import styled from 'styled-components';
import Specification from '../Specification';
import ReuseTag from '@shared/web/components/ReuseTag';
import { capitalize } from 'lodash';
import Typography from '@/newComponents/Typography';
import Button from '@/newComponents/Button';

const Description = ({ productInfo }: any) => {
  const {
    address,
    adsExpiry,
    condition,
    delivery,
    desc,
    negotiable,
    title,
    price,
  } = productInfo;

  const descData = [
    {
      title: 'price',
      desc: price,
    },
    {
      title: 'address',
      desc: address,
    },
    {
      title: 'adsExpiry',
      desc: adsExpiry,
    },
    {
      title: 'condition',
      desc: condition,
    },
    {
      title: 'delivery',
      desc: delivery,
    },
    {
      title: 'negotiable',
      desc: negotiable,
    },
  ];

  const divRef = useRef();

  function calculateCharacters(lineHeight: number): number {
    const divElement = divRef?.current;
    if (!!divElement) {
      const divHeight = divElement?.offsetHeight;
      const divWidth = divElement?.offsetWidth;
      let fontSize = parseFloat(window.getComputedStyle(divElement).fontSize);

      if (!fontSize || isNaN(fontSize)) {
        // Handle invalid font size gracefully
        fontSize = 14;
      }

      // Calculate maximum number of lines
      const maxLines = Math.floor(divHeight / (fontSize * lineHeight));

      // Average width of a character (approximation)
      const averageCharWidth = fontSize * 0.6; // You may need to adjust this value based on the font and its characteristics

      // Calculate maximum number of characters per line
      const maxCharsPerLine = Math.floor(divWidth / averageCharWidth);

      // Calculate total number of characters
      const totalChars = maxLines * maxCharsPerLine;

      return totalChars;
    }
    return 100;
  }

  return (
    <>
      <div style={{ marginBottom: '80px' }}>
        <div ref={divRef}>
          <Typography variant="p">{productInfo.desc}</Typography>
        </div>
        <br />
        {/* <Button variant="ghost" style={{ color: '#2061F8', padding: '0px' }}>
          ReadMore
        </Button> */}
      </div>
      <Typography variant="h5">Product Details</Typography>
      <FeatureCard featureData={descData} />
      <Specification specification={productInfo?.specification} />
    </>
  );
};

export default Description;
