import { ReturnType } from "./types";

export default {
  getNotifications(): ReturnType {
    return {
      method: "get",
      url: "/v1/auth/notifications",
      config: {
        store: {
          action: "set",
          key: "notifications",
        },
      },
    };
  },

  updateNotification(data: any, id: number): ReturnType {
    return {
      method: "patch",
      url: `/v1/auth/notifications/${id}`,
      data: data,
    };
  },

  getMyAppointments(): ReturnType {
    return {
      method: "get",
      url: `/v1/common/appointments`,
      config: {
        store: {
          action: "set",
          key: "myAppointments",
        },
      },
    };
  },

  createReminder(data: any): ReturnType {
    return {
      method: "post",
      url: `/v1/common/reminder`,
      data: data,
      config: {
        successMsg: `Reminder Create successfully`,
        navigateBack: true,
      },
    };
  },

  deleteReminder(id?: number): ReturnType {
    return {
      method: "delete",
      url: `/v1/common/reminders/${id}`,
    };
  },

  getReminder(): ReturnType {
    return {
      method: "get",
      url: `/v1/common/reminders`,
      config: {
        store: {
          action: "set",
          key: "reminders",
        },
      },
    };
  },
  readAllNotifications(data: any): ReturnType {
    return {
      method: "post",
      url: `/v1/common/notifications/read`,
      data: data,
    };
  },
};
