import Button from '@/newComponents/Button';
import Typography from '@/newComponents/Typography';
import { devices } from '@/utils/theme';
import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
`;

export const Title = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  padding-bottom: 6px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;

  @media ${devices.mobileXS} {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 12px;
    margin-bottom: 18px;
    line-height: normal;
  }

  @media ${devices.laptop} {
    font-size: 18px;
    margin-bottom: 30px;
    padding-bottom: 18px;
  }
`;

export const StyledButton = styled(Button)`
  font-size: 12px;
  line-height: 25px;
`;

export const StyledLabel = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 8px;
`;

export const SaveButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
`;
