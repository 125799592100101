import React, { useState } from 'react';
import styled from 'styled-components';
import Typography from './Typography';
import Button from './Button';
import EditIcon from './assets/Edit_light.svg';
import { devices } from '../utils/theme';
import { useNavigate } from 'react-router-dom';
import ImageWithFallBack from './ImageWithFallBack';
import moment from 'moment';

interface ProductLargeCardProps extends React.ComponentPropsWithoutRef<'div'> {
  style?: React.CSSProperties;
  address: string;
  title: string;
  isActive?: boolean;
  productId?: number;
  imageURL: string;
  productName?: string;
  onEdit?: () => {};
  onClick?: () => {};
  createdAt?: string;
  tag: { label: string; color: string };
}

const ProductLargeCard = ({
  address,
  productId,
  isActive,
  title,
  imageURL,
  productName,
  tag,
  createdAt,
  ...restProps
}: ProductLargeCardProps) => {
  const navigate = useNavigate();
  const editProduct = (e: any) => {
    e.stopPropagation();
    navigate(`/mymarketplace/edit/${productId}`);
  };

  const viewProduct = () => {
    navigate(`/mymarketplace/${productId}`);
  };

  return (
    <ProductCardContainer {...restProps} onClick={viewProduct}>
      <div className="img-wrapper">
        <ImageWithFallBack src={imageURL} alt={address} />
      </div>
      <Wrapper>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            width: '100%',
          }}
        >
          <Typography
            variant="h3"
            color="#1F1F1F"
            fontFamily="Poppins"
            style={{
              marginBottom: '20px',
              fontWeight: 'bold',
              fontStyle: 'italic',
              fontSize: '18px',
            }}
          >
            {title}
          </Typography>
          <Address>{address}</Address>
          {!!createdAt && (
            <Date>{moment(createdAt).format('yyyy, MMM DD hh:mm A')}</Date>
          )}

          <ButtonWrapper>
            <Button
              bgColor={tag.color}
              onClick={() => {}}
              radius="round"
              size="sm"
              variant="tag"
              style={{ width: '60px' }}
            >
              {tag.label}
            </Button>

            <Button
              variant="gray"
              size="md"
              radius="round"
              onClick={editProduct}
            >
              <img
                src={EditIcon}
                alt="editicon"
                style={{ width: '20px', height: '20px' }}
              />
              Edit
            </Button>
          </ButtonWrapper>
        </div>
      </Wrapper>
    </ProductCardContainer>
  );
};

export default ProductLargeCard;

const ProductCardContainer = styled.div`
  display: flex;
  font-family: Poppins;
  cursor: pointer;
  gap: 20px;
  & .img-wrapper {
    height: 153px;
    width: 270px;
    position: relative;
    @media only screen and (max-width: 420px) {
      width: 100%;
    }
  }

  @media only screen and (max-width: 420px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0;
  }
`;

const Image = styled.img``;
const Address = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #949494;
  font-family: Poppins;
  margin: 0px;
  @media only screen and (max-width: 340px) {
    max-width: 100%;
  }
`;
const Date = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #949494;
  font-family: Poppins;
  @media only screen and (max-width: 340px) {
    max-width: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  justify-content: space-between;
  line-height: 0px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 420px) {
    gap: 30px;
    align-items: center;
    width: 100%;
  }
  @media only screen and (max-width: 340px) {
    display: flex;
    gap: 30px;
    align-items: center;
    width: 100%;
  }
`;
