import Error from '@/components/Error';
import InputField from '@/components/InputField';
import { useResponsive } from '@/hooks/useResponsive';
import Button from '@/newComponents/Button';
import Modal from '@/newComponents/Modal';
import Typography from '@/newComponents/Typography';
import { Apis } from '@shared/front';
import { request } from '@shared/front/utils';
import TextField from '@shared/web/components/TextField';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import * as yup from 'yup';

type Props = {
  open: boolean;
  propertyId?: number;
  isEditMode?: boolean;
  dataToEdit?: any;
  itineraryCategoryId?: number;
  onClose: () => void;
  inventory: any;
};

const addRoomItinerarySchema = yup.object().shape({
  title: yup.string().required('Title is required.'),
  quantity: yup.string().required('Quantity is required.'),
  desc: yup.string().max(300).required('Description is required.'),
});

const Initial_Values = {
  title: '',
  quantity: 1,
  desc: '',
};

const RoomsItemsModal = ({
  open,
  onClose,
  inventory,
  propertyId,
  isEditMode = false,
  itineraryCategoryId,
  dataToEdit,
}: Props) => {
  const { isTablet } = useResponsive();
  const [loading, setLoading] = useState(false);
  const handleOnClose = () => {
    onClose();
  };

  const handleAddPropertyItinerary = async (values: any) => {
    const response = await request(
      Apis.Properties.createPropertyItinerary({
        ...values,
        propertyId,
        itineraryCategoryId,
      }),
    );

    if (response) {
      toast.success('Items added to inventory.');
      handleOnClose();
    }
  };

  const handleEditPropertyItinerary = async (values: any) => {
    const response = await request(
      Apis.Properties.updatePropertyItineries(
        {
          ...values,
          itineraryCategoryId,
        },
        dataToEdit?.id,
      ),
    );

    if (response) {
      toast.success('Items added to inventory.');
      handleOnClose();
    }
  };

  return (
    <Modal
      onClose={handleOnClose}
      open={open}
      title={`Add ${inventory?.title} Itineries Items`}
      style={{ width: !isTablet ? '95vw' : '60vw' }}
    >
      <Formik
        initialValues={{
          title: isEditMode ? dataToEdit?.title : '',
          quantity: isEditMode ? dataToEdit?.quantity : 1,
          desc: isEditMode ? dataToEdit?.desc : '',
        }}
        validationSchema={addRoomItinerarySchema}
        onSubmit={async (values, { resetForm }) => {
          if (isEditMode) {
            handleEditPropertyItinerary(values);
          } else {
            await handleAddPropertyItinerary(values);
          }
          resetForm();
        }}
        validateOnBlur={false}
        enableReinitialize
      >
        {({ values, errors, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <div style={{}}>
              <Field
                component={InputField}
                label="Title"
                name="title"
                type="text"
                placeholder="Title"
                value={values?.title}
              />
            </div>
            <Field
              component={InputField}
              label="Quantity"
              name="quantity"
              type="number"
              placeholder="Quantity"
              value={values?.quantity}
            />
            <Field
              component={TextField}
              label="Description"
              name="desc"
              placeholder="Description"
              value={values?.desc}
              maxLength={300}
            />
            <Typography
              variant="p"
              color="#666"
              size="sm"
              style={{ textAlign: 'right' }}
            >{`${values?.desc?.length}/300`}</Typography>
            <ModalFooter>
              <Button isLoading={loading} style={{ marginLeft: 'auto' }}>
                Submit
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default RoomsItemsModal;

export const ModalFooter = styled.div`
  display: flex;
  margin-top: 20px;
`;
