import React from 'react';

type Props = {};

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px 20px;
`;
const Content = styled.div`
  max-width: 900px;

  h2 {
    font-size: 18px;
  }

  li {
    margin: 8px 0;
    line-height: 20px;
  }
`;

const TermsAndPrivacy = (props: Props) => {
  return (
    <Container>
      <Content>
        <h1>Privacy Policy for Rentisity</h1>
        <p>
          <strong>Effective Date:</strong> 2024-02-14
          <br />
          <strong>Last Updated:</strong> 2024-04-30
        </p>
        <p>
          Thank you for choosing Rentisity. This Privacy Policy explains how we
          collect, use, disclose, and safeguard your information when you use
          our mobile application (the “App”) or website, including any online
          services offered by Rentisity (collectively, the “Service”). Please
          read this Privacy Policy carefully. If you do not agree with the terms
          of this Privacy Policy, please do not access the App.
        </p>

        <h2>Information We Collect</h2>
        <p>We collect information from you when you use our App, including:</p>
        <ul>
          <li>
            <strong>Information You Provide</strong>
            <ul>
              <li>
                Account Registration Information: When you create an account, we
                may collect your email address, phone number, and verification
                ID to authenticate and manage your account.
              </li>
              <li>
                Property Information: Landlords can add property details
                including images and descriptions.
              </li>
              <li>
                Payment Information: When you make payments through the App, we
                collect payment card information securely through our payment
                processor, Stripe.
              </li>
              <li>
                Communications: We may collect information from your
                communications with us, such as emails or messages sent through
                the App.
              </li>
              <li>
                Feedback: If you provide feedback or participate in surveys, we
                may collect and store that information.
              </li>
            </ul>
          </li>
          <li>
            <strong>Information We Collect Automatically</strong>
            <ul>
              <li>
                Location Information: We may collect your precise location data
                when you use our App to provide location-based services.
              </li>
              <li>
                Device Information: We collect information about your device,
                including device type, operating system, and unique device
                identifiers.
              </li>
              <li>
                Usage Information: We collect information about your
                interactions with the App, such as the features you use, pages
                you visit, and actions you take.
              </li>
              <li>
                Cookies and Similar Technologies: We use cookies and similar
                technologies to enhance your experience, analyze usage patterns,
                and personalize content.
              </li>
            </ul>
          </li>
        </ul>

        <h2>How We Use Your Information</h2>
        <p>We may use the information we collect from you to:</p>
        <ul>
          <li>Provide, personalize, and improve our Service.</li>
          <li>
            Facilitate transactions and communicate with you regarding your
            account, property listings, bookings, and payments.
          </li>
          <li>
            Send you important notifications, updates, and promotional materials
            based on your communication preferences.
          </li>
          <li>
            Analyze usage patterns, conduct research, and perform analytics to
            understand and improve our services.
          </li>
          <li>
            Respond to your inquiries, requests, and provide customer support.
          </li>
          <li>
            Comply with legal obligations and enforce our terms and policies.
          </li>
        </ul>

        <h2>How We Share Your Information</h2>
        <p>We may share your information with:</p>
        <ul>
          <li>
            Service Providers: We may share your information with third-party
            service providers who assist us in providing the Service, such as
            hosting, payment processing, analytics, and marketing.
          </li>
          <li>
            Other Users: Depending on your interactions with the Service,
            certain information may be shared with other users, such as
            landlords and tenants.
          </li>
          <li>
            Legal Compliance: We may disclose your information when required by
            law or to protect our rights, property, or safety or that of others.
          </li>
        </ul>

        <h2>Your Choices</h2>
        <p>
          You can access and update your account information through the App.
          You may also opt-out of certain data collection and processing
          features, but this may limit your ability to use certain features of
          the App.
        </p>

        <h2>Data Deletion</h2>
        <p>
          You can request the deletion of your account and associated data
          directly through the settings of the App. However, please note that
          certain data may be retained for specific purposes even after account
          deletion, as outlined below:
        </p>
        <ol>
          <li>Outstanding Balances</li>
          <li>Payments to Another Person (e.g., Landlord)</li>
          <li>Unfinished Work Contracts or Services</li>
          <li>Legal and Regulatory Requirements</li>
        </ol>
        <p>
          <strong>Requesting Data Deletion:</strong> For any questions or
          assistance regarding data deletion and retention, please contact us at{' '}
          <a href="mailto:info@rentisity.com">info@rentisity.com</a>.
        </p>

        <h2>Security Measures</h2>
        <p>
          We implement industry-standard security measures to protect your
          information from unauthorized access, use, or disclosure.
        </p>

        <h2>Data Retention</h2>
        <p>
          We retain your information for as long as necessary to fulfill the
          purposes outlined in this Privacy Policy, unless a longer retention
          period is required or permitted by law.
        </p>

        <h2>Children’s Privacy</h2>
        <p>
          Our Service is not directed to individuals under the age of 18. If you
          become aware that your child has provided us with personal information
          without your consent, please contact us, and we will take steps to
          remove such information and terminate the child's account.
        </p>

        <h2>Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy periodically to reflect changes in
          our practices and Service. We will notify you of any material changes
          by posting the new Privacy Policy on this page.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy or our
          privacy practices, please contact us at{' '}
          <a href="mailto:info@rentisity.com">info@rentisity.com</a>.
        </p>
      </Content>
    </Container>
  );
};

export default TermsAndPrivacy;
