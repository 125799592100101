import { useRoot } from '@/RootProvider';
import { optionTypes } from '@/containers/Bookings';
import useAPI from '@/hooks/api';
import Divider from '@/newComponents/Divider';
import Tabs from '@/newComponents/Tabs';
import Typography from '@/newComponents/Typography';
import LoadingPlaceholder from '@shared/web/components/LoadingPlaceholder';
import { Row } from '@shared/web/components/common';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import EmptyPage from '../Empty';
import JobContractItem from './JobContractItem';

const HeaderDivider = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  margin: 20px 0px;
`;
const Container = styled.div`
  padding: 20px;
`;
const OrderTableView = () => {
  const [getMyPropertiesRequest, { data: contracts, loading }] = useAPI();
  const { auth } = useRoot();

  const [params, setParams] = useState({
    page: 0,
    limit: 100,
    filter: 'active',
    type: 'active',
  });

  const items = [
    {
      key: 'active',
      label: `Active`,
    },
    {
      key: 'Completed',
      label: `Hired`,
    },
  ];

  const onChange = (key: string) => {
    setParams({ ...params, type: key });
  };
  const handleRequest = tempParams => {
    setParams(tempParams);
    getMyPropertiesRequest({
      method: 'get',
      url: '/v1/services/proposals',
      params: tempParams,
    });
  };

  useEffect(() => {
    handleRequest(params);
  }, [params]);

  return (
    <Container>
      <Row
        align="middle"
        justify="space-between"
        style={{
          marginBottom: 20,
        }}
      >
        <Typography size="md">Job Proposals</Typography>
        {/* 
        <PopOvers options={options} /> */}
      </Row>
      <HeaderDivider />
      {/* <Tabs
        defaultActiveKey="active"
        onChange={key => {
          let tempParams = {
            ...params,
            filter: key,
            page: 0,
          };
          handleRequest(tempParams);
        }}
      >
        <Tabs.items tab="All" key="all"></Tabs.items>
        <Tabs.items tab="Active" key="active"></Tabs.items>
        <Tabs.items tab="Completed" key="completed"></Tabs.items>
        <Tabs.items tab="Cancelled" key="cancelled"></Tabs.items>
      </Tabs> */}
      <Tabs activeKey={params.type} items={items} onChange={onChange} />
      {!!loading && <LoadingPlaceholder />}
      {!loading && contracts?.length === 0 && <EmptyPage />}
      {!loading &&
        contracts?.length > 0 &&
        contracts.map(contract => (
          <>
            <JobContractItem
              key={contract.id}
              contract={contract}
              refreshData={() => handleRequest(params)}
            />
            <Divider />
          </>
        ))}
    </Container>
  );
};
export default OrderTableView;

const options: optionTypes = {
  featureoption: [
    {
      data: [
        {
          value: 'all',
          label: 'All',
        },
        {
          value: 'hired',
          label: 'Hired',
        },
        {
          value: 'withdrawn',
          label: 'Withdrawn',
        },
      ],
      placeholder: 'Proposal Status',
      key: 'proposalStatus',
    },
  ],

  sortingoption: [
    {
      data: [
        {
          value: 'Price High to Low',
          label: 'Price High to Low',
        },
        {
          value: 'Price Low to High',
          label: 'Price Low to High',
        },
      ],
      placeholder: 'Price Status',
      key: 'priceStatus',
    },
  ],
};
