import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled, { useTheme } from 'styled-components';
import Button from './Button';
import DropdownMenu from './DropdownMenu';
import Modal from './Modal';
import SearchFilterInput from './SearchFilterInput';
import Typography from './Typography';
import FilterIcon from './assets/Filter_alt.svg';
import { devices } from '@/utils/theme';

interface ISelectItems {
  id: number | string;
  label: string;
  subItems: {
    id: number | string;
    label: string;
  }[];
}

interface IFilterWithMenuProps {
  title: string;
  searchPlaceholder: string;
  onSelectItem: (data: any) => void;
  onSelectCategory: (data: any) => void;
  browserAll: () => void;
  items: ISelectItems[];
  setParams: any;
  params: any;
  hasMore: any;
  setHasMore: any;
}

const Container = styled.div<{ isTablet: boolean }>`
  display: flex;
  flex-direction: column;
  // gap: 30px;
  position: sticky;
  top: 60px;
  height: 100%;
  z-index: 99;
  background: #fff;
  padding: 10px 0px 5px;
  width: 100%;

  @media (min-width: 1000px) {
    width: 260px;
    padding: 20px 0px 5px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 8px;
  margin-bottom: 8px;
`;

const Title = styled(Typography)`
  font-size: 16px;
`;

const BrowseAllButton = styled(Button)`
  color: #2061f8;
  padding: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 25px;
  height: auto;
`;

const SearchProductWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 30px;
`;

const ModalWrapper = styled.div``;
const FilterWithMenu = ({
  title,
  searchPlaceholder,
  items,
  onSelectItem,
  browserAll,
  onSelectCategory,
  setParams,
  params,
  hasMore,
  setHasMore,
}: IFilterWithMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalState, setModalState] = useState({
    categoryId: '',
    subCategoryId: '',
    item: {},
    subItem: {},
  });
  const [expandedItem, setExpandedItem] = useState<string | number | null>(
    null,
  );
  const theme = useTheme();

  // const { isTablet } = useResponsive();
  const isLessThanThousand = useMediaQuery({ query: '(min-width:1000px)' });
  const onClose = () => {
    setIsOpen(false);
  };
  const handleChange = searchProduct => {
    setParams(prev => ({ ...prev, title: searchProduct }));
  };
  const debouncedResults = useMemo(() => {
    return debounce(value => handleChange(value), 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  return (
    <>
      <Container isTablet={isLessThanThousand}>
        <SearchProductWrapper>
          <SearchFilterInput
            inputType="search"
            placeholder={searchPlaceholder}
            onChange={e => {
              debouncedResults(e.target.value);
            }}
            onFilter={() => {}}
            onCancel={() => {}}
            style={{
              width: !isLessThanThousand ? '70%' : '100%',
              // alignSelf: !isLessThanThousand ? 'center' : 'auto',
            }}
          />
          {!isLessThanThousand && (
            <Button
              onClick={() => setIsOpen(true)}
              size="sm"
              bgColor={theme?.colors?.primary}
            >
              <img src={FilterIcon} alt="Filter icon" />
            </Button>
          )}
        </SearchProductWrapper>

        {isLessThanThousand && (
          <>
            <TitleContainer>
              <Title variant="p">{title}</Title>
              <BrowseAllButton
                variant="ghost"
                onClick={() => {
                  browserAll();
                  setExpandedItem(null);
                }}
              >
                Browse All
              </BrowseAllButton>
            </TitleContainer>
            <DropdownMenu
              items={items}
              onItemClick={(data: any) => {
                if (data.hasOwnProperty('subItem')) {
                  onSelectItem(data.subItem);
                }
                if (data.hasOwnProperty('item')) {
                  onSelectCategory(data.item);
                }
              }}
              params={params}
              modalState={modalState}
              setModalState={setModalState}
              setParams={setParams}
              expandedItem={expandedItem}
              setExpandedItem={setExpandedItem}
            />
          </>
        )}
      </Container>
      <Modal open={isOpen} onClose={onClose} size={'lg'}>
        <Wrapper>
          <CategoryWrapper>
            <TitleContainer>
              <Typography
                variant="h3"
                size="md"
                fontWeight={'bold'}
                style={{ margin: '12px 0px' }}
              >
                Category
              </Typography>
              <BrowseAllButton
                variant="ghost"
                onClick={() => {
                  browserAll();
                  setExpandedItem(null);
                  onClose();
                }}
              >
                Browse All
              </BrowseAllButton>
            </TitleContainer>
            <DropdownMenu
              items={items}
              onItemClick={onSelectItem}
              isModal
              modalState={modalState}
              setModalState={setModalState}
              params={params}
              setParams={setParams}
              expandedItem={expandedItem}
              setExpandedItem={setExpandedItem}
              style={{ width: '100%' }}
              hasMore={hasMore}
              setHasMore={setHasMore}
            />
          </CategoryWrapper>
          {/* <FilterWrapper>
            <Typography
              variant="h3"
              size="md"
              fontWeight={'bold'}
              style={{ margin: '12px 0px' }}
            >
              Filter
            </Typography>
          </FilterWrapper> */}
        </Wrapper>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={() => {
              // setParams((prev: any) => ({ ...prev, ...modalState }));
              if (modalState.hasOwnProperty('subItem')) {
                onSelectItem(modalState.subItem);
              }
              if (modalState.hasOwnProperty('item')) {
                onSelectCategory(modalState.item);
              }
              onClose();
            }}
          >
            Select
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default FilterWithMenu;

const CategoryWrapper = styled.div`
  width: 100%;
`;
const FilterWrapper = styled.div``;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 9999;
`;
