import Button from '@/newComponents/Button';
import { devices } from '@/utils/theme';
import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  flex-direction: row;

  @media ${devices.laptop} {
    margin-top: 40px;
  }
  @media ${devices.mobileXS} {
    flex-direction: column;
    gap: 10px;
  }
  @media ${devices.mobileXSS} {
    flex-direction: column;
    gap: 10px;
  }
`;

export const StyledButton = styled(Button)`
  font-size: 12px;
  line-height: 25px;
  display: flex;
  flex-direction: row;
  width: 100%;

  @media ${devices.laptop} {
    font-size: 14px;
    line-height: 30px;
    width: 100%;
  }
  @media ${devices.mobileXS} {
    flex-direction: column;
    width: 50%;
  }
`;
