import { ReturnType } from "./types";
export default {
  getMyMarketProducts: (action: "update" | "set", params?: any): ReturnType => {
    return {
      method: "get",
      url: `/v1/marketPlace/my-products`,
      params: params,
      config: {
        store: {
          action: action,
          key: "marketProducts",
        },
      },
    };
  },
  getMarketProducts: (action: "update" | "set", params?: any): ReturnType => {
    return {
      method: "get",
      url: `/v1/marketPlace/search-products`,
      params: params,
      config: {
        store: {
          action: action,
          key: "marketProducts",
        },
      },
    };
  },
  getMarketProduct: (id: number): ReturnType => {
    return {
      method: "get",
      url: `/v1/marketPlace/products/${id}`,
      config: {
        store: {
          action: "set",
          key: "marketProduct",
        },
      },
    };
  },
  getMarketProductForGuest: (id: number): ReturnType => {
    return {
      method: "get",
      url: `/v1/marketPlace/guest-products/${id}`,
    };
  },
  getMarketCategory: (): ReturnType => {
    return {
      method: "get",
      url: "/v1/marketPlace/categories",
    };
  },
  getMarketSubCategory: (id: number): ReturnType => {
    return {
      method: "get",
      url: `/v1/marketPlace/categories/${id}`,
    };
  },
  createMarketProduct: (data: any): ReturnType => {
    return {
      method: "post",
      url: "/v1/marketPlace/products",
      data: data,
    };
  },
  updateMarketProduct: (data: any, id: number): ReturnType => {
    return {
      method: "patch",
      url: `/v1/marketPlace/products/${id}`,
      data: data,
    };
  },

  deleteMarketProduct: (id: number): ReturnType => {
    return {
      method: "delete",
      url: `/v1/marketPlace/products/${id}`,
      config: {
        navigateBack: true,
        successMsg: `Market Product deleted successfully`,
      },
    };
  },

  updateMarketProductImage: (data: any, id: number): ReturnType => {
    return {
      method: "post",
      url: `/v1/marketPlace/products/${id}/images`,
      data: data,
      config: {
        navigateBack: true,
      },
    };
  },
  deleteMarketProductImage: (id: number): ReturnType => {
    return {
      method: "delete",
      url: `/v1/marketPlace/products/${id}/images`,
    };
  },
  createComment: (data: any): ReturnType => {
    return {
      method: "post",
      url: `/v1/marketPlace/comments`,
      data: data,
    };
  },
  getComment: (
    productId: number,
    action?: "set" | "update",
    params?: any
  ): ReturnType => {
    return {
      method: "get",
      url: `/v1/marketPlace/comments/${productId}`,
      params: params,
      config: {
        store: {
          action: action || "set",
          key: "marketComments",
        },
      },
    };
  },

  getAllSubCategory: () => {
    return {
      method: "get",
      url: "/v1/marketPlace/sub-categories",
      config: {
        store: {
          action: "set",
          key: "allCategorySubCategory",
        },
      },
    };
  },

  getGuestMarketProductById: (id: number): ReturnType => {
    return {
      method: "get",
      url: `/v1/marketPlace/guest-products/${id}`,
      config: {
        store: {
          action: "set",
          key: "marketProduct",
        },
      },
    };
  },
};
