import Button from '@/newComponents/Button';
import Modal from '@/newComponents/Modal';
import Typography from '@/newComponents/Typography';
import { ButtonEndWrapperStyled } from '@/styles/shareableStyle';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

type Props = {
  onYes: () => void;
  onClose: () => void;
  isOpen: boolean;
  loading?: boolean;
};

const DeleteAccountConfirmation = ({
  onClose,
  onYes,
  isOpen,
  loading,
}: Props) => {
  const isMin6oo = useMediaQuery({ query: '(min-width:600px)' });

  return (
    <Modal
      onClose={onClose}
      open={isOpen}
      title="Delete Account"
      style={{ width: isMin6oo ? '600px' : '95%' }}
      isCentered
      closeOnBackdropClick={false}
    >
      <Typography>
        Are you sure you want to initiate deletion of this account?
      </Typography>
      <Typography
        style={{
          margin: '20px 0px',
          fontSize: '14px',
          color: '#666',
        }}
      >
        Note : Once your account deletion request is processed you will not be
        able to recover your account or any associated data. You will have a
        week to cancel your request, if deletion process is not initiated
        earlier.
      </Typography>
      <ButtonEndWrapperStyled>
        <Button
          onClick={onYes}
          variant="danger"
          isDisabled={loading}
          isLoading={loading}
        >
          Delete
        </Button>
        <Button
          onClick={onClose}
          variant="gray"
          isDisabled={loading}
          isLoading={loading}
        >
          Cancel
        </Button>
      </ButtonEndWrapperStyled>
    </Modal>
  );
};

export default DeleteAccountConfirmation;
