import Error from '@shared/web/components/Error';
import SelectField from '@shared/web/components/FormSelect';
import InputField from '@shared/web/components/InputField';
import { Row, Typography } from 'antd';
import { Field, Form, Formik, FormikProps } from 'formik';
import { debounce, pick } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import useAPI from '@/hooks/api';
import Button from '@/newComponents/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Apis, Utils } from '@shared/front';
import request from '@shared/front/utils/request';
import LoadingPlaceholder from '@shared/web/components/LoadingPlaceholder';
import Select from 'react-select/async';
import styled from 'styled-components';
import validation from './validation';

const data = [
  {
    label: 'Individual',
    value: 'individual',
  },
  {
    label: 'Company',
    value: 'company',
  },
];

const GOOGLE_MAPS_KEY = process.env.GOOGLE_MAPS_API || '';

const CurrentLocation = styled.button`
  margin: 14px 0px;
  background: none;
  border: none;
  color: blue;
  cursor: pointer;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  ${props =>
    props.disabled &&
    `
    opacity: 0.5;
    pointer-events: none;
  `}
`;

type Props = {
  initialValues: any;
  onSubmitStep: () => void;
  google?: any;
};

const Location = ({ google, initialValues, onSubmitStep }: Props) => {
  const [geoLoading, setGeoLoading] = useState(false);
  const formikRef = useRef<FormikProps<any>>(null);
  let [places, setPlaces] = useState([
    {
      label: 'Select',
      value: 'Select',
    },
  ]);
  const [geoAddress, setGeoAddress]: any = useState();
  const [lat, setLat]: any = useState();
  const [lng, setLng]: any = useState();

  const [handleSubmitRequest, { loading, error }] = useAPI();

  const handleCurrentLocation = async () => {
    let lat;
    let lng;

    try {
      setGeoLoading(true);
      await new Promise<void>((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            data => {
              const { latitude, longitude } = data.coords;
              lat = latitude;
              lng = longitude;
              resolve();
            },
            error => {
              reject(error);
            },
          );
        } else {
          reject(new Error('Geolocation is not supported by this browser.'));
        }
      });

      if (lat && lng) {
        const res = await Utils.request(
          Apis.Location.getAddressFromLatLng({
            latitude: lat,
            longitude: lng,
          }),
        );
        setGeoAddress({
          value: res.formattedAddress,
          label: res.formattedAddress,
        });
        formikRef.current?.setFieldValue('address', res.formattedAddress);
        // console.log('the res', res);
      }
    } catch (err) {
      console.log('handle current location error', err);
    } finally {
      setGeoLoading(false);
    }
  };

  const getLatLongFromPlaceId = async (placeId: string) => {
    try {
      const res = await request({
        method: 'get',
        url: '/v1/common/google-geocode',
        params: {
          placeId,
        },
      });
      const { lat, lng } = res?.geometry?.location;
      setLat(lat);
      setLng(lng);
      return { latitude: lat, longitude: lng };
    } catch (err) {
      console.log('error on fetching lat and long', err);
    }
  };

  const fetchPlaces = async (query: string) => {
    if (!query.trim()) {
      return [];
    }
    try {
      const res = await request({
        method: 'get',
        url: '/v1/common/google-places',
        params: {
          input: query.trim(),
        },
      });
      return res.map((place: any) => ({
        label: place.description,
        value: place.place_id,
      }));
    } catch (err) {
      console.log('error on fetching places', err);
    }
  };

  const handleSelectChange = (selectedOptions: any) => {
    if (selectedOptions) {
      const latlng = getLatLongFromPlaceId(selectedOptions?.value);
      return latlng;
    }
  };

  const debounceSearch = React.useRef(debounce(fetchPlaces, 400)).current;
  useEffect(() => {
    return () => {
      debounceSearch.cancel();
    };
  }, [debounceSearch]);

  let payload = {};
  const handleSubmitForm = async (values: any) => {
    payload = {
      ...payload,
      address: values.address.label,
      latitude: lat,
      longitude: lng,
    };
    const individualValues = pick(values, ['country', 'phone', 'address']);
    const companyValues = {
      name: values.companyName,
      website: values.website,
      address: values.address.label,
      type: initialValues.role,
      regNo: values.regNo,
    };
    await handleSubmitRequest({
      method: 'put',
      url: '/v1/auth/profile',
      data: {
        ...individualValues,
        address: values?.address.label,
        longitude: lng,
        latitude: lat,
      },
    });
    if (values.accountType === 'company') {
      await handleSubmitRequest({
        method: 'post',
        url: '/v1/auth/create-company',
        data: {
          ...companyValues,
          //  longitude: lng,
          // latitude: lat,
        },
      });
    }
    onSubmitStep();
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        ...initialValues,
        accountType: '',
        country: 'Ireland',
        address: '',
      }}
      onSubmit={values => {
        handleSubmitForm(values);
      }}
      validationSchema={validation.account}
    >
      {({
        isSubmitting,
        errors,
        touched,
        values,
        setFieldValue,
        handleSubmit,
        handleChange,
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Typography.Title level={5}>Account Details</Typography.Title>
            <Field
              component={SelectField}
              label="Account Type"
              name="accountType"
              options={data}
              setFieldValue={setFieldValue}
              width={500}
              size={'large'}
            />
            <Typography.Title
              level={5}
              style={{
                marginTop: 20,
              }}
            >
              {/* Individual Details */}
              Address
            </Typography.Title>

            {geoLoading ? (
              <LoadingPlaceholder width={50} height={50} />
            ) : (
              <Field
                name="address"
                component={Select}
                options={places}
                isClearable
                loadOptions={debounceSearch}
                value={geoAddress ? geoAddress : values.address}
                onChange={(selectedOption, { action }) => {
                  if (action === 'clear') {
                    setFieldValue('address', '');
                    setGeoAddress('');
                  } else {
                    handleSelectChange(selectedOption);
                    setFieldValue('address', selectedOption);
                    setGeoAddress('');
                  }
                }}
                onInputChange={query => debounceSearch(query)}
              />
            )}

            <CurrentLocation
              onClick={handleCurrentLocation}
              type="button"
              disabled={geoLoading}
            >
              <FontAwesomeIcon
                icon={'location'}
                size="xl"
                style={{ marginRight: '10px' }}
              />
              use Current Locations
            </CurrentLocation>

            {values?.accountType === 'company' && (
              <>
                {' '}
                <Typography.Title
                  level={5}
                  style={{
                    marginTop: 20,
                  }}
                >
                  Company Details
                </Typography.Title>
                <Field
                  component={InputField}
                  label="Legal name"
                  name="companyName"
                />
                <Field
                  component={InputField}
                  label="Email"
                  name="companyEmail"
                  type="email"
                />
              </>
            )}
            <Error message={error || errors?.address} isFormError />
            <Row justify="end">
              <Button
                onClick={() => {
                  handleSubmitForm(values);
                }}
                type="submit"
                htmlType="submit"
                block={true}
                isDisabled={loading || isSubmitting}
                isLoading={loading || isSubmitting}
              >
                Save
              </Button>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Location;
