import Typography from '@/newComponents/Typography';
import React, { useState } from 'react';
import styled from 'styled-components';
import AmenitiesModal from './AmenitiesModal';
import { SectionDescription, SectionTitle, SectionWrapper } from './style';
const FeatureWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 16px;
`;
const FeatureItem = styled.div``;
const Amenities = ({ propertyAmenities }: any) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      {propertyAmenities?.length == 0 ? (
        ''
      ) : (
        <>
          <SectionWrapper>
            <SectionTitle>Amenities</SectionTitle>
            <FeatureWrapper>
              {propertyAmenities
                ?.slice(0, 10)
                .map((propertyAmenityItem: any) => (
                  <FeatureItem key={propertyAmenityItem?.id}>
                    <SectionDescription>
                      {/* {iconAmenities.map(iconAmenityItem => {
                        if (
                          propertyAmenityItem?.amenities ===
                          iconAmenityItem.title
                        ) {
                          return (
                            <Image
                              key={iconAmenityItem.title}
                              style={{ height: 20 }}
                              src={iconAmenityItem.icon}
                            />    
                          );
                        }
                        return null;
                      })} */}
                      {propertyAmenityItem?.amenities}
                    </SectionDescription>
                  </FeatureItem>
                ))}
            </FeatureWrapper>
            <Typography
              variant="h1"
              color="blue"
              style={{ textAlign: 'center', margin: '40px 10px' }}
            >
              {propertyAmenities?.length >= 8 && (
                <span
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    color: 'blue',
                  }}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  View all Amenities
                </span>
              )}
              <AmenitiesModal
                setOpen={setOpen}
                open={open}
                propertyAmenities={propertyAmenities}
              />
            </Typography>
          </SectionWrapper>
        </>
      )}
    </>
  );
};

export default Amenities;
