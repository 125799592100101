import { RootType, useRoot } from '@/RootProvider';
import useAPI from '@/hooks/api';
import Button from '@/newComponents/Button';
import CustomRate from '@/newComponents/CustomRate';
import Input from '@/newComponents/Input';
import Toggle from '@/newComponents/Toggle';
import Typography from '@/newComponents/Typography';
import { Apis } from '@shared/front';
import Error from '@shared/web/components/Error';
import LoadingPlaceholder from '@shared/web/components/LoadingPlaceholder';
import S3presigned from '@shared/web/components/S3presigned';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import * as yup from 'yup';
import DesireList from './Desired/DesireList';
import DesireListForLandlord from './Desired/DesireListForLandlord';
import Education from './Eductaion';
import Certificate from './Eductaion/Certification';
import EmploymentHistories from './EmploymentHistory';
import ProfileSkeleton from './ProfileSkeleton';
import RentalHistories from './RentalHistory';
import DesireLists from './ServiceDesired/DesireList';
import DesireListForService from './ServiceDesired/DesireListForService';
import {
  Info,
  InfoLabel,
  ProfileActionWrapperStyled,
  SaveButtonContainer,
  StyledButton,
  StyledLabel,
  Title,
} from './index.styles';
import Modal from '@/newComponents/Modal';
import DefaultUserPNG from '@/assets/defaultuser.png';
import DeleteAccountConfirmation from '../DeleteAccount/DeleteAccountConfirmation';

const Container = styled.div`
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Avatar = styled.img`
  height: 80px;
  width: 80px;
  border-radius: 50%;
  object-fit: cover;
`;

const Header = styled.div`
  display: flex;
  gap: 20px;
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 10px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Section = styled.div`
  padding-top: 12px;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
`;

const History = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0;
`;

const HistoryImg = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 2px;
  margin-right: 20px;
`;

const STATUS_CHANGE_ALLOWED = ['tenant'];
const ADD_DESIRED_PROPERTY = ['tenant'];
const ADD_DESIRED_JOBS = ['service'];

const Profile = () => {
  // @ts-ignore
  const navigate = useNavigate();
  const { id: userId, role: paramsRole } = useParams();

  const { auth, checkAuth }: RootType = useRoot();

  const role = auth?.role;
  const [makeRequest, { data: profileData, loading: profileLoading }] =
    useAPI();
  const [isLoadingUserStatus, setIsLoadingUserStatus] = useState(false);

  const [userBio, setUserBio] = useState<{
    bio: string;
    address: string;
    avatar: string[];
  }>({
    bio: '',
    address: '',
    avatar: [],
  });
  const [isChangeProfileVisible, setChangeProfileVisible] = useState(false);
  const [handleSubmitRequest, { loading: changeRequestLoading }] = useAPI();
  const [handleUpdateBioRequest, { loading: changeBioRequestLoading }] =
    useAPI();
  const [getProfileRequest, { data: profile, loading }] = useAPI();
  const [getStatus] = useAPI();
  const [getMyBookings, { data: bookings }] = useAPI();
  const [profileStatus, setProfileStatus] = useState(false);

  const getProfileStatus = async (data: boolean) => {
    try {
      setIsLoadingUserStatus(true);
      const response = await getStatus({
        method: 'post',
        url: `/v1/auth/updateProfile`,
        data: {
          invitePermission: data,
        },
      });
    } catch (error) {
    } finally {
      setIsLoadingUserStatus(false);
    }
  };

  const handleStatusChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const status = e.target.checked;
    await getProfileStatus(!status);
    setProfileStatus(!status);
  };

  const theme = useTheme();

  const getBookings = async () => {
    await getMyBookings({
      method: 'get',
      url: `/v1/bookings`,
    });
  };

  useEffect(() => {
    getBookings();
  }, []);

  const getProfile = async () => {
    setIsLoadingUserStatus(true);
    if (userId && paramsRole) {
      const response = await makeRequest(
        Apis.Auth.getProfile(parseInt(userId, 20), paramsRole),
      );
      console.log(response, 'response data');
      if (response?.userDetails) {
        setProfileStatus(!!response?.userDetails?.invitePermission);
      }
    } else {
      const response = await makeRequest(
        Apis.Auth.getProfile(auth?.id, auth?.role),
      );
      if (response?.userDetails) {
        setProfileStatus(!!response?.userDetails?.invitePermission);
      }
    }
    setIsLoadingUserStatus(false);
  };

  useEffect(() => {
    getProfile();
  }, []);

  const handleSubmit = async (values: any) => {
    const pickBioData: {
      bio?: string;
      address?: string;
      avatar?: string;
    } = {};
    if (!!values?.bio) {
      pickBioData.bio = values.bio;
    }
    if (!!values?.address) {
      pickBioData.address = values.address;
    }
    if (!!values?.avatar && values.avatar.length > 0) {
      pickBioData.avatar = values.avatar[0];
    }

    if (Object.keys(pickBioData).length > 0) {
      await handleUpdateBioRequest({
        method: 'post',
        url: '/v1/auth/updateProfile',
        data: pickBioData,
      });
    }

    checkAuth();
    getProfile();
    setChangeProfileVisible(false);
  };

  const userDesire = profileData?.desire;

  return (
    <Container>
      <Modal
        title="Change profile"
        open={isChangeProfileVisible}
        onClose={() => setChangeProfileVisible(false)}
      >
        <Formik
          initialValues={userBio}
          enableReinitialize
          onSubmit={handleSubmit}
          validationSchema={yup.object().shape({
            avatar: yup.mixed().required('Please upload profile picture.'),
            bio: yup.string(),
            address: yup.string().required('Address is required.'),
          })}
        >
          {({ isSubmitting, errors, values, setFieldValue }) => {
            console.log('teh values ate', values.avatar[0]);
            return (
              <Form>
                <div>
                  <StyledLabel>Avatar</StyledLabel>
                  <Field
                    name="avatar"
                    component={S3presigned}
                    isMultiple={false}
                  />
                  <Error message={errors.avatar} />
                </div>
                <Field
                  name="bio"
                  component={Input}
                  value={values.bio}
                  type="textarea"
                  label="Bio"
                  onChange={e => setFieldValue('bio', e.target.value)}
                  error={errors.bio}
                  style={{
                    minHeight: '100px',
                  }}
                />
                <Field
                  name="address"
                  component={Input}
                  label="Address"
                  error={errors.address}
                  value={values.address}
                  onChange={e => setFieldValue('address', e.target.value)}
                />
                <SaveButtonContainer>
                  <StyledButton
                    htmlType="submit"
                    radius="round"
                    disabled={
                      !values?.avatar || isSubmitting || changeRequestLoading
                    }
                    loading={changeRequestLoading || isSubmitting}
                  >
                    Change
                  </StyledButton>
                </SaveButtonContainer>
              </Form>
            );
          }}
        </Formik>
      </Modal>

      {profileLoading ? (
        <ProfileSkeleton />
      ) : (
        <>
          <Avatar
            src={
              profileData?.avatar ||
              profileData?.userDetails?.avatar ||
              DefaultUserPNG
            }
          />
          <DetailContainer>
            <div>
              <TitleContainer>
                <Title variant="p">
                  {profileData?.fullName || profileData?.userDetails?.fullName}
                </Title>
                <Button
                  variant="tag"
                  radius="round"
                  bgColor={theme?.colors?.primary}
                >
                  {profileData?.role?.toUpperCase() ||
                    profileData?.userDetails?.role.toUpperCase()}
                </Button>
              </TitleContainer>
              <InfoContainer>
                <Info variant="p">
                  {profileData?.address || profileData?.userDetails?.address}
                </Info>
                <Info variant="p">
                  {profileData?.email || profileData?.userDetails?.email}
                </Info>
                <Info variant="p">
                  +
                  {profileData?.countryCode ||
                    profileData?.userDetails?.countryCode}{' '}
                  &nbsp;
                  {profileData?.phone || profileData?.userDetails?.phone}
                </Info>
                {!!(
                  profileData?.website || profileData?.userDetails?.website
                ) && (
                  <Info variant="p">
                    {profileData?.website || profileData?.userDetails?.website}
                  </Info>
                )}

                <CustomRate
                  value={
                    profileData?.rating || profileData?.userDetails?.rating
                  }
                  disabled
                />
              </InfoContainer>
            </div>
            {!userId && (
              <ProfileActionWrapperStyled>
                <StyledButton
                  variant="gray"
                  onClick={() => {
                    setChangeProfileVisible(true);
                    setUserBio({
                      address: profileData?.userDetails?.address,
                      avatar: !!profileData?.userDetails?.avatar
                        ? [profileData?.userDetails?.avatar]
                        : [],
                      bio: profileData?.userDetails?.bio,
                    });
                  }}
                >
                  Change profile & bio
                </StyledButton>
                <StyledButton
                  variant="danger"
                  onClick={() => navigate('/profile/delete')}
                >
                  Delete Account
                </StyledButton>
              </ProfileActionWrapperStyled>
            )}
          </DetailContainer>
        </>
      )}

      {!userId && STATUS_CHANGE_ALLOWED?.includes(role) && (
        <Section>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div>
              <Typography variant="p">
                {!profileStatus
                  ? 'Make Profile Public'
                  : 'Make Profile Private'}
              </Typography>
              <Typography variant="p" size="xsm">
                {!profileStatus ? 'Profile is private' : 'Profile is public'}
              </Typography>
            </div>
            {isLoadingUserStatus ? (
              <div>
                <LoadingPlaceholder height={48} width={48} />
              </div>
            ) : (
              <Toggle value={!profileStatus} onChange={handleStatusChange} />
            )}
          </div>
        </Section>
      )}

      {!userId && ADD_DESIRED_PROPERTY?.includes(role) && (
        <Section>
          <DesireList />
        </Section>
      )}

      {role === 'landlord' &&
        profileData?.userDetails?.role === 'tenant' &&
        profileData?.userDetails?.desire && (
          <Section>
            <DesireListForLandlord
              userDesired={profileData?.userDetails?.desire}
            />
          </Section>
        )}

      {!userId && ADD_DESIRED_JOBS?.includes(role) && (
        <Section>
          <DesireLists />
        </Section>
      )}

      {['landlord', 'tenant'].includes(role) &&
        profileData?.userDetails?.role === 'service' &&
        profileData?.userDetails?.desire && (
          <Section>
            <DesireListForService
              userDesired={profileData?.userDetails?.desire}
            />
          </Section>
        )}

      <Section>
        <Title variant="p">Bio</Title>
        <Info variant="p">{profileData?.userDetails?.bio}</Info>
      </Section>

      <Section>
        <Education
          educationalData={profileData?.userDetails?.educationHistories}
          profileId={userId}
          profileRole={profileData?.userDetails?.role}
        />
      </Section>

      {(userId && paramsRole === 'tenant') ||
        (!userId && role === 'tenant' && (
          <>
            {/* Tenant Rental Histories */}
            <Section>
              <RentalHistories
                rentalData={profileData?.userDetails?.rentalHistories}
                profileId={userId}
                role={role}
              />
            </Section>

            {/* Tenant Employment Histories */}
            <Section>
              <EmploymentHistories
                employerData={profileData?.userDetails?.employmentHistories}
                profileId={userId}
                role={role}
              />
            </Section>

            {/* Tenant Booking Histories */}
            <Section>
              <Title variant="p">Booking History</Title>
              {bookings?.data?.map(booking => (
                <History>
                  <HistoryImg src={booking?.property?.images?.[0]?.URL} />
                  <div>
                    <InfoLabel variant="p">
                      {booking?.property?.address}
                    </InfoLabel>
                    <InfoLabel variant="p">
                      {booking?.property?.country}
                    </InfoLabel>
                    <div>
                      <CustomRate
                        value={
                          booking?.reviews?.find(
                            item => item.reviewedId === profile?.id,
                          )?.rating
                        }
                        disabled
                      />
                      <Typography variant="p" style={{ display: 'block' }}>
                        {
                          booking?.reviews?.find(
                            item => item.revieweeId === profile?.id,
                          )?.review
                        }
                      </Typography>
                    </div>
                  </div>
                </History>
              ))}
            </Section>
          </>
        ))}

      {/* <div
        style={{
          margin: 20,
        }}
      >
        <ShowMoreText
          style={{
            marginTop: 30,
          }}
        >
          <Typography.Text>{profile?.bio}</Typography.Text>
        </ShowMoreText>

        {!!profile?.categories?.length && (
          <Row>
            {profile?.categories?.map?.(category => (
              <>
                <Tag key={category} type={category}></Tag>
              </>
            ))}
          </Row>
        )}
      </div> */}
      {/* <Section>
        {(userId && paramsRole === 'tenant') ||
        (!userId && role === 'tenant') ? (
          <Typography
            variant="p"
            fontWeight="bold"
            style={{ fontSize: '16px' }}
          >
            Booking History
          </Typography>
        ) : (
          ''
        )}
        {(userId && paramsRole === 'tenant') || (!userId && role === 'tenant')
          ? bookings?.data?.map(booking => (
              <History>
                <HistoryImg src={booking?.property?.images?.[0]?.URL} />
                <div>
                  <Typography variant="p" style={{ margin: 0 }}>
                    {booking?.property?.address}
                  </Typography>
                  <div>
                    <Typography variant="p">
                      {booking?.property?.country}
                    </Typography>
                  </div>
                  <div>
                    <Rate
                      value={
                        booking?.reviews?.find(
                          item => item.revieweeId === profile?.id,
                        )?.rating
                      }
                      disabled
                    />
                    <Typography variant="p" style={{ display: 'block' }}>
                      {
                        booking?.reviews?.find(
                          item => item.revieweeId === profile?.id,
                        )?.review
                      }
                    </Typography>
                  </div>
                </div>
              </History>
            ))
          : ''}
      </Section> */}
      {/* <Section>
        <Typography.Title level={5}>Job Contract History</Typography.Title>
        {profile?.contracts?.map(contract => (
          <History>
            <div>
              <Typography.Title level={5} style={{ margin: 0 }}>
                {contract?.service?.title}
              </Typography.Title>
              <div>
                <Typography.Text>
                  €{numeral(contract?.price).format('0,0')}
                </Typography.Text>
              </div>
              <div>
                <Rate
                  value={
                    contract?.reviews?.find(
                      item => item.revieweeId === profile?.id,
                    )?.rating
                  }
                  disabled
                />
                <Typography.Text type="secondary" style={{ display: 'block' }}>
                  {
                    contract?.reviews?.find(
                      item => item.revieweeId === profile?.id,
                    )?.review
                  }
                </Typography.Text>
              </div>
            </div>
          </History>
        ))}
      </Section> */}

      {role === 'service' && (
        <Section>
          <Certificate />
        </Section>
      )}
    </Container>
  );
};

export default Profile;

const ToggleContainer = styled.div`
  display: flex;

  gap: 50px;
`;
