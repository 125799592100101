import { devices } from '@/utils/theme';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import NotSupportedIMG from '../assets/ImageNotSupported-noText.svg';
import NotFoundIMG from '../assets/ImageNotFound-noText.svg';

interface ImageWithDefaultProps extends React.ComponentPropsWithoutRef<'img'> {
  src: string;
  fallbackSrc?: string;
  errorSrc?: string;
  alt?: string;
  className?: string;
}

const defaultImage =
  'https://images.pexels.com/photos/106399/pexels-photo-106399.jpeg?cs=srgb&dl=pexels-binyamin-mellish-106399.jpg&fm=jpg';

const brokenImagePLaceholder =
  'https://via.placeholder.com/500?text=Image+Not+Supported';

const noImagePLaceholder =
  'https://via.placeholder.com/500?text=Image+Not+Found';

const ImageWithFallBack: React.FC<ImageWithDefaultProps> = ({
  src,
  fallbackSrc = NotFoundIMG,
  errorSrc = NotSupportedIMG,
  alt,
  className,
  ...restProps
}) => {
  const [imageSrc, setImageSrc] = useState(src);
  const [imageLoadingError, setImageLoadingError] = useState(false);

  useEffect(() => {
    setImageSrc(src);
  }, [src]);

  const handleImageError = () => {
    setImageSrc(errorSrc);
    setImageLoadingError(true);
  };

  if (!imageSrc) {
    return (
      <StyledImage
        src={fallbackSrc}
        onError={handleImageError}
        alt={alt}
        className={`${className} noImage`}
        loading="lazy"
        {...restProps}
      />
    );
  }
  return (
    <StyledImage
      src={imageSrc}
      onError={handleImageError}
      alt={alt}
      className={`${className} ${imageLoadingError ? 'noImage' : ''}`}
      loading="lazy"
      {...restProps}
    />
  );
};

const StyledImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  filter: blur(0px); /* Initial blur effect */
  animation: fadeIn 1.5s ease-in-out;
  background: #6663;

  &.noImage {
    object-fit: contain;
  }

  @keyframes fadeIn {
    from {
      filter: blur(10px);
      opacity: 0;
    }
    to {
      filter: blur(0);
      opacity: 1;
    }
  }
`;

export default ImageWithFallBack;
