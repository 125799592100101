import { useResponsive } from '@/hooks/useResponsive';
import SkeletonLoading from '@/newComponents/Skeleton';
import React from 'react';
import styled from 'styled-components';
const JobsSkeleton = ({ ...rest }) => {
  const { isTablet } = useResponsive();
  return (
    <Wrapper>
      {[1, 2, 3, 4, 5].map(item => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              background: '#fafafa',
              marginBottom: '20px',
              padding: '20px 6px',
            }}
          >
            <div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  minWidth: '300px',
                  justifyContent: 'space-between',
                  marginBottom: '12px',
                }}
              >
                <SkeletonLoading
                  height={30}
                  width={200}
                  borderRadius={10}
                  style={{ marginRight: '20px' }}
                />

                <SkeletonLoading
                  height={25}
                  width={70}
                  borderRadius={10}
                  style={{ marginRight: '20px' }}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                  marginBottom: '20px',
                }}
              >
                <SkeletonLoading
                  height={20}
                  width={300}
                  borderRadius={10}
                  style={{ marginRight: '20px' }}
                />
                <SkeletonLoading
                  height={20}
                  width={280}
                  borderRadius={10}
                  style={{ marginRight: '20px' }}
                />

                <SkeletonLoading
                  height={20}
                  width={300}
                  borderRadius={10}
                  style={{ marginRight: '20px' }}
                />
                <SkeletonLoading
                  height={25}
                  width={290}
                  borderRadius={10}
                  style={{ marginRight: '20px' }}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  gap: '8px',
                  marginTop: '30px',
                }}
              >
                <SkeletonLoading
                  height={20}
                  width={100}
                  borderRadius={10}
                  style={{ marginRight: '20px' }}
                />
                <SkeletonLoading
                  height={20}
                  width={100}
                  borderRadius={10}
                  style={{ marginRight: '20px' }}
                />
                <SkeletonLoading
                  height={25}
                  width={100}
                  borderRadius={10}
                  style={{ marginRight: '20px' }}
                />
              </div>
            </div>
            <SkeletonLoading
              height={20}
              width={100}
              borderRadius={10}
              style={{ marginRight: '20px' }}
            />
          </div>
        );
      })}
    </Wrapper>
  );
};

export default JobsSkeleton;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 10px;
`;
