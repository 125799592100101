import { useRoot } from '@/RootProvider';

export function checkAuth(ownerId?: number) {
  const { auth } = useRoot();
  return ownerId === auth?.id ? true : false;
}

export function LoggedOrNot() {
  const { auth } = useRoot();
  if (!!auth) {
    return false;
  } else {
    return true;
  }
}
