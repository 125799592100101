import { useResponsive } from '@/hooks/useResponsive';
import Divider from '@/newComponents/Divider';
import SkeletonLoading from '@/newComponents/Skeleton';
import { devices } from '@/utils/theme';
import React from 'react';
import styled from 'styled-components';

const PropertySkeleton = ({ ...rest }) => {
  const { isTablet } = useResponsive();
  return (
    <Wrapper>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(item => {
        return (
          <div>
            <div className="card" key={item}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: isTablet ? 'row' : 'column',
                  gap: isTablet ? '20px' : '10px',
                }}
              >
                <SkeletonLoading
                  width={isTablet ? 250 : 300}
                  height={150}
                  borderRadius={10}
                />
                <div>
                  <SkeletonLoading
                    height={30}
                    width={250}
                    style={{ marginTop: isTablet ? '12px' : '4px' }}
                  />
                  <SkeletonLoading
                    borderRadius={10}
                    height={30}
                    width={90}
                    style={{ marginTop: isTablet ? '20px' : '5px' }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '10px',
                      marginTop: '20px',
                    }}
                  >
                    <SkeletonLoading borderRadius={10} height={30} width={30} />
                    <SkeletonLoading borderRadius={10} height={30} width={30} />
                  </div>
                </div>
              </div>

              {isTablet && (
                <SkeletonLoading height={30} width={80} borderRadius={10} />
              )}
            </div>
            {isTablet && <Divider style={{ marginTop: '20px' }} />}
          </div>
        );
      })}
    </Wrapper>
  );
};

export default PropertySkeleton;

const Wrapper = styled.div`
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media ${devices.tablet} {
    flex-direction: column;
    gap: 20px;
  }
  .card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
`;

const Container = styled.div``;
