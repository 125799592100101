import { useRoot } from '@/RootProvider';
import { Apis, Hooks, Utils } from '@shared/front';
import PhoneInputField from '@shared/web/components/PhoneInputField';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';

import { Typography } from 'antd';
import OTPInput from '../OTPInput';

import { useLocation, useNavigate } from 'react-router-dom';
import Button from '@/newComponents/Button';

const validationSchema = yup.object().shape({
  countryCode: yup.string().required('Country code is required'),
  phone: yup.string().required('Phone is required'),
});
const codeSchema = yup.object().shape({
  code: yup
    .string()
    .required('Verification code required')
    .min(6, 'Code must be six characters'),
});

function useQuery() {
  const query = new URLSearchParams(useLocation().search);

  return {
    email: query.get('email'),
    verificationCode: query.get('verificationCode'),
  };
}
type Props = {
  initialValues: any;
  onSubmitStep: () => void;
};
const Phone = ({ initialValues, onSubmitStep }: Props) => {
  const [code, setCode] = useState('');
  const navigate = useNavigate();

  const { email, verificationCode }: any = useQuery();
  const [makeRequest, { loading }] = Hooks.useAPI();
  const { auth } = useRoot();

  const [isVerifyBox, setVerifyBox] = useState(!!auth?.unverifiedPhone);

  return (
    <div>
      <Formik
        initialValues={
          !isVerifyBox ? { phone: '', countryCode: '+353' } : { code: '' }
        }
        onSubmit={async values => {
          try {
            if (isVerifyBox) {
              if (!values?.code) return;
              await Utils.request(
                Apis.Auth.verifyPhone({ code: values?.code }),
              );
              onSubmitStep();
            } else {
              await makeRequest(
                Apis.Auth.addPhone({
                  phone:
                    values?.phone?.replace(`^${values?.countryCode}`, '') || '',
                  countryCode: values?.countryCode || '',
                }),
              );

              setVerifyBox(true);
              await Utils.request(Apis.Auth.getMe());
            }
          } catch (error) {}
        }}
        // validationSchema={isVerifyBox ? codeSchema : validationSchema}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form>
              {!isVerifyBox ? (
                <>
                  <Field
                    component={PhoneInputField}
                    label="Phone"
                    name="phone"
                    setFieldValue={setFieldValue}
                  />

                  <Button htmlType="submit">Add Phone</Button>
                </>
              ) : (
                <div
                  style={{
                    textAlign: 'center',
                  }}
                >
                  <Typography.Title level={5}>Verify Account</Typography.Title>
                  <OTPInput
                    length={6}
                    loading={loading}
                    defaultValue={verificationCode}
                    onComplete={(code: any) => {
                      setFieldValue('code', code);
                    }}
                  />
                  <Typography.Text>
                    (We have sent you an email with verification code)
                  </Typography.Text>
                  <div>
                    <Button htmlType="submit">Verify code</Button>
                  </div>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Phone;
