import { Apis } from '@shared/front';
import { useAPI } from '@shared/front/hooks';
import InputField from '@shared/web/components/InputField';
import { Field, Form, Formik } from 'formik';
import { omit } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import { ButtonsContainer, StyledButton } from './index.styles';
import Typography from '@/newComponents/Typography';
import { devices } from '@/utils/theme';
import Modal from '@/newComponents/Modal';
import Select from '@/newComponents/Select';
import SolidRadio from '@/newComponents/SolidRadio';

const ScrollableContainer = styled.div`
  width: 100%;
  height: 400px;
  overflow: auto;

  padding: 10px;
`;
const Box = styled.div`
  width: 100%;
  height: 100%;
`;

const Title = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;

  @media ${devices.mobileXS} {
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
  }

  @media ${devices.laptop} {
    font-size: 18px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 6px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;

  @media ${devices.mobileXS} {
    padding-bottom: 12px;
    margin-bottom: 18px;
  }

  @media ${devices.laptop} {
    padding-bottom: 18px;
    margin-bottom: 30px;
  }
`;

const UtilityProvider = ({ initialValues, onSubmitStep }: any) => {
  const utilityData = initialValues.data;
  const propertyID = initialValues?.id;

  const { id: propertyId } = useParams();
  const formikref = useRef();
  const [selectedUtility, setSelectedUtility] = useState([]);
  const [openUtilityModal, setOpenUtilityModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [selectedUtilityProvider, setSelectedUtilityProvider] =
    useState<string>('');
  const [formValues, setFormValues] = useState<FormValues>({
    category: {},
    utilityProvider: {},
    fields: [],
  });

  const [handleGetCategoriesRequest, { data: categoriesData }] = useAPI();
  const [handleGetProvidersRequest, { data: utilityProvidersData }] = useAPI();
  const [handlePostUtilityProviders, { data: postUtilityProvider }] = useAPI();

  const handleSkipSubmit = () => {
    onSubmitStep({
      data: formikref.current?.values,
      isDraft: false,
      skipStep: false,
    });
  };

  const getUtilityCategory = async () => {
    await handleGetCategoriesRequest(Apis.Utility.getUtilityCategories());
  };

  const getUtilityProviders = async (utilityCategoryId: number) => {
    await handleGetProvidersRequest(
      Apis.Utility.getUtilityProviders(utilityCategoryId),
    );
  };

  const updateUtilityProviders = async () => {
    if (!!selectedUtility.length) {
      const finalData = selectedUtility.map((su: any) => {
        return {
          propertyId: su.propertyId,
          utilityProviderId: su.utilityProviderId,
          fields: su.fields,
          category: `${su.category}`,
          utilityCategoryId: su.utilityCategoryId,
        };
      });

      const response = await handlePostUtilityProviders(
        Apis.Properties.updatePropertyProvider(finalData, initialValues?.id),
      );
      onSubmitStep({ response, isDraft: false });
    }
  };

  // Function to stringify the 'fields' property of each object
  const stringifyFields = utilityData => {
    return utilityData.map(item => {
      return {
        ...item,
        fields: JSON.stringify(item.fields),
      };
    });
  };

  // Call the function to update the array
  const updatedDataArr = stringifyFields(
    utilityData.map(val => ({ ...val, propertyId: propertyID })),
  );
  //post the utility provides
  const handleStepSubmits = fieldValues => {
    const providersData = {
      propertyId: propertyID,
      utilityProviderId: formValues?.utilityProvider.id,
      utilityProvider: formValues?.utilityProvider,
      fields: JSON.stringify(fieldValues),
      category: formValues?.category.title,
      utilityCategoryId: formValues?.category.id,
    };

    // console.log('the providersData', providersData);

    setSelectedUtility((prev: any) => [...prev, providersData]);
  };
  useEffect(() => {
    setSelectedUtility(updatedDataArr);
  }, []);

  useEffect(() => {
    if (openUtilityModal) {
      getUtilityCategory();
    }
  }, [openUtilityModal]);

  //set the selected category to state
  useEffect(() => {
    if (!!selectedCategory) {
      const result = parsedCategories.find(
        (item: any) => item.title === selectedCategory,
      );
      setFormValues(prev => ({ ...prev, category: result }));
    }
  }, [selectedCategory]);
  //get the utility providers
  useEffect(() => {
    if (!!formValues?.category?.id) {
      getUtilityProviders(formValues?.category?.id);
    }
  }, [formValues.category]);

  //get the utility fields
  useEffect(() => {
    if (!!selectedUtilityProvider) {
      const result = parsedUtilityProviders.find(
        (item: any) => item.title === selectedUtilityProvider,
      );

      setFormValues(prevValues => ({
        ...prevValues,
        utilityProvider: result,
        fields: result?.fields,
      }));
    }
  }, [selectedUtilityProvider]);
  //parsed to pass options in selectField components
  const parsedCategories = categoriesData?.length
    ? categoriesData
        ?.filter((category: any) => category?.providers.length)
        .map((category: any) => ({
          ...category,
          label: category?.title,
          value: category?.title,
        }))
    : [];

  //parsed to pass options in solidRadio components

  const parsedUtilityProviders = !!utilityProvidersData?.length
    ? utilityProvidersData?.map((utilityProvider: any) => ({
        ...utilityProvider,
        label: utilityProvider.title,
        value: utilityProvider.title,
      }))
    : [];

  return (
    <div>
      <TitleContainer>
        <Title variant="h1">Add Utility / Utility Providers</Title>
        <StyledButton
          variant="gray"
          radius="round"
          onClick={() => setOpenUtilityModal(true)}
        >
          Add Utility
        </StyledButton>
      </TitleContainer>
      <ScrollableContainer>
        <Box>
          {selectedUtility?.map(utilityItem => {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  margin: '10px',
                }}
              >
                <Typography variant="p">{utilityItem?.category}</Typography>

                <img
                  src={utilityItem?.utilityProvider?.logo}
                  width={100}
                  height={100}
                />
              </div>
            );
          })}
        </Box>
      </ScrollableContainer>

      <ButtonsContainer>
        <StyledButton
          variant="gray"
          radius="round"
          htmlType="button"
          onClick={() => {
            handleSkipSubmit();
          }}
        >
          Skip
        </StyledButton>
        <StyledButton
          radius="round"
          htmlType="submit"
          onClick={updateUtilityProviders}
        >
          Save
        </StyledButton>
      </ButtonsContainer>

      <Modal
        open={openUtilityModal}
        // onOk={() => setOpenUtilityModal(false)}
        onClose={() => setOpenUtilityModal(false)}
        title="Category"
      >
        <Formik
          innerRef={formikref}
          initialValues={{}}
          onSubmit={(values, { resetForm }) => {
            console.log({ lllllllllllll: values });

            setFormValues({
              category: {},
              utilityProvider: {},
              fields: [],
            });

            const fieldsValues = omit(values, ['category', 'utilityProvider']);

            console.log('the fieldsValues', fieldsValues);

            handleStepSubmits(fieldsValues);
            setOpenUtilityModal(false);
          }}
          validationSchema={yup.object().shape({
            category: yup.string().required('Category is required'),
            utilityProvider: yup
              .string()
              .required('Utility Provider is required'),
            ...formValues?.fields?.reduce((schema, field) => {
              schema[field.title] = yup
                .number()
                .required(`${field.title} is required`);
              return schema;
            }, {}),
          })}
          enableReinitialize
        >
          {({
            values,
            errors,
            handleSubmit,
            setFieldValue,
            handleChange,
            resetForm,
          }) => {
            return (
              <Form>
                <CategoryField>
                  <Field
                    component={Select}
                    name="category"
                    label="Category"
                    options={[
                      { value: 'Select', label: 'Select Category' },
                      ...parsedCategories,
                    ]}
                    selected={selectedCategory}
                    placeholder="Select category"
                    onChange={(value: string) => {
                      setSelectedCategory(value);
                      setSelectedUtilityProvider('');
                      setFormValues(prev => ({ ...prev, fields: [] }));
                      setFieldValue('category', value);
                    }}
                  />
                </CategoryField>
                <UtilityProviderField>
                  <Field
                    component={SolidRadio}
                    name="utilityProvider"
                    label="Utility Providers"
                    data={parsedUtilityProviders}
                    selected={selectedUtilityProvider}
                    onChange={(value: string) => {
                      setSelectedUtilityProvider(value);
                      setFieldValue('utilityProvider', value);
                    }}
                  />
                </UtilityProviderField>
                <UtilityProviderField>
                  {formValues?.fields?.map(field => {
                    return (
                      <Field
                        component={InputField}
                        key={field.id}
                        label={field.title}
                        name={field.title}
                      />
                    );
                  })}
                </UtilityProviderField>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '16px',
                  }}
                >
                  <StyledButton
                    variant="gray"
                    radius="round"
                    onClick={() => setOpenUtilityModal(false)}
                  >
                    Cancel
                  </StyledButton>
                  <StyledButton radius="round" htmlType="submit">
                    Save
                  </StyledButton>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
};

export default UtilityProvider;

const CategoryField = styled.div`
  margin: 10px;
`;

const UtilityProviderField = styled.div`
  margin: 10px;
`;

interface FormValues {
  category: {
    id?: number;
    title?: string;
  };
  utilityProvider: {
    id?: number;
    name?: string;
  };
  fields: any[];
}
