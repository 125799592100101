import InputField from '@shared/web/components/InputField';
import TextField from '@shared/web/components/TextField';
import MaskInputField from '@shared/web/components/MaskInputField';
import { Form, Field, Formik } from 'formik';
import useAPI from '@/hooks/api';
import { pick } from 'lodash';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import validation from './validation';
import { Constants } from '@shared/common';
import {
  ButtonsContainer,
  StyledButton,
  StyledLabel,
  Title,
} from './index.styles';
import styled from 'styled-components';

const FormikSelectField = styled(Field)`
  display: block;
  width: 100%;
  height: 40px;
  margin: 6px 0px;
  outline: none;
  border: 1px solid ${({ theme }) => theme.colors.border};
  background-color: ${({ theme }) => theme.colors.lightBG};
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }

  &.disabled-hidden-option {
    color: transparent;
    filter: blur(2px);
    border: 1px solid red;
  }
`;

const StyledOption = styled.option`
  text-align: center;
`;

const BasicInfo = ({ initialValues, onSubmitStep }) => {
  const navigate = useNavigate();
  const [handleSubmitRequest, { loading, error }] = useAPI();
  const [targetButton, setTargetButton] = useState<string>('');
  const handleSubmitForm = async (values, isDraft?: boolean) => {
    const parsedValues = pick(values, [
      'bedrooms',
      'bathrooms',
      'area',
      'berNo',
      'price',
      'desc',
      'propertyType',
      'furnishedType',
      'rentalMarket',
    ]);

    const data = await handleSubmitRequest({
      method: 'post',
      url: `/v1/properties/updateProperty/${initialValues.id}`,
      data: parsedValues,
    });

    onSubmitStep({ data, isDraft });
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmitForm(values);
      }}
      validationSchema={validation.basicInfo}
    >
      {({
        isSubmitting,
        enableReinitialize,
        errors,
        touched,
        values,
        setFieldValue,
        handleSubmit,
        setSubmitting,
      }) => {
        return (
          <div>
            <Form onSubmit={handleSubmit}>
              <Title variant="h1">Basic Information</Title>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '12px',
                }}
              >
                <div>
                  <StyledLabel>Property Type</StyledLabel>
                  <FormikSelectField component="select" name="propertyType">
                    <StyledOption value="" disabled selected>
                      Select the Property Type
                    </StyledOption>
                    {Constants.PropertyDropdown.propertyTypeOptions()?.map(
                      item => {
                        return (
                          <StyledOption label={item.label} value={item.value}>
                            {item.value}
                          </StyledOption>
                        );
                      },
                    )}
                  </FormikSelectField>
                </div>
                <div>
                  <StyledLabel>Furnished Type</StyledLabel>
                  <FormikSelectField component="select" name="furnishedType">
                    <StyledOption value="" disabled selected>
                      Select Furnished Type
                    </StyledOption>
                    {Constants.PropertyDropdown.furnishedTypeOption()?.map(
                      item => {
                        return (
                          <StyledOption label={item.label} value={item.value}>
                            {item.value}
                          </StyledOption>
                        );
                      },
                    )}
                  </FormikSelectField>
                </div>
                <div>
                  <StyledLabel>Rental Market</StyledLabel>
                  <FormikSelectField component="select" name="rentalMarket">
                    <StyledOption value="" disabled selected>
                      Select the Rental Market
                    </StyledOption>
                    {Constants.PropertyDropdown.rentalMarketOptions()?.map(
                      item => {
                        return (
                          <StyledOption label={item.label} value={item.value}>
                            {item.value}
                          </StyledOption>
                        );
                      },
                    )}
                  </FormikSelectField>
                </div>
                <div>
                  <StyledLabel>BER No.</StyledLabel>
                  <FormikSelectField component="select" name="berNo">
                    <StyledOption value="" disabled selected>
                      Select the Ber no.
                    </StyledOption>
                    {Constants.PropertyDropdown.berNo()?.map(item => {
                      return (
                        <StyledOption label={item.label} value={item.value}>
                          {item.value}
                        </StyledOption>
                      );
                    })}
                  </FormikSelectField>
                </div>
                <div>
                  <StyledLabel>Bedrooms</StyledLabel>
                  <Field
                    component={InputField}
                    name="bedrooms"
                    type="number"
                    min={1}
                  />
                </div>
                <div>
                  <StyledLabel>Bathrooms</StyledLabel>
                  <Field
                    component={InputField}
                    name="bathrooms"
                    type="number"
                    min={1}
                  />
                </div>
                <div>
                  <StyledLabel>Area (Sq. ft)</StyledLabel>
                  <Field component={InputField} name="area" type="number" />
                </div>
                <div>
                  <StyledLabel>Rent price per calendar month</StyledLabel>
                  <Field
                    component={MaskInputField}
                    name="price"
                    decimalSeparator="."
                    displayType="input"
                    type="text"
                    thousandSeparator={true}
                    prefix={'€'}
                    setFieldValue={setFieldValue}
                  />
                </div>
                <div>
                  <StyledLabel>Description</StyledLabel>
                  <Field component={TextField} name="desc" type="text" />
                </div>

                {/* <Error message={error} isFormError /> */}
              </div>
              <ButtonsContainer>
                <StyledButton
                  htmlType="button"
                  variant="gray"
                  radius="round"
                  onClick={() => navigate('/properties')}
                >
                  Cancel
                </StyledButton>

                <div style={{ display: 'flex', gap: '20px' }}>
                  <StyledButton
                    radius="round"
                    variant="gray"
                    htmlType="button"
                    isDisabled={targetButton === 'draft' ? loading : false}
                    isLoading={targetButton === 'draft' ? loading : false}
                    onClick={() => {
                      setTargetButton('draft');
                      handleSubmitForm(values, true);
                    }}
                  >
                    Save as draft
                  </StyledButton>
                  <StyledButton
                    radius="round"
                    htmlType="submit"
                    isDisabled={targetButton === 'save' ? loading : false}
                    isLoading={targetButton === 'save' ? loading : false}
                    onClick={() => {
                      setTargetButton('save');
                    }}
                  >
                    Save
                  </StyledButton>
                </div>
              </ButtonsContainer>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default BasicInfo;
