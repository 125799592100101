import { devices } from '@/utils/theme';
import Typography from '@/newComponents/Typography';
import Button from '@/newComponents/Button';
import { ErrorMessage, Field } from 'formik';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 10px;
  @media ${devices.tablet} {
    padding: 20px;
  }
`;
const ContactForm = styled.div`
  @media ${devices.tablet} {
    width: 50%;
    margin: 40px auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
    padding: 10px;
  }
`;
const FormWrapper = styled.div`
  margin: 20px;
  @media ${devices.tablet} {
    padding: 0px 8px;
  }
`;

const FormContent = styled.div`
  margin-top: 20px;
`;
const NextFinalWrapper = styled.div``;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ContractForm = ({
  currentForm,
  formikProps,
  errors,
  handleNextStep,
  currentStep,
  setCurrentStep,
  formStepsLength,
  setInitialValues,
  initialValues,
  contractLoading,
}: ContractProps) => {
  // console.log('the curetn form', currentForm);
  const formFields = currentForm?.fields;
  const { label } = currentForm;
  const Validation = currentForm?.validations;
  const lists = currentForm?.lists ? currentForm.lists[0] : '';

  return (
    <Container>
      <ContactForm>
        <Typography>{label}</Typography>
        <Typography size="sm">{lists}</Typography>

        {formFields.map(({ Component, ...others }: any, index: number) => {
          let props = others;
          let name = others.fieldKey;
          if (props?.type === 'radioBoxGroup') {
            props = {
              ...props,
              ...{
                onClick: (value: any) => {
                  setInitialValues({
                    ...initialValues,
                    [props.fieldKey]: value,
                  });
                },
              },
            };
          }
          if (props?.type === 'input') {
            props = {
              ...props,
              ...{
                onChange: e => {
                  setInitialValues({
                    ...initialValues,
                    [props.fieldKey]: e.target.value,
                  });
                },
              },
            };
          }
          if (props?.type === 'date') {
            props = {
              ...props,
              ...{
                onChange: (value: any, dateString: any) => {
                  setInitialValues({
                    ...initialValues,
                    [props.fieldKey]: dateString,
                  });
                },
              },
            };
          }

          return (
            <FormWrapper key={index}>
              <FormContent>
                <Field
                  key={index}
                  {...props}
                  {...formikProps}
                  name={name}
                  component={Component}
                />
              </FormContent>
            </FormWrapper>
          );
        })}

        <ButtonWrapper style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {/* <Button onClick={formikProps.submitForm}>next</Button> */}
          {currentStep > 0 && (
            <Button
              variant="gray"
              radius="round"
              onClick={() => setCurrentStep(currentStep - 1)}
            >
              Previous
            </Button>
          )}
          &nbsp;
          <NextFinalWrapper>
            {formStepsLength - 1 === currentStep ? (
              <Button
                variant="primary"
                radius="round"
                onClick={formikProps.submitForm}
                isLoading={contractLoading}
                isDisabled={contractLoading}
              >
                Finish
              </Button>
            ) : (
              <Button
                variant="primary"
                radius="round"
                onClick={formikProps.submitForm}
              >
                Next
              </Button>
            )}
          </NextFinalWrapper>
        </ButtonWrapper>
      </ContactForm>
    </Container>
  );
};

export default ContractForm;

interface ContractProps {
  setInitialValues?: any;
  initialValues?: any;
  currentForm?: any;
  formikProps?: any;
  errors?: any;
  handleNextStep?: any;
  mode?: string;
  currentStep: any;
  setCurrentStep: any;
  formStepsLength: number;
  contractLoading: boolean;
}
