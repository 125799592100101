import React, { useEffect, useMemo, useState } from 'react';
import { useRoot } from '@/RootProvider';
import { createGlobalStyle } from 'styled-components';
import { ChatWrapper, ChatLeftWrapper, ChatRightWrapper } from './style';
import { Socket, Store } from '@shared/front';
import { Common } from '@shared/common';
import { useSelector } from 'react-redux';
import ConversationList from './ConversationList';
import GroupConversationList from './GroupConversationList';
import ChatView from './ChatView';
import NoData from '@/newComponents/NoData';
import Loading from '@/newComponents/Loading';
import { useParams } from 'react-router-dom';
import { useResponsive } from '@/hooks/useResponsive';
import { useMediaQuery } from 'react-responsive';
import Drawer from '@/newComponents/Drawer';
import Typography from '@/newComponents/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingPlaceholder from '@/components/LoadingPlaceholder';
const GlobalStyle = createGlobalStyle`
  body {
   overflow:hidden;
  }
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #f5f5f5;
  }
  ::-webkit-scrollbar-thumb {
    background:#cacaca;
    border-radius:4px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #ccc;
  }
`;
const INITIAL_PARAMS = {
  page: 0,
  limit: 40,
};
const Chats = () => {
  const { id: paramConversationId } = useParams();
  const { auth: user } = useRoot();
  const [params, setParams] = useState(INITIAL_PARAMS);
  const [currentConversation, setCurrentConversation] = useState<any>(null);
  const conversations: any[] = useSelector(Store.Select.conversations);
  const [loading, setLoading] = useState<boolean>(false);
  const [messageLoading, setMessageLoading] = useState<boolean>(false);
  const marketConversations: any[] = useSelector(
    Store.Select.marketConversations,
  );

  const [enableDrawer, setEnableDrawer] = useState(true);

  const isDesktop = useMediaQuery({ query: `(max-width:1080px)` });

  const filterConversationByChatLength = Common.orderChats([
    ...conversations,
    ...marketConversations,
  ]);

  const conversationData =
    user?.role === 'landlord'
      ? Common.groupChats(filterConversationByChatLength)
      : filterConversationByChatLength;

  const handleSelectConversation = async (conversation: any) => {
    isDesktop && setEnableDrawer(false);

    setCurrentConversation(conversation);
    // setMessageLoading(true);
    setParams(INITIAL_PARAMS);
    // setTimeout(() => {
    //   setMessageLoading(false);
    // }, 1500);
    await Socket.getConversationChats({
      conversationId: conversation?.id,
      ...INITIAL_PARAMS,
    });
  };

  useEffect(() => {
    setLoading(true);
    Socket.getConversations();
    Socket.getMarketConversations();
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    if (!paramConversationId) {
      if (
        !currentConversation &&
        !!filterConversationByChatLength?.length &&
        params?.page === 0
      ) {
        setCurrentConversation(filterConversationByChatLength[0]);
        if (filterConversationByChatLength[0]) {
          Socket.getConversationChats({
            conversationId: filterConversationByChatLength[0]?.id,
            ...INITIAL_PARAMS,
          });
        }
      }
    } else {
      if (
        paramConversationId &&
        Array.isArray(filterConversationByChatLength) &&
        filterConversationByChatLength?.length > 0
      ) {
        const neededConversation = filterConversationByChatLength.find(
          x => x.id === parseInt(paramConversationId, 10),
        );

        handleSelectConversation(neededConversation);
      }
    }
  }, [conversations, paramConversationId]);

  const handleToggleChatList = () => {
    setEnableDrawer(prev => !prev);
  };

  return (
    <>
      <GlobalStyle />

      <ChatWrapper>
        <>
          <ChatLeftWrapper $isToggleWidth={isDesktop && enableDrawer}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '15px',
                padding: '15px 25px',
                background: '#fcfcfc',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '15px',
                }}
              >
                <FontAwesomeIcon
                  icon="message"
                  style={{
                    fontSize: '22px',
                    cursor: 'pointer',
                  }}
                  color="#2857ff"
                  onClick={() => setEnableDrawer(true)}
                />
                {isDesktop ? (
                  enableDrawer && (
                    <Typography
                      variant="h4"
                      style={{
                        fontSize: '22px',
                        fontWeight: '600',
                      }}
                    >
                      Chat
                    </Typography>
                  )
                ) : (
                  <Typography
                    variant="h4"
                    style={{
                      fontSize: '22px',
                      fontWeight: '600',
                    }}
                  >
                    Chat
                  </Typography>
                )}
              </div>
              {isDesktop && enableDrawer && (
                <FontAwesomeIcon
                  icon="close"
                  style={{
                    fontSize: '20px',
                    cursor: 'pointer',
                  }}
                  color="#000"
                  onClick={handleToggleChatList}
                />
              )}
            </div>
            {filterConversationByChatLength.length === 0 && (
              <div>
                {loading ? (
                  <LoadingPlaceholder />
                ) : (
                  <Typography
                    variant="p"
                    style={{
                      fontSize: '16px',
                      fontWeight: '600',
                      textAlign: 'center',
                    }}
                  >
                    No Conversations yet!!
                  </Typography>
                )}
              </div>
            )}
            {!!Array.isArray(conversationData) && !!conversationData?.length ? (
              <>
                <ConversationList
                  conversations={conversationData}
                  handleSelectConversation={handleSelectConversation}
                  currentConversation={currentConversation}
                />
              </>
            ) : (
              <>
                <GroupConversationList
                  conversations={conversationData}
                  currentConversation={currentConversation}
                  handleSelectConversation={handleSelectConversation}
                />
              </>
            )}
          </ChatLeftWrapper>
          <ChatRightWrapper>
            <ChatView
              currentConversation={currentConversation}
              messageLoading={messageLoading}
              setParams={setParams}
              params={params}
            />
          </ChatRightWrapper>
        </>
      </ChatWrapper>
    </>
  );
};

export default Chats;
