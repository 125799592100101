import CustomRate from '@/newComponents/CustomRate';
import ImageWithFallBack from '@/newComponents/ImageWithFallBack';
import Typography from '@/newComponents/Typography';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

type Props = {
  id: number;
  name: string;
  email: string;
  rating: number;
  avatar: string;
  desire: any;
};

const ServiceProvicerCard = ({
  id,
  name,
  avatar,
  rating,
  email,
  desire,
}: Props) => {
  const theme: any = useTheme();
  const navigate = useNavigate();

  const cardClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate(`/profile/${id}/service`);
  };
  return (
    <>
      {!!desire ? (
        <ServiceProviderCardContainer onClick={cardClick}>
          <div className="image-wrapper">
            <ImageWithFallBack src={avatar} />{' '}
          </div>
          <Typography fontWeight="bold" variant="p" className="ellipsis">
            {name}
          </Typography>
          <Typography
            className="ellipsis"
            variant="p"
            size="sm"
            color={theme?.colors?.fontColors?.primaryLight}
          >
            {email}
          </Typography>
          {!!desire && (
            <>
              <Typography
                variant="p"
                color={theme?.colors?.fontColors?.primaryLight}
              >
                Desired Location
              </Typography>
              <Typography variant="p" size="sm">
                {desire?.address}
              </Typography>
              {!!desire?.moveStatus ? (
                <Text>
                  <Typography
                    variant="p"
                    size="sm"
                    color={theme?.colors?.fontColors?.primaryLight}
                  >
                    Move in date{' '}
                  </Typography>
                  <Typography variant="p" size="sm">
                    {desire?.moveStatus}
                  </Typography>
                </Text>
              ) : null}
            </>
          )}
          <DesiredCard>
            <Typography color="white" variant="p">
              Price &euro;{desire?.targetPrice}
            </Typography>
          </DesiredCard>
        </ServiceProviderCardContainer>
      ) : (
        <ServiceProviderCardContainer onClick={cardClick}>
          <div className="image-wrapper">
            <ImageWithFallBack src={avatar} />{' '}
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontWeight="bold" variant="p" className="ellipsis">
              {name}
            </Typography>
            <CustomRate value={rating} />
          </div>
          <Typography
            className="ellipsis"
            variant="p"
            size="sm"
            color={theme?.colors?.fontColors?.primaryLight}
          >
            {email}
          </Typography>
        </ServiceProviderCardContainer>
      )}
    </>
  );
};

export default ServiceProvicerCard;

const ServiceProviderCardContainer = styled.div`
  cursor: pointer;
  width: 100%;
  /* width: 400px; */
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 6px;

  & .image-wrapper {
    height: 185px;
  }

  & .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    white-space: normal;
  }
`;
const DesiredCard = styled.div`
  height: 40px;
  /* width: 150px; */
  width: auto;
  display: flex;
  align-items: center;
  padding: 5px;
  /* justify-content: center; */
  background: rgba(238, 75, 43, 0.8);
  position: absolute;
  z-index: 999;
  right: 100;
  /* bottom: 170px; */
  top: 0px;
  border-radius: 5px;

  @media (max-width: 768px) {
    bottom: 115px;
  }

  @media (max-width: 1200px) {
    bottom: 130px;
  }
`;

const Text = styled.div`
  display: flex;
  gap: 5px;
`;
