import React from 'react';
import styled from 'styled-components';
const LoaderWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  #loader {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: 5px solid #ddd;
    border-top-color: ${({ theme }) => theme.colors.primary};
    transition: all ease 1s;
    animation: rotate 1s infinite;
  }
  @keyframes rotate {
    100% {
      rotate: 360deg;
    }
  }
`;
const Loading = () => {
  return (
    <LoaderWrapper>
      <div id="loader"></div>
    </LoaderWrapper>
  );
};

export default Loading;
