import { AuthRoleType } from '@shared/types';

const createMenu: any = (role: AuthRoleType) =>
  [
    (role === 'landlord' || role === 'service' || role === 'tenant') && {
      title: 'Dashboard',
      url: '/dashboard',
      icon: 'tachometer-alt',
    },
    // (role === 'landlord' || role === 'service' || role === 'tenant') && {
    //   title: 'Browse Marketplace',
    //   url: '/marketplace',
    //   icon: 'shop',
    // },
    (role === 'landlord' || role === 'service' || role === 'tenant') && {
      title: 'Marketplace',
      url: '/mymarketplace',
      icon: 'store',
      children:
        role === 'landlord' || role === 'service' || role === 'tenant'
          ? [
              {
                title: 'Browse Marketplace',
                url: '/marketplace',
                icon: 'shop',
              },
            ]
          : null,
    },
    role === 'tenant' && {
      title: 'Browse Properties',
      url: '/search-properties',
      icon: 'building',
    },
    // role === 'service' && {
    //   title: 'Browse Properties',
    //   url: '/search-properties',
    //   icon: 'building',
    // },
    role === 'landlord' && {
      title: 'Properties',
      url: '/properties',
      icon: 'house-user',
      // children:
      //   role === 'landlord'
      //     ? [
      //         {
      //           title: 'Browse Properties',
      //           url: '/search-properties',
      //           icon: 'building',
      //         },
      //       ]
      //     : null,
    },
    (role === 'landlord' || role === 'tenant') && {
      title: 'Calendar',
      url: '/calendar',
      icon: 'calendar-days',
    },
    (role === 'landlord' || role === 'tenant') && {
      title: 'Bookings',
      url: '/bookings',
      icon: 'star',
    },
    role === 'service' && {
      title: 'Browse Jobs',
      url: '/search-jobs',
      icon: 'suitcase',
    },
    (role === 'landlord' || role === 'tenant') && {
      title: 'Jobs',
      url: '/jobs',
      icon: 'briefcase',
      children:
        role === 'landlord' || role === 'tenant'
          ? [
              // {
              //   title: 'Browse Jobs',
              //   url: '/search-jobs',
              //   icon: 'suitcase',
              // },
              {
                title: 'Job Contracts',
                url: '/jobContracts',
                icon: 'file-contract',
              },
              {
                title: 'Job Proposals',
                url: '/proposals',
                icon: 'file-alt',
              },
            ]
          : null,
    },
    role === 'service' && {
      title: 'Job Contracts',
      url: '/jobContracts',
      icon: 'file-contract',
    },
    role === 'service' && {
      title: 'Job Proposals',
      url: '/proposals',
      icon: 'file-alt',
    },
    (role === 'landlord' || role === 'service' || role === 'tenant') && {
      title: 'Messages',
      url: '/chats',
      icon: 'comment-alt',
    },
    (role === 'tenant' || role === 'service') && {
      title: 'Wish List',
      url: '/wishlist',
      icon: 'heart',
    },

    (role === 'landlord' || role === 'service' || role === 'tenant') && {
      title: 'Transactions',
      url: '/transactions',
      icon: 'credit-card',
    },

    // (role === 'landlord' || role === 'service' || role === 'tenant') && {
    //   title: 'Profile',
    //   url: '/profile',
    //   icon: 'user-tie',
    // },
    role === 'landlord' && {
      title: 'My Tenant',
      url: '/mytenant',
      icon: 'user-tie',
      children: [
        {
          title: 'Browse Tenant',
          url: '/search-tenants',
          icon: 'user',
        },
      ],
    },
    // role === 'landlord' && {
    //   title: 'Browse Tenant',
    //   url: '/search-tenants',
    //   icon: 'user-tie',
    // },
    (role === 'landlord' || role === 'tenant') && {
      title: 'Browse Service provider',
      url: '/search-service-provider',
      icon: 'suitcase',
    },
    (role === 'landlord' || role === 'tenant' || role === 'service') && {
      title: 'Profile',
      url: '/profile',
      icon: 'user',
    },

    (role === 'landlord' || role === 'service' || role === 'tenant') && {
      title: 'Dev Menu',
      url: '/devMenu',
      icon: 'flask',
    },
    (role === 'landlord' || role === 'service' || role === 'tenant') && {
      title: 'Settings',
      url: '/settings',
      icon: 'sliders',
    },

    (role === 'landlord' || role === 'tenant') && {
      title: 'Report Issues',
      url: '/my-issues',
      icon: 'circle-exclamation',
    },
  ].filter(Boolean);

export { createMenu };
