import Button from '@/newComponents/Button';
import Typography from '@/newComponents/Typography';
import { devices } from '@/utils/theme';
import styled from 'styled-components';
export const ChatWrapper = styled.div`
  display: flex;
  width: 100%;
  position: fixed;
  right: 0px;
  height: calc(100vh - 62px);
  top: 61px;
  z-index: 111;

  @media ${devices.tablet} {
    width: calc(100% - 270px);
  }
`;

export const ChatLeftWrapper = styled.div<{ $isToggleWidth?: boolean }>`
  width: 350px;
  height: 100%;
  border-right: 1px solid #ddd;
  flex: 0 0 auto;
  transition: 0.3s;

  @media (max-width: 1080px) {
    width: ${({ $isToggleWidth }) => ($isToggleWidth ? '350px' : '70px')};
  }
`;
export const ChatRightWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  flex: 1;
`;
export const ConversationWrapper = styled.div`
  overflow-y: auto;

  height: 100%;
  scroll-behavior: smooth;
  max-height: calc(100% - 60px);

  @media (max-width: 1080px) {
    overflow-x: hidden;
    width: 100%;
  }
`;

export const ConversationItem = styled.div<any>`
  padding: 15px;
  background: ${({ active }) => (!!active ? '#aeb8ce' : 'none')};
  cursor: pointer;
`;
export const ConversationItemWrapper = styled.div`
  display: flex;
  column-gap: 10px;
`;

export const UserImageWrapper = styled.div<any>`
  position: relative;
  width: ${({ child }) => (!!child ? '40px' : '40px')};
  height: ${({ child }) => (!!child ? '40px' : '40px')};
  border-radius: 50%;
  margin-right: 5px;
  @media (min-width: 1081px) {
    width: ${({ child }) => (!!child ? '40px' : '70px')};
    height: ${({ child }) => (!!child ? '40px' : '70px')};
  }
`;
export const UserImage = styled.img<any>`
  width: ${({ child }) => (!!child ? '40px' : '40px')};
  height: ${({ child }) => (!!child ? '40px' : '40px')};
  border-radius: 50%;
  border: 1px solid #f0f0f0;

  @media (min-width: 1080px) {
    width: ${({ child }) => (!!child ? '40px' : '70px')};
    height: ${({ child }) => (!!child ? '40px' : '70px')};
  }
`;
export const UserInitialText = styled.div<any>`
  width: ${({ child }) => (!!child ? '25px' : '40px')};
  height: ${({ child }) => (!!child ? '25px' : '40px')};
  border-radius: 50%;
  border: 3px solid ${({ $isActive, $color }) => ($isActive ? '#fff' : $color)};

  color: ${({ $isActive, $color }) => ($isActive ? '#fff' : $color)};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  font-weight: 700;
  @media (min-width: 1081px) {
    width: ${({ child }) => (!!child ? '40px' : '70px')};
    height: ${({ child }) => (!!child ? '40px' : '70px')};
  }
`;
export const UserOnlineStatus = styled.div<any>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${({ active }) => (!!active ? 'green' : '#777')};
  position: absolute;
  bottom: ${({ child }) => (!!child ? '0px' : '5px')};
  right: ${({ child }) => (!!child ? '0px' : '10px')};
`;

export const UserInfoWrapper = styled.div``;

export const MessageViewWrapper = styled.div`
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;
export const MessageViewHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 19px 20px;
  background: #f5f5f5;
  column-gap: 5px;
`;
export const SendMessageWrapper = styled.div`
  display: flex;
  column-gap: 20px;
  padding: 10px 20px;
  border-top: 1px solid #ddd;
  width: 100%;
  form {
    width: 100%;
  }
`;
export const SendMessageButton = styled.button`
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  height: 25px;
  width: 25px;
  border: 0px;

  @media ${devices.laptop} {
    height: 40px;
    width: 40px;
  }
`;
export const SendMessageBox = styled.div`
  width: 75%;
`;
export const SendMessageActionWrapper = styled.div`
  margin-left: auto;
  display: flex;
  column-gap: 6px;

  @media ${devices.laptop} {
    gap: 10px;
  }
`;
export const InputDateTime = styled.input<any>`
  width: 100%;
  height: 50px;
  border: 1px solid ${({ error, theme }) => (!!error ? 'red' : '#ddd')};
  font-size: 12px;
  border-radius: 4px;
  padding: 0px 10px;
  outline: none;
  margin-top: 10px;
  cursor: pointer;
`;
export const MessageActionWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;
export const MessageInput = styled.textarea<any>`
  width: 100%;
  border: ${({ error }) => `1px solid ${!!error ? 'red' : '#ddd'}`};
  border-radius: 30px;
  text-indent: 10px;
  outline: none;
  font-size: 1rem;
  height: 36px;
  resize: none;
  &::placeholder {
    font-size: 1rem;
  }

  @media ${devices.laptop} {
    height: 50px;
    padding: 10px 15px;
  }
`;

export const MessageList = styled.div`
  height: calc(100% - 80px);
  overflow-y: auto;
`;
export const MessageItem = styled.div`
  display: flex;
  flex-direction: column;
  &:last-child {
    margin-top: 20px;
  }

  &:first-child {
    margin-bottom: 20px;

    @media ${devices.laptop} {
      margin-bottom: 0;
    }
  }
`;
export const MessageItemWrapper = styled.div<any>`
  display: flex;
  flex-direction: ${({ active }) => (!!active ? 'column-reverse' : 'column')};
  align-items: ${({ active }) => (!!active ? 'end' : 'start')};
  padding: 0px 20px;

  @media ${devices.laptop} {
    flex-direction: row;
    row-gap: 10px;
    column-gap: 10px;
    align-items: center;
    justify-content: ${({ active }) => (!!active ? 'flex-end' : 'flex-start')};
  }
`;

export const MessageBox = styled.div<any>`
  background: ${({ theme, active }) =>
    !!active ? theme.colors.primary : '#f0f0f0'};
  width: auto;
  max-width: 60%;
  min-width: 25%;
  padding: 15px 20px;
  border-radius: 10px;
  margin-bottom: 4px;
  text-align: ${({ active }) => (!!active ? 'right' : 'left')};
  // @media ${devices.laptop} {
  //   // margin-bottom: 20px;
  // }
`;
export const MessageImageWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;
export const MessageImage = styled.img<any>`
  width: 150px;
  height: 150px;
  border-radius: 4px;
`;

export const AppointmentWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  column-gap: 20px;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 20px;
`;

export const GroupList = styled.div`
  overflow-y: auto;
  height: 100%;
  scroll-behavior: smooth;
  max-height: calc(100% - 60px);

  @media (max-width: 1080px) {
    overflow-x: hidden;
    width: 100%;
  }
`;

export const GroupItem = styled.div<{ $isActive?: boolean }>`
  background: ${({ $isActive }) =>
    $isActive ? 'rgba(200,200,205,0.2)' : 'none'};
  cursor: pointer;
`;

export const GroupItemWrapper = styled.div<{ $isActive?: boolean }>`
  display: flex;
  column-gap: 20px;
  padding: 15px;
  background: ${({ $isActive }) => ($isActive ? '#2857ff' : 'none')};
`;
export const MarketCircle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 1081px) {
    width: 70px;
    height: 70px;
  }
`;
export const GroupLeftItem = styled.div``;
export const GroupRightItem = styled.div``;

export const UploadImageWrapper = styled.div`
  display: flex;
  column-gap: 10px;
  margin-bottom: 10px;
`;

export const UploadImageItem = styled.div`
  position: relative;
`;
export const UploadImage = styled.img`
  width: 40px;
  height: 40px;

  @media ${devices.laptop} {
    width: 60px;
    height: 60px;
  }
`;
export const RemoveUploadImage = styled.div`
  width: 20px;
  height: 20px;
  color: white;
  background: red;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  right: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledButton = styled(Button)`
  font-size: 12px;
  line-height: 25px;
  padding: 0 30px;
  width: fit-content;
  white-space: nowrap;

  @media ${devices.laptop} {
    font-size: 14px;
    line-height: 30px;
  }
`;

export const MessageDateAndTime = styled(Typography)`
  font-size: 10px;
  display: flex;
  align-items: center;
  color: #949494;

  @media ${devices.laptop} {
    font-size: 12px;
  }
`;
