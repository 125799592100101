import { SET, RESET, UPDATE, RESET_REDUX, REMOVE } from "./constants";
import initialState from "./initialState";

export function set(key: keyof typeof initialState, data: any) {
  return {
    type: SET,
    data,
    key,
  };
}

export function reset(key: keyof typeof initialState) {
  return {
    type: RESET,
    data: null,
    key,
  };
}

export function update(key: keyof typeof initialState, data: any) {
  return {
    type: UPDATE,
    data,
    key,
  };
}
export function remove(key: keyof typeof initialState, id: any) {
  return {
    type: REMOVE,
    id,
    key,
  };
}

export function resetRedux() {
  return {
    type: RESET_REDUX,
  };
}
