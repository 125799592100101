import React from 'react';
import Modal from '@/newComponents/Modal';
import { ButtonsContainer, StyledButton } from './modalStyles';

type Props = {
  title: string;
  open: boolean;
  declineLoading: boolean;
  onClose: () => void;
  onDeclineBooking: () => void;
};

const DeclineBookingModal = ({
  title,
  open,
  declineLoading,
  onClose,
  onDeclineBooking,
}: Props) => {
  return (
    <Modal open={open} title={title} onClose={onClose}>
      <ButtonsContainer>
        <StyledButton radius="round" variant="gray" onClick={onClose}>
          No, take me back
        </StyledButton>
        <StyledButton
          radius="round"
          variant="danger"
          isLoading={declineLoading}
          onClick={onDeclineBooking}
        >
          Decline Booking
        </StyledButton>
      </ButtonsContainer>
    </Modal>
  );
};

export default DeclineBookingModal;
