import { useRoot } from '@/RootProvider';
import { useResponsive } from '@/hooks/useResponsive';
import Button from '@/newComponents/Button';
import ImageBrowser from '@/newComponents/FileBrowser/ImageBrowser';
import SmallImageViewer from '@/newComponents/FileBrowser/UploadedSmallImageViewer';
import Modal from '@/newComponents/Modal';
import NoData from '@/newComponents/NoData';
import Typography from '@/newComponents/Typography';
import { uploadImagesToS3 } from '@/utils/S3MultipleUploader';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Apis, Hooks, Store, Utils } from '@shared/front';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ButtonWrapperStyled, TitleWrapperStyled } from '../ReferenceImage';
import { SectionTitle } from '../style';
import { toast } from 'react-toastify';
import DeleteIconNew from '../../../assets/delete-icon-new.svg';

type Props = {
  enableAddImagesModal: boolean;
  onClose: () => void;
  selectedRoom: any;
  propertyId: number;
  handleGetPropertyRooms: () => void;
  setSelectedRoom: Function;
  isLandlord: boolean;
  setEditRoom: (data: { status: boolean; data: any }) => void;
};

const AddRefImagesModal = ({
  enableAddImagesModal,
  onClose,
  propertyId,
  selectedRoom,
  handleGetPropertyRooms,
  setSelectedRoom,
  isLandlord,
  setEditRoom,
}: Props) => {
  const { isTablet } = useResponsive();
  const { auth } = useRoot();
  // STATE HOOKS
  const [deletingRefImage, setDeletingRefImage] = useState([]);
  const [addingRefImage, setAddingRefImage] = useState(false);
  const [markRefAsCompleted, setMarkRefAsCompleted] = useState(false);
  const [showEditRoomName, setShowEditRoomName] = useState(false);
  const [editRoomInfo, setEditRoomInfo] = useState('');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [files, setFiles] = useState([]);
  const { property }: any = useSelector(Store.Select.property);

  // API HOOKS
  const [requestCreateOrUpdateRefImages, { loading: refLoading }] =
    Hooks.useAPI();
  const [handleS3Images, { loading: loadings3Images }] = Hooks.useAPI();
  const [requestMarkCompleted, { loading: markRefAsCompleteLoading }] =
    Hooks.useAPI();
  const [updatePropertyReferenceRoom, { loading: updatingRoomName }] =
    Hooks.useAPI();
  const [loading, setLoading] = useState<boolean>(false);
  const handleCancelAddImageModal = () => {
    onClose();
    setFiles([]);
    closeEditRoomName();
  };

  const uploadReferenceImage = async () => {
    setAddingRefImage(true);
    try {
      const keys = await uploadImagesToS3(files);
      if (!keys || !Array.isArray(keys)) return;
      if (selectedRoom?.room?.id && selectedRoom?.refGroupId) {
        await requestCreateOrUpdateRefImages(
          Apis.Properties.uploadPropertyReferenceImage(
            {
              urls: selectedRoom?.room?.referenceImageGroup[0]?.referenceImage.reduce(
                (allKeys: string[], img) => {
                  allKeys.push(img.URL);
                  return allKeys;
                },
                [...keys],
              ),
              referenceImageGroupId: selectedRoom?.refGroupId,
              propertyReferenceRoomId: selectedRoom?.room?.id,
            },
            propertyId,
          ),
        );
        setFiles([]);
        handleGetPropertyRooms();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setAddingRefImage(false);
    }
  };

  const handleRoomDelete = async (room: any) => {
    if (!room || !room.id) {
      return;
    }
    setLoading(true);
    try {
      await Utils.request(
        Apis.Properties.deletePropertyReferenceRoomById(room.id),
      );
      await handleGetPropertyRooms();
      onClose();
      setLoading(false);
      toast.success('Room deleted successfully');
      setShowDeleteConfirmation(false);
    } catch (error) {
      console.error('Error occurred while deleting the room:', error);
    }
  };

  const handleRefImageDelete = async (id: number) => {
    setDeletingRefImage(prev => [...prev, id]);
    try {
      await Utils.request(Apis.Properties.deletePropertyReferenceImage(id));
      setSelectedRoom(prev => {
        const refImageGroup = [...prev.room.referenceImageGroup];
        const referenceImageGroupSelectedIndex =
          selectedRoom?.room?.referenceImageGroup.findIndex((x: any) => {
            if (x.authId === auth?.id && x.role === auth?.role) {
              return true;
            }

            return false;
          });

        const images = refImageGroup[
          referenceImageGroupSelectedIndex
        ].referenceImage.filter(x => x.id !== id);
        refImageGroup[referenceImageGroupSelectedIndex] = {
          ...refImageGroup[referenceImageGroupSelectedIndex],
          referenceImage: images,
        };
        return {
          ...prev,
          room: {
            ...prev.room,
            referenceImageGroup: refImageGroup,
          },
        };
      });
      setDeletingRefImage(prev => [...prev.filter(x => x !== id)]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleMarkAsComplete = async (groupId: number) => {
    await requestMarkCompleted(
      Apis.Booking.updateReferenceImageGroupStatus(groupId),
    );
    await handleGetPropertyRooms();
    setMarkRefAsCompleted(false);
  };

  const handleEditRoomName = async (roomName: string, roomId: number) => {
    if (!roomName.trim() || updatingRoomName) {
      return;
    }
    await updatePropertyReferenceRoom(
      Apis.Properties.updatePropertyReferenceRoom({ title: roomName }, roomId),
    );
    await handleGetPropertyRooms();
    closeEditRoomName();
  };

  const onEditRoomName = (room: any) => {
    // setEditRoomInfo(room);
    // setShowEditRoomName(true);
    setEditRoom({
      data: room,
      status: true,
    });
  };

  const closeEditRoomName = () => {
    setEditRoomInfo('');
    setShowEditRoomName(false);
  };

  const isActionAble = isLandlord && auth?.id === property?.landlordId;

  const referenceImageGroup = () => {
    if (
      !selectedRoom ||
      !Array.isArray(selectedRoom?.room?.referenceImageGroup)
    ) {
      return;
    }

    if (isActionAble) {
      return selectedRoom?.room?.referenceImageGroup.find((x: any) => {
        if (x.authId === auth?.id && x.role === auth?.role) {
          return true;
        }
        return false;
      });
    } else {
      return selectedRoom?.room?.referenceImageGroup.find((x: any) => {
        if (Array.isArray(x?.referenceImage) && x?.referenceImage.length > 0) {
          return x?.referenceImage[0]?.status === 'After';
        }
        return false;
      });
    }
  };
  const referenceImageGroupSelected = referenceImageGroup();
  return (
    <>
      <Modal
        open={enableAddImagesModal}
        onClose={handleCancelAddImageModal}
        size={'lg'}
        isCentered
        style={{ width: !isTablet ? '95vw' : '70vw' }}
        title="Reference Images"
      >
        <MainContainerStyled>
          <div>
            {isActionAble && (
              <Typography
                variant="span"
                size="sm"
                color={
                  referenceImageGroupSelected?.status === 'Draft'
                    ? 'red'
                    : 'green'
                }
                style={{ padding: '0px 0px', margin: '0px 0px' }}
              >
                {referenceImageGroupSelected?.status}
              </Typography>
            )}
            <TitleWrapperStyled>
              <SectionTitle
                style={{
                  display: 'block',
                  marginBottom: '0',
                }}
                className="title"
              >
                {selectedRoom?.room?.title}{' '}
              </SectionTitle>
              <div
                style={{ display: 'flex', gap: '20px', marginRight: '25px' }}
              >
                {isActionAble && (
                  <FontAwesomeIcon
                    style={{ cursor: 'pointer' }}
                    icon="pencil"
                    color="blue"
                    fontSize={24}
                    onClick={() => {
                      onEditRoomName(selectedRoom?.room);
                    }}
                  />

                  // <FontAwesomeIcon
                  //   icon={faEdit}
                  //   fontSize={24}
                  //   onClick={() => {
                  //     onEditRoomName(selectedRoom?.room);
                  //   }}
                  // />
                )}
                {isActionAble && (
                  <Image
                    src={DeleteIconNew}
                    alt="Delete"
                    onClick={() => {
                      setShowDeleteConfirmation(true);
                    }}
                  />

                  // <FontAwesomeIcon
                  //   icon={faTrash}
                  //   color="red"
                  //   fontSize={24}
                  //   onClick={() => {
                  //     setShowDeleteConfirmation(true);
                  //   }}
                  // />
                )}
              </div>
            </TitleWrapperStyled>

            {selectedRoom?.room?.notes && (
              <Typography
                variant="p"
                style={{
                  display: 'block',
                  color: '#666',
                }}
              >
                {selectedRoom?.room?.notes}{' '}
              </Typography>
            )}
            {(isActionAble &&
              ['Draft', 'Completed'].includes(
                referenceImageGroupSelected?.status,
              )) ||
            (!isActionAble &&
              ['Completed'].includes(referenceImageGroupSelected?.status)) ? (
              <SmallImageViewer
                files={files}
                setFiles={setFiles}
                imagesURL={referenceImageGroupSelected?.referenceImage.reduce(
                  (acc, val) => {
                    acc.push({
                      id: val.id,
                      URL: val.URL,
                    });
                    return acc;
                  },
                  [],
                )}
                isLoading={refLoading || loadings3Images || addingRefImage}
                onRemove={handleRefImageDelete}
                enableRemoveImage={
                  isActionAble &&
                  ['Draft'].includes(referenceImageGroupSelected?.status)
                }
                isDeletingIds={deletingRefImage}
                callbackAfterDeletion={handleGetPropertyRooms}
              />
            ) : (
              <NoData
                title="No Images are available as reference images."
                size={150}
              />
            )}

            {Array.isArray(referenceImageGroupSelected?.referenceImage) &&
              referenceImageGroupSelected?.referenceImage?.length === 0 &&
              files?.length === 0 &&
              isActionAble && (
                <NoData title="No Images are added yet." size={100} />
              )}
            <ButtonWrapperStyled style={{ margin: '30px 0px' }}>
              {isActionAble &&
                referenceImageGroupSelected?.referenceImage.length > 0 &&
                referenceImageGroupSelected?.status === 'Draft' && (
                  <Button
                    variant="primary"
                    onClick={() => setMarkRefAsCompleted(true)}
                    isLoading={markRefAsCompleteLoading}
                    isDisabled={markRefAsCompleteLoading}
                    style={{ backgroundColor: '#4b954b' }}
                  >
                    Mark as Complete
                  </Button>
                )}
            </ButtonWrapperStyled>
            {isActionAble &&
              referenceImageGroupSelected?.status === 'Draft' && (
                <>
                  <SectionTitle>Add Reference Images</SectionTitle>
                  <Typography
                    variant="p"
                    style={{
                      display: 'block',
                    }}
                  >
                    Drag n drop or Click and browse for uploading multiple
                    Images.
                  </Typography>

                  <ImageBrowser setFiles={setFiles} />
                  <Typography
                    variant="p"
                    style={{
                      display: 'block',
                    }}
                  >
                    Only JPEG, PNG and JPG image format are supported.
                  </Typography>
                </>
              )}
          </div>
          <ButtonWrapperStyled style={{ marginTop: '30px' }}>
            <Button
              variant="gray"
              onClick={() => {
                handleCancelAddImageModal();
              }}
              isDisabled={refLoading || loadings3Images || addingRefImage}
            >
              Close
            </Button>
            {isActionAble &&
              referenceImageGroupSelected?.status === 'Draft' && (
                <Button
                  variant="primary"
                  onClick={uploadReferenceImage}
                  isLoading={refLoading || loadings3Images || addingRefImage}
                  isDisabled={refLoading || loadings3Images || addingRefImage}
                >
                  Upload Images
                </Button>
              )}
          </ButtonWrapperStyled>
        </MainContainerStyled>
      </Modal>
      <Modal
        open={markRefAsCompleted}
        title="Mark as complete."
        onClose={() => setMarkRefAsCompleted(false)}
        isCentered={!isTablet}
        style={{ width: !isTablet ? '90vw' : 'auto' }}
      >
        <Typography variant="p">
          Are you sure you want to mark these room reference images as
          Completed?
        </Typography>
        <Typography variant="p">
          After completed, these images will be visible to tenant.
        </Typography>
        <ButtonWrapperStyled style={{ marginTop: '30px' }}>
          <Button
            onClick={() => {
              setMarkRefAsCompleted(false);
            }}
            isDisabled={refLoading || loadings3Images || addingRefImage}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => handleMarkAsComplete(referenceImageGroupSelected.id)}
            isLoading={refLoading || loadings3Images || addingRefImage}
            isDisabled={refLoading || loadings3Images || addingRefImage}
          >
            Mark as complete
          </Button>
        </ButtonWrapperStyled>
      </Modal>
      <Modal
        open={showDeleteConfirmation}
        title="Confirm Room Deletion"
        onClose={() => setShowDeleteConfirmation(false)}
        isCentered={!isTablet}
        style={{ width: !isTablet ? '90vw' : 'auto' }}
      >
        <Typography variant="p">
          Are you sure you want to delete this room?
        </Typography>
        <ButtonWrapperStyled style={{ marginTop: '30px' }}>
          <Button
            variant="gray"
            radius="round"
            onClick={() => {
              setShowDeleteConfirmation(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            radius="round"
            isLoading={loading}
            onClick={() => {
              handleRoomDelete(selectedRoom?.room);
            }}
          >
            Delete
          </Button>
        </ButtonWrapperStyled>
      </Modal>
    </>
  );
};

export default AddRefImagesModal;

const MainContainerStyled = styled.div`
  max-height: calc(100vh - 120px);
  overflow-y: auto;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
`;

const Image = styled.img`
  height: 30px;
  width: 30px;
  cursor: pointer;
`;
