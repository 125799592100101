export type Sizes = {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  "1x": number;
  "2x": number;
  "3x": number;
  "4x": number;
  "5x": number;
  zero: number;
};

export const fontSizes: Sizes = {
  xs: 10,
  sm: 12,
  md: 14,
  lg: 16,
  "1x": 20,
  "2x": 24,
  "3x": 30,
  "4x": 40,
  "5x": 50,
  zero: 0,
};

export const fontWeight = {
  extraThin: 100,
  thin: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900,
};

export const fontFamily = {
  bold: "Poppins-Bold",
  semiBold: "Poppins-SemiBold",
  medium: "Poppins-Medium",
  regular: "Poppins-Regular",
  poppinsBold: "Poppins-Bold",
  poppinsSemiBold: "Poppins-SemiBold",
  poppinsMedium: "Poppins-Medium",
  poppinsRegular: "Poppins-Regular",
};

export const spacing: Sizes = {
  xs: 4,
  sm: 8,
  md: 12,
  lg: 16,
  "1x": 20,
  "2x": 24,
  "3x": 30,
  "4x": 40,
  "5x": 50,
  zero: 0,
};

export const materialColors = {
  red: {
    bg: "#EE9A9A",
    text: "#D32F2F",
  },
  pink: {
    bg: "#f48fb1",
    text: "#c2185b",
  },
  purple: {
    bg: "#ce93d8",
    text: "#7b1fa2",
  },
  deepPurple: {
    bg: "#b39ddb",
    text: "#512da8",
  },
  indigo: {
    bg: "#9fa8da",
    text: "#303f9f",
  },
  blue: {
    bg: "#90caf9",
    text: "#1976d2",
  },
  lightBlue: {
    bg: "#81d4fa",
    text: "#0288d1",
  },
  cyan: {
    bg: "#80deea",
    text: "#0097a7",
  },
  teal: {
    bg: "#80cbc4",
    text: "#00796b",
  },
  green: {
    bg: "#a5d6a7",
    text: "#388e3c",
  },
  lightGreen: {
    bg: "#c5e1a5",
    text: "#689f38",
  },
  lime: {
    bg: "#e6ee9c",
    text: "#afb42b",
  },
  yellow: {
    bg: "#fff59d",
    text: "#fbc02d",
  },
  amber: {
    bg: "#ffe082",
    text: "#ffa000",
  },
  orange: {
    bg: "#ffcc80",
    text: "#f57c00",
  },
  deepOrange: {
    bg: "#ffab91",
    text: "#e64a19",
  },
  brown: {
    bg: "#bcaaa4",
    text: "#5d4037",
  },
  grey: {
    bg: "#eeeeee",
    text: "#616161",
  },
  blueGrey: {
    bg: "#b0bec5",
    text: "#455a64",
  },
};

export const statusColor = {
  info: "#079CFB",
  warn: "#CB973D",
  error: "#C82B05",
  success: "#25993D",
};
const statusColors = {
  Cancelled: materialColors.blueGrey,
  Completed: materialColors.green,
  Pending: materialColors.blueGrey,
  Active: materialColors.green,
  Booked: materialColors.purple,
  Inactive: materialColors.red,
  Paused: materialColors.grey,
  Draft: materialColors.grey,
  Verified: materialColors.green,
  landlord: materialColors.blue,
  Landlord: materialColors.blue,
  tenant: materialColors.green,
  Tenant: materialColors.green,
  Service: materialColors.purple,
  "Service Provider": materialColors.purple,
  Processing: materialColors.purple,
  In_progress: materialColors.purple,
  Hired: materialColors.blue,
  Closed: materialColors.red,
  Declined: materialColors.red,
  Property: materialColors.blue,
  "Contract Issued": materialColors.purple,
  Chat: materialColors.blue,
  Calendar: materialColors.purple,
  Contract: materialColors.blue,
  Transaction: materialColors.purple,
  "Under Contract": materialColors.red,
  "Booking Requested": materialColors.purple,
  "Contract Uploaded": materialColors.purple,
  "Appointment Request": materialColors.purple,
  "Viewing Arranged": materialColors.purple,
};

export const generalColors = {
  primary: "#2857FF",
  primaryMedium: "rgba(238,60,163,1)",
  primaryLight: "rgba(242,108,185,1)",
  secondary: "#49DF88",
  secondaryMedium: "rgba(96,192,228,1)",
  secondaryLight: "rgba(135,207,234,1)",
  white: "rgba(255,255,255,1)",
  lightBG: "#FAFAFA",
  border: "#F0F0F0",
  danger: "#D32F2F",
  divider: "#E0E0E0",
};

export const fontColors = {
  primary: "#2857FF",
  primaryMedium: "rgba(110,115,104,1)",
  primaryLight: "rgba(146,149,141,1)",
  secondary: "rgba(181,184,179,1)",
  secondaryMedium: "rgba(195,198,194,1)",
  secondaryLight: "rgba(209,211,208,1)",
  dark: "rgba(15, 17, 12, 1)",
};

export const screenSizes = {
  xs: "480px",
  xss: "300px",
  sm: "576px",
  md: "766px",
  lg: "992px",
  xl: "1200px",
  xxl: "1600px",
};
export const devices = {
  mobileXSS: ` (min-width: ${screenSizes.xss})`,
  mobileXS: ` (min-width: ${screenSizes.xs})`,
  mobileSM: ` (min-width: ${screenSizes.sm})`,
  tablet: ` (min-width: ${screenSizes.md})`,
  laptop: ` (min-width: ${screenSizes.lg})`,
  laptopXL: ` (min-width: ${screenSizes.xl})`,
  Desktop: ` (min-width: ${screenSizes.xxl})`,
};

export const createTheme = (isDarkMode: boolean) => {
  const theme = {
    isDarkMode,
    colors: {
      primaryColor: "#2061F8",
      primary: "#1677FF",
      primaryBlue: "#00359E",
      black: "black",
      white: "white",
      danger: "#E83F36",
      warn: "#FEBC2E",
      success: "#5AB601",
      secondarySuccess: "#00DE90",
      textColor: isDarkMode ? "white" : "black",
      textNeutral1: "#616161",
      textNeutral2: "#424242",
      textNeutral3: "#c7c7c7",
      alterTextColor: isDarkMode ? "black" : "white",
      secondaryTextColor: isDarkMode ? "#E0E0E1" : "#576874",
      lightTextColor: isDarkMode ? "#E0E0E1" : "#B8B8B9",
      pageBackgroundColor: isDarkMode ? "#1F1F1F" : "white",
      pageDimBackgroundColor: isDarkMode ? "#3D3D3D" : "#F8F6FA",
      sectionHeaderBackground: isDarkMode ? "#3D3D3D" : "#F9FAFF",
      bannerBackgroundColor: isDarkMode ? "#393939" : "#38B0DD",
      borderColor: isDarkMode ? "#525252" : "#C1C1C1",
      lightWhite: "#fafafa",
      whiteDim: "#E0E0E1",
      blackDim: "#21252B",
      transparent: "transparent",
      lightPrimary: "#add8e6",
      lightGrey: "#ddd",
      primaryTextColor: "black",
      listBackground: "#f9fafc",
      primaryBackground: "#f9fafc",
      lightBlue: "#d9ebfc",
      navyBlue: "#0E1335",
      circleGrey: "#EEF3FF",
      circleBlue: "#2061F8",
      purpleBlue: "#007fff",
      purple: "#ce93d8",
      lightGreen: "#c7f4e6",
      lightGreen2: "#00DE90",
      darkGreen: "#00de90",
      lightYellow: "#faeec2",
      darkYellow: "#ffc500",
      lightRed: "#fad0d2",
      darkRed: "#ff0505",
      white2: "#f2f2f2",
      primaryButton: "#007fff",
      lightGrey2: "#AEB8CF",
      whiteGrey: "#EFF1F5",
      whiteGreen: "#F0FCF8",
      greyBlack: "#576874",
      yellowWhite: "#FAF1CE",
      darkGrey: "#b2babf",
      deepOrange: "#ffab91",
      webPrimaryTextColor: "#949494",
      bannerLightBg: "#F2F6FE",
      normalText: "#292929",
      darkBlue: "#00359E",
      lightPink: "#FFB6C1",
    },
    statusColors,
    materialColors,
    generalColors,
    fontSizes,
    spacing,
    fontFamily,
    fontWeight,
    screenSizes,
    devices,
  };

  return theme;
};

export const sharedTheme = createTheme(false);

export type ThemeType = typeof sharedTheme;

export const LightenDarkenColor = (col: string, amt: number) => {
  var usePound = false;

  if (col[0] == "#") {
    col = col.slice(1);
    usePound = true;
  }

  var num = parseInt(col, 16);

  var r = (num >> 16) + amt;

  if (r > 255) {
    r = 255;
  } else if (r < 0) {
    r = 0;
  }

  var b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) {
    b = 255;
  } else if (b < 0) {
    b = 0;
  }

  var g = (num & 0x0000ff) + amt;

  if (g > 255) {
    g = 255;
  } else if (g < 0) {
    g = 0;
  }

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
};
