import moment from "moment";

export const groupNotifications = (notifications: any) => {
  return (
    (!!notifications &&
      notifications?.reduce((a: any, b: any) => {
        const date = moment(b.createdAt).format("MMM DD, YYYY");
        if (!a[date]) {
          a[date] = [];
        }
        a[date].push(b);
        return a;
      }, {})) ||
    {}
  );
};

export function getAttentionNotification(unReadNotifications: any[]) {
  let attentions: any[] = [];
  !!unReadNotifications?.length &&
    unReadNotifications?.map((notification: any) => {
      const route = JSON.parse(notification?.appRoute)
        ? JSON.parse(notification?.appRoute)[0]
        : null;
      if (route) {
        const notificationPropertyId =
          JSON.parse(notification?.appRoute)[1]?.propertyId ||
          JSON.parse(notification?.appRoute)[1]?.id;
        if (
          (route === "BookingView" || route === "PropertyView") &&
          notificationPropertyId
        ) {
          if (!attentions.includes(notificationPropertyId)) {
            attentions.push(notificationPropertyId);
          }
        }
      }
    });

  return attentions;
}

export function getPropertyNotification(unReadNotifications: any[]) {
  let propertyNotifications: any[] = [];

  unReadNotifications?.map((item: any) => {
    if (!!item?.appRoute?.length) {
      const route = JSON.parse(item?.appRoute)[0] || null;
      const notificationPropertyId =
        JSON.parse(item?.appRoute)[1]?.propertyId ||
        JSON.parse(item?.appRoute)[1]?.id;
      if (
        (route === "BookingView" || route === "ReportIssueDetails") &&
        notificationPropertyId
      ) {
        propertyNotifications.push({
          type: route === "BookingView" ? "Notification" : "Report",
          propertyId: notificationPropertyId,
        });
      }
    }
  });

  return propertyNotifications || [];
}

export function getServiceNotification(unReadNotifications: any[]) {
  let propertyNotifications: any[] = [];

  unReadNotifications?.map((item: any) => {
    if (!!item?.appRoute?.length) {
      const parseRoute = JSON.parse(item?.appRoute);
      const route = parseRoute[0] || null;
      const notificationServiceId =
        parseRoute[1]?.serviceId || parseRoute[1]?.id;
      if (
        (route === "JobView" || route === "ReportIssueDetails") &&
        notificationServiceId
      ) {
        propertyNotifications.push({
          type: route === "JobView" ? "Notification" : "Report",
          serviceId: notificationServiceId,
        });
      }
    }
  });

  return propertyNotifications || [];
}

export function getEventNotifications(
  notifications: any[],
  appointments?: any,
  reminders?: any[],
  role?: string
) {
  const filterNotification = notifications?.filter((notification) => {
    if (!!notification?.appRoute?.length) {
      const route = JSON.parse(notification?.appRoute)[0] || null;
      if (
        route === "BookingView" ||
        route === "PropertyView" ||
        route === "TransactionView" ||
        route === "JobsView" ||
        route === "ProposalView" ||
        route === "Calendar"
      ) {
        return notification;
      }
    }
  });

  const groupNotifications =
    [...filterNotification, ...appointments, ...reminders]?.reduce(
      (a: any, b: any) => {
        const date = moment(
          b?.appoinmentDate || b?.reminderDate || b.createdAt
        ).format("YYYY-MM-DD");
        if (!a[date]) {
          a[date] = [];
        }
        a[date].push({
          name: b?.appoinmentDate
            ? `You have an appointment with ${
                role === "landlord"
                  ? `${b.tenant?.firstName} ${b.tenant?.lastName}`
                  : `${b.landlord?.firstName} ${b.landlord?.lastName}`
              }`
            : b.title,
        });
        return a;
      },
      {}
    ) || {};

  return groupNotifications;
}

export function orderChats(conversations?: any) {
  const sortedData: any[] = [...conversations]?.sort(
    (a: any, b: any) =>
      Date.parse(b?.chats[0]?.createdAt) - Date.parse(a?.chats[0]?.createdAt)
  );

  return sortedData;
}

export function orderAllChats(conversations?: any) {
  const sortedData: any[] = [...conversations]?.sort((a: any, b: any) => {
    // If both conversations have chats, compare the latest chat time
    if (a.chats.length && b.chats.length) {
      return (
        Date.parse(b.chats[0].createdAt) - Date.parse(a.chats[0].createdAt)
      );
    }
    // If only conversation a has chats, move it to the beginning
    else if (a.chats.length) {
      return -1;
    }
    // If only conversation b has chats, move it to the beginning
    else if (b.chats.length) {
      return 1;
    }
    // If neither conversation has chats, preserve their order
    else {
      return 0;
    }
  });

  return sortedData;
}

export function groupChats(conversations?: any) {
  const filterConversation = conversations?.filter((conversation: any) => {
    if (
      conversation?.propertyId ||
      conversation?.serviceProviderId ||
      conversation?.buyer
    ) {
      return conversation;
    }
  });
  const groupConversations =
    filterConversation?.reduce((a: any, b: any) => {
      const key = b.propertyId
        ? `${b.propertyId}-property`
        : b.serviceId
        ? `${b.serviceId}-service`
        : "market";
      if (!a[key]) {
        a[key] = [];
      }

      a[key].push(b);
      return a;
    }, {}) || {};

  return groupConversations;
}

export function getTargetKeyByConversationId(
  conversationId?: any,
  conversationData?: any
) {
  let targetKey;
  if (!!Array.isArray(conversationData) && !!conversationData?.length) {
    return;
  } else {
    for (const key in conversationData) {
      const targetObject = conversationData[key].find(
        (obj: any) => obj.id === +conversationId
      );
      if (targetObject) {
        targetKey = key;
        break;
      }
    }
    return targetKey;
  }
}

export function getInitialChatsWithPropertyId(conversationData?: any) {
  const chatsWithPropertyId = conversationData.filter(
    (item) => item.hasOwnProperty("propertyId") && item.propertyId !== ""
  );
  return chatsWithPropertyId;
}

//filter chats of marketConverstions and order with the latest one first
export function orderChatsOfMarketPlace(marketConverstaions?: any) {
  return marketConverstaions.flatMap(({ id, chats }) =>
    chats.map((chat) => ({ ...chat, chatId: id }))
  );
}

//order all convestions of services propertyies and marketpalce and sort to get the latest chats
export function orderAllChatsWithmarketPlace(
  conversations?: any,
  marketConverstaions?: any
) {
  const sortedData: any[] = [...conversations, ...marketConverstaions]?.sort(
    (a: any, b: any) => {
      // If both conversations have chats, compare the latest chat time
      if (a.chats.length && b.chats.length) {
        return (
          Date.parse(b.chats[0].createdAt) - Date.parse(a.chats[0].createdAt)
        );
      }
      // If only conversation a has chats, move it to the beginning
      else if (a.chats.length) {
        return -1;
      }
      // If only conversation b has chats, move it to the beginning
      else if (b.chats.length) {
        return 1;
      }
      // If neither conversation has chats, preserve their order
      else {
        return 0;
      }
    }
  );

  return sortedData;
}

//know whether me buyer or seller and render on chatlist
export function identifyUserRole(userId: any, data: any) {
  const buyerId = data.buyer.buyerId;
  const sellerId = data.seller.sellerId;

  if (buyerId === userId) {
    //its me buyer
    return data.seller.avatar;
  }
  if (sellerId === userId) {
    //its me seller
    return data.buyer.avatar;
  }
}

export function sortConversationsByLatest(
  marketConversations,
  conversationData
) {
  // Combine marketConversations and conversationData into a single array
  const allConversations = [
    ...Object.values(marketConversations),
    ...Object.values(conversationData),
  ];

  // Sort the conversations by createdAt in descending order (latest first)
  allConversations.sort((a, b) => {
    const createdAtA = a.chats[0] ? new Date(a.chats[0].createdAt) : null;
    const createdAtB = b.chats[0] ? new Date(b.chats[0].createdAt) : null;

    if (createdAtA && createdAtB) {
      return createdAtB - createdAtA;
    } else if (createdAtA) {
      return -1;
    } else if (createdAtB) {
      return 1;
    } else {
      return 0;
    }
  });

  return allConversations;
}

export function getAllConverstionWithNoEmptyChats(coversationData: any) {
  return coversationData.filter((data) => data.chats.length !== 0);
}
