import Divider from '@/newComponents/Divider';
import SearchFilterInput from '@/newComponents/SearchFilterInput';
import { Apis } from '@shared/front';
import { request } from '@shared/front/utils';
import { FormikProps } from 'formik';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import FilterInfo from './FilterInfo';
import FilterModel, { InitialValueTypes } from './FilterModel';
import SearchTenantSkeleton from './SearchTenantSkeleton';
import TenantCard from './TenantCard';
import { devices } from '@/utils/theme';
import NoData from '@/newComponents/NoData';
import store from '@shared/front/store';
import AddressSearch from '@/newComponents/AddressSearch';

type Props = {};

const Initial_Params = {
  page: 0,
  limit: 18,
  search: '',
  role: 'tenant',
};

export const INITIAL_VALUES = {
  bedrooms: 0,
  bathrooms: 0,
  address: undefined,
  propertyType: undefined,
  sort: undefined,
  furnishedType: undefined,
  rentalMarket: undefined,
};

const BrowseTenants = (props: Props) => {
  const [searchText, setSearchText] = useState('');
  const [openFilter, setOpenFilter] = useState(false);
  const formikRef = useRef<FormikProps<any>>(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const tenants: any = useSelector((state: any) => state?.searchProfiles);
  const pagination: any = useSelector((state: any) => state.pagination);
  const delayedGetAllTenants = useCallback(
    debounce(search => {
      const myParams = {
        ...params,
        page: 0,
      };
      setParams(myParams);
      // getAllTenants(params, search);
    }, 1000),
    [],
  );
  const dispatch = useDispatch();

  const [params, setParams] = useState(Initial_Params);
  const [filterQuery, setFilterQuery] =
    useState<InitialValueTypes>(INITIAL_VALUES);

  useEffect(() => {
    return () => {
      dispatch(store.Actions.reset('searchProfiles'));
    };
  }, []);

  useEffect(() => {
    if (params?.page === 0 || params?.page <= pagination?.lastPage) {
      getAllTenants(params, searchText);
    }
  }, [params]);

  const getAllTenants = async (
    params: typeof Initial_Params,
    search: string,
  ) => {
    try {
      setLoading(true);
      const myParams = { ...params, page: params?.page, limit: params?.limit };
      if (!!search) {
        myParams.search = search;
      }
      const action = myParams?.page === 0 ? 'set' : 'update';
      await request(Apis.Auth.searchProfile(myParams, action));
    } catch (error) {
      console.error('Get all Tenants Error = ', error);
    } finally {
      setLoading(false);
    }
  };

  const clearSearchText = () => {
    setSearchText('');
    const myParams = {
      ...params,
      page: 0,
    };
    setParams(myParams);
  };

  const handleSearchTextChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;
    setSearchText(value);

    delayedGetAllTenants(value);
  };

  const handleOnFilterOpen = () => {
    setOpenFilter(true);
  };

  const handleOnFilterClose = () => {
    setOpenFilter(false);
  };

  const onFilter = async (data: InitialValueTypes) => {
    setFilterQuery(data);

    const myParams: any = {
      ...params,
      page: 0,
    };
    if (!!data?.address) {
      myParams.address = data?.address;
    }
    if (!!data?.propertyType) {
      myParams.propertyType = data?.propertyType;
    }
    setParams(myParams);
    handleOnFilterClose();
  };

  const onResetFilter = () => {
    setFilterQuery(INITIAL_VALUES);
    formikRef?.current?.resetForm();
    formikRef?.current?.setFieldValue('address', undefined);
    setParams(Initial_Params);
    dispatch(store?.Actions.reset('pagination'));
  };

  const onRemoveFilter = (filterKey: keyof typeof INITIAL_VALUES) => {
    setFilterQuery(prev => ({
      ...prev,
      [filterKey]: INITIAL_VALUES[filterKey],
    }));
    dispatch(store?.Actions.reset('pagination'));
    setParams(prev => ({
      ...prev,
      page: 0,
    }));
  };

  const filterInfo = Object.entries(filterQuery)?.filter(x => !!x[1]);

  const fetchMoreData = () => {
    if (params?.page >= pagination?.lastPage) {
      setHasMore(false);
      return;
    }
    setParams((prev: any) => ({ ...prev, page: prev.page + 1 }));
  };

  return (
    <BrowseTenantsContainer>
      <FilterContainer>
        <SearchFilterInput
          placeholder="Search for Tenant"
          onCancel={clearSearchText}
          name="searchText"
          value={searchText}
          onChange={handleSearchTextChange}
          onFilter={handleOnFilterOpen}
        />
        <FilterModel
          isModalOpen={openFilter}
          onClose={handleOnFilterClose}
          onSubmit={onFilter}
          onReset={onResetFilter}
          formikRef={formikRef}
          initialValues={filterQuery}
        />
        {filterInfo?.length > 0 && (
          <FilterInfo
            onReset={onResetFilter}
            filterInfo={filterInfo}
            onRemoveFilter={key =>
              onRemoveFilter(key as keyof typeof INITIAL_VALUES)
            }
          />
        )}
      </FilterContainer>

      <Divider space="0px" />
      <TenantContainer id="scrollableTenantContainer">
        {loading && params.page === 0 && tenants?.length === 0 && (
          <SearchTenantSkeleton count={10} />
        )}
        {!loading && tenants?.length === 0 && (
          <NoData title="No tenants available." size="100%" />
        )}
        <InfiniteScroll
          dataLength={(params.page + 1) * params?.limit}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<h4></h4>}
          scrollableTarget="scrollableTenantContainer"
          style={{
            overflow: 'hidden',
            paddingBottom: '150px',
          }}
        >
          <GridContainer>
            {loading && params.page === 0 && tenants?.length > 0 && (
              <SearchTenantSkeleton noWrapper count={1} />
            )}
            {tenants &&
              Array.isArray(tenants) &&
              tenants?.map((tenant: any) => (
                <TenantCard
                  key={tenant?.id}
                  id={tenant?.id}
                  name={tenant?.fullName}
                  avatar={tenant?.avatar}
                  rating={tenant?.rating || 0}
                  email={tenant?.email}
                  desire={tenant?.desire}
                />
              ))}
            {loading && params.page > 0 && (
              <SearchTenantSkeleton count={7} noWrapper />
            )}
          </GridContainer>
        </InfiniteScroll>
      </TenantContainer>
    </BrowseTenantsContainer>
  );
};

export default BrowseTenants;

const BrowseTenantsContainer = styled.div`
  width: 100%;
  height: calc(100vh - 90px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FilterContainer = styled.div`
  max-width: 100%;
  width: 100%;
  @media ${devices.tablet} {
    max-width: 1200px;
  }

  margin: 0 auto;
`;

const TenantContainer = styled.div`
  height: calc(100vh - 90px - 100px - 20px);
  width: 100%;
  overflow-y: auto;
  position: relative;

  padding: 0px 10px;
  @media ${devices.tablet} {
    padding: 0px 20px;
  }
`;
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 50px 30px;
  width: 100%;
`;
