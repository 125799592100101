import Modal from '@/newComponents/Modal';
import React from 'react';
import { toast } from 'react-toastify';
import { localeNumberFormat } from '@shared/front/utils/numberFormat';
import ColorFulText from '@shared/web/components/ColorFulText';
import useAPI from '@/hooks/api';
import { Apis } from '@shared/front';
import {
  ButtonsContainer,
  Container,
  ModalMainText,
  StyledButton,
} from './modalStyles';
import { TRANSACTION_TYPE } from '@/constants';

type Props = {
  isDepositVisible: boolean;
  onCancel: Function;
  booking: any;
  getBooking: any;
  user: any;
};

const SecurityDepositModal = ({
  isDepositVisible,
  onCancel,
  booking,
  getBooking,
  user,
}: Props) => {
  const [handleSubmitDeposit, { loading: depositLoading }] = useAPI();
  console.log({ user });

  const handleDeposit = async () => {
    if (!user?.hasValidPaymentMethod) {
      return toast.error('Please add payment method');
    } else if (!user?.stripeAccountToken) {
      return toast.error('Please add bank details');
    } else {
      try {
        await handleSubmitDeposit(
          Apis.Booking.securityDeposit({
            amount: booking?.price,
            transactionType: TRANSACTION_TYPE['Security Deposit'],
            bookingId: booking?.id,
            debitedFrom: user.id,
            creditedTo: booking?.landlord?.id,
          }),
        );
      } catch (error) {
        return toast('Error while procssing your deposit.');
      }
    }

    await getBooking();
  };

  const handleCancel = () => {
    onCancel && onCancel();
  };
  return (
    <Modal
      title="Security Deposit"
      open={isDepositVisible}
      onClose={handleCancel}
    >
      <Container>
        <ModalMainText variant="p">
          Your credit card will be used to charge the amount.
        </ModalMainText>
        <ColorFulText color="primary">
          <ModalMainText variant="p">
            {localeNumberFormat(booking?.price)}
          </ModalMainText>
        </ColorFulText>
        <ButtonsContainer>
          <StyledButton radius="round" variant="gray" onClick={handleCancel}>
            Cancel
          </StyledButton>
          <StyledButton
            radius="round"
            isLoading={depositLoading}
            onClick={() => {
              handleDeposit();
              handleCancel();
            }}
          >
            Okay
          </StyledButton>
        </ButtonsContainer>
      </Container>
    </Modal>
  );
};

export default SecurityDepositModal;
