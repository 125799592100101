import { useResponsive } from '@/hooks/useResponsive';
import Button from '@/newComponents/Button';
import Input from '@/newComponents/Input';
import Modal from '@/newComponents/Modal';
import Select from '@/newComponents/Select';
import Typography from '@/newComponents/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Apis, Hooks } from '@shared/front';
import { Field, Formik } from 'formik';
import React, { useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { ButtonsWrapper, StyledButton, StyledForm } from './style';

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  & .title {
    font-size: 1.3rem;
    font-weight: 500;
  }
`;

const MyPropertyReport = ({ ownerId, propertyId }: any) => {
  const [handleCreateReport, { loading }] = Hooks.useAPI();

  const initialValues = {
    title: '',
    desc: '',
    reportType: 'Property',
    propertyId: propertyId,
    ownerId: ownerId,
  };

  const [isAddIssueModalOpen, setIsAddIssueModalOpen] = useState(false);
  const { isTablet } = useResponsive();

  const handleSubmit = async (values: any) => {
    await handleCreateReport(Apis.ReportIssue.createReportIssue(values));
  };

  return (
    <>
      <TitleContainer>
        <Button
          variant="gray"
          radius="round"
          bgColor="red"
          onClick={() => setIsAddIssueModalOpen(true)}
        >
          <Typography variant={'p'} size={'sm'}>
            <FontAwesomeIcon icon="plus" style={{ marginRight: 5 }} />
            Report
          </Typography>
        </Button>
      </TitleContainer>

      <Modal
        title="Report this property"
        open={isAddIssueModalOpen}
        onClose={() => setIsAddIssueModalOpen(false)}
        style={{
          maxWidth: isTablet ? '600px' : '100%',
          width: '100%',
        }}
      >
        <Formik
          onSubmit={async (values, { resetForm }) => {
            await handleSubmit(values);
            resetForm();
            setIsAddIssueModalOpen(false);
          }}
          initialValues={initialValues}
          enableReinitialize
          validationSchema={yup.object().shape({
            title: yup.string().required('Please enter a title.'),
            desc: yup.string().required('Please enter description.'),
          })}
        >
          {({ values, errors, isSubmitting, setFieldValue }) => {
            return (
              <StyledForm>
                <div>
                  <Field
                    component={Input}
                    label="Title"
                    name="title"
                    htmlType="text"
                    value={values.title}
                    onChange={e => setFieldValue('title', e.target.value)}
                    error={errors.title}
                  />

                  <Field
                    component={Select}
                    options={[
                      {
                        label: 'Properties',
                        value: 'property',
                      },
                    ]}
                    label="Category"
                    name="reportType"
                    htmlType="select"
                    value={values.reportType}
                    onChange={e => setFieldValue('reportType', e)}
                  />

                  <Field
                    component={Input}
                    label="Description"
                    name="desc"
                    htmlType="text"
                    value={values.desc}
                    type="textarea"
                    onChange={e => setFieldValue('desc', e.target.value)}
                    style={{ height: 'unset' }}
                    rows={7}
                    error={errors.desc}
                  />
                </div>
                <ButtonsWrapper>
                  <StyledButton
                    variant="gray"
                    radius="round"
                    onClick={() => setIsAddIssueModalOpen(false)}
                  >
                    Cancel
                  </StyledButton>
                  <StyledButton
                    htmlType="submit"
                    radius="round"
                    isDisabled={loading || isSubmitting}
                    isLoading={loading || isSubmitting}
                  >
                    Submit
                  </StyledButton>
                </ButtonsWrapper>
              </StyledForm>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default MyPropertyReport;
