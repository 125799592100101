import Error from '@shared/web/components/Error';
import InputField from '@shared/web/components/InputField';
import { Field, Form, Formik } from 'formik';
import useAPI from '@/hooks/api';
import { omit } from 'lodash';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import AuthWrapper from './AuthWrapper';
import { toast } from 'react-toastify';
import LinkedIn from './LinkedIn';
import Modal from '@/newComponents/Modal';
import { useDispatch, useSelector } from 'react-redux';
import store from '@/store';
import styled from 'styled-components';
import { useResponsive } from '@/hooks/useResponsive';
import { devices } from '@/utils/theme';
import {
  LinkButton,
  PageTitle,
  StyledButton,
  StyledForm,
  SubText,
  Title,
} from './styles';
const loginValidationSchema = yup.object().shape({
  firstName: yup.string().required('Please enter your first name'),
  lastName: yup.string().required('Please enter your last name'),
  email: yup
    .string()
    .email('Email is not valid')
    .required('Please enter your email'),
  password: yup.string().required('Please enter your password'),
  confirmPassword: yup
    .string()
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value;
    }),
  remember: yup.boolean(),
});

const SignupModal = () => {
  const navigate = useNavigate();
  const [handleSubmitRequest, { loading, error }] = useAPI();
  const [linkedInError, setLinkedInError] = useState('');
  const dispatch = useDispatch();
  const { isTablet } = useResponsive();
  const authState = useSelector(store.Select.authModal);
  const handleSubmit = async (values, formikHelpers) => {
    await handleSubmitRequest({
      method: 'post',
      url: '/v1/auth/register',
      data: { ...omit(values, ['confirmPassword']) },
    });
    toast(
      'Thanks for joining Rentisity. We have send you verification email, you can continue login using that email.',
    );
    openOTPModal({ status: true, type: 'verifyOTP', email: values?.email });
    // closeAuthModal();

    // navigate(`/verifyAccount`, {
    //   state: {
    //     email: values.email,
    //   },
    // });
  };
  const openAuthModal = () => {
    dispatch(store.Actions.set('authModal', { status: true, type: 'login' }));
  };
  const openOTPModal = data => {
    dispatch(store.Actions.set('authModal', data));
  };

  const closeAuthModal = () => {
    dispatch(
      store.Actions.set('authModal', { status: false, type: undefined }),
    );
  };

  return (
    <Modal
      isCentered={true}
      size={isTablet ? 'md' : 'lg'}
      style={{
        maxWidth: isTablet ? '600px' : '100%',
        width: '100%',
      }}
      open={authState?.status && authState?.type === 'register'}
      onClose={closeAuthModal}
    >
      <RegisterWrapper>
        <Formik
          initialValues={{ email: '', password: '' }}
          onSubmit={handleSubmit}
          validationSchema={loginValidationSchema}
        >
          {({ handleSubmit, isSubmitting, errors, touched }) => {
            return (
              <StyledForm onSubmit={handleSubmit}>
                <div>
                  <PageTitle variant="p">Welcome to Rentisity</PageTitle>
                  <Title
                    variant="p"
                    color="gray"
                    style={{ fontWeight: 'normal' }}
                  >
                    Already a member?{' '}
                    <LinkButton
                      style={{
                        color: '#1F1F1F',
                        cursor: 'pointer',
                      }}
                      onClick={openAuthModal}
                    >
                      Login
                    </LinkButton>
                  </Title>
                  <Field
                    component={InputField}
                    label="First Name"
                    name="firstName"
                  />
                  <Field
                    component={InputField}
                    label="Last Name"
                    name="lastName"
                  />
                  <Field component={InputField} label="Email" name="email" />

                  <Field
                    component={InputField}
                    name="password"
                    type="password"
                    label="Password"
                  />
                  <Field
                    component={InputField}
                    name="confirmPassword"
                    type="password"
                    label="Confirm Password"
                  />
                  <Error message={error} isFormError />
                  <SubText variant="p">
                    By submitting the form, I accept Terms and Conditions.
                  </SubText>
                </div>
                <div>
                  <StyledButton
                    htmlType="submit"
                    isDisabled={loading || isSubmitting}
                    isLoading={loading || isSubmitting}
                    style={{ width: '100%' }}
                  >
                    Create Account
                  </StyledButton>
                  <SubText
                    style={{
                      textAlign: 'center',
                      margin: '15px 0',
                    }}
                    variant="p"
                  >
                    Or
                  </SubText>
                  <LinkedIn title="Create with LinkedIn" />
                </div>
              </StyledForm>
            );
          }}
        </Formik>
      </RegisterWrapper>
    </Modal>
  );
};

export default SignupModal;

const RegisterWrapper = styled.div``;

const TermsConditions = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: 20px;

  @media ${devices.mobileSM} {
  }
`;
