import React from 'react';
import styled from 'styled-components';
import logo from './assets/Group.svg';
import Facebook from './assets/facebook.svg';
import Instagram from './assets/instagram.svg';
import Linkedin from './assets/linkedin.svg';
import Twitter from './assets/twitter.svg';
import Youtube from './assets/youtube.svg';
import Apple from './assets/Apple.png';
import Android from './assets/Android.png';
import Typography from './Typography';
import { devices } from '../utils/theme';
import { Link } from 'react-router-dom';

interface FooterProps extends React.ComponentPropsWithoutRef<'div'> {
  style?: React.CSSProperties;
}

const COMPANY_LINKS = [
  {
    title: 'About',
    link: '/',
  },
  {
    title: 'Careers',
    link: '/',
  },
  {
    title: 'FAQs',
    link: '/',
  },
  {
    title: 'Advertise',
    link: '/',
  },
  {
    title: 'Terms & Privacy',
    link: '/terms-and-privacy',
  },
];

const EXPLORE_LINKS = [
  {
    title: 'Tenants',
    link: '/',
  },
  {
    title: 'Landlords',
    link: '/',
  },
  {
    title: 'Home Service Providers',
    link: '/terms',
  },
  {
    title: 'Utilities',
    link: '/',
  },
  {
    title: 'Find A Property',
    link: '/',
  },
];

const Footer = ({}: FooterProps) => {
  return (
    <MainWrapper>
      <Logo>
        <img src={logo} alt="logo" />
        <Heading>Rentisity</Heading>
      </Logo>
      <FooterContainer>
        <DescContainer>
          <Typography variant="p" size="sm" color="#949494" style={{}}>
            Use rentisity to communicate with tenants and have a record of all
            issues that can be passed onto tradespeople to help with. Save time,
            manage deposits and payments easily.Use rentisity to communicate
            with tenants and have a record of all issues that can be passed onto
            tradespeople to help with. Save time, manage deposits and payments
            easily.
          </Typography>
        </DescContainer>

        <Wrapper>
          <ExploreAndCompanyWrapper>
            <Explore>
              <Heading>Explore</Heading>
              {EXPLORE_LINKS?.map(explore => {
                return (
                  <Link to={explore?.link} key={explore?.title}>
                    <Typography variant="span" size="sm" color="#949494">
                      {explore?.title}
                    </Typography>
                  </Link>
                );
              })}
            </Explore>
            <Explore>
              <Heading>Company</Heading>
              {COMPANY_LINKS?.map(companyTitle => {
                return (
                  <Link to={companyTitle?.link} key={companyTitle?.title}>
                    <Typography variant="span" size="sm" color="#949494">
                      {companyTitle?.title}
                    </Typography>
                  </Link>
                );
              })}
            </Explore>
          </ExploreAndCompanyWrapper>
          <StoreContainer>
            <Heading>Mobile Apps</Heading>
            <Store>
              <a
                href="https://apps.apple.com/us/app/rentisity/id1605487320"
                target="_blank"
              >
                <img src={Apple} alt="apple" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.rentisity"
                target="_blank"
              >
                <img src={Android} alt="android" />
              </a>
            </Store>
            <Follow>
              <Heading>Follow Us</Heading>
              <SocialIcons>
                <a href="https://www.facebook.com/rentisity" target="_blank">
                  <img src={Facebook} alt="Facebook logo" />
                </a>
                {/* <a href="">
                  <img src={Instagram} alt="logo" />
                </a> */}
                <a
                  href="https://www.linkedin.com/company/rentisity/"
                  target="_blank"
                >
                  <img src={Linkedin} alt="LinkedIn logo" />
                </a>
                {/* <a href="">
                  <img src={Twitter} alt="logo" />
                </a>
                <a href="">
                  <img src={Youtube} alt="logo" />
                </a> */}
              </SocialIcons>
            </Follow>
          </StoreContainer>
        </Wrapper>
      </FooterContainer>
      <Typography
        variant="p"
        size="sm"
        color="gray"
        style={{ textAlign: 'center', padding: '20px 20px' }}
      >
        &copy; Rentisity 2023 All rights reserved
      </Typography>
    </MainWrapper>
  );
};

export default Footer;

const MainWrapper = styled.div`
  font-size: 12px;
  background: #f5f5f5;
  padding: 30px 30px 0px;
  @media ${devices.laptop} {
    padding: 50px 80px 0px;
  }
`;

const Logo = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  & img {
    height: 37px;
    width: 37px;
  }
  margin-bottom: 10px;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 40px;
  gap: 30px;
  @media ${devices.laptop} {
    flex-direction: row;
  }
`;
const DescContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  @media ${devices.laptop} {
    width: 305px;
  }
`;
const Explore = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const StoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 305px;
`;

const Heading = styled.div`
  color: #2061f8;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 500;
  margin-bottom: 12px;
`;

const Store = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 30px;

  @media ${devices.mobileXS} {
    flex-direction: row;
    justify-content: start;
    gap: 20px;
  }

  @media only screen and (max-width: 320px) {
    flex-direction: column;
    & img {
      height: 70px;
      object-fit: contain;
    }
  }
`;

const Follow = styled.div``;
const SocialIcons = styled.div`
  display: flex;
  gap: 10px;
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  gap: 50px;
  @media ${devices.laptop} {
    flex-direction: row;
  }
  @media ${devices.tablet} {
    flex-direction: row;
    gap: 20px;
  }
`;
const ExploreAndCompanyWrapper = styled.div`
  display: flex;
  justify-content: start;
  gap: 30%;

  flex: 1;
  @media ${devices.laptop} {
    gap: 0%;

    justify-content: space-evenly;
  }
`;
