import { useState } from 'react';
import React from 'react';
import styled from 'styled-components';
import {
  SectionWrapper,
  SectionTitle,
  SectionDescription,
  Image,
} from './style';
const NearByList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  column-gap: 20px;
  row-gap: 15px;
  margin-bottom: 20px;
`;
const NearByItem = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
`;
const ButtonWrapper = styled.div`
  cursor: pointer;
`;
const NearByProperties = ({ nearBy }: any) => {
  const [needShowMore, setNeedShowMore] = useState(false);

  return (
    <SectionWrapper>
      {nearBy && nearBy.length === 0 ? (
        ''
      ) : (
        <>
          {' '}
          <SectionTitle>What's nearby</SectionTitle>
          <NearByList>
            {nearBy
              ?.slice(0, !!needShowMore ? nearBy?.length : 9)
              ?.map((item: any) => (
                <NearByItem key={item.name}>
                  <Image style={{ height: 20 }} src={item?.icon} />
                  <SectionDescription style={{ textAlign: 'left' }}>
                    {item?.name}
                  </SectionDescription>
                </NearByItem>
              ))}
          </NearByList>
          <ButtonWrapper>
            {nearBy?.length > 9 && (
              <a
                onClick={() => {
                  setNeedShowMore(!needShowMore);
                }}
              >
                {!needShowMore ? 'Show More' : 'Show Less'}
              </a>
            )}
          </ButtonWrapper>
        </>
      )}
    </SectionWrapper>
  );
};

export default NearByProperties;
