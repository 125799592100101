// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik } from 'formik';
import React from 'react';
// import styled from 'styled-components';
// import InputField from '@/components/InputField';
import Modal from '@/newComponents/Modal';
import Select from '@/newComponents/Select';
import { Constants } from '@shared/common';
import { ButtonsContainer, StyledButton } from './styles';
const FilterModel = ({
  isModalOpen,
  initialValues,
  onClose,
  onSubmit,
  onReset,
  formikRef,
}) => {
  // const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    // <>
    //   <div style={{ marginLeft: '10px' }}>
    //     <Button
    //       style={{ height: '40px' }}
    //       icon={
    //         <FontAwesomeIcon
    //           icon={['fas', 'sliders']}
    //           style={{ marginRight: '10px' }}
    //         />
    //       }
    //       onClick={() => setIsModalOpen(true)}
    //     >
    //       <Typography.Text>Filter</Typography.Text>
    //     </Button>
    //   </div>
    //   {
    <Modal title="Filters" open={isModalOpen} onClose={onClose}>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        // onSubmit={values => {
        //   // console.log('the values ate', values);
        //   setParams({ ...params, ...values });
        //   setIsModalOpen(false);
        // }}
        onSubmit={values => onSubmit(values)}
      >
        {({ values, setFieldValue }) => {
          // console.log("the valuea arewqa",values)
          return (
            <Form>
              <div style={{ width: '100%' }}>
                <Field
                  component={Select}
                  options={[
                    {
                      label: 'All',
                      value: 'all',
                      selected: true,
                      disabled: true,
                    },
                    ...Constants.jobDropdown.ServiceByPrice(),
                  ]}
                  label="Sort By"
                  name="sort"
                  onChange={e => setFieldValue('sort', e)}
                  value={values.sort}
                />
                <Field
                  component={Select}
                  options={[
                    {
                      label: 'All',
                      value: 'all',
                      selected: true,
                      disabled: true,
                    },
                    ...Constants.jobDropdown.PriceType(),
                  ]}
                  label="Price Type"
                  name="priceType"
                  onChange={e => setFieldValue('priceType', e)}
                  value={values.priceType}
                />
              </div>

              <ButtonsContainer>
                <div style={{ display: 'flex', gap: '16px' }}>
                  <StyledButton variant="gray" radius="round" onClick={onClose}>
                    Cancel
                  </StyledButton>
                  <StyledButton
                    radius="round"
                    onClick={() => {
                      formikRef.current?.resetForm();
                      onReset();
                    }}
                  >
                    Reset
                  </StyledButton>
                </div>
                <StyledButton radius="round" htmlType="submit">
                  Submit
                </StyledButton>
              </ButtonsContainer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
    // }
    //   </>
  );
};

export default FilterModel;
