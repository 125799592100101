import React from 'react';
import styled from 'styled-components';
import Typography from './Typography';
export type TabsPaneType = {
  key: string;
  label: string;
  children?: React.ReactNode;
};

type Props = {
  activeKey: string;
  activeTabColor?: string;
  items: Array<TabsPaneType>;
  onChange: (key: string) => void;
  highlightFullSelectedTab?: boolean;
  className?: string;
};

const Tabs = ({
  activeKey,
  items,
  activeTabColor = '#2857FF',
  onChange,
  highlightFullSelectedTab = true,
  className,
}: Props) => {
  const renderItems = actKey => {
    const activeTab = items.find(item => item?.key === activeKey);

    return activeTab?.children;
  };

  return (
    <TabsStyled
      $activeTabColor={activeTabColor}
      $highlightFullSelectedTab={highlightFullSelectedTab}
      className={className}
    >
      <div className="tabs-label-container">
        {items.map(item => (
          <div
            key={item.key}
            className={`tab ${item?.key === activeKey ? 'active' : ''} `}
            onClick={() => onChange(item?.key)}
          >
            <Typography
              variant="p"
              color={
                item?.key === activeKey
                  ? activeTabColor || '#000000'
                  : '#c7c7c7'
              }
              size="sm"
            >
              {item?.label}
            </Typography>
          </div>
        ))}
      </div>
      {Array.isArray(items) && (
        <div className="tabs-content-container">{renderItems(activeKey)}</div>
      )}
    </TabsStyled>
  );
};

export default Tabs;

const TabsStyled = styled.div<{
  $activeTabColor: string;
  $highlightFullSelectedTab: boolean;
}>`
  & .tabs-label-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    border-bottom: 1px solid #dbdbdb;
    margin-bottom: 12px;
    & .tab {
      position: relative;
      min-width: fit-content;
      cursor: pointer;
      padding: 18px 0px;
      flex: ${({ $highlightFullSelectedTab }) =>
        $highlightFullSelectedTab ? 1 : 'none'};

      &.active {
        color: ${({ $activeTabColor }) => $activeTabColor || '#000000'};

        &::after {
          content: '';
          position: absolute;
          bottom: -1px;
          left: 0px;
          right: 0px;
          height: 3px;
          color: ${({ $activeTabColor }) => $activeTabColor || '#000000'};
          border-bottom: 3px solid
            ${({ $activeTabColor }) => $activeTabColor || '#000000'};
        }
      }
    }
  }

  & .tabs-content-container {
    width: 100%;
  }
`;
