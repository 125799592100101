import Button from '@/newComponents/Button';
import Typography from '@/newComponents/Typography';
import { devices } from '@/utils/theme';
import styled from 'styled-components';
import { TenantsListContainer } from '../bookingStepsView/styles';

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
`;

export const StyledButton = styled(Button)`
  font-size: 12px;
  line-height: 25px;
`;

export const ModalInfoContainer = styled.div`
  margin-top: 20px;
`;

export const Container = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ModalMainText = styled(Typography)`
  font-size: 14px;
  line-height: 25px;

  @media ${devices.laptop} {
    font-size: 16px;
    line-height: 30px;
  }
`;

export const ModalSubText = styled(Typography)`
  font-size: 12px;
  line-height: 25px;
  color: #949494;

  @media ${devices.laptop} {
    font-size: 14px;
    line-height: 30px;
  }
`;

export const TenantsContainerStyled = styled(TenantsListContainer)`
  max-height: calc(100vh - 400px);
  overflow-y: auto;
`;

export const SaveButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
`;

export const StyledLabel = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 8px;
`;
