import Divider from '@/newComponents/Divider';
import Typography from '@/newComponents/Typography';
import React from 'react';
import styled from 'styled-components';

const FeatureContainer = styled.div`
  padding: 20px 0px;
  margin-bottom: 80px;
`;
const FeatureItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const FeatureCard = ({ featureData }: any) => {
  return (
    <FeatureContainer>
      {featureData.map((item: any) => {
        return (
          <>
            <FeatureItem key={item.title}>
              <div>
                <Typography style={{ textTransform: 'capitalize' }}>
                  {item.title}
                </Typography>
              </div>
              <div>
                <Typography>{item.desc}</Typography>
              </div>
            </FeatureItem>
            <Divider />
          </>
        );
      })}
    </FeatureContainer>
  );
};

export default FeatureCard;
