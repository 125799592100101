import React, { useState, useEffect } from 'react';
import Modal from '@/newComponents/Modal';
import Button from '@/newComponents/Button';
import { Formik, Form, Field } from 'formik';
import { ModalFooter } from '../style';
import * as yup from 'yup';
import InputField from '@/components/InputField';
import { toast } from 'react-toastify';
import { Store, Utils, Apis } from '@shared/front';
import { useResponsive } from '@/hooks/useResponsive';
import TextField from '@shared/web/components/TextField';
import Typography from '@/newComponents/Typography';
interface RoomModalProps {
  setOpen: any;
  open: boolean;
  propertyId: number;
  isEditMode?: boolean;
  setIsEditMode?: any;
  setSelectedItem?: (data: any) => void;
  selectedItem?: any;
  hasAccess: boolean;
}
const createRoomSchema = yup.object().shape({
  title: yup.string().required('Title is required.'),
  desc: yup.string(),
});
const RoomModal = ({
  setOpen,
  open,
  propertyId,
  isEditMode,
  setIsEditMode,
  selectedItem,
  setSelectedItem,
  hasAccess,
}: RoomModalProps) => {
  const { isTablet } = useResponsive();

  const [initialValues, setInitialValues] = useState({
    title: '',
    desc: '',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const handleCreateOrUpdateInventoryCategory = async (values: any) => {
    setLoading(true);
    const finalData = { ...values };
    if (!finalData.desc) {
      delete finalData.desc;
    }
    console.log({ finalData });

    try {
      const resp = await Utils.request(
        !!isEditMode
          ? Apis.Properties.updateInventoryCategory(selectedItem?.id, finalData)
          : Apis.Properties.addInventoryCategory({
              ...finalData,
              propertyId: propertyId,
            }),
      );
      if (!!isEditMode) {
        Store.store.dispatch(
          Store.Actions.update('inventoryCategories', (currentData: any) => {
            const findIndex = currentData?.findIndex(
              (item: any) => item.id === selectedItem?.id,
            );
            const updateData = [...currentData];
            updateData[findIndex] = {
              ...updateData[findIndex],
              title: values?.title,
              desc: values?.desc ?? '',
            };
            return updateData;
          }),
        );
      } else {
        Store.store.dispatch(
          Store.Actions.update('inventoryCategories', (currentData: any) => {
            return [
              ...currentData,
              ...[{ ...resp, title: values?.title, desc: values?.desc ?? '' }],
            ];
          }),
        );
      }
      setOpen(false);
      setLoading(false);
      setIsEditMode(false);
      setSelectedItem && setSelectedItem({});
      toast.success('Inventory category created successfully');
    } catch (err) {
      toast.error('Failed');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!!isEditMode) {
      setInitialValues({
        title: selectedItem?.title,
        desc: selectedItem?.desc,
      });
    }
  }, [selectedItem]);

  return (
    <>
      {!!open && (
        <Modal
          isCentered
          title={`${isEditMode ? 'Update' : 'Add'} Inventory Category`}
          open={open}
          onClose={() => {
            setOpen(false);
            setSelectedItem && setSelectedItem({});
            setIsEditMode(false);
          }}
          style={{ width: !isTablet ? '95vw' : '60vw' }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={createRoomSchema}
            onSubmit={async (values, { resetForm }) => {
              await handleCreateOrUpdateInventoryCategory(values);
              resetForm();
            }}
            validateOnBlur={false}
            enableReinitialize
          >
            {({ values, handleSubmit, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <Field
                  component={InputField}
                  label="Title"
                  name="title"
                  type="text"
                  placeholder="Title"
                  value={values?.title}
                />
                <Field
                  component={TextField}
                  label="Description"
                  name="desc"
                  placeholder="Description"
                  value={values?.desc}
                  maxLength={300}
                />
                <Typography
                  variant="p"
                  color="#666"
                  size="sm"
                  style={{ textAlign: 'right' }}
                >{`${values?.desc?.length || 0}/300`}</Typography>
                <ModalFooter>
                  <Button isLoading={loading} style={{ marginLeft: 'auto' }}>
                    Submit
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};

export default RoomModal;
