import produce from 'immer';
import { SET, RESET, UPDATE } from './constants';
import initialState from './initialState';

const appReducer = (
  state = initialState,
  action: {
    key: keyof typeof initialState;
    data?: any;
    type: string;
  },
) =>
  produce(state, (draft: any) => {
    switch (action.type) {
      case SET:
        draft[action.key] = action.data;
        break;
      case RESET:
        draft[action.key] = initialState[action.key];
        break;
      case UPDATE: {
        if (draft[action.key]) {
          try {
            if (typeof action.data === 'function') {
              draft[action.key] = action.data(draft[action.key]);
            } else {
              if (Array.isArray(draft[action.key])) {
                draft[action.key] = [...draft[action.key], ...action.data];
              } else if (typeof initialState === 'object') {
                draft[action.key] = { ...draft[action.key], ...action.data };
              }
            }
          } catch (err) {
            console.log('state update failed err', err);
          }
          break;
        }
      }
    }
  });

export default appReducer;
