import Button from '@/newComponents/Button';
import Typography from '@/newComponents/Typography';
import { devices } from '@/utils/theme';
import styled from 'styled-components';

export const Title = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  color: #333;

  @media ${devices.laptop} {
    font-size: 16px;
    font-weight: 500;
  }
`;

export const Info = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  line-height: 25px;
  color: #949494;

  @media ${devices.laptop} {
    font-size: 14px;
  }
`;

export const InfoLabel = styled(Info)`
  color: #333;
`;

export const AddButton = styled(Button)`
  min-width: unset;
`;

export const EditDeleteButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const UnStyledButton = styled(Button)`
  all: unset;
  cursor: pointer;
`;

export const StyledButton = styled(Button)`
  font-size: 12px;
  line-height: 25px;
`;

export const StyledLabel = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 8px;
`;

export const SaveButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
`;

export const ProfileActionWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;

  @media screen and (max-width: 500px) {
    justify-content: center;
  }
`;
