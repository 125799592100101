import { ReturnType } from "./types";

export default {
  getRentalHistories: (): ReturnType => {
    return {
      method: "get",
      url: "/v1/auth/rental-histories",
      config: {
        store: {
          action: "set",
          key: "rentalHistories",
        },
      },
    };
  },

  createOrUpdate: (obj: any, id?: number | undefined | null): ReturnType => {
    return {
      method: id ? "patch" : "post",
      url: `/v1/auth/rental-histories/${id || ""}`,
      data: obj,
      config: {
        successMsg: `Rental history has been ${id ? "updated" : "added"}`,
        navigateBack: true,
      },
    };
  },

  delete: (id: number): ReturnType => {
    return {
      method: "delete",
      url: `/v1/auth/rental-histories/${id}`,
      config: {
        successMsg: "Your rental history has been deleted successfully.",
        navigateBack: true,
      },
    };
  },
  getRentalHistoriesById: (id: string): ReturnType => {
    return {
      method: "get",
      url: `/v1/auth/rental-histories/${id}`,
    };
  },
};
