import { options } from 'numeral';
import * as yup from 'yup';
import { Constants } from '@shared/common';

const schema = {
  basicInfo: yup.object().shape({
    bedrooms: yup.number().required('Please enter Bedrooms'),
    bathrooms: yup.number().required('Please enter Bathrooms'),
    area: yup.number().required('Please enter Area'),

    price: yup.number().required('Please enter price'),
    desc: yup.string().required('Please enter description'),
    propertyType: yup.string().required('Please select a property type'),
    berNo: yup.string().required('Please select a property type'),
    furnishedType: yup.string().required('Please select a furnished type'),
    rentalMarket: yup.string().required('Please select a rental market'),
  }),
  amenities: yup.object().shape({
    amenities: yup
      .array()
      .min(1, 'Please add features')
      .required('Please add atleast one features'),
  }),
  location: yup.object().shape({
    address: yup.string().required('Please select address'),
  }),
  utility: yup.object().shape({
    utilities: yup
      .array()
      .min(1, 'pleaswe select one')
      .required('Please add features'),
  }),
  photos: yup.object().shape({
    //   images: yup.array().notOneOf([null], 'Please select an image'),
    // }),
    images: yup
      .array()
      .min(1, 'Please upload photos')
      .required('Please upload photos'),
  }),
};
export default schema;
