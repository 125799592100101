import { useRoot } from '@/RootProvider';
import request from '@shared/front/utils/request';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Account from './Account';
import Documents from './Douments';
import Phone from './Phone';
import Role from './Role';
import Typography from '@/newComponents/Typography';
const Container = styled.div`
  padding: 20px;
  display: flex;
  min-height: 600px;
  padding-top: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Content = styled.div`
  width: 640px;
  padding: 0 40px;
`;

const Steps = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  position: relative;
`;
const StepCircle = styled.div<{ active: boolean }>`
  height: 50px;
  width: 50px;
  border-radius: 50px;
  margin: 10px 20px;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.primary : 'white'};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  h4 {
    color: ${({ active, theme }) => (active ? 'white' : 'black')};
  }
`;

const StepLine = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.primary};
  position: absolute;
  top: 35px;
  left: 40px;
  right: 40px;
  z-index: 1;
  /* width: 100%; */
`;
const Step = styled.div`
  text-align: center;
  z-index: 9;
`;
const AddProperty = () => {
  const navigate = useNavigate();

  // @ts-ignore

  const { auth, checkAuth } = useRoot();
  const [step, setStep] = useState(0);

  const [initialValues, setInitialValues] = useState({
    role: auth?.role,
    avatar: auth?.avatar || [],
    RTB: auth?.RTB || '',
  });

  useEffect(() => {
    if (auth?.onboardingStep && !step) {
      setStep(auth?.onboardingStep || 0);
    }
  }, [auth]);

  useEffect(() => {
    if (step === steps.length) {
      navigate('/dashboard');
    }
  }, [step]);

  const onSubmitStep = async () => {
    await request({
      method: 'put',
      url: '/v1/auth/profile',
      data: {
        onboardingStep: step + 1,
      },
    });
    await checkAuth?.();

    if (step === steps.length - 1) {
      navigate('/dashboard');
    } else {
      setStep(step + 1);
    }
  };

  const childProps = {
    initialValues,
    setInitialValues,
    onSubmitStep,
  };
  const steps = [
    {
      component: <Role {...childProps} />,
      title: 'Role',
    },
    {
      component: <Phone {...childProps} />,
      title: 'Phone',
    },
    {
      component: <Account {...childProps} />,
      title: 'Account',
    },
    // auth?.role === 'service' && {
    //   component: <Profile {...childProps} />,
    //   title: 'Profile',
    // },
    {
      component: <Documents {...childProps} />,
      title: 'Documents',
    },
    // {
    //   //@ts-ignore
    //   component: <CreditCard {...childProps} />,
    //   title: 'Payment',
    // },

    // {
    //   component: <Account {...childProps} />,
    //   title: 'Account',
    // },
    // {
    //   component: <Photos {...childProps} />,
    //   title: 'Photos',
    // },
  ].filter(Boolean);
  // @ts-ignore
  // if (propertyId && !initialValues?.id) {
  //   return <LoadingPlaceholder />;
  // }
  return (
    <Container>
      <Steps>
        {steps?.map((stepItem: any, index) => (
          <Step key={stepItem?.title}>
            <StepCircle active={step === index}>
              <Typography
                variant="h4"
                fontWeight="extrabold"
                style={{ marginBottom: 0 }}
              >
                {index + 1}
              </Typography>
            </StepCircle>
            <Typography variant="p">{stepItem.title}</Typography>
          </Step>
        ))}
        <StepLine />
      </Steps>
      <Content>
        {/* @ts-ignore  */}
        {steps[step]?.component}
      </Content>
    </Container>
  );
};

export default AddProperty;
