import useAPI from '@/hooks/api';
import React, { useEffect } from 'react';
import WishListView from './wishListItems';
import WishListServiceView from './WishListServiceView';
import NoData from '@/newComponents/NoData';
import PropertyWishlistSkeleton from './PropertyWishlistSkeleton';
import { PageContainer, PageTitle, WishListContainer } from './styles';

const WishList = () => {
  const [makeRequest, { data: wishListData, loading: wishListLoading }] =
    useAPI();

  const fetchWishListRequest = () => {
    makeRequest({
      method: 'get',
      url: '/v1/common/bookmarks',
    });
  };

  useEffect(() => {
    fetchWishListRequest();
  }, []);

  return (
    <PageContainer>
      <PageTitle variant="h1">My WishList</PageTitle>
      {!!wishListLoading && <PropertyWishlistSkeleton />}
      {!wishListLoading && !wishListData?.length && (
        <NoData title="Looks like your wishlist is empty." />
      )}
      {!!wishListData && (
        <WishListContainer>
          {wishListData?.map((item: any) => {
            if (item.property !== null) {
              return (
                <WishListView
                  key={item?.property?.id}
                  propertyWishItem={item?.property}
                  refreshData={() => {
                    fetchWishListRequest();
                  }}
                />
              );
            }
            if (item.service !== null) {
              return (
                <WishListServiceView
                  key={item?.service?.id}
                  propertyWishItem={item?.service}
                  refreshData={() => {
                    fetchWishListRequest();
                  }}
                />
              );
            }
          })}
        </WishListContainer>
      )}
    </PageContainer>
  );
};

export default WishList;
export interface optionTypes {
  featureoption: FeatureType[];
  sortingoption: FeatureType[];
}

interface FeatureType {
  data: OptionDataType[];
  placeholder: string;
  key: string;
}
interface OptionDataType {
  value: string;
  label: string;
}

const options: optionTypes = {
  featureoption: [
    {
      data: [
        {
          value: 'all',
          label: 'All',
        },
        {
          value: 'cancelled',
          label: 'Cancelled',
        },
        {
          value: 'completed',
          label: 'Completed',
        },
      ],
      placeholder: 'Booking Status',
      key: 'bookingStatus',
    },
  ],

  sortingoption: [
    {
      data: [
        {
          value: 'price_desc',
          label: 'Price High to Low',
        },
        {
          value: 'price_asce',
          label: 'Price Low to High',
        },
      ],
      placeholder: 'Price Status',
      key: 'priceStatus',
    },
    {
      data: [
        {
          value: 'Rating High to Low',
          label: 'Rating High to Low',
        },
        {
          value: 'Rating Low to High',
          label: 'Rating Low to High',
        },
      ],
      placeholder: 'Review Status',
      key: 'reviewStatus',
    },
  ],
};
