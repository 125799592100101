import Button from '@/newComponents/Button';
import Typography from '@/newComponents/Typography';
import { devices } from '@/utils/theme';
import styled from 'styled-components';

export const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

export const PageTitle = styled(Typography)`
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
  margin-bottom: 40px;

  @media ${devices.laptop} {
    font-size: 26px;
    line-height: 30px;
    font-weight: 500;
    margin-bottom: 60px;
  }
`;

export const WishListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media ${devices.laptop} {
    gap: 20px;
  }
`;

export const WishListItemContainer = styled.div`
  display: flex;
  padding: 0px 20px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  &:last-child {
    border-bottom: none;
  }

  @media ${devices.laptop} {
    padding-bottom: 20px;
  }
`;

export const Image = styled.img`
  height: 100%;
  width: 100%;
  max-width: 140px;
  max-height: 90px;
  aspect-ratio: 1.5 / 1;
  object-fit: cover;

  @media ${devices.laptop} {
    max-width: 180px;
    max-height: 120px;
  }
`;

export const Content = styled.div`
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;

  @media ${devices.laptop} {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
`;

export const Actions = styled.div`
  display: flex;
  gap: 5px;

  @media ${devices.laptop} {
    flex-direction: column;
  }
`;

export const StyledButton = styled(Button)`
  font-size: 12px;
  line-height: 25px;

  @media ${devices.laptop} {
    font-size: 14px;
    line-height: 30px;
  }
`;

export const WishItemTitle = styled(Typography)`
  font-size: 14px;

  @media ${devices.laptop} {
    font-size: 18px;
  }
`;

export const WishItemDescription = styled(Typography)`
  font-size: 12px;

  @media ${devices.laptop} {
    font-size: 14px;
  }
`;
