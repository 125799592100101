import { ReturnType } from "./types";

export default {
  getTransaction: (
    params: any,
    action: "set" | "update",
    authId: number
  ): ReturnType => {
    return {
      method: "get",
      url: `/v1/payments/${authId}`,
      params: params,
      config: {
        store: {
          action: action || "set",
          key: "transactions",
        },
      },
    };
  },
  getEscrowAmount: (): ReturnType => {
    return {
      method: "get",
      url: "/v1/auth/escrow-amount",
    };
  },
};
