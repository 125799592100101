import React from 'react';
import styled from 'styled-components';
import {
  SectionTitle,
  SectionWrapper,
  Image,
  SectionDescription,
} from './style';
import { useNavigate } from 'react-router-dom';
const SimilarWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
  column-gap: 20px;
  row-gap: 15px;
  margin-bottom: 60px;
`;
const SimilarItem = styled.div`
  overflow: hidden;
  cursor: pointer;
`;
const SimilarImageWrapper = styled.div`
  overflow: hidden;
`;
const SimilarImage = styled(Image)`
  width: 100%;
  height: 200px;
  border-radius: 8px;
  transition: 0.6s;
  &:hover {
    transform: scale(1.1);
  }
`;
const SimilarPropertyTitle = styled.h3`
  font-size: 16px;
  line-height: 23px;
  margin-top: 10px;
`;
const SimilarContentWrapper = styled.div``;
interface SimilarProperties {
  properties: Array<any>;
}

const SimilarProperties = ({ properties }: SimilarProperties) => {
  console.log(properties?.length, 'properties');
  const navigate = useNavigate();

  return (
    <SectionWrapper>
      {properties && properties?.length === 0 ? (
        ''
      ) : (
        <>
          <SectionTitle>Similar Properties</SectionTitle>
          <SimilarWrapper>
            {properties?.map((property: any) => (
              <SimilarItem
                key={property?.id}
                onClick={() => {
                  navigate(`/properties/${property?.id}`);
                }}
              >
                <SimilarImageWrapper>
                  <SimilarImage src={property?.images[0]?.URL} />
                </SimilarImageWrapper>
                <SimilarContentWrapper>
                  <SimilarPropertyTitle>
                    {property?.address}
                  </SimilarPropertyTitle>
                  <SectionDescription>${property?.price}</SectionDescription>
                </SimilarContentWrapper>
              </SimilarItem>
            ))}
          </SimilarWrapper>
        </>
      )}
    </SectionWrapper>
  );
};

export default SimilarProperties;
