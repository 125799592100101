import Button from '@/newComponents/Button';
import Typography from '@/newComponents/Typography';
import { devices } from '@/utils/theme';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const ProfileHeadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  border-radius: 10px;
  box-shadow: 1px 1px 8px 2px #e0e0e0;
  padding: 10px;

  @media ${devices.laptop} {
    align-items: start;
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  @media ${devices.laptop} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const ButtonCardStyle = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
  width: 100%;

  @media ${devices.laptop} {
    grid-template-columns: auto auto auto auto;
  }
`;

export const StyledButton = styled(Button)`
  font-size: 12px;
  line-height: 30px;
  white-space: nowrap;

  @media ${devices.laptop} {
    font-size: 14px;
    line-height: 30px;
  }
`;

export const SectionTitle = styled(Typography)`
  font-size: 20px;
  line-height: 25px;

  @media ${devices.laptop} {
    font-size: 26px;
    line-height: 30px;
  }
`;
