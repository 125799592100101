import { Apis, Store, Utils } from '@shared/front';
// import Button from '@/newComponents/Button';
import DatePickerField from '@shared/web/components/DatePicker';
// import InputField from '@shared/web/components/InputField';
import Modal from '@/newComponents/Modal';
import Typography from '@/newComponents/Typography';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
// import { useParams } from 'react-router-dom';
import { useRoot } from '@/RootProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { devices } from '@/utils/theme';
import { useResponsive } from '@/hooks/useResponsive';
import Input from '@/newComponents/Input';
import EditIconNew from '../../../assets/edit-icon-new.svg';
import DeleteIconNew from '../../../assets/delete-icon-new.svg';
import {
  AddButton,
  EditDeleteButtonsContainer,
  Info,
  InfoLabel,
  SaveButtonContainer,
  StyledButton,
  StyledLabel,
  Title,
  UnStyledButton,
} from '../index.styles';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  @media ${devices.laptop} {
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
  }

  & .info-wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
`;
const EmploymentHistories = ({
  employerData,
  profileId: userId,
  role,
}: any) => {
  const { auth } = useRoot();

  const formikRef = useRef();
  const [initialState, setInitialState] = useState({
    id: '',
    jobTitle: '',
    companyName: '',
    companyAddress: '',
    startMonth: '',
    endMonth: '',
    isActive: true,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [itemId, setItemId] = useState();

  const { isTablet } = useResponsive();

  const employmentHistories: any = useSelector(
    Store.Select.employmentHistories,
  );
  //modal for adding data
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  //delete modal

  const handleOkDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };
  const showDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };
  const handleCancelDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  //Apis
  const getEmployeeHistories = async () => {
    await Utils.request(Apis.EmployeeHistories.getEmployeeHistories());
  };

  const getEmployeeHistoriesById = async id => {
    const dataById = await Utils.request(
      Apis.EmployeeHistories.getEmployeeHistoriesById(id),
    );

    setInitialState(dataById);
  };

  const handleCreateUpdate = async (data, id) => {
    await Utils.request(Apis.EmployeeHistories.createOrUpdate(data, id));
    getEmployeeHistories();
  };

  const handleDelete = async id => {
    await Utils.request(Apis.EmployeeHistories.delete(id));
    getEmployeeHistories();
  };

  useEffect(() => {
    if (!userId) {
      getEmployeeHistories();
    }
  }, []);

  return (
    <>
      <Container>
        <Title variant="p">Employment History</Title>
        {!userId && (
          <AddButton onClick={showModal} variant="gray">
            <FontAwesomeIcon icon="fa-solid fa-plus" />
          </AddButton>
        )}
      </Container>
      {/* <div> */}
      <Modal
        style={{ maxWidth: isTablet ? '500px' : '100%' }}
        title="Employment History"
        size={isTablet ? 'md' : 'lg'}
        open={isDeleteModalOpen}
        onClose={() => {
          handleOkDeleteModal();
        }}
      >
        <Typography variant="p" style={{ fontSize: '14px' }}>
          Do you want to delete this employee history?
        </Typography>
        <SaveButtonContainer>
          <StyledButton
            onClick={handleCancelDeleteModal}
            variant="gray"
            radius="round"
          >
            Cancel
          </StyledButton>
          <StyledButton
            variant="danger"
            radius="round"
            onClick={() => {
              handleDelete(itemId);
              handleOkDeleteModal();
            }}
            style={{ marginLeft: '16px' }}
          >
            Delete
          </StyledButton>
        </SaveButtonContainer>
      </Modal>
      {/* </div> */}
      <div>
        {/* {employmentHistories?.map((item: any) => { */}

        {(userId && role ? employerData : employmentHistories)?.map(
          (item: any) => {
            return (
              <DetailsContainer key={item.id}>
                <div className="info-wrapper">
                  <InfoLabel variant="p">
                    Company Name <Info variant="span">{item.companyName}</Info>
                  </InfoLabel>
                  <InfoLabel variant="p">
                    Job Title <Info variant="span">{item.jobTitle}</Info>
                  </InfoLabel>
                  <InfoLabel variant="p">
                    Company Address{' '}
                    <Info variant="span">{item.companyAddress}</Info>
                  </InfoLabel>

                  {item?.startMonth && item.endMonth && (
                    <InfoLabel variant="p">
                      Employment Date{' '}
                      <Info variant="span">
                        {moment(item.startMonth).format('YYYY-MM-DD')} to{' '}
                      </Info>
                      <Info variant="span">
                        {moment(item.endMonth).format('YYYY-MM-DD')}
                      </Info>
                    </InfoLabel>
                  )}
                </div>
                {!userId && (
                  <EditDeleteButtonsContainer>
                    <UnStyledButton
                      onClick={() => {
                        showModal();
                        getEmployeeHistoriesById(item.id);
                      }}
                    >
                      <FontAwesomeIcon icon="pencil" color="blue" />
                    </UnStyledButton>

                    <UnStyledButton
                      onClick={() => {
                        showDeleteModal();
                        setItemId(item.id);
                      }}
                    >
                      <img src={DeleteIconNew} alt="Delete" />
                    </UnStyledButton>
                  </EditDeleteButtonsContainer>
                )}
              </DetailsContainer>
            );
          },
        )}
      </div>
      <Modal
        title="Employment History"
        open={isModalOpen}
        onClose={() => {
          handleCancel();
          setInitialState({
            id: '',
            jobTitle: '',
            companyName: '',
            companyAddress: '',
            startMonth: '',
            endMonth: '',
            isActive: true,
          });
        }}
      >
        <div style={{ position: 'relative' }}>
          <Formik
            innerRef={formikRef}
            initialValues={initialState}
            onSubmit={(values, { resetForm }) => {
              handleCreateUpdate(values, !!values?.id ? values?.id : null);
              resetForm();
              handleOk();
            }}
            enableReinitialize
            validationSchema={yup.object().shape({
              jobTitle: yup.string().required('Job title is required.'),
              companyName: yup.string().required('Company name is required.'),
              companyAddress: yup
                .string()
                .required('Company address is required.'),
              startMonth: yup.string().required('Please select a date.'),
              endMonth: yup.string().required('Please select a date.'),
            })}
          >
            {({
              isSubmitting,
              errors,
              touched,
              values,
              setFieldValue,
              handleSubmit,
              initialValues,
              resetForm,
            }) => {
              // console.log('the values are', isSubmitting);
              return (
                <Form>
                  <Field
                    name="jobTitle"
                    component={Input}
                    label="Job Title"
                    onChange={e => setFieldValue('jobTitle', e.target.value)}
                    error={errors.jobTitle}
                  />
                  <Field
                    name="companyName"
                    component={Input}
                    label="Company Name"
                    onChange={e => setFieldValue('companyName', e.target.value)}
                    error={errors.companyName}
                  />
                  <Field
                    name="companyAddress"
                    component={Input}
                    label="Company Address"
                    onChange={e =>
                      setFieldValue('companyAddress', e.target.value)
                    }
                    error={errors.companyAddress}
                  />
                  <div>
                    <StyledLabel>Start Date</StyledLabel>
                    <Field
                      name="startMonth"
                      component={DatePickerField}
                    ></Field>
                  </div>
                  <div>
                    <StyledLabel>End Date</StyledLabel>
                    <Field name="endMonth" component={DatePickerField}></Field>
                  </div>
                  <SaveButtonContainer>
                    <StyledButton
                      htmlType="submit"
                      radius="round"
                      isLoading={false}
                      isDisabled={false}
                    >
                      Add
                    </StyledButton>
                  </SaveButtonContainer>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal>
    </>
  );
};

export default EmploymentHistories;
