import moment from "moment";

export const MONTHS = Array.apply(0, Array(12)).map((_, index) => {
  const month = moment().month(index).format("MMM");
  return {
    value: `${index}`,
    label: month,
  };
});

export const DocumentType = () => {
  return [
    {
      label: "Citizenship",
      value: "Citizenship",
    },
    {
      label: "Passport",
      value: "Passport",
    },
    {
      label: "Driving License",
      value: "Driving License",
    },
  ];
};

export const trades = [
  "Cleaning",
  "Plumbing",
  "Electricity",
  "Gas",
  "Carpeting",
  "Appliance service",
  "Decoration",
  "Pest Control",
  "Guttering",
  "Roofing",
  "Gardening",
  "Builder",
];
