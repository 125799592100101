import React from 'react';

import styled from 'styled-components';
import Typography from '@/newComponents/Typography';
import moment from 'moment';
import { Info, InfoLabel } from './style';
import { devices } from '@/utils/theme';
const Steps = styled.div<{
  horizontal?: boolean;
}>`
  ${({ horizontal }) =>
    horizontal
      ? `

 display: inline-flex;
 flex-direction: row;
 align-items: center;
 justify-content: center;
 margin-bottom: 40px;
 position: relative;

`
      : `
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 40px;
      position: relative;`};
`;
const StepCircle = styled.div<{ active: boolean }>`
  height: 50px;
  width: 50px;
  border-radius: 50px;
  margin: 10px 20px;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.primary : 'white'};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  h4 {
    color: ${({ active, theme }) => (active ? 'white' : 'black')};
  }
`;

const StepNumber = styled(Typography)`
  font-size: 16px;
  margin-bottom: 0;

  @media ${devices.laptop} {
    font-size: 18px;
  }
`;

const StepLineHorizontal = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.primary};
  position: absolute;
  top: 35px;
  left: 40px;
  right: 40px;
  z-index: 1;
`;

const StepLineVertical = styled.div`
  width: 1px;
  background-color: ${({ theme }) => theme.colors.primary};
  position: absolute;
  top: 40px;
  bottom: 40px;
  left: 45px;
  z-index: 1;
`;
const Step = styled.div<{
  horizontal?: boolean;
}>`
  z-index: 9;
  ${({ horizontal }) =>
    horizontal
      ? `
  
   display: inline-flex;
   flex-direction: row;
  `
      : `
        display: flex;
        flex-direction: row;
        `};
`;

const Content = styled.div<{
  horizontal?: boolean;
}>`
  z-index: 9;
  flex-wrap: wrap;
  ${({ horizontal }) =>
    horizontal
      ? `
    
    
    `
      : `
          padding-top: 10px;
          
          `};
`;
const Row = styled.div`
  display: flex;
  column-gap: 5px;
`;
type StepTypes = {
  propertyDetails: any;
};

const StepComp: React.FC<StepTypes> = ({ propertyDetails }) => {
  const bookingEvents = propertyDetails?.bookingEvents;
  const acceptOrReject = bookingEvents?.find(
    (item: any) => item?.type === 'Accepted' || item?.type === 'Declined',
  );
  const conversation = propertyDetails?.conversations?.createdAt || null;
  const contractGenerate = bookingEvents?.find(
    (item: any) => item?.type === 'Contract',
  );
  const deposited = bookingEvents?.find(
    (item: any) => item?.type === 'Deposit',
  );
  const signed = bookingEvents?.filter((item: any) => item?.type === 'Signed');
  const landlordSigned = signed?.find((item: any) =>
    item?.message.includes('Landlord'),
  );
  const tenantSigned = signed?.find((item: any) =>
    item?.message.includes('tenant'),
  );
  const releaseRent = bookingEvents?.find(
    (item: any) => item?.type === 'Release Rent',
  );
  const timelineSteps = [
    {
      step: 1,
      title: 'Booking',
      date: moment(propertyDetails?.createdAt).format('DD MMM, YYYY'),
      desc: 'You initial booking of a property, your profile wiil be shared to property owner',
    },
    {
      step: 2,
      title: 'Landlord Review Booking',
      date: conversation
        ? moment(conversation).format('DD MMM, YYYY')
        : acceptOrReject
        ? moment(acceptOrReject?.createdAt).format('DD MMM, YYYY')
        : null,
      desc: 'Landlord will review your booking and profile',
    },
    {
      step: 3,
      title: 'Communication',
      date: conversation
        ? moment(conversation).format('DD MMM, YYYY')
        : contractGenerate
        ? moment(contractGenerate?.createdAt).format('DD MMM, YYYY')
        : null,
      desc: 'Landlord will communication with you using rentisity messaging feature, and come to informal agreement regarding price and property and property related terms',
    },
    {
      step: 4,
      title: 'Contract Generation',
      date: contractGenerate
        ? moment(contractGenerate?.createdAt).format('DD MMM, YYYY')
        : null,
      desc: 'Landlord will generate contract and send to you',
    },
    {
      step: 5,
      title: 'Deposit to escrow',
      date: deposited
        ? moment(deposited?.createdAt).format('DD MMM, YYYY')
        : null,
      desc: 'Tenant will deposit certain amount in escrow',
    },
    {
      step: 6,
      title: 'Contract Sign',
      date:
        landlordSigned || tenantSigned
          ? {
              landlord: landlordSigned
                ? moment(landlordSigned?.createdAt).format('DD MMM, YYYY')
                : null,
              tenant: tenantSigned
                ? moment(tenantSigned?.createdAt).format('DD MMM, YYYY')
                : null,
            }
          : undefined,
      desc: 'You and landlord both will sign the contract',
    },
    {
      step: 7,
      date: releaseRent
        ? moment(releaseRent?.createdAt).format('DD MMM, YYYY')
        : null,
      title: 'Release Rent',
      desc: 'Tenant will release rent.',
    },
    {
      step: 8,
      date: releaseRent
        ? moment(releaseRent?.createdAt).format('DD MMM, YYYY')
        : null,
      title: 'Property Handover',
      desc: 'After completing all required things property will handed over to you',
    },
  ];
  return (
    <Steps>
      {Array.isArray(timelineSteps) &&
        timelineSteps.map((stepItem, index) => (
          <Step key={stepItem.title}>
            <div>
              <StepCircle active={stepItem?.date ? true : false}>
                <StepNumber variant="h4">{index + 1}</StepNumber>
              </StepCircle>
            </div>
            <Content>
              <InfoLabel variant="p">{stepItem.title}</InfoLabel>
              <div>
                {!!stepItem?.date && typeof stepItem?.date === 'string' ? (
                  <InfoLabel variant="p">{stepItem?.date}</InfoLabel>
                ) : !!stepItem?.date && typeof stepItem?.date === 'object' ? (
                  <>
                    <Row>
                      {!!stepItem?.date?.tenant && (
                        <>
                          <InfoLabel variant="p">Singed by tenant on</InfoLabel>

                          <InfoLabel variant="p">
                            {stepItem?.date?.tenant}
                          </InfoLabel>
                        </>
                      )}
                    </Row>
                    <Row>
                      {!!stepItem?.date?.landlord && (
                        <>
                          <InfoLabel variant="p">
                            Singed by landlord on{' '}
                          </InfoLabel>

                          <InfoLabel variant="p">
                            {stepItem?.date?.landlord}
                          </InfoLabel>
                        </>
                      )}
                    </Row>
                  </>
                ) : null}
              </div>
              <Info variant="p">{stepItem.desc}</Info>
            </Content>
          </Step>
        ))}
      <StepLineVertical />
    </Steps>
  );
};

export default StepComp;
