import React from 'react';
import ListLoading from '../ListLoading';
import {
  ButtonWrapperStyled,
  StyledButton,
  TenantsListContainer,
} from '../styles';
import Select from '@/newComponents/Select';
import { FiMail } from 'react-icons/fi';
import IndividualUser from '../IndividualUser';
import Typography from '@/newComponents/Typography';

const ShortList = ({
  loading,
  disableActions,
  bookingRequested,
  selectedShortList,
  handleSelectTenantForShortList,
  setEnableInviteShortListedModal,
  activeShortListIndex,
  handleShortListFilter,
}) => {
  const optionsFromTenantShortList = Array.from(
    {
      length: bookingRequested?.totalSortListIndex,
    },
    (_, i) => bookingRequested?.totalSortListIndex - i,
  ).map(val => ({
    id: val,
    value: val,
    label: `${val}`,
  }));

  const options = [
    {
      id: 0,
      value: 'all',
      label: 'All',
    },
    ...optionsFromTenantShortList,
  ];

  return (
    <div>
      {loading ? (
        <ListLoading number={4} />
      ) : (
        <>
          {!disableActions && (
            <ButtonWrapperStyled>
              <div className="filter-list">
                Short List No. &nbsp;
                <Select options={options} onChange={handleShortListFilter} />
              </div>
              <StyledButton
                radius="round"
                isDisabled={bookingRequested?.tenants.length <= 0}
                onClick={() => {
                  setEnableInviteShortListedModal(true);
                }}
              >
                <FiMail className="icons" />
                Invite For Viewing
              </StyledButton>
            </ButtonWrapperStyled>
          )}
          {bookingRequested &&
          bookingRequested?.tenants &&
          bookingRequested?.tenants.length > 0 ? (
            <TenantsListContainer>
              {bookingRequested?.tenants.map(tenant => {
                if (activeShortListIndex !== 'all') {
                  if (Number(activeShortListIndex) !== tenant.sortListIndex) {
                    return null;
                  }
                }
                return (
                  <IndividualUser
                    tenant={tenant}
                    isChecked={selectedShortList.includes(tenant.bookingId)}
                    onSelect={handleSelectTenantForShortList}
                    isReadOnly
                    tag={tenant.sortListIndex}
                  />
                );
              })}
            </TenantsListContainer>
          ) : (
            <Typography variant="p" size="sm" style={{ textAlign: 'center' }}>
              No request!
            </Typography>
          )}
        </>
      )}
    </div>
  );
};

export default ShortList;
