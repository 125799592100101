import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import List from './List';
import { useRoot } from '@/RootProvider';
import Map from '@/newComponents/Map';
import SearchFilterInput from '@/newComponents/SearchFilterInput';
import Store from '@/store';
// import makeRequestWishList from '@shared/front/utils/request';
import { devices } from '@/utils/theme';
import { Apis, Utils } from '@shared/front';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import FilterModel from './FilterModel';
import FilterTag from '@/newComponents/FilterTags';
import Button from '@/newComponents/Button';
import InfiniteScroll from 'react-infinite-scroll-component';

import BrowsePropertySkeleton from './BrowsePropertySkeleton';
import EmptyPage from '@/containers/Empty';

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 90px);
  overflow: hidden;
  display: flex;
  flex-direction: column;

  gap: 20px;
`;
// const Content = styled.div`
//   display: flex;
//   gap: 18px;
//   margin-top: 18px;
// `;

const Content = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: 'center';
  gap: 25px;

  @media (${devices.laptop}) {
    flex-direction: row;
  }
`;
const SortFilterStyle = styled.div<{ isPublic: Boolean }>``;

// const MapContainer = styled.div`
//   position: -webkit-sticky;
//   position: sticky;
//   width: 35%;
//   right: 0;
//   top: 0;
// `;
const MapWrapper = styled.div`
  position: relative;
  /* width: calc((1 / 3) * 100%); */
  /* width: calc(100vw - 180px); */
  width: 100%;
  /* order: 1; */
  height: 300px;
  @media (${devices.laptop}) {
    height: calc(100vh - 180px);
    width: calc((2 / 5) * 100%);
  }
`;

const FilterInfoWrapperStyled = styled.div<{ isPublic?: Boolean }>`
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 16px;
  margin-bottom: 16px;
`;
const MapSticky = styled.div`
  position: sticky;
  top: 115px;
  width: 100%;
`;
const PropertyContainer = styled.div`
  height: calc(100vh - 90px - 100px - 20px);
  width: 100%;
  overflow-y: auto;
  padding: 0px;
  position: relative;

  @media (${devices.laptop}) {
    width: calc((3 / 5) * 100%);
    padding: 12px;
  }
`;

const SearchFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e0e0e0;
  max-width: 100%;
  @media (${devices.laptop}) {
    max-width: calc(100% - (2 / 5) * 100% - 30px);
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 50px 30px;
  width: 100%;
`;

const PropertyList = () => {
  const formikRef = useRef();
  const searchRef = useRef();
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const browserProperties = useSelector(state => state.webbrowseproperties);

  const pagination: any = useSelector<any>(Store.Select.pagination);

  const [queryParams, setQueryParams] = useSearchParams();
  const { auth } = useRoot();
  const [params, setParams] = useState({
    address: '',
    limit: 10,
    page: 0,
    propertyType: '',
  });

  const fetchMyBookmarks = () => {
    if (auth?.id) {
      Utils.request(Apis.Bookmark.checkBookmark('set'));
    }
  };

  useEffect(() => {
    fetchMyBookmarks();
  }, []);

  const wishList = useSelector(state => state.wishList);
  const fetchMoreData = () => {
    if (browserProperties?.length >= pagination?.count) {
      setHasMore(false);
      return;
    }
    setParams((prev: any) => ({ ...prev, page: params.page + 1 }));
  };
  const getBrowserProperty = async () => {
    setLoading(true);
    try {
      if (params.page === 0) {
        await Utils.request(
          Apis.Properties.getAllBrowserPropertiesForWeb({
            params,

            action: 'set',
          }),
        );
      } else {
        await Utils.request(
          Apis.Properties.getAllBrowserPropertiesForWeb({
            params,

            action: 'update',
          }),
        );
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const searchByAddress = (value: string) => {
    setParams({ ...params, address: value });
  };

  useEffect(() => {
    // if (queryParams.get('address') && !params.address) return;
    getBrowserProperty();
  }, [params]);

  const onFilterChange = (key, val) => {
    setParams({ ...params, [key]: val });
  };
  const searchPropertyByTitle = (value: string) => {
    setParams({ ...params, address: value });
  };

  const searchByAddressDebounced = useRef(
    debounce(value => {
      searchPropertyByTitle(value);
    }, 500),
  ).current;

  return (
    <>
      <Container>
        <SearchFilterWrapper>
          <SortFilterStyle isPublic={!auth}>
            <SearchFilterInput
              value={searchValue}
              name="address"
              inputType="filter"
              placeholder="Search by Property address"
              onChange={e => {
                setSearchValue(e.target.value);
                searchByAddressDebounced(e.target.value);
              }}
              onSearch={searchPropertyByTitle}
              onCancel={() => {
                setSearchValue('');
                searchPropertyByTitle('');
              }}
              filterModal={
                <FilterModel
                  isModalOpen={isModalOpen}
                  initialValues={params}
                  onClose={() => setIsModalOpen(false)}
                  onSubmit={values => {
                    console.log('modal values', values);
                    setParams({ ...params, ...values });
                    setIsModalOpen(false);
                  }}
                  onReset={() => {
                    setParams({
                      address: '',
                      limit: 10,
                      page: 0,
                      dataLength: 1,
                      sort: '',
                      priceType: '',
                    });
                  }}
                  formikRef={formikRef}
                />
              }
              onFilter={() => setIsModalOpen(true)}
            />
          </SortFilterStyle>
          <FilterInfoWrapperStyled>
            <FilterInfo
              params={params}
              setParams={setParams}
              formikRef={formikRef}
              searchRef={searchRef}
            />
          </FilterInfoWrapperStyled>
        </SearchFilterWrapper>
        <Content>
          <PropertyContainer id="scrollableDiv">
            <InfiniteScroll
              dataLength={browserProperties.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={<h4></h4>}
              scrollableTarget="scrollableDiv"
            >
              {loading && <BrowsePropertySkeleton />}
              {!loading && browserProperties?.length === 0 && <EmptyPage />}
              <GridContainer>
                {browserProperties?.length > 0 &&
                  browserProperties?.map((property: any) => {
                    const isBookmarked = !!wishList?.filter(
                      (item: { property: { id: any } }) =>
                        property?.id === item?.property?.id,
                    ).length;
                    return <List key={property?.id} property={property}></List>;
                  })}
              </GridContainer>
            </InfiniteScroll>
            {loading && params?.page > 0 && <BrowsePropertySkeleton />}
          </PropertyContainer>
          <MapWrapper>
            <MapSticky>
              <Map coordinates={browserProperties || []} />
            </MapSticky>
          </MapWrapper>
        </Content>
      </Container>
    </>
  );
};

export default PropertyList;

const FilterInfo = ({ params, setParams, formikRef, searchRef }: any) => {
  // console.log('the filter property', params);

  const convertObjectToArray = params => {
    const result = [];

    for (let key in params) {
      if (
        key !== 'limit' &&
        key !== 'address' &&
        key !== 'page' &&
        key !== 'dataLength'
      ) {
        const value = params[key];
        if (value !== null && value !== '') {
          result.push({ [key]: params[key] });
        }
      }
    }
    return result;
  };
  const filterInfo = convertObjectToArray(params);

  return (
    <>
      {filterInfo.length > 0 && (
        <ClearAllButton
          variant="gray"
          radius="round"
          onClick={() => {
            setParams({
              title: '',
              limit: 10,
              page: 0,
              dataLength: 1,
            });
            formikRef.current?.resetForm();
            searchRef.current?.setFieldValue('title', '');
          }}
        >
          Clear All
        </ClearAllButton>
      )}

      {filterInfo.length > 0 &&
        filterInfo?.filter(Boolean)?.map(item => {
          const key = Object.keys(item)[0];
          const value = item[key];
          const getSortLabel = (value: string) => {
            if (value === 'price_desc') {
              return 'High to Low';
            } else if (value === 'price_asc') {
              return 'Low to High';
            } else {
              return value;
            }
          };
          return (
            <FilterTag
              filterType={key}
              filterSubType={getSortLabel(value)}
              onClose={() => {
                setParams({
                  ...params,
                  [key]: null,
                });
                formikRef?.current.setFieldValue(key, null);
              }}
            />
          );
        })}
    </>
  );
};

const ClearAllButton = styled(Button)`
  color: #949494;
  height: auto;
  font-size: 12px;
  line-height: 25px;
  padding: 2px 12px;
  background-color: #ebebeb;
`;
