type RoleType = "service" | "landlord" | "tenant";

const deleteDescription = (role: RoleType) => {
  return [
    {
      title: "Deletion Process",
      desc: "Account deletion takes 7 days. During this period your account will be scheduled for removal from our system.",
    },
    {
      title: "Data Removal",
      desc: "All your data will be permanently deleted form our system, including account information, listings, contracts and payment details.",
    },
    {
      title: "Email Notification",
      desc: "We will notify you via email once your account deletion request has been initiated.",
    },
    {
      title: "Balance withdrawl",
      desc: "If there is a remaining balance in your account you wont be able to delete it until you withdraw your balance.",
    },
    role !== "service" && {
      title: "Active jobs and properties",
      desc: "If your account has active jobs or properties that are not yet completed or closed you may not be able to delete your account.",
    },
    role === "service" && {
      title: "Active jobs ",
      desc: "If your account has active jobs or contracts that are not yet completed or closed you may not be able to delete your account until these are resolved.",
    },
    role === "tenant" && {
      title: "Outstanding Rent Payments to Landlord",
      desc: "You won't be allowed to delete your account if there are any remaining rent payments to be made to a landlord.",
    },
    role === "tenant" && {
      title: " Early Termination of Property Contracts",
      desc: "You won't be allowed to delete your account if you leave the property before the specified contract period ends.",
    },
    role === "service" && {
      title: "Pending Payments ",
      desc: "If there are any pending payments from the landlord or service provider, you won't be able to delete your account.",
    },
  ].filter(Boolean);
};
export default deleteDescription;
