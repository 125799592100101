import Button from '@/newComponents/Button';
import Divider from '@/newComponents/Divider';
import Modal from '@/newComponents/Modal';
import Typography from '@/newComponents/Typography';
import { Apis, Store } from '@shared/front';
import { request } from '@shared/front/utils';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ImageBrowser from '../ImageBrowser';
import SmallImageViewer from '@/newComponents/FileBrowser/UploadedSmallImageViewer';
import { uploadImagesToS3 } from '@/utils/S3MultipleUploader';
import LoadingPlaceholder from '@/components/LoadingPlaceholder';
import { useResponsive } from '@/hooks/useResponsive';
import { ButtonEndWrapperStyled } from '@/styles/shareableStyle';

type Props = {
  itineraryId: number;
  open: boolean;
  onClose: () => void;
  hasAccess: boolean;
};

type ItineraryType = {
  id: number;
  authId: number;
  propertyId: number;
  title: string;
  quantity: number;
  desc: string;
  itineraryCategoryId: number;
  createdAt: string;
  updatedAt: string;
  itineryImages: string[];
};

const RoomItemsDetailsModal = ({
  itineraryId,
  onClose,
  open,
  hasAccess,
}: Props) => {
  const { isTablet } = useResponsive();
  const [selectedImages, setSelectedImages] = useState([]);
  const [enableAddImages, setEnableAddImages] = useState(false);
  const [loading, setLoading] = useState({
    deleting: false,
    adding: false,
    uploading: false,
    fetching: false,
  });
  const [deletingIds, setDeletingIds] = useState<Array<number>>([]);

  // SELECTORS
  const roomItinerary: ItineraryType = useSelector(
    // @ts-ignore
    Store.Select.itineraryDetails,
  );

  // HOOKS
  const dispatch = useDispatch();

  useEffect(() => {
    if (open && itineraryId) {
      fetchItineraryDetails();
    }
  }, [open, itineraryId]);

  const fetchItineraryDetails = async () => {
    setLoading(prev => ({ ...prev, fetching: true }));

    await request(Apis.Properties.getPropertyItineriesById(itineraryId));
    setLoading(prev => ({ ...prev, fetching: false }));
  };
  const handleOnClose = () => {
    onClose();
    handleCancelAddImages();
    dispatch(Store.Actions.reset('itineraryDetails'));
  };

  const handleCancelAddImages = () => {
    setEnableAddImages(false);
    setSelectedImages([]);
  };

  const uploadItineraryImages = async () => {
    setLoading(prev => ({ ...prev, uploading: true }));

    try {
      const keys = await uploadImagesToS3(selectedImages);
      if (!keys || !Array.isArray(keys) || keys?.length === 0) {
        setLoading(prev => ({ ...prev, uploading: false }));
        return;
      }
      setLoading(prev => ({ ...prev, adding: true, uploading: false }));
      const oldImages = roomItinerary?.itineryImages?.map((x: any) => x.URL);
      await request(
        Apis.Properties.addItineraryImages(
          [...oldImages, ...keys],
          itineraryId,
        ),
      );

      fetchItineraryDetails();
      handleCancelAddImages();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(prev => ({ ...prev, adding: false, uploading: false }));
    }
  };

  const handleDeleteImages = async (id: number) => {
    try {
      setLoading(prev => ({ ...prev, deleting: true }));
      setDeletingIds(prev => [...prev, id]);
      const response = await request(
        Apis.Properties.deletePropertyItineriesImages(id),
      );
      const updatedData = {
        ...roomItinerary,
        itineryImages: roomItinerary?.itineryImages.filter(
          (x: any) => x.id !== id,
        ),
      };

      dispatch(Store.Actions.set('itineraryDetails', updatedData));
      setDeletingIds(prev => prev.filter(x => x !== id));
    } catch (error) {
    } finally {
      setLoading(prev => ({ ...prev, deleting: false }));
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleOnClose}
      isCentered
      title={roomItinerary?.title || 'Loading'}
      style={{ width: !isTablet ? '95vw' : '70vw' }}
    >
      {loading.fetching && !roomItinerary?.id ? (
        <LoadingPlaceholder />
      ) : (
        <MainContainerStyled>
          <IndividualItinerary>
            <Typography variant="p" className="quantity">
              <span className="label">Quantity</span>
              {roomItinerary?.quantity}
            </Typography>
            <Typography variant="p">{roomItinerary?.desc}</Typography>
          </IndividualItinerary>

          <Divider />
          <ItineraryImagesConatiner>
            <div className="heading-wrapper">
              <Typography variant="h6" className="heading">
                Images
              </Typography>
              {hasAccess && !enableAddImages && (
                <Button variant="gray" onClick={() => setEnableAddImages(true)}>
                  + Images
                </Button>
              )}
            </div>
            <div className="images-container">
              {Array.isArray(roomItinerary?.itineryImages) &&
                !enableAddImages &&
                roomItinerary?.itineryImages?.length === 0 && (
                  <Typography variant="p">No images added yet.</Typography>
                )}
            </div>
            <div>
              <SmallImageViewer
                files={selectedImages}
                setFiles={setSelectedImages}
                isLoading={loading?.uploading}
                enableRemoveImage={!loading?.uploading && hasAccess}
                imagesURL={
                  Array.isArray(roomItinerary?.itineryImages)
                    ? roomItinerary?.itineryImages?.map((img: any) => ({
                        id: img.id,
                        URL: img.URL,
                      }))
                    : []
                }
                isDeletingIds={deletingIds}
                onRemove={handleDeleteImages}
              />
            </div>

            {enableAddImages && (
              <div>
                <ImageBrowser
                  setFiles={setSelectedImages}
                  files={selectedImages}
                />

                <div className="save-btn-wrapper">
                  <Button variant="gray" onClick={handleCancelAddImages}>
                    Cancel
                  </Button>
                  <Button
                    onClick={uploadItineraryImages}
                    isDisabled={loading.adding || loading.uploading}
                    isLoading={loading.adding}
                  >
                    Save
                  </Button>
                </div>
              </div>
            )}
          </ItineraryImagesConatiner>
        </MainContainerStyled>
      )}
      {!enableAddImages && (
        <ButtonEndWrapperStyled style={{ marginTop: '20px' }}>
          <Button variant="gray" onClick={handleOnClose}>
            Back
          </Button>
        </ButtonEndWrapperStyled>
      )}
    </Modal>
  );
};

export default RoomItemsDetailsModal;

const MainContainerStyled = styled.div`
  max-height: calc(100vh - 120px);
  overflow: auto;
  min-height: 50vh;
`;

const IndividualItinerary = styled.div`
  width: 100%;

  & .quantity {
    font-weight: 600;
    font-size: 1.1rem;
    & .label {
      font-weight: 400;
      color: #888;
      margin-right: 10px;
    }
    margin-bottom: 8px;
  }

  & .desc {
    color: #333;
  }
`;

const ItineraryImagesConatiner = styled.div`
  & .heading-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & .heading {
      font-weight: 600;
      font-size: 1.2rem;
    }
    margin-bottom: 10px;
  }

  & .images-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }

  & .save-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
  }
`;
