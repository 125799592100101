import { Apis, Hooks, Store, Utils } from '@shared/front';
import DatePickerField from '@shared/web/components/DatePicker';
// import InputField from '@shared/web/components/InputField';
import Modal from '@/newComponents/Modal';
// import Button from '@/newComponents/Button';
import Typography from '@/newComponents/Typography';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
// import { useParams } from 'react-router-dom';
// import { useRoot } from '@/RootProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { devices } from '@/utils/theme';
import { useResponsive } from '@/hooks/useResponsive';
import EditIconNew from '../../../assets/edit-icon-new.svg';
import DeleteIconNew from '../../../assets/delete-icon-new.svg';
import {
  Title,
  AddButton,
  StyledLabel,
  StyledButton,
  SaveButtonContainer,
  InfoLabel,
  Info,
  EditDeleteButtonsContainer,
  UnStyledButton,
} from '../index.styles';
import Input from '@/newComponents/Input';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  @media ${devices.laptop} {
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
  }

  & .info-wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
`;

const RentalHistories = ({ rentalData, profileId: userId, role }: any) => {
  const formikRef = useRef();
  // console.log(role, 'rentalData');
  const [initialState, setInitialState] = useState({
    id: '',
    landlordName: '',
    landlordEmail: '',
    propertyAddress: '',
    startMonth: '',
    endMonth: '',
    isActive: true,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [itemId, setItemId] = useState();
  const [makeRequest, { data }] = Hooks.useAPI();

  const rentalHistories: any = useSelector(Store.Select.rentalHistories);

  const { isTablet } = useResponsive();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOkDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };
  const showDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };
  const handleCancelDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const getRentalHistories = async () => {
    await Utils.request(Apis.RentelHistories.getRentalHistories());
  };

  const getRentalHistoriesById = async userId => {
    const dataById = await Utils.request(
      Apis.RentelHistories.getRentalHistoriesById(userId),
    );
    setInitialState(dataById);
  };
  // console.log(getRentalHistoriesById, 'getRentalHistoriesById');

  const handleCreateUpdate = async (data, id) => {
    await Utils.request(Apis.RentelHistories.createOrUpdate(data, id));
    getRentalHistories();
  };

  const handleDelete = async id => {
    await Utils.request(Apis.RentelHistories.delete(id));
    getRentalHistories();
  };

  useEffect(() => {
    if (!userId) {
      getRentalHistories();
    }
  }, []);

  return (
    <>
      <Container>
        <Title variant="p">Rental History (Outside Rentisity)</Title>
        {!userId && (
          <AddButton onClick={showModal} variant="gray">
            <FontAwesomeIcon icon="fa-solid fa-plus" />
          </AddButton>
        )}
        <Modal
          title="Rental History"
          open={isModalOpen}
          onClose={() => {
            handleCancel();
            setInitialState({
              id: '',
              landlordName: '',
              landlordEmail: '',
              propertyAddress: '',
              startMonth: '',
              endMonth: '',
              isActive: true,
            });
          }}
        >
          <Formik
            innerRef={formikRef}
            initialValues={initialState}
            onSubmit={(values, { resetForm }) => {
              handleCreateUpdate(values, !!values?.id ? values?.id : null);
              resetForm();
              handleOk();
            }}
            enableReinitialize
            validationSchema={yup.object().shape({
              landlordName: yup.string().required('This field is required.'),
              landlordEmail: yup.string().required('This field is required.'),
              propertyAddress: yup.string().required('This field is required.'),
              startMonth: yup.string().required('Please select a date.'),
              endMonth: yup.string().required('Please select a date.'),
            })}
          >
            {({
              isSubmitting,
              errors,
              touched,
              values,
              setFieldValue,
              handleSubmit,
              initialValues,
              resetForm,
            }) => {
              return (
                <Form>
                  <Field
                    name="landlordName"
                    component={Input}
                    label="Landlord Name"
                    onChange={e =>
                      setFieldValue('landlordName', e.target.value)
                    }
                    error={errors.landlordName}
                  />

                  <Field
                    name="landlordEmail"
                    component={Input}
                    label="Landlord Email"
                    onChange={e =>
                      setFieldValue('landlordEmail', e.target.value)
                    }
                    error={errors.landlordEmail}
                  />
                  <Field
                    name="propertyAddress"
                    component={Input}
                    label="Property Address"
                    onChange={e =>
                      setFieldValue('propertyAddress', e.target.value)
                    }
                    error={errors.propertyAddress}
                  />
                  <div>
                    <StyledLabel>Start Date</StyledLabel>
                    <Field
                      name="startMonth"
                      component={DatePickerField}
                    ></Field>
                  </div>
                  <div>
                    <StyledLabel>End Date</StyledLabel>
                    <Field name="endMonth" component={DatePickerField}></Field>
                  </div>
                  <SaveButtonContainer>
                    <StyledButton
                      htmlType="submit"
                      variant="primary"
                      radius="round"
                      isLoading={false}
                      isDisabled={false}
                      style={{ marginTop: '10px' }}
                    >
                      Add
                    </StyledButton>
                  </SaveButtonContainer>
                </Form>
              );
            }}
          </Formik>
        </Modal>
      </Container>
      {/* <div> */}
      <Modal
        title="Rental History"
        style={{ maxWidth: isTablet ? '500px' : '100%' }}
        size={isTablet ? 'md' : 'lg'}
        open={isDeleteModalOpen}
        onClose={() => {
          handleOkDeleteModal();
        }}
      >
        <Typography variant="p" style={{ fontSize: '14px' }}>
          Do you want to delete this rental history?
        </Typography>
        <SaveButtonContainer>
          <StyledButton
            onClick={handleCancelDeleteModal}
            variant="gray"
            radius="round"
          >
            Cancel
          </StyledButton>
          <StyledButton
            variant="danger"
            radius="round"
            onClick={() => {
              handleDelete(itemId);
              handleOkDeleteModal();
            }}
            style={{ marginLeft: '16px' }}
          >
            Delete
          </StyledButton>
        </SaveButtonContainer>
      </Modal>
      {/* </div> */}
      <div>
        {(userId && role ? rentalData : rentalHistories)?.map((item: any) => {
          return (
            <DetailsContainer key={item.id}>
              <div className="info-wrapper">
                <InfoLabel variant="p">
                  Address <Info variant="span">{item.propertyAddress}</Info>
                </InfoLabel>
                <InfoLabel variant="p">
                  Landlord Name <Info variant="span">{item.landlordName}</Info>
                </InfoLabel>

                {item?.startMonth && item.endMonth && (
                  <InfoLabel variant="p">
                    Rental Date{' '}
                    <Info variant="span">
                      {moment(item.startMonth).format('YYYY-MM-DD')} to{' '}
                    </Info>{' '}
                    <Info variant="span">
                      {moment(item.endMonth).format('YYYY-MM-DD')}
                    </Info>
                  </InfoLabel>
                )}
              </div>

              {!userId && (
                <EditDeleteButtonsContainer>
                  <UnStyledButton
                    onClick={() => {
                      showModal();
                      getRentalHistoriesById(item.id);
                    }}
                  >
                    <FontAwesomeIcon icon="pencil" color="blue" />
                  </UnStyledButton>

                  <UnStyledButton
                    onClick={() => {
                      showDeleteModal();
                      setItemId(item.id);
                    }}
                  >
                    <img src={DeleteIconNew} alt="Delete" />
                  </UnStyledButton>
                </EditDeleteButtonsContainer>
              )}
            </DetailsContainer>
          );
        })}
      </div>
    </>
  );
};

export default RentalHistories;
