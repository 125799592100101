import React from 'react';
import ListLoading from '../ListLoading';
import {
  ButtonWrapperStyled,
  StyledButton,
  TenantsListContainer,
} from '../styles';
import IndividualUser from '../IndividualUser';
import Typography from '@/newComponents/Typography';

const LongList = ({
  loading,
  disableActions,
  bookingRequested,
  selectedShortList,
  handleSelectTenantForShortList,
  setEnableShortListProfileModal,
}) => {
  return (
    <div>
      {loading ? (
        <ListLoading number={4} />
      ) : (
        <>
          {!disableActions && bookingRequested?.tenants.length !== 0 && (
            <ButtonWrapperStyled>
              <div></div>
              <StyledButton
                radius="round"
                isDisabled={bookingRequested?.tenants.length <= 0}
                onClick={() => {
                  setEnableShortListProfileModal(true);
                }}
              >
                Create Short List
              </StyledButton>
            </ButtonWrapperStyled>
          )}
          {bookingRequested &&
          bookingRequested?.tenants &&
          bookingRequested?.tenants.length > 0 ? (
            <TenantsListContainer>
              {bookingRequested?.tenants.map(tenant => (
                <IndividualUser
                  tenant={tenant}
                  isChecked={selectedShortList.includes(tenant.bookingId)}
                  onSelect={handleSelectTenantForShortList}
                  isReadOnly={disableActions}
                />
              ))}
            </TenantsListContainer>
          ) : (
            <Typography variant="p" size="sm" style={{ textAlign: 'center' }}>
              No request!
            </Typography>
          )}
        </>
      )}
    </div>
  );
};

export default LongList;
