import { useRoot } from '@/RootProvider';
import Drawer from '@/newComponents/Drawer';
import HeaderSearchFilter from '@/newComponents/Header/HeaderSearchFilter';
import SideMenu from '@/newComponents/SideMenu';
import { devices } from '@/utils/theme';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Hamburger from '../../assets/hamburger.svg';
import FullLogoSVG from '../../assets/rentisity-full-logo.svg';
import SmLogoSVG from '../../assets/rentisity-sm-logo.svg';
import MenuOptions from './MenuOptions';
import Notification from './notification';
import Profile from './profile';
import SignupModal from '@/containers/Auth/SignupModal';
import LoginModal from '@/containers/Auth/LoginModal';
import VerifyOTPModal from '@/containers/Auth/VerifyOTPModal';
const StyledHeader = styled.div<{
  isHome: boolean;
}>`
  height: 60px;
  position: fixed;
  background-color: white;

  left: 0;
  right: 0;
  top: 0;

  z-index: 999;
  display: flex;
  align-items: center;
  @media ${devices.tablet} {
    justify-content: space-between;
    height: 60px;
  }

  ${({ isHome }) => !isHome && ` border-bottom: 1px solid #f0f0f0;`}
`;

const IconWrapper = styled.div`
  margin: 0 10px;
  cursor: pointer;
`;

const Logo = styled.img`
  height: 50px;
  width: 40px;

  &.drawerLogo {
    width: 100px;
  }

  margin-right: 8px;
  @media ${devices.tablet} {
    height: 50px;
    width: 120px;
  }
  cursor: pointer;
`;

const HamburgerStyle = styled.div`
  margin-left: 20px;
  margin-right: 8px;
  cursor: pointer;
  border-radius: 50%;
  background: #f1f1f1;
  padding: 8px;
  & img {
    height: 24px;
    width: 24px;
  }
`;

const MenuWrapper = styled.div`
  margin-right: 10px;
`;

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { auth } = useRoot();
  const [leftMenuOpen, setLeftMenuOpen] = useState(false);
  const openLeftbarMenu = () => {
    setLeftMenuOpen(true);
  };
  const closeLeftbarMenu = () => {
    setLeftMenuOpen(false);
  };

  const isDesktopOrLaptop = useMediaQuery({ query: devices.tablet });
  const isHome = location.pathname === '/';
  const handleLogoClick = () => {
    if (auth?.deleteRequested) {
      return;
    }
    closeLeftbarMenu();
    if (auth && auth.token) {
      navigate('/dashboard');
    } else {
      navigate('/');
    }
  };

  return (
    <StyledHeader isHome={isHome}>
      {/* For Small Screen to show sidebar in a drawer */}
      {!isDesktopOrLaptop && !!auth && !isHome ? (
        <>
          <HamburgerStyle onClick={openLeftbarMenu}>
            <img src={Hamburger} />
          </HamburgerStyle>
          <Drawer
            placement="left"
            onClose={closeLeftbarMenu}
            open={leftMenuOpen}
            width={270}
            header={
              <Logo
                src={FullLogoSVG}
                alt="Logo"
                onClick={handleLogoClick}
                className="drawerLogo"
              />
            }
          >
            <SideMenu onMenuItemClick={closeLeftbarMenu} />
          </Drawer>
        </>
      ) : (
        ''
      )}
      <HeaderWrapper>
        {isDesktopOrLaptop ? (
          <Logo src={FullLogoSVG} alt="Logo" onClick={handleLogoClick} />
        ) : (
          <Logo src={SmLogoSVG} alt="Logo" onClick={handleLogoClick} />
        )}

        {isHome && (
          <div
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <HeaderSearchFilter />
          </div>
        )}

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {!auth && (
            <MenuWrapper>
              <MenuOptions />
            </MenuWrapper>
          )}

          {auth ? (
            <>
              <IconWrapper>
                <Notification />
              </IconWrapper>
              <IconWrapper>
                <Profile user={auth} />
              </IconWrapper>
            </>
          ) : (
            <></>
          )}
        </div>
      </HeaderWrapper>
      <SignupModal />
      <LoginModal />
      <VerifyOTPModal />
    </StyledHeader>
  );
};

export default Header;

const HeaderWrapper = styled.div`
  margin: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media ${devices.tablet} {
    margin: 10px 30px;
  } ;
`;
