import { RootType, useRoot } from '@/RootProvider';
import LeftSVG from '@/assets/Arrow_left_alt.svg';
import Button from '@/newComponents/Button';
import Checkbox from '@/newComponents/Checkbox';
import Input from '@/newComponents/Input';
import Typography from '@/newComponents/Typography';
import { deleteDescription } from '@shared/common/constants';
import { Apis, Utils } from '@shared/front';
import React, { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DeleteAccountConfirmation from './DeleteAccountConfirmation';
import {
  DeletionFormContainerStyled,
  IndividualConditionsStyled,
  TitleContainer,
} from './style';

const DeleteAccount = () => {
  const [formData, setFormData] = useState({
    password: '',
    acceptConfirmation: false,
  });

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [requestingDeletion, setRequestingDeletion] = useState(false);
  const [validationMessage, setValidationMessage] = useState({
    password: '',
    acceptConfirmation: '',
  });
  const navigate = useNavigate();
  const { auth, checkAuth }: RootType = useRoot();
  const deleteConditions = auth?.role ? deleteDescription(auth?.role) : [];
  const [isAccountDeletable, setIsAccountDeletable] = useState(true);

  const handleDeleteAccount = async () => {
    if (auth?.id && isAccountDeletable) {
      setRequestingDeletion(true);
      try {
        await Utils.request(
          Apis.Auth.deleteAccountRequest({
            password: formData.password,
          }),
        );
        toast('Requested to delete account.');
        checkAuth();
      } catch (error) {
        if (error && typeof error === 'string') {
          setValidationMessage({ password: error, acceptConfirmation: '' });
        }
      } finally {
        setRequestingDeletion(false);
        onCloseModal();
      }
    }
  };

  const onDeleteAccount = () => {
    if (auth?.id) {
      if (!formData.acceptConfirmation) {
        setValidationMessage(prev => ({
          ...prev,
          acceptConfirmation:
            'Please click on check to confirm account deletion.',
        }));
        return;
      }

      setValidationMessage({
        acceptConfirmation: '',
        password: '',
      });
      setShowConfirmationModal(true);
    }
  };

  const onCloseModal = () => setShowConfirmationModal(false);

  const handleGoback = () => {
    navigate('/profile');
  };

  return (
    <div>
      <TitleContainer>
        <span
          onClick={handleGoback}
          style={{
            cursor: 'pointer',
            marginRight: '12px',
          }}
        >
          <img src={LeftSVG} alt="go-back" />
        </span>
        <Typography
          style={{ margin: 0, padding: 0 }}
          className="title"
          variant="p"
        >
          Delete Account
        </Typography>
      </TitleContainer>

      <Typography style={{ marginBottom: '28px' }}>
        You can delete your account at any time. However, please note the
        following:
      </Typography>

      <div>
        {Array.isArray(deleteConditions) &&
          deleteConditions?.map((conditions, index) => {
            if (!conditions) {
              return null;
            }
            return (
              <IndividualConditionsStyled key={conditions?.title}>
                <Typography className="conditionNumber">
                  {index + 1}.
                </Typography>
                <Typography className="title">{conditions?.title}</Typography>
                <Typography className="desc">{conditions?.desc}</Typography>
              </IndividualConditionsStyled>
            );
          })}

        <DeletionFormContainerStyled>
          <Typography style={{ fontSize: '14px', margin: '0px 0px 20px' }}>
            <span style={{ fontWeight: 700 }}>Note : </span>
            Once your account deletion request is processed you will not be able
            to recover your account or any associated data.
          </Typography>

          {/* <CannotDelete setIsDeletable={setIsAccountDeletable} /> */}

          <div style={{}}>
            <Typography>Password</Typography>
            <Input
              type="password"
              name="password"
              value={formData.password}
              placeholder="Enter your password"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const { value } = event.target;
                setFormData(prev => ({ ...prev, password: value }));
              }}
              isDisabled={!isAccountDeletable}
              style={{ borderRadius: '10px', height: '36px' }}
            />
            {validationMessage?.password && (
              <Typography
                style={{
                  fontSize: '14px',
                  color: 'red',
                  margin: '3px 0px 20px',
                }}
              >
                {validationMessage?.password}
              </Typography>
            )}
            <Checkbox
              isChecked={formData.acceptConfirmation}
              disabled={!isAccountDeletable}
              id="confirmation"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const { checked } = event.target;
                setFormData(prev => ({ ...prev, acceptConfirmation: checked }));
              }}
              name="acceptConfirmation"
              label="I confirm I want to delete my account"
              style={{ height: '16px', width: '16px', margin: '20px 0px ' }}
            />
            {validationMessage?.acceptConfirmation && (
              <Typography
                style={{
                  fontSize: '14px',
                  color: 'red',
                  margin: '-18px 0px 0px',
                }}
              >
                {validationMessage?.acceptConfirmation}
              </Typography>
            )}
            <Button
              variant="danger"
              onClick={onDeleteAccount}
              isLoading={requestingDeletion}
              style={{ marginTop: '30px' }}
              isDisabled={requestingDeletion || !isAccountDeletable}
            >
              Delete Account
            </Button>
          </div>
          <DeleteAccountConfirmation
            isOpen={showConfirmationModal}
            onClose={onCloseModal}
            onYes={handleDeleteAccount}
            loading={requestingDeletion}
          />
        </DeletionFormContainerStyled>
      </div>
    </div>
  );
};

export default DeleteAccount;
