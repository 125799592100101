const bookingSteps = [
  {
    title: 'Booking',
    subTitle:
      'You initiate booking of a property, your profile will be shared to property owner.',
  },
  {
    title: 'Landlord Review Booking',
    subTitle: 'Landlord will review your booking and profile',
  },
  {
    title: 'Communication',
    subTitle:
      'Landlord will communicate with you using Rentisity messaging feature, and come to informal agreement regarding price and property related terms.',
  },
  {
    title: 'Contract Generation',
    subTitle: 'Landlord will generate contract and send to you.',
  },
  {
    title: 'Contract Sign',
    subTitle: 'You and landlord both will sign the contract',
  },
  {
    title: 'Property handover',
    subTitle:
      'After completing all required things property will handed over to you.',
  },
].filter(Boolean);

export default bookingSteps;
