import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import SideMenu from '../SideMenu/index';
import Header from '../Header/Header';

const PrivateLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <>
      <Header />
      <BodyStyled>
        <SidebarStyled>
          <SideMenu />
        </SidebarStyled>
        <MainWrapperStyled>
          {!!children ? children : <Outlet />}
        </MainWrapperStyled>
      </BodyStyled>
    </>
  );
};

export default PrivateLayout;

const BodyStyled = styled.div`
  display: flex;
  align-items: flex-start;
  min-height: calc(100vh - 60px);
  position: relative;
  padding-top: 60px;
`;

const MainWrapperStyled = styled.main`
  width: 100%;
  padding: 12px 80px;
  flex: 1;
  padding-left: calc(270px + 80px);

  @media (max-width: 900px) {
    padding: 12px 40px;
    padding-left: calc(270px + 40px);
  }
  @media (max-width: 765px) {
    padding: 12px 20px;
    padding-left: calc(0px + 20px);
    width: 100%;
  }
`;

const SidebarStyled = styled.div`
  @media (max-width: 765px) {
    display: none;
  }
`;
