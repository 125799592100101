import { useRoot } from '@/RootProvider';
import useAPI from '@/hooks/api';
import Button from '@/newComponents/Button';
import CustomRate from '@/newComponents/CustomRate';
import GoBack from '@/newComponents/GoBack';
import Modal from '@/newComponents/Modal';
import NoData from '@/newComponents/NoData';
import Typography from '@/newComponents/Typography';
import { devices } from '@/utils/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Error from '@shared/web/components/Error';
import InputField from '@shared/web/components/InputField';
import LoadingPlaceholder from '@shared/web/components/LoadingPlaceholder';
import MaskInputField from '@shared/web/components/MaskInputField';
import SideProfile from '@shared/web/components/SideProfile';
import TextField from '@shared/web/components/TextField';
import { Field, Formik } from 'formik';
import { capitalize } from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React, { useEffect, useState } from 'react';
import Rating from 'react-rating';
import { useNavigate, useParams } from 'react-router-dom';
import ShowMoreText from 'react-show-more-text';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import * as yup from 'yup';

const Content = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: column-reverse;
  gap: 20px;

  @media ${devices.laptop} {
    flex-direction: row;
  }
`;

const LeftBox = styled.div`
  flex: 1;
`;

const RightBox = styled.div`
  text-align: center;
  width: 100%;

  display: flex;
  flex-direction: column;

  @media ${devices.tablet} {
    width: 100%;
    gap: 20px;
    flex-direction: row;
  }

  @media ${devices.laptop} {
    width: 320px;
    flex-direction: column;
  }
`;

const RightInnerBox = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 2px;
  padding: 20px;
  margin: 0 auto;
  width: 100%;
  gap: 20px;
  & > button {
    width: 100%;
  }
`;

const DescContainer = styled.div`
  margin: 20px 0;
  padding: 20px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`;

const FeedBack = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 100%;
`;

const DataRowWrapperStyled = styled.div`
  & .label,
  & .value {
    font-size: 12px;
    font-weight: 400;
    line-height: 25px;
    color: #333333;

    @media ${devices.laptopXL} {
      font-size: 14px;
      line-height: 30px;
    }
  }
  & .label {
    margin-right: 15px;
  }
  & .value {
    color: #8a8a8a;
  }
`;

const DescWrapper = styled.div`
  & .descriptionTextArea {
    resize: none;
    min-height: 50px;
  }

  & .counter {
    text-align: right;
  }
`;

const PropertyView = () => {
  // @ts-ignore
  const { id: contractId } = useParams();
  const [getContractRequest, { loading, data }] = useAPI();
  const [isReviewModal, setReviewModalVisibility] = useState(false);
  const [isPaymentModal, setPaymentModalVisibility] = useState(false);
  const [makeReviewRequest, { loading: reviewLoading }] = useAPI();
  const [makePaymentRequest, { loading: paymentLoading }] = useAPI();
  const [makeMessageRequest, { loading: messageLoading }] = useAPI();
  const { auth } = useRoot();
  const user = auth;
  const navigate = useNavigate();
  const serviceId = data?.serviceId;
  const getContract = async () => {
    const contractDetails = await getContractRequest({
      method: 'get',
      url: `/v1/services/contracts/${contractId}`,
    });
    return contractDetails;
  };
  useEffect(() => {
    if (contractId) {
      getContract();
    }
  }, []);

  const handleReview = async (values: any) => {
    if (auth?.role === 'service') {
      values.revieweeId = data.ownerId;
    } else {
      values.revieweeId = data.serviceProviderId;
    }
    values.reviewerId = auth.id;
    await makeReviewRequest({
      method: 'post',
      url: `/v1/common/reviews`,
      data: { ...values, contractId },
    });
    setReviewModalVisibility(false);
    await getContract();
    toast('Your review has been submitted successfully.');
  };

  type ServicePaymentReleaseType = {
    serviceId: number;
    serviceProposalId: number;
    serviceContractId: number;
    debitedFrom: number;
    creditedTo: number;
    amount: number;
    transactionType: 'Service';
    remark?: string;
  };

  const handlePayment = async (values: {
    amount: number;
    description: string;
  }) => {
    const contractDetails: {
      id: number;
      serviceId: number;
      proposalId: number;
      serviceProviderId: number;
      price: number;
      ownerId: number;
    } = await getContract();
    const paymentData: ServicePaymentReleaseType = {
      serviceId: contractDetails?.serviceId,
      serviceProposalId: contractDetails?.proposalId,
      serviceContractId: contractDetails?.id,
      debitedFrom: contractDetails?.ownerId,
      creditedTo: contractDetails?.serviceProviderId,
      amount: values.amount,
      transactionType: 'Service',
      remark: values.description,
    };

    try {
      if (user.hasValidPaymentMethod) {
        await makePaymentRequest({
          method: 'post',
          url: `/v1/payments/service/release`,
          data: paymentData,
        });
        toast.success('Payments has been released successfully.');
      } else {
        return toast.error('Please add the Payment Methods');
      }
      setPaymentModalVisibility(false);
      await getContract();
    } catch (error: any) {
      toast.error(error);
    }

    // navigate('/jobs');
  };

  const handleMessage = async ({
    serviceId,
    landlordId,
    tenantId,
    jobId,
    chatType,
  }: {
    serviceId: number;
    landlordId?: number;
    tenantId?: number;
    chatType?: string;
    jobId?: number;
  }) => {
    const messageId = await makeMessageRequest({
      method: 'post',
      url: `/v1/common/message`,
      data: {
        serviceId,
        landlordId,
        tenantId,
        jobId,
        chatType,
      },
    });

    navigate(`/chats/${messageId}`);
  };
  const paymentType = data?.service?.priceType;
  const reviewForMe = data?.reviews?.find(
    (item: any) => item.revieweeId === auth?.id,
  );
  const reviewByMe = data?.reviews?.find(
    (item: any) => item.revieweeId !== auth?.id,
  );

  const [initalState, setInitialState] = useState({
    price: data?.price,
    amount: !!data?.price && paymentType === 'hourly' ? '' : data?.price,
    description: '',
  });

  useEffect(() => {
    setInitialState({
      price: data?.price,
      amount: !!data?.price && paymentType === 'hourly' ? '' : +data?.price,
      description: '',
    });
  }, [data]);
  return (
    <div>
      <GoBack />
      {isPaymentModal && (
        <Modal
          title="Release payment"
          open={isPaymentModal}
          onClose={() => setPaymentModalVisibility(false)}
        >
          <Formik
            initialValues={initalState}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              await handlePayment(values);
              setSubmitting(false);
              resetForm();
            }}
            validationSchema={yup.object().shape({
              description: yup.string().required('Please enter a description.'),
              amount: yup.string().required('Please enter a amount.'),
            })}
          >
            {({
              isSubmitting,
              errors,
              touched,
              values,
              setFieldValue,
              handleSubmit,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Typography variant="p">
                    {paymentType === 'hourly'
                      ? `Contract rate: €${numeral(data?.price).format(
                          '0,0.00',
                        )}/hr`
                      : `Contract rate: €${numeral(data?.price).format(
                          '0,0.00',
                        )}`}
                  </Typography>
                  <Typography variant="p">
                    Paid amount: : €{numeral(data?.paidAmount).format('0,0.00')}
                  </Typography>
                  <DescWrapper>
                    <Typography
                      variant="p"
                      style={{ display: 'block', marginBottom: 20 }}
                    >
                      {paymentType !== 'hourly' &&
                        ` Remaining amount: : €
                      ${numeral(data?.price - data?.paidAmount).format(
                        '0,0.00',
                      )}`}
                    </Typography>
                    <Field
                      component={TextField}
                      name="description"
                      label="Remarks"
                      maxLength={200}
                      className="descriptionTextArea"
                    />
                    <Typography variant="p" className="counter">
                      {`${values.description?.length} / 200`}
                    </Typography>
                  </DescWrapper>
                  {paymentType === 'hourly' ? (
                    <Field
                      component={InputField}
                      label="Hours"
                      name="hours"
                      onChange={async (
                        e: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        await setFieldValue('hours', e.target.value);
                        const newAmount = (
                          data?.price * parseInt(e.target.value, 10)
                        ).toFixed(2);
                        await setFieldValue('amount', newAmount);
                      }}
                    />
                  ) : (
                    <Field
                      component={MaskInputField}
                      setFieldValue={setFieldValue}
                      name="amount"
                      label="Amount"
                      value={values?.amount}
                      decimalSeparator="."
                      thousandSeparator={true}
                      prefix={'€'}
                      disabled={true}
                    />
                  )}

                  {paymentType === 'hourly' ? (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <label style={{ margin: '10px 0px' }}>
                        <Typography variant="p">Total Amount</Typography>
                      </label>
                      <Field
                        value={values?.amount}
                        disabled={true}
                        component={MaskInputField}
                        setFieldValue={setFieldValue}
                        decimalSeparator="."
                        thousandSeparator={true}
                        prefix={'€'}
                      />
                    </div>
                  ) : null}

                  {errors?.amount && touched?.amount && (
                    <Error message={errors.amount} />
                  )}
                  <Content>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Button
                        htmlType="submit"
                        variant="primary"
                        isDisabled={paymentLoading || isSubmitting}
                        isLoading={paymentLoading || isSubmitting}
                      >
                        Release payment
                      </Button>
                    </div>
                  </Content>
                </form>
              );
            }}
          </Formik>
        </Modal>
      )}

      <Modal
        title="Submit review"
        open={isReviewModal}
        onClose={() => setReviewModalVisibility(false)}
      >
        <Formik
          initialValues={{
            price: data?.price,
            rating: data?.rating,
          }}
          onSubmit={values => {
            handleReview(values);
          }}
          validationSchema={yup.object().shape({
            rating: yup.number().required('Please rate.'),
            review: yup.string().required('Please provide review.'),
          })}
        >
          {({
            isSubmitting,
            errors,
            touched,
            values,
            setFieldValue,
            handleSubmit,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Typography variant="p" style={{ display: 'block' }}>
                  Rate
                </Typography>

                {/*@ts-ignore*/}
                <Rating
                  onChange={val => setFieldValue('rating', val)}
                  emptySymbol={
                    <FontAwesomeIcon
                      icon={['far', 'star']}
                      size="lg"
                      style={{ margin: '0px 2px' }}
                    />
                  }
                  fullSymbol={
                    <FontAwesomeIcon
                      icon={['fas', 'star']}
                      color="#F1C40F"
                      size="lg"
                      style={{ margin: '0px 2px' }}
                    />
                  }
                  initialRating={values?.rating || 0}
                />
                {/* @ts-ignore */}
                <Error message={errors?.rating} />
                <Field component={TextField} label="Feedback" name="review" />
                <Content>
                  <Error message="" />
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      htmlType="submit"
                      variant="primary"
                      isDisabled={reviewLoading || isSubmitting}
                      isLoading={reviewLoading || isSubmitting}
                    >
                      Submit
                    </Button>
                  </div>
                </Content>
              </form>
            );
          }}
        </Formik>
      </Modal>

      {loading && (
        <div
          style={{
            height: 100,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <LoadingPlaceholder />
        </div>
      )}

      <>
        <Content>
          <LeftBox>
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="p" size="lg" color="#292929">
                  {data?.service?.title}
                </Typography>

                <Button variant="tag" radius="round">
                  {capitalize(data?.status)}
                </Button>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <DataRowWrapperStyled>
                  <Typography variant="span" className="label">
                    Payment Type
                  </Typography>
                  <Typography variant="span" className="value">
                    {capitalize(paymentType)}
                  </Typography>
                </DataRowWrapperStyled>
                <DataRowWrapperStyled>
                  <Typography variant="span" className="label">
                    Contract rate
                  </Typography>
                  <Typography variant="span" className="value">
                    {paymentType === 'hourly'
                      ? `€
                        ${numeral(data?.price).format('0,0.00')}/hr`
                      : `€ ${numeral(data?.price).format('0,0.00')}`}
                  </Typography>
                </DataRowWrapperStyled>
                <DataRowWrapperStyled>
                  <Typography variant="span" className="label">
                    Paid amount
                  </Typography>
                  <Typography variant="span" className="value">
                    € {numeral(data?.paidAmount).format('0,0.00')}
                  </Typography>
                </DataRowWrapperStyled>
                <DataRowWrapperStyled>
                  <Typography variant="span" className="label">
                    Payment Status
                  </Typography>
                  <Typography variant="span" className="value">
                    {capitalize(data?.paymentStatus)}
                  </Typography>
                </DataRowWrapperStyled>
                <DataRowWrapperStyled>
                  <Typography variant="span" className="label">
                    Hired At
                  </Typography>
                  <Typography variant="span" className="value">
                    {moment.utc(data?.createdAt).local().fromNow()}
                  </Typography>
                </DataRowWrapperStyled>
                <DataRowWrapperStyled>
                  <Typography variant="span" className="label">
                    Callout
                  </Typography>
                  <Typography variant="span" className="value">
                    {data?.service?.isCallout ? 'Yes' : 'No'}
                  </Typography>
                </DataRowWrapperStyled>
              </div>
            </div>
            <DescContainer>
              <Typography
                variant="p"
                color="#333"
                fontWeight="extrabold"
                style={{ marginBottom: '15px' }}
              >
                Work Description
              </Typography>
              <ShowMoreText
                lines={10}
                more={'Show more'}
                less="Show less"
                truncatedEndingComponent={'...'}
              >
                <Typography variant="p">{data?.service?.desc}</Typography>
              </ShowMoreText>
            </DescContainer>
            {data?.status === 'Completed' && (
              <>
                <DescContainer>
                  {/* <Typography variant="p">
                    Your feedback to{' '}
                    {auth?.role === 'service' ? 'client' : 'service provider'}
                  </Typography> */}
                  <FeedBack>
                    <Typography
                      variant="p"
                      color="#333"
                      style={{ marginBottom: '10px', fontWeight: '600' }}
                    >
                      Feedback
                    </Typography>
                    {!reviewByMe && (
                      <Button onClick={() => setReviewModalVisibility(true)}>
                        Give feedback
                      </Button>
                    )}
                  </FeedBack>
                  {!reviewByMe ? (
                    <Typography variant="p">
                      <NoData
                        size="130"
                        title={`You haven't give your feedback to ${
                          auth?.role === 'service'
                            ? 'client'
                            : 'service provider'
                        }`}
                      />
                    </Typography>
                  ) : (
                    <div>
                      <CustomRate value={reviewByMe.rating} disabled isLong />
                      <Typography variant="p">{reviewByMe.review}</Typography>
                    </div>
                  )}
                </DescContainer>
                <DescContainer>
                  <Typography
                    variant="p"
                    color="#333"
                    fontWeight={'extrabold'}
                    style={{ marginBottom: '10px', fontWeight: '600' }}
                  >
                    {auth?.role === 'service' ? 'Client' : 'Service provider'}{' '}
                    feedback to you
                  </Typography>
                  {!reviewForMe ? (
                    <Typography variant="p">
                      <NoData
                        size="130"
                        title={`${
                          auth?.role === 'service'
                            ? 'Client'
                            : 'Service provider'
                        }
                      haven't provide feedback yet`}
                      />
                    </Typography>
                  ) : (
                    // <Typography variant="p" style={{ display: 'block' }}>
                    //   {auth?.role === 'service' ? 'Client' : 'Service provider'}{' '}
                    //   haven't provide feedback yet
                    // </Typography>
                    <div>
                      <CustomRate isLong value={reviewForMe.rating} disabled />
                      <Typography variant="p">{reviewForMe.review}</Typography>
                    </div>
                  )}
                </DescContainer>
              </>
            )}
          </LeftBox>
          <RightBox>
            <SideProfile
              profileData={
                auth?.role === 'service' ? data?.owner : data?.serviceProvider
              }
              role={
                auth?.role === 'service'
                  ? data?.owner?.role
                  : data?.serviceProvider?.role
              }
              isProfileViewable
            />
            <RightInnerBox>
              {auth?.role !== 'service' && data?.status !== 'Completed' && (
                <Button
                  variant="primary"
                  bgColor={data?.status !== 'Completed' ? 'primary' : ''}
                  onClick={() => setPaymentModalVisibility(true)}
                  isDisabled={data?.status === 'Completed' ? true : false}
                >
                  Release payment
                </Button>
              )}

              <Button
                variant="gray"
                size="md"
                style={{ height: '34px' }}
                onClick={() => navigate(`/jobs/${data.serviceId}`)}
              >
                View job
              </Button>
              <Button
                variant="ghost"
                size="md"
                style={{ height: '34px', border: '2px solid #dbdbdb' }}
                onClick={() => navigate(`/proposals/${data.proposalId}`)}
              >
                View Proposal
              </Button>
            </RightInnerBox>
          </RightBox>
        </Content>
      </>
    </div>
  );
};

export default PropertyView;
