import Typography from '@/newComponents/Typography';
import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { devices } from '@/utils/theme';
import { Info, InfoLabel, StyledButton } from './style';
import Button from '@/newComponents/Button';

interface PropertyInfoProps {
  area: number;
  address: string;
  bathrooms: number;
  bedrooms: number;
  berNo: string;
  furnishedType: string;
  id: string;
  propertyType: string;
  rentalMarket: string;
}

const PropertyInfo = ({
  area,
  address,
  bathrooms,
  bedrooms,
  berNo,
  furnishedType,
  id,
  propertyType,
  rentalMarket,
}: PropertyInfoProps) => {
  const navigate = useNavigate();
  return (
    <Container>
      <InfoWrapper>
        <Address variant="h1">{address || 'N/A'}</Address>
        <InfoContainer>
          <InfoLabel variant="p">
            Area&nbsp;&nbsp;<Info variant="span">{area || 'N/A'}</Info>
          </InfoLabel>
          <InfoLabel variant="p">
            Bedrooms&nbsp;&nbsp;
            <Info variant="span">{bedrooms || 'N/A'}</Info>
          </InfoLabel>
          <InfoLabel variant="p">
            Bathrooms&nbsp;&nbsp;
            <Info variant="span">{bathrooms || 'N/A'}</Info>
          </InfoLabel>
          <InfoLabel variant="p">
            Property Type&nbsp;&nbsp;
            <Info variant="span">{propertyType || 'N/A'}</Info>
          </InfoLabel>
          <InfoLabel variant="p">
            Furnished Type&nbsp;&nbsp;
            <Info variant="span">{furnishedType || 'N/A'}</Info>
          </InfoLabel>
          <InfoLabel variant="p">
            Rental Market&nbsp;&nbsp;
            <Info variant="span">{rentalMarket || 'N/A'}</Info>
          </InfoLabel>
          <InfoLabel variant="p">
            Ber No.&nbsp;&nbsp;
            <Info variant="span">{berNo || 'N/A'}</Info>
          </InfoLabel>
        </InfoContainer>
      </InfoWrapper>
      <ViewPropertyButton onClick={() => navigate(`/properties/${id}`)}>
        View Property Details
      </ViewPropertyButton>
    </Container>
  );
};

export default PropertyInfo;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media ${devices.laptop} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media ${devices.laptop} {
    gap: 12px;
  }
`;

const Address = styled(Typography)`
  font-size: 16px;
  font-weight: 500;

  @media ${devices.laptop} {
    font-size: 26px;
  }

  @media ${devices.laptopXL} {
    font-size: 32px;
  }
`;

const ViewPropertyButton = styled(Button)`
  font-size: 12px;
  line-height: 25px;
  padding: 0 30px;
  white-space: nowrap;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 18px;
  align-items: center;
  flex-wrap: wrap;

  @media ${devices.laptop} {
    gap: 24px;
  }

  @media ${devices.laptopXL} {
    gap: 30px;
  }
`;
