import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import ListPlugin from '@fullcalendar/list';
import { useSelector } from 'react-redux';
import { Store } from '@shared/front';
import { Apis, Utils } from '@shared/front';
import moment from 'moment';
import styled from 'styled-components';
import { devices } from '@/utils/theme';
import CustomTooltip from '@/newComponents/CustomTooltip';
const FullCalendarList = () => {
  const notifications: any[] = useSelector(Store.Select.notifications);
  const appointments: any[] = useSelector(Store.Select.myAppointments);

  const CalendarContainer = styled.div`
    position: relative;
    padding: 20px;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    .fc-toolbar-title {
      font-size: 10px;
      @media ${devices.tablet} {
        font-size: 20px;
      }
    }

    .fc-button-primary {
      border: none;
      outline: none;
      height: 40px;
      padding: 3px 16px;
      border-radius: 5px;
      background-color: #e0e0e0;
      text-transform: capitalize;
      font-family: Poppins;
      font-size: 12px;
      line-height: 25px;
      color: #333333;

      &:hover,
      &:active,
      &:focus {
        box-shadow: none !important;
        background-color: #c7c7c7;
        color: #333333;
      }
    }

    .fc-button-primary:not(:disabled).fc-button-active {
      background-color: #c7c7c7;
      color: #333333;
    }

    .fc-button-primary:disabled {
      background-color: #e0e0e0;
      color: #333333;
      border: none;
    }

    .fc-button-primary:not(:disabled):active {
      background-color: #c7c7c7;
      color: #333333;
    }

    .fc-prev-button,
    .fc-next-button {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #8a8a8a;
      background-color: transparent;
      padding: 0;

      &:hover,
      &:active,
      &:focus {
        background-color: transparent;
        color: #8a8a8a;
      }

      &:not(:disabled):active {
        background-color: transparent;
      }

      > .fc-icon {
        vertical-align: initial;
      }
    }

    .fc-next-button {
      margin-left: 10px !important;
    }

    .fc-toolbar-title {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      color: #1f1f1f;

      @media ${devices.mobileSM} {
        font-size: 16px;
      }

      @media ${devices.tablet} {
        font-size: 18px;
      }
    }

    .fc-scrollgrid {
      background-color: #f0f0f0;
      border: none;

      th {
        border: 2px solid #ebebeb;
        border-bottom: none;
      }

      td {
        border: 2px solid #ebebeb;
      }
    }

    .fc-scrollgrid-sync-inner {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .fc-col-header-cell-cushion {
      font-family: Poppins;
      font-size: 14px;
      line-height: 30px;
      font-weight: 400;
      color: #333333 !important;
    }

    .fc-daygrid-day-number {
      color: #333333 !important;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;

      @media ${devices.mobileSM} {
        font-size: 16px;
      }

      @media ${devices.tablet} {
        font-size: 18px;
      }

      @media ${devices.laptop} {
        font-size: 22px;
      }
    }

    .fc-daygrid-day.fc-day-today {
      background-color: transparent;

      & .fc-daygrid-day-number {
        color: #2061f8 !important;
      }
    }
  `;

  useEffect(() => {
    const getMyAppointments = async () => {
      await Utils.request(Apis.Notification.getMyAppointments());
    };
    getMyAppointments();
  }, []);

  let events = [];

  // Add notifications to events array
  notifications.forEach(notification => {
    const startDate = moment(notification.createdAt).format('YYYY-MM-DD');
    const event = {
      title: notification.title,
      start: startDate,
      backgroundColor: 'green',
      borderColor: 'darkgreen',
      color: 'red',
    };
    events.push(event);
  });

  // Add appointments to events array
  appointments.forEach(appointment => {
    const startDate = moment(appointment.appoinmentDate).format('YYYY-MM-DD');
    const event = {
      title: `Appointment with ${appointment?.landlord?.firstName} ${appointment?.landlord?.lastName} and ${appointment?.tenant?.firstName} ${appointment?.tenant?.lastName}`,
      start: startDate,
      backgroundColor: 'blue',
      borderColor: 'darkgreen',
      color: 'blue',
    };
    events.push(event);
  });

  return (
    <CalendarContainer>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, ListPlugin]}
        initialView="listWeek"
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
        }}
        events={events}
        eventBorderColor="red"
        dayMaxEvents={3}
        eventMinHeight={30}
        eventContent={eventInfo => {
          const isMonthView = eventInfo.view.type === 'dayGridMonth';
          const title = isMonthView
            ? eventInfo?.event?.title.substring(0, 20) + '.....'
            : eventInfo?.event?.title;

          return (
            <div>
              <div>{eventInfo?.timeText}</div>
              <div>{moment(eventInfo?.event?.start).format('YYYY-MM-DD')}</div>
              <CustomTooltip title={eventInfo?.event?.title}>
                {title}
              </CustomTooltip>
            </div>
          );
        }}
      />
    </CalendarContainer>
  );
};

export default FullCalendarList;
