import React, { useState, useRef } from 'react';
import styled from 'styled-components';

const CodeInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
`;

const CodeInputs = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;

  input {
    border: none;
    background-image: none;
    background-color: white;
    box-shadow: none;
    text-align: center;
    height: 50px;
    width: 40px;
    border-radius: 4px;
    border: 1px solid #ebebeb;
    margin: 0 4px;
    font-size: 32px;
    padding: 5px auto;

    &:focus {
      outline: none;
      border: 1px solid ${({ theme }) => theme.colors.primary};
    }
    &:nth-child(3n) {
      margin-right: 24px;
    }
  }
`;

const InputCode = ({ length, loading, onComplete, defaultValue }) => {
  const [code, setCode] = useState(
    defaultValue ? defaultValue.split('') : [...Array(length)].map(() => ''),
  );
  const inputs = useRef<any[]>([]);

  const processInput = (e, slot) => {
    const num = e.target.value;
    // console.log('number', num);
    if (/[^0-9]/.test(num)) return;
    const newCode = [...code];
    newCode[slot] = num;
    setCode(newCode);
    if (slot !== length - 1) {
      // console.log('test te', inputs.current[0]);
      inputs.current[slot + 1]?.focus();
    }
    if (newCode.every(num => num !== '')) {
      onComplete(newCode.join(''));
    }
  };

  const onKeyUp = (e, slot) => {
    if (e.keyCode === 8 && !code[slot] && slot !== 0) {
      // console.log('test for focus');
      const newCode = [...code];
      newCode[slot - 1] = '';
      setCode(newCode);
      inputs.current[slot - 1]?.focus();
    }
  };

  return (
    <CodeInput>
      <CodeInputs>
        {code.map((num, idx) => {
          return (
            <input
              key={idx}
              type="text"
              inputMode="numeric"
              maxLength={1}
              value={num}
              autoFocus={!code[0].length && idx === 0}
              readOnly={loading}
              onChange={e => processInput(e, idx)}
              onKeyUp={e => onKeyUp(e, idx)}
              ref={ref => inputs.current.push(ref)}
            />
          );
        })}
      </CodeInputs>
    </CodeInput>
  );
};

export default InputCode;
