import useAPI from '@/hooks/api';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useRoot } from '@/RootProvider';
import DatePickerField from '@shared/web/components/DatePicker';
import MaskInputField from '@shared/web/components/MaskInputField';
import { Field, Form, Formik } from 'formik';
import { capitalize } from 'lodash';
import numeral from 'numeral';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import Modal from '@/newComponents/Modal';
import Button from '@/newComponents/Button';
import Typography from '@/newComponents/Typography';
import { Apis, Hooks } from '@shared/front';
import CustomRate from '@/newComponents/CustomRate';
import { useResponsive } from '@/hooks/useResponsive';
import moment from 'moment';

const ProposalContentsWrapperStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const AvatarAndInfoWrapperStyled = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1;
`;

const ActionsContainerStyled = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 8px;
`;
const ProposalView = styled.div`
  padding: 25px 0px;
  border-bottom: 1px solid #f0f0f0;
`;

const UserAvatar = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 20px;
  margin: 30px 16px 0px 0px;
`;

const ActionButtonWrapper = styled.div`
  display: flex;
  align-items: centrer;
  justify-content: flex-end;
  gap: 30px;
`;
const CallOutInfoWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

type Props = {
  proposal: any;
  getProperty: () => void;
  serviceId: number;
  isCallout: boolean;
  hasAlreadyHiredOne: boolean;
};

const Proposal = ({
  proposal,
  getProperty,
  serviceId,
  isCallout,
  hasAlreadyHiredOne,
}: Props) => {
  const { isTablet } = useResponsive();

  const { id: jobId } = useParams();
  const [callOutModal, setCallOutModal] = useState(false);
  const [hireModal, setHireModal] = useState<{
    status: boolean;
    data: any;
    info: any;
  }>({
    status: false,
    data: null,
    info: null,
  });
  const [makeMessageRequest, { loading: messageLoading }] = useAPI();
  const [makeHireRequest, { loading: hireLoading }] = useAPI();
  const [makeArchiveRequest, { loading: archiveLoading }] = useAPI();
  const [handleSubmitRequest, { loading, error }] = useAPI();

  const [makeReadRequest, { loading: readLoading }] = useAPI();
  const [requestForCallout, { loading: requestForCalloutLoading }] =
    Hooks.useAPI();
  const { auth } = useRoot();
  // console.log('the auth value', auth);
  const navigate = useNavigate();
  const role: any = `${auth?.role}Id`;

  const handleMessage = async ({ serviceProviderId }: any) => {
    const messageId = await makeMessageRequest({
      method: 'post',
      url: `/v1/common/message`,
      data: {
        serviceId: serviceProviderId,
        landlordId: auth?.role === 'landlord' ? auth?.id : null,
        tenantId: auth?.role === 'tenant' ? auth?.id : null,
        jobId: proposal?.serviceId ? proposal?.serviceId : null,
        chatType: 'job',
      },
    });

    navigate(`/chats/${messageId}`);
  };

  const handleRequestForCallout = async (values: any) => {
    try {
      await requestForCallout(
        Apis.Proposal.updateProposal(
          {
            calloutFee: values?.calloutFee,
            calloutDate: values?.calloutDate,
            isRequestCallout: true,
          },
          proposal?.id,
        ),
      );
      setCallOutModal(false);
      toast.success('Callout request has been sucessfully sent');
      getProperty();
    } catch (error) {}
  };

  const handleHire = async ({
    serviceId,
    proposalId,
    serviceProviderId,
  }: {
    serviceId?: number;
    proposalId?: number;
    serviceProviderId: number;
  }) => {
    const data = { serviceId, proposalId, serviceProviderId };
    await handleSubmitRequest(Apis.Contract.serviceContract(data));
    await getProperty();
    onCloseHireModal();
  };

  const onHire = async ({
    serviceId,
    proposalId,
    serviceProviderId,
    info,
  }: {
    serviceId?: number;
    proposalId?: number;
    serviceProviderId: number;
    info: any;
  }) => {
    setHireModal({
      status: true,
      data: { serviceId, proposalId, serviceProviderId },
      info,
    });
  };

  const onCloseHireModal = () => {
    setHireModal({ status: false, data: null, info: null });
  };

  const handleRead = async (proposalId: number) => {
    await makeHireRequest({
      method: 'patch',
      url: `/v1/services/proposals/${proposalId}`,
      data: {
        isRead: true,
      },
    });
    getProperty();
  };

  const handleArchive = async (proposalId: number) => {
    await makeArchiveRequest({
      method: 'patch',
      url: `/v1/services/proposals/${proposalId}`,
      data: {
        isArchived: true,
      },
    });
    getProperty();
  };

  // console.log('the proposal', proposal);

  return (
    <>
      <ProposalView>
        {callOutModal && (
          <Modal
            title="CallOut"
            open={callOutModal}
            onClose={() => setCallOutModal(false)}
          >
            <Formik
              initialValues={{}}
              onSubmit={values => {
                handleRequestForCallout(values);
              }}
              validationSchema={yup.object().shape({
                calloutFee: yup.number().required('Please enter callout fees'),
                calloutDate: yup
                  .string()
                  .required('Please select callout date'),
              })}
            >
              {({
                values,
                setFieldValue,
                isSubmitting,
                errors,
                isValid,
                dirty,
              }) => {
                return (
                  <Form>
                    <Field
                      component={MaskInputField}
                      name="calloutFee"
                      label="Callout Fee"
                      decimalSeparator="."
                      displayType="input"
                      type="text"
                      thousandSeparator={true}
                      prefix={'€'}
                      setFieldValue={setFieldValue}
                    />
                    <Field
                      component={DatePickerField}
                      name="calloutDate"
                      label="Callout Date"
                    />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '20px',
                        marginTop: '30px',
                      }}
                    >
                      <div>
                        <Button
                          variant="gray"
                          onClick={() => setCallOutModal(false)}
                          isDisabled={requestForCalloutLoading}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div>
                        <Button
                          htmlType="submit"
                          variant="primary"
                          isLoading={requestForCalloutLoading}
                          isDisabled={requestForCalloutLoading || !isValid}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </Modal>
        )}
        <ProposalContentsWrapperStyled>
          <AvatarAndInfoWrapperStyled>
            <UserAvatar
              src={
                proposal?.provider?.avatar
                  ? proposal?.provider?.avatar
                  : 'https://img.freepik.com/free-icon/user_318-159711.jpg'
              }
            />
            <div>
              <Button
                size="sm"
                radius="round"
                style={{
                  backgroundColor:
                    proposal.status.toLowerCase() === 'active'
                      ? '#047104'
                      : '#1687e3',
                  cursor: 'default',
                  marginBottom: '8px',
                }}
              >
                {capitalize(proposal.status)}
              </Button>
              <Typography variant="h5">{proposal.provider.fullName}</Typography>

              <div style={{ margin: '10px 0px' }}>
                <CustomRate value={proposal.provider.rating} isLong />
              </div>

              <Typography variant="span">
                Proposed rate: €{numeral(proposal.price).format('0,0.00')}
              </Typography>

              {proposal?.isRequestCallout &&
              !proposal?.isServiceProviderAcceptCallout ? (
                <Typography
                  variant="p"
                  style={{
                    background: '#163d83',
                    color: '#fff',
                    padding: '3px 5px',
                    borderRadius: '3px',
                    margin: '10px 0px',
                  }}
                >
                  Request for CallOut has been sent.
                </Typography>
              ) : proposal?.isRequestCallout &&
                proposal?.isServiceProviderAcceptCallout ? (
                <Typography
                  variant="p"
                  style={{
                    background: '#00af8f',
                    color: '#fff',
                    padding: '3px 5px',
                    borderRadius: '3px',
                    margin: '10px 0px',
                  }}
                >
                  Request for CallOut has been accepted.
                </Typography>
              ) : null}
              {proposal?.isRequestCallout &&
                proposal?.isServiceProviderAcceptCallout && (
                  <CallOutInfoWrapperStyled>
                    <Typography variant="p">
                      Callout Fee : &euro;{proposal?.calloutFee}
                    </Typography>
                    <Typography variant="p">
                      Callout Date :{' '}
                      {moment(proposal.calloutDate).format('YYYY, MMM DD')}
                    </Typography>
                  </CallOutInfoWrapperStyled>
                )}
            </div>
          </AvatarAndInfoWrapperStyled>
          <ActionsContainerStyled>
            {/* {!proposal.isRead && (
              <Button
                loading={readLoading}
                disabled={readLoading}
                onClick={() => handleRead(proposal.id)}
              >
                Mark as read
              </Button>
            )} */}

            <Button
              variant="gray"
              size="sm"
              onClick={() => navigate(`/proposals/${proposal.id}`)}
            >
              View
            </Button>
            {(auth?.role === 'landlord' || auth?.role === 'tenant') &&
              !hasAlreadyHiredOne &&
              isCallout &&
              !proposal.isRequestCallout &&
              proposal?.status !== 'Hired' &&
              proposal?.status === 'Active' && (
                <Button size="sm" onClick={() => setCallOutModal(true)}>
                  Request for Callout
                </Button>
              )}
            <Button
              onClick={() =>
                handleMessage({
                  serviceProviderId: proposal.providerId,
                  role: +auth?.id,
                })
              }
              size="sm"
            >
              Message
            </Button>
            {/* {proposal.status !== 'Hired' && (
              <Button
                loading={archiveLoading}
                disabled={archiveLoading}
                onClick={() => handleArchive(proposal.id)}
              >
                Archive
              </Button>
            )} */}
            {proposal?.status === 'Active' && !hasAlreadyHiredOne && (
              <>
                {isCallout ? (
                  proposal.isRequestCallout &&
                  proposal.isServiceProviderAcceptCallout && (
                    <Button
                      size="sm"
                      isLoading={hireLoading}
                      isDisabled={hireLoading}
                      onClick={() =>
                        onHire({
                          serviceId,
                          proposalId: proposal?.id,
                          serviceProviderId: proposal?.providerId,
                          info: proposal,
                        })
                      }
                    >
                      Hire
                    </Button>
                  )
                ) : (
                  <Button
                    size="sm"
                    isLoading={hireLoading}
                    isDisabled={hireLoading}
                    onClick={() =>
                      onHire({
                        serviceId,
                        proposalId: proposal?.id,
                        serviceProviderId: proposal?.providerId,
                        info: proposal,
                      })
                    }
                  >
                    Hire
                  </Button>
                )}
              </>
            )}
          </ActionsContainerStyled>
        </ProposalContentsWrapperStyled>
      </ProposalView>
      <Modal
        open={hireModal.status && !!hireModal.data}
        onClose={() => {
          onCloseHireModal();
        }}
        title="Hire Service"
        style={{ width: !isTablet ? '95vw' : '500px' }}
      >
        <Typography variant="p" style={{ marginBottom: '30px' }}>
          Are you sure you want to hire {hireModal?.info?.provider?.fullName}?
        </Typography>
        <ActionButtonWrapper>
          <Button
            variant="gray"
            onClick={onCloseHireModal}
            isDisabled={hireLoading}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => handleHire(hireModal.data)}
            isDisabled={hireLoading}
            isLoading={hireLoading}
          >
            Hire
          </Button>
        </ActionButtonWrapper>
      </Modal>
    </>
  );
};

const Proposals = (props: any) => {
  return (
    <div>
      <Typography variant="h5" fontWeight="extrabold">
        Proposals
      </Typography>
      {props.proposals
        ?.filter((item: any) => !item.isArchived)
        .map((proposal: any) => (
          <Proposal {...props} proposal={proposal} key={proposal.id} />
        ))}
    </div>
  );
};

export default Proposals;
