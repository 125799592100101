import { useRoot } from '@/RootProvider';
import ProtectedComponent from '@/components/ProtectedComponent';
import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import routes from '../../routes';
import ScrollToTop from '@/components/ScrollToTop';
import { Apis, Utils } from '@shared/front';

export function App() {
  const root = useRoot();
  const isAuthenticated = !!root?.auth?.email;
  const userRole = root?.auth?.role;

  const fetchMyBookmarks = () => {
    if (root?.auth?.id) {
      Utils.request(Apis.Bookmark.checkBookmark('set'));
    }
  };

  useEffect(() => {
    fetchMyBookmarks();
  }, []);

  return (
    <>
      <ScrollToTop />
      <Routes>
        {routes(isAuthenticated)?.map(route => {
          if (
            !!userRole &&
            !!route?.allowedRoles &&
            !route?.allowedRoles.includes(userRole)
          ) {
            return null;
          }

          if (route.path === '*') {
            return (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <route.layout>
                    <ProtectedComponent
                      component={route.component}
                      isPublic={route?.isPublic}
                    />
                  </route.layout>
                }
              />
            );
          }

          return (
            <Route
              path={route?.path}
              element={<route.layout />}
              key={route?.path}
            >
              <Route
                index
                element={
                  <ProtectedComponent
                    component={route.component}
                    isPublic={route?.isPublic}
                  />
                }
              />
            </Route>
          );
        })}
      </Routes>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        // pauseOnHover

        style={{
          top: 60,
          right: 0,
          maxWidth: 400,
          width: '100%',
        }}
      />
    </>
  );
}
