import { ReturnType } from "./types";
export default {
  getServices: (obj: any): ReturnType => {
    let url;
    if (obj?.role) {
      url = obj?.role === "service" ? "/v1/services/search" : "/v1/services";
    } else {
      url = "/v1/services/search";
    }
    return {
      method: "get",
      url: url,
      params: obj?.params,
      config: {
        store: {
          action: obj?.action || "set",
          key: "jobs",
        },
      },
    };
  },

  getMyServices: (params: any = {}): ReturnType => {
    return {
      method: "get",
      url: "/v1/services",
      params,
    };
  },

  getAllBrowserServiceForWeb: ({ params, action }: any): ReturnType => {
    return {
      method: "get",
      url: "/v1/services/search",
      params: params,
      config: {
        store: {
          action: action || "set",
          key: "webbrowseServices",
        },
      },
    };
  },

  getSimilarServices: (id: number): ReturnType => {
    return {
      method: "get",
      url: `/v1/services/${id}/similar`,
      config: {
        store: {
          action: "set",
          key: "similarJobs",
        },
      },
    };
  },

  getServiceById: (id: number | undefined | null): ReturnType => {
    return {
      method: "get",
      url: `/v1/services/${id}`,
      config: {
        store: {
          action: "set",
          key: "job",
        },
      },
    };
  },

  createOrUpdateService: (
    obj: any,
    id?: number | undefined | null
  ): ReturnType => {
    return {
      method: id ? "put" : "post",
      url: `/v1/services/${id || ""}`,
      data: obj,
    };
  },
  updateServicePhoto: (data: any, id: number): ReturnType => {
    return {
      method: "post",
      url: `/v1/services/${id}/attachments`,
      data: data,
      config: {
        showErr: true,
      },
    };
  },

  deleteServiceAttachment: (id: number): ReturnType => {
    return {
      method: "delete",
      url: `/v1/services/attachment/${id}`,
    };
  },
  closeService: (id: number | undefined | null): ReturnType => {
    return {
      method: "post",
      url: `/v1/services/${id}/close`,
    };
  },

  deleteServiceById: (id: number | undefined | null): ReturnType => {
    return {
      method: "delete",
      url: `/v1/services/${id}`,
    };
  },
  getAllJobCategories: (): ReturnType => {
    return {
      method: "get",
      url: `/v1/admin/job-categories`,
      config: {
        store: {
          action: "set",
          key: "categories",
        },
      },
    };
  },
  updateServiceCategories: (data: any, id: number): ReturnType => {
    return {
      method: "post",
      url: `/v1/services/${id}/categories`,
      data: data,
    };
  },
  releasePayment: (data: any): ReturnType => {
    return {
      method: "post",
      url: `/v1/payments/service/release`,
      data: data,
      config: {
        successMsg: "Payments has been released successfully.",
      },
    };
  },

  getGuestServiceById: (id: number): ReturnType => {
    return {
      method: "get",
      url: `/v1/services/${id}/guests`,
      config: {
        store: {
          action: "set",
          key: "job",
        },
      },
    };
  },
};
