import React from 'react';
import { Dot, IStepItem } from './StepItem';
import styled from 'styled-components';
import Typography from '../Typography';

const StepsSmall = ({ items }: IStepItem) => {
  return (
    <Container>
      <ItemsWrapper>
        {items.map(item => (
          <Item $status={item.status} $length={items.length} />
        ))}
      </ItemsWrapper>
      <IndexWrapper>
        <Index>
          <Dot $status="finish" />
          <Label variant="p">Completed</Label>
        </Index>
        <Index>
          <Dot $status="process" />
          <Label variant="p">Processing</Label>
        </Index>
      </IndexWrapper>
    </Container>
  );
};

export default StepsSmall;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ItemsWrapper = styled.div`
  overflow: hidden;
  display: flex;
  height: 10px;
  border-radius: 9px;
  background-color: #e0e0e0;
  z-index: 2;
`;

const Item = styled.div<{ $status: IStepItem['status']; $length: number }>`
  width: ${({ $length }) => `calc(100% / ${$length})`};
  background-color: ${({ $status }) =>
    $status === 'finish'
      ? '#00de90'
      : $status === 'process'
      ? '#52c1ff'
      : '#e0e0e0'};
`;

const IndexWrapper = styled.div`
  display: flex;
  gap: 30px;
`;

const Index = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Label = styled(Typography)`
  font-size: 12px;
  line-height: 25px;
  color: #949494;
`;
