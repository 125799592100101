import { useRoot } from '@/RootProvider';
import Modal from '@/newComponents/Modal';
import * as yup from 'yup';

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useAPI from '@/hooks/api';
import LoadingPlaceholder from '@shared/web/components/LoadingPlaceholder';
import { Apis, Hooks, Utils } from '@shared/front';
import S3presigned from '@shared/web/components/S3presigned';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Button from '@/newComponents/Button';
import Typography from '@/newComponents/Typography';
import { devices } from '@/utils/theme';
import { request } from '@shared/front/utils';
import { useSelector } from 'react-redux';
import {
  SaveButtonContainer,
  StyledButton,
  StyledLabel,
  Title,
} from '../index.styles';

const validationSchema = yup.object().shape({
  documentType: yup.string().required('Document type is required'),
  identity1: yup
    .array()
    .of(yup.string())
    .min(1, 'At least one front image is required'),
  identity2: yup
    .array()
    .of(yup.string())
    .min(1, 'At least one back image is required'),
});

const DocTypeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: center;
  margin-top: 30px;
`;

const DocumentType = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  line-height: 25px;

  @media ${devices.laptop} {
    font-size: 14px;
    line-height: 30px;
  }
`;

const ImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-top: 30px;
`;

const ImageIdentifier = styled(Typography)`
  font-size: 12px;
  line-height: 25px;
  color: #949494;
`;

const StyledImage = styled.img`
  max-width: 250px;

  @media ${devices.tablet} {
    max-width: 410px;
  }
`;

const NoDocInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`;

const FormikSelectField = styled(Field)`
  display: block;
  width: 100%;
  height: 40px;
  margin: 6px 0px;
  outline: none;
  border: 1px solid ${({ theme }) => theme.colors.border};
  background-color: ${({ theme }) => theme.colors.lightBG};
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }

  &.disabled-hidden-option {
    color: transparent;
    filter: blur(2px);
    border: 1px solid red;
  }
`;

const Error = styled(ErrorMessage)`
  color: red;
`;

type Props = {};
const BankAccount = ({}: Props) => {
  const { checkAuth } = useRoot();

  const [documentLoading, setDocumentLoading] = useState(false);
  const documents: { type: string; url: string }[] = useSelector(
    (state: any) => state?.accountDocuments,
  );
  const documentTypes = Utils.Constants.DocumentType();
  const [makeRequest, { loading: updatingDocument }] = Hooks.useAPI();
  const [initialState, setInitialState] = useState({
    documentType: 'Citizenship',
    identity1: [],
    identity2: [],
  });

  const [handleDeleteDocuments, { loading: deleteLoading }] = useAPI();
  const [openDocumentModal, setOpenDocumentModal] = useState(false);
  const [openDeleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    getUserDocuments();
  }, []);

  const handleSubmitForm = async (values: any) => {
    const data = {
      identity1: {
        type: values?.documentType,
        url: values?.identity1[0],
        documentType: 1,
      },
      identity2: {
        type: values?.documentType,
        url: values?.identity2[0],
        documentType: 2,
      },
    };
    await makeRequest(Apis.Account.updateDocument(data));
    await getUserDocuments();
    await checkAuth();
    setOpenDocumentModal(false);
  };

  const handleDeleteDocument = (id: any) => {
    handleDeleteDocuments({
      method: 'delete',
      url: `/v1/auth/deleteDocument/${id}`,
    });
  };

  const getUserDocuments = async () => {
    setDocumentLoading(true);
    await request(Apis.Account.getDocuments());
    setDocumentLoading(false);
  };

  return (
    <div style={{ marginTop: '20px' }}>
      <Title variant="h1">Documents</Title>
      <Typography
        variant="p"
        style={{ display: 'block', fontWeight: '500', marginTop: 20 }}
      >
        Identity document
      </Typography>
      <Typography variant="p" style={{ display: 'block', marginBottom: 10 }}>
        Government issued ID (Citizenship, Passport, Driving License)
      </Typography>
      <DocTypeContainer>
        <DocumentType variant="p">
          <span style={{ fontWeight: 500, marginRight: '20px' }}>
            Document Type
          </span>
          :{' '}
          {documents && documents.length > 0
            ? documents[0]?.type
            : 'No Document'}
        </DocumentType>
        <StyledButton
          style={{ minWidth: 'unset', padding: '5px 24px' }}
          radius="round"
          onClick={() => setOpenDocumentModal(true)}
        >
          Add
        </StyledButton>

        {/* <Button >Add</Button> */}
      </DocTypeContainer>
      {!!documentLoading && <LoadingPlaceholder />}
      {!documentLoading && documents && documents.length > 0 ? (
        <ImagesContainer>
          <ImageIdentifier variant="p">Front Image</ImageIdentifier>
          <StyledImage src={documents[0]?.url} />
          <ImageIdentifier variant="p">Back Image</ImageIdentifier>
          <StyledImage src={documents[1]?.url} />
        </ImagesContainer>
      ) : (
        <NoDocInfoContainer>
          <DocumentType variant="p">Add Your Valid Document....</DocumentType>
        </NoDocInfoContainer>
      )}

      <Modal
        open={openDocumentModal}
        onClose={() => setOpenDocumentModal(false)}
      >
        <Formik
          initialValues={initialState}
          onSubmit={values => {
            alert('aslkdnaksjdkjn');
            handleSubmitForm(values);
          }}
          enableReinitialize
          validationSchema={validationSchema}
          // validateOnBlur
          // validateOnChange
        >
          {({ values, errors, touched }) => {
            return (
              <Form>
                <StyledLabel>Document Type</StyledLabel>
                <FormikSelectField component={'select'} name="documentType">
                  {documentTypes?.map(documentType => (
                    <option
                      label={documentType.label}
                      value={documentType.value}
                    >
                      {documentType.value}
                    </option>
                  ))}
                </FormikSelectField>

                {/* {errors.documentType && touched.documentType && (
                    <div>{errors.documentType}</div>
                  )} */}
                <Error name="documentType" component="span" />

                <div>
                  <StyledLabel>Front Image</StyledLabel>
                  <Field
                    component={S3presigned}
                    name="identity1"
                    isMultiple={false}
                  />
                  <Error name="identity1" component="span" />
                </div>
                <div style={{ marginTop: '40px' }}>
                  <StyledLabel>Back Image</StyledLabel>
                  <Field
                    component={S3presigned}
                    name="identity2"
                    isMultiple={false}
                  />
                  <Error name="identity2" component="span" />
                </div>

                <SaveButtonContainer>
                  <Button
                    radius="round"
                    isDisabled={updatingDocument}
                    isLoading={updatingDocument}
                    onClick={() => {
                      handleSubmitForm(values);
                    }}
                  >
                    Save
                  </Button>
                </SaveButtonContainer>
              </Form>
            );
          }}
        </Formik>
      </Modal>

      <Modal
        title="Are you sure you want to delete?"
        open={openDeleteModal}
        // onOk={handleDeleteRequest}
        // confirmLoading={deleteLoading}
        onClose={() => setDeleteModal(false)}
      >
        <p>
          You card will be deleted, if you don't add card then you can't use
          some features.
        </p>
      </Modal>
    </div>
  );
};

export default BankAccount;
