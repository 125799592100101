import React, { useState, useEffect } from 'react';
import { Utils, Store, Apis } from '@shared/front';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import PropertySkeleton from '../Properties/PropertySkeleton';
import BookingLargeCard from '@/newComponents/BookingLargeCard';
const TabWrapper = styled.div`
  display: flex;
  column-gap: 30px;
`;
const TabItem = styled.button<any>`
  padding: 15px 0px;
  background: none;
  border: 0px;
  color: ${({ active }) => (active ? '#000' : '#949494')};
  cursor: pointer;
`;
const BookingItems = styled.div<any>`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
`;
const tabs = [
  {
    label: 'Active',
    key: 'current',
  },
  {
    label: 'Completed',
    key: 'historic',
  },
];
const InitialParams = {
  page: 0,
  limit: 6,
  status: '',
};
const TenantBookingList = () => {
  const bookings: any[] = useSelector(Store.Select.bookings);
  const [active, setActive] = useState('current');
  const [params, setParams] = useState(InitialParams);
  const [loading, setLoading] = useState(false);
  const getBookings = async (params: any, action?: 'set' | 'update') => {
    setLoading(true);
    try {
      setParams(params);
      await Utils.request(Apis.Booking.getBookings(params, action));
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const handleClickTab = async (value: string) => {
    try {
      setActive(value);

      await getBookings(
        { ...InitialParams, status: value === 'current' ? null : 'Completed' },
        'set',
      );
    } catch (err) {}
  };
  useEffect(() => {
    getBookings(InitialParams, 'set');
  }, []);
  return (
    <>
      <TabWrapper>
        {tabs?.map((item: any) => (
          <TabItem
            active={item?.key === active}
            key={item?.key}
            onClick={() => {
              handleClickTab(item.key);
            }}
          >
            {item.label}
          </TabItem>
        ))}
      </TabWrapper>
      <InfiniteScroll dataLength={bookings.length}>
        <BookingItems>
          {!!loading ? (
            <>
              <PropertySkeleton />
            </>
          ) : (
            <>
              {bookings?.map((booking: any) => (
                <BookingLargeCard
                  item={booking}
                  active={active}
                  key={booking?.id}
                />
              ))}
            </>
          )}
        </BookingItems>
      </InfiniteScroll>
    </>
  );
};

export default TenantBookingList;
