import { useRoot } from '@/RootProvider';
import Constants from '@/constants';
import useAPI from '@/hooks/api';
import Typography from '@/newComponents/Typography';
import { devices } from '@/utils/theme';
import { Apis, Store, Utils } from '@shared/front';
import SideProfile from '@shared/web/components/SideProfile';
import Steps from '@shared/web/components/Steps';
import Modal from '@/newComponents/Modal';
import numeral from 'numeral';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import NearByProperties from './NearByProperties';
import PropertyInfo from './PropertyInfo';
import ReferencePropertyImage from './ReferenceImage';
import Rooms from './Rooms';
import Amenities from './amenities';

import MapView from './map';
import {
  ButtonsWrapper,
  Content,
  LeftBox,
  ModalMainText,
  RightBox,
  SectionDescription,
  SectionTitle,
  SectionWrapper,
  StickyBox,
  StyledButton,
} from './style';
import Button from '@/newComponents/Button';
import GoBack from '@/newComponents/GoBack';
import ImageSwiper from '@/newComponents/ImageSwiper';
import NoData from '@/newComponents/NoData';
import Policy from './Policy';
import MyPropertyReport from './Report';
import Rules from './Rules';
import SimilarProperties from './Similar';
import { useMediaQuery } from 'react-responsive';
import Divider from '@/newComponents/Divider';

const RightInnerBox = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;

  width: 100%;

  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 2px;
  padding: 20px;
  @media ${devices.laptop} {
  }

  & .rent-wrapper {
    & .per-month {
      font-size: 14px;
      color: #808080;
    }
  }
`;

const PropertyView = () => {
  const isDesktop = useMediaQuery({ query: `(min-width:992px)` });

  const { auth: user } = useRoot();
  console.log(user, 'user side profile');
  const navigate = useNavigate();
  const { id: propertyId }: any = useParams();
  const [showDescMore, setShowDescMore] = useState<boolean>(false);
  const [isBookingModalVisible, setBookingModalVisibility] = useState(false);
  const {
    bounceRate,
    contract,
    property,
    isBookedByMe,
    myBooking,
    similarProperties,
  }: any = useSelector(Store.Select.property);

  const [makeBookingRequest, { loading: bookingLoading }] = useAPI();
  const ownerId = property?.landlordId;
  const propertyRules = property?.propertyRules;
  const propertyAmentiesData = property?.propertyAmenities;

  const paramsRole = property?.landlord
    ? property.landlord.role
    : property?.tenant
    ? property.tenant.role
    : property?.service
    ? property?.service.role
    : null;
  const getPropertyDetails = async () => {
    try {
      await Utils.request(
        Apis.Properties.getPropertyById(propertyId, user?.role),
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleBooking = async () => {
    if (!user?.hasValidPaymentMethod) {
      setBookingModalVisibility(false);
      return toast.error('Please add payment method');
    } else if (!user?.stripeBankToken) {
      setBookingModalVisibility(false);
      return toast.error('Please add bank details');
    } else {
      try {
        await makeBookingRequest({
          method: 'post',
          url: `/v1/bookings/book`,
          data: {
            tenantId: user?.id,
            landlordId: property?.landlordId,
            propertyId,
            price: property?.price,
            status: 'Processing',
          },
        });
        setBookingModalVisibility(false);
        toast(
          'Your booking has been placed successfully. Landlord will contact you shortly.',
        );

        navigate('/bookings');
      } catch (error) {
        if (error && typeof error === 'string') {
          toast(error);
          setBookingModalVisibility(false);
        }
      }
    }
  };

  useEffect(() => {
    if (!!propertyId) {
      getPropertyDetails();
    }
  }, [propertyId]);
  return (
    <>
      <Modal
        open={isBookingModalVisible}
        title="Booking Property"
        onClose={() => setBookingModalVisibility(false)}
      >
        <ModalMainText variant="p">
          You are about to book this property.
        </ModalMainText>
        <Steps steps={Constants.bookingSteps} activeStep={0} />
        <ButtonsWrapper>
          <StyledButton
            variant="gray"
            radius="round"
            onClick={() => setBookingModalVisibility(false)}
          >
            Cancel
          </StyledButton>
          <StyledButton
            radius="round"
            onClick={handleBooking}
            isLoading={bookingLoading}
            isDisabled={bookingLoading}
          >
            Book
          </StyledButton>
        </ButtonsWrapper>
      </Modal>

      <GoBack />

      {property?.images?.length > 0 ? (
        <ImageSwiper
          images={property?.images?.map((image: any) => image?.URL)}
          imageStyle={{ height: 300 }}
        />
      ) : (
        <NoData title={`No Image Found`} size="200" />
      )}

      <Content>
        <LeftBox>
          <PropertyInfo
            id={property?.id}
            area={property?.area}
            bathrooms={property?.bathrooms}
            bedrooms={property?.bedrooms}
            propertyType={property?.propertyType}
            furnishedType={property?.furnishedType}
            rentalMarket={property?.rentalMarket}
            address={property?.address}
            berNo={property?.berNo}
          />
          <SectionWrapper>
            <SectionTitle>{property?.desc ? 'Description' : ''}</SectionTitle>
            <SectionDescription style={{ textOverflow: 'ellipsis' }}>
              {property?.desc?.length > 600 && !showDescMore
                ? `${property?.desc?.slice(0, 600)}...`
                : property?.desc}
            </SectionDescription>
            {property?.desc?.length > 600 && (
              <a
                onClick={() => {
                  setShowDescMore(!showDescMore);
                }}
              >
                <Typography
                  variant="h1"
                  color="blue"
                  style={{ marginTop: '10px', cursor: 'grab' }}
                >
                  {!showDescMore ? 'Show More' : 'Show Less'}
                </Typography>
              </a>
            )}
          </SectionWrapper>
          <Amenities propertyAmenities={propertyAmentiesData} />
          <Rooms propertyId={propertyId} landlordId={property?.landlord?.id} />
          <Rules
            propertyId={propertyId}
            landlordId={property?.landlord?.id}
            propertyRules={propertyRules}
          />
          <Policy propertyId={propertyId} landlordId={property?.landlord?.id} />
          {user?.id === ownerId && (
            <ReferencePropertyImage
              propertyId={propertyId}
              landlordId={property?.landlord?.id}
            />
          )}

          <MapView
            coordinates={[
              {
                id: 1,
                longitude: property?.longitude,
                latitude: property?.latitude,
              },
            ]}
          />
          <NearByProperties nearBy={property?.nearBy?.results} />
        </LeftBox>
        <RightBox style={{ position: 'relative', right: 0 }}>
          <StickyBox>
            {!!user && (
              <SideProfile
                profileData={property?.landlord}
                role={paramsRole}
                isProfileViewable
              />
            )}

            <RightInnerBox>
              <SectionDescription
                style={{
                  fontSize: '1.3rem',
                  fontWeight: 500,
                  margin: '6px 0px',
                }}
              >
                Rent
              </SectionDescription>
              <SectionDescription>
                €{numeral(property?.price).format('0,0.00')}{' '}
                <span className="per-month">/month</span>
              </SectionDescription>
              <>
                {!!user && (
                  <>
                    {user?.role === 'tenant' &&
                      property?.status == 'Active' &&
                      (!isBookedByMe ? (
                        <Button onClick={() => setBookingModalVisibility(true)}>
                          Book Now
                        </Button>
                      ) : (
                        <Button
                          onClick={() => navigate(`/bookings/${myBooking?.id}`)}
                        >
                          View Booking
                        </Button>
                      ))}

                    {user?.role === 'landlord' && user?.id === ownerId && (
                      <>
                        {isDesktop && <Divider space="12px 10px" />}
                        <MyPropertyReport
                          propertyId={propertyId}
                          ownerId={ownerId}
                        />
                      </>
                    )}
                  </>
                )}
              </>
            </RightInnerBox>
          </StickyBox>
        </RightBox>
      </Content>
      {!!similarProperties?.length && (
        <SimilarProperties properties={similarProperties} />
      )}
    </>
  );
};

export default PropertyView;
