import { useDetectOutsideClick } from '@/hooks/useDetectOutsideClick';
import { devices } from '@/utils/theme';
import { Store } from '@shared/front';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import SearchFilterInput from '../SearchFilterInput';

interface IHeaderSearchFilterProps {}

const Container = styled.div`
  width: 100%;

  @media ${devices.mobileSM} {
    width: 80%;
  }

  @media ${devices.tablet} {
    width: 70%;
  }
`;

const SmallScreenSearchWrapper = styled.div<{ $isVisible: boolean }>`
  position: fixed;
  top: 10px;
  left: 60px;
  right: 10px;
  z-index: 99;
  transition: width 0.3s;
  // display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  width: ${({ $isVisible }) => ($isVisible ? 'calc(100vw - 70px)' : '0px')};
  overflow: hidden;
`;

const HeaderSearchFilter = ({}: IHeaderSearchFilterProps) => {
  const [filterText, setFilterText] = useState<string>('');
  const pagination = useSelector<any>(Store.Select.propertyPagination);
  const dispatch = useDispatch();
  const searchInputRef = useRef(null);
  const [enableSearch, setEnableSearch] = useDetectOutsideClick(
    searchInputRef,
    false,
  );

  const isLargeMobile = useMediaQuery({ query: '(min-width: 575px)' });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event?.target;

    setFilterText(value);
  };

  const delayedSearch = useCallback(
    debounce(text => handleSearch(text), 500),
    [],
  );

  useEffect(() => {
    delayedSearch(filterText);
  }, [filterText]);

  const handleSearch = (searchText: string) => {
    dispatch(
      Store.Actions['update']('propertyPagination', {
        ...pagination,
        page: 0,
        search: searchText,
      }),
    );
  };

  const renderFilterInput = () => (
    <SearchFilterInput
      inputType="search"
      value={filterText}
      onChange={handleInputChange}
      placeholder="Search Address  "
      name="propertySearch"
      onCancel={() => {
        handleSearch(filterText);
      }}
    />
  );

  return <Container>{renderFilterInput()}</Container>;
};

export default HeaderSearchFilter;

const SearchButton = styled.button`
  border: none;
  outline: none;
  background-color: #2061f8;
  border-radius: 50%;
  height: 37px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #1c52d1;
  }
`;
