import Typography from '@/newComponents/Typography';
import { devices } from '@/utils/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DisplayFlexColumn } from '@shared/web/common/style';
import styled, { css } from 'styled-components';

export const MainWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px 20px;
  align-items: center;
  justify-items: center;
  margin: 20px 0 40px;

  @media ${devices.mobileXS} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${devices.laptop} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export const CalenderWrapper = styled.div`
  margin-bottom: 60px;
  background: #f0f0f0;
  padding: 20px 10px 10px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 100%;

  @media ${devices.mobileXS} {
    padding: 20px 10px;
  }

  @media ${devices.mobileSM} {
    padding: 23px;
  }
`;

export const FeatureWrapper = styled.div`
  margin: 32px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: center;
  justify-items: center;

  @media ${devices.mobileXS} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media ${devices.mobileSM} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media ${devices.tablet} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media ${devices.laptop} {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
`;

export const Feature = styled.div`
  ${DisplayFlexColumn}
  align-items: center;
  background: #e0e0e0;
  border-radius: 4px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  max-height: 120px;
  padding: 10px;
  position: relative;

  @media ${devices.tablet} {
    padding: 20px 10px;
  }
`;

export const Badge = styled.div`
  height: 25px;
  width: 25px;
  background-color: red;
  color: white;
  border-radius: 15px;
  position: absolute;
  top: -5px;
  right: -5px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const Box = styled.div<{
  bgColor?: string;
  size?: string;
  hoverable?: boolean;
}>`
  background-color: ${({ theme, bgColor }) =>
    bgColor ? theme.colors.materialColors[bgColor].bg : null};
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  position: relative;
  border-radius: 50%;
  cursor: ${({ hoverable }) => (hoverable ? 'pointer' : null)};
  ${({ hoverable }) =>
    hoverable &&
    css`
      &:hover {
        background-color: #6060bb;
        transition-duration: 1s;
        color: ${({ theme }) => theme.colors.white};
      }
    `};
  @media ${devices.tablet} {
    padding: 20px;
  }
`;

export const CardsWrapperStyled = styled(Box)<{ textColor?: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 120px;
  flex-shrink: 0;
  border-radius: 5px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 50px 0px;
  text-transform: uppercase;
`;
export const Title = styled(Typography)<{ textColor: string }>`
  color: ${({ theme, textColor }) =>
    textColor ? theme.colors?.materialColors[textColor]?.text : null};
  text-align: center;
  font-size: 14px;

  @media ${devices.laptop} {
    font-size: 16px;
  }
`;
export const Value = styled.p<{ textColor: string }>`
  color: ${({ theme, textColor }) =>
    textColor ? theme.colors?.materialColors[textColor]?.text : null};
  font-size: 32px;
  font-weight: 600;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  display: flex;
  justify-content: center;
`;

export const Icon = styled(FontAwesomeIcon)<{
  color: string;
  position?: string;
}>`
  color: ${({ theme, color }) =>
    color ? theme?.colors?.materialColors[color]?.text : null};
  position: ${({ position }) =>
    position === 'noposition' ? null : 'absolute'};
  font-size: 38px;
  top: -20px;
  left: 20px;
  @media ${devices.tablet} {
    top: -20px;
    left: 20px;
  }
`;
