import { useRoot } from '@/RootProvider';
import useAPI from '@/hooks/api';
import ButtonNew from '@/newComponents/Button';
import GoBack from '@/newComponents/GoBack';
import ImageSwiper from '@/newComponents/ImageSwiper';
import TypographyNew from '@/newComponents/Typography';
import { LoggedOrNot } from '@/utils/checkAuth';
import request from '@shared/front/utils/request';
import { devices } from '@/utils/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Apis, Store, Utils } from '@shared/front';
import store from '@shared/front/store';
import utils from '@shared/front/utils';
import Error from '@shared/web/components/Error';
import LoadingPlaceholder from '@shared/web/components/LoadingPlaceholder';
import MaskInputField from '@shared/web/components/MaskInputField';
import SideProfile from '@shared/web/components/SideProfile';
import TextField from '@shared/web/components/TextField';
import { Field, Formik } from 'formik';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import { capitalize } from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import * as yup from 'yup';
import Contracts from './Contracts';
import JobViewSkeleton from './JobViewSkeleton';
import Proposals from './Proposals';
import Divider from '@/newComponents/Divider';
import Modal from '@/newComponents/Modal';
import { Row } from '@shared/web/components/common';

const Content = styled.div`
  max-width: 1200px;
  margin: 0px auto 0;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media ${devices.laptopXL} {
    gap: 60px;
    padding-bottom: 100px;
  }
`;

const JobDetails = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;

  padding-bottom: 50px;
  border-bottom: 1px solid #f0f0f0;

  @media ${devices.tablet} {
    flex-direction: row;
    justify-content: space-between;
  }

  @media ${devices.laptopXL} {
    gap: 20px;
  }
`;

const DescContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 24px;

  @media ${devices.tablet} {
    flex-direction: row;
    align-items: center;
  }
`;

const JobTitle = styled(TypographyNew)`
  font-size: 16px;
  font-weight: 500;
  color: #292929;
  margin-right: 20px;

  @media ${devices.laptopXL} {
    font-size: 32px;
  }
`;

const SectionTitle = styled(TypographyNew)`
  font-size: 14px;
  line-height: 30px;
  margin-bottom: 10px;
  font-weight: 400;

  @media ${devices.laptopXL} {
    font-size: 16px;
    line-height: normal;
    font-weight: 500;
  }
`;

const JobStatusTag = styled(ButtonNew)`
  font-size: 10px;

  @media ${devices.laptopXL} {
    font-size: 12px;
  }
`;

const DataRowWrapperStyled = styled.div`
  & .label,
  & .value {
    font-size: 12px;
    font-weight: 400;
    line-height: 25px;
    color: #333333;

    @media ${devices.laptopXL} {
      font-size: 14px;
      line-height: 30px;
    }
  }
  & .label {
    margin-right: 15px;
  }
  & .value {
    color: #8a8a8a;
  }
`;

const DataLabel = styled(TypographyNew)`
  font-size: 12px;
  font-weight: 400;
  line-height: 25px;
  color: #333333;

  @media ${devices.laptopXL} {
    font-size: 14px;
    line-height: 30px;
  }
`;

const DataValue = styled(DataLabel)`
  color: #8a8a8a;
`;

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 16px;
`;

const AsideContainer = styled.div`
  max-width: 300px;
  @media ${devices.laptopXL} {
    width: 30%;
  }
`;

const AsideInnerBox = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  padding: 0px 20px;
`;

const CloseJobButton = styled(ButtonNew)`
  border: 1px solid #ee9a9a;
  background-color: #fff;
  color: #d32f2f;
  transition: 0.3s;
  font-size: 14px;
  width: 100px;
  &:hover {
    color: #fff;
    background-color: #d32f2f;
  }
`;
type Props = {
  google: any;
};

const PropertyView = ({ google }: Props) => {
  const { id: jobId } = useParams();
  const { auth } = useRoot();
  const navigate = useNavigate();
  const [isMapReady, setMapReady] = useState(false);
  const [jobData, setJobData] = useState<any>({});
  const [getPropertyRequest, { loading, data }] = useAPI();
  const [isBookingModalVisible, setBookingModalVisibility] = useState(false);
  const [isCloseModalVisible, setCloseModalVisibility] = useState(false);
  const [makeBookingRequest, { loading: bookingLoading }] = useAPI();
  const [makeCloseRequest, { loading: closeLoading }] = useAPI();

  const dispatch = useDispatch();

  const myBookmarksJobs = useSelector(state => state.myBookmarks?.serviceIds);
  console.log(myBookmarksJobs, 'mybookmark jobs');
  const createBookMarked = async () => {
    try {
      await Utils.request(Apis.Bookmark.createBookmark({ serviceId: jobId }));
      dispatch(
        store.Actions.update('myBookmarks', {
          serviceIds: [...myBookmarksJobs, parseInt(jobId, 10)],
        }),
      );
    } catch (err) {
      console.log(err);
    }
  };

  const deleteBookMarked = async () => {
    try {
      await utils.request(Apis.Bookmark.deleteBookmark({ serviceId: jobId }));
      dispatch(
        Store.Actions.update('myBookmarks', {
          serviceIds: [
            ...myBookmarksJobs.filter(x => x !== parseInt(jobId, 10)),
          ],
        }),
      );
    } catch (err) {
      console.log(err);
    }
  };

  const toggleMarked = status => {
    if (status) {
      deleteBookMarked();
    } else {
      createBookMarked();
    }
  };

  const getProperty = async () => {
    let data;

    if (!!auth) {
      data = await getPropertyRequest({
        method: 'get',
        url: `/v1/services/${jobId}`,
      });
    } else {
      data = await getPropertyRequest({
        method: 'get',
        url: `/v1/services/${jobId}/guests`,
      });
    }

    if (data?.bounceRate) {
      const { data: filterData } = data;
      setJobData(filterData);
    } else {
      setJobData(data);
    }

    if (auth?.id === jobData?.landlordId) {
      request({
        method: 'post',
        url: `/v1/services/${jobId}`,
        data: {
          lastViewed: moment().toISOString(),
        },
      });
    }
  };
  const isProposalSubmitted = jobData?.proposals?.find(
    item => item.providerId === auth?.id,
  );

  useEffect(() => {
    if (jobId) {
      getProperty();
    }
  }, []);

  const handleBooking = async values => {
    await makeBookingRequest({
      method: 'post',
      url: `/v1/services/${jobData?.id}/proposals`,
      data: values,
    });
    getProperty();
    setBookingModalVisibility(false);
    toast(
      'Your proposal has been submitted successfully. Landlord will contact you shortly.',
    );
  };

  const handleClose = async () => {
    await makeCloseRequest({
      method: 'post',
      url: `/v1/services/${jobData?.id}/close`,
    });
    getProperty();
    toast('Job has been close successfully.');
  };

  const getLayoutNumber = length => {
    if (length === 1) {
      return [1];
    }
    if (length > 4) {
      return [1, 4];
    } else {
      return [1, length - 1];
    }
  };
  const isBookmark = myBookmarksJobs?.includes(parseInt(jobId, 10));

  return (
    <div>
      <GoBack />

      <Modal
        title="Submit proposal"
        open={isBookingModalVisible}
        onClose={() => setBookingModalVisibility(false)}
      >
        <Formik
          initialValues={{
            price: jobData?.price,
          }}
          onSubmit={(values, { setSubmitting }) => {
            try {
              if (auth.hasValidPaymentMethod) {
                handleBooking(values);
              } else {
                toast.error('Please add the payment methods');
              }
            } catch (error: any) {
              toast.error(error);
            }
            setSubmitting(false);
          }}
          validationSchema={yup.object().shape({
            price: yup.number().required('Please enter price'),
            desc: yup.string().required('Please enter proposal'),
          })}
        >
          {({
            isSubmitting,
            errors,
            touched,
            values,
            setFieldValue,
            handleSubmit,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Field
                  component={TextField}
                  label="Your proposal"
                  name="desc"
                />

                <Field
                  component={MaskInputField}
                  label="Proposal rate"
                  name="price"
                  decimalSeparator="."
                  displayType="input"
                  type="text"
                  thousandSeparator={true}
                  prefix={'€'}
                  setFieldValue={setFieldValue}
                />
                {/* <Typography.Text
                  style={{
                    display: 'block',
                    marginBottom: 4,
                    marginTop: 12,
                  }}
                >
                  Your estimated work duration
                </Typography.Text>
                <RangePicker /> */}
                <Content>
                  <Error message="" isFormError />
                  <Row
                    justify={'end'}
                    style={{
                      width: '100%',
                      margin: 'auto',
                    }}
                  >
                    {/* <Button
                      htmlType="submit"
                      block={true}
                      backgroundColor="primary"
                      disabled={bookingLoading || isSubmitting}
                      loading={bookingLoading || isSubmitting}
                    >
                      Submit
                    </Button> */}
                    <ButtonNew style={{ width: '100%', marginTop: '10px' }}>
                      Submit
                    </ButtonNew>
                  </Row>
                </Content>
              </form>
            );
          }}
        </Formik>
      </Modal>
      {!!loading && <JobViewSkeleton />}

      <>
        <Content>
          {/* Job details with side profile box */}
          <JobDetails>
            <DescContainer>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <TitleContainer>
                  <JobTitle variant="h1">{jobData?.title}</JobTitle>
                  <JobStatusTag
                    variant="tag"
                    radius="round"
                    style={{ width: 'fit-content' }}
                  >
                    {jobData?.status}
                  </JobStatusTag>
                  {!!auth && auth?.role === 'service' && (
                    <FontAwesomeIcon
                      icon={'heart'}
                      stroke={isBookmark ? 'red' : 'gray'}
                      strokeWidth={50}
                      color={isBookmark ? 'red' : 'white'}
                      style={{
                        width: 24,
                        height: 24,
                        cursor: 'pointer',
                        marginLeft: '10px',
                        textAlign: 'center',
                      }}
                      onClick={() => toggleMarked(isBookmark)}
                    />
                  )}
                </TitleContainer>
              </div>
              <DataRowWrapperStyled>
                <TypographyNew variant="span" className="label">
                  Posted at
                </TypographyNew>
                <TypographyNew variant="span" className="value">
                  {jobData?.createdAt &&
                    moment.utc(jobData.createdAt).local().fromNow()}
                </TypographyNew>
              </DataRowWrapperStyled>
              <DataRowWrapperStyled>
                <TypographyNew variant="span" className="label">
                  {`${capitalize(jobData?.priceType)} rate `}
                </TypographyNew>
                <TypographyNew variant="span" className="value">
                  &euro;{numeral(jobData?.price).format('0,0')}
                </TypographyNew>
              </DataRowWrapperStyled>
              <DataRowWrapperStyled>
                <TypographyNew variant="span" className="label">
                  Proposals{' '}
                </TypographyNew>
                <TypographyNew variant="span" className="value">
                  {jobData?.proposals?.length}
                </TypographyNew>
              </DataRowWrapperStyled>
              <DataRowWrapperStyled>
                <TypographyNew variant="span" className="label">
                  New Proposals{' '}
                </TypographyNew>
                <TypographyNew variant="span" className="value">
                  {jobData?.proposals?.filter(item => !item.isRead)?.length}
                </TypographyNew>
              </DataRowWrapperStyled>
              <DataRowWrapperStyled>
                <TypographyNew variant="span" className="label">
                  Hires{' '}
                </TypographyNew>
                <TypographyNew variant="span" className="value">
                  {jobData?.contracts?.length}
                </TypographyNew>
              </DataRowWrapperStyled>
              <DataRowWrapperStyled>
                <TypographyNew variant="span" className="label">
                  Request For Callout
                </TypographyNew>
                <TypographyNew variant="span" className="value">
                  {jobData?.isCallout ? 'Yes' : 'No'}
                </TypographyNew>
              </DataRowWrapperStyled>
              <DataRowWrapperStyled>
                <TypographyNew variant="span" className="label">
                  Description{' '}
                </TypographyNew>
                <TypographyNew variant="p" className="value desc">
                  {jobData?.desc}
                </TypographyNew>
              </DataRowWrapperStyled>
              <TagsContainer>
                {Array.isArray(jobData?.categories) &&
                  jobData?.categories?.map(category => (
                    <ButtonNew
                      variant="tag"
                      radius="round"
                      bgColor="#9747ff5e"
                      style={{ color: '#424242', fontSize: '12px' }}
                    >
                      {category}
                    </ButtonNew>
                  ))}
              </TagsContainer>
            </DescContainer>
            <AsideContainer>
              {auth?.role !== 'landlord' && (
                <SideProfile
                  profileData={jobData?.landlord}
                  isProfileViewable
                  role={jobData?.landlord?.role}
                />
              )}
              <AsideInnerBox>
                {loading ? (
                  <LoadingPlaceholder />
                ) : (
                  <>
                    {auth?.role === 'service' &&
                      (!isProposalSubmitted ? (
                        <ButtonNew
                          style={{ width: '100%' }}
                          onClick={() => setBookingModalVisibility(true)}
                        >
                          Submit Proposal
                        </ButtonNew>
                      ) : (
                        <>
                          <TypographyNew variant="p">
                            Proposal Submitted
                          </TypographyNew>
                          <ButtonNew
                            style={{ width: '100%' }}
                            onClick={() =>
                              navigate(`/proposals/${isProposalSubmitted.id}`)
                            }
                          >
                            View Proposal
                          </ButtonNew>
                        </>
                      ))}
                  </>
                )}

                {jobData?.landlordId === auth?.id &&
                  jobData?.status === 'Active' && (
                    <CloseJobButton
                      isLoading={closeLoading}
                      onClick={handleClose}
                    >
                      Close Job
                    </CloseJobButton>
                  )}
              </AsideInnerBox>
              {LoggedOrNot()}
            </AsideContainer>
          </JobDetails>

          {/* Contract details section */}
          {auth?.id == jobData?.landlordId && !!jobData?.contracts?.length && (
            <section>
              <Contracts contracts={jobData.contracts} />
            </section>
          )}

          {/* Proposal details section */}
          {auth?.id == jobData?.landlordId && !!jobData?.proposals?.length && (
            <section>
              <Proposals
                proposals={jobData.proposals}
                getProperty={getProperty}
                serviceId={jobData.id}
                isCallout={jobData?.isCallout}
                hasAlreadyHiredOne={
                  Array.isArray(jobData?.contracts) &&
                  jobData?.contracts.length > 0
                }
              />
            </section>
          )}

          {/* Attachments section */}
          {jobData?.attachments?.length > 0 && (
            <section>
              <SectionTitle variant="h5">Attachments</SectionTitle>
              <ImageSwiper
                images={jobData?.attachments?.map(item => item?.URL)}
                containerHeight="300px"
              />
            </section>
          )}

          {/* Map */}
          {jobData?.property?.address && (
            <section>
              <SectionTitle variant="h5">Location</SectionTitle>
              <DataValue variant="p">{jobData?.property?.address}</DataValue>
              {/*@ts-ignore */}
              <Map
                google={google}
                // @ts-ignore
                mapTypeControl={false}
                zoomControl={false}
                fullscreenControl={false}
                streetViewControl={false}
                draggable={false}
                onReady={(props, map) => {
                  setMapReady(true);
                }}
                containerStyle={{
                  position: 'relative',
                }}
                center={{
                  lat: jobData?.property?.latitude,
                  lng: jobData?.property?.longitude,
                }}
                style={{
                  height: 300,
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right: 0,
                  marginTop: 16,
                }}
                zoom={10}
              >
                {isMapReady && (
                  <Marker
                    // @ts-ignore
                    name={jobData?.property?.address}
                    position={{
                      lat: jobData?.property?.latitude,
                      lng: jobData?.property?.longitude,
                    }}
                  />
                )}
              </Map>
            </section>
          )}
        </Content>
      </>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.GOOGLE_MAPS_API || '',
})(PropertyView);
