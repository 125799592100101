import LoadingPlaceholder from '@shared/web/components/LoadingPlaceholder';
import useAPI from '@/hooks/api';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import BasicInfo from './BasicInfo';
import Categories from './Categories';
import Photos from './Photos';
import Pricing from './Pricing';
import { toast } from 'react-toastify';
import request from '@shared/front/utils/request';
import { useSelector } from 'react-redux';
import { Store } from '@shared/front';
import Stepper from '@/newComponents/Stepper';
const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 600px;
  padding-top: 60px;
`;

const AddService = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { id: jobId } = useParams();
  const job = useSelector(Store.Select.job);
  const [getServiceRequest] = useAPI();
  const [step, setStep] = useState(0);
  const [validatedTab, setValidatedTab] = useState(0);
  const [initialValues, setInitialValues] = useState({
    categories: [],
    attachments: [null],
    address: '',
    id: null,
    isCallout: '',
    propertyId:
      state?.type === 'tenantCreateJob'
        ? state?.propertyId
        : jobId
        ? job?.propertyId
        : '',
    priceType: 'fixed',
  });

  const getService = async () => {
    const service = await getServiceRequest({
      method: 'get',
      url: `/v1/services/${jobId || initialValues.id}`,
    });
    setInitialValues({
      ...service?.data,
      attachments: [...service?.data.attachments, null],
    });
    setValidatedTab(service?.data.completedStep);
  };

  useEffect(() => {
    if (jobId) {
      getService();
    }
  }, []);

  const onSubmitStep = async ({ data, isDraft }) => {
    if (isDraft) {
      navigate('/jobs');
      toast('Your service job is saved as draft you can continue on it later');
    } else {
      const url = '/v1/services';
      if (step < steps.length - 1) {
        data && setInitialValues(data);
        setStep(step + 1);
        setValidatedTab(prev => Math.max(step + 1, prev));

        await request({
          method: 'post',
          url: `${url}/${initialValues?.id || data?.id}`,
          data: { completedStep: step + 1 },
        });
        initialValues?.id && getService();
      } else {
        try {
          await request({
            method: 'post',
            url: `${url}/${initialValues?.id || data?.id}`,
            data: { status: 'active' },
          });
          navigate('/jobs');
          toast('Your service job creation has been completed');
        } catch (err) {
          console.log('error', err);
        }
      }
    }
  };

  const childProps = {
    initialValues,
    setInitialValues,
    setStep,
    setValidatedTab,
    step,
    onSubmitStep,
    state,
  };
  const steps = [
    {
      children: <BasicInfo {...childProps} />,
      key: 0,
      label: 'Basic Information',
    },
    {
      children: <Categories {...childProps} />,
      key: 1,
      label: 'Categories',
    },
    {
      children: <Photos {...childProps} />,
      key: 2,
      label: 'Attachments',
    },
    {
      children: <Pricing {...childProps} />,
      key: 3,
      label: 'Pricing',
    },
  ];

  if (jobId && !initialValues?.id) {
    return <LoadingPlaceholder />;
  }

  return (
    <Container>
      <Stepper
        activeStep={step}
        stepItems={steps}
        enableTabClick={!!jobId}
        onTabClick={data => {
          setStep(data?.key);
        }}
      />
    </Container>
  );
};

export default AddService;
