import { createStore } from "redux";
import reducer from "./reducer";
import * as Actions from "./actions";
import Select from "./selectors";

const store = createStore(
  reducer,
  typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__()
);

if (module.hot) {
  module.hot.accept(() => {
    const nextRootReducer = require("./reducer").default;
    store.replaceReducer(nextRootReducer);
  });
}

export default { store, Actions, Select };
