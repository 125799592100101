import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Modal from '@/newComponents/Modal';
import Typography from '@/newComponents/Typography';
// import Button from '@shared/web/components/Button';
import Error from '@shared/web/components/Error';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
// import request from '@shared/front/utils/request';
import useAPI from '@/hooks/api';
// import LoadingPlaceholder from '@shared/web/components/LoadingPlaceholder';
import { useRoot } from '@/RootProvider';
import { useSelector } from 'react-redux';
import { Apis, Hooks, Store, Utils } from '@shared/front';
import {
  SaveButtonContainer,
  StyledButton,
  StyledLabel,
  Title,
} from '../index.styles';

// import { CleanPlugin } from 'webpack';
const stripePromise = loadStripe(process.env.STRIPE_PK || '');

// console.log('the stripe promise', stripePromise);

const Container = styled.div`
  max-width: 450px;
`;
const CardDetails = styled.div`
  background-color: #fafafa;
  padding: 15px 20px;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  > .ant-typography {
    margin-bottom: 0;
  }
`;

const DeleteButton = styled(StyledButton)`
  background-color: #d32f2f;
  margin-left: 16px;
`;

const CreditCard = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { auth, checkAuth } = useRoot();
  const { stripeCustomerId } = auth;
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [makeRequest, { loading: cardRequestLoading }] = Hooks.useAPI();
  // const [handleGetCard, { data: card, loading: pageLoading }] = useAPI();
  const card = useSelector(Store.Select.cards);
  const [deleteCardRequest, { loading: deleteLoading }] = useAPI();
  const [visible, setVisible] = React.useState(false);
  const handleDeleteRequest = async () => {
    await deleteCardRequest({
      method: 'delete',
      url: `/v1/payments/card `,
    });
    setVisible(false);
    handleGetCard();
  };
  const handleGetCard = async () => {
    await Utils.request(Apis.Account.getCards());
    await checkAuth();
  };
  useEffect(() => {
    handleGetCard();
  }, []);

  const handleSubmitForm = async e => {
    setError('');

    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    if (elements == null) {
      return;
    }
    setLoading(true);
    const cardElement = elements.getElement(CardElement);
    const { error: paymentError, paymentMethod } =
      await stripe.createPaymentMethod({
        type: 'card',
        //@ts-ignore
        card: cardElement,
      });

    if (error) {
      console.log('[error]', error);
      setError(error?.message || '');
    } else {
      // console.log('paymentMethodTest', paymentMethod);
      // console.log('PaymentMethod attached to customer successfully');
      try {
        await makeRequest({
          method: 'post',
          url: '/v1/payments/card',
          data: {
            paymentMethodId: paymentMethod?.id,
            brand: paymentMethod?.card?.brand,
            last4: paymentMethod?.card?.last4,
            exp_month: paymentMethod?.card?.exp_month,
            exp_year: paymentMethod?.card?.exp_year,
            funding: paymentMethod?.card?.funding,
            // customerId: stripeCustomerId,
            // toAttach: true,
            // isTest: true,
          },
        });
      } catch (error) {}
    }

    setLoading(false);
    handleGetCard();
  };

  const cardStyle = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#32325d',
        },
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
    hidePostalCode: true,
  };
  return (
    <Container>
      <Modal
        title="Are you sure you want to delete?"
        open={visible}
        // onOk={handleDeleteRequest}
        // okType="danger"
        // okText="Delete"
        // confirmLoading={deleteLoading}
        // onCancel={() => setVisible(false)}
        onClose={() => setVisible(false)}
      >
        <StyledLabel>
          Your card will be deleted, if you don't add card then you can't use
          some features.
        </StyledLabel>
        <SaveButtonContainer>
          <StyledButton
            radius="round"
            variant="gray"
            onClick={() => setVisible(false)}
          >
            Cancel
          </StyledButton>
          <DeleteButton radius="round" onClick={handleDeleteRequest}>
            Delete
          </DeleteButton>
        </SaveButtonContainer>
      </Modal>
      <Title variant="h1">Credit Cards</Title>
      {!!card?.id && (
        <CardDetails>
          <Typography variant="p" color="#949494">
            XXXX-XXXX-XXXX-{card.last4}
          </Typography>
          <FontAwesomeIcon
            icon="trash"
            color="red"
            size="xl"
            style={{ marginLeft: '20px', cursor: 'pointer' }}
            onClick={() => setVisible(true)}
          />
        </CardDetails>
      )}
      {!card?.id && (
        <form onSubmit={handleSubmitForm}>
          <div
            style={{
              width: '100%',
            }}
          >
            <CardElement options={cardStyle} onChange={() => setError('')} />
          </div>

          <Error message={error} isFormError />
          <SaveButtonContainer>
            <StyledButton
              htmlType="submit"
              radius="round"
              isDisabled={loading}
              isLoading={loading}
            >
              Save
            </StyledButton>
          </SaveButtonContainer>
        </form>
      )}
    </Container>
  );
};

const App = props => {
  return (
    <Elements stripe={stripePromise}>
      <CreditCard {...props} />
    </Elements>
  );
};

export default App;
