import { ReturnType } from "./types";
export default {
  getBiddings: (): ReturnType => {
    return {
      method: "get",
      url: "/v1/biddings",
      config: {
        store: {
          action: "set",
          key: "biddings",
        },
      },
    };
  },
  createBidding: (data: any): ReturnType => {
    return {
      method: "post",
      url: `/v1/biddings/`,
      data,
      config: {
        store: {
          action: "set",
          key: "biddings",
        },
        successMsg: "Bidding created successfully",
      },
    };
  },
  updateBiddingStatus: (id: number): ReturnType => {
    return {
      method: "put",
      url: `/v1/biddings/${id}`,
      config: {
        store: {
          action: "set",
          key: "biddings",
        },
        successMsg: "Bidding created successfully",
      },
    };
  },
  createUnregisteredBidding: (data: any): ReturnType => {
    return {
      method: "post",
      url: `/v1/biddings/unregistered`,
      data,
      config: {
        successMsg: "Bidding Created successfully",
      },
    };
  },
};
