import Button from '@/newComponents/Button';
import Typography from '@/newComponents/Typography';
import { devices } from '@/utils/theme';
import { Form } from 'formik';
import styled from 'styled-components';

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const PageTitle = styled(Typography)`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;

  @media ${devices.laptop} {
    font-size: 20px;
  }
`;

export const Title = styled(Typography)`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 20px;

  @media ${devices.laptop} {
    font-size: 16px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledButton = styled(Button)`
  font-size: 12px;
  line-height: 25px;
  padding: 0px 20px;

  @media ${devices.laptop} {
    font-size: 14px;
    line-height: 30px;
  }
`;

export const LinkButton = styled(Button)`
  all: unset;
  cursor: pointer;

  &:hover {
    color: #2857ff !important;
  }
`;

export const SubText = styled(Typography)`
  font-size: 10px;
  line-height: 25px;

  @media ${devices.laptop} {
    font-size: 12px;
  }
`;
