import BankAccount from '@/containers/Settings/Documents';
import useAPI from '@/hooks/api';
import Button from '@/newComponents/Button';
import S3presigned from '@shared/web/components/S3presigned';
import { Row } from 'antd';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import validation from './validation';
const OPTIONS = [
  {
    label: 'Individual',
    value: 'individual',
  },
  {
    label: 'Company',
    value: 'company',
  },
];
type Props = {
  initialValues: any;
  onSubmitStep: () => void;
};
const Location = ({ initialValues, onSubmitStep }: Props) => {
  const formikRef = useRef<FormikProps<any>>(null);
  const [isMapReady, setMapReady] = useState(false);
  const [places, setPlaces] = useState([
    {
      label: 'Select',
      value: 'Select',
    },
  ]);
  const [handleSubmitRequest, { loading, error }] = useAPI();

  const documents: { type: string; url: string }[] = useSelector(
    (state: any) => state?.accountDocuments,
  );

  const handleSubmitForm = async (values: any) => {
    // if (documents && documents?.length > 0) {
    await handleSubmitRequest({
      method: 'put',
      url: '/v1/auth/documents',
      data: {
        avatar: values.avatar[0],

        // identity1: {
        //   type: documents[0]?.type,
        //   url: documents[0]?.url,
        // },
        // identity2: {
        //   type: documents[1]?.type,
        //   url: documents[1]?.url,
        // },
      },
    });

    onSubmitStep();
    // }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      onSubmit={async values => {
        if (!!values?.avatar.length) {
          await handleSubmitForm(values);
        }
      }}
      validationSchema={validation.documents}
    >
      {({ isSubmitting, values }) => {
        return (
          <Form>
            <Field
              name="avatar"
              component={S3presigned}
              label={'Profie Picture'}
              isMultiple={false}
            ></Field>

            {/* <BankAccount /> */}
            <Row justify="end">
              <Button
                onClick={() => handleSubmitForm(values)}
                bgColor="primary"
                isDisabled={loading || isSubmitting}
                isLoading={loading || isSubmitting}
              >
                Save
              </Button>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Location;
