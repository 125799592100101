import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Store, Utils, Apis } from '@shared/front';
import { useSelector } from 'react-redux';
import BookingLargeCard from '@/newComponents/BookingLargeCard';
import PropertySkeleton from '../Properties/PropertySkeleton';
import EmptyPage from '../Empty';
const TabWrapper = styled.div`
  display: flex;
  column-gap: 30px;
`;
const TabItem = styled.button<any>`
  padding: 15px 0px;
  background: none;
  border: 0px;
  color: ${({ active }) => (active ? '#000' : '#949494')};
  cursor: pointer;
`;
const BookingItems = styled.div<any>`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
`;
const tabs = [
  {
    label: 'Requests',
    key: 'requests',
  },
  {
    label: 'Completed',
    key: 'completed',
  },
];
const LandlordBookingList = () => {
  const [active, setActive] = useState<string>('requests');
  const [loading, setLoading] = useState<boolean>(false);
  const bookingRequests: any[] = useSelector(Store.Select.bookingRequests);
  const bookingCompleted: any[] = useSelector(Store.Select.bookingCompleted);
  const bookings = active === 'requests' ? bookingRequests : bookingCompleted;
  const getBookingRequests = async (action: 'set' | 'update') => {
    setLoading(true);
    try {
      await Utils.request(Apis.Booking.getBookingRequests(action));
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const getBookingCompleted = async (action: 'set' | 'update') => {
    setLoading(true);
    try {
      await Utils.request(Apis.Booking.getCompletedBooking({}, action));
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const handleClickTab = (value: string) => {
    setActive(value);
    if (value === 'requests') {
      getBookingRequests('set');
    } else {
      getBookingCompleted('set');
    }
  };
  useEffect(() => {
    getBookingRequests('set');
  }, []);

  return (
    <>
      <TabWrapper>
        {tabs?.map((item: any) => (
          <TabItem
            active={item?.key === active}
            key={item?.key}
            onClick={() => {
              handleClickTab(item.key);
            }}
          >
            {item.label}
          </TabItem>
        ))}
      </TabWrapper>
      <BookingItems>
        {!!loading && <PropertySkeleton />}
        {!loading && bookings?.length === 0 && <EmptyPage />}
        {!loading && bookings?.length && (
          <>
            {bookings?.map((booking: any) => (
              <BookingLargeCard
                item={booking}
                active={active}
                key={booking?.id}
              />
            ))}
          </>
        )}
      </BookingItems>
    </>
  );
};

export default LandlordBookingList;
