import React, { useState, useEffect } from 'react';
import { useRoot } from '@/RootProvider';
import { SectionHeaderRow, SectionTitle, SectionWrapper } from '../style';
import Button from '@/newComponents/Button';
import RoomModal from './RoomModal';
import { Store, Utils, Apis } from '@shared/front';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Typography from '@/newComponents/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import NoData from '@/newComponents/NoData';
import inventoryIcon from '@shared/common/constants/iconInventory';
import { Image } from '../style';
import RoomsItemsModal from './RoomsItemsModal';
import ListRoomItemsModal from './ListRoomItemsModal';
import Modal from '@/newComponents/Modal';
import { ButtonEndWrapperStyled } from '@/styles/shareableStyle';
import { useResponsive } from '@/hooks/useResponsive';
import DeleteIconNew from '../../../assets/delete-icon-new.svg';

const InventoryWrapper = styled.div``;
const StyledButton = styled.button<{ isPrimary?: boolean }>`
  padding: 0px 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: ${props =>
    props.isPrimary
      ? 'blue'
      : 'inherit'}; // Change color based on the "isPrimary" prop
`;

const StyledIconSpan = styled.span`
  cursor: pointer;
  padding: 5px 3px;
  /* Add any additional styling here if needed */
`;
const InventoryList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
`;
const InventoryItem = styled.div`
  background: #f5f5f5;
  padding: 10px 20px;
  border-radius: 4px;
  display: flex;
  cursor: pointer;
`;
const InventoryActionWrapper = styled.div`
  display: flex;
  margin-left: auto;
  column-gap: 6px;
`;

type Props = {
  propertyId: number;
  bookingId?: number;
  landlordId?: number;
};

const Rooms = ({ propertyId, bookingId, landlordId }: Props) => {
  const { isTablet } = useResponsive();

  const { auth: user } = useRoot();
  const categories: any[] = useSelector(Store.Select.inventoryCategories);
  const [confirmDeletion, setConfirmDeletion] = useState<{
    status: boolean;
    data: any;
  }>({
    status: false,
    data: null,
  });
  const [open, setOpen] = useState<boolean>(false);
  const [enableAddItinerary, setEnableAddItinerary] = useState({
    status: false,
    data: {},
  });
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<any>({
    id: null,
    status: false,
  });
  const [selectedItem, setSelectedItem] = useState<any>({});
  const hasAccess = user?.id === landlordId;
  const dispatch = useDispatch();

  const getInventoryCategories = async () => {
    try {
      await Utils.request(Apis.Properties.getInventoryCategories(propertyId));
    } catch (err) {
      console.log(err);
    }
  };
  const deleteInventoryCategory = async (categoryId: number) => {
    try {
      setDeleteLoading({ id: categoryId, status: true });
      await Utils.request(Apis.Properties.deleteInventoryCategory(categoryId));
      toast.success('Deleted successfully');
      dispatch(
        Store.Actions.set(
          'inventoryCategories',
          categories.filter(x => x.id !== categoryId),
        ),
      );
      setDeleteLoading({ ...deleteLoading, status: false });
      closeDeleteModal();
    } catch (err) {
      toast.error('Failed');
      setDeleteLoading({ ...deleteLoading, status: false });
    }
  };
  useEffect(() => {
    getInventoryCategories();
  }, [propertyId]);

  const handleOpenAddItineraries = (category: any) => {
    setEnableAddItinerary({
      status: true,
      data: {
        categoryId: category?.id,
        propertyId,
        category,
      },
    });
  };

  const handleCloseAddItineraries = () => {
    setEnableAddItinerary({
      status: false,
      data: {},
    });
  };

  const handleToggleAddItineraries = () => {
    setEnableAddItinerary(prev => ({
      ...prev,
      status: !prev?.status,
    }));
  };

  const openDeleteModal = (data: any) => {
    setConfirmDeletion({
      status: true,
      data,
    });
  };

  const closeDeleteModal = () => {
    setConfirmDeletion({
      status: false,
      data: null,
    });
  };

  return (
    <SectionWrapper>
      <SectionHeaderRow>
        <SectionTitle>Inventory Category</SectionTitle>
        {hasAccess && (
          <Button
            style={{ marginLeft: 'auto' }}
            variant="gray"
            size="sm"
            onClick={() => {
              setOpen(true);
            }}
          >
            Add
          </Button>
        )}
      </SectionHeaderRow>
      <InventoryWrapper>
        {!!categories?.length ? (
          <InventoryList>
            {categories?.map(category => (
              <InventoryItem
                key={category?.id}
                onClick={() => handleOpenAddItineraries(category)}
              >
                <Image
                  style={{ height: 20, paddingRight: 10 }}
                  src={inventoryIcon?.icon}
                />
                <Typography variant="p">{category?.title}</Typography>
                {hasAccess && (
                  <InventoryActionWrapper>
                    <StyledButton
                      isPrimary={true}
                      onClick={e => {
                        e.stopPropagation();
                        setIsEditMode(true);
                        setSelectedItem(category);
                        setOpen(true);
                      }}
                    >
                      <FontAwesomeIcon icon="pencil" color="primary" />
                    </StyledButton>
                    <StyledIconSpan
                      onClick={e => {
                        e.stopPropagation();
                        openDeleteModal(category);
                      }}
                    >
                      <img src={DeleteIconNew} alt="Delete" />
                    </StyledIconSpan>
                    <StyledButton
                      onClick={e => {
                        e.stopPropagation();
                        handleOpenAddItineraries(category);
                      }}
                    >
                      <FontAwesomeIcon icon="eye" />
                    </StyledButton>
                  </InventoryActionWrapper>
                )}
              </InventoryItem>
            ))}
          </InventoryList>
        ) : (
          <NoData size="130" title="No Inventory available" />
        )}
      </InventoryWrapper>
      {hasAccess && (
        <RoomModal
          propertyId={propertyId}
          setOpen={setOpen}
          open={open}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          hasAccess={hasAccess}
        />
      )}
      <ListRoomItemsModal
        open={enableAddItinerary?.status}
        onClose={handleCloseAddItineraries}
        itineraryCategoryId={enableAddItinerary?.data?.categoryId}
        propertyId={enableAddItinerary?.data?.propertyId}
        itineraryCategory={enableAddItinerary?.data?.category}
        onToggle={handleToggleAddItineraries}
        hasAccess={hasAccess}
      />
      {hasAccess && (
        <Modal
          open={confirmDeletion.status}
          onClose={closeDeleteModal}
          title="Delete"
          isCentered={!isTablet}
          style={{ width: !isTablet ? '90vw' : 'auto' }}
        >
          Are you sure you want to delete {confirmDeletion?.data?.title} ?
          <br />
          <br />
          <ButtonEndWrapperStyled>
            <Button variant="gray" onClick={closeDeleteModal}>
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                deleteInventoryCategory(confirmDeletion?.data?.id);
              }}
              isLoading={deleteLoading.status}
              isDisabled={deleteLoading.status}
            >
              Delete
            </Button>
          </ButtonEndWrapperStyled>
        </Modal>
      )}
    </SectionWrapper>
  );
};

export default Rooms;
