import Button from '@/newComponents/Button';
import Modal from '@/newComponents/Modal';
import Typography from '@/newComponents/Typography';
import { ButtonEndWrapperStyled } from '@/styles/shareableStyle';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

type Props = {
  onYes: () => void;
  onClose: () => void;
  isOpen: boolean;
  loading?: boolean;
};

const CancelDeleteAccountConfirmation = ({
  onClose,
  onYes,
  isOpen,
  loading,
}: Props) => {
  const isMin6oo = useMediaQuery({ query: '(min-width:600px)' });

  return (
    <Modal
      onClose={onClose}
      open={isOpen}
      title="Cancel Delete Account"
      style={{ width: isMin6oo ? '600px' : '95%' }}
      isCentered
      closeOnBackdropClick={false}
    >
      <Typography style={{ marginBottom: '30px' }}>
        Are you sure you want to cancel deletion of this account?
      </Typography>

      <ButtonEndWrapperStyled>
        <Button onClick={onYes} isDisabled={loading} isLoading={loading}>
          Activate Account
        </Button>
        <Button
          onClick={onClose}
          variant="gray"
          isDisabled={loading}
          isLoading={loading}
        >
          Cancel
        </Button>
      </ButtonEndWrapperStyled>
    </Modal>
  );
};

export default CancelDeleteAccountConfirmation;
