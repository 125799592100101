import { useRoot } from '@/RootProvider';
import { optionTypes } from '@/containers/Bookings';
import Button from '@/newComponents/Button';
import Divider from '@/newComponents/Divider';
import NoData from '@/newComponents/NoData';
import Typography from '@/newComponents/Typography';
import Store from '@/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Apis, Utils } from '@shared/front';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import JobsSkeleton from './JobsSkeleton';
import JobItem from './jobItem';

const Container = styled.div``;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 60px;
  background: #fff;

  & .title {
    font-size: 1.6rem;
    font-weight: 500;
  }
  padding: 20px 0px 10px 0px;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 20px;
  z-index: 20;
`;

const OrderTableView = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { auth } = useRoot();
  const role = auth.role;
  const dispatch = useDispatch();

  // SELECTORS
  const jobs: any = useSelector<any>(Store.Select.jobs);
  const jobsPagination = useSelector<{
    page: number;
    limit: number;
    lastPage?: number;
    count?: number;
    title?: string;
  }>(Store.Select.jobsPagination);

  const handleJobsRequest = async (myParams: any) => {
    setLoading(true);
    const response = await Utils.request(
      Apis.Services.getServices({
        role,
        params: { limit: myParams.limit || 10, page: myParams.page || 0 },
        action: myParams?.page == 0 ? 'set' : 'update',
      }),
    );
    if (response) {
      dispatch(Store.Actions.set('jobsPagination', response?.pagination));
    }
    setLoading(false);
  };
  useEffect(() => {
    handleJobsRequest(jobsPagination);
  }, []);

  const handleRequest = () => {
    if (jobsPagination) {
      handleJobsRequest({ ...jobsPagination, page: +jobsPagination?.page + 1 });
    }
  };
  const searchByTitle = event => {
    dispatch(
      Store.Actions.update('jobsPagination', {
        page: 0,
        title: event.target.value,
      }),
    );
  };

  const searchKeyword = React.useRef(debounce(searchByTitle, 500)).current;

  return (
    <Container>
      <TitleContainer>
        <Typography className="title" variant="p">
          Jobs
        </Typography>
        <Button
          variant="gray"
          radius="round"
          bgColor="red"
          onClick={() => navigate('/jobs/add')}
        >
          <Typography variant={'p'} size={'sm'}>
            <FontAwesomeIcon
              icon="plus"
              style={{ marginRight: 5 }}
            ></FontAwesomeIcon>
            Add Jobs
          </Typography>
        </Button>
      </TitleContainer>
      {!loading && !jobs?.length && <NoData />}
      {!!loading && !jobs?.length && <JobsSkeleton />}
      <InfiniteScroll
        dataLength={(jobsPagination?.page + 1) * jobsPagination?.limit}
        next={() => {
          handleRequest();
        }}
        hasMore={jobsPagination?.lastPage > jobsPagination?.page}
        loader={<JobsSkeleton />}
      >
        {!!jobs?.length &&
          jobs?.map((job: any) => (
            <>
              <JobItem
                key={job.id}
                job={job}
                loggedInUserId={+auth?.id}
                ownerId={+job?.landlordId}
                refreshData={() => handleRequest()}
              />
              <Divider style={{ margin: '24px 0px' }} />
            </>
          ))}
      </InfiniteScroll>
    </Container>
  );
};
export default OrderTableView;

const options: optionTypes = {
  featureoption: [
    {
      data: [
        {
          value: 'all',
          label: 'All',
        },
        {
          value: 'active',
          label: 'Active',
        },
        {
          value: 'draft',
          label: 'Draft',
        },
      ],
      placeholder: 'Property Status',
      key: 'properyStatus',
    },
  ],

  sortingoption: [
    {
      data: [
        {
          value: 'Price High to Low',
          label: 'Price High to Low',
        },
        {
          value: 'Price Low to High',
          label: 'Price Low to High',
        },
      ],
      placeholder: 'Price Status',
      key: 'priceStatus',
    },
  ],
};
