const login = (data: any) => {
  return {};
};

const getMe = () => {
  return {};
};

export default {
  login,
  getMe,
};
