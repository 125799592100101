import { ReturnType } from "./types";

export default {
  createReportIssue: (data: any): ReturnType => {
    return {
      method: "post",
      url: "/v1/common/report-issue",
      data: data,
      config: {
        successMsg: `Report issue has been created successfully`,
      },
    };
  },

  updateReportIssue: (id: number, data: any): ReturnType => {
    return {
      method: "patch",
      url: `/v1/common/report-issue/${id}`,
      data: data,
      config: {
        successMsg: `Status update successfully`,
        navigateBack: true,
      },
    };
  },

  getLandlordReportIssue: (params?: any): ReturnType => {
    return {
      method: "get",
      url: "/v1/common/issue/landlord",
      params: params,
      config: {
        store: {
          action: "set",
          key: "landlordReportIssues",
        },
      },
    };
  },

  getMyReportIssue: (params?: any): ReturnType => {
    return {
      method: "get",
      url: "/v1/common/my-report",
      params: params,
      config: {
        store: {
          action: "set",
          key: "myReportIssues",
        },
      },
    };
  },

  getReportById: (id: number): ReturnType => {
    return {
      method: "get",
      url: `/v1/common/report-issue/${id}`,
      config: {
        store: {
          action: "set",
          key: "reportDetails",
        },
      },
    };
  },

  uploadReportImage: (id: number, data: any): ReturnType => {
    return {
      method: "post",
      url: `/v1/common/report-issue-photos/${id}`,
      data: data,
      config: {
        navigateBack: true,
      },
    };
  },
  getAmenitiesByPropertyId: (propertyId: number): ReturnType => {
    return {
      method: "get",
      url: `/v1/common/property/${propertyId}/amenities`,
    };
  },
  getInventoryByPropertyId: (propertyId: number): ReturnType => {
    return {
      method: "get",
      url: `/v1/common/property/${propertyId}/itineraries`,
    };
  },
};
