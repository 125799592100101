import ProductCard from '@/newComponents/ProductCard';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const ListingWrapper = styled.div`
  padding: 0px 20px 20px;
  width: 100%;
`;

const Listing = ({
  products,
  productPagination,
  params,
  setParams,
  hasMore,
  setHasMore,
}: any) => {
  const navigate = useNavigate();

  const fetchMoreData = () => {
    if (products.length >= productPagination.count) {
      setHasMore(false);
      return;
    }
    setParams(prev => ({ ...prev, page: params.page + 1 }));
  };

  return (
    <ListingWrapper>
      {
        <InfiniteScroll
          dataLength={products.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<h4></h4>}
        >
          <GridContainer>
            {products?.map((item: any) => {
              return (
                <ProductCard
                  onClick={() => {
                    navigate(`/marketplace/${item.id}`);
                  }}
                  address={item?.address}
                  imageURL={item?.productImage[0]?.url}
                  productName={item?.title}
                  price={item?.price}
                  tag={item?.status}
                ></ProductCard>
              );
            })}
          </GridContainer>
        </InfiniteScroll>
      }
    </ListingWrapper>
  );
};

export default Listing;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  row-gap: 60px;
  column-gap: 30px;
  width: 100%;
`;
