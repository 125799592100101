import * as yup from 'yup';

const schema = {
  address: yup.object().shape({
    address: yup.string().nullable().required('Please enter address'),
  }),
  basicInfo: yup.object().shape({
    categoryId: yup.string().nullable().required('Please select category'),
    // subCategoryId: yup.string().nullable().required('Please select sub category'),
    title: yup.string().nullable().required('Please enter title'),
    price: yup.number().required('Please enter price'),
    adsExpiry: yup.string().nullable().required('Please enter expiry date '),
    desc: yup.string().nullable().required('Please enter description'),
    condition: yup
      .string()
      .nullable()
      .required('Please accept one of condition'),
    negotiable: yup
      .string()
      .nullable()
      .required('Please accept one of negotiable'),
    delivery: yup.string().nullable().required('Please accept one of delivery'),
    listType: yup
      .string()
      .nullable()
      .required('Please select one of the list type'),
  }),
  specification: yup.object().shape({
    specification: yup
      .array()
      .min(1, 'Please add specification')
      .of(
        yup.object().shape({
          title: yup.string().nullable().required('Please enter title'),
          value: yup.string().nullable().required('Please enter value'),
        }),
      ),
  }),

  photos: yup.object().shape({
    images: yup
      .array()
      .min(1, 'Please upload photos')
      .required('Please upload photos'),
  }),
};
export default schema;
