import InternetIcon from "../assets/amenities/globe.png";
import TvIcon from "../assets/amenities/tv.png";
import AirConditionerIcon from "../assets/amenities/air-conditioner.png";
import WashingMachineIcon from "../assets/amenities/washing-machine.png";
import ParkingIcon from "../assets/amenities/parking.png";
import heater from "../assets/amenities/heater.png";
import gym from "../assets/amenities/gym.png";
import refrigerator from "../assets/amenities/refrigerator.png";
import smokeDetector from "../assets/amenities/smoke-detector.png";
import elevator from "../assets/amenities/elevator.png";
import fireExtinguisher from "../assets/amenities/fire-extinguisher.png";
import emergencyExit from "../assets/amenities/emergency-exit.png";
import servingDish from "../assets/amenities/serving-dish.png";
import Alarm from "../assets/amenities/alram.png";
import DishWasher from "../assets/amenities/dish-washer.png";
const amenities = [
  { id: 1, title: "Internet", icon: InternetIcon },
  {
    id: 2,
    title: "TV",
    icon: TvIcon,
  },
  {
    id: 3,
    title: "Air Conditioner",
    icon: AirConditionerIcon,
  },
  {
    id: 4,
    title: "Washing Machine",
    icon: WashingMachineIcon,
  },
  {
    id: 5,
    title: "Parking",
    icon: ParkingIcon,
  },
  {
    id: 6,
    title: "Central Heating",
    icon: heater,
  },
  {
    id: 8,
    title: "Fitness Room/Equipment",
    icon: gym,
  },
  {
    id: 9,
    title: "Refrigerator",
    icon: refrigerator,
  },
  {
    id: 10,
    title: "Dishes & Utensils",
    icon: servingDish,
  },
  {
    id: 11,
    title: "Smoke detector",
    icon: smokeDetector,
  },
  {
    id: 12,
    title: "Elevator",
    icon: elevator,
  },
  {
    id: 13,
    title: "Fire extinguisher",
    icon: fireExtinguisher,
  },
  {
    id: 14,
    title: "Emergency exit route",
    icon: emergencyExit,
  },
  {
    id: 15,
    title: "Alarm",
    icon: Alarm,
  },
  {
    id: 16,
    title: "Dryer",
    icon: "",
  },
  {
    id: 16,
    title: "Dish Washer",
    icon: DishWasher,
  },
  {
    id: 17,
    title: "Microwave",
    icon: "",
  },
  {
    id: 18,
    title: "Wheelchair Access",
    icon: "",
  },
  {
    id: 19,
    title: "Garden",
    icon: "",
  },
  {
    id: 20,
    title: "Patio",
    icon: "",
  },
  {
    id: 21,
    title: "Balcony",
    icon: "",
  },
];

export default amenities;
