import { useRoot } from '@/RootProvider';
import { useResponsive } from '@/hooks/useResponsive';
import Button from '@/newComponents/Button';
import Divider from '@/newComponents/Divider';
import ImageBrowser from '@/newComponents/FileBrowser/ImageBrowser';
import SmallImageViewer from '@/newComponents/FileBrowser/UploadedSmallImageViewer';
import Modal from '@/newComponents/Modal';
import NoData from '@/newComponents/NoData';
import Typography from '@/newComponents/Typography';
import { uploadImagesToS3 } from '@/utils/S3MultipleUploader';
import { Apis, Hooks, Utils } from '@shared/front';
import React, { useState } from 'react';
import styled from 'styled-components';
import { ButtonWrapperStyled, TitleWrapperStyled } from '../ReferenceImage';
import { SectionTitle } from '../style';

// const SectionTitle = styled.h3``;

type Props = {
  enableAddImagesModal: boolean;
  onClose: () => void;
  selectedRoom: any;
  propertyRooms: any;
  propertyId: number;
  handleGetPropertyRooms: () => void;
  setSelectedRoom: Function;
  setEditRoom: (data: { status: boolean; data: any }) => void;
  booking: any;
};

const AddRefImagesModal = ({
  enableAddImagesModal,
  onClose,
  propertyId,
  selectedRoom,
  handleGetPropertyRooms,
  setSelectedRoom,
  setEditRoom,
  propertyRooms,
  booking,
}: Props) => {
  const { auth } = useRoot();
  const isTenant = auth?.role === 'tenant';
  const { isTablet } = useResponsive();
  // STATE HOOKS
  const [deletingRefImage, setDeletingRefImage] = useState([]);
  const [addingRefImage, setAddingRefImage] = useState(false);
  const [markRefAsCompleted, setMarkRefAsCompleted] = useState(false);
  const [showEditRoomName, setShowEditRoomName] = useState(false);
  const [files, setFiles] = useState([]);

  // API HOOKS
  const [requestCreateOrUpdateRefImages, { loading: refLoading }] =
    Hooks.useAPI();
  const [requestMarkCompleted, { loading: markRefAsCompleteLoading }] =
    Hooks.useAPI();

  const handleCancelAddImageModal = () => {
    onClose();
    setFiles([]);
  };

  const uploadReferenceImage = async () => {
    setAddingRefImage(true);
    try {
      const keys = await uploadImagesToS3(files);
      if (!keys || !Array.isArray(keys)) return;
      if (selectedRoom?.room?.id && selectedRoom?.refGroupId) {
        await requestCreateOrUpdateRefImages(
          Apis.Properties.uploadPropertyReferenceImage(
            {
              urls: selectedRoom?.room?.referenceImageGroup[0]?.referenceImage.reduce(
                (allKeys: string[], img) => {
                  allKeys.push(img.URL);
                  return allKeys;
                },
                [...keys],
              ),
              referenceImageGroupId: selectedRoom?.refGroupId,
              propertyReferenceRoomId: selectedRoom?.room?.id,
            },
            propertyId,
          ),
        );
        setFiles([]);
        handleGetPropertyRooms();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setAddingRefImage(false);
    }
  };

  const handleRefImageDelete = async (id: number) => {
    setDeletingRefImage(prev => [...prev, id]);
    try {
      await Utils.request(Apis.Properties.deletePropertyReferenceImage(id));
      setSelectedRoom(prev => {
        const refImageGroup = [...prev.room.referenceImageGroup];
        const referenceImageGroupTenantIndex =
          selectedRoom?.room?.referenceImageGroup?.findIndex(
            grp => grp?.authId === booking?.tenantId,
          );
        const images = refImageGroup[
          referenceImageGroupTenantIndex
        ].referenceImage.filter(x => x.id !== id);
        refImageGroup[referenceImageGroupTenantIndex] = {
          ...refImageGroup[referenceImageGroupTenantIndex],
          referenceImage: images,
        };
        return {
          ...prev,
          room: {
            ...prev.room,
            referenceImageGroup: refImageGroup,
          },
        };
      });
      setDeletingRefImage(prev => [...prev.filter(x => x !== id)]);
    } catch (error) {
      console.log(error);
      setDeletingRefImage(prev => [...prev.filter(x => x !== id)]);
    }
  };

  const handleMarkAsComplete = async (groupId: number) => {
    await requestMarkCompleted(
      Apis.Booking.updateReferenceImageGroupStatus(groupId),
    );
    await handleGetPropertyRooms();
    setMarkRefAsCompleted(false);
  };

  const isActionAble = isTenant && auth?.id === booking?.tenantId;

  const referenceImageGroupLandlord =
    selectedRoom?.room?.referenceImageGroup?.find(
      grp => grp?.authId === booking?.landlordId,
    );
  const referenceImageGroupTenant =
    selectedRoom?.room?.referenceImageGroup?.find(
      grp => grp?.authId === booking?.tenantId,
    );

  return (
    <>
      <Modal
        open={enableAddImagesModal}
        onClose={handleCancelAddImageModal}
        size={'lg'}
        isCentered
        style={{ width: !isTablet ? '95vw' : '80vw' }}
        title="Reference Images"
      >
        <MainContainerStyled>
          <TitleWrapperStyled>
            <h3
              style={{
                display: 'block',
                marginBottom: '0',
              }}
              className="title"
            >
              {selectedRoom?.room?.title}{' '}
            </h3>
          </TitleWrapperStyled>
          {selectedRoom?.room?.notes && (
            <Typography
              variant="p"
              style={{
                display: 'block',
                color: '#666',
                marginBottom: '30px',
              }}
            >
              {selectedRoom?.room?.notes}{' '}
            </Typography>
          )}
          <Typography variant="p">Landlord Reference Images</Typography>
          {referenceImageGroupLandlord?.status === 'Completed' ? (
            <SmallImageViewer
              imagesURL={referenceImageGroupLandlord?.referenceImage.map(
                (image: any) => {
                  return { URL: image?.URL, id: image.id };
                },
              )}
              isLoading={refLoading || addingRefImage}
            />
          ) : (
            <div>
              <NoData
                title="No reference images of landlord are available."
                size={120}
              />
            </div>
          )}
          <Divider />
          {isActionAble && (
            <Typography
              variant="span"
              fontWeight="bold"
              size="sm"
              color={
                referenceImageGroupTenant?.status === 'Draft' ? 'red' : 'green'
              }
              style={{ padding: '0px 0px', margin: '0px 0px' }}
            >
              {referenceImageGroupTenant?.status}
            </Typography>
          )}
          {selectedRoom?.room?.notes && (
            <Typography
              variant="p"
              style={{
                display: 'block',
                color: '#666',
              }}
            >
              Tenant Reference Images
            </Typography>
          )}

          <SmallImageViewer
            files={files}
            setFiles={setFiles}
            imagesURL={referenceImageGroupTenant?.referenceImage.reduce(
              (acc, val) => {
                acc.push({
                  id: val.id,
                  URL: val.URL,
                });
                return acc;
              },
              [],
            )}
            isLoading={refLoading || addingRefImage}
            onRemove={handleRefImageDelete}
            enableRemoveImage={
              isActionAble && referenceImageGroupTenant?.status === 'Draft'
            }
            isDeletingIds={deletingRefImage}
            callbackAfterDeletion={handleGetPropertyRooms}
          />
          {Array.isArray(referenceImageGroupTenant?.referenceImage) &&
            referenceImageGroupTenant?.referenceImage?.length === 0 &&
            files?.length === 0 &&
            isActionAble && (
              <NoData title="No Images are added yet." size={100} />
            )}
          <ButtonWrapperStyled style={{ margin: '30px 0px' }}>
            {isActionAble &&
              referenceImageGroupTenant?.referenceImage.length > 0 &&
              referenceImageGroupTenant?.status === 'Draft' && (
                <Button
                  variant="primary"
                  onClick={() => setMarkRefAsCompleted(true)}
                  isLoading={markRefAsCompleteLoading}
                  isDisabled={markRefAsCompleteLoading}
                >
                  Mark as Complete
                </Button>
              )}
          </ButtonWrapperStyled>
          {isActionAble && referenceImageGroupTenant?.status === 'Draft' && (
            <>
              <SectionTitle variant="p">Add Your Reference Images</SectionTitle>
              <Typography
                variant="p"
                style={{
                  display: 'block',
                }}
              >
                Drag n drop or Click and browse for uploading multiple Images.
              </Typography>

              <ImageBrowser setFiles={setFiles} />
              <Typography
                variant="p"
                style={{
                  display: 'block',
                }}
              >
                Only JPEG, PNG and JPG image format are supported.
              </Typography>
            </>
          )}
          <ButtonWrapperStyled style={{ marginTop: '30px' }}>
            <Button
              onClick={() => {
                handleCancelAddImageModal();
              }}
              isDisabled={refLoading || addingRefImage}
            >
              Close
            </Button>
            {isActionAble && referenceImageGroupTenant?.status === 'Draft' && (
              <Button
                variant="primary"
                onClick={uploadReferenceImage}
                isLoading={refLoading || addingRefImage}
                isDisabled={refLoading || addingRefImage}
              >
                Upload Images
              </Button>
            )}
          </ButtonWrapperStyled>
        </MainContainerStyled>
      </Modal>
      <Modal
        open={markRefAsCompleted}
        title="Mark as complete."
        onClose={() => setMarkRefAsCompleted(false)}
        isCentered={!isTablet}
        style={{ width: !isTablet ? '90vw' : 'auto' }}
      >
        <Typography variant="p">
          Are you sure you want to mark these room reference images as
          Completed?
        </Typography>
        <Typography variant="p">
          After completed, these images will be visible to tenant.
        </Typography>
        <ButtonWrapperStyled style={{ marginTop: '30px' }}>
          <Button
            onClick={() => {
              setMarkRefAsCompleted(false);
            }}
            isDisabled={refLoading || addingRefImage}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => handleMarkAsComplete(referenceImageGroupTenant.id)}
            isLoading={refLoading || addingRefImage}
            isDisabled={refLoading || addingRefImage}
          >
            Mark as complete
          </Button>
        </ButtonWrapperStyled>
      </Modal>
    </>
  );
};

export default AddRefImagesModal;

const MainContainerStyled = styled.div`
  max-height: calc(100vh - 120px);
  overflow-y: auto;
  min-height: 50vh;
`;
