import React, { useState } from 'react';
import styled from 'styled-components';

const TableWrapper = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #e8e8e8;
`;

const TableHeader = styled.th`
  background-color: #f5f5f5;
  text-align: left;
  padding: 12px;
`;

const TableRow = styled.tr`
  &:nth-child(odd) {
    background-color: #fafafa;
  }
`;

const TableCell = styled.td`
  padding: 12px;
  border-bottom: 1px solid #e8e8e8;
`;

type TableColumn = {
  key: string;
  title: string;
};

type TableProps = {
  data: Record<string, string>[];
  columns: TableColumn[];
  rowAction?: (param: any) => void;
};

const CustomTable: React.FC<TableProps> = ({ data, columns, rowAction }) => {
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

  const handleSort = (columnKey: string) => {
    if (columnKey === sortColumn) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(columnKey);
      setSortOrder('asc');
    }
  };

  const sortedData = React.useMemo(() => {
    if (!sortColumn) {
      return data;
    }

    return [...data].sort((a, b) => {
      const aValue = a[sortColumn];
      const bValue = b[sortColumn];

      if (sortOrder === 'asc') {
        return String(aValue).localeCompare(bValue);
      } else {
        return String(bValue).localeCompare(aValue);
      }
    });
  }, [data, sortColumn, sortOrder]);

  return (
    <TableWrapper>
      <thead>
        <tr>
          {columns.map(column => (
            <TableHeader
              key={column.key}
              onClick={() => handleSort(column.key)}
            >
              {column.title}
            </TableHeader>
          ))}
        </tr>
      </thead>
      <tbody>
        {sortedData.map((row, rowIndex) => (
          <TableRow key={rowIndex} onClick={() => rowAction && rowAction(row)}>
            {columns.map(column => (
              <TableCell key={column.key}>{row[column.key]}</TableCell>
            ))}
          </TableRow>
        ))}
      </tbody>
    </TableWrapper>
  );
};

export default CustomTable;
