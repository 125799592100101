import request from '@shared/front/utils/request';
import { useState } from 'react';

const useAPI = (): [
  makeRequest: (config: any) => any,
  state: {
    data: any;
    loading: boolean;
    error: string;
  },
] => {
  const [state, setState] = useState({
    loading: false,
    error: '',
    data: null,
  });

  const makeRequest = async config => {
    try {
      setState({
        loading: true,
        error: '',
        data: state.data,
      });

      const res = await request(config);

      setState({
        loading: false,
        error: '',
        data: res,
      });
      return res;
    } catch (err: any) {
      setState({
        loading: false,
        error: err,
        data: state.data,
      });
      throw err;
    }
  };
  return [makeRequest, state];
};

export default useAPI;
