import useAPI from '@/hooks/api';
import Button from '@/newComponents/Button';
import Checkbox from '@/newComponents/Checkbox';
import Input from '@/newComponents/Input';
import Modal from '@/newComponents/Modal';
import Typography from '@/newComponents/Typography';
import { devices } from '@/utils/theme';
import { Apis } from '@shared/front';
import Error from '@shared/web/components/Error';
import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { DesiredPropertyData, OnSubmitStepParams } from '.';
import * as validation from './validation';

const CategoryGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 12px;

  @media ${devices.laptop} {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }
`;
type Props = {
  desiredValues: DesiredPropertyData;
  onSubmitStep: (data: OnSubmitStepParams) => void;
  loading: boolean;
};
const Trades = ({ desiredValues, onSubmitStep, loading }: Props) => {
  const formikref = useRef();
  const [getCategories, { data: trades }] = useAPI();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalInput, setModalInput] = useState('');
  const [tradeLists, setTradeList] = useState([]);
  const [modalError, setModalError] = useState('');

  const handleSubmitForm = async values => {
    try {
      await onSubmitStep({
        data: { ...desiredValues, trades: values?.trades },
        dataFrom: 'trades',
      });
    } catch (error) {}
  };

  const fetchFeature = async () => {
    const response = await getCategories(Apis.Services.getAllJobCategories());
    const responseCategories = response.map(x => x.title);
    const serviceCategories = desiredValues?.trades?.map(x => x);

    if (serviceCategories.length > 0) {
      const featureValue = serviceCategories.reduce((acc, val) => {
        if (!responseCategories.includes(val)) {
          acc.push(val);
          return acc;
        }
        return acc;
      }, []);

      setTradeList(featureValue);
    }
  };

  useEffect(() => {
    if (!!desiredValues?.trades) {
      formikref?.current?.setFieldValue('trades', [
        ...desiredValues?.trades.map(items => items),
      ]);
    }
    fetchFeature();
  }, []);

  const handleAddNewAmenity = e => {
    e.preventDefault();
    setIsModalVisible(true);
  };

  const isContainedInFeatures = (text: string) => {
    const responseCategories = trades.map(x => x.title);
    return responseCategories.includes(text);
  };

  const addNewAmenityToFeatures = e => {
    e.preventDefault();
    if (modalInput) {
      const responseCategories = trades.map(x => x?.title.toLowerCase());
      const featureListAmenities = tradeLists.map(x => x?.toLowerCase());
      if (
        responseCategories?.includes(modalInput.toLowerCase()) ||
        featureListAmenities?.includes(modalInput.toLowerCase())
      ) {
        setModalError('Category already exists.');
      } else {
        setModalError('');
        formikref?.current?.setFieldValue('trades', [
          ...(formikref?.current?.values?.trades || []),
          modalInput,
        ]);
        setTradeList([...tradeLists, modalInput]);
        setModalInput('');
        setIsModalVisible(false);
      }
    }
  };

  const removeNewCategory = (category: string) => {
    setTradeList(prev => {
      return prev.filter(x => x !== category);
    });
    formikref?.current?.setFieldValue(
      'trades',
      formikref?.current?.values?.trades.filter(i => i !== category),
    );
  };
  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  console.log({ desiredValues });

  return (
    <Formik
      innerRef={formikref}
      // initialValues={initialValues}
      initialValues={{ trades: desiredValues?.trades || [] }}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmitForm(values);
        setSubmitting(false);
      }}
      validationSchema={validation.trades}
      enableReinitialize
    >
      {({ errors, values, setFieldValue, touched, handleSubmit }) => {
        console.log(values, 'hhhhhhhhhhhh');
        return (
          <Form>
            <Typography
              variant="h1"
              style={{
                fontSize: '16px',
                fontWeight: 500,
                marginBottom: '24px',
              }}
            >
              Trades
            </Typography>
            <CategoryGrid>
              {!!trades?.length &&
                trades?.map(({ title, id }) => {
                  return (
                    <Checkbox
                      id={id}
                      label={title}
                      isChecked={
                        Array.isArray(values?.trades) &&
                        values?.trades?.includes(title)
                      }
                      onChange={e => {
                        if (e.target.checked) {
                          setFieldValue('trades', [
                            ...(values?.trades || []),
                            title,
                          ]);
                        } else {
                          setFieldValue(
                            'trades',
                            values?.trades.filter(i => i !== title),
                          );
                        }
                      }}
                    />
                  );
                })}
              {tradeLists?.map(newCategory => {
                return (
                  <Checkbox
                    id={newCategory}
                    key={newCategory}
                    label={newCategory}
                    isChecked={true}
                    onChange={e => {
                      removeNewCategory(newCategory);
                    }}
                  />
                );
              })}
              <Button
                size="md"
                variant="primary"
                onClick={handleAddNewAmenity}
                style={{ height: '40px', width: '40px', fontSize: '30px' }}
              >
                +
              </Button>
            </CategoryGrid>
            <Modal
              title="Add New Category"
              open={isModalVisible}
              isCentered={true}
              onClose={handleModalCancel}
              style={{ width: '400px', paddingBottom: '18px' }}
            >
              <Input
                type="text"
                placeholder="Enter a new category"
                value={modalInput}
                onChange={e => setModalInput(e.target.value)}
              />
              {modalError && <div style={{ color: 'red' }}>{modalError}</div>}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: '10px',
                  marginTop: '10px',
                }}
              >
                <Button
                  onClick={handleModalCancel}
                  radius="round"
                  variant="gray"
                >
                  Cancel
                </Button>
                <Button onClick={addNewAmenityToFeatures} radius="round">
                  Add
                </Button>
              </div>
            </Modal>

            {errors?.trades && touched?.trades && (
              <Error message={errors?.trades} />
            )}

            <div
              style={{
                display: 'flex',
                gap: '20px',
                justifyContent: 'flex-end',
                width: '100%',
                margin: '20px 0px',
              }}
            >
              <StyledButton
                htmlType="submit"
                radius="round"
                isDisabled={loading}
                isLoading={loading}
              >
                Save
              </StyledButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Trades;

export const StyledButton = styled(Button)`
  font-size: 12px;
  line-height: 25px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
`;
