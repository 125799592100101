import Button from '@/newComponents/Button';
import Typography from '@/newComponents/Typography';
import { Constants } from '@shared/common';
import {
  moveInTypeOption,
  paidTypeOption,
} from '@shared/common/constants/propertyDropdown';
import MaskInputField from '@shared/web/components/MaskInputField';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { DesiredPropertyData, ErrorMessage, OnSubmitStepParams } from '.';
import * as validation from './validation';

type Props = {
  desiredValues: DesiredPropertyData;
  onSubmitStep: (data: OnSubmitStepParams) => void;
};

const FormikSelectField = styled(Field)`
  display: block;
  width: 100%;
  height: 40px;
  margin: 6px 0px;
  outline: none;
  border: 1px solid ${({ theme }) => theme.colors.border};
  background-color: ${({ theme }) => theme.colors.lightBG};
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }

  &.disabled-hidden-option {
    color: transparent;
    filter: blur(2px);
    border: 1px solid red;
  }
`;

export const StyledButton = styled(Button)`
  font-size: 12px;
  line-height: 25px;
`;

const StyledOption = styled.option`
  text-align: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
`;

const BasicInformation = ({ desiredValues, onSubmitStep }: Props) => {
  const handleSubmitForm = (values: any) => {
    onSubmitStep({ data: values, dataFrom: 'info' });
  };

  return (
    <div>
      <Formik
        initialValues={desiredValues}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmitForm(values);
        }}
        validationSchema={validation.basicInfo}
      >
        {({
          isSubmitting,
          enableReinitialize,
          errors,
          touched,
          values,
          setFieldValue,
          handleSubmit,
          setSubmitting,
        }) => {
          // console.log('the changed values', values);
          return (
            <div>
              <Form onSubmit={handleSubmit}>
                <Typography
                  variant="h1"
                  style={{
                    fontSize: '16px',
                    fontWeight: 500,
                    marginBottom: '24px',
                  }}
                >
                  Basic Information
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px',
                  }}
                >
                  <div>
                    <Typography variant="p">Property Type</Typography>
                    <FormikSelectField component="select" name="propertyType">
                      <StyledOption value="" disabled selected>
                        Select the Property Type
                      </StyledOption>
                      {Constants.PropertyDropdown.propertyTypeOptions()?.map(
                        item => {
                          return (
                            <StyledOption label={item.label} value={item.value}>
                              {item.value}
                            </StyledOption>
                          );
                        },
                      )}
                    </FormikSelectField>
                    {errors?.propertyType && (
                      <ErrorMessage> {errors?.propertyType}</ErrorMessage>
                    )}
                  </div>

                  <div>
                    <Typography variant="p">Price</Typography>
                    <Field
                      component={MaskInputField}
                      name="price"
                      decimalSeparator="."
                      displayType="input"
                      type="text"
                      thousandSeparator={true}
                      prefix={'€'}
                      setFieldValue={setFieldValue}
                    />
                    {errors?.price && (
                      <ErrorMessage> {errors?.price}</ErrorMessage>
                    )}
                  </div>
                  <div>
                    <Typography variant="p">Target Price</Typography>
                    <Field
                      component={MaskInputField}
                      name="targetPrice"
                      decimalSeparator="."
                      displayType="input"
                      type="text"
                      thousandSeparator={true}
                      prefix={'€'}
                      setFieldValue={setFieldValue}
                    />
                    {errors?.targetPrice && (
                      <ErrorMessage> {errors?.targetPrice}</ErrorMessage>
                    )}
                  </div>
                  <div>
                    <Typography variant="p">
                      Commute Distance (in KM)
                    </Typography>
                    <Field
                      component={MaskInputField}
                      name="distance"
                      decimalSeparator="."
                      displayType="input"
                      type="text"
                      thousandSeparator={true}
                      suffix={' KM'}
                      setFieldValue={setFieldValue}
                    />
                    {errors?.distance && (
                      <ErrorMessage> {errors?.distance}</ErrorMessage>
                    )}
                  </div>
                  <div>
                    <Typography variant="p">
                      Commute Time (in minutes)
                    </Typography>
                    <Field
                      component={MaskInputField}
                      name="time"
                      decimalSeparator="."
                      displayType="input"
                      type="text"
                      thousandSeparator={true}
                      suffix={' mins'}
                      setFieldValue={setFieldValue}
                    />
                    {errors?.time && (
                      <ErrorMessage> {errors?.time}</ErrorMessage>
                    )}
                  </div>

                  <div>
                    <Typography variant="p">Move In </Typography>
                    <FormikSelectField component="select" name="moveStatus">
                      <StyledOption value="" disabled selected>
                        Select the Move In Type
                      </StyledOption>
                      {moveInTypeOption()?.map(item => {
                        return (
                          <StyledOption label={item.label} value={item.value}>
                            {item.value}
                          </StyledOption>
                        );
                      })}
                    </FormikSelectField>
                    {errors?.moveStatus && (
                      <ErrorMessage> {errors?.moveStatus}</ErrorMessage>
                    )}
                  </div>

                  <div>
                    <Typography variant="p">Paid Type </Typography>
                    <FormikSelectField component="select" name="paidType">
                      <StyledOption value="" disabled selected>
                        Select the Paid Type
                      </StyledOption>
                      {paidTypeOption()?.map(item => {
                        return (
                          <StyledOption label={item.label} value={item.value}>
                            {item.value}
                          </StyledOption>
                        );
                      })}
                    </FormikSelectField>
                    {errors?.paidType && (
                      <ErrorMessage> {errors?.paidType}</ErrorMessage>
                    )}
                  </div>
                </div>
                <ButtonsContainer>
                  <div
                    style={{
                      display: 'flex',
                      gap: '20px',
                      justifyContent: 'flex-end',
                      width: '100%',
                    }}
                  >
                    <StyledButton radius="round" htmlType="submit">
                      Next
                    </StyledButton>
                  </div>
                </ButtonsContainer>
              </Form>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default BasicInformation;
