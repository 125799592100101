import React from 'react';
import styled, { useTheme } from 'styled-components';
import Button from './Button';
import Typography from './Typography';
import { devices } from '@/utils/theme';
import EditLightSVG from '../assets/Edit_light.svg';
import DeleteLightSVG from '../assets/delete_shape.svg';
import { useNavigate } from 'react-router-dom';
import Modal from './Modal';
import useAPI from '@/hooks/api';
import { useResponsive } from '@/hooks/useResponsive';
import { useRoot } from '@/RootProvider';
import { capitalize } from 'lodash';
// import DeleteIconNew from '../../../assets/delete-icon-new.svg';
import DeleteIconNew from '../assets/delete-icon-new.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IJobsCardProps {
  id: number;
  ownerId: number;
  title: string;
  rate: string;
  numberOfProposal: number;
  numberOfNewProposals: number;
  numberOfHires: number;
  tags: Array<string>;
  jobType: string;
  jobStatus: string;
  refreshData?: () => void;
}

function JobsLGCard({
  id,
  jobStatus,
  jobType,
  rate,
  tags,
  title,
  numberOfHires,
  numberOfNewProposals,
  numberOfProposal,
  refreshData,
  ownerId,
}: IJobsCardProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [visible, setVisible] = React.useState(false);
  const { isTablet } = useResponsive();
  const { auth } = useRoot();
  const role = auth.role;
  const loggedInUserId = +auth?.id;

  const [deletePropertyRequest, { loading: deleteLoading }] = useAPI();

  const handleDeleteRequest = async () => {
    await deletePropertyRequest({
      method: 'delete',
      url: `/v1/services/${id}`,
    });
    refreshData && refreshData();
    setVisible(false);
  };

  const contents = [
    {
      label: 'Job Type',
      value: jobType?.toUpperCase(),
    },
    {
      label: `${capitalize(jobType)} Rate`,
      value: `€ ${rate} ${jobType?.toLowerCase() === 'hourly' ? '/hr' : ''}`,
    },
    {
      label: 'Proposals',
      value: numberOfProposal,
    },
    {
      label: 'New Proposals',
      value: numberOfNewProposals,
    },
    {
      label: 'Hires',
      value: numberOfHires,
    },
  ];

  const tagColors = [
    '#9747FF',
    '#00DE90',
    '#9DBAFC',
    '#00DE90',
    '#FF6961',
    '#ADD8E6',
    '#98FB98',
    '#FFFFE0',
    '#FFD700',
    '#BA55D3',
    '#FFB6C1',
    '#D2B48C',
    '#333333',
    '#F5F5F5',
  ];

  const handleOnEdit = e => {
    e.stopPropagation();
    navigate(`/jobs/edit/${id}`);
  };

  const handleOnDelete = e => {
    e.stopPropagation();
    setVisible(true);
  };

  return (
    <>
      <JobsCardStyled onClick={() => navigate(`/jobs/${id}`)}>
        <div className="left-container">
          <div className="title-row">
            <div className="title-wrapper">
              <Typography
                variant="p"
                className="title"
                style={{ fontWeight: 600, fontSize: '16px' }}
              >
                {title}
              </Typography>
              <Button
                variant="tag"
                radius="round"
                bgColor={
                  jobStatus === 'Active'
                    ? theme?.colors?.secondary
                    : theme?.colors?.primary
                }
              >
                {jobStatus}
              </Button>
            </div>
          </div>
          <div className="contents">
            {contents?.map(({ label, value }, index) => (
              <div className="content-row">
                <Typography variant="p" className="label">
                  {label}
                </Typography>
                <Typography variant="p" className="value">
                  {value}
                </Typography>
              </div>
            ))}
          </div>

          <div className="tags-container">
            {tags?.map((tag, idx) => (
              <Button
                variant="tag"
                radius="round"
                bgColor={tagColors[idx]}
                style={{ fontSize: '12px', cursor: 'default' }}
              >
                {tag}
              </Button>
            ))}
          </div>
        </div>
        {ownerId === loggedInUserId && (
          <div className="button-wrapper">
            <Button
              variant="gray"
              radius="round"
              size="sm"
              onClick={handleOnEdit}
            >
              <FontAwesomeIcon icon="pencil" color="blue" /> Edit
            </Button>
            {!numberOfProposal && (
              <Button
                variant="gray"
                radius="round"
                size="sm"
                onClick={handleOnDelete}
              >
                <img src={DeleteIconNew} alt="Delete" /> Delete
              </Button>
            )}
          </div>
        )}
      </JobsCardStyled>
      <Modal
        style={{ maxWidth: isTablet ? '500px' : '100%' }}
        size={isTablet ? 'md' : 'lg'}
        title="Are you sure you want to delete?"
        open={visible}
        onClose={() => setVisible(false)}
      >
        <p>You cannot retrieve this job once deleted. </p>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: '12px',
          }}
        >
          <Button variant="gray" onClick={() => setVisible(false)}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={handleDeleteRequest}
            isLoading={deleteLoading}
            isDisabled={deleteLoading}
          >
            Delete
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default JobsLGCard;

const JobsCardStyled = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 12px;

  @media ${devices.tablet} {
    flex-direction: row;
  }

  & .left-container {
    flex: 1;
    cursor: pointer;
    & .title-wrapper {
      margin-bottom: 8px;
      display: flex;
      align-items: center;

      & .title {
        margin-right: 8px;

        @media ${devices.tablet} {
          margin-right: 30px;
        }
      }
    }

    & .contents {
      margin-bottom: 18px;
      & .content-row {
        display: flex;
        align-items: center;

        & .label {
          width: 150px;
          font-weight: 500;
          font-size: 14px;
        }
        & .value {
          font-size: 14px;
          color: ${({ theme }) => theme?.colors?.lightgray};
        }
      }
    }

    & .job-status {
      margin-bottom: 20px;
    }

    & .tags-container {
      display: flex;
      align-items: center;
      gap: 8px;
      flex-wrap: wrap;
    }
  }
  & .button-wrapper {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-self: end;
    @media ${devices.tablet} {
      flex-direction: column;
      align-self: start;
    }
  }
`;
