import { useRoot } from '@/RootProvider';
import useAPI from '@/hooks/api';
import { Apis, Store, Utils } from '@shared/front';
import Button from '@/newComponents/Button';
import Alert from '@/newComponents/Alert';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import BookingLoadingView from './BookingLoadingView';
import SimpleBookingSteps from './SimpleBookingSteps';
import {
  CancelBookingModal,
  ContractMethodSelectionModal,
  ContractSignModal,
  DeclineBookingModal,
  GenerateManualContractModal,
  RentPaymentModal,
  SecurityDepositModal,
  UpdateManualContractModal,
} from './bookingModals';
import SelectedTenentView from './bookingStepsView/SelectedTenentView';
import {
  BookingActionsStyled,
  BookingDetailsStyled,
  ButtonWrapperStyled,
  Container,
  Info,
  InfoLabel,
  LeftBox,
  RightBox,
  StyledButton,
  UserActionsStyled,
} from './style';

import GoBack from '@/newComponents/GoBack';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import bookingProcessActions from './bookingProcessActionsButton';
import ImageSwiper from '@/newComponents/ImageSwiper';
import PropertyInfo from './PropertyInfo';
import bookingProcessStatus from './bookingProcessStatus';
import TenantsListWithTabs from './bookingStepsView/TenantsListWithTabs';
import Divider from '@/newComponents/Divider';
import MyPropertyReport from '@/containers/PropertyView/Report';
import { useResponsive } from '@/hooks/useResponsive';
import { useMediaQuery } from 'react-responsive';

const settings = {
  className: 'slider variable-width center',
  dots: true,
  infinite: true,
  speed: 500,
  rows: 1,
  swipeToSlide: true,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  // centerMode: true,
  variableWidth: true,
  pauseOnHover: true,
  vertical: false,
  verticalSwiping: false,
  arrows: true,
};

{
  /*
 bookingId : used by tenant methods only as it is coming from params id
 bookingIdAfterTenantIsSelected: used by landlord methods as it comes after selecting an specific tenanat from short list
*/
}
const BookingView = () => {
  // @ts-ignore
  const { auth: user } = useRoot();
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const isDesktop = useMediaQuery({ query: `(min-width:992px)` });

  // STATES
  const [isSignModalVisible, setSignModalVisibility] = React.useState(false);
  const [isDeclineModalVisible, setDeclineModalVisibility] =
    React.useState(false);
  const [isCancelModalVisible, setCancelModalVisibility] =
    React.useState(false);
  const [isDepositVisible, setDepositeVisible] = React.useState(false);
  const [isContractGenerateVisible, setContractGenerateVisible] =
    useState(false);
  const [isManualGenerateVisible, setManualGenerateVisible] = useState(false);
  const [isManualGenerateUpdateVisible, setManulGenerateUpdateVisible] =
    useState(false);
  const [isPaymentRentRelease, setPaymentRentRelease] = useState(false);
  const [bookingStatus, setBookingStatus] = useState('');
  const [bookingIdAfterTenantIsSelected, setBookingIdAfterTenantIsSelected] =
    useState(null);
  const [showAllRequestedTenants, setShowAllRequestedTenants] = useState(false);
  // API
  const [makeDeclineRequest, { loading: declineLoading }] = useAPI();
  const [makeCancelRequest, { loading: cancelLoading }] = useAPI();
  const [makeAcceptRequest, { loading: acceptLoading }] = useAPI();
  const [getBookingRequest, { loading: bookingLoading, data: booking }] =
    useAPI();

  // SELECTORS
  const propertyDetails = useSelector(Store.Select.property);
  const bookingRequested = useSelector(Store.Select.bookingRequestByProperty);
  const {
    property: propertyLoading,
    bookingRequestByProperty: bookingRequestByPropertyLoading,
  } = useSelector(Store.Select.loading);

  const dispatch = useDispatch();

  //check the auth
  const isLandlord = user?.role === 'landlord';
  const isTenant = user?.role === 'tenant';
  const ownerId = booking?.landlordId;
  const bookingCurrentStep = booking?.currentStep;
  const isSystemGenerate = booking?.contract?.isSystemGenerate;
  const propertyId = isLandlord ? parseInt(id || '', 10) : null;
  const bookingId = isTenant ? parseInt(id || '', 10) : null;

  const navigate = useNavigate();

  const getBookingRequestByProperty = async (parameter = {}) => {
    try {
      await Utils.request(
        Apis.Booking.getBookingByPropertyId(propertyId, parameter),
      );
    } catch (err) {}
  };
  const getPropertyById = async () => {
    await Utils.request(
      Apis.Properties.getPropertyById(propertyId, user?.role),
    );
  };

  const getBooking = async bId => {
    getBookingRequest({
      method: 'get',
      url: `/v1/bookings/${bId}`,
    });
  };

  useEffect(() => {
    if (propertyId) {
      getBookingRequestByProperty({
        longList: 1,
      });
      getPropertyById();
    }
    if (bookingId) {
      getBooking(bookingId);
    }
  }, [propertyId, location]);

  useEffect(() => {
    if (booking?.id) {
      setBookingStatus(booking?.status);
    }
  }, [booking]);

  useEffect(() => {
    if (
      bookingRequested?.acceptedBooking &&
      bookingRequested?.acceptedBooking?.id
    ) {
      setBookingIdAfterTenantIsSelected(bookingRequested?.acceptedBooking?.id);
      getBooking(bookingRequested?.acceptedBooking?.id);
    } else {
      setBookingIdAfterTenantIsSelected(null);

      setShowAllRequestedTenants(true);
    }
  }, [bookingRequested]);

  useEffect(() => {
    return () => {};
  }, []);

  const handleCancel = async bId => {
    await makeCancelRequest({
      method: 'post',
      url: `/v1/bookings/${bId}/cancel`,
    });
    setCancelModalVisibility(false);
    getBooking(bId);
    toast('Booking has been cancelled successfully.');
  };

  const handleDecline = async bId => {
    await makeDeclineRequest({
      method: 'post',
      url: `/v1/bookings/${bId}/decline`,
    });
    setDeclineModalVisibility(false);
    getBooking(bId);

    toast('Booking has been declined successfully');
  };

  const handleAccept = async () => {
    await makeAcceptRequest({
      method: 'post',
      url: `/v1/bookings/${bookingId}/accept`,
    });
    getBooking(bookingId);

    toast('Booking has been accepted successfully');
  };

  const bookingEvents = booking?.bookingEvents;

  const bookingProcessActionsButton = bookingProcessActions({
    booking,
    bookingCurrentStep,
    bookingStatus,
    isLandlord,
    isSystemGenerate,
    isTenant,
    cancelLoading,
    declineLoading,
    setCancelModalVisibility,
    setDeclineModalVisibility,
    setContractGenerateVisible,
    setDepositeVisible,
    setManulGenerateUpdateVisible,
    setPaymentRentRelease,
    setSignModalVisibility,
  });

  const bookingProcessActionStatus = bookingProcessStatus({
    isLandlord,
    isTenant,
    booking,
    bookingStatus,
    bookingCurrentStep,
    isSystemGenerate,
  });

  const propDetails = isLandlord
    ? propertyDetails?.property
    : booking?.property;

  const propertyImages = propDetails?.images.map(image => image.URL);

  return (
    <div>
      <GoBack />
      <Container>
        {/* Component Loading */}
        {(propertyLoading || bookingLoading) && <BookingLoadingView />}
        {booking &&
          booking?.id &&
          (isTenant || (isLandlord && !!bookingIdAfterTenantIsSelected)) && (
            <SimpleBookingSteps booking={booking} />
          )}
        {propDetails?.id && (
          <>
            <ImageSwiper images={propertyImages} containerHeight="250px" />
            <PropertyInfo
              id={propDetails?.id}
              address={propDetails?.address}
              area={propDetails?.area}
              bathrooms={propDetails?.bathrooms}
              bedrooms={propDetails?.bedrooms}
              berNo={propDetails?.berNo}
              furnishedType={propDetails?.furnishedType}
              propertyType={propDetails?.propertyType}
              rentalMarket={propDetails?.rentalMarket}
            />
          </>
        )}

        {booking?.property?.address &&
          (isTenant || (isLandlord && !!bookingIdAfterTenantIsSelected)) && (
            <>
              <BookingDetailsStyled>
                <LeftBox>
                  {/* Status Display */}
                  {bookingProcessActionStatus.length > 0 &&
                    bookingProcessActionStatus.map((item: any) => {
                      let color = '';
                      if (item.status === 'Completed') {
                        color = 'green';
                      } else if (item.status === 'Declined' || 'Cancelled') {
                        color = 'red';
                      } else if (item.status === 'Contract Issued') {
                        color = 'orange';
                      }
                      return (
                        <div style={{ maxWidth: '100%', marginBottom: '24px' }}>
                          <Alert
                            // message="Booking Status"
                            description={item.title}
                            showIcon
                            type={item.type}
                          />
                        </div>
                      );
                    })}
                  {isTenant && (
                    <SelectedTenentView
                      booking={booking}
                      isLandlord={isLandlord}
                      user={user}
                      getBooking={() => getBooking(bookingId)}
                    />
                  )}
                  {isLandlord && !!bookingIdAfterTenantIsSelected && (
                    <SelectedTenentView
                      booking={booking}
                      isLandlord={isLandlord}
                      user={user}
                      getBooking={() => getBooking(bookingId)}
                    />
                  )}
                </LeftBox>
                <RightBox>
                  {booking?.status !== 'Completed' &&
                    bookingProcessActionsButton?.length == 0 && (
                      <>
                        <InfoLabel
                          variant="p"
                          style={{
                            fontSize: '1.3rem',
                            fontWeight: 500,
                            margin: '6px 0px',
                          }}
                        >
                          Actions
                        </InfoLabel>
                        <Info variant="p">No actions allowed.</Info>
                      </>
                    )}
                  {bookingProcessActionsButton?.length > 0 && (
                    <>
                      <InfoLabel
                        variant="p"
                        style={{
                          fontSize: '1.3rem',
                          fontWeight: 500,
                          margin: '6px 0px',
                        }}
                      >
                        Actions
                      </InfoLabel>
                      <BookingActionsStyled>
                        {bookingProcessActionsButton?.map(item => {
                          return (
                            <StyledButton
                              onClick={item.onClick}
                              isLoading={item.loading}
                              variant={
                                item.color === 'danger' ? 'danger' : 'primary'
                              }
                            >
                              {item.title}
                            </StyledButton>
                          );
                        })}
                      </BookingActionsStyled>
                    </>
                  )}
                  <UserActionsStyled>
                    {booking?.status === 'Completed' && (
                      <>
                        <Button
                          onClick={() => {
                            navigate('/jobs/add', {
                              state: {
                                type: 'tenantCreateJob',
                                propertyId: booking?.propertyId,
                                propertyAddress: booking?.property?.address,
                              },
                            });
                          }}
                          radius="round"
                        >
                          Create Job
                        </Button>

                        {isTenant && (
                          <>
                            {isDesktop && <Divider space="6px 10px" />}
                            <MyPropertyReport
                              id={booking?.propertyId}
                              serviceType={'property'}
                              ownerId={ownerId}
                            />
                          </>
                        )}
                      </>
                    )}
                  </UserActionsStyled>
                </RightBox>
              </BookingDetailsStyled>
            </>
          )}

        {isLandlord && !!bookingIdAfterTenantIsSelected && <Divider />}

        {/* Tenants List */}
        {isLandlord && showAllRequestedTenants && (
          <TenantsListWithTabs
            propertyId={propertyId}
            disableActions={!!bookingIdAfterTenantIsSelected}
            loading={bookingRequestByPropertyLoading}
            bookingRequested={bookingRequested}
            getBookingRequestByProperty={getBookingRequestByProperty}
          />
        )}
        {isLandlord && !!bookingIdAfterTenantIsSelected && (
          <ButtonWrapperStyled>
            <Button
              size="sm"
              radius="round"
              onClick={() =>
                setShowAllRequestedTenants(!showAllRequestedTenants)
              }
            >
              {showAllRequestedTenants ? 'Hide' : 'Show'} All Requested Tenants
            </Button>
          </ButtonWrapperStyled>
        )}
      </Container>

      {/* MODALS */}

      <SecurityDepositModal
        booking={booking}
        getBooking={() => getBooking(bookingId)}
        isDepositVisible={isDepositVisible}
        onCancel={() => setDepositeVisible(false)}
        user={user}
      />
      {/* TIMELINE MODAL */}

      {/* CONTRACT SIGN */}
      <ContractSignModal
        isVisible={isSignModalVisible}
        onCancel={() => setSignModalVisibility(false)}
        bookingId={isLandlord ? bookingIdAfterTenantIsSelected : bookingId}
        getBooking={() =>
          getBooking(isLandlord ? bookingIdAfterTenantIsSelected : bookingId)
        }
      />
      {/* CANCEL BOOKING */}
      <CancelBookingModal
        open={isCancelModalVisible}
        title="Are you sure you want to cancel this booking?"
        onClose={() => setCancelModalVisibility(false)}
        onCancelBooking={() => handleCancel(bookingId)}
        cancelLoading={cancelLoading}
      />

      {/* DECLINE BOOKING */}
      <DeclineBookingModal
        title="Are you sure you want to decline this booking?"
        open={isDeclineModalVisible}
        declineLoading={declineLoading}
        onClose={() => setDeclineModalVisibility(false)}
        onDeclineBooking={() => handleDecline(bookingIdAfterTenantIsSelected)}
      />

      <ContractMethodSelectionModal
        bookingId={bookingIdAfterTenantIsSelected}
        isVisible={isContractGenerateVisible}
        enableManualContractGenerate={setManualGenerateVisible}
        onCancel={() => setContractGenerateVisible(false)}
      />

      <GenerateManualContractModal
        booking={booking}
        bookingId={bookingIdAfterTenantIsSelected}
        getBooking={() => getBooking(bookingIdAfterTenantIsSelected)}
        isVisible={isManualGenerateVisible}
        onCancel={() => setManualGenerateVisible(false)}
      />

      <UpdateManualContractModal
        booking={booking}
        bookingId={bookingIdAfterTenantIsSelected}
        getBooking={() => getBooking(bookingIdAfterTenantIsSelected)}
        isVisible={isManualGenerateUpdateVisible}
        onCancel={() => setManulGenerateUpdateVisible(false)}
      />
      <RentPaymentModal
        booking={booking}
        getBooking={() => getBooking(bookingId)}
        isVisible={isPaymentRentRelease}
        onCancel={() => setPaymentRentRelease(false)}
      />
    </div>
  );
};

export default BookingView;
