import React from 'react';
import styled from 'styled-components';
import { devices } from '@/utils/theme';
import ImageWithFallBack from './ImageWithFallBack';
import Typography from './Typography';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultUser from '../assets/defaultuser.png';
import { useNavigate } from 'react-router-dom';
import { useRoot } from '@/RootProvider';
interface BookingLargeCardProps {
  item: any;
  active: string;
}

const BookingItem = styled.div`
  display: flex;
  column-gap: 20px;
  cursor: pointer;
`;
const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 10px;
  width: calc(100% - 240px);

  @media ${devices.mobileSM} {
    padding: 20px 0;
    gap: 16px;
  }
`;
const Image = styled.div`
  width: 100%;
  height: 153px;
  border-radius: 10px;
  @media ${devices.mobileSM} {
    width: 240px;
  }
`;
const Address = styled(Typography)`
  font-size: 18px;
  font-style: italic;
  font-weight: 500;
`;
const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const RequestItems = styled.div``;
const RequestItem = styled.div`
  display: flex;
  column-gap: 5px;
  align-items: center;
`;
const BookingInfo = styled(Typography)`
  font-size: 12px;
`;
const NavigateBooking = styled.a`
  background: ${({ theme }) => theme.colors.primary};
  color: #fff !important;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border-radius: 20px;
  height: 20px;
  font-size: 14px;
`;
const RatingWrapper = styled.div`
  display: flex;
  column-gap: 5px;
`;
const Status = styled(Typography)`
  color: #fff !important;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border-radius: 20px;
  height: 20px;
  font-size: 14px;
  color: ${({ theme }) =>
    theme?.colors?.statusColors?.['Completed']?.text} !important;
  background: ${({ theme }) => theme?.colors?.statusColors?.['Completed']?.bg};
`;
const BookedStatus = styled(Typography)<any>`
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border-radius: 20px;
  height: 20px;
  font-size: 12px;
  color: ${({ theme, status }) =>
    theme?.colors?.statusColors?.[status]?.text} !important;
  background: ${({ theme, status }) =>
    theme?.colors?.statusColors?.[status]?.bg};
`;
const UserImage = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
`;
const BookingLargeCard = ({ item, active }: BookingLargeCardProps) => {
  const navigate = useNavigate();
  const { auth: user } = useRoot();

  const handleNavigate = () => {
    if (active === 'requests') {
      navigate(`/bookings/${item?.propertyId}`);
    } else {
      if (user?.role === 'landlord') {
        navigate(`/bookings/${item?.property?.id}`);
      } else {
        navigate(`/bookings/${item?.id}`);
      }
    }
  };
  console.log({ item });

  return (
    <BookingItem key={item?.id} onClick={handleNavigate}>
      <Image>
        <ImageWithFallBack
          src={
            active === 'completed' || user?.role === 'tenant'
              ? Array.isArray(item?.property?.images) &&
                item?.property?.images.length > 0 &&
                item?.property?.images[0]?.URL
              : Array.isArray(item?.propertyImages) &&
                item?.propertyImages?.length > 0 &&
                item?.propertyImages[0]
          }
        />
      </Image>
      <Description>
        <Address variant="h2" color="#1f1f1f">
          {item?.propertyAddress || item?.property?.address}
        </Address>
        {active === 'requests' ? (
          <RowWrapper>
            <RequestItems>
              <RequestItem>
                <BookingInfo variant="p" color="#1f1f1f">
                  Total Requests :
                </BookingInfo>
                <BookingInfo variant="p" color="#1f1f1f">
                  {item?.totalRequest}
                </BookingInfo>
              </RequestItem>
              <RequestItem>
                <BookingInfo variant="p" color="#1f1f1f">
                  News Requests :
                </BookingInfo>
                <BookingInfo variant="p" color="red">
                  {item?.newRequest} unread
                </BookingInfo>
              </RequestItem>
              <RequestItem>
                <BookingInfo variant="p" color="#1f1f1f">
                  Last Request :
                </BookingInfo>
                <BookingInfo variant="p" color="#1f1f1f">
                  {moment(item?.lastRequest).fromNow()}
                </BookingInfo>
              </RequestItem>
            </RequestItems>
            <NavigateBooking>Manage Requests</NavigateBooking>
          </RowWrapper>
        ) : active === 'completed' ? (
          <RowWrapper>
            <RequestItems>
              <RequestItem>
                <BookingInfo variant="p" color="#1f1f1f">
                  Tenant :
                </BookingInfo>
                <BookingInfo variant="p" color="#1f1f1f">
                  {item?.tenant?.fullName}
                </BookingInfo>
              </RequestItem>
              <RequestItem>
                <BookingInfo variant="p" color="#1f1f1f">
                  Rating :
                </BookingInfo>
                <RatingWrapper>
                  {Array(5)
                    .fill(0)
                    ?.map((i, index) => (
                      <FontAwesomeIcon
                        key={index}
                        icon="star"
                        color={
                          index <= item?.tenant?.rating ? '#efe410' : '#ddd'
                        }
                        style={{ fontSize: 10 }}
                      />
                    ))}
                </RatingWrapper>
              </RequestItem>
              <RequestItem>
                <BookingInfo variant="p" color="#1f1f1f">
                  Ends :
                </BookingInfo>
                <BookingInfo variant="p" color="#1f1f1f">
                  {moment(item?.contract?.startDate).format('DD/MM/YYYY')} -{' '}
                  {moment(item?.contract?.endDate).format('DD/MM/YYYY')}
                </BookingInfo>
              </RequestItem>
            </RequestItems>
            <Status variant="p" color="#fff">
              Completed
            </Status>
          </RowWrapper>
        ) : (
          <>
            <RowWrapper>
              <RequestItems>
                <RequestItem style={{ columnGap: 10 }}>
                  <BookingInfo variant="p" color="#1f1f1f">
                    <UserImage src={item?.landlord?.avatar || DefaultUser} />
                  </BookingInfo>
                  <BookingInfo
                    variant="p"
                    color="#1f1f1f"
                    style={{ fontSize: 14 }}
                  >
                    {item?.landlord?.fullName}
                    <RatingWrapper style={{ marginTop: 5 }}>
                      {Array(5)
                        .fill(0)
                        ?.map((i, index) => (
                          <FontAwesomeIcon
                            key={index}
                            icon="star"
                            color={
                              index <= item?.tenant?.rating ? '#2061F8' : '#ddd'
                            }
                            style={{ fontSize: 10 }}
                          />
                        ))}
                    </RatingWrapper>
                  </BookingInfo>
                </RequestItem>
              </RequestItems>
              <BookedStatus variant="p" status={item.status}>
                {item?.status}
              </BookedStatus>
            </RowWrapper>
            <Typography variant="p" color="#1f1f1f">
              {moment(item?.createdAt).fromNow()}
            </Typography>
          </>
        )}
      </Description>
    </BookingItem>
  );
};

export default BookingLargeCard;
