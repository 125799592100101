import React, { useState, useEffect } from 'react';
import Modal from '@/newComponents/Modal';
import Button from '@/newComponents/Button';
import { Formik, Form, Field } from 'formik';
import { ModalFooter } from '../style';
import * as yup from 'yup';
import InputField from '@/components/InputField';
import { toast } from 'react-toastify';
import { Store, Utils, Apis } from '@shared/front';
import { useSelector } from 'react-redux';
import PropertyInfo from '../PropertyInfo';
interface RoomModalProps {
  setOpen: any;
  open: boolean;
  propertyId: number;
  isEditMode?: boolean;
  setIsEditMode?: any;
  setSelectedItem;
  selectedItem;
}
const createRoomSchema = yup.object().shape({
  rules: yup.string().required('Title is required.'),
});
const PropertyRulesModal = ({
  setOpen,
  open,
  propertyId,
  isEditMode,
  setIsEditMode,
  selectedItem,
  setSelectedItem,
}: RoomModalProps) => {
  // console.log(selectedItem?.id, 'propertyIdpropertyIdtest');
  const [initialValues, setInitialValues] = useState({
    rules: '',
    id: '',
  });
  const property = useSelector(Store.Select.property);
  const [loading, setLoading] = useState<boolean>(false);
  const handleCreateOrUpdatePolicy = async (
    values: any,
    categoryId: number,
  ) => {
    console.log(selectedItem?.id, 'selectitemid');
    setLoading(true);
    try {
      console.log(propertyId, 'selectedItemselectedItem');
      const payload = { rules: [values.rules] };
      const rulesID = { id: [values.id] };
      // console.log(rulesID, 'rilesid');
      // const resp = await Utils.request(
      //   Apis.Properties.updatePropertyRules(payload, propertyId), // Always pass propertyId as the second argument
      // );
      // const updateResponse = await Utils.request(
      //   Apis.Properties.updatePropertyRules(payload, selectedItem?.id), // Always pass propertyId as the second argument
      // );
      // console.log(selectedItem?.id, 'selectedItem');
      const resp = await Utils.request(
        !!isEditMode
          ? Apis.Properties.updatePropertyRules(payload, selectedItem?.id)
          : Apis.Properties.updatePropertyRules(payload, propertyId),
      );

      if (isEditMode) {
        Store.store.dispatch(
          Store.Actions.update('property', (currentData: any) => {
            const findIndex = currentData?.findIndex(
              (item: any) => item.id === selectedItem?.id,
            );
            const updateData = [...currentData];
            updateData[findIndex] = {
              ...updateData[findIndex],
              rules: values?.rules,
            };
            return updateData;
          }),
        );
      } else {
        // console.log(resp);
        let updateProperty: any = property;
        let propertyInfo: any = updateProperty?.property;
        const currentPropertyRules = updateProperty?.property?.propertyRules;
        const propertyRules: any[] = [...resp, ...currentPropertyRules];
        propertyInfo = { ...propertyInfo, propertyRules: propertyRules };
        updateProperty = { ...updateProperty, property: propertyInfo };
        // console.log(propertyInfo, 'store');
        Store.store.dispatch(Store.Actions.update('property', updateProperty));
      }

      setOpen(false);
      setLoading(false);
      setIsEditMode(false);
      setSelectedItem({});
      toast.success('Rules created successfully');
    } catch (err) {
      toast.error('Failed');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!!isEditMode) {
      setInitialValues({
        rules: selectedItem?.rules,
      });
    }
  }, [selectedItem]);

  return (
    <>
      {!!open && (
        <Modal
          isCentered
          title={`${isEditMode ? 'Update' : 'Add'} Rules `}
          open={open}
          onClose={() => {
            setOpen(false);
            setSelectedItem({});
            setIsEditMode(false);
          }}
          size={'sm'}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={createRoomSchema}
            onSubmit={async (values, { resetForm }) => {
              await handleCreateOrUpdatePolicy(values);
              resetForm();
            }}
            validateOnBlur={false}
            enableReinitialize
          >
            {({ values, handleSubmit, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <Field
                  component={InputField}
                  label="Title"
                  name="rules"
                  type="text"
                  placeholder="Title"
                  value={values?.rules}
                />

                <ModalFooter>
                  <Button isLoading={loading} style={{ marginLeft: 'auto' }}>
                    Submit
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};

export default PropertyRulesModal;
