import React from 'react';
import Modal from '@/newComponents/Modal';
import { ButtonsContainer, StyledButton } from './modalStyles';

type Props = {
  title: string;
  open: boolean;
  cancelLoading: boolean;
  onClose: () => void;
  onCancelBooking: () => void;
};

const CancelBookingModal = ({
  title,
  open,
  cancelLoading,
  onClose,
  onCancelBooking,
}: Props) => {
  return (
    <Modal open={open} title={title} onClose={onClose}>
      <ButtonsContainer>
        <StyledButton radius="round" variant="gray" onClick={onClose}>
          No, take me back
        </StyledButton>
        <StyledButton
          radius="round"
          variant="danger"
          isLoading={cancelLoading}
          onClick={onCancelBooking}
        >
          Cancel Booking
        </StyledButton>
      </ButtonsContainer>
    </Modal>
  );
};

export default CancelBookingModal;
