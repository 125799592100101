import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Typography from './Typography';
import Rating from 'react-rating';

interface RateProps {
  value: number;
  disabled?: boolean;
  isLong?: boolean;
}

interface ContainerProps {
  disabled?: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 24px;
  color: ${props => (props.disabled ? '#fcba03' : '#fcba03')};
`;

const CustomRate: React.FC<RateProps> = ({
  value,
  disabled,
  isLong = false,
}) => {
  if (isLong) {
    return (
      <>
        {/*@ts-ignore*/}
        <Rating
          emptySymbol={
            <FontAwesomeIcon
              icon={['far', 'star']}
              size="lg"
              style={{ margin: '0px 2px' }}
            />
          }
          fullSymbol={
            <FontAwesomeIcon
              icon={['fas', 'star']}
              color="#F1C40F"
              size="lg"
              style={{ margin: '0px 2px' }}
            />
          }
          initialRating={value}
          readonly
        />
      </>
    );
  }
  return (
    <Container disabled={disabled}>
      {value === 0 ? (
        <FontAwesomeIcon
          icon={['far', 'star']}
          style={{ opacity: 1, width: '18px' }}
        />
      ) : (
        <FontAwesomeIcon
          icon={['fas', 'star']}
          style={{ opacity: 1, width: '18px' }}
        />
      )}
      <Typography variant="span" color="#000000" size="sm">
        {value?.toFixed(1)}
      </Typography>
    </Container>
  );
};

export default CustomRate;
