import { useRoot } from '@/RootProvider';
import Typography from '@/newComponents/Typography';
import React from 'react';
import styled from 'styled-components';
import LandlordBookingList from './LandLordBookingList';
import TenantBookingList from './TenantBookingList';

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 60px;
  background-color: white;
  z-index: 99;
  background: #fff;
  & .title {
    font-size: 1.6rem;
    font-weight: 500;
  }
  padding: 20px 0px 10px 0px;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 20px;
`;

interface BookingsProps {}
const Bookings = ({}: BookingsProps) => {
  const { auth: user } = useRoot();

  return (
    <>
      <TitleContainer>
        <Typography className="title" variant="p">
          Bookings
        </Typography>
      </TitleContainer>
      {user?.role === 'landlord' ? (
        <LandlordBookingList />
      ) : (
        <TenantBookingList />
      )}
    </>
  );
};
export default Bookings;
