import { SET, RESET, UPDATE } from './constants';
import initialState from './initialState';

export function set(key: keyof typeof initialState, data: any) {
  return {
    type: SET,
    data,
    key,
  };
}

export function reset(key: keyof typeof initialState) {
  return {
    type: RESET,
    data: null,
    key,
  };
}

export function update(key: keyof typeof initialState, data: any) {
  return {
    type: UPDATE,
    data,
    key,
  };
}
