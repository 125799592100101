import Input from '@/newComponents/Input';
import Modal from '@/newComponents/Modal';
import Select from '@/newComponents/Select';
import { Constants } from '@shared/common';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { ButtonsContainer, StyledButton } from './styles';
const FilterModel = ({
  isModalOpen,
  initialValues,
  onClose,
  onSubmit,
  formikRef,
}) => {
  return (
    <Modal title="Filters" open={isModalOpen} onClose={onClose}>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        onSubmit={values => {
          onSubmit(values);
        }}
      >
        {({ values, setFieldValue, errors }) => {
          return (
            <Form>
              <Field
                name="bedrooms"
                label="Bedrooms"
                component={Input}
                type="number"
                min={1}
                max={40}
                onChange={e => setFieldValue('bedrooms', e.target.value)}
                errors={errors.bedrooms}
              />
              <Field
                name="bathrooms"
                label="Bathrooms"
                component={Input}
                type="number"
                min={1}
                max={10}
                onChange={e => setFieldValue('bathrooms', e.target.value)}
                errors={errors.bathrooms}
              />
              <Field
                component={Select}
                options={[
                  {
                    label: 'All',
                    value: 'all',
                    selected: true,
                    disabled: true,
                  },
                  ...Constants.PropertyDropdown.propertyTypeOptions(),
                ]}
                label="Property Type"
                name="propertyType"
                htmlType="select"
                value={values.propertyType}
                onChange={e => setFieldValue('propertyType', e)}
              />
              <Field
                component={Select}
                options={[
                  {
                    label: 'All',
                    value: 'all',
                    selected: true,
                    disabled: true,
                  },
                  ...Constants.PropertyDropdown.sort(),
                ]}
                label="Sort By"
                name="sort"
                htmlType="select"
                value={values.sort}
                onChange={e => setFieldValue('sort', e)}
              />
              <Field
                component={Select}
                options={[
                  {
                    label: 'All',
                    value: 'all',
                    selected: true,
                    disabled: true,
                  },
                  ...Constants.PropertyDropdown.furnishedTypeOption(),
                ]}
                label="Furnished Type"
                name="furnishedType"
                htmlType="select"
                value={values.furnishedType}
                onChange={e => setFieldValue('furnishedType', e)}
              />
              <Field
                component={Select}
                options={[
                  {
                    label: 'All',
                    value: 'all',
                    selected: true,
                    disabled: true,
                  },
                  ...Constants.PropertyDropdown.rentalMarketOptions(),
                ]}
                label="Rental Market"
                name="rentalMarket"
                htmlType="select"
                value={values.rentalMarket}
                onChange={e => setFieldValue('rentalMarket', e)}
              />
              <ButtonsContainer>
                <div style={{ display: 'flex', gap: '16px' }}>
                  <StyledButton radius="round" variant="gray" onClick={onClose}>
                    Cancel
                  </StyledButton>
                  <StyledButton
                    radius="round"
                    onClick={() => {
                      formikRef.current?.resetForm();
                    }}
                  >
                    Reset
                  </StyledButton>
                </div>
                <StyledButton htmlType="submit" radius="round">
                  Submit
                </StyledButton>
              </ButtonsContainer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
    // }
    //   </>
  );
};

export default FilterModel;
