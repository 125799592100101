import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Apis, Store, Utils } from "../../front";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getWeekDays } from "../../front/utils/calender";
import { ColunmAlignItemsJustifyCenter } from "../../web/common/style";
import { devices, sharedTheme } from "../../front/theme";

const theme = sharedTheme;

const ButtonIcon = styled.button`
  border: none;
  border-radius: 0;
  background-color: transparent;
`;

const Container = styled.div`
  width: 100%;
  padding: 0px 0px;

  @media ${devices.mobileXS} {
    padding: 0px 10px;
  }
`;
const DayView = styled.div`
  background: #e0e0e0;
  width: 100%;
  padding: 10px 0px 0px;
  height: 100px;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 5px;
  ${ColunmAlignItemsJustifyCenter}
  justify-content: center;

  &.active {
    background-color: ${theme.colors.primary};
    * {
      color: ${theme.colors.white} !important;
    }
  }
`;
const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const CalenderWrapper = styled.div`
  margin: 10px 0px;
  padding: 10px 0px;
  display: flex;
  align-items: center;
  gap: 8px;

  @media ${devices.mobileXS} {
    gap: 20px;
  }
`;

const DayNameMapping = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const Calendar = ({}) => {
  const appointments: any[] = useSelector(Store.Select.myAppointments);

  const navigate = useNavigate();
  useEffect(() => {
    const getMyAppointments = async () => {
      await Utils.request(Apis.Notification.getMyAppointments());
    };
    getMyAppointments();
  }, []);
  const [activeWeekDay, setActiveWeekDay] = useState(moment().toLocaleString());

  const weekdays = getWeekDays(moment(activeWeekDay).toLocaleString());
  const ref = moment();

  return (
    <Container>
      <SliderContainer>
        <ButtonIcon
          onClick={() => {
            navigate("/calendar");
          }}
        >
          <FontAwesomeIcon
            icon={["fas", "calendar-week"]}
            color={theme.colors.primary}
            size="xl"
          />
        </ButtonIcon>
        <p style={{ margin: "0px", fontSize: "16px", fontWeight: "500" }}>
          Event in this week
        </p>
      </SliderContainer>
      <CalenderWrapper>
        {weekdays.map((day, index) => {
          const date = day.format("YYYY-MM-DD");
          const findAppointmentDate = appointments.find(
            (appointment) =>
              date === moment(appointment.appoinmentDate).format("YYYY-MM-DD")
          );

          const isEvent = findAppointmentDate ? true : false;

          return (
            <DayView
              key={`${day}${index}`}
              className={ref.format("YYYY-MM-DD") === date ? "active" : ""}
              style={{
                backgroundColor: isEvent
                  ? "lightGreen"
                  : ref.format("YYYY-MM-DD") === date
                  ? "purpleBlue"
                  : "",
              }}
              onClick={() => navigate("/calendar")}
            >
              <p style={{ fontSize: "1.2rem", fontWeight: 500 }}>
                {DayNameMapping[index]}
              </p>
              {isEvent && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <FontAwesomeIcon
                    icon={["fas", "flag"]}
                    style={{ color: "red" }}
                  />
                </div>
              )}
              <p style={{ color: isEvent ? "red" : "" }}>{day.format("DD")}</p>
            </DayView>
          );
        })}
      </CalenderWrapper>
    </Container>
  );
};

export default Calendar;
