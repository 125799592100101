import { ReturnType } from "./types";
interface GetPropertiesParameter {
  params?: any;
  role?: string;
  action?: "set" | "update" | null | undefined;
  type?: "my" | "browse";
}
export default {
  getProperties: ({
    params,
    role,
    action,
    type,
  }: GetPropertiesParameter): ReturnType => {
    let url;
    if (role) {
      url =
        role === "tenant" || type === "browse"
          ? "/v1/properties/search-properties"
          : "/v1/properties";
    } else {
      url = "/v1/properties/search-properties";
    }
    return {
      method: "get",
      url: url,
      params: params,
      config: {
        store: {
          action: action || "set",
          key: type === "browse" ? "browseProperties" : "properties",
        },
      },
    };
  },
  scrapePropertiesUrl: (data: { link: string }): ReturnType => {
    return {
      method: "post",
      url: "/v1/properties/scrape",
      data,
      config: {
        store: {
          action: "set",
          key: "scrapeProperty",
        },
      },
    };
  },

  scrapeImagesToS3: (data: { link: string }): ReturnType => {
    return {
      method: "post",
      url: "/v1/common/url-for-scraped-img",
      data,
    };
  },

  getAllBrowserPropertiesForWeb: ({ params, action }: any): ReturnType => {
    return {
      method: "get",
      url: "/v1/properties/search-properties",
      params: params,
      config: {
        store: {
          action: action || "set",
          key: "webbrowseproperties",
        },
      },
    };
  },

  getMyProperties: (): ReturnType => {
    return {
      method: "get",
      url: "/v1/properties",
      params: {
        page: 0,
        filter: "active",
      },
    };
  },

  getSimilarProperties: (id: number): ReturnType => {
    return {
      method: "get",
      url: `/v1/properties/${id}/similar-properties`,
      config: {
        store: {
          action: "set",
          key: "similarProperties",
        },
      },
    };
  },

  getPropertyById: (id: number | undefined | null, role?: any): ReturnType => {
    return {
      method: "get",
      url: role ? `/v1/properties/${id}` : `/v1/properties/${id}/guests`,
      config: {
        store: {
          action: "set",
          key: "property",
        },
      },
    };
  },
  generateDescription: (data: any): ReturnType => {
    return {
      method: "post",
      url: `/v1/properties/generate-description`,
      data: data,
    };
  },

  createOrUpdateProperty: (
    obj: any,
    id?: number | undefined | null
  ): ReturnType => {
    return {
      method: id ? "put" : "post",
      url: `/v1/properties/${id || ""}`,
      data: obj,
    };
  },

  deleteProperty: (id: number): ReturnType => {
    return {
      method: "delete",
      url: `/v1/properties/${id}`,
      config: {
        successMsg: `Property has been deleted`,
      },
    };
  },

  updatePropertyFeature: (data: any, id: any): ReturnType => {
    return {
      method: "put",
      url: `/v1/properties/${id ? id + "/" : ""}features`,
      data: data,
    };
  },

  updatePropertyPhotos: (data: any, id: number): ReturnType => {
    return {
      method: "put",
      url: `/v1/properties/${id}/photos`,
      data: data,
      config: {
        showErr: true,
      },
    };
  },
  deletePropertyPhotos: (id: number): ReturnType => {
    return {
      method: "delete",
      url: `/v1/properties/${id}/photos`,
      config: {
        successMsg: `Property Image deleted successfully`,
      },
    };
  },

  updatePropertyProvider: (data: any, id?: number): ReturnType => {
    return {
      method: "put",
      url: `/v1/properties/${id ? id + "/" : ""}providers`,
      data: data,
    };
  },

  createPropertyItinerary: (data: any): ReturnType => {
    return {
      method: "post",
      url: `/v1/properties/itineraries`,
      data: data,
      config: {
        successMsg: `Property Inventory created successfully`,
        navigateBack: true,
        showErr: true,
      },
    };
  },

  getPropertyItineraries: (propertyId: number): ReturnType => {
    return {
      method: "get",
      url: `/v1/properties/${propertyId}/itineraries`,
      config: {
        store: {
          action: "set",
          key: "itineraries",
        },
      },
    };
  },
  getPropertyItineriesById: (ItineraryId: number): ReturnType => {
    return {
      method: "get",
      url: `/v1/properties/itineraries/${ItineraryId}`,
      config: {
        store: {
          action: "set",
          key: "itineraryDetails",
        },
      },
    };
  },

  getPropertyInventoryByCategoryId: (categoryId: number): ReturnType => {
    return {
      method: "get",
      url: `/v1/properties/itinerary-category-id/${categoryId}`,
      config: {
        store: {
          action: "set",
          key: "itineraries",
        },
      },
    };
  },

  addItineraryImages: (data?: any, id?: number): ReturnType => {
    return {
      method: "post",
      url: `/v1/properties/itineraries/${id}/images`,
      data: data,
    };
  },

  updatePropertyItineries: (data?: any, id?: number): ReturnType => {
    return {
      method: "patch",
      url: `/v1/properties/itineraries/${id}`,
      data: data,
    };
  },

  deletePropertyItinerary: (id: number): ReturnType => {
    return {
      method: "delete",
      url: `/v1/properties/itineraries/${id}`,
    };
  },

  //For api Inventory Category
  getInventoryCategories: (propertyId: number): ReturnType => {
    return {
      method: "get",
      url: `/v1/properties/${propertyId}/itinerary-category`,
      config: {
        store: {
          action: "set",
          key: "inventoryCategories",
        },
      },
    };
  },

  addInventoryCategory: (data: any): ReturnType => {
    return {
      method: "post",
      url: `/v1/properties/itinerary-category`,
      data,
      config: {
        successMsg: `Created Successfully`,
      },
    };
  },

  deleteInventoryCategory: (categoryId: number): ReturnType => {
    return {
      method: "delete",
      url: `/v1/properties/itinerary-category/${categoryId}`,
      config: {
        successMsg: "Deleted successfully",
      },
    };
  },

  updateInventoryCategory: (categoryId: number, data: any): ReturnType => {
    return {
      method: "patch",
      url: `/v1/properties/itinerary-category/${categoryId}`,
      data,
      config: {
        successMsg: "Updated successfully",
      },
    };
  },

  //Property Policy
  addPropertyPolicy: (data: any): ReturnType => {
    return {
      method: "post",
      url: `/v1/properties/policies`,
      data,
      config: {
        successMsg: `Property Policy Created successfully`,
      },
    };
  },
  updatePropertyPolicy: (categoryId: number, data: any): ReturnType => {
    return {
      method: "patch",
      url: `/v1/properties/policies/${categoryId}`,
      data,
      config: {
        successMsg: "Property Policy Updated successfully",
      },
    };
  },
  getPolicies: (propertyId: number): ReturnType => {
    return {
      method: "get",
      url: `/v1/properties/${propertyId}/policies`,
      config: {
        store: {
          action: "set",
          key: "policies",
        },
      },
    };
  },
  deletePolicy: (categoryId: number): ReturnType => {
    return {
      method: "delete",
      url: `/v1/properties/policies/${categoryId}`,
      config: {
        successMsg: "Deleted successfully",
      },
    };
  },

  //For now in property details unused code
  deletePropertyPolicy: (id: number): ReturnType => {
    return {
      method: "delete",
      url: `/v1/properties/policies/${id}`,
      config: {
        successMsg: `Property Policy deleted successfully`,
      },
    };
  },

  //Rules for Api
  // addPropertyRules: (data: any): ReturnType => {
  //   return {
  //     method: "post",
  //     url: `/v1/properties/updatePropertyRules/`,
  //     data,
  //     config: {
  //       successMsg: `Property Policy Created successfully`,
  //     },
  //   };
  // },

  // addUpdatePropertyRules: (propertyId: number, data: any): ReturnType => {
  //   return {
  //     method: "post",
  //     url: `/v1/properties/updatePropertyRules/${propertyId}`,
  //     data,
  //     config: {
  //       successMsg: "Property Rules Updated successfully",
  //     },
  //   };
  // },
  // updatePropertyPolicy: (categoryId: number, data: any): ReturnType => {
  //   return {
  //     method: "patch",
  //     url: `/v1/properties/updatePropertyPolicies/${categoryId}`,
  //     data,
  //     config: {
  //       successMsg: "Property Policy Updated successfully",
  //     },
  //   };
  // },
  updatePropertyRules: (data: any, propertyId: number): ReturnType => {
    return {
      method: "post",
      url: `/v1/properties/${propertyId}/rules`,
      data: data,
    };
  },
  deletePropertyRules: (id: number): ReturnType => {
    return {
      method: "delete",
      url: `/v1/properties/${id}/rules`,
    };
  },
  // deletePolicy: (categoryId: number): ReturnType => {
  //   return {
  //     method: "delete",
  //     url: `/v1/properties/deletePropertyPolicies/${categoryId}`,
  //     config: {
  //       successMsg: "Deleted successfully",
  //     },
  //   };
  // },
  getWatchListProperties: ({ action }: GetPropertiesParameter): ReturnType => {
    return {
      method: "get",
      url: `/v1/common/bookmark/properties`,
      config: {
        store: {
          action: action || "set",
          key: "properties",
        },
      },
    };
  },

  getAmenities: (): ReturnType => {
    return {
      method: "get",
      url: `/v1/admin/amenities`,
      config: {
        store: {
          action: "set",
          key: "features",
        },
      },
    };
  },

  createPropertyReferenceRoom: (data: any): ReturnType => {
    return {
      method: "post",
      url: `/v1/properties/property-ref-room`,
      data,
      config: {
        successMsg: "Create room successfully",
      },
    };
  },

  updatePropertyReferenceRoom: (data: any, roomId: number): ReturnType => {
    return {
      method: "patch",
      url: `/v1/properties/ref-room/${roomId}`,
      data,
      config: {
        successMsg: "Updated room successfully",
      },
    };
  },
  //Image
  getPropertyReferenceRoomByPropertyId(propertyId: number): ReturnType {
    return {
      method: "get",
      url: `/v1/properties/${propertyId}/property-ref-room`,
      config: {
        store: {
          action: "set",
          key: "propertiesRooms",
        },
      },
    };
  },

  deletePropertyReferenceRoomById(roomId: number): ReturnType {
    return {
      method: "delete",
      url: `/v1/properties/ref-room/${roomId}`,
      config: {
        successMsg: "Property Room deleted successfully",
      },
    };
  },
  getPropertyReferenceImageByGroupId(groupId: number): ReturnType {
    return {
      method: "get",
      url: `/v1/properties/ref-image-group/${groupId}`,
    };
  },
  uploadPropertyReferenceImage: (data: any, id: number): ReturnType => {
    return {
      method: "post",
      url: `/v1/properties/${id}/ref-photos`,
      data: data,
      config: {
        successMsg: `Property Reference Image uploded successfully`,
        showErr: true,
      },
    };
  },
  deletePropertyReferenceImage: (id: number): ReturnType => {
    return {
      method: "delete",
      url: `/v1/properties/${id}/ref-photos`,
      config: {
        successMsg: `Property Reference Image deleted successfully`,
      },
    };
  },
  deletePropertyItineriesImages: (id: number): ReturnType => {
    return {
      method: "delete",
      url: `/v1/properties/itineraries/${id}/images`,
      config: {
        successMsg: `Property itinerary image deleted successfully`,
      },
    };
  },
};
