import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useRoot } from '@/RootProvider';
import { ProfileWrapper } from './styled';
import styled from 'styled-components';
import Button from '../../Button';
import { handleLogout } from '@shared/front/utils/request';
import Divider from '../../Divider';
import Typography from '../../Typography';
import { devices } from '@/utils/theme';
import { Popover } from 'antd';
import SmLogoSVG from '../../../assets/rentisity-sm-logo.svg';

const Avatar = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 22px;
  border: 2px solid ${({ theme }) => theme.colors.border};
  overflow: hidden;
  object-fit: cover;
`;

const UserFullName = styled(Typography)`
  font-size: 14px;
  font-weight: 600;

  @media ${devices.laptop} {
    font-size: 16px;
  }
`;

const UserEmail = styled(Typography)`
  font-size: 10px;
  margin-bottom: 6px;

  @media ${devices.laptop} {
    font-size: 12px;
  }
`;

const LogoutButton = styled(Button)`
  all: unset;
  cursor: pointer;

  &:hover {
    color: #2857ff;
  }
`;

type ProfileProps = {
  user: any;
};

const TagWrapper = styled.div<{ type: string }>`
  color: ${({ type, theme }) =>
    theme.colors.statusColors[type.toLowerCase()]?.text};
  background-color: ${({ type, theme }) => theme.colors.statusColors[type]?.bg};
  text-align: center;
  border-radius: 500px;
  margin: 2px 4px;
  display: inline-flex;
  align-items: center;
  padding: 2px 10px;
  justify-content: center;
`;

const Profile = ({ user }: ProfileProps) => {
  const navigate = useNavigate();
  const { setAuth, auth } = useRoot();
  const [visible, setVisible] = useState(false);
  const handleVisibleChange = () => {
    setVisible(!visible);
  };
  const menu = (
    <>
      <Link
        to="/settings"
        hidden={auth?.deleteRequested}
        style={{ marginBottom: 10, display: 'block' }}
        onClick={handleVisibleChange}
      >
        Settings
      </Link>
      <Link
        to="/profile"
        hidden={auth?.deleteRequested}
        onClick={handleVisibleChange}
      >
        Profile
      </Link>
      {!auth?.deleteRequested && <Divider />}
      <LogoutButton
        onClick={() => {
          setAuth({});
          localStorage.removeItem('user');
          navigate('/');
          handleLogout();
        }}
      >
        Logout
      </LogoutButton>
    </>
  );
  const content = (
    <ProfileWrapper>
      <UserFullName>
        {user?.firstName} {user?.lastName}
      </UserFullName>
      <UserEmail>{user?.email}</UserEmail>
      <TagWrapper type={capitalize(user?.role)}>
        {capitalize(user?.role)}
      </TagWrapper>
      <Divider />
      {menu}
    </ProfileWrapper>
  );

  return (
    <Popover
      content={content}
      trigger="click"
      open={visible}
      onOpenChange={handleVisibleChange}
      arrowPointAtCenter={true}
      placement="bottomLeft"
      getPopupContainer={() => document.body}
    >
      <Avatar src={user.avatar || SmLogoSVG} />
    </Popover>
  );
};
export default Profile;
