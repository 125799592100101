import styled from 'styled-components';

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: sticky;
  top: 60px;
  background-color: #fff;
  z-index: 99;
  & .title {
    font-size: 1.4rem;
    font-weight: 500;
  }
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 20px;
`;

export const IndividualConditionsStyled = styled.div`
  margin-bottom: 20px;
  padding-left: 30px;
  position: relative;

  & .conditionNumber {
    position: absolute;
    left: 0px;
    top: 0px;

    font-weight: 600;
    font-size: 1.2rem;
  }

  & .title {
    margin-bottom: 4px;
    font-weight: 600;
    font-size: 1.2rem;
  }

  & .desc {
  }
`;

export const DeletionFormContainerStyled = styled.div`
  padding: 30px 30px;
  margin: 40px 0px 100px;
  background: ${({ theme }) => theme.colors.materialColors.lightRed.bg};
  border: 1px solid ${({ theme }) => theme.colors.materialColors.lightRed.text};
  border-radius: 10px;
`;
