import { DatePicker, TimePicker } from 'antd';
import React, { useState } from 'react';
import IndividualUser from '../IndividualUser';
import moment from 'moment';
import styled from 'styled-components';
import Modal from '@/newComponents/Modal';
import {
  ButtonsContainer,
  ModalInfoContainer,
  ModalMainText,
  ModalSubText,
  StyledButton,
  TenantsContainerStyled,
} from '../../bookingModals/modalStyles';

type Props = {
  selectedShortListed: number[];
  isOpen: boolean;
  onClose: () => void;
  onInviteShortListed: (values: any) => void;
  isLoading: boolean;
};

const InviteShortListedModal = ({
  isOpen,
  onClose,
  onInviteShortListed,
  selectedShortListed,
  isLoading,
}: Props) => {
  const [dateTime, setDateTime] = useState({
    date: null,
    time: null,
  });
  const [validationMessage, setValidationMessage] = useState('');

  const handleCancel = () => {
    onClose();
    setDateTime({ date: null, time: null });
    setValidationMessage('');
  };
  const handleOk = async () => {
    if (!dateTime.date || !dateTime.time) {
      setValidationMessage('Date and Time are required.');
      return;
    }
    setValidationMessage('');
    const values = {
      appointmentDate: dateTime.date,
      appointmentTime: dateTime.time,
      message: '',
    };
    await onInviteShortListed(values);

    handleCancel();
  };

  const onDateTimeChange = (name, value) => {
    setDateTime(prev => ({ ...prev, [name]: value }));
    setValidationMessage('');
  };

  return (
    <Modal
      title="Invite Short Listed Tenants"
      open={isOpen}
      onClose={handleCancel}
    >
      <ModalInfoContainer>
        {selectedShortListed && selectedShortListed.length > 0 ? (
          <>
            <ModalMainText variant="h4">
              Are you sure you want to invite short listed profile for property
              viewing?
            </ModalMainText>
            <br />
            <br />
            <ModalSubText variant="p">
              Select date and time for appointments.
            </ModalSubText>
            <DateTimeWrapperStyled>
              <DatePicker
                className="datepicker"
                onChange={(date, dateString) =>
                  onDateTimeChange('date', dateString)
                }
                format="YYYY/MM/DD"
                disabledDate={current => {
                  return moment().add(-1, 'days') >= current;
                }}
              />
              <TimePicker
                className="timepicker"
                onChange={(time, timeString) =>
                  onDateTimeChange('time', timeString)
                }
                format="hh:mm A"
              />
            </DateTimeWrapperStyled>
            {validationMessage && (
              <div style={{ color: 'red', margin: '0px 0px 30px' }}>
                {validationMessage}
              </div>
            )}
            <TenantsContainerStyled>
              {selectedShortListed.map(tenant => (
                <IndividualUser
                  tenant={tenant}
                  isReadOnly
                  isSmall
                  disableViewBtn
                />
              ))}
            </TenantsContainerStyled>
          </>
        ) : (
          <ModalSubText variant="p">
            No profiles has been moved to short list. Please move tenants from
            long list to short list before inviting.
          </ModalSubText>
        )}
      </ModalInfoContainer>
      {selectedShortListed && selectedShortListed.length > 0 && (
        <ButtonsContainer>
          <StyledButton
            variant="gray"
            radius="round"
            isDisabled={isLoading}
            onClick={handleCancel}
          >
            Cancel
          </StyledButton>
          <StyledButton radius="round" isLoading={isLoading} onClick={handleOk}>
            Invite
          </StyledButton>
        </ButtonsContainer>
      )}
    </Modal>
  );
};

export default InviteShortListedModal;

const DateTimeWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 5px 0px 10px;
  & .datepicker {
    flex: 1;
    margin-right: 10px;
  }

  & .timepicker {
    flex: 1;
    margin-left: 10px;
  }
`;
