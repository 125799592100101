import React from 'react';
import TransactionWallet1 from '../../assets/transaction1.png';
import TransactionWallet1Alt from '../../assets/transaction1-alt.png';
import TransactionWallet2 from '../../assets/transaction2.png';
import TransactionWallet2Alt from '../../assets/transaction2-alt.png';
import TransactionWallet3 from '../../assets/transaction3.png';
import TransactionWallet3Alt from '../../assets/transaction3-alt.png';
import TransactionWallet4 from '../../assets/transaction4.png';
import TransactionWallet4Alt from '../../assets/transaction4-alt.png';
import styled from 'styled-components';
import Typography from '@/newComponents/Typography';
import moment from 'moment';

const ICON_TYPE = {
  Rent: {
    debit: TransactionWallet1,
    credit: TransactionWallet1Alt,
    color: '#1677FF',
  },
  'Security Deposit': {
    debit: TransactionWallet2,
    credit: TransactionWallet2Alt,
    color: '#F1C40F',
  },
  Service: {
    debit: TransactionWallet3,
    credit: TransactionWallet3Alt,
    color: '#12BCC9',
  },
  Refund: {
    debit: TransactionWallet4,
    credit: TransactionWallet4Alt,
    color: '#42BF64',
  },
};

const IndividualTransaction = ({
  role,
  transaction,
  userId,
}: TransactionPropsType) => {
  const userType = userId === transaction?.debitedFrom ? 'debit' : 'credit';
  const isDebited = userType === 'debit';

  const constructMessage = (tran: TransactionType) => {
    let message = '';
    if (isDebited) {
      message = `Fund transfered to ${tran?.creditedUser?.firstName} ${tran?.creditedUser?.lastName}.`;
    } else {
      message = `Fund received from ${tran?.debitedUser?.firstName} ${tran?.debitedUser?.lastName}.`;
    }

    return message;
  };

  const constructTransactionReason = (tran: TransactionType) => {
    const type = tran?.transactionType;
    let message = '';

    if (!!tran?.booking) {
      message = 'Property : ' + tran?.booking?.property?.address;
    }
    if (!!tran?.serviceProposal) {
      message = 'Service : ' + tran?.serviceProposal?.service?.title;
    }

    return message;
  };

  const formatDate = (date: string) => {
    return moment(date).format('MMM DD, YYYY hh:mm A ');
  };

  return (
    <IndividualStyled $isDebited={isDebited}>
      <img
        src={ICON_TYPE[transaction.transactionType][userType]}
        alt="wallet"
      />
      <div className="container">
        <div className="message">{constructMessage(transaction)}</div>
        <div className="transaction-reason">
          {constructTransactionReason(transaction)}
        </div>
        <div className="full-date">{formatDate(transaction?.createdAt)}</div>
        <Typography
          variant="p"
          className="transaction-type"
          style={{ color: ICON_TYPE[transaction.transactionType].color }}
        >
          {transaction.transactionType}
        </Typography>
        {transaction?.remark && (
          <Typography variant="p" className="remark">
            Remark : {transaction?.remark}
          </Typography>
        )}
      </div>
      <div className="amount">&euro; {transaction?.amount}</div>
    </IndividualStyled>
  );
};

export default IndividualTransaction;

const IndividualStyled = styled.div<{ $isDebited: boolean }>`
  border-radius: 5px;
  background: var(--100, #f0f0f0);

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 20px 16px;
  margin-bottom: 8px;

  & .container {
    flex: 1;
    & .message {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 8px;
    }
    & .full-date {
      font-size: 14px;
      margin-bottom: 8px;
    }

    & .transaction-reason {
      font-size: 14px;
      margin-bottom: 8px;
    }
    & .transaction-type {
    }
    & .remark {
      color: #666;
      font-size: 14px;
    }
  }

  & .amount {
    color: ${({ $isDebited }) => ($isDebited ? 'red' : 'green')};
    font-weight: 600;
    font-size: 1.3rem;
  }
`;

export type TransactionPropsType = {
  transaction: TransactionType;
  role: 'tenant' | 'landlord' | 'service';
  userId: number;
};

export type TransactionType = {
  id: number;
  debitedFrom: number;
  creditedTo: number;
  amount: number;
  transactionType: 'Rent' | 'Security Deposit' | 'Service' | 'Refund';
  transactionTitle: string;
  bookingId?: number;
  serviceProposalId?: number;
  serviceContractId?: number;
  escrowId: number;
  escrowType: string;
  transactionCode: string;
  remark: string;
  stripeResponse: {
    id: string;
    amount: number;
    metadata: {
      name: string;
      email: string;
      phone: string;
      customerId: string;
    };
    description: string;
  };
  capture_method: string;
  amount_received: number;
  createdAt: string;
  updatedAt: string;
  debitedUser: {
    id: number;
    firstName: string;
    lastName: string;
    rating: number;
    avatar: string;
  };
  creditedUser: {
    id: number;
    firstName: string;
    lastName: string;
    rating: number;
    avatar: string;
  };
  booking: {
    id: number;
    price: number;
    property: {
      id: number;
      address: string;
    };
  };
  serviceProposal: {
    id: number;
    service: {
      id: number;
      title: string;
    };
  };
};
