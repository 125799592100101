export function furnishedTypeOption() {
  return [
    {
      label: "Furnished",
      value: "Furnished",
    },
    {
      label: "Unfurnished",
      value: "Unfurnished",
    },
  ];
}

export function moveInTypeOption() {
  return [
    {
      label: "Immediately",
      value: "Immediately",
    },
    {
      label: "Gradually",
      value: "Gradually",
    },
  ];
}

export function paidTypeOption() {
  return [
    {
      label: "Hourly",
      value: "hourly",
    },
    {
      label: "Fixed",
      value: "fixed",
    },
  ];
}

export function propertyTypeOptions() {
  return [
    {
      label: "Detached House",
      value: "Detached house",
    },
    {
      label: "Semi Detached House",
      value: "Semi-detached house",
    },
    {
      label: "Terraced House",
      value: "Terraced house",
    },
    {
      label: "Apartment",
      value: "Apartment",
    },
  ];
}
export function yesNoOptions() {
  return [
    {
      label: "Yes",
      value: "Yes",
    },
    {
      label: "No",
      value: "No",
    },
  ];
}
export function rentalMarketOptions() {
  return [
    {
      label: "Professionals",
      value: "Professionals",
    },
    {
      label: "Family",
      value: "Family",
    },
    {
      label: "Students",
      value: "Students",
    },
    {
      label: "Co-habit",
      value: "Co-habit",
    },
  ];
}

export function sort() {
  return [
    {
      label: "Price: High to Low",
      value: "price_desc",
    },
    {
      label: "Price: Low to High",
      value: "price_asc",
    },
  ];
}

export function berNo() {
  return [
    {
      label: "25(A1)",
      value: "25(A1)",
    },
    {
      label: "25(A2)",
      value: "25(A2)",
    },
    {
      label: "50(A3)",
      value: "50(A3)",
    },
    {
      label: "75(B1)",
      value: "75(B1)",
    },
  ];
}
