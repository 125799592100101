import { ReturnType } from "./types";

export default {
  getMessageId: (data: any): ReturnType => {
    return {
      method: "post",
      url: `/v1/common/message`,
      data: data,
    };
  },

  deleteMessageId: (id: number): ReturnType => {
    return {
      method: "delete",
      url: `/v1/common/conversations/chat/${id}`,
      config: {
        successMsg: "",
        store: {
          action: "update",
          key: "chats",
        },
      },
    };
  },

  addChat: (data: any): ReturnType => {
    return {
      url: "/v1/common/addChat",
      data: data,
      method: "post",
      config: {
        successMsg: "",
        store: {
          action: "update",
          key: "chats",
        },
      },
    };
  },
};
