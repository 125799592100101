import React, { useState } from 'react';
import styled from 'styled-components';

interface ToggleProps {
  style?: React.CSSProperties;
  value: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ToggleButton: React.FC<React.PropsWithChildren<ToggleProps>> = ({
  onChange,
  value,
  ...props
}) => {
  const toggle = e => {
    onChange(e);
  };

  return (
    <Toggle>
      <label className={value ? 'switch toggled' : 'switch'}>
        <input type="checkbox" checked={value} onChange={toggle} />
        <span className="slider round"></span>
      </label>
      <div className="status"></div>
    </Toggle>
  );
};

export default ToggleButton;
const Toggle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 23px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 20px;
  }

  .slider:before {
    content: '';
    position: absolute;
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }

  .switch input:checked + .slider {
    background-color: #2196f3;
  }

  .switch input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  .switch input:checked + .slider:before {
    transform: translateX(16px);
  }

  .status {
    margin-top: 10px;
    font-weight: bold;
  }
`;
