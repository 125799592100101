type bookingProcessStatusType = {
  isLandlord: boolean;
  isTenant: boolean;
  booking: any;
  bookingStatus: string;
  bookingCurrentStep: number;
  isSystemGenerate: boolean;
};

const hasBookingEventType = (type: string, bEvents: any[]) => {
  const bEvent = bEvents.find(be => be?.type === type);
  if (bEvent?.id) {
    return true;
  }
  return false;
};

export default ({
  booking,
  bookingCurrentStep,
  bookingStatus,
  isLandlord,
  isSystemGenerate,
  isTenant,
}: bookingProcessStatusType) => {
  return [
    isLandlord &&
      bookingCurrentStep === 2 &&
      ['Viewing Arranged'].includes(bookingStatus) &&
      ['Accept'].includes(booking?.propertyViewRequest) && {
        title:
          'Your have accepted the booking request. You need to generate contract and co-ordinate with tenant for further proceeding.',
        status: bookingStatus,
        type: 'info',
      },
    isLandlord &&
      bookingCurrentStep === 3 &&
      ['Contract Issued'].includes(bookingStatus) &&
      hasBookingEventType('Contract', booking?.bookingEvents) && {
        title:
          'You have uploaded contract. Tenant will view, review, consult and if have consent then place their security deposit to escrow.',
        status: bookingStatus,
        type: 'info',
      },
    isLandlord &&
      bookingCurrentStep === 4 &&
      !isSystemGenerate &&
      ['Contract Issued'].includes(bookingStatus) &&
      !hasBookingEventType('Signed', booking?.bookingEvents) &&
      hasBookingEventType('Deposit', booking?.bookingEvents) && {
        title:
          'Tenant have placed security deposit to escrow. You need to upload both parties signed contract.',
        status: bookingStatus,
        type: 'info',
      },
    isLandlord &&
      bookingCurrentStep === 4 &&
      !isSystemGenerate &&
      ['Contract Issued'].includes(bookingStatus) &&
      hasBookingEventType('Signed', booking?.bookingEvents) && {
        title:
          'You have uploaded signed contract. Tenant need to release rent from escrow to get property handover.',
        status: bookingStatus,
        type: 'info',
      },
    isLandlord &&
      bookingCurrentStep === 5 &&
      ['Completed'].includes(bookingStatus) && {
        title:
          'Contract has been signed and property has been handovered to tenant.',
        status: bookingStatus,
        type: 'success',
      },

    isLandlord &&
      ['Declined'].includes(bookingStatus) && {
        title: 'You declined this booking.',
        status: bookingStatus,
        type: 'error',
      },
    isLandlord &&
      ['Cancelled'].includes(bookingStatus) && {
        title: 'Tenant cancelled the booking.',
        status: bookingStatus,
        type: 'error',
      },

    isTenant &&
      bookingCurrentStep === 0 &&
      ['Booking Requested'].includes(bookingStatus) &&
      !['Reject', 'Accept'].includes(booking?.propertyViewRequest) && {
        title:
          'Your request for booking has been sent to landlord. Landlord will soon notify you via message or email for further process.',
        status: bookingStatus,
        type: 'info',
      },

    isTenant &&
      bookingCurrentStep === 0 &&
      ['Booking Requested'].includes(bookingStatus) &&
      ['Reject'].includes(booking?.propertyViewRequest) && {
        title:
          "You've rejected property viewing appointment. So, you are off the list.",
        status: bookingStatus,
        type: 'error',
      },
    isTenant &&
      bookingCurrentStep === 0 &&
      ['Viewing Arranged'].includes(bookingStatus) &&
      ['Accept'].includes(booking?.propertyViewRequest) && {
        title:
          "You've accepted property viewing request. Make sure to be there on time. ",
        status: bookingStatus,
        type: 'info',
      },
    isTenant &&
      bookingCurrentStep === 2 &&
      ['Viewing Arranged'].includes(bookingStatus) &&
      ['Accept'].includes(booking?.propertyViewRequest) && {
        title:
          'Your request have been accepted. Soon landlord will generate contract, co-ordinate for further proceeding.',
        status: bookingStatus,
        type: 'info',
      },
    isTenant &&
      bookingCurrentStep === 3 &&
      ['Contract Issued'].includes(bookingStatus) &&
      hasBookingEventType('Contract', booking?.bookingEvents) && {
        title:
          'Landlord has uploaded contract. View, review and if you have consent then place your security deposit to escrow.',
        status: bookingStatus,
        type: 'info',
      },

    isTenant &&
      bookingCurrentStep === 4 &&
      !isSystemGenerate &&
      ['Contract Issued'].includes(bookingStatus) &&
      !hasBookingEventType('Signed', booking?.bookingEvents) &&
      hasBookingEventType('Deposit', booking?.bookingEvents) && {
        title:
          'You have placed security deposit to escrow. Wait for landlord to upload both parties signed contract.',
        status: bookingStatus,
        type: 'info',
      },
    isTenant &&
      bookingCurrentStep === 4 &&
      !isSystemGenerate &&
      ['Contract Issued'].includes(bookingStatus) &&
      hasBookingEventType('Signed', booking?.bookingEvents) && {
        title:
          'Landlord has uploaded both parties signed contract. You need to release rent from escrow to get access to property.',
        status: bookingStatus,
        type: 'info',
      },
    isTenant &&
      bookingCurrentStep === 5 &&
      ['Completed'].includes(bookingStatus) && {
        title:
          'Contract has been signed and property has been handovered to you.',
        status: bookingStatus,
        type: 'success',
      },
    isTenant &&
      ['Cancelled'].includes(bookingStatus) && {
        title: 'You cancelled this booking.',
        status: bookingStatus,
        type: 'error',
      },
    isTenant &&
      ['Declined'].includes(bookingStatus) && {
        title: 'Landlord has declined your booking request.',
        status: bookingStatus,
        type: 'error',
      },
  ].filter(Boolean);
};
