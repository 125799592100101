import { ReturnType } from "./types";

export default {
  getCalendars: (params?: any): ReturnType => {
    return {
      method: "get",
      url: "/v1/common/calenders",
      params,
      config: {
        store: {
          action: "set",
          key: "calendars",
        },
      },
    };
  },
};
