import { ReturnType } from "./types";

export default {
  getMyTenants: (): ReturnType => {
    return {
      method: "get",
      url: "/v1/contractGenerate/tenants",
      config: {
        store: {
          action: "set",
          key: "myTenants",
        },
      },
    };
  },

  getTenantRentalHistories: (tenantId?: number, params?: any): ReturnType => {
    return {
      method: "get",
      url: `/v1/contractGenerate/rental-histories/${tenantId}`,
      params: params || {},
    };
  },

  generateContract: (data: any): ReturnType => {
    return {
      method: "post",
      url: `/v1/contractGenerate/contracts`,
      data: data,
      config: {
        successMsg: "Contract Generate Successfully",
        navigateBack: true,
      },
    };
  },

  updateManualContract: (data: any, id: number): ReturnType => {
    return {
      method: "patch",
      url: `/v1/contractGenerate/contracts/${id}`,
      data: data,
      config: {
        successMsg: "Contract updated Successfully",
        navigateBack: true,
      },
    };
  },

  signContract: (signature: any, id: number): ReturnType => {
    return {
      method: "post",
      url: `/v1/bookings/${id}/signature`,
      data: {
        base64: signature,
      },
    };
  },

  serviceContract: (data: any): ReturnType => {
    return {
      method: "post",
      url: `/v1/services/contracts`,
      data: data,
    };
  },

  getServiceContractById: (id: number): ReturnType => {
    return {
      method: "get",
      url: `/v1/services/contracts/${id}`,
    };
  },

  getServiceContracts: (params: any): ReturnType => {
    return {
      method: "get",
      url: "/v1/services/contracts",
      params: params,
      config: {
        store: {
          action: "set",
          key: "contracts",
        },
      },
    };
  },
};
