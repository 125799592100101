import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AuthWrapper from './AuthWrapper';
import useAPI from '@/hooks/api';
import { useRoot } from '@/RootProvider';
import Modal from '@/newComponents/Modal';
import OTPInput from './OTPInput';
import Error from '@shared/web/components/Error';
import Button from '@shared/web/components/Button';
import { toast } from 'react-toastify';
import { PageTitle, StyledButton, SubText } from './styles';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  text-align: center;
`;

function useQuery() {
  const query = new URLSearchParams(useLocation().search);

  return {
    email: query.get('email'),
    verificationCode: query.get('verificationCode'),
  };
}

const Login = () => {
  const { setAuth } = useRoot();
  const { state }: any = useLocation();
  const [code, setCode] = useState('');
  const navigate = useNavigate();
  const [handleSubmitRequest, { loading, error }] = useAPI();
  const [visible, setVisible] = useState();

  useEffect(() => {
    if (!state?.email) {
      // history.replace('/login');
      navigate('/login', { replace: true });
    }
  }, []);

  const handleSubmit = async code => {
    const data = await handleSubmitRequest({
      method: 'post',
      url: '/v1/auth/verify',
      data: { code, email: state.email },
    });

    toast('Your account verified successfully. Now you can login.');
    navigate('/login', { replace: true });
  };

  return (
    <AuthWrapper>
      <Container>
        <div>
          <PageTitle variant="h1">Verify Account</PageTitle>
          <OTPInput
            length={6}
            loading={loading}
            defaultValue={''}
            onComplete={code => {
              setCode(code);
            }}
          />
          <SubText>(We have sent you an email with verification code)</SubText>
          <Error message={error} isFormError />
        </div>
        <StyledButton
          isLoading={loading}
          isDisabled={loading}
          onClick={() => handleSubmit(code)}
        >
          Verify Account
        </StyledButton>
      </Container>
    </AuthWrapper>
  );
};

export default Login;
