import Stepper from '@/newComponents/Stepper';
import { Apis, Store, Utils } from '@shared/front';
import LoadingPlaceholder from '@shared/web/components/LoadingPlaceholder';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import BasicInformation from './BasicInformation';
import Features from './Features';
import Location from './Location';
const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 600px;
  padding-top: 60px;
`;

export const ErrorMessage = styled.div`
  color: rgb(240, 20, 0);
  font-size: 12px;
`;

const INITIAL_STATES = {
  features: [],

  latitude: '',
  longitude: '',
  address: '',
  // desiredFrom: {
  //   latitude: null,
  //   longitude: null,
  //   address: '',
  // },
  // desiredTo: {
  //   latitude: null,
  //   longitude: null,
  //   address: '',
  // },
  price: 0,
  targetPrice: 0,
  distance: 0,
  time: 0,
  moveStatus: '',
  paidType: '', // "hourly" / "fixed"
  propertyType: '',
  // trades: [],
};

const UserDesired = () => {
  const { id: propertyId } = useParams();
  const userDesired: any = useSelector(Store.Select.desired);
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isEdit = pathname.includes('edit');
  const [desiredValues, setDesiredValues] = useState(INITIAL_STATES);
  const [savingDesires, setSavingDesires] = useState(false);

  useEffect(() => {
    if (isEdit) {
      if (userDesired?.id) {
        console.log({ userDesired });
        const dataToEdit = {
          features: userDesired?.features,

          latitude: userDesired?.latitude,
          longitude: userDesired?.longitude,
          address: userDesired?.address,

          price: userDesired?.price,
          targetPrice: userDesired?.targetPrice,
          distance: userDesired?.distance,
          time: userDesired?.time,
          moveStatus: userDesired?.moveStatus,
          paidType: userDesired?.paidType,
          propertyType: userDesired?.propertyType,
        };
        setDesiredValues(dataToEdit);
      } else {
        getDesired();
      }
    }
  }, [userDesired?.id, isEdit]);

  const getDesired = async () => {
    try {
      await Utils.request(Apis.Auth.getOwnUserDesires());
    } catch (err) {}
  };

  const onSubmitStep = async ({ data, dataFrom }: OnSubmitStepParams) => {
    switch (dataFrom) {
      case 'location':
        const locationToSave = {
          address: data?.address,
          latitude: `${data?.latitude}`,
          longitude: `${data?.longitude}`,
        };

        setDesiredValues((prev: typeof INITIAL_STATES) => ({
          ...prev,
          ...locationToSave,
        }));
        setStep(prev => prev + 1);
        break;
      case 'info':
        const infoToSave = {
          price: data?.price,
          targetPrice: data?.targetPrice,
          distance: `${data?.distance}`,
          time: `${data?.time}`,
          moveStatus: data?.moveStatus,
          paidType: data?.paidType,
          propertyType: data?.propertyType,
        };
        setDesiredValues((prev: typeof INITIAL_STATES) => ({
          ...prev,
          ...infoToSave,
        }));
        setStep(prev => prev + 1);
        break;
      case 'features':
        const featuresToSave = {
          features: data?.features,
        };
        setDesiredValues((prev: typeof INITIAL_STATES) => ({
          ...prev,
          ...featuresToSave,
        }));
        if (isEdit) {
          await updateDesired(
            { ...desiredValues, ...featuresToSave },
            userDesired?.id,
          );
        } else {
          await addDesired({ ...desiredValues, ...featuresToSave });
        }
        break;
      default:
    }
  };

  const addDesired = async (data: any) => {
    try {
      const response = await Utils.request(Apis.Auth.addOwnUserDesires(data));
      if (response) {
        navigate('/profile');
      }
    } catch (error) {
      if (error && typeof error === 'string') {
        toast.error(error);
      }
    }
  };

  const updateDesired = async (data: any, id: number) => {
    try {
      const response = await Utils.request(
        Apis.Auth.updateOwnDesires(data, id),
      );
      if (response) {
        navigate('/profile');
      }
    } catch (error) {
      if (error && typeof error === 'string') {
        toast.error(error);
      }
    }
  };

  const childProps = {
    desiredValues,
    onSubmitStep,
  };

  const steps = [0, 1, 2];
  const items = [
    {
      label: 'Location',
      key: 0,
      children: <Location {...childProps} />,
    },
    {
      label: 'Basic Infomation',
      key: 1,
      children: <BasicInformation {...childProps} />,
    },
    {
      label: 'Features',
      key: 2,
      children: <Features {...childProps} loading={savingDesires} />,
    },
  ];
  // @ts-ignore
  if (propertyId && !initialValues?.id) {
    return <LoadingPlaceholder />;
  }
  return (
    <Container>
      <Stepper
        activeStep={step}
        stepItems={items}
        enableTabClick
        onTabClick={data => {
          setStep(data?.key);
        }}
      />
    </Container>
  );
};

export default UserDesired;

export interface DesiredPropertyData {
  id: number;
  features: Array<string>;
  price: number;
  targetPrice: number;
  distance: number;
  time: number;
  moveStatus: string;
  latitude: string;
  longitude: string;
  address: string;
  propertyType: string;
  desiredFrom: {
    latitude: string;
    longitude: string;
    address: string;
  };
  desiredTo: {
    latitude: string;
    longitude: string;
    address: string;
  };
  paidType: string; // "hourly" / "fixed"
  trades: Array<string>;
}
export interface OnSubmitStepParams {
  data: DesiredPropertyData;
  dataFrom: 'location' | 'info' | 'features';
}
