export const PriceType = () => {
  return [
    {
      label: "Hourly",
      value: "hourly",
    },
    {
      label: "Fixed",
      value: "fixed",
    },
    {
      label: "Quote",
      value: "quote",
    },
  ];
};
export const ServiceByPrice = () => {
  return [
    {
      label: "Price: High to Low",
      value: "price_desc",
    },
    {
      label: "Price: Low to High",
      value: "price_asc",
    },
  ];
};
