import { useRoot } from '@/RootProvider';
import useAPI from '@/hooks/api';
import GoBack from '@/newComponents/GoBack';
import ImageSwiper from '@/newComponents/ImageSwiper';
import { Apis, Socket, Store, Utils } from '@shared/front';
import LoadingPlaceholder from '@shared/web/components/LoadingPlaceholder';
import NoImage from 'assets/noimage.png';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import ProductInfo from './ProductInfo';
import UserInfo from './UserInfo';
import Button from '@/newComponents/Button';
import MessageOwner from './MessageOwner';

const MarketItemView = ({}) => {
  const navigate = useNavigate();
  const { id: productId } = useParams();
  const { auth } = useRoot();
  const userId = auth?.id;
  const [sendMessageLoading, setSendMessageLoading] = useState<boolean>(false);

  const [loading, setLoading] = useState(true);
  const [makeRequest, { data: product, loading: productLoading }] = useAPI();
  // console.log('the product id', product?.owner?.id);

  const marketProduct: any = useSelector(Store.Select.marketProduct);
  console.log({ marketProduct });

  const getMarketProductId = (id: any) => {
    if (!!auth) {
      makeRequest(Apis.Market.getMarketProduct(+id));
    } else {
      makeRequest(Apis.Market.getMarketProductForGuest(+id));
    }
  };

  const createMessageIdAndSendMessage = async (message: string) => {
    // console.log('tyeh message', message);
    const data = {
      sellerId: product?.owner?.id,
      buyerId: userId,
      chatType: 'marketPlace',
      productId: product?.id,
    };
    setSendMessageLoading(true);
    // console.log('dddd', data);
    const messageId = await Utils.request(Apis.Chat.getMessageId(data));
    if (messageId) {
      const chatData = {
        message: message,
        conversationId: messageId,
        messageBy: 'buyer',
      };
      await Socket.addChat(chatData);
    }
    setSendMessageLoading(false);
    await getMarketProductId(productId);
  };

  useEffect(() => {
    productId && getMarketProductId(productId);
  }, [productId]);

  return (
    <MarketDetailsStyled>
      <GoBack />
      {!!productLoading && (
        <div
          style={{
            height: 100,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <LoadingPlaceholder />
        </div>
      )}
      {!!product?.id && product?.productImage?.length > 0 && (
        <ImageSwiper
          images={
            product?.productImage?.length > 0
              ? product?.productImage?.map((item: any) => item.url)
              : [NoImage]
          }
          imageStyle={{
            height: '300px',
            objectFit: 'cover',
          }}
        />
      )}
      {!!product?.id && (
        <div className="container">
          <div className="left-contents">
            <ProductInfo productInfo={product}></ProductInfo>
          </div>
          <div className="right-contents">
            <div className="user-info">
              <UserInfo
                email={product?.owner?.email}
                id={product?.owner?.id}
                name={product?.owner?.fullName}
                profileImage={product?.owner?.avatar}
                rating={product?.owner?.rating || 3}
              />
            </div>

            {/* <Button
              className="gotoMessage"
              onClick={() => navigate(`/chats/${product?.conversationId}`)}
            >
              Go to Message{' '}
            </Button> */}


            <MessageOwner
              product={product}
              getMarketProductId={getMarketProductId}
            />
          </div>
        </div>
      )}
    </MarketDetailsStyled>
  );
};

export default MarketItemView;
const MarketDetailsStyled = styled.div`
  margin: 0 auto;

  & .container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 24px;

    @media (max-width: 950px) {
      flex-direction: column-reverse;
    }
    & .left-contents {
      flex: 1;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    & .right-contents {
      position: -webkit-sticky; /* Safari */
      position: sticky;
      top: 80px;
      width: 305px;
      background: #fff;

      @media (max-width: 950px) {
        width: 100%;
        top: 60px;
      }
      & .user-info {
        width: 100%;
        background: #f0f0f0;
        flex-shrink: 0;
        margin-bottom: 25px;
      }
      & .gotoMessage {
        width: 100%;
      }
    }
  }
`;
