import Error from '@shared/web/components/Error';
import InputField from '@shared/web/components/InputField';
import { Field, Formik } from 'formik';
import useAPI from '@/hooks/api';
import { omit } from 'lodash';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import AuthWrapper from './AuthWrapper';
import { toast } from 'react-toastify';
import LinkedIn from './LinkedIn';
import { SubText, PageTitle, StyledButton, StyledForm, Title } from './styles';
const loginValidationSchema = yup.object().shape({
  firstName: yup
    .string()

    .required('Please enter your first name'),
  lastName: yup
    .string()

    .required('Please enter your last name'),
  email: yup
    .string()
    .email('Email is not valid')
    .required('Please enter your email'),
  password: yup.string().required('Please enter your password'),
  confirmPassword: yup
    .string()
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value;
    }),
  remember: yup.boolean(),
});

const Login = () => {
  const navigate = useNavigate();
  const [handleSubmitRequest, { loading, error }] = useAPI();
  const [linkedInError, setLinkedInError] = useState('');

  const handleSubmit = async (values, formikHelpers) => {
    await handleSubmitRequest({
      method: 'post',
      url: '/v1/auth/register',
      data: { ...omit(values, ['confirmPassword']) },
    });
    toast(
      'Thanks for joining Rentisity. We have send you verification email, you can continue login using that email.',
    );
    navigate(`/verifyAccount`, {
      state: {
        email: values.email,
      },
    });
  };

  return (
    <AuthWrapper bgImg="/register-bg.jpg">
      <Formik
        initialValues={{ email: '', password: '' }}
        onSubmit={handleSubmit}
        validationSchema={loginValidationSchema}
      >
        {({ handleSubmit, isSubmitting, errors, touched }) => {
          return (
            <StyledForm onSubmit={handleSubmit}>
              <div>
                <PageTitle variant="h1">Create Account</PageTitle>
                <Title>
                  Already a member? <Link to="/login"> Login</Link>
                </Title>

                <Field
                  component={InputField}
                  label="First Name"
                  name="firstName"
                />
                <Field
                  component={InputField}
                  label="Last Name"
                  name="lastName"
                />
                <Field component={InputField} label="Email" name="email" />

                <Field
                  component={InputField}
                  name="password"
                  type="password"
                  label="Password"
                />
                <Field
                  component={InputField}
                  name="confirmPassword"
                  type="password"
                  label="Confirm Password"
                />
                <Error message={error} isFormError />
                <SubText variant="p">
                  By submitting the form, I accept Terms and Conditions.
                </SubText>
              </div>

              <div>
                <StyledButton
                  htmlType="submit"
                  isDisabled={loading || isSubmitting}
                  isLoading={loading || isSubmitting}
                  style={{ width: '100%' }}
                >
                  Create Account
                </StyledButton>
                <SubText
                  style={{
                    textAlign: 'center',
                    margin: '15px 0',
                  }}
                  variant="p"
                >
                  Or
                </SubText>
                <LinkedIn title="Create with LinkedIn" />
              </div>
            </StyledForm>
          );
        }}
      </Formik>
    </AuthWrapper>
  );
};

export default Login;
