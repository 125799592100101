import { useRoot } from '@/RootProvider';
import { theme, devices } from '@/utils/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Store } from '@shared/front';
import { NotificationHelper } from '@shared/front/utils';
import React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Typography from '../Typography';
import { createMenu } from './leftMenu';

export type MenuType = {
  title: string;
  url: string;
  icon: any;
  children?: Omit<MenuType, 'icon'>[];
};

interface ISidemenuProps {
  onMenuItemClick?: () => void;
}

const SideMenuWrapper = styled.div`
  flex-shrink: 0;
  width: 270px;
  position: fixed;
  top: 60px;
  left: 0px;
  bottom: 0px;
  padding: 10px 0px 0px 24px;
  overflow-y: auto;
  z-index: 1;
  background-color: #fff;
  border-right: 1px solid #f0f0f0;
  padding-bottom: 20px;

  &::-webkit-scrollbar {
    width: 0.4em;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
`;

const ListItem = styled.div<{ isActive: boolean }>`
  font-size: 14px;
  line-height: 30px;
  display: flex;
  align-items: center;
  gap: 12px;
  transition: all 0.3s ease;
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.primary : ' #576874'};
  cursor: pointer;
  transition: all 0.3s ease-in;
  padding: 4px 6px;
  border-right: 3px solid
    ${({ theme, isActive }) =>
      isActive ? theme.colors.primary : 'transparent'};
  &:hover {
    background-color: #f1f3f4;
  }
`;

const ListItemContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const RedDot = styled.div`
  padding: 5px;
  margin: 0 5px 0 0;
  border-radius: 50%;
  background: #ee9a9a;
`;

const ChildContainer = styled.div<{ $height: number }>`
  // height: ${({ $height }) => `${$height}px`};
  // transition: height 0.2s ease-out;
  // display: flex;
  // flex-direction: column;
  // gap: 0px;
  // overflow: hidden;
`;

const ItemWrapper = styled.div<{
  $childrenLen: number;
  $isItemSelected: boolean;
}>`
  & .children-menu {
    height: ${({ $isItemSelected, $childrenLen }) =>
      $isItemSelected ? `${$childrenLen * 40}px` : '0px'};
    transition: height 0.2s ease-out;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 0px;
  }

  &:hover {
    & .children-menu {
      height: ${({ $childrenLen }) => $childrenLen * 40}px;
    }
  }
`;

const SideMenu = ({ onMenuItemClick }: ISidemenuProps) => {
  const isDesktopOrLaptop = useMediaQuery({ query: devices.tablet });
  const root = useRoot();
  const list = createMenu(root?.auth?.role);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const notifications: any = useSelector(Store.Select.notifications);
  const unReadNotificationCount =
    (!!notifications?.length &&
      notifications?.filter((notification: any) => !notification.isRead)) ||
    [];
  const Notification = NotificationHelper.countNotifications(
    unReadNotificationCount,
  );

  const showRedDot = (menuTitle: string) => {
    if (menuTitle === 'Messages' && Notification?.chatNotificationCount > 0) {
      return true;
    }
    if (
      menuTitle === 'Bookings' &&
      Notification?.bookingNotificationCount > 0
    ) {
      return true;
    }

    if (
      menuTitle === 'Report Issues' &&
      Notification?.reportIssueNotificationCount > 0
    ) {
      return true;
    }
    if (
      menuTitle === 'Transactions' &&
      Notification?.transactionNotificationCount > 0
    ) {
      return true;
    }
    return false;
  };

  const getMenuItem = ({ singleOption }: { singleOption: MenuType | any }) => {
    const { url, title, icon, children } = singleOption;

    const splittedPathname = pathname.split('/')[1];
    const splittedURL = url.split('/')[1];
    let isItemSelected = splittedPathname === splittedURL;

    if (children) {
      const filteredChildren = children.filter(Boolean);

      let isItemChildSelected = false;

      const selectedChild = filteredChildren.filter((item: any) => {
        const splittedURL = item.url.split('/')[1];
        isItemChildSelected = splittedPathname === splittedURL;
        return isItemChildSelected;
      });

      // if (Array.isArray(selectedChild) && selectedChild.length > 0) {
      //   isItemSelected = true;
      // }

      return (
        <ItemWrapper
          $childrenLen={filteredChildren.length}
          $isItemSelected={isItemSelected || selectedChild.length > 0}
        >
          <ListItem
            isActive={isItemSelected}
            onClick={() => {
              navigate(url);
              !!onMenuItemClick && onMenuItemClick();
            }}
          >
            <FontAwesomeIcon icon={icon} style={{ width: 18 }} />
            <ListItemContent>
              <Typography
                variant="span"
                style={{ fontSize: '14px' }}
                color={isItemSelected ? theme.colors.primary : ' #576874'}
              >
                {title}
              </Typography>
              {showRedDot(title) && <RedDot />}
            </ListItemContent>
          </ListItem>
          <ChildContainer
            $height={isItemSelected ? filteredChildren.length * 40 : 0}
            className="children-menu "
          >
            {filteredChildren.map((item: any) => {
              const splittedURL = item.url.split('/')[1];
              isItemChildSelected = splittedPathname === splittedURL;
              return (
                <ListItem
                  isActive={isItemChildSelected}
                  onClick={() => {
                    navigate(item.url);
                    !!onMenuItemClick && onMenuItemClick();
                  }}
                  style={{
                    paddingLeft: 36,
                  }}
                >
                  {item.icon && (
                    <FontAwesomeIcon
                      icon={item.icon}
                      style={{ marginRight: 10, width: 20 }}
                    />
                  )}
                  <Typography
                    variant="span"
                    style={{ fontSize: '14px' }}
                    color={
                      isItemChildSelected ? theme.colors.primary : ' #576874'
                    }
                  >
                    {item.title}
                  </Typography>
                </ListItem>
              );
            })}
          </ChildContainer>
        </ItemWrapper>
      );
    }

    return (
      <ListItem
        isActive={isItemSelected}
        onClick={() => {
          navigate(url);
          !!onMenuItemClick && onMenuItemClick();
        }}
      >
        <FontAwesomeIcon icon={icon} style={{ width: 18, height: 18 }} />
        <ListItemContent>
          <Typography
            variant="span"
            style={{ fontSize: '14px' }}
            color={isItemSelected ? theme.colors.primary : ' #576874'}
          >
            {title}
          </Typography>
          {showRedDot(title) && <RedDot />}
        </ListItemContent>
      </ListItem>
    );
  };

  return (
    <SideMenuWrapper>
      {list.map((singleOption: any) => getMenuItem({ singleOption }))}
    </SideMenuWrapper>
  );
};

export default SideMenu;
