import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import SignatureCanvas from 'react-signature-canvas';
import useAPI from '@/hooks/api';
import dataURItoBlob from '@/utils/dataURItoBlob';
import Error from '@shared/web/components/Error';
import Modal from '@/newComponents/Modal';
import {
  ButtonsContainer,
  Container,
  ModalMainText,
  StyledButton,
} from './modalStyles';
import { useResponsive } from '@/hooks/useResponsive';

type Props = {
  bookingId: number | null;
  onCancel: Function;
  getBooking: any;
  isVisible: boolean;
};

const ContractSignModal = ({
  bookingId,
  onCancel,
  getBooking,
  isVisible,
}: Props) => {
  const [signError, setSignError] = useState('');
  const { isTablet } = useResponsive();

  const [makeSignRequest, { loading: signLoading }] = useAPI();

  const signPadRef = useRef<any>();

  const handleCancel = () => {
    onCancel && onCancel();
  };

  const handleSignContract = async () => {
    if (signPadRef?.current?.isEmpty()) {
      setSignError('Please sign');
      return;
    } else {
      setSignError('');
    }

    const dataURI = signPadRef?.current
      ?.getTrimmedCanvas()
      .toDataURL('image/png');

    const formData = new FormData();

    formData.append('file', dataURItoBlob(dataURI));

    await makeSignRequest({
      method: 'post',
      url: `/v1/bookings/${bookingId}/signature`,
      data: formData,
    });
    handleCancel();
    getBooking();
    toast('Your sign has been successfully saved.');
  };

  return (
    <Modal
      title="Sign Contract"
      open={isVisible}
      onClose={handleCancel}
      style={{ maxWidth: isTablet ? '700px' : '100%' }}
    >
      <Container>
        <ModalMainText variant="p">Sign inside yellow box</ModalMainText>
        <div
          style={{
            backgroundColor: '#FF0',
            overflow: 'hidden',
            display: 'inline-block',
            width: 'fit-content',
          }}
        >
          <SignatureCanvas
            ref={signPadRef}
            penColor="blue"
            minDistance={1}
            canvasProps={{
              width: 500,
              height: 200,
            }}
          />
        </div>
        <Error message={signError} />
        <ButtonsContainer>
          <StyledButton variant="gray" radius="round" onClick={handleCancel}>
            Cancel
          </StyledButton>
          <div style={{ display: 'flex', gap: '16px' }}>
            <StyledButton
              variant="gray"
              radius="round"
              onClick={() => signPadRef?.current?.clear?.()}
            >
              Clear board
            </StyledButton>
            <StyledButton
              radius="round"
              isLoading={signLoading}
              onClick={handleSignContract}
            >
              Done
            </StyledButton>
          </div>
        </ButtonsContainer>
      </Container>
    </Modal>
  );
};

export default ContractSignModal;
