import { ReturnType } from "./types";
export default {
  getBookings: (params?: any, action?: "set" | "update"): ReturnType => {
    return {
      method: "get",
      url: "/v1/bookings",
      params: params,
      config: {
        store: {
          action: action,
          key: "bookings",
        },
      },
    };
  },

  getBookingRequests: (action: "set" | "update"): ReturnType => {
    return {
      method: "get",
      url: "/v1/bookings/requests",
      config: {
        store: {
          action: action,
          key: "bookingRequests",
        },
      },
    };
  },

  getCompletedBooking: (params?: any, action: "set" | "update"): ReturnType => {
    return {
      method: "get",
      url: "/v1/bookings/complete",
      params,
      config: {
        store: {
          action: action,
          key: "bookingCompleted",
        },
      },
    };
  },

  getBookingByPropertyId: (propertyId: number, params?: any): ReturnType => {
    return {
      method: "get",
      url: `/v1/bookings/property/${propertyId}/users`,
      params,
      config: {
        store: {
          action: "set",
          key: "bookingRequestByProperty",
        },
      },
    };
  },

  undoBooking: (propertyId: number): ReturnType => {
    return {
      method: "post",
      url: `/v1/bookings/${propertyId}/undo-accept`,
      config: {
        successMsg: "Undo completed successfully",
      },
    };
  },

  bookingAddToList: (data: any): ReturnType => {
    return {
      method: "put",
      url: "/v1/bookings/list",
      data,
      config: {
        successMsg: "Added Successfully",
      },
    };
  },

  bookingRequest: (data: {
    tenantId: number;
    landlordId: number;
    propertyId: number;
    price: number;
    status: string;
  }): ReturnType => {
    return {
      method: "post",
      url: "/v1/bookings",
      data: data,
      config: {
        successMsg:
          "Your booking has been placed successfully. Landlord will contact you shortly.",
        store: {
          action: "set",
          key: "bookingRequestLoading",
        },
        showErr: true,
      },
    };
  },

  getBookingById: (id?: number): ReturnType => {
    return {
      method: "get",
      url: `/v1/bookings/${id}`,
      config: {
        store: {
          action: "set",
          key: "booking",
        },
      },
    };
  },

  acceptBooking: (id?: number): ReturnType => {
    return {
      method: "post",
      url: `/v1/bookings/${id}/accept`,
      config: {
        successMsg: "Booking has been accepted successfully",
        store: {
          action: "set",
          key: "acceptBookingLoading",
        },
      },
    };
  },

  cancelBooking: (id?: number): ReturnType => {
    return {
      method: "post",
      url: `/v1/bookings/${id}/cancel`,
      config: {
        successMsg: "Booking has been canceled",
        store: {
          action: "set",
          key: "cancelBookingLoading",
        },
      },
    };
  },

  declineBooking: (id?: number): ReturnType => {
    return {
      method: "post",
      url: `/v1/bookings/${id}/decline`,
      config: {
        successMsg: "Booking has been declined",
        store: {
          action: "set",
          key: "declineBookingLoading",
        },
      },
    };
  },

  createSignature: (signature: any, id: number): ReturnType => {
    return {
      method: "post",
      url: `/v1/bookings/${id}/signature-test`,
      data: {
        base64: signature,
      },
      config: {
        successMsg: "Your sign has been successfully saved",
      },
    };
  },

  deposit: (data: any, id: number): ReturnType => {
    return {
      method: "post",
      url: `/v1/bookings/${id}/depositEscrow`,
      data: data,
      config: {
        successMsg: "Deposit has been made successfully",
      },
    };
  },
  securityDeposit: (data: any): ReturnType => {
    return {
      method: "post",
      url: `/v1/payments/property-deposit`,
      data: data,
      config: {
        successMsg: "Deposit has been made successfully",
      },
    };
  },
  rentPayment: (data: any, id: number): ReturnType => {
    return {
      method: "post",
      url: `/v1/bookings/${id}/depositEscrow`,
      data: data,
      config: {
        successMsg: "Deposit has been made successfully",
      },
    };
  },

  getAllReferenceImageGroupByBookingId: (bookingId: number): ReturnType => {
    return {
      method: "get",
      url: `/v1/bookings/${bookingId}/ref-image`,
    };
  },

  createReferenceImageGroup: (data: any): ReturnType => {
    return {
      method: "post",
      url: `/v1/bookings/ref-image-group`,
      data,
    };
  },
  updateReferenceImageGroupStatus: (groupId: number): ReturnType => {
    return {
      method: "patch",
      url: `/v1/bookings/ref-image-status/${groupId}`,
      data: {
        status: "Completed",
      },
      config: {
        successMsg: "Mark as completed successfully",
      },
    };
  },
  invitieShortListedProfileForPropertyViewing: (
    propertyId: number
  ): ReturnType => {
    return {
      method: "post",
      url: `/v1/bookings/${propertyId}/invite-for-viewing`,
      config: {
        store: {
          action: "set",
          key: "invitieShortListedProfileForPropertyViewing",
        },
      },
    };
  },
};
