import Footer from '@/newComponents/Footer';
import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Header from '../Header/Header';

const PublicLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <>
      <Header />
      <BodyStyled>
        <MainWrapperStyled>
          {!!children ? children : <Outlet />}
        </MainWrapperStyled>
      </BodyStyled>
      <Footer />
    </>
  );
};

export default PublicLayout;

const BodyStyled = styled.div`
  min-height: calc(100vh - 60px);
  position: relative;
  padding-top: 60px;
`;

const MainWrapperStyled = styled.main`
  padding: 12px 80px;
  width: 100%;

  @media (max-width: 900px) {
    padding: 12px 40px;
  }
  @media (max-width: 765px) {
    padding: 12px 20px;
  }
`;
