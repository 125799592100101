import { screenSizes } from '@/utils/theme';
import React from 'react';
import {
  MediaQueryAllQueryable,
  MediaQueryMatchers,
  useMediaQuery,
} from 'react-responsive';

interface ResponsiveReturnValues {
  isMobile?: boolean;
  isLargeMobile?: boolean;
  isTablet?: boolean;
  isDesktop?: boolean;
  isLargeScreen?: boolean;
  isBigScreen?: boolean;
  mobileOnly?: boolean;
  tabletOnly?: boolean;
  desktopOnly?: boolean;
  useMediaQuery?: (
    settings: Partial<MediaQueryAllQueryable & { query?: string | undefined }>,
    device?: MediaQueryMatchers,
    callback?: (matches: boolean) => void,
  ) => boolean;
}

const getMedia = (breakpoint: string): string => `(min-width: ${breakpoint})`;
export const media = {
  xs: getMedia(screenSizes.xs),
  sm: getMedia(screenSizes.xs),
  md: getMedia(screenSizes.md),
  lg: getMedia(screenSizes.lg),
  xl: getMedia(screenSizes.xl),
  xxl: getMedia(screenSizes.xxl),
};

export const useResponsive = (): ResponsiveReturnValues => {
  const isMobile = useMediaQuery({ query: screenSizes.xs });
  const isLargeMobile = useMediaQuery({ query: screenSizes.sm });
  const isTablet = useMediaQuery({ query: media.md });
  const isLargeScreen = useMediaQuery({ query: screenSizes.lg });
  const isDesktop = useMediaQuery({ query: screenSizes.xl });
  const isBigScreen = useMediaQuery({ query: screenSizes.xxl });
  return {
    isMobile,
    isLargeMobile,
    isTablet,
    isLargeScreen,
    isDesktop,
    isBigScreen,
    useMediaQuery,
  };
};
