import React, { CSSProperties } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Viewer from 'react-viewer';
import styled from 'styled-components';
import ImageWithFallBack from './ImageWithFallBack';

interface IImageSwiperProps {
  images: string[];
  imageStyle?: CSSProperties;
  containerHeight?: string;
  style?: CSSProperties;
}

const SwiperContainer = styled.div<{ $height: string }>`
  overflow: hidden;
  & .alice-carousel__dots-item.__active {
    background-color: #2061f8;
  }

  & .alice-carousel__wrapper {
    height: ${({ $height }) => $height};
  }
`;

const SwiperImage = styled(ImageWithFallBack)`
  width: calc(100% - 10px);
  cursor: pointer;
`;

const responsive = {
  0: { items: 1 },
  575: { items: 2 },
  992: { items: 3 },
  1600: { items: 4, itemsFit: 'fill' },
};

const ImageSwiper = ({
  images,
  containerHeight = '',
  imageStyle,
  style,
}: IImageSwiperProps) => {
  const [visible, setVisible] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const items = images.map((image, index) => (
    <SwiperImage
      src={image}
      alt={`image-${index}`}
      style={{ ...imageStyle }}
      onClick={() => {
        setActiveIndex(index);
        openImageViewer();
      }}
    />
  ));

  const openImageViewer = () => {
    setVisible(true);
  };

  const closeImageViewer = () => {
    setVisible(false);
  };

  return (
    <>
      <SwiperContainer $height={containerHeight} style={{ ...style }}>
        <AliceCarousel
          autoPlay
          autoPlayInterval={2000}
          animationDuration={1000}
          infinite
          disableButtonsControls
          responsive={responsive}
          items={items}
        />
      </SwiperContainer>
      <Viewer
        activeIndex={activeIndex}
        visible={visible}
        onClose={closeImageViewer}
        onMaskClick={closeImageViewer}
        images={images.map((image, index) => ({
          src: image,
          alt: `image-${index}`,
        }))}
      />
    </>
  );
};

export default ImageSwiper;
