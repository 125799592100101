// import { createSelector } from "reselect";
// import initialState from "./initialState";

// const selectRoot = (state: typeof initialState) => state || initialState;

// const createInitialSelectors = () => {
//   let selectors: any = {};
//   Object.keys(initialState).map((key) => {
//     selectors[key] = createSelector(selectRoot, (state) => state[key]);
//   });

//   return selectors;
// };

// const selectors: typeof initialState = createInitialSelectors();

// export default selectors;

import { createSelector, Selector } from "reselect";

import initialState from "./initialState";
import reducer from "./reducer";

export type RootState = ReturnType<typeof reducer>;
type KeyOfInitialStateType = keyof RootState;
const selectRoot = (state: RootState) => state || initialState;

const createInitialSelectors = () => {
  let selectors:
    | Record<
        KeyOfInitialStateType,
        Selector<RootState, RootState[keyof RootState]>
      >
    | any = {};
  Object.keys(initialState).map((key) => {
    const typedKey = key as KeyOfInitialStateType;
    selectors[typedKey] = createSelector(
      selectRoot,
      (state: RootState) => state[typedKey]
    );
  });

  return selectors;
};

type SelectorReturnType = ReturnType<typeof createInitialSelectors>;

const selectors: SelectorReturnType = createInitialSelectors();
export default selectors;
