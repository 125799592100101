import React, { useEffect, useState } from 'react';
import { ModalHeader, ModalStyled, ModalTitle, OverlayStyled } from './styles';

export type SizeType = 'sm' | 'md' | 'lg' | number;
export type AnimationDirectionType = 'left' | 'right' | 'top' | 'bottom';
interface IModalProps {
  style?: React.CSSProperties;
  open: boolean;
  onClose: () => void;
  size?: SizeType;
  isFullWidth?: boolean;
  isCentered?: boolean;
  title?: string;
  closeOnBackdropClick?: boolean;
  animationDirection?: AnimationDirectionType;
  noAnimation?: boolean;
}

const Modal: React.FC<React.PropsWithChildren<IModalProps>> = ({
  style = {},
  open = false,
  onClose,
  children,
  size = 'md',
  isFullWidth = false,
  isCentered = false,
  title,
  closeOnBackdropClick = true,
  animationDirection = 'bottom',
  noAnimation = false,
}) => {
  const [localStatus, setLocalStatus] = useState(true);
  useEffect(() => {
    if (open) {
      if (typeof window != 'undefined' && window.document) {
        document.body.style.overflow = 'hidden';
      }
    } else {
      if (typeof window != 'undefined' && window.document) {
        document.body.style.overflow = 'unset';
      }
    }
    return () => {
      if (typeof window != 'undefined' && window.document) {
        document.body.style.overflow = 'unset';
      }
    };
  }, [open]);

  const handleClose = () => {
    setLocalStatus(false);
    if (noAnimation) {
      setLocalStatus(true);
      onClose();
      return;
    }
    setTimeout(() => {
      setLocalStatus(true);
      onClose();
    }, 300);
  };

  if (!open) {
    return null;
  }
  return (
    <>
      <OverlayStyled onClick={() => closeOnBackdropClick && handleClose()} />
      <ModalStyled
        size={size}
        isFullWidth={isFullWidth}
        isCentered={isCentered}
        style={style}
        isOpen={localStatus}
        $direction={animationDirection}
        $noAnimation={noAnimation}
      >
        <ModalHeader>
          {!!title && <ModalTitle>{title}</ModalTitle>}
          <div
            className="close"
            onClick={() => {
              handleClose();
            }}
          >
            <div>&times;</div>
          </div>
        </ModalHeader>

        {children}
      </ModalStyled>
    </>
  );
};

export default Modal;
