import Modal from '@/newComponents/Modal';
import React, { useState, useEffect } from 'react';
import RoomsItemsModal from './RoomsItemsModal';
import { request } from '@shared/front/utils';
import { Apis, Store } from '@shared/front';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@/newComponents/Typography';
import styled from 'styled-components';
import Button from '@/newComponents/Button';
import RoomItemsDetailsModal from './RoomItemsDetailsModal';
import NoData from '@/newComponents/NoData';
import LoadingPlaceholder from '@/components/LoadingPlaceholder';
import { ButtonEndWrapperStyled } from '@/styles/shareableStyle';
import { useResponsive } from '@/hooks/useResponsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ImageWithFallBack from '@/newComponents/ImageWithFallBack';

type Props = {
  open: boolean;
  propertyId: number;
  isEditMode?: boolean;
  dataToEdit?: any;
  itineraryCategoryId: number;
  onClose: () => void;
  onToggle: () => void;
  itineraryCategory: any;
  hasAccess: boolean;
};

const ListRoomItemsModal = ({
  open,
  onClose,
  onToggle,
  itineraryCategory,
  propertyId,
  isEditMode = false,
  itineraryCategoryId,
  dataToEdit,
  hasAccess,
}: Props) => {
  const { isTablet } = useResponsive();
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState<{ status: boolean; ids: number[] }>({
    status: false,
    ids: [],
  });
  const [confirmDeletion, setConfirmDeletion] = useState<{
    status: boolean;
    data: any;
  }>({
    status: false,
    data: null,
  });
  const [editing, setEditing] = useState<{
    status: boolean;
    data: any;
  }>({
    status: false,
    data: null,
  });
  const [enableAddItinerary, setEnableAddItinerary] = useState<{
    status: boolean;
    data: {
      category?: any;
      categoryId?: number;
      propertyId?: number;
    };
  }>({
    status: false,
    data: {},
  });
  const [enableViewItinerary, setEnableViewItinerary] = useState<{
    status: boolean;
    data?: any;
  }>({
    status: false,
    data: {},
  });

  // SELECTORS
  //@ts-ignore
  const propertyItineraries = useSelector(Store.Select.itineraries);

  // HOOKS
  const dispatch = useDispatch();

  useEffect(() => {
    if (open && itineraryCategoryId) {
      fetchRoomItems();
    }
  }, [open]);

  const fetchRoomItems = async () => {
    try {
      setLoading(true);
      const response = await request(
        Apis.Properties?.getPropertyInventoryByCategoryId(itineraryCategoryId),
      );
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleOnClose = () => {
    onClose();
    dispatch(Store.Actions.reset('itineraries'));
  };

  const handleOpenAddItineraries = () => {
    onToggle();
    setEnableAddItinerary({
      status: true,
      data: {
        categoryId: itineraryCategory?.id,
        propertyId,
        category: itineraryCategory,
      },
    });
  };

  const handleCloseAddItineraries = () => {
    onToggle();
    setEnableAddItinerary({
      status: false,
      data: {},
    });
    setEditing({
      status: false,
      data: null,
    });
  };

  const handleOpenViewItinerary = (itineraryId: number) => {
    onToggle();
    setEnableViewItinerary({
      status: true,
      data: itineraryId,
    });
  };

  const handleCloseViewItinerary = () => {
    onToggle();
    setEnableViewItinerary({
      status: false,
      data: {},
    });
  };

  const deleteItinerary = async (id: number) => {
    closeDeleteItinerary();
    setDeleting(prev => ({ ...prev, status: true, ids: [...prev.ids, id] }));
    await request(Apis.Properties.deletePropertyItinerary(id));

    const updatedData =
      Array.isArray(propertyItineraries) &&
      propertyItineraries.filter(x => x.id !== id);

    dispatch(Store.Actions.set('itineraries', updatedData));
    setDeleting(prev => ({
      ...prev,
      status: false,
      ids: prev.ids.filter(x => x !== id),
    }));
  };

  const handleDeleteItinerary = (data: any) => {
    setConfirmDeletion({
      status: true,
      data,
    });
  };

  const closeDeleteItinerary = () => {
    setConfirmDeletion({
      status: false,
      data: null,
    });
  };
  return (
    <>
      <Modal
        onClose={handleOnClose}
        open={open}
        title={`${itineraryCategory?.title} Items`}
        size={'lg'}
        isCentered
        style={{ width: !isTablet ? '95vw' : '80vw' }}
      >
        {loading ? (
          <LoadingPlaceholder />
        ) : (
          <MainContainerStyled>
            <Typography style={{ fontSize: '12px', color: '#666' }}>
              {itineraryCategory?.desc}
            </Typography>

            {hasAccess && (
              <ActionContainer>
                <Button onClick={handleOpenAddItineraries}>Add Items</Button>
              </ActionContainer>
            )}

            {Array.isArray(propertyItineraries) &&
              propertyItineraries.length === 0 && <NoData size="200" />}
            <ItinerariesContainer>
              {Array.isArray(propertyItineraries) &&
                propertyItineraries.map(itinerary => (
                  <IndividualItinerary
                    key={itinerary?.id}
                    onClick={() => {
                      handleOpenViewItinerary(itinerary?.id);
                    }}
                  >
                    <Typography variant="p" className="title">
                      {itinerary?.title}
                    </Typography>{' '}
                    <Typography variant="p" className="quantity">
                      <span className="label">Quantity </span>
                      {itinerary?.quantity}
                    </Typography>
                    <Typography variant="p" className="quantity">
                      <span className="label">Images </span>
                      {itinerary?.propertyItineriesImages?.length || 0}
                    </Typography>
                    <ItinerayImagesContainer>
                      {itinerary?.propertyItineriesImages?.map(
                        (img: any, idx: number) => {
                          let isLast = false;
                          if (idx > 2) return null;
                          if (
                            idx === 2 &&
                            itinerary?.propertyItineriesImages?.length > 3
                          ) {
                            isLast = true;
                          }
                          return (
                            <div
                              style={{
                                height: '100px',
                                width: '100px',
                                position: 'relative',
                              }}
                            >
                              <ImageWithFallBack src={img.URL} />
                              {isLast && (
                                <div
                                  style={{
                                    height: '100px',
                                    width: '100px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    position: 'absolute',
                                    left: '0px',
                                    top: '0px',
                                    background: 'rgba(0,0,0,0.3)',
                                    borderRadius: '6px',
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon="plus"
                                    color="#fff"
                                    size="3x"
                                  />
                                </div>
                              )}
                            </div>
                          );
                        },
                      )}
                    </ItinerayImagesContainer>
                    <Typography variant="p" className="desc">
                      {itinerary?.desc}
                    </Typography>
                    {hasAccess ? (
                      <div className="actions-container">
                        <Button
                          variant="gray"
                          onClick={e => {
                            e.stopPropagation();
                            handleOpenViewItinerary(itinerary?.id);
                          }}
                        >
                          <FontAwesomeIcon icon="eye" />
                        </Button>
                        <Button
                          variant="primary"
                          onClick={e => {
                            e.stopPropagation();
                            handleOpenAddItineraries();
                            setEditing({
                              data: itinerary,
                              status: true,
                            });
                          }}
                        >
                          <FontAwesomeIcon icon="edit" />
                        </Button>
                        <Button
                          variant="danger"
                          onClick={e => {
                            e.stopPropagation();
                            handleDeleteItinerary(itinerary);
                          }}
                          isLoading={
                            deleting.status &&
                            deleting.ids.includes(itinerary?.id)
                          }
                          isDisabled={
                            deleting.status &&
                            deleting.ids.includes(itinerary?.id)
                          }
                        >
                          <FontAwesomeIcon icon="trash" />
                        </Button>
                      </div>
                    ) : (
                      <div className="actions-container">
                        <Button
                          variant="gray"
                          onClick={e => {
                            e.stopPropagation();
                            handleOpenViewItinerary(itinerary?.id);
                          }}
                        >
                          View
                        </Button>
                      </div>
                    )}
                  </IndividualItinerary>
                ))}
            </ItinerariesContainer>
          </MainContainerStyled>
        )}
      </Modal>

      <RoomsItemsModal
        open={enableAddItinerary?.status}
        onClose={handleCloseAddItineraries}
        itineraryCategoryId={enableAddItinerary?.data?.categoryId}
        propertyId={enableAddItinerary?.data?.propertyId}
        inventory={enableAddItinerary?.data?.category}
        dataToEdit={editing?.data}
        isEditMode={editing?.status}
      />
      <RoomItemsDetailsModal
        itineraryId={enableViewItinerary?.data}
        onClose={handleCloseViewItinerary}
        open={enableViewItinerary?.status}
        hasAccess={hasAccess}
      />
      <Modal
        open={confirmDeletion.status}
        onClose={closeDeleteItinerary}
        title="Delete"
        isCentered={!isTablet}
        style={{ width: !isTablet ? '90vw' : 'auto' }}
      >
        Are you sure you want to delete {confirmDeletion?.data?.title} ?
        <br />
        <br />
        <ButtonEndWrapperStyled>
          <Button variant="gray" onClick={closeDeleteItinerary}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              deleteItinerary(confirmDeletion?.data?.id);
            }}
          >
            Delete
          </Button>
        </ButtonEndWrapperStyled>
      </Modal>
    </>
  );
};

export default ListRoomItemsModal;
const MainContainerStyled = styled.div`
  max-height: calc(100vh - 120px);
  overflow-y: auto;
  min-height: 50vh;
`;
const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

const ItinerariesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 40px 25px;
`;

const IndividualItinerary = styled.div`
  position: relative;

  width: 100%;
  cursor: pointer;
  border: 1px solid #dbdbdb;

  padding: 20px 16px 60px;
  border-radius: 20px;

  &:hover {
    background: rgba(100, 100, 100, 0.1);
  }

  & .title {
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 8px;
  }

  & .quantity {
    font-weight: 600;
    font-size: 1.1rem;
    & .label {
      font-weight: 400;
      color: #888;
    }
    margin-bottom: 8px;
  }

  & .desc {
    color: #333;
  }

  & .actions-container {
    position: absolute;
    bottom: 16px;
    left: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
`;

const ItinerayImagesContainer = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
`;
