import React from 'react';
import styled from 'styled-components';
import { useRoot } from '@/RootProvider';
import moment from 'moment';
import { Info, InfoLabel, ProfileContainer, SectionTitle } from './style';
import { devices } from '@/utils/theme';
import { StyledButton } from './bookingStepsView/styles';

const TenantProfile = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  padding-top: 20px;
`;

const Contract = ({ booking }) => {
  // @ts-ignore

  const { auth: user } = useRoot();
  const valueToShow = [
    {
      title: 'Contract Type',
      value: booking?.contract?.isSystemGenerate
        ? 'System Generated'
        : 'Manually Created',
    },
    {
      title: 'Contract Start Date',
      value: !!booking?.contract?.startDate
        ? moment(booking?.contract?.startDate).format('DD MMM, YYYY')
        : 'N/A',
    },
    {
      title: 'Contract End Date',
      value: !!booking.contract?.endDate
        ? moment(booking.contract?.endDate).format('DD MMM, YYYY')
        : 'N/A',
    },
    {
      title: ' Landlord Sign',
      value: booking?.landlordSign ? 'Yes' : 'No',
    },
    {
      title: ' Tenant Sign',
      value: booking?.tenantSign ? 'Yes' : 'No',
    },
    {
      title: 'Contract Updated Date',
      value: !!booking.contract?.uploadUpdateContractDate
        ? moment(booking.contract?.uploadUpdateContractDate).format(
            'DD MMM, YYYY',
          )
        : moment(booking.contract?.updatedAt).format('DD MMM, YYYY'),
    },
  ];
  const renderRow = (title, value) => {
    return (
      <div>
        <InfoLabel variant="p">
          {title}&nbsp;&nbsp;&nbsp;<Info variant="span">{value}</Info>
        </InfoLabel>
      </div>
    );
  };

  return (
    <TenantProfile>
      <SectionTitle variant="p">Contract</SectionTitle>
      <ProfileContainer>
        <ContractInfoContainer>
          {valueToShow.map(val => renderRow(val.title, val.value))}
        </ContractInfoContainer>
        <StyledButton variant="gray" radius="round">
          <a
            href={booking?.agreementURL}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              fontFamily: 'Poppins',
            }}
          >
            Download Contract
          </a>
        </StyledButton>
      </ProfileContainer>
    </TenantProfile>
  );
};

export default Contract;

const ContractInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
