import Error from '@shared/web/components/Error';
import Typography from '@/newComponents/Typography';
import S3FileUploader from '@/containers/Properties/S3FileUploader';
import { Formik, Form } from 'formik';
import useAPI from '@/hooks/api';
import React, { useState } from 'react';
import validation from './validation';
import { devices } from '@/utils/theme';
import styled from 'styled-components';
import { SaveButtonContainer, StyledButton, Title } from './index.style';
import { Apis, Utils } from '@shared/front';
const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 20px;
`;

const StyledDescription = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  line-height: 25px;

  @media ${devices.laptop} {
    font-size: 14px;
    line-height: 30px;
  }
`;

const StyledNote = styled(StyledDescription)`
  margin-top: 20px;
  color: #949494;
`;
const Photos = ({
  initialValues,
  onSubmitStep,
}: {
  initialValues: { id: number; attachments: string };
  onSubmitStep: any;
}) => {
  const [handleSubmitRequest, { loading, error }] = useAPI();
  const [photoLoading, setPhotoLoading] = useState(false);
  const [targetButton, setTargetButton] = useState<string>('');
  const handleSubmitForm = async (
    values: { images: Array<string> },
    isDraft?: boolean,
  ) => {
    const updateImages = values?.images?.filter(
      item => typeof item === 'string',
    );
    const serviceId = initialValues?.id;
    await handleSubmitRequest(
      Apis.Services.updateServicePhoto({ files: updateImages }, serviceId),
    );
    onSubmitStep({ isDraft });
  };

  const handleDeleteAttachments = async id => {
    await Utils.request(Apis.Services.deleteServiceAttachment(id));
  };

  return (
    <Formik
      initialValues={{ images: initialValues.attachments }}
      onSubmit={(values: any, { setErrors }) => {
        const images =
          values.images && Array.isArray(values.images)
            ? values.images.filter(Boolean)
            : [];
        if (images.length === 0) {
          setErrors({ images: 'Select at least one image.' });
          return;
        }
        handleSubmitForm(values);
      }}
      validationSchema={validation.photos}
      enableReinitialize
    >
      {({
        isSubmitting,
        errors,
        touched,
        values,
        setFieldValue,
        handleSubmit,
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Title variant="h1">Add Photos</Title>
            <DescriptionContainer>
              <StyledDescription variant="p">
                Upload property photos
              </StyledDescription>
              <StyledDescription variant="p">
                Drag images into box to add an image / dragging in multiple
                images at once is also possible.
              </StyledDescription>
            </DescriptionContainer>
            {(values.images?.length ? values.images : [null]).map(
              (item: any, index: any) => {
                return (
                  <S3FileUploader
                    index={index}
                    setFieldValue={setFieldValue}
                    values={values}
                    item={item}
                    fieldName={'images'}
                    deleteById={id => {
                      handleDeleteAttachments(id);
                    }}
                    photoLoading={photoLoading}
                    setPhotoLoading={setPhotoLoading}
                  />
                );
              },
            )}
            <StyledNote variant="p">
              <span style={{ color: '#2061f8' }}>Note:</span> PNG, JPG and JPEG
              are supported
            </StyledNote>
            <Error message={error || errors?.images} isFormError />
            <SaveButtonContainer>
              <StyledButton
                radius="round"
                onClick={() => {
                  setTargetButton('save');
                }}
                htmlType="submit"
                isDisabled={photoLoading}
                isLoading={photoLoading}
              >
                Save
              </StyledButton>
            </SaveButtonContainer>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Photos;
