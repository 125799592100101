import React from 'react';
import styled from 'styled-components';
import ImageWithDefault from './ImageWithFallBack';

interface PropertyCardProps extends React.ComponentPropsWithoutRef<'div'> {
  style?: React.CSSProperties;
  address: string;

  price: number;
  image: string;
  features?: Array<any>;
  rentBase?: string;
}

const PropertyCard = ({
  address,
  price,

  image,
  rentBase = 'month',
  property,
  features,
  ...restProps
}: PropertyCardProps) => {
  return (
    <>
      <PropertyCardComponentContainer {...restProps}>
        <div style={{ height: '216px', width: '100%', marginBottom: '20px' }}>
          {!!image ? (
            <ImageWithDefault src={image} alt={address} />
          ) : (
            <ImageWithDefault
              src="https://images.pexels.com/photos/106399/pexels-photo-106399.jpeg?cs=srgb&dl=pexels-binyamin-mellish-106399.jpg&fm=jpg"
              alt={address}
            />
          )}
        </div>

        <Address>{address}</Address>
        <Price>
          &euro;{price} /{rentBase}
        </Price>

        <FeatureWrapper>
          {features?.map(feature => {
            return (
              <div
                style={{
                  display: 'flex',
                  gap: '4px',
                  alignItems: 'center',
                }}
              >
                <FeatureImage src={feature?.img} />
                <Feature>{feature?.total}</Feature>
              </div>
            );
          })}
        </FeatureWrapper>
      </PropertyCardComponentContainer>
    </>
  );
};

export default PropertyCard;

const PropertyCardComponentContainer = styled.div`
  /* width: 100%; */
  cursor: pointer;
`;

const Image = styled.img`
  height: 216px;
  object-fit: cover;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
`;
const Address = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #949494;
  font-family: Poppins;
  margin-bottom: 20px;
`;
const Price = styled.p`
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  font-family: Poppins;
  /* line-height: normal; */
  color: #1f1f1f;
  line-height: 0;
  margin-bottom: 20px;
  align-items: center;
`;

const FeatureWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
const FeatureImage = styled.img`
  height: 28px;
  width: 28p;
  /* margin-bottom: 10px; */
`;

const Feature = styled.p`
  font-size: 14px;
  color: #949494;
  font-style: normal;
  font-weight: 400;
  line-height: 0;
`;
