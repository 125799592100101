import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import LeftSVG from '../assets/Arrow_left_alt.svg';
type Props = {
  navigateTo?: string;
};

const GoBack = ({ navigateTo }: Props) => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    if (!!navigateTo) {
      navigate(navigateTo);
    } else {
      navigate(-1);
    }
  };

  return (
    <GoBackStyled>
      <span onClick={handleGoBack}>
        <img src={LeftSVG} alt="back" />
      </span>
    </GoBackStyled>
  );
};

export default GoBack;

const GoBackStyled = styled.div`
  margin-bottom: 20px;
  & span {
    cursor: pointer;
    height: 30px;
    width: 30px;
    display: inline-block;

    & img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
`;
