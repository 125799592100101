import Error from '@shared/web/components/Error';
import InputField from '@shared/web/components/InputField';
import {
  Field,
  Formik,
  FormikHandlers,
  FormikHelpers,
  FormikProps,
} from 'formik';
import useAPI from '@/hooks/api';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { RootType, useRoot } from '@/RootProvider';
import * as yup from 'yup';
import AuthWrapper from './AuthWrapper';
import axios from 'axios';
import LinkedIn from './LinkedIn';
import { Apis, Socket, Store } from '@shared/front';
import {
  ButtonsContainer,
  PageTitle,
  StyledButton,
  StyledForm,
  SubText,
  Title,
} from './styles';
import Checkbox from '@/newComponents/Checkbox';

const loginValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Email is not valid')
    .required('Please enter your email'),
  password: yup.string().required('Please enter your password'),
  remember: yup.boolean(),
});

type InitialStateType = {
  email: string;
  password: string;
};

const Login = () => {
  const navigate = useNavigate();
  const { setAuth, auth }: RootType = useRoot();
  const [handleSubmitRequest, { loading, error }] = useAPI();

  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    if (auth && auth?.id && auth?.token) {
      navigate('/');
    }
  }, []);

  const handleSuccess = async (data: RootType['auth']) => {
    if (!data) {
      return;
    }
    axios.defaults.headers.Authorization = `Bearer ${data.token}`;

    Socket.connectSocket();

    setAuth(data);
    await localStorage.setItem('user', JSON.stringify(data));
    console.log({ on: data?.onboardingStep });

    if (data?.onboardingStep && data.onboardingStep < 4) {
      navigate('/onboarding', { replace: true });
    } else {
      navigate('/dashboard', { replace: true });
    }
  };

  const handleSubmit = async (
    values: InitialStateType,
    formikHelpers: FormikHelpers<InitialStateType>,
  ) => {
    try {
      const data = await handleSubmitRequest(Apis.Auth.login(values));
      Store.Actions.set('loggedInUser', data);
      handleSuccess(data);
    } catch (err) {
      // console.log('test err', err);
      if (
        typeof err === 'string' &&
        err?.includes("Your account hasn't been verified")
      ) {
        navigate('/verifyAccount', { state: { email: values.email } });
      }
    }
  };

  return (
    <AuthWrapper bgImg="/login-bg.jpg">
      <Formik
        initialValues={{ email: '', password: '' }}
        onSubmit={handleSubmit}
        validationSchema={loginValidationSchema}
      >
        {({ handleSubmit, isSubmitting, errors, touched }) => {
          return (
            <StyledForm onSubmit={handleSubmit}>
              <div>
                <PageTitle variant="h1">Login</PageTitle>
                <Title variant="h2">
                  Don't have an account?{' '}
                  <Link to="/signup">Create Account</Link>
                </Title>
                <Field component={InputField} label="Email" name="email" />
                <Field
                  component={InputField}
                  name="password"
                  type="password"
                  label="Password"
                />

                <Checkbox
                  id="checkbox-remember-me"
                  style={{ height: '20px', width: '20px' }}
                  onChange={() => setRememberMe(prev => !prev)}
                  label="Remember me"
                  isChecked={rememberMe}
                />
                <Error message={error} isFormError />
              </div>
              <div>
                <ButtonsContainer>
                  <StyledButton
                    variant="gray"
                    radius="round"
                    onClick={() => navigate('/forgotPassword')}
                  >
                    Forgot Password
                  </StyledButton>
                  <StyledButton
                    radius="round"
                    htmlType="submit"
                    isDisabled={loading || isSubmitting}
                    isLoading={loading || isSubmitting}
                  >
                    Login
                  </StyledButton>
                </ButtonsContainer>
                <SubText
                  style={{
                    textAlign: 'center',
                    margin: '15px 0',
                  }}
                  variant="p"
                >
                  Or
                </SubText>
                <LinkedIn title="Login with LinkedIn" />
              </div>
            </StyledForm>
          );
        }}
      </Formik>
    </AuthWrapper>
  );
};

export default Login;
