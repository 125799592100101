import JobsLGCard from '@/newComponents/JobsLGCard';
import numeral from 'numeral';
import React from 'react';

const JobItem = ({ job, refreshData, ownerId, loggedInUserId }) => {
  return (
    <>
      <JobsLGCard
        id={job?.id}
        title={job?.title}
        jobStatus={job?.status}
        jobType={job?.priceType}
        numberOfHires={job?.contracts?.length}
        numberOfNewProposals={
          job?.proposals?.filter(item => !item.isRead)?.length
        }
        numberOfProposal={job?.proposals?.length}
        rate={numeral(job.price).format('0,0.00')}
        tags={job?.categories}
        ownerId={ownerId}
        refreshData={refreshData}
      />
    </>
  );
};

export default JobItem;
