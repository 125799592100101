import styled from 'styled-components';
import { devices } from '@/utils/theme';
import { Form } from 'formik';
import Button from '@/newComponents/Button';
import Typography from '@/newComponents/Typography';

export const LeftBox = styled.div`
  width: 100%;
  flex: 1;
`;

export const RightBox = styled.div`
  width: 100%;
  position: relative;

  @media ${devices.laptop} {
    width: 240px;
  }
  @media ${devices.laptopXL} {
    width: 280px;
  }
`;

export const StickyBox = styled.div`
  position: sticky;
  top: 115px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;

  @media ${devices.mobileXS} {
    flex-direction: row;
    width: 100%;
  }

  @media ${devices.laptop} {
    flex-direction: column;
    width: 240px;
  }
  @media ${devices.laptopXL} {
    width: 280px;
  }
`;

export const Content = styled.div`
  display: flex;
  padding: 40px 0px;
  position: relative;
  justify-content: space-between;
  flex-flow: column-reverse nowrap;
  row-gap: 20px;
  column-gap: 40px;

  @media ${devices.laptop} {
    flex-flow: row nowrap;
  }
`;

export const CarouselWrapperStyled = styled.div`
  margin-bottom: 10px;
  text-align: center;
  img {
    margin: 0px auto;
  }
  & .slick-track {
  }
  .slick-slide {
    margin: 0 auto;
  }
  .slick-slide > div {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  & img {
    margin: auto;
    height: 300px !important;
    object-fit: cover;
  }
`;

export const SlideImageWrapperStyled = styled.div`
  margin: 0px 3px;
`;

export const Image = styled.img``;

export const SimilarItemsWrapper = styled.div``;

export const PropertyTitle = styled.h1`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 0px;
`;

export const SectionWrapper = styled.div`
  margin-bottom: 80px;
`;

export const SectionTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin: 0px 0px 20px 0px;
`;

export const SectionDescription = styled.p`
  color: ${({ theme }: any) => theme?.colors?.webPrimaryTextColor};
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  margin: 0px;

  &.title {
    font-weight: 300;
    margin-right: 6px;
  }
  &.value {
    font-weight: 500;
  }
`;

export const IconWrapper = styled.p``;

export const SectionHeaderRow = styled.div`
  display: flex;
  align-items: center;
`;

export const ModalFooter = styled.div`
  display: flex;
  margin-top: 20px;
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media ${devices.laptop} {
    gap: 40px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledButton = styled(Button)`
  font-size: 12px;
  line-height: 25px;

  @media ${devices.laptop} {
    font-size: 14px;
    line-height: 30px;
  }
`;

export const ModalMainText = styled(Typography)`
  font-size: 14px;
  line-height: 25px;

  @media ${devices.laptop} {
    font-size: 16px;
    line-height: 30px;
  }
`;

// export const ModalSubText = styled(Typography)`
//   font-size: 12px;
//   line-height: 25px;
//   color: #949494;

//   @media ${devices.laptop} {
//     font-size: 14px;
//     line-height: 30px;
//   }
// `;
