import React from "react";
import styled from "styled-components";

import { devices, sharedTheme } from "../../../../front/theme";

const ImageDesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${devices.mobileSM} {
    flex-direction: row;
  }
`;
const ImageDescWrapper = ({ children, onClick }) => {
  return (
    <ImageDesWrapper onClick={onClick && onClick}>{children}</ImageDesWrapper>
  );
};

export default ImageDescWrapper;
