import { ErrorMessage, Field, Formik, Form } from 'formik';
import * as yup from 'yup';
import React, { useState } from 'react';
import { Apis, Hooks } from '@shared/front';
import DatePickerField from '@shared/web/components/DatePicker';
import S3presigned from '@shared/web/components/contractS3PresignedUrl';
import Modal from '@/newComponents/Modal';
import Select from '@/newComponents/Select';
import {
  ButtonsContainer,
  Container,
  StyledButton,
  StyledLabel,
} from './modalStyles';
import DatePickerCustomized from '@/newComponents/DatePicker';

type Props = {
  isVisible: boolean;
  onCancel: Function;
  booking: any;
  bookingId: string | undefined;
  getBooking: any;
};

const paymentType = [
  {
    id: 0,
    label: 'Manually',
    value: 'Manual',
  },
  {
    id: 1,
    label: 'Automatic',
    value: 'System',
  },
];

const GenerateManualContractModal = ({
  booking,
  isVisible,
  onCancel,
  bookingId,
  getBooking,
}: Props) => {
  const validationSchema = yup.object().shape({
    startDate: yup.string().required('Please enter contract start date.'),
    endDate: yup.string().required('Please enter contract end date.'),
    contractFile: yup.string().required('Please upload contract.'),
    paymentMode: yup.string().required('Please Select payment type.'),
  });

  const [makeRequest, { loading: loadingManualContract }] = Hooks.useAPI();

  const [manualContract, setManualcontract] = useState({
    startDate: Date.now(),
    endDate: Date.now(),
    paymentMode: 'Manual',
    contractFile: '',
  });
  const isCreated = !!(
    !booking?.contract?.isSystemGenerate &&
    booking?.contract?.contractGenerateURL
  );

  const handleCancel = () => {
    onCancel && onCancel();
  };

  const handleManualContract = async values => {
    try {
      await makeRequest(
        Apis.Contract.generateContract({
          startDate: values?.startDate,
          endDate: values?.endDate,
          contractFile: values?.contractFile,
          paymentMood: values?.paymentMode,
          bookingId: bookingId,
          tenantId: booking?.tenantId,
          propertyId: booking?.propertyId,
          isSystemGenerate: false,
        }),
      );
    } catch (error) {
    } finally {
      getBooking();
    }
  };

  return (
    <Modal
      title={isCreated ? 'Update Manual contract' : 'Generate Manual Contract'}
      open={isVisible}
      onClose={handleCancel}
    >
      <Formik
        validationSchema={validationSchema}
        initialValues={manualContract}
        enableReinitialize
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={async values => {
          await handleManualContract(values);
          handleCancel();
        }}
      >
        {({ values, handleBlur, setFieldValue, errors }) => {
          console.log({ values, errors });

          return (
            <Form>
              <Container>
                <div>
                  <StyledLabel>Start Date</StyledLabel>
                  <Field
                    name="startDate"
                    component={DatePickerCustomized}
                    autoComplete={false}
                    minDate={Date.now()}
                  />
                </div>

                <div>
                  <StyledLabel>End Date</StyledLabel>
                  <Field
                    name="endDate"
                    component={DatePickerCustomized}
                    minDate={values.startDate}
                  />
                </div>
                <div>
                  <StyledLabel>Payment Type</StyledLabel>
                  <Field
                    name="paymentMode"
                    component={Select}
                    options={paymentType}
                    onChange={e => setFieldValue('paymentMode', e)}
                  />
                </div>
                <div>
                  <StyledLabel>Upload Contract</StyledLabel>
                  <Field
                    name="contractFile"
                    component={S3presigned}
                    accept=".pdf"
                    fileType="file"
                    isMultiple={false}
                
                  />
                  <ErrorMessage name="contractFile" />
                </div>
                <ButtonsContainer>
                  <StyledButton
                    variant="gray"
                    radius="round"
                    onClick={handleCancel}
                  >
                    Cancel
                  </StyledButton>
                  <StyledButton
                    radius="round"
                    htmlType="submit"
                    loading={loadingManualContract}
                    disabled={loadingManualContract}
                  >
                    {isCreated ? 'Update' : 'Save'}
                  </StyledButton>
                </ButtonsContainer>
              </Container>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default GenerateManualContractModal;
