import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ButtonEndWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  & button + button {
    margin-left: 10px;
  }
`;
