import React from 'react';
import styled from 'styled-components';
import CloseIcon from './assets/close-button.svg';

interface IFilterTagProps {
  filterType: string;
  filterSubType: string;
  onClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const FilterTagWrapper = styled.div`
  display: flex;
  padding: 2px 2px 2px 12px;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  background: #ebebeb;
  font-size: 12px;
  font-weight: 400;
  line-height: 25px;
  width: fit-content;
  gap: 12px;
`;

const FilterType = styled.div`
  color: #2061f8;
  text-transform: capitalize;
`;

const FilterSubType = styled.div`
  color: #949494;
  text-transform: capitalize;
`;

const CloseButton = styled.button`
  border: none;
  outline: none;
  background-color: #949494;
  border-radius: 50%;
  height: 27px;
  width: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #858585;
  }
`;

const FilterTag = ({ filterType, filterSubType, onClose }: IFilterTagProps) => {
  return (
    <FilterTagWrapper>
      <FilterType>{filterType}</FilterType>
      <FilterSubType>{filterSubType}</FilterSubType>

      <CloseButton onClick={onClose}>
        <img src={CloseIcon} alt="close" />
      </CloseButton>
    </FilterTagWrapper>
  );
};

export default FilterTag;
