import { useRoot } from '@/RootProvider';
import useAPI from '@/hooks/api';
import Typography from '@/newComponents/Typography';
import ListDescription from '@shared/web/components/ListItem/ListDescription';
import ImageDescWrapper from '@shared/web/components/ListItem/Wrapper';
import ReuseTag from '@shared/web/components/ReuseTag';
import { Row } from '@shared/web/components/common';
import { capitalize } from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
const Container = styled.div`
  margin-bottom: 20px;
  flex-direction: row;
  display: flex;
  cursor: pointer;
  padding: 0px 20px;
`;

const Content = styled.div`
  padding: 10px 20px;
  flex: 1;
`;

const Actions = styled.div`
  margin-left: auto;
  > button {
    display: block;
    width: 100px;
    margin: 4px 0 !important;
  }
`;

const TenantAvatar = styled.div`
  display: flex;
  align-items: center;

  & .ant-avatar {
    border: 1px solid ${({ theme }) => theme.colors.border};
  }
`;

const TenantInfo = styled.div`
  margin-left: 7px;
`;

const PropertyView = ({ contract, refreshData }) => {
  const { auth } = useRoot();
  const [visible, setVisible] = React.useState(false);
  const navigate = useNavigate();
  const [deletePropertyRequest, { loading: deleteLoading }] = useAPI();

  const handleDeleteRequest = async () => {
    await deletePropertyRequest({
      method: 'delete',
      url: `/v1/services/${contract.id}`,
    });
    refreshData();
    setVisible(false);
  };
  return (
    <Container
      onClick={() =>
        navigate(`/proposals/${contract.id}`, {
          state: {
            isRequestCallout: contract?.isRequestCallout,
            jobSatus: contract?.status,
          },
        })
      }
    >
      {/* <Content>
        <Row justify="space-between">
          <Typography.Title level={5}>
            {contract?.service?.title}
          </Typography.Title>
          <div>
            <Tag type={capitalize(contract.status)} />
          </div>
        </Row>
      </Content> */}

      <ImageDescWrapper>
        <ListDescription onClick={() => {}}>
          <Row justify="space-between">
            <div>
              <Typography size="md">{contract?.service?.title}</Typography>
              <Typography style={{ display: 'block' }}>
                proposal rate:{' '}
                <strong> €{numeral(contract.price).format('0,0.00')} </strong>
              </Typography>
              {/* <Typography.Text style={{ display: 'block' }}>
                Paid amount: : €{numeral(contract.paidAmount).format('0,0.00')}
              </Typography.Text> */}
              <Typography style={{ display: 'block', marginTop: '5px' }}>
                {moment.utc(contract.createdAt).local().fromNow()}
              </Typography>
              <Row>
                {contract?.service?.categories?.map(category => (
                  <ReuseTag
                    key={category}
                    type={category}
                    textColor="processing"
                  >
                    {category}
                  </ReuseTag>
                ))}
              </Row>
            </div>
          </Row>
          <div style={{ margin: '10px 0px' }}>
            <ReuseTag type={capitalize(contract.status)}>
              {capitalize(contract.status)}
            </ReuseTag>
          </div>
        </ListDescription>
      </ImageDescWrapper>
      <Actions>
        {/* {contract.status !== 'draft' && (
          <Button type="text" color="black">
            View
          </Button>
        )} */}
      </Actions>
    </Container>
  );
};

export default PropertyView;
