import { useRoot } from '@/RootProvider';
import store from '@/store';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import NotAuthorized from './NotAuthorized';

type MyRouteProps = {
  component: any;
  isPublic?: boolean;
  path?: string;
};
const ProtectedComponent = ({
  component: Component,
  isPublic = false,
  path,
}: MyRouteProps) => {
  const { auth } = useRoot();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isPublic && auth?.deleteRequested) {
      navigate('/deactivated');
    }
  }, [auth]);

  if (!isPublic && !auth?.id) {
    dispatch(store.Actions.set('authModal', { status: true, type: 'login' }));
    return <NotAuthorized />;
  }
  return <Component />;
};
export default ProtectedComponent;
