import SkeletonLoading from '@/newComponents/Skeleton';
import React from 'react';
import styled from 'styled-components';

const ProfileSkeleton = ({ ...rest }) => {
  return (
    <Wrapper>
      <div className="post">
        {[1].map(item => {
          return (
            <div className="card" key={item}>
              <SkeletonLoading
                height={60}
                width={60}
                borderRadius={50}
                style={{ marginRight: '20px' }}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                }}
              >
                <SkeletonLoading
                  height={20}
                  width={150}
                  style={{ marginTop: '12px' }}
                />

                <div>
                  <div>
                    <SkeletonLoading
                      height={20}
                      width={200}
                      style={{ marginTop: '12px' }}
                    />
                    <SkeletonLoading
                      height={20}
                      width={100}
                      style={{ marginTop: '12px' }}
                    />
                    <SkeletonLoading
                      height={20}
                      width={100}
                      style={{ marginTop: '12px' }}
                    />
                    <SkeletonLoading
                      height={30}
                      width={170}
                      style={{ marginTop: '12px' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
};

export default ProfileSkeleton;

const Wrapper = styled.div`
  width: 100%;

  .post {
    display: flex;
    flex-direction: column;

    & .card {
      display: flex;
      margin-bottom: 20px;
    }
  }
`;
