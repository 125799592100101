import { useRoot } from '@/RootProvider';
import useAPI from '@/hooks/api';
import Divider from '@/newComponents/Divider';
import Typography from '@/newComponents/Typography';
import { default as React, useEffect, useState } from 'react';
import styled from 'styled-components';
import EmptyPage from '../Empty';
import JobContractSkeleton from '../Skeleton/JobContractSkeleton';
import JobContractItem from './JobContractItem';

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 60px;
  background-color: white;
  z-index: 99;
  background: #fff;
  & .title {
    font-size: 1.6rem;
    font-weight: 500;
  }
  padding: 20px 0px 10px 0px;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 20px;
`;

const OrderTableView = () => {
  const [getMyPropertiesRequest, { data: contracts, loading }] = useAPI();

  const { auth } = useRoot();

  const [params, setParams] = useState({
    page: 0,
    filter: 'active',
  });

  const handleRequest = tempParams => {
    setParams(tempParams);
    getMyPropertiesRequest({
      method: 'get',
      url: '/v1/services/contracts',
      params: tempParams,
    });
  };

  useEffect(() => {
    handleRequest(params);
  }, []);

  return (
    <>
      <TitleContainer>
        <Typography className="title" variant="p">
          Job Contracts
        </Typography>
      </TitleContainer>
      {!!loading && <JobContractSkeleton />}

      {!loading && !contracts?.data?.length && <EmptyPage />}
      {!loading &&
        !!contracts?.data?.length &&
        contracts.data.map(contract => (
          <>
            <JobContractItem
              key={contract.id}
              contract={contract}
              refreshData={() => handleRequest(params)}
            />
            <Divider />
          </>
        ))}
    </>
  );
};
export default OrderTableView;
const options: optionTypes = {
  featureoption: [
    {
      data: [
        {
          value: 'all',
          label: 'All',
        },
        {
          value: 'active',
          label: 'Active',
        },
        {
          value: 'completed',
          label: 'Completed',
        },
      ],
      placeholder: 'Contract Status',
      key: 'contractStatus',
    },
  ],

  sortingoption: [
    {
      data: [
        {
          value: 'Price High to Low',
          label: 'Price High to Low',
        },
        {
          value: 'Price Low to High',
          label: 'Price Low to High',
        },
      ],
      placeholder: 'Price Status',
      key: 'priceStatus',
    },
  ],
};
