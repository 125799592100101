import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AuthWrapper from './AuthWrapper';
import Modal from '@/newComponents/Modal';
import Typography from '@/newComponents/Typography';
import useAPI from '@/hooks/api';
import { useRoot } from '@/RootProvider';
import OTPInput from './OTPInput';
import Error from '@shared/web/components/Error';
import Button from '@/newComponents/Button';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import store from '@/store';
import { useResponsive } from '@/hooks/useResponsive';

function useQuery() {
  const query = new URLSearchParams(useLocation().search);

  return {
    email: query.get('email'),
    verificationCode: query.get('verificationCode'),
  };
}

const VerifyOTPModal = () => {
  const { setAuth } = useRoot();
  const { state }: any = useLocation();
  const [code, setCode] = useState('');
  const navigate = useNavigate();
  const [visible, setVisible] = useState();
  const [handleSubmitRequest, { loading, error }] = useAPI();
  const dispatch = useDispatch();
  const { isTablet } = useResponsive();
  const authState = useSelector(store.Select.authModal);

  useEffect(() => {
    if (!authState?.email) {
      // openAuthModal('login');
    }
  }, []);

  const closeAuthModal = () => {
    dispatch(
      store.Actions.set('authModal', { status: false, type: undefined }),
    );
  };

  const openAuthModal = (type: string) => {
    dispatch(store.Actions.set('authModal', { status: true, type }));
  };

  const handleSubmit = async code => {
    const data = await handleSubmitRequest({
      method: 'post',
      url: '/v1/auth/verify',
      data: { code, email: authState?.email },
    });

    toast('Your account verified successfully. Now you can login');
    // navigate('/login', { replace: true });
    openAuthModal('login');
  };

  return (
    <Modal
      open={authState?.status && authState?.type === 'verifyOTP'}
      onClose={closeAuthModal}
      isCentered={true}
      size={isTablet ? 'md' : 'lg'}
      style={{ maxWidth: isTablet ? '600px' : '100%', width: '100%' }}
      animationDirection="top"
    >
      <div
        style={{
          textAlign: 'center',
        }}
      >
        <Typography variant="p" fontWeight="bold" size="md">
          Verify Account
        </Typography>
        <OTPInput
          length={6}
          loading={loading}
          defaultValue={''}
          onComplete={code => {
            setCode(code);
          }}
        />
        <Typography variant="p">
          (We have sent you an email with verification code)
        </Typography>
        <Error message={error} isFormError />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            isLoading={loading}
            radius="round"
            isDisabled={loading}
            onClick={() => handleSubmit(code)}
          >
            Verify Account
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default VerifyOTPModal;
