import Error from '@shared/web/components/Error';
import { Form, Formik, FormikProps } from 'formik';
import useAPI from '@/hooks/api';
import { debounce } from 'lodash';
import React, { useRef, useState } from 'react';
import Select from 'react-select/async';
import request from '@shared/front/utils/request';
import * as yup from 'yup';
import Map from '@/newComponents/Map';
import { ButtonsContainer, StyledButton, Title } from './index.styles';
const DUBLIN = {
  lat: 53.34304171021489,
  lng: -6.264192673164263,
  address: 'DUBLIN',
};

const Location = ({ google, initialValues, onSubmitStep }: any) => {
  const formikRef = useRef<FormikProps<any>>(null);
  const [selectedCoordinates, setSelectedCoordinates] = useState<any>({
    latitude: DUBLIN.lat,
    longitude: DUBLIN.lng,
  });

  const [handleSubmitRequest, { loading, error }] = useAPI();
  const [targetButton, setTargetButton] = useState<string>('');
  const getLatLongFromPlaceId = async (placeId: string) => {
    try {
      const res = await request({
        method: 'get',
        url: '/v1/common/google-geocode',
        params: {
          placeId,
        },
      });
      const { lat, lng } = res.geometry.location;
      formikRef.current?.setFieldValue('address', res.formatted_address);
      formikRef.current?.setFieldValue('latitude', lat);
      formikRef.current?.setFieldValue('longitude', lng);
      setSelectedCoordinates({ latitude: lat, longitude: lng });
      return false;
    } catch (err) {
      console.log(err);
    }
  };

  const fetchPlaces = async (query: string) => {
    if (!query.trim()) {
      return [];
    }
    try {
      const res = await request({
        method: 'get',
        url: '/v1/common/google-places',
        params: {
          input: query.trim(),
        },
      });

      return res.map((place: any) => ({
        label: place.description,
        value: place.place_id,
      }));
    } catch (err) {
      return [];
    }
  };

  const handleSubmitForm = async (values, isDraft?: boolean) => {
    const url = '/v1/properties';
    const parsedValues = {
      latitude: values.latitude,
      longitude: values.longitude,
      address: values.address,
    };
    try {
      const data = await handleSubmitRequest({
        method: 'put',
        url: initialValues.id ? `${url}/${initialValues.id}` : url,
        data: parsedValues,
      });
      onSubmitStep({ data, isDraft });
    } catch (error) {
      console.log(error);
    }
  };

  const debounceSearch = React.useRef(debounce(fetchPlaces, 400)).current;

  const handleSelectChange = (selectedOption: string) => {
    if (selectedOption) {
      getLatLongFromPlaceId(selectedOption.value);
      formikRef.current?.setFieldValue('address', selectedOption.label);
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      onSubmit={values => {
        handleSubmitForm(values);
      }}
      validationSchema={yup.object().shape({
        address: yup.string().required('Please select address'),
      })}
      enableReinitialize
    >
      {({
        isSubmitting,
        errors,
        touched,
        values,
        setFieldValue,
        handleSubmit,
        setSubmitting,
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <div style={{ marginBottom: '20px' }}>
              <Title variant="h1">Location</Title>
              <Select
                name="address"
                placeholder={values.address || 'Search address'}
                isClearable
                loadOptions={debounceSearch}
                onChange={e => {
                  handleSelectChange(e);
                }}
                onInputChange={query => debounceSearch(query)}
              />
            </div>
            <Map
              coordinates={[selectedCoordinates]}
              readOnly
              style={{ height: '50vh' }}
            />
            <Error message={error || errors?.address} isFormError />
            <ButtonsContainer>
              <StyledButton
                variant="gray"
                radius="round"
                onClick={() => {
                  setTargetButton('draft');
                  handleSubmitForm(values, true);
                }}
                type="button"
                isDisabled={targetButton === 'draft' ? loading : false}
                isLoading={targetButton === 'draft' ? loading : false}
              >
                Save as draft
              </StyledButton>
              <StyledButton
                radius="round"
                onClick={() => {
                  setTargetButton('save');
                }}
                htmlType="submit"
                isDisabled={targetButton === 'save' ? loading : false}
                isLoading={targetButton === 'save' ? loading : false}
              >
                Save
              </StyledButton>
            </ButtonsContainer>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Location;
