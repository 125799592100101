import styled from 'styled-components';

export const HomeStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  margin: 35px;
  & .header {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 50px;
  }

  & .propertyContainer {
    display: grid;
    gap: 60px 20px;
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
    margin-bottom: 60px;

    & .property {
      cursor: pointer;
    }
  }

  & .exploreBtn {
    width: auto;
    margin: 0 auto;
    margin-bottom: 60px;
    font-family: poppins;
  }
`;
