import { Skeleton } from 'antd';
import React from 'react';
import styled from 'styled-components';
import Divider from '@/newComponents/Divider';

const SkeletonWrapperStyled = styled.div`
  & .style-img {
    width: 100%;
    margin-bottom: 30px;
  }

  & .skeleton-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    & .paragraph {
      flex: 1;
      max-width: 500px;
    }
    & .button {
      width: 150px;
    }
  }
`;

type Props = {};

const BookingLoadingView = (props: Props) => {
  return (
    <SkeletonWrapperStyled>
      <Skeleton.Input
        active
        className="style-img"
        style={{ width: '100%', height: '100px' }}
      />
      <div className="skeleton-wrapper">
        <Skeleton className="paragraph"></Skeleton>
        <Skeleton.Button className="button"></Skeleton.Button>
      </div>
      <Divider />
      <div className="skeleton-wrapper">
        <Skeleton className="paragraph"></Skeleton>
        <Skeleton.Button className="button"></Skeleton.Button>
      </div>
      <Divider />
      <div className="skeleton-wrapper">
        <Skeleton className="paragraph"></Skeleton>
        <Skeleton.Button className="button"></Skeleton.Button>
      </div>
      <Divider />
      <div className="skeleton-wrapper">
        <Skeleton className="paragraph"></Skeleton>
        <Skeleton.Button className="button"></Skeleton.Button>
      </div>
    </SkeletonWrapperStyled>
  );
};

export default BookingLoadingView;
