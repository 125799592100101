import React, { useState, useEffect } from 'react';
import Modal from '@/newComponents/Modal';
import Button from '@/newComponents/Button';
import { Formik, Form, Field } from 'formik';
import { ModalFooter } from './style';
import * as yup from 'yup';
import InputField from '@/components/InputField';
import { toast } from 'react-toastify';
import { Store, Utils, Apis } from '@shared/front';
import { useSelector } from 'react-redux';
import { StyledLabel } from '../index.styles';
import SelectField from '@/components/SelectField';
import { useRoot } from '@/RootProvider';
import request from '@shared/front/utils/request';
import styled from 'styled-components';
import Typography from '@/newComponents/Typography';
import DeleteSVG from '../../../assets/delete-icon-new.svg';

const PropertyView = styled.div`
  margin-bottom: 2px;
  flex-direction: row;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0px 4px;
`;
const PropertyImage = styled.img`
  height: 34px;
  width: 60px;
  object-fit: cover;
  border-radius: 2px;
  margin-right: 10px;
`;
interface InvitationRequestProps {
  setOpen: any;
  open: boolean;
  serviceId: number;
}
const createRoomSchema = yup.object().shape({
  rules: yup.string().required('Title is required.'),
});
const InvitationRequestModal = ({
  setOpen,
  open,
  serviceId,
}: InvitationRequestProps) => {
  const { auth: user } = useRoot();
  const [selectedJob, setSelectedJob] = useState<Array<number>>([]);
  const [job, setJob] = useState([]);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const handleInvitationRequest = async (serviceIds: number[]) => {
    try {
      if (!serviceIds?.length) {
        toast.warn('Select at least one job for invitation');
        return;
      }

      setLoading(true);
      const payload = { authId: serviceId, serviceIds };
      await Utils.request(Apis.Auth.sendInvitation(payload));
      setOpen(false);
      setLoading(false);
      toast.success('Invitation send successfully');
    } catch (err) {
      toast.error('Failed to Send Invitation Request');
      setLoading(false);
    }
  };

  const getServices = async () => {
    try {
      const res = await Utils.request(
        Apis.Services.getServices({
          role: 'landlord',
          params: { limit: 25, status: 'Active' },
        }),
      );
      setJob(
        res?.data?.map((item: any) => ({
          title: item?.title,
          serviceId: item?.id,
        })),
      );
      setServices(
        res.data.map((item: any) => {
          return {
            label: (
              <PropertyView className="selected">
                {/* <PropertyImage src={item.images[0]?.URL} /> */}
                <Typography variant="h1" style={{ marginBottom: 0 }}>
                  {item.title}
                </Typography>
              </PropertyView>
            ),
            value: item.id,
          };
        }),
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getServices();
  }, []);

  const getJobName = (serviceId: number) => {
    const propertyDetails = job?.filter(
      (item: any) => item?.serviceId === serviceId,
    )[0];
    return propertyDetails;
  };

  const onRemove = (id: number) => {
    setSelectedJob(prev => prev.filter(item => item !== id));
  };

  return (
    <>
      {!!open && (
        <Modal
          isCentered
          title={`Send Invitation`}
          open={open}
          onClose={() => {
            setOpen(false);
            setSelectedJob([]);
          }}
          size={'sm'}
        >
          <Formik
            initialValues={selectedJob}
            validationSchema={createRoomSchema}
            onSubmit={async (values, { resetForm }) => {
              await handleInvitationRequest(values);
              resetForm();
            }}
            validateOnBlur={false}
            enableReinitialize
          >
            {({ values, handleSubmit, setFieldValue }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <StyledLabel>Job</StyledLabel>
                  <Field
                    component={SelectField}
                    name="serviceId"
                    options={services}
                    setFieldValue={setFieldValue}
                    isSearchable={true}
                    onSelectOption={(options: any) => {
                      if (options?.value) {
                        setSelectedJob(prev => {
                          if (prev.includes(options?.value)) {
                            return prev.filter(item => item !== options?.value);
                          } else {
                            return [...prev, options?.value];
                          }
                        });
                      }
                    }}
                  />
                  <div>
                    <Typography variant="p" style={{ margin: '30px 0px 10px' }}>
                      Selected Job Names are:
                    </Typography>
                    {selectedJob.map((id, index) => (
                      <SelectedPropertyWrapper>
                        <div>{getJobName(id)?.title}</div>
                        <img
                          src={DeleteSVG}
                          alt="delete"
                          onClick={() => onRemove(id)}
                        />
                      </SelectedPropertyWrapper>
                    ))}
                    {selectedJob?.length === 0 && (
                      <div>
                        <Typography
                          variant="p"
                          size="sm"
                          color="#666"
                          style={{ textAlign: 'center' }}
                        >
                          Select job to invite.
                        </Typography>
                      </div>
                    )}
                  </div>

                  <ModalFooter>
                    <Button
                      isLoading={loading}
                      style={{ marginLeft: 'auto' }}
                      isDisabled={selectedJob?.length === 0 || loading}
                    >
                      Invite
                    </Button>
                  </ModalFooter>
                </Form>
              );
            }}
          </Formik>
        </Modal>
      )}
    </>
  );
};

export default InvitationRequestModal;

const SelectedPropertyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px;
  background: #f0f0f0;
  padding: 6px 10px;
  border-radius: 6px;

  & img {
    cursor: pointer;
  }
`;
