import React from 'react';
import styled, { css } from 'styled-components';
import Typography from './Typography';
import Button from './Button';

interface ItemProps {
  title?: string;
  rate?: number;
  address?: string;
  description?: string;
  tags?: string[];
  priceType?: string;
  onClick: () => void;
}

const fontStylesLabel = css`
  font-size: 14px;
  font-weight: 400;
  color: #333333;
`;

const fontStylesValue = css`
  font-size: 14px;
  color: #8a8a8a;
`;

const Container = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background-color: #ebebeb;
  border-radius: 10px;
  padding: 24px 17px;
  cursor: pointer;
`;

const Title = styled(Typography)`
  font-weight: 500;
  margin-bottom: 8px;
`;

const Rate = styled(Typography)`
  ${fontStylesLabel}
  margin-bottom: 5px;
`;

const Amount = styled(Typography)`
  ${fontStylesValue}
`;

const Location = styled(Typography)`
  ${fontStylesLabel}
  margin-bottom: 10px;
`;

const Address = styled(Typography)`
  ${fontStylesValue}
`;

const Description = styled(Typography)`
  ${fontStylesLabel}
  margin-bottom: 6px;
`;

const Desc = styled(Typography)`
  ${fontStylesValue}

  font-size: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 16px;
  align-items: center;
`;

const JobCard: React.FC<ItemProps> = ({
  title = 'N/A',
  rate = 'N/A',
  address = 'N/A',
  description = 'N/A',
  tags = [],
  priceType = 'N/A',
  onClick,
}) => {
  const tagsToDisplay = tags?.slice(0, 3);
  return (
    <Container onClick={onClick}>
      <div>
        <Title variant="h2" size="sm" color="#1f1f1f">
          {title}
        </Title>

        <Rate variant="p">
          {priceType === 'hourly'
            ? 'Hourly Rate'
            : priceType === 'fixed'
            ? 'Fixed Rate'
            : 'Quoted rate'}{' '}
          <Amount variant="span">&euro;{rate}</Amount>
        </Rate>
        <Location variant="p">
          Location <Address variant="span">{address}</Address>
        </Location>
        <Description variant="p">Description</Description>
        <Desc variant="p">{description}</Desc>
      </div>
      <TagsContainer>
        {tagsToDisplay?.map(tag => (
          <Button
            variant="tag"
            radius="round"
            bgColor="#9747ff5e"
            style={{ color: '#424242' }}
          >
            {tag}
          </Button>
        ))}
        {tags?.length > 3 && (
          <Typography style={{ fontSize: '10px' }} variant="p">{`+${
            tags.length - 3
          } more`}</Typography>
        )}
      </TagsContainer>
    </Container>
  );
};

export default JobCard;
