import React, { useEffect, useRef, useState } from 'react';
import {
  ConversationItem,
  ConversationItemWrapper,
  ConversationWrapper,
  UserImage,
  UserImageWrapper,
  UserInfoWrapper,
  UserOnlineStatus,
} from './style';
import DefaultUser from '../../assets/defaultuser.png';
import Typography from '@/newComponents/Typography';
import { Utils } from '@shared/front';
import { useRoot } from '@/RootProvider';
import moment from 'moment';
interface ConversationListProps {
  conversations: any[];
  handleSelectConversation: (conversation: any) => void;
  currentConversation: any;
  child?: boolean;
}
const ConversationList = ({
  conversations,
  handleSelectConversation,
  currentConversation,
  child,
}: ConversationListProps) => {
  const { auth: user } = useRoot();
  const [autoScrollConversationId, setAutoScrollConversationId] =
    useState(null);

  const myRef = useRef<HTMLDivElement>(null);
  const executeScroll = () => {
    if (myRef?.current) {
      myRef?.current?.scrollIntoView();
    }
  };

  useEffect(() => {
    if (autoScrollConversationId) {
      if (!child) {
        executeScroll();
      }
    }
  }, [autoScrollConversationId, myRef.current]);

  return (
    <ConversationWrapper>
      {!!conversations?.length &&
        conversations
          ?.filter((conversation: any) => !!conversation)
          ?.map((conversation: any) => {
            const latestChat = conversation?.chats[0];
            const checkJsonOrNot = Utils.isJsonString(latestChat?.message);
            const message = !!checkJsonOrNot?.length
              ? checkJsonOrNot
              : latestChat?.message;
            const chatStatus = latestChat?.chatBy === user?.role;
            const hasSeen =
              latestChat?.chatBy !== user?.role ? latestChat?.isRead : true;

            return (
              <ConversationItem
                key={conversation?.id}
                onClick={() => {
                  handleSelectConversation(conversation);
                  if (!child) {
                    setAutoScrollConversationId(conversation?.id);
                  }
                }}
                active={conversation?.id === currentConversation?.id}
                ref={
                  conversation?.id === currentConversation?.id && !child
                    ? myRef
                    : null
                }
              >
                <ConversationItemWrapper>
                  <UserImageWrapper child={child}>
                    <UserImage
                      child={child}
                      src={
                        !!conversation?.avatar
                          ? conversation?.avatar
                          : DefaultUser
                      }
                    />
                    <UserOnlineStatus
                      active={conversation?.isActive}
                      child={child}
                    />
                  </UserImageWrapper>
                  <UserInfoWrapper>
                    <Typography
                      variant="h4"
                      size="sm"
                      color={
                        conversation?.id === currentConversation?.id
                          ? '#00157f'
                          : hasSeen
                          ? '#949494'
                          : '#000'
                      }
                      fontWeight={hasSeen ? 'normal' : 'bold'}
                    >
                      {!!conversation?.buyer && latestChat?.chatBy === 'buyer'
                        ? conversation?.seller?.fullName
                        : !!conversation?.seller &&
                          latestChat?.chatBy === 'seller'
                        ? conversation?.buyer?.fullName
                        : conversation?.fullName}
                    </Typography>
                    <Typography
                      variant="p"
                      style={{
                        fontSize: 12,
                        marginTop: 3,
                        overflow: 'hidden',
                        width: '200px',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        height: '14px',
                      }}
                      color={
                        conversation?.id === currentConversation?.id
                          ? '#fff'
                          : '#949494'
                      }
                    >
                      {latestChat?.chatBy === user?.role ||
                      conversation?.buyer?.buyerId === user?.id ||
                      conversation?.buyer?.sellerId === user?.id
                        ? 'You: '
                        : `${
                            !!conversation?.buyer &&
                            latestChat?.chatBy === 'buyer'
                              ? conversation?.seller?.fullName
                              : !!conversation?.seller &&
                                latestChat?.chatBy === 'seller'
                              ? conversation?.buyer?.fullName
                              : conversation?.fullName
                          }: `}
                      {!!message ? (
                        typeof message === 'string' ? (
                          message
                        ) : !!message?.length ? (
                          'send a photo'
                        ) : (
                          'Appointment Request'
                        )
                      ) : (
                        <em>No Messages</em>
                      )}
                    </Typography>
                    {!!chatStatus && (
                      <Typography
                        variant="p"
                        style={{ fontSize: 12, marginTop: 3 }}
                        color={
                          conversation?.id === currentConversation?.id
                            ? '#00157f'
                            : '#949494'
                        }
                      >
                        {!!latestChat?.isDelivered
                          ? !!latestChat?.isRead
                            ? 'Seen'
                            : `Not Seen`
                          : `Not Delivered`}
                      </Typography>
                    )}
                    {!!conversation?.buyer && (
                      <Typography
                        variant="span"
                        style={{
                          marginTop: 5,
                          fontSize: 10,
                          background: '#2857FF',
                          borderRadius: '4px',
                          padding: '1px 4px',
                          color: '#fff',
                        }}
                      >
                        Market Place
                      </Typography>
                    )}
                    {!!latestChat?.createdAt && (
                      <Typography
                        variant="p"
                        color={
                          conversation?.id === currentConversation?.id
                            ? '#00157f'
                            : '#949494'
                        }
                        style={{ fontSize: 12, marginTop: 3 }}
                      >
                        {moment(latestChat?.createdAt).utc().fromNow(true)}
                      </Typography>
                    )}
                  </UserInfoWrapper>
                </ConversationItemWrapper>
              </ConversationItem>
            );
          })}
    </ConversationWrapper>
  );
};

export default ConversationList;
