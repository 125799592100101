import Tabs from '@/newComponents/Tabs';
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { Apis, Socket, Utils } from '@shared/front';
import CreateShortListModal from '../confirmationModals/CreateShortListModal';
import InviteShortListedModal from '../confirmationModals/InviteShortListedModal';
import AcceptBookingModal from '../confirmationModals/AcceptBookingModal';
import useAPI from '@/hooks/api';
import moment from 'moment';
import { useRoot } from '@/RootProvider';
import LongList from './LongList';
import ShortList from './ShortList';
import ViewingList from './ViewingList';
import RejectInvitationModal from '../confirmationModals/RejectBookingModal';

type Props = {
  bookingRequested: any;
  getBookingRequestByProperty: Function;
  propertyId: number;
  loading: boolean;
  disableActions: boolean;
};

const TABS = {
  LONG_LIST: 'longList',
  SHORT_LIST: 'shortList',
  VIEWING_LIST: 'viewingList',
};

const INITIAL_TENANT = {
  firstName: '',
  lastName: '',
  avatar: '',
  id: 0,
  bookingId: 0,
  rating: 0,
};

const TenantsListWithTabs = ({
  bookingRequested,
  getBookingRequestByProperty,
  propertyId,
  loading,
  disableActions,
}: Props) => {
  // HOOKS
  const { auth } = useRoot();
  const [activeKey, setActiveKey] = useState(TABS.LONG_LIST);
  const [selectedShortList, setSelectedShortList] = useState<number[]>([]);
  const [enableShortListProfileModal, setEnableShortListProfileModal] =
    useState(false);
  const [enableInviteShortListedModal, setEnableInviteShortListedModal] =
    useState(false);
  const [enableAcceptBookingModal, setEnableAcceptBookingModal] =
    useState(false);
  const [enableRejectBookingModal, setEnableRejectBookingModal] =
    useState(false);
  const [selectedTenant, setSelectedTenant] = useState(INITIAL_TENANT);
  const [activeShortListIndex, setActiveShortListIndex] = useState('all');

  const [creatingShortList, setCreatingShortList] = useState(false);
  const [invitingShortListForViewing, setInvitingShortListForViewing] =
    useState(false);

  // API
  const [makeAcceptRequest, { loading: acceptLoading }] = useAPI();
  const [makeRejectRequest, { loading: rejectLoading }] = useAPI();

  // SELECTORS

  // --------------------------------------------------------------------------------------------------

  const createSortList = async () => {
    setCreatingShortList(true);
    try {
      await Utils.request(
        Apis.Booking.bookingAddToShortList({
          bookingId: selectedShortList,
          sortListIndex: bookingRequested.totalSortListIndex + 1,
        }),
      );
      toast.success('Profile added to short list.');

      await getBookingRequestByProperty();
      setSelectedShortList([]);
      handleTabChange('shortList');
    } catch (error) {
    } finally {
      setCreatingShortList(false);
    }
  };

  const handleAccept = async (bookingId: number) => {
    await makeAcceptRequest(Apis.Booking.acceptBooking(bookingId));
    getBookingRequestByProperty();
    toast('Booking has been accepted successfully.');
  };

  const handleReject = async (bookingId: number) => {
    await makeRejectRequest(Apis.Booking.declineBooking(bookingId));
    toast('Booking has been rejected.');
  };

  const sendGroupMessage = async (values: any) => {
    try {
      const formatData = bookingRequested?.tenants?.map(item => {
        return {
          tenantId: item.id,
          landlordId: auth.id,
          bookingId: item.bookingId,
          chatType: 'booking',
        };
      });

      const { appointmentDate, appointmentTime, message } = values;
      const data = {
        conversationPayload: formatData,
        message: message,
        appointmentDate: `${moment(appointmentDate).format(
          'YYYY-MM-DD',
        )} ${moment(appointmentTime, 'HH:mm A').format('HH:mm:ss')}`,
      };

      await Socket.createGroupMessage(data);
    } catch (err) {
      console.log(err, 'err');
    }
  };

  const inviteSortListForPropertyViewing = async (values: any) => {
    setInvitingShortListForViewing(true);
    try {
      sendGroupMessage(values);
      toast.success('Short listed profile are invited for property viewing.');
    } catch (error) {
    } finally {
      setInvitingShortListForViewing(false);
    }
  };

  const handleSelectTenantForShortList = event => {
    const { value } = event.target;
    const id = parseInt(value);
    setSelectedShortList(prev =>
      prev.includes(id) ? prev.filter(x => x !== id) : [...prev, id],
    );
  };

  const handleTabChange = tab => {
    setSelectedShortList([]);
    setActiveKey(tab);
    if (tab === TABS.SHORT_LIST) {
      getBookingRequestByProperty({
        sortList: 1,
      });
    }
    if (tab === TABS.VIEWING_LIST) {
      getBookingRequestByProperty({
        isViewingList: +1,
      });
    }
    if (tab === TABS.LONG_LIST) {
      getBookingRequestByProperty({
        longList: 1,
      });
    }
  };

  const handleShortListFilter = value => {
    setActiveShortListIndex(value);
  };

  const getFilteredTenantsForInviting = (_tenants, _shortListIndex) => {
    if (_shortListIndex === 'all') {
      return _tenants;
    }

    return _tenants.filter(x => x.sortListIndex === Number(_shortListIndex));
  };

  const items = [
    {
      key: TABS.LONG_LIST,
      label: `Long List ( ${bookingRequested?.totalLongList || 0} )`,
      children: (
        <LongList
          loading={loading}
          disableActions={disableActions}
          bookingRequested={bookingRequested}
          selectedShortList={selectedShortList}
          handleSelectTenantForShortList={handleSelectTenantForShortList}
          setEnableShortListProfileModal={setEnableShortListProfileModal}
        />
      ),
    },
    {
      key: TABS.SHORT_LIST,
      label: `Short List ( ${bookingRequested?.totalSortList || 0} )`,
      children: (
        <ShortList
          loading={loading}
          disableActions={disableActions}
          bookingRequested={bookingRequested}
          selectedShortList={selectedShortList}
          handleSelectTenantForShortList={handleSelectTenantForShortList}
          setEnableInviteShortListedModal={setEnableInviteShortListedModal}
          activeShortListIndex={activeShortListIndex}
          handleShortListFilter={handleShortListFilter}
        />
      ),
    },
    {
      key: TABS.VIEWING_LIST,
      label: `Viewing List ( ${bookingRequested?.totalViewListCount || 0} )`,
      children: (
        <ViewingList
          loading={loading}
          disableActions={disableActions}
          bookingRequested={bookingRequested}
          selectedShortList={selectedShortList}
          handleSelectTenantForShortList={handleSelectTenantForShortList}
          setEnableAcceptBookingModal={setEnableAcceptBookingModal}
          setEnableRejectBookingModal={setEnableRejectBookingModal}
          setSelectedTenant={setSelectedTenant}
        />
      ),
    },
  ];
  return (
    <>
      <Tabs
        activeKey={activeKey}
        onChange={handleTabChange}
        items={items}
        activeTabColor="#2061f8"
      />
      <CreateShortListModal
        tenants={bookingRequested?.tenants}
        selectedTenants={selectedShortList}
        isOpen={enableShortListProfileModal}
        onClose={() => setEnableShortListProfileModal(false)}
        onCreateShortList={createSortList}
        isLoading={creatingShortList}
      />

      <InviteShortListedModal
        selectedShortListed={getFilteredTenantsForInviting(
          bookingRequested?.tenants,
          activeShortListIndex,
        )}
        isOpen={enableInviteShortListedModal}
        onClose={() => setEnableInviteShortListedModal(false)}
        onInviteShortListed={inviteSortListForPropertyViewing}
        isLoading={invitingShortListForViewing}
      />

      <AcceptBookingModal
        isOpen={enableAcceptBookingModal && !!selectedTenant.id}
        onClose={() => {
          setEnableAcceptBookingModal(false);
          setSelectedTenant(INITIAL_TENANT);
        }}
        onAcceptBooking={handleAccept}
        tenant={selectedTenant}
        isLoading={acceptLoading}
      />
      <RejectInvitationModal
        isOpen={enableRejectBookingModal && !!selectedTenant.id}
        onClose={() => {
          setEnableRejectBookingModal(false);
          setSelectedTenant(INITIAL_TENANT);
        }}
        onRejectBooking={handleReject}
        tenant={selectedTenant}
        isLoading={rejectLoading}
      />
    </>
  );
};

export default TenantsListWithTabs;
