import React from 'react';
import FeatureCard from '../FeatureCard';
import Typography from '@/newComponents/Typography';

const Specification = ({ specification }: any) => {
  const parsedData = JSON.parse(specification);

  const specData =
    parsedData?.specification && parsedData?.specification.length > 0
      ? parsedData?.specification.map(({ title, value }: any) => ({
          title,
          desc: value,
        }))
      : [];

  return (
    <>
      <Typography size="md">Specification Details</Typography>
      <FeatureCard featureData={specData} />
    </>
  );
};

export default Specification;
// specification
// :
// "{\"specification\":[{\"title\":\"TV\",\"value\":\"0.00\"}]}"
