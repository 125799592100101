const berNo = [
  {
    label: "A1",
    value: "A1",
  },
  {
    label: "A2",
    value: "A2",
  },
  {
    label: "A3",
    value: "A3",
  },
  {
    label: "B1",
    value: "B1",
  },
  {
    label: "B2",
    value: "B2",
  },
  {
    label: "B3",
    value: "B3",
  },
  {
    label: "C1",
    value: "C1",
  },
  {
    label: "C2",
    value: "C2",
  },
  {
    label: "C3",
    value: "C3",
  },
  {
    label: "D1",
    value: "D1",
  },
  {
    label: "D2",
    value: "D2",
  },
  {
    label: "E1",
    value: "E1",
  },
  {
    label: "E2",
    value: "E2",
  },
  {
    label: "F",
    value: "F",
  },
  {
    label: "G",
    value: "G",
  },
  {
    label: "EXEMPT",
    value: "EXEMPT",
  },
];

export default berNo;
