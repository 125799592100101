import { Skeleton } from 'antd';
import React from 'react';
import styled from 'styled-components';

type Props = {
  number?: number;
};

const ListLoading = ({ number = 1 }: Props) => {
  return Array.from(Array(number).keys()).map(val => (
    <SkeletonWrapperStyled key={val}>
      <Skeleton.Image active />
      <Skeleton active paragraph={{ rows: 2 }}></Skeleton>
      <Skeleton.Button active />
    </SkeletonWrapperStyled>
  ));
};

export default ListLoading;
const SkeletonWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  & div + div {
    margin-left: 15px;
  }
`;
