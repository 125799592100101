import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Location from './Location';
import BasicInfo from './BasicInfo';
import Specification from './Specification';
import ImagesUploader from './ImagesUploader';
// import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { Apis, Store, Utils } from '@shared/front';
import styled from 'styled-components';
// import { Grid, Tabs } from 'antd';
import { toast } from 'react-toastify';
// import request from '@shared/front/utils/request';
// import useAPI from '@/hooks/api';
// import Photos from './TestImage';
import Stepper from '@/newComponents/Stepper';

// const validation = {
//   address: yup.object().shape({
//     address: yup.string().required('Please enter address'),
//   }),
//   basicInfo: yup.object().shape({
//     categoryId: yup.string().required('Please select category'),
//     subCategoryId: yup.string().required('Please select sub category'),
//     title: yup.string().required('Please enter title'),
//     price: yup.number().required('Please enter price'),
//     adsExpiry: yup.string().required('Please enter expiry date '),
//     desc: yup.string().required('Please enter description'),
//   }),
//   specification: yup.object().shape({
//     specification: yup
//       .array()
//       .min(1, 'Please add specification')
//       .of(
//         yup.object().shape({
//           title: yup.string().required('Please enter title'),
//           value: yup.string().required('Please enter value'),
//         }),
//       ),
//   }),
//   photos: yup.object().shape({
//     images: yup
//       .array()
//       .min(1, 'Please upload photos')
//       .required('Please upload photos'),
//   }),
// };

const Container = styled.div`
  padding: 20px;
  display: flex;
  /* gap: 200px; */
  flex-direction: row;
  min-height: 600px;
  padding-top: 60px;
`;

// const TabsStyle = styled(Tabs)`
//   width: 700px;
// `;

//on submit steps

const AddProduct = () => {
  // const { useBreakpoint } = Grid;
  // const screens = useBreakpoint();
  // console.log('the screens', screens);
  const { id: marketProductId } = useParams();
  const navigate = useNavigate();
  const marketProduct: any = useSelector(Store.Select.marketProduct);
  // const [getProductRequest, { data: product, error: productError }] = useAPI();
  const [validatedTab, setValidatedTab] = useState(0);
  const [step, setStep] = useState(0);
  // const [currentStep, setCurrentStep] = useState<number>(1);
  const [initialValues, setInitialValues] = useState({
    id: marketProductId ? marketProduct?.id : null,
    title: marketProductId
      ? marketProduct?.title !== null
        ? marketProduct?.title
        : ''
      : '',
    listType: marketProductId
      ? marketProduct?.listType !== null
        ? marketProduct?.listType
        : ''
      : '',
    listDesc: marketProductId
      ? marketProduct?.listDesc !== null
        ? marketProduct?.listDesc
        : ''
      : '',

    categoryId: marketProductId
      ? marketProduct?.categoryId !== null
        ? marketProduct?.categoryId
        : ''
      : '',
    subCategoryId: marketProductId
      ? marketProduct?.subCategoryId !== null
        ? marketProduct?.subCategoryId
        : ''
      : '',
    condition: marketProductId
      ? marketProduct?.condition !== null
        ? marketProduct?.condition
        : ''
      : '',
    delivery: marketProductId
      ? marketProduct?.delivery !== null
        ? marketProduct?.delivery
        : ''
      : '',
    negotiable: marketProductId
      ? marketProduct?.negotiable !== null
        ? marketProduct?.negotiable
        : ''
      : '',
    price: marketProductId
      ? marketProduct?.price !== null
        ? marketProduct?.price
        : ''
      : '',
    address: marketProductId
      ? marketProduct?.address !== null
        ? marketProduct?.address
        : ''
      : '',
    desc: marketProductId
      ? marketProduct?.desc !== null
        ? marketProduct?.desc
        : ''
      : '',
    adsExpiry: marketProductId
      ? marketProduct?.adsExpiry !== null
        ? marketProduct?.adsExpiry
        : ''
      : '',
    specification: marketProduct?.specification || null,
    productImage: marketProductId
      ? [
          ...(marketProduct?.productImage?.map((image: any) => ({
            key: image?.url,
            URL: image?.url,
            id: image?.id,
          })) || []),
          null,
        ]
      : [null],
    latitude: marketProductId ? marketProduct?.latitude : '',
    longitude: marketProductId ? marketProduct?.longitude : '',
  });

  const steps = [0, 1, 2, 3];

  const onSubmitStep = async ({ data, isDraft, skipStep }: any) => {
    // console.log('the data after submit steps', data);
    if (isDraft) {
      navigate('/mymarketplace');
      toast('Your product is saved as draft. You can complete it later.');
    } else {
      const url = '/v1/marketPlace/products';
      if (skipStep) {
        setStep(step + 1);
        setValidatedTab(prev => Math.max(step + 1, prev));
      } else if (step < steps.length - 1) {
        setInitialValues(prev => ({
          ...prev,
          ...data,
          id: prev.id || data.id,
        }));
        setStep(step + 1);
        setValidatedTab(prev => Math.max(step + 1, prev));
      } else {
        try {
          navigate('/mymarketplace');
          toast('Your product creation has been completed');
        } catch (err) {}
      }
    }
  };

  const getProduct = async marketProductId => {
    const response = await Utils.request(
      Apis.Market.getMarketProduct(marketProductId),
    );
    // console.log('the response', response);
    setInitialValues({
      ...response,
      // specification: !!response?.specification
      //   ? JSON.parse(response?.specification)?.specification
      //   : [{ title: '', value: '' }],

      productImage: [...response.productImage, null],
    });
  };
  // console.log('the inital value', initialValues);

  useEffect(() => {
    if (!!marketProductId) {
      getProduct(marketProductId);
    }
  }, []);

  const chidProps = {
    initialValues,
    setInitialValues,
    setStep,
    setValidatedTab,
    step,
    onSubmitStep,
  };

  const items = [
    {
      key: 0,
      label: 'Address',
      children: <Location {...chidProps} />,
    },
    {
      key: 1,
      label: 'Basic Info',
      children: <BasicInfo {...chidProps} />,
    },
    {
      key: 2,
      label: 'Specification',
      children: <Specification {...chidProps} />,
    },
    {
      key: 3,
      label: 'Image',
      children: <ImagesUploader {...chidProps} />,
    },
  ];

  return (
    <Container>
      <Stepper
        activeStep={step}
        stepItems={items}
        enableTabClick={!!marketProductId}
        onTabClick={data => {
          setStep(data?.key);
        }}
      />
    </Container>
  );
};

export default AddProduct;
