import { toast } from 'react-toastify';
import React from 'react';
import Modal from '@/newComponents/Modal';
import { Apis, Hooks } from '@shared/front';
import { localeNumberFormat } from '@shared/front/utils/numberFormat';
import {
  ButtonsContainer,
  Container,
  ModalMainText,
  ModalSubText,
  StyledButton,
} from './modalStyles';
import ColorFulText from '@shared/web/components/ColorFulText';
import { useRoot } from '@/RootProvider';
import { TRANSACTION_TYPE } from '@/constants';

type Props = {
  isVisible: boolean | undefined;
  onCancel: Function;
  booking: any;
  getBooking: any;
};

const RentPaymentModal = ({
  booking,
  getBooking,
  isVisible,
  onCancel,
}: Props) => {
  const [handleSubmitPayment, { loading: paymentLoading }] = Hooks.useAPI();
  const { auth } = useRoot();
  const authId = auth?.id;
  const handleCancel = () => {
    onCancel && onCancel();
  };

  const handlePayment = async () => {
    try {
      await handleSubmitPayment(
        Apis.Booking.securityDeposit({
          amount: booking?.price,
          debitedFrom: authId,
          creditedTo: booking?.landlord?.id,
          transactionType: TRANSACTION_TYPE.Rent,
          bookingId: booking?.id,
          remark: 'Release property fare to landlord',
        }),
      );
      await getBooking();
      toast.success('Payemnt for the Rent is done');
      handleCancel();
    } catch (err) {
      toast.error(err);
    }
  };

  return (
    <Modal title="Rent Payment" onClose={handleCancel} open={isVisible}>
      <Container>
        <ModalMainText variant="p">Pay rent to Escrow</ModalMainText>
        <ColorFulText color="primary">
          <ModalMainText variant="p">
            {localeNumberFormat(booking?.price)}
          </ModalMainText>
        </ColorFulText>
        <ModalSubText variant="p">
          Your credit card will be used to charge the amount.
        </ModalSubText>
        <ButtonsContainer>
          <StyledButton variant="gray" radius="round" onClick={handleCancel}>
            Cancel
          </StyledButton>
          <StyledButton
            radius="round"
            isLoading={paymentLoading}
            isDisabled={paymentLoading}
            onClick={handlePayment}
          >
            Pay
          </StyledButton>
        </ButtonsContainer>
      </Container>
    </Modal>
  );
};

export default RentPaymentModal;
