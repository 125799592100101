import Button from '@/newComponents/Button';
import Typography from '@/newComponents/Typography';
import { devices } from '@/utils/theme';
import styled from 'styled-components';

export const ButtonWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  & .filter-list {
    display: flex;
    align-items: center;

    & button {
      padding: 2px 5px;
      margin: 0px 1px;
      border-radius: 6px;
    }
  }

  & .icons {
    margin-right: 5px;
  }
`;

export const StyledButton = styled(Button)`
  font-size: 12px;
  line-height: 25px;
  white-space: nowrap;
`;

export const Info = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  line-height: 25px;

  @media ${devices.laptop} {
    font-size: 14px;
    line-height: 30px;
  }
`;

export const TenantsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 20px;

  @media ${devices.laptop} {
    gap: 14px;
  }
`;

export const DescContainer = styled.div`
  padding: 14px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
`;

export const FeedbackPrompt = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;

  @media ${devices.laptop} {
    flex-direction: row;
    justify-content: space-between;
  }
`;
