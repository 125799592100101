import React from 'react';
import styled from 'styled-components';

const CustomTooltipWrapper = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;

  .tooltip-content {
    visibility: hidden;
    width: 160px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 8px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.2s;
  }

  &:hover .tooltip-content {
    visibility: visible;
    opacity: 1;
  }
`;

interface CustomTooltipProps {
  title: string | React.ReactNode;
  children: React.ReactNode;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ title, children }) => {
  return (
    <CustomTooltipWrapper>
      {children}
      <div className="tooltip-content">{title}</div>
    </CustomTooltipWrapper>
  );
};

export default CustomTooltip;
