import Typography from '@/newComponents/Typography';
import Button from '@/newComponents/Button';
import { devices } from '@/utils/theme';
import React, { useState } from 'react';
import styled from 'styled-components';
// import Inventory from '@/containers/PropertyView/inventory';
import InvitationRequestModal from './InvitationRequestModal';

type Props = { userDesired: any };

const DesireListForService = ({ userDesired }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const trades = userDesired?.trades?.key?.split(',');
  return (
    <Container>
      <TitleContainer>
        <Typography
          variant="h3"
          fontWeight={'extrabold'}
          style={{
            fontSize: '16px',
          }}
        >
          Desired Service
        </Typography>
        <Button
          style={{ float: 'right' }}
          variant="primary"
          size="md"
          onClick={() => {
            setOpen(true);
          }}
        >
          Invite
        </Button>
      </TitleContainer>
      <InfoWrapper>
        <InfoContainer>
          <InfoLabel variant="p">
            Location&nbsp;&nbsp;&nbsp;
            <Info variant="span">{userDesired?.address || 'N/A'}</Info>
          </InfoLabel>
          <InfoLabel variant="p">
            Location From&nbsp;&nbsp;&nbsp;
            <Info variant="span">{userDesired?.desiredFrom || 'N/A'}</Info>
          </InfoLabel>
          <InfoLabel variant="p">
            Location To&nbsp;&nbsp;&nbsp;
            <Info variant="span">{userDesired?.desiredTo || 'N/A'}</Info>
          </InfoLabel>
          <InfoLabel variant="p">
            Move Date&nbsp;&nbsp;&nbsp;
            <Info variant="span">{userDesired?.moveStatus || 'N/A'}</Info>
          </InfoLabel>
          <InfoLabel variant="p">
            Price&nbsp;&nbsp;&nbsp;
            {userDesired?.price ? (
              <Info variant="span">&euro; {userDesired?.price}</Info>
            ) : (
              <Info variant="span"> N/A</Info>
            )}
          </InfoLabel>
          <InfoLabel variant="p">
            Target Price&nbsp;&nbsp;&nbsp;
            {userDesired?.targetPrice ? (
              <Info variant="span">&euro; {userDesired?.targetPrice}</Info>
            ) : (
              <Info variant="p">N/A</Info>
            )}
          </InfoLabel>
          <InfoLabel variant="p">
            Commute Distance&nbsp;&nbsp;&nbsp;
            <Info variant="span">
              {userDesired?.distance ? userDesired?.distance + ' KM' : 'N/A'}
            </Info>
          </InfoLabel>
          <InfoLabel variant="p">
            Commute Time&nbsp;&nbsp;&nbsp;
            <Info variant="span">
              {userDesired?.time ? userDesired?.time + ' mins' : 'N/A'}
            </Info>
          </InfoLabel>
          <InfoLabel variant="p">
            Payment Type&nbsp;&nbsp;&nbsp;
            <Info variant="span">{userDesired?.paidType || 'N/A'}</Info>
          </InfoLabel>
          <InfoLabel variant="p">
            Property Type&nbsp;&nbsp;&nbsp;
            <Info variant="span">{userDesired?.propertyType || 'N/A'}</Info>
          </InfoLabel>
        </InfoContainer>
        <FeaturesContainer>
          {Array.isArray(trades) &&
            trades.map(trade => (
              <Typography variant="span" key={trade} className="feature">
                {trade}
              </Typography>
            ))}
        </FeaturesContainer>
      </InfoWrapper>

      <InvitationRequestModal
        setOpen={setOpen}
        open={open}
        serviceId={userDesired?.authId}
      />
    </Container>
  );
};

export default DesireListForService;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media ${devices.laptop} {
    gap: 16px;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media ${devices.laptop} {
    gap: 20px;
  }
`;

const Address = styled(Typography)`
  font-size: 16px;
  font-weight: 500;

  @media ${devices.laptop} {
    font-size: 26px;
  }

  @media ${devices.laptopXL} {
    font-size: 32px;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media ${devices.laptop} {
    gap: 12px;
  }

  @media ${devices.laptopXL} {
    gap: 16px;
  }
`;

const Info = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  color: #949494;
  @media ${devices.laptop} {
    font-size: 14px;
  }
`;

const InfoLabel = styled(Info)`
  color: #333;
`;

const FeaturesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;

  & .feature {
    font-weight: 500;
    border: 1px solid #dbdbdb;
    padding: 6px 12px;
    border-radius: 30px;
    font-size: 13px;
  }
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
