import { useRoot } from '@/RootProvider';
import Divider from '@/newComponents/Divider';
import Tabs from '@/newComponents/Tabs';
import Typography from '@/newComponents/Typography';
import { Apis, Store, Utils } from '@shared/front';
import { localeNumberFormat } from '@shared/front/utils/numberFormat';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import styled, { useTheme } from 'styled-components';
import EmptyPage from '../Empty';
import IndividualTransaction, {
  TransactionPropsType,
  TransactionType,
} from './IndividualTransaction';
import TransactionSkeleton from './Skeleton';
import Button from '@/newComponents/Button';
const Container = styled.div`
  padding: 20px;
`;

const ProfileDetails = styled.div`
  color: ${({ theme }) => theme.colors.white};
  width: 100%;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
`;

const RightBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 60px;
  background-color: white;
  z-index: 99;
  background: #fff;
  & .title {
    font-size: 1.6rem;
    font-weight: 500;
  }
  padding: 20px 0px 10px 0px;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 20px;
`;
const OrderTableView = ({}) => {
  const { auth } = useRoot();
  const role = auth?.role;
  const authId = auth?.id;

  const transactions: TransactionPropsType[] = useSelector(
    Store.Select.transactions,
  );

  const [balanceAmount, setBalanceAmount] = useState<number>(0);
  const [activeKey, setActiveKey] = useState('all');
  const [loading, setLoading] = useState(false);
  const isLargeScreen = useMediaQuery({ query: `(min-width:1000px)` });
  const theme = useTheme();

  const [params, setParams] = useState({
    page: 0,
    type: '',
  });
  const [pagination, setPagination] = useState({
    limit: 10,
    page: 0,
    lastPage: 1,
  });

  const getTotalBalance = async () => {
    setLoading(true);
    const response = await Utils.request(Apis.Transaction.getEscrowAmount());
    setBalanceAmount(response);
    setLoading(false);
  };

  const handleRequest = async params => {
    if (params?.type === 'all') {
      params.type = '';
    }
    await fetchTransaction(params);
  };

  const fetchTransaction = async myParams => {
    try {
      setLoading(true);
      const action = myParams?.page === 0 ? 'set' : 'update';
      const response = await Utils.request(
        Apis.Transaction.getTransaction(myParams, action, authId),
      );
      if (response?.pagination) {
        setPagination(response?.pagination);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleRequest(params);
  }, [params]);

  useEffect(() => {
    if (auth?.role === 'landlord' || auth?.role === 'service') {
      getTotalBalance();
    }
  }, []);

  const TransactionTab = [
    {
      key: 'all',
      label: 'All',
    },
    {
      key: 'Security Deposit',
      label: 'SecurityDeposit',
    },
    {
      key: 'Rent',
      label: 'Rent',
    },
    {
      key: 'Service',
      label: 'Service',
    },
    {
      key: 'Refund',
      label: 'Refund',
    },
  ];

  const handleTransactionTabChange = (key: string) => {
    if (key !== '') {
      setParams({ ...params, page: 0, type: key });
    } else {
      setParams({ ...params, page: 0, type: '' });
    }
    setActiveKey(key);
  };

  const fetchMore = () => {
    if (pagination?.lastPage >= pagination?.page) {
      setParams(prev => ({ ...prev, page: prev.page + 1 }));
    }
  };

  const grouppedTransactions = transactions?.reduce((a: any, b: any) => {
    const date = moment(b.createdAt).format('MMM DD, YYYY');
    if (!a[date]) {
      a[date] = [];
    }
    a[date].push(b);
    return a;
  }, {});

  return (
    <>
      <TitleContainer>
        <Typography className="title" variant="p">
          My Transactions
        </Typography>
      </TitleContainer>{' '}
      <Container>
        <>
          <ProfileDetails>
            <RightBox>
              <Typography
                variant="p"
                size="md"
                color="#333"
                fontWeight={'extrabold'}
              >
                {auth?.fullName.toUpperCase()}
              </Typography>
              <Typography variant="p" size="sm" color="#949494">
                {auth?.phone}
              </Typography>
            </RightBox>
            {balanceAmount === 0 ? (
              <Typography
                variant="p"
                size="lg"
                color="#333"
                fontWeight={'extrabold'}
              >
                €0
              </Typography>
            ) : (
              <Typography
                variant="p"
                size="lg"
                color="#333"
                fontWeight={'extrabold'}
              >
                {localeNumberFormat(balanceAmount)}
              </Typography>
            )}
          </ProfileDetails>

          <Tabs
            items={TransactionTab}
            activeKey={activeKey}
            activeTabColor={theme?.colors?.primary}
            onChange={handleTransactionTabChange}
            highlightFullSelectedTab={isLargeScreen ? true : false}
          />

          {Object.entries(grouppedTransactions).length > 0 ? (
            Object.entries(grouppedTransactions)?.map(
              ([date, grpTransactions], index) => {
                return (
                  <div>
                    <Typography
                      variant="h2"
                      fontWeight="bold"
                      style={{ marginBottom: '12px' }}
                    >
                      {date}
                    </Typography>
                    {Array.isArray(grpTransactions) &&
                      grpTransactions?.map(
                        (transaction: TransactionType, index: number) => {
                          return (
                            <IndividualTransaction
                              role={role}
                              transaction={transaction}
                              userId={authId}
                            />
                          );
                        },
                      )}
                    <Divider />
                  </div>
                );
              },
            )
          ) : (
            <EmptyPage />
          )}
          {loading && params?.page > 0 && <TransactionSkeleton />}
          {pagination?.lastPage > pagination?.page && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                isLoading={loading}
                isDisabled={loading}
                onClick={fetchMore}
              >
                Load More
              </Button>
            </div>
          )}
        </>
      </Container>
    </>
  );
};
export default OrderTableView;
