import { Row, Typography } from 'antd';
import Error from '@shared/web/components/Error';
import { Field, Formik } from 'formik';
import useAPI from '@/hooks/api';
import React from 'react';
import styled from 'styled-components';
import validation from './validation';
import InputField from '@shared/web/components/InputField';
import * as yup from 'yup';
import Button from '@/newComponents/Button';
const RoleBox = styled.div<{ active: boolean }>`
  background-color: #fafafa;
  border-radius: 2px;
  border: 2px solid
    ${({ theme, active }) =>
      active ? theme.colors.primary : theme.colors.border};
  margin-bottom: 20px;
  text-align: center;
  cursor: pointer;
  padding: 20px;
`;

const ROLES = [
  {
    value: 'landlord',
    title: 'Landlord',
    subTitle: 'I am looking to rent/sell my property',
  },
  {
    value: 'tenant',
    title: 'Tenant',
    subTitle: 'I am looking to buy/rent a property',
  },
  {
    value: 'service',
    title: 'Service Provider',
    subTitle: 'I am looking to sell property related services',
  },
];

const BasicInfo = ({ initialValues, onSubmitStep, setInitialValues }) => {
  const [handleSubmitRequest, { loading, error }] = useAPI();
  const handleSubmitForm = async values => {
    await handleSubmitRequest({
      method: 'put',
      url: '/v1/auth/profile',
      data: { role: values.role, RTB: values?.RTB || null },
    });

    onSubmitStep();
    setInitialValues(prev => ({ ...prev, role: values.role }));
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => {
        handleSubmitForm(values);
      }}
      validationSchema={validation.role}
    >
      {({
        isSubmitting,
        errors,
        touched,
        values,
        setFieldValue,
        handleSubmit,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Typography.Title level={5}>Select Role</Typography.Title>

            {ROLES.map(role => (
              <RoleBox
                key={role.value}
                active={values.role === role.value}
                onClick={() => setFieldValue('role', role.value)}
              >
                <Typography.Title level={5} style={{ margin: 0 }}>
                  {role.title}
                </Typography.Title>
                <Typography.Text type="secondary">
                  {role.subTitle}
                </Typography.Text>
              </RoleBox>
            ))}

            {values?.role === 'landlord' && (
              <Field name="RTB" component={InputField} label="RTB Number" />
            )}

            {/*@ts-ignore */}

            <Error message={error || errors?.role} isFormError />
            <Row justify={'end'}>
              <Button
                htmlType="submit"
                block={true}
                isDisabled={loading || isSubmitting}
                isLoading={loading || isSubmitting}
              >
                Save
              </Button>
            </Row>
          </form>
        );
      }}
    </Formik>
  );
};

export default BasicInfo;
