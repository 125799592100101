import React from "react";
import styled from "styled-components";
import createTheme from "../../../packages/web/src/utils/theme";
import { Typography } from "antd";
const ColorText = styled.div<{ textColor: string }>`
  & * {
    color: ${({ theme, textColor }) => theme.colors[textColor]} !important;
  }
`;

const ColorFulText = ({ children, color }) => {
  return <ColorText textColor={color}>{children}</ColorText>;
};

export default ColorFulText;
