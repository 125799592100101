import { Utils } from '@shared/front';
import { toast } from 'react-toastify';
const uploadTo = async (file: any, presignedUrl: any) => {
  try {
    await fetch(presignedUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
      },
      body: file,
    });
  } catch (err) {
    console.log('Image upload failed:', err);
  }
};
const s3Uploader = async (file: any) => {
  try {
    const { url, key }: any = await Utils.request({
      method: 'get',
      url: '/v1/common/s3-signed-url',
    });
    await uploadTo(file, url);
    return key;
  } catch (err) {
    toast.error('Failed tyo upload');
    throw err;
  }
};

export default s3Uploader;
