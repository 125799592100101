import { RootType, useRoot } from '@/RootProvider';
import useAPI from '@/hooks/api';
import { useResponsive } from '@/hooks/useResponsive';
import Checkbox from '@/newComponents/Checkbox';
import Modal from '@/newComponents/Modal';
import { Apis, Socket, Store } from '@shared/front';
import store from '@shared/front/store';
import { InitialStoreStateType } from '@shared/front/store/initialState';
import Error from '@shared/web/components/Error';
import InputField from '@shared/web/components/InputField';
import axios from 'axios';
import { Field, Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import * as yup from 'yup';
import LinkedIn from './LinkedIn';
import {
  ButtonsContainer,
  LinkButton,
  PageTitle,
  StyledButton,
  StyledForm,
  SubText,
  Title,
} from './styles';
import Flex from '@shared/web/components/Flex';
import { Link } from 'react-router-dom';
const loginValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Email is not valid')
    .required('Please enter your email'),
  password: yup.string().required('Please enter your password'),
  remember: yup.boolean(),
});

type InitialStateType = {
  email: string;
  password: string;
};

const LoginModal = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { setAuth }: RootType = useRoot();
  const [handleSubmitRequest, { loading, error }] = useAPI();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const [rememberMe, setRememberMe] = useState(false);
  const { isTablet } = useResponsive();

  const authState: InitialStoreStateType['authModal'] = useSelector(
    store.Select.authModal,
  );

  const handleSuccess = async (data: RootType['auth']) => {
    if (!data) {
      return;
    }
    axios.defaults.headers.Authorization = `Bearer ${data.token}`;

    Socket.connectSocket();

    setAuth(data);
    await localStorage.setItem('user', JSON.stringify(data));

    if (typeof data?.onboardingStep === 'number' && data.onboardingStep < 4) {
      console.log({ on: data?.onboardingStep });
      navigate('/onboarding', { replace: true });
    } else {
      if (
        [
          '/',
          '/login',
          '/search-jobs',
          '/marketplace',
          '/search-properties',
          '/devMenu',
          '/terms-and-privacy',
        ].includes(pathname)
      ) {
        navigate('/dashboard', { replace: true });
      } else {
        navigate(pathname, { replace: true });
      }
    }
    closeAuthModal();
  };

  const handleSubmit = async (
    values: InitialStateType,
    formikHelpers: FormikHelpers<InitialStateType>,
  ) => {
    try {
      const data = await handleSubmitRequest(Apis.Auth.login(values));
      Store.Actions.set('loggedInUser', data);
      handleSuccess(data);
    } catch (err) {
      if (
        typeof err === 'string' &&
        err?.includes("Your account hasn't been verified")
      ) {
        navigate('/verifyAccount', { state: { email: values.email } });
      }
    }
  };
  const openAuthModal = () => {
    dispatch(
      store.Actions.set('authModal', { status: true, type: 'register' }),
    );
  };

  const closeAuthModal = () => {
    dispatch(
      store.Actions.set('authModal', { status: false, type: undefined }),
    );
  };

  return (
    <Modal
      open={authState?.status && authState?.type === 'login'}
      onClose={closeAuthModal}
      isCentered={true}
      size={isTablet ? 'md' : 'lg'}
      style={{ maxWidth: isTablet ? '600px' : '100%', width: '100%' }}
    >
      <LoginWrapper>
        <Formik
          initialValues={{ email: '', password: '' }}
          onSubmit={handleSubmit}
          validationSchema={loginValidationSchema}
        >
          {({ handleSubmit, isSubmitting, errors, touched }) => {
            return (
              <StyledForm onSubmit={handleSubmit}>
                <Flex direction="column" gap="20px">
                  <PageTitle variant="p">Welcome to Rentisity</PageTitle>
                  <Title
                    variant="p"
                    color="gray"
                    style={{ fontWeight: 'normal' }}
                  >
                    Don't have an account?{' '}
                    <LinkButton
                      style={{
                        color: '#1F1F1F',
                        cursor: 'pointer',
                      }}
                      onClick={openAuthModal}
                    >
                      Create Account
                    </LinkButton>
                  </Title>
                  <Field component={InputField} label="Email" name="email" />
                  <div>
                    <Field
                      component={InputField}
                      name="password"
                      type="password"
                      label="Password"
                    />

                    <Flex justifyContent="flex-end" margin="10px 0px 0px">
                      <div
                        style={{
                          color: theme.colors.fontColors.primaryMedium,
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          closeAuthModal();
                          navigate('/forgotPassword');
                        }}
                      >
                        Forgot password?
                      </div>
                    </Flex>
                  </div>

                  <Error message={error} isFormError />
                </Flex>
                <div>
                  <ButtonsContainer>
                    <StyledButton
                      radius="round"
                      htmlType="submit"
                      isDisabled={loading || isSubmitting}
                      isLoading={loading || isSubmitting}
                      style={{ width: '100%' }}
                    >
                      Login
                    </StyledButton>
                  </ButtonsContainer>
                  <SubText
                    style={{
                      textAlign: 'center',
                      margin: '15px 0',
                    }}
                    variant="p"
                  >
                    Or
                  </SubText>
                  <LinkedIn title="Login with LinkedIn" />
                </div>
              </StyledForm>
            );
          }}
        </Formik>
      </LoginWrapper>
    </Modal>
  );
};

export default LoginModal;

const LoginWrapper = styled.div``;
