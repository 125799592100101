import { ReturnType } from "./types";

export default {
  observeAction(data: any): ReturnType {
    return {
      method: "post",
      url: "/v1/analytics/observe-action",
      data: data,
    };
  },
};
