import { ReturnType } from "./types";

export default {
  getMyProposal: (params: any): ReturnType => {
    return {
      method: "get",
      url: "/v1/services/proposals",
      params: params,
      config: {
        store: {
          action: "set",
          key: "proposals",
        },
      },
    };
  },
  getProposalById: (id: number): ReturnType => {
    return {
      method: "get",
      url: `/v1/services/proposals/${id}`,
      config: {
        store: {
          action: "set",
          key: "proposal",
        },
      },
    };
  },

  proposalWithDraw: (id: number): ReturnType => {
    return {
      method: "post",
      url: `/v1/services/proposals/${id}/withdraw`,
      config: {
        navigateBack: true,
        successMsg: "Your proposal has been withdrawn successfully.",
      },
    };
  },
  updateProposal: (data: any, id: number): ReturnType => {
    return {
      method: "patch",
      url: `/v1/services/proposals/${id}`,
      data: data,
    };
  },

  acceptCallout: (id: number): ReturnType => {
    return {
      method: "patch",
      url: `/v1/services/proposals/${id}/accept-callout`,
      config: {
        successMsg: "You have accepted callout successfully",
      },
    };
  },

  acceptQuotation: (id: number): ReturnType => {
    return {
      method: "post",
      url: `/v1/services/proposal/${id}/accept`,
      config: {
        successMsg: "Accepted Successfully",
      },
    };
  },
  rejectQuotation: (id: number): ReturnType => {
    return {
      method: "post",
      url: `/v1/services/proposal/${id}/accept`,
      config: {
        successMsg: "Rejected",
      },
    };
  },
};
