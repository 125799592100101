import React from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from '@/newComponents/Modal';
import { SaveButtonContainer, StyledButton } from './modalStyles';
import styled from 'styled-components';
import { createSearchParams } from 'react-router-dom';

type Props = {
  isVisible: boolean | undefined;
  onCancel: Function;
  bookingId: string | undefined;
  enableManualContractGenerate: Function;
};

const ContractMethodSelectionModal = ({
  isVisible,
  onCancel,
  bookingId,
  enableManualContractGenerate,
}: Props) => {
  const navigate = useNavigate();

  const handleCancel = () => {
    onCancel && onCancel();
  };

  const handleManualContractGenerate = () => {
    enableManualContractGenerate(true);
    handleCancel();
  };

  const handleSystemContractGenerate = () => {
    navigate({
      pathname: '/test',
      //@ts-ignore
      search: createSearchParams<{
        bookingID: number;
      }>({
        bookingID: bookingId,
      }).toString(),
    });
    handleCancel();
  };
  return (
    <Modal
      title="Choose the methods to create contract ?"
      open={isVisible}
      onClose={handleCancel}
    >
      <ContractButtonsContainer>
        <StyledButton radius="round" onClick={handleSystemContractGenerate}>
          System Generate
        </StyledButton>
        <StyledButton radius="round" onClick={handleManualContractGenerate}>
          Manual Generate
        </StyledButton>
      </ContractButtonsContainer>
      <SaveButtonContainer>
        <StyledButton variant="gray" radius="round" onClick={handleCancel}>
          Cancel
        </StyledButton>
      </SaveButtonContainer>
    </Modal>
  );
};

export default ContractMethodSelectionModal;

const ContractButtonsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
`;
