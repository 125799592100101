import useAPI from '@/hooks/api';
import Button from '@/newComponents/Button';
import Checkbox from '@/newComponents/Checkbox';
import Input from '@/newComponents/Input';
import Modal from '@/newComponents/Modal';
import Typography from '@/newComponents/Typography';
import { devices } from '@/utils/theme';
import { Apis } from '@shared/front';
import Error from '@shared/web/components/Error';
import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { DesiredPropertyData, OnSubmitStepParams } from '.';
import * as validation from './validation';

const AmenitiesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 12px;

  @media ${devices.laptop} {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }
`;
type Props = {
  desiredValues: DesiredPropertyData;
  onSubmitStep: (data: OnSubmitStepParams) => void;
  loading: boolean;
};
const Features = ({ desiredValues, onSubmitStep, loading }: Props) => {
  const formikref = useRef();
  const [getFeatures, { data: features }] = useAPI();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalInput, setModalInput] = useState('');
  const [featuresList, setFeaturesList] = useState([]);
  const [modalError, setModalError] = useState('');

  const handleSubmitForm = async values => {
    try {
      onSubmitStep({
        data: { ...desiredValues, features: values?.amenities },
        dataFrom: 'features',
      });
    } catch (error) {}
  };

  const fetchFeature = async () => {
    const response = await getFeatures(Apis.Properties.getAmenities());
    const reponseAmenities = response.map(x => x.title);
    const propertyAmenities = desiredValues?.features?.map(x => x);

    if (propertyAmenities.length > 0) {
      const featureValue = propertyAmenities.reduce((acc, val) => {
        if (!reponseAmenities.includes(val)) {
          acc.push(val);
          return acc;
        }
        return acc;
      }, []);

      setFeaturesList(featureValue);
    }
  };

  useEffect(() => {
    if (!!desiredValues?.features) {
      formikref?.current?.setFieldValue('amenities', [
        ...desiredValues?.features.map(items => items),
      ]);
    }
    fetchFeature();
  }, []);

  const handleAddNewAmenity = e => {
    e.preventDefault();
    setIsModalVisible(true);
  };

  const isContainedInFeatures = (text: string) => {
    const reponseAmenities = features.map(x => x.title);
    return reponseAmenities.includes(text);
  };

  const addNewAmenityToFeatures = e => {
    e.preventDefault();
    if (modalInput) {
      const reponseAmenities = features.map(x => x?.title.toLowerCase());
      const featureListAmenities = featuresList.map(x => x?.toLowerCase());
      if (
        reponseAmenities?.includes(modalInput.toLowerCase()) ||
        featureListAmenities?.includes(modalInput.toLowerCase())
      ) {
        setModalError('Amenity already exists.');
      } else {
        setModalError('');
        formikref?.current?.setFieldValue('amenities', [
          ...(formikref?.current?.values?.amenities || []),
          modalInput,
        ]);
        setFeaturesList([...featuresList, modalInput]);
        setModalInput('');
        setIsModalVisible(false);
      }
    }
  };

  const removeNewAmenity = (amenity: string) => {
    setFeaturesList(prev => {
      return prev.filter(x => x !== amenity);
    });
    formikref?.current?.setFieldValue(
      'amenities',
      formikref?.current?.values?.amenities.filter(i => i !== amenity),
    );
  };
  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  console.log({ desiredValues });

  return (
    <Formik
      innerRef={formikref}
      // initialValues={initialValues}
      initialValues={{ amenities: desiredValues?.features || [] }}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmitForm(values);
        setSubmitting(false);
      }}
      validationSchema={validation.features}
      enableReinitialize
    >
      {({ errors, values, setFieldValue, touched, handleSubmit }) => {
        return (
          <Form>
            <Typography
              variant="h1"
              style={{
                fontSize: '16px',
                fontWeight: 500,
                marginBottom: '24px',
              }}
            >
              Features
            </Typography>
            <AmenitiesGrid>
              {!!features?.length &&
                features?.map(({ title, id }) => {
                  return (
                    <Checkbox
                      id={id}
                      label={title}
                      isChecked={values?.amenities?.includes(title)}
                      onChange={e => {
                        if (e.target.checked) {
                          setFieldValue('amenities', [
                            ...(values?.amenities || []),
                            title,
                          ]);
                        } else {
                          setFieldValue(
                            'amenities',
                            values?.amenities.filter(i => i !== title),
                          );
                        }
                      }}
                    />
                  );
                })}
              {featuresList?.map(newAmenity => {
                return (
                  <Checkbox
                    id={newAmenity}
                    key={newAmenity}
                    label={newAmenity}
                    isChecked={true}
                    onChange={e => {
                      removeNewAmenity(newAmenity);
                    }}
                  />
                );
              })}
              <Button
                size="md"
                variant="primary"
                onClick={handleAddNewAmenity}
                style={{ height: '40px', width: '40px', fontSize: '30px' }}
              >
                +
              </Button>
            </AmenitiesGrid>
            <Modal
              title="Add New Amenity"
              open={isModalVisible}
              isCentered={true}
              onClose={handleModalCancel}
              style={{ width: '400px', paddingBottom: '18px' }}
            >
              <Input
                type="text"
                placeholder="Enter a new amenity"
                value={modalInput}
                onChange={e => setModalInput(e.target.value)}
              />
              {modalError && <div style={{ color: 'red' }}>{modalError}</div>}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: '10px',
                  marginTop: '10px',
                }}
              >
                <Button
                  onClick={handleModalCancel}
                  radius="round"
                  variant="gray"
                >
                  Cancel
                </Button>
                <Button onClick={addNewAmenityToFeatures} radius="round">
                  Add
                </Button>
              </div>
            </Modal>

            {errors?.amenities && touched?.amenities && (
              <Error message={errors?.amenities} />
            )}

            <div
              style={{
                display: 'flex',
                gap: '20px',
                justifyContent: 'flex-end',
                width: '100%',
                margin: '20px 0px',
              }}
            >
              <StyledButton
                htmlType="submit"
                radius="round"
                isDisabled={loading}
                isLoading={loading}
              >
                Save
              </StyledButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Features;

export const StyledButton = styled(Button)`
  font-size: 12px;
  line-height: 25px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
`;
