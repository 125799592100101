import React, { CSSProperties, ChangeEvent, HTMLAttributes } from 'react';
import styled from 'styled-components';

interface IInputProps extends HTMLAttributes<HTMLInputElement> {
  isDisabled?: boolean;
}

interface IInputComponentProps {
  type?: 'text' | 'number' | 'textarea' | 'email' | 'password';
  label?: string;
  placeholder?: string;
  value?: string;
  error?: string;
  isRequired?: boolean;
  style?: CSSProperties;
  className?: string;
  onChange?: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-family: 'Poppins';
`;

const StyledInput = styled.input<IInputProps>`
  background: #f5f5f5;
  border: 1px solid #e0e0e0;
  outline: none;
  height: 30px;
  padding: 5px 25px;

  &::placeholder {
    color: #b3b3b3;
    opacity: 1;
    font-family: 'Poppins';
  }

  &:focus {
    outline: 1px solid #90caf9;
  }
`;

const StyledLabel = styled.label`
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  color: #333333;
`;

const RequiredMarker = styled.span`
  color: #d32f2f;
`;

const ErrorBox = styled.div`
  color: #d32f2f;
  font-size: 10px;
`;

const Input = ({
  type = 'text',
  label,
  placeholder,
  onChange,
  value,
  error,
  isRequired = false,
  isDisabled = false,
  className,
  style,
  ...rest
}: IInputComponentProps & IInputProps) => {
  return (
    <StyledContainer>
      <StyledLabel>
        {label} {isRequired && <RequiredMarker>*</RequiredMarker>}
      </StyledLabel>
      <StyledInput
        as={type === 'textarea' ? 'textarea' : 'input'}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={isDisabled}
        className={className}
        style={{ ...style }}
        {...rest}
      />
      {error && <ErrorBox>{error}</ErrorBox>}
    </StyledContainer>
  );
};

export default Input;
