import React, { useState } from "react";
import useAPI from "@/hooks/api";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppButton from "./Button";
import { Typography } from "antd";
import LoadingPlaceholder from "./LoadingPlaceholder";
import { ThemeType } from "../../../packages/web/src/utils/theme";
import { FormikErrors, FormikTouched } from "formik";

const Container = styled.div`
  position: relative;
  display: inline-block;
  background-color: #fafafa;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  margin: 5px;
`;

const UploaderButton = styled.div`
  height: 100px;
  width: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const FileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100px;
  overflow: hidden;
  opacity: 0;
  z-index: 9999;
  font-size: 100px;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  color: red;
  background-color: white;
  border-radius: 20px;
  position: absolute;
  top: -5px;
  right: -5px;
  font-size: 20px;
  cursor: pointer;
`;

const Image = styled.img`
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 2px;
`;

const S3presigned = ({
  field: { name: fieldName, value, onChange, onBlur, disabled },
  form: { errors, touched, submitCount, setFieldValue },
  isMultiple = true,
  label,
  accept,
  fileType,
  S3url = "/v1/common/s3-signed-url",
}: InputFieldType) => {
  const [imageLoading, setImageLoading] = useState(false);
  const [handleS3Images, { loading: loadings3Images }] = useAPI();
  const uploadPromises: Promise<any>[] = [];
  const tempUrls: string[] = [];
  const [uploadedFile, setUploadedFile] = useState<File>();
  let uploadedFiles = 0;
  const data = value;
  function findSrc(singleData: any) {
    let src = "";
    let id: number;

    if (typeof singleData === "string") {
      src = singleData;
    } else {
      if (singleData?.URL) {
        src = singleData.URL;
        id = singleData.id;
      } else {
        try {
          src = URL.createObjectURL(singleData);
        } catch (err) {}
      }
    }
    return src;
  }

  const uploadTo = async (file: File, presignedUrl: string) => {
    try {
      const response = await fetch(presignedUrl, {
        method: "PUT",
        headers: {
          "Content-Type": file.type,
        },
        body: file,
      });
      console.log("Image upload successful:", response);
    } catch (err) {
      console.log("Image upload failed:", err);
    }
  };
  const uploadToS3 = async (file: File) => {
    try {
      setImageLoading(true);
      const { url, key } = await handleS3Images({
        method: "get",
        url: S3url,
      });
      await uploadTo(file, url);

      return key;
    } catch (err: any) {
      console.log("uplaod to s3", err?.response?.data?.message);
    } finally {
      setImageLoading(false);
    }
  };
  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const files = Array.from(e?.target?.files);
    setImageLoading(true);

    try {
      for (let i = 0; i < files.length; i++) {
        const file = files[i] as File;

        const uploadPromise = new Promise(async (resolve, reject) => {
          try {
            // Fetch the pre-signed URL from your server for each file
            const { url, key } = await handleS3Images({
              method: "get",
              url: S3url,
              body: JSON.stringify({
                fileName: file.name,
                fileType: file.type,
              }),
            });

            // Upload the current file to S3 using the presigned URL
            const uploadResponse = await fetch(url, {
              method: "PUT",
              body: file,
              headers: {
                "Content-Type": file.type,
              },
            });

            if (!uploadResponse.ok) {
              throw new Error(`Failed to upload file ${file.name} to S3`);
            }

            // Increment the count of uploaded files
            uploadedFiles++;

            // Resolve the promise after successful upload
            resolve(`File ${file.name} uploaded successfully`);
            tempUrls.push(key);
          } catch (error) {
            console.error(`Error uploading file ${file.name}:`, error);
            // Reject the promise in case of error
            reject(error);
          }
        });

        uploadPromises.push(uploadPromise);
      }
      await Promise.all(uploadPromises);

      setFieldValue(fieldName, [...value, ...tempUrls]);
    } catch (error) {
    } finally {
      setImageLoading(false);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const uploadFile = e?.target?.files && e?.target?.files[0];
    if (uploadFile) {
      setUploadedFile(uploadFile);
    }
  };

  const handleUploadFile = () => {
    if (!uploadedFile) return;
    uploadToS3(uploadedFile)
      .then((fileURL) => {
        setFieldValue(fieldName, fileURL);
      })
      .catch((error) => console.log("error uploading", error));
  };

  return (
    <>
      <Typography.Text
        style={{ display: "block", marginTop: 20, marginBottom: 10 }}
      >
        {label}
      </Typography.Text>
      <div>
        {fileType === "file" && !isMultiple ? (
          <>
            {value ? (
              <div>
                <Typography.Text>
                  {uploadedFile?.name?.split(".")[0]} file is being uploaded
                </Typography.Text>
              </div>
            ) : (
              ""
            )}
            <input
              type='file'
              accept={accept}
              multiple={isMultiple}
              onChange={handleFileChange}
            />
            {!!uploadedFile ? (
              <AppButton onClick={handleUploadFile}>upload File</AppButton>
            ) : (
              ""
            )}
          </>
        ) : (
          <Container>
            {!!data?.length ? (
              Array.isArray(data) &&
              data?.map((item: any, idx: number) => {
                const src = findSrc(item);
                return (
                  <div style={{ display: "inline-block" }} key={idx}>
                    <Image src={src} />
                    <CloseIcon
                      icon='times-circle'
                      onClick={() => {
                        setFieldValue(fieldName, []);
                      }}
                    />
                  </div>
                );
              })
            ) : (
              <>
                <UploaderButton>
                  {imageLoading ? (
                    <>Uploading...</>
                  ) : (
                    <FontAwesomeIcon icon='plus' />
                  )}
                </UploaderButton>
                <FileInput
                  type='file'
                  onChange={handleChange}
                  accept={accept}
                  multiple={isMultiple}
                />
              </>
            )}
          </Container>
        )}
      </div>
    </>
  );
};
export default S3presigned;
type InputFieldType = {
  field: FieldType;
  form: MetaType;
  border?: keyof typeof ThemeType.sizes;
  type?: string;
  placeholder?: string;
  label?: string;
  background?: string;
  options?: { label: string; value: string }[];
  onSelectOption?: (value: string) => void;
  style?: any;
  prepend?: any;
  append?: any;
  isMultiple?: Boolean;
  accept?: string;
  fileType?: string;
  S3url?: string;
};

type FieldType = {
  name: string;
  value: any;
  onChange: (e: React.ChangeEvent<any>) => void;
  onBlur: (e: React.FocusEvent<any>) => void;
  [rest: string]: any;
};

type MetaType = {
  errors?: FormikErrors<object> | undefined;
  touched?: FormikTouched<object> | undefined;
  submitCount: number;
  setFieldValue: any;
};
