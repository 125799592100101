import axios from "axios";
import Store from "../../store";

import rootData from "../rootData";
export const handleLogout = async () => {
  localStorage.removeItem("user");
  axios.defaults.headers.Authorization = "";
  location.reload();
};

const request = async (config, isDebug = false) => {
  const API_URL = rootData.apiURL;
  const user = localStorage.getItem("user");
  const isUserLoggedIn = user ? JSON.parse(user) : null;

  const redux = config?.config;
  try {
    if (redux?.store?.key) {
      Store.store.dispatch(
        Store.Actions["update"]("loading", { [redux.store.key]: true })
      );
      setTimeout(() => {
        Store.store.dispatch(
          Store.Actions["update"]("loading", { [redux.store.key]: false })
        );
      }, 20000);
    }

    const res = await axios({
      ...config,
      url: API_URL + config.url,
    });
    if (res.status === 403) {
      if (isUserLoggedIn) {
        return handleLogout();
      }
      return null;
    }
    if (res.status >= 200 && res.status < 300) {
      const data = res?.data?.data || res?.data || res;

      if (config?.config?.store) {
        // const redux = config?.config;
        if (redux?.store?.loading && redux.store.action === "set") {
          Store.store.dispatch(Store.Actions["startLoading"](redux.store.key));
          setTimeout(() => {
            Store.store.dispatch(Store.Actions["stopLoading"](redux.store.key));
          }, 800);
        }
        Store.store.dispatch(
          Store.Actions[redux.store.action](redux.store.key, data)
        );
      }
      if (res.data.status === "error") {
        throw res;
      } else {
        return data;
      }
    }
  } catch (err: any) {
    console.log("the errrr", err.response);

    if (err?.data?.message) {
      throw err?.data?.message;
    }

    if (err && err.response && err.response.data && err.response.data.message) {
      throw err.response.data.message;
    }
    if (err && err.response && err.response.data && err.response.data.error) {
      throw err.response.data.error;
    }
    throw err.message || err;
  } finally {
    if (redux?.store?.key) {
      Store.store.dispatch(
        Store.Actions["update"]("loading", { [redux.store.key]: false })
      );
    }
  }
};
export default request;
