import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties, HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

interface IButtonProps extends HTMLAttributes<HTMLButtonElement> {
  variant?: 'ghost' | 'primary' | 'gray' | 'tag' | 'danger';
  radius?: 'round' | 'arc' | 'normal';
  size?: 'sm' | 'md' | 'lg' | 'xl';
  bgColor?: string;
  color?: string;
  isDisabled?: boolean;
  block?: boolean;
  type?: string;
  isLoading?: boolean;
}

interface IButtonComponentProps {
  as?: 'button' | 'a';
  icon?: string;
  href?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isLoading?: boolean;
  className?: string;
  style?: CSSProperties;
  children: ReactNode;
  htmlType?: string;
}

const getBorderRadius = (radius: string) => {
  switch (radius) {
    case 'round':
      return '30';
    case 'arc':
      return '17';
    case 'normal':
      return '5';
  }
};

const getBackgroundColor = (bgColor: string, variant: string) => {
  switch (variant) {
    case 'ghost':
      return 'transparent';
    case 'primary':
      return '#2061F8';
    case 'gray':
      return '#E0E0E0';
    case 'tag':
      return bgColor || '#00DE90';
    case 'danger':
      return bgColor || 'red';
    default:
      return bgColor || '#2061F8';
  }
};

const getSize = (size: string, variant: string) => {
  if (variant === 'tag') {
    return `
      font-size: 14px;
      height: 23px;
    `;
  }
  switch (size) {
    case 'sm':
      return `
        font-size: 12px;
        height: 26px;
        min-width: 60px;
      `;
    case 'md':
      return `
        font-size: 16px;
        height: 34px;
        min-width: 90px;
      `;
    case 'lg':
      return `
        font-size: 22px;
        height: 42px;
        min-width: 120px;
      `;
    case 'xl':
      return `
        font-size: 28px;
        height: 50px;
        min-width: 150px;
      `;
    default:
      return `
        font-size: 16px;
        height: 30px;
        min-width: 100px;
      `;
  }
};

const getColor = (variant: string) => {
  switch (variant) {
    case 'ghost':
      return '#949494';
    case 'gray':
      return '#333333';
    default:
      return '#ffffff';
  }
};

const getLoaderSize = (size: string) => {
  switch (size) {
    case 'sm':
      return '8px';
    case 'md':
      return '10px';
    case 'lg':
      return '12px';
    case 'xl':
      return '16px';
    default:
      return '16px';
  }
};

const StyledButton = styled.button<IButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: 4px;
  outline: none;
  border: none;
  ${({ size, variant }) => getSize(size, variant)};
  color: ${({ variant }) => `${getColor(variant)}`};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  border-radius: ${({ radius }) => `${getBorderRadius(radius)}px`};
  background-color: ${({ bgColor, variant, isDisabled }) =>
    isDisabled ? '#5d5d5d' : getBackgroundColor(bgColor, variant)};
  padding: 5px 12px;

  &:hover {
    // TODO: Add hover effects
  }

  & .lds-hourglass {
    display: inline-block;
    position: relative;
  }

  > div {
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 8px;
    box-sizing: border-box;
    border: ${({ size }) => `${getLoaderSize(size)} solid`};
    border-color: ${({ variant }) =>
      variant === 'ghost' || variant === 'gray'
        ? '#414141 transparent #414141 transparent'
        : '#fff transparent #fff transparent'};
    animation: lds-hourglass 1.2s infinite;
  }

  @keyframes lds-hourglass {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
  }
`;

const Button = ({
  as = 'button',
  variant = 'primary',
  radius = 'normal',
  bgColor,
  size = 'md',
  icon,
  href,
  isLoading = false,
  isDisabled = false,
  onClick,
  className,
  style,
  children,
  ...rest
}: IButtonComponentProps & IButtonProps) => {
  return (
    <StyledButton
      as={as}
      variant={variant}
      radius={radius}
      size={size}
      bgColor={bgColor}
      isDisabled={isDisabled}
      disabled={isDisabled}
      href={href}
      onClick={onClick}
      className={className}
      style={{ ...style }}
      {...rest}
    >
      {isLoading ? (
        <div className="lds-hourglass">
          <div />
        </div>
      ) : (
        <>
          {/*FIXME: Icon not working / displaying */}
          {icon && <FontAwesomeIcon icon={icon as IconName} />}
          {children}
        </>
      )}
    </StyledButton>
  );
};

export default Button;
