import Button from '@/newComponents/Button';
import Typography from '@/newComponents/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Apis, Store, Utils } from '@shared/front';
import AppButton from '@shared/web/components/Button';
import TextField from '@shared/web/components/TextField';
import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const CommentText = styled(Typography)`
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  display: block;
  margin: 10px;
`;
const ProfileName = styled(Typography)`
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
`;

const FromNow = styled(Typography)`
  color: #ccc;
  font-size: 12px;
  margin-left: 10px;
`;

const ReplyList = styled.div`
  margin-left: ${({ index }) => (index + 1) * 20 + 'px'};
`;
const Comment = ({ productInfo: marketProduct }: any) => {
  const initialParams = {
    limit: 100,
    page: 0,
  };
  const comments: any[] = useSelector(Store.Select.marketComments);
  const handleGetComments = (params: any, action: 'set' | 'update') => {
    Utils.request(Apis.Market.getComment(marketProduct?.id, action, params));
  };

  // console.log('the comment', comments);
  useEffect(() => {
    handleGetComments(initialParams, 'set');
  }, []);

  const initialData = {
    comment: '',
    productId: marketProduct?.id,
    parentId: 0,
    replyTo: null,
  };
  const [initialValues, setInitialValues] = useState(initialData);
  const handlePressReply = (obj: any) => {
    setInitialValues({
      ...initialData,
      parentId: obj?.parentId,
      comment: obj?.name,
      replyTo: obj?.replyTo,
    });
  };
  return (
    <>
      <div>
        <div
          style={{
            maxHeight: '40vh',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          {comments?.map(item => {
            // console.log('the item', item);
            return (
              <div>
                <CommentList
                  comment={item}
                  setInitialValues={setInitialValues}
                  initialData={initialData}
                  handlePressReply={handlePressReply}
                />
                <div>
                  {item.replies &&
                    item.replies.length > 0 &&
                    item.replies.map((reply, index) => {
                      return (
                        <ReplyList key={reply.id} index={index}>
                          <CommentList
                            comment={reply}
                            setInitialValues={setInitialValues}
                            initialData={initialData}
                            handlePressReply={handlePressReply}
                          />
                        </ReplyList>
                      );
                    })}
                </div>
              </div>
            );
          })}
        </div>

        <CommentInput
          initialValues={initialValues}
          marketProduct={marketProduct}
          initialData={initialData}
        />
      </div>
    </>
  );
};

export default Comment;

const CommentList = ({
  comment,
  setInitialValues,
  initialData,
  handlePressReply,
}) => {
  // console.log('the item', comment);
  return (
    <>
      <div
        style={{
          margin: '0px 10px 30px',
          display: 'flex',
          flexDirection: 'row',
          border: '1px solid #e8e8e8',
          borderRadius: '10px',
          padding: '17px',
          position: 'relative',
        }}
      >
        <div>
          <img
            src={
              comment?.auth?.avatar
                ? comment?.auth?.avatar
                : 'https://img.freepik.com/free-icon/user_318-159711.jpg'
            }
            height={30}
            width={30}
            style={{ borderRadius: '50%', border: '1px solid blue' }}
          />
        </div>

        <div style={{ marginLeft: '20px' }}>
          <ProfileName>
            {comment?.auth?.firstName} {comment?.auth?.lastName}
          </ProfileName>
          <FromNow>{moment(comment?.createdAt).fromNow()}</FromNow>

          <CommentText>{comment?.comment}</CommentText>
        </div>
        <Button
          style={{
            position: 'absolute',
            top: '80px',
            background: 'none',
            border: 'none',
          }}
          onClick={() =>
            handlePressReply({
              parentId: comment?.parentId > 0 ? comment?.parentId : comment?.id,
              name: `${comment?.auth?.firstName} ${comment?.auth?.lastName}`,
              replyTo: comment?.auth?.id,
            })
          }
        >
          <a href="#scroll">
            <FontAwesomeIcon icon={'reply'} />
          </a>
        </Button>
      </div>
    </>
  );
};

const CommentInput = ({
  initialValues,
  marketProduct,
  setInitialValues,
  initialData,
}: any) => {
  return (
    <div id="scroll">
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { resetForm }) => {
          // console.log('the values are', values);
          await Utils.request(Apis.Market.createComment(values));
          Utils.request(Apis.Market.getComment(marketProduct?.id));
          resetForm();
        }}
        enableReinitialize
      >
        {({ values, handleSubmit }) => {
          console.log('the submitting', values);
          return (
            <Form onSubmit={handleSubmit}>
              <Field
                name="comment"
                component={TextField}
                label="Comment: "
                isborder={true}
              />
              <AppButton type="primary" onClick={handleSubmit}>
                Add Comment
              </AppButton>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
