import SkeletonLoading from '@/newComponents/Skeleton';
import React from 'react';
import styled from 'styled-components';

const TransactionSkeleton = ({ ...rest }) => {
  return (
    <Wrapper>
      {[1, 2, 3, 4, 5].map(item => {
        return (
          <div className="card" key={item}>
            <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
              <SkeletonLoading height={50} borderRadius={10} width={50} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                }}
              >
                <SkeletonLoading height={40} width={350} />
                <SkeletonLoading height={20} width={250} />
                <SkeletonLoading height={20} width={250} />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <SkeletonLoading height={30} width={120} />
              <SkeletonLoading height={20} width={80} />
            </div>
          </div>
        );
      })}
    </Wrapper>
  );
};

export default TransactionSkeleton;

const Wrapper = styled.div`
  padding: 30px;
  width: 100%;

  & .card {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 150px;
    gap: 10px;
    align-items: center;
  }
`;
