import Button from '@/newComponents/Button';
import Error from '@shared/web/components/Error';
import { Formik, Field, Form } from 'formik';
import { useNavigate } from 'react-router-dom';

import useAPI from '@/hooks/api';
import React, { useState } from 'react';
import styled from 'styled-components';
import validation from './validation';
import MaskInputField from '@shared/web/components/MaskInputField';
import { pick } from 'lodash';
import Typography from '@/newComponents/Typography';
import { Title } from './index.style';

const RoleBox = styled.div<{ active: boolean }>`
  background-color: #fafafa;
  border-radius: 2px;
  border: 2px solid
    ${({ theme, active }) =>
      active ? theme.colors.primary : theme.colors.border};
  margin-bottom: 20px;
  text-align: center;
  cursor: pointer;
  padding: 20px;
  width: 200px;
  margin-left: 20px;
`;
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
`;

const StyledButton = styled(Button)`
  font-size: 12px;
  line-height: 25px;
`;
const BoxContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledLabel = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 8px;
`;

const ROLES = [
  {
    value: 'hourly',
    title: 'Hourly',
    subTitle: 'Pay per hour',
  },
  {
    value: 'fixed',
    title: 'Fixed',
    subTitle: 'Pay fixed price',
  },
  {
    value: 'quote',
    title: 'Quote',
    subTitle: 'Quote price ',
  },
];
const BasicInfo = ({ initialValues, onSubmitStep, setInitialValues }) => {
  const [handleSubmitRequest, { loading, error }] = useAPI();
  const navigate = useNavigate();
  const [targetButton, setTargetButton] = useState<string>('');

  const handleSubmitForm = async values => {
    const url = '/v1/services';
    const parsedValues = pick(values, ['priceType', 'price']);

    const data = await handleSubmitRequest({
      method: 'post',
      url: initialValues.id ? `${url}/${initialValues.id}` : url,
      data: parsedValues,
    });
    onSubmitStep({ isDraft: false });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => {
        handleSubmitForm(values);
      }}
      validationSchema={validation.pricing}
    >
      {({
        isSubmitting,
        errors,
        touched,
        values,
        setFieldValue,
        handleSubmit,
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Title variant="h1">Pricing</Title>
            <div>
              <StyledLabel>Select Pricing type</StyledLabel>

              <BoxContainer>
                {ROLES.map(role => (
                  <RoleBox
                    key={role.value}
                    active={values.priceType === role.value}
                    onClick={() => setFieldValue('priceType', role.value)}
                  >
                    <Typography
                      variant="h1"
                      style={{ padding: '10px 0px', color: 'black' }}
                    >
                      {role.title}
                    </Typography>
                    <Typography variant="h1" style={{ padding: '10px 0px' }}>
                      {role.subTitle}
                    </Typography>
                  </RoleBox>
                ))}
              </BoxContainer>

              <StyledLabel>
                {values?.priceType === 'fixed'
                  ? 'Price'
                  : values?.priceType === 'quote'
                  ? ''
                  : 'Price (Per hour)'}
              </StyledLabel>

              {values?.priceType !== 'quote' && (
                <Field
                  component={MaskInputField}
                  name="price"
                  decimalSeparator="."
                  displayType="input"
                  type="text"
                  thousandSeparator={true}
                  prefix={'€'}
                  setFieldValue={setFieldValue}
                />
              )}
            </div>
            <Error message={error || errors?.priceType} isFormError />
            <ButtonsContainer>
              <StyledButton
                type="button"
                variant="gray"
                radius="round"
                onClick={() => navigate('/jobs')}
              >
                Cancel
              </StyledButton>
              <div style={{ display: 'flex', gap: '20px' }}>
                <StyledButton
                  radius="round"
                  variant="gray"
                  type="button"
                  isDisabled={targetButton === 'draft' ? loading : false}
                  isLoading={targetButton === 'draft' ? loading : false}
                  onClick={() => {
                    setTargetButton('draft');
                    handleSubmitForm(values, true);
                  }}
                >
                  Save as draft
                </StyledButton>
                <StyledButton
                  radius="round"
                  type="submit"
                  disabled={targetButton === 'save' ? loading : false}
                  loading={targetButton === 'save' ? loading : false}
                  onClick={() => {
                    setTargetButton('save');
                  }}
                >
                  Save
                </StyledButton>
              </div>
            </ButtonsContainer>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BasicInfo;
