import React from 'react';
import styled from 'styled-components';
import Typography from './Typography';
import Button from './Button';
import ImageWithFallBack from './ImageWithFallBack';

interface PropertyCardProps extends React.ComponentPropsWithoutRef<'div'> {
  style?: React.CSSProperties;
  address: string;
  price: number;
  imageURL: string;
  productName: string;
  rentBase?: string;
  tag: string;
}

const ProductCard = ({
  address,
  price,
  imageURL,
  productName,
  rentBase,
  tag,
  ...restProps
}: PropertyCardProps) => {
  return (
    <ProductCardContainer {...restProps}>
      <Image>
        <ImageWithFallBack src={imageURL} />
      </Image>

      <ProductTitle variant="h3" color="#1F1F1F">
        {productName}
      </ProductTitle>
      <Address variant="p">{address}</Address>
      <Wrapper>
        <Price variant="p">
          &euro;{price} {rentBase && `/ ${rentBase}`}
        </Price>

        <Button
          variant="tag"
          radius="round"
          size="sm"
          style={{ color: '#424242' }}
        >
          {tag}
        </Button>
      </Wrapper>
    </ProductCardContainer>
  );
};

export default ProductCard;

const ProductCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  /* width: 100%; */
  cursor: pointer;
  border-radius: 6px;
`;

const Image = styled.div`
  height: 250px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
`;

const ProductTitle = styled(Typography)`
  margin: 0;
  font-weight: bold;
  font-style: italic;
  font-size: 18px;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Address = styled(Typography)`
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #949494;
  line-height: 30px;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
const Price = styled(Typography)`
  margin: 0;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  color: #1f1f1f;
  line-height: 0;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 0px;
`;
