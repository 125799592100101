import SkeletonLoading from '@/newComponents/Skeleton';
import React from 'react';
import styled from 'styled-components';

const MarketSkeleton = ({ ...rest }) => {
  return (
    <Wrapper>
      <div className="post">
        {[1, 2, 3, 4, 5, 6].map(item => {
          return (
            <div className="card" key={item}>
              <SkeletonLoading height={250} borderRadius={10} />
              <SkeletonLoading
                height={20}
                width={180}
                style={{ marginTop: '12px' }}
              />
              <SkeletonLoading
                height={20}
                width={250}
                style={{ marginTop: '12px' }}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <SkeletonLoading
                  height={20}
                  width={80}
                  style={{ marginTop: '12px' }}
                />
                <SkeletonLoading
                  height={24}
                  width={100}
                  style={{ marginTop: '12px' }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
};

export default MarketSkeleton;

const Wrapper = styled.div`
  padding: 20px;
  width: 100%;

  .post {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    row-gap: 60px;
    column-gap: 20px;
  }
`;
