import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StyledPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8792a2;
`;

const LoadingPlaceholder = ({
  height = 300,
  width = 700,
}: LoadingPlaceholderProps) => {
  return (
    <StyledPlaceholder height={height} width={width}>
      <FontAwesomeIcon icon="circle-notch" spin size="2x" />
    </StyledPlaceholder>
  );
};

export default LoadingPlaceholder;

type LoadingPlaceholderProps = {
  height?: number;
  width?: number;
};
