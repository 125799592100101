import Steps from '@/newComponents/Steps';
// import moment from 'moment';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import Timeline from './Timeline';
import Typography from '@/newComponents/Typography';
import Button from '@/newComponents/Button';
import Modal from '@/newComponents/Modal';
import {
  SaveButtonContainer,
  StyledButton as OkayButton,
} from './bookingModals/modalStyles';
import StepsSmall from '@/newComponents/Steps/StepsSmall';
import { devices } from '@/utils/theme';

const BookingStepsStyled = styled.div`
  & .booking-progress-container {
    @media ${devices.laptop} {
      padding: 10px 15px;
      background-color: #f5f5f5;
    }

    & .title-wrapper {
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
`;

const Title = styled(Typography)`
  font-size: 14px;
  line-height: 30px;
`;

const StyledButton = styled(Button)`
  font-size: 10px;
  line-height: 25px;
  cursor: default;
`;

const SimpleBookingStepsWrapperStyled = styled.div<any>`
  cursor: pointer;
  margin: 10px 0px;
`;

// const TAGS = [
//   'Processing',
//   'Booking Requested',
//   'Appointment Request',
//   'Contract Issued | Unsigned Contract',
//   'Under Contract | Consideration',
//   'Contract Uploaded | Signed Contract',
//   'Cancelled',
//   'Declined',
//   'Completed',
//   'Viewing Arranged',
// ];

// const TENANT = 'tenant';
// const LANDLORD = 'landlord';

const TAG_COLORS = {
  processing: '#2061f8',
  success: '#00de90',
  error: 'red',
};

type Props = {
  booking: any;
};

const SimpleBookingSteps = ({ booking, ...restProps }: Props) => {
  const [isStepsVisible, setStepsVisibility] = React.useState(false);
  const isBookingCancelled = ['Cancelled', 'Declined'].includes(
    booking?.status,
  );
  const maxWidth510 = useMediaQuery({ minWidth: '510px' });
  const maxWidth769 = useMediaQuery({ minWidth: '769px' });

  const bookingEvents = booking?.bookingEvents;
  const acceptOrReject = bookingEvents?.find(
    (item: any) => item?.type === 'Accepted' || item?.type === 'Declined',
  );
  const underContract = booking.status === 'Under Contract';

  const conversation = booking?.conversations?.createdAt || null;
  const contractGenerate = bookingEvents?.find(
    (item: any) => item?.type === 'Contract',
  );
  const deposited = bookingEvents?.find(
    (item: any) => item?.type === 'Deposit',
  );
  const signed = bookingEvents?.filter((item: any) => item?.type === 'Signed');
  const landlordSigned = signed?.find((item: any) =>
    item?.message.includes('Landlord'),
  );
  const tenantSigned = signed?.find((item: any) =>
    item?.message.includes('tenant'),
  );
  const releaseRent = bookingEvents?.find(
    (item: any) => item?.type === 'Release Rent',
  );

  const hasBookingEventType = (type: string, bEvents: any[]) => {
    const bEvent = bEvents.find(be => be?.type === type);
    if (bEvent?.id) {
      return true;
    }
    return false;
  };

  const BookingSteps = [
    {
      step: 1,
      title: 'Requested',
      status: booking?.createdAt ? 'finish' : 'wait',
      // by: [TENANT],
    },
    {
      step: 2,
      title: 'Review',
      status:
        conversation || acceptOrReject
          ? 'finish'
          : booking?.createdAt && !isBookingCancelled
          ? 'process'
          : 'wait',
      // by: [LANDLORD],
    },
    {
      step: 3,
      title: 'Chat',
      status:
        conversation || contractGenerate
          ? 'finish'
          : acceptOrReject && !isBookingCancelled
          ? 'process'
          : 'wait',
      // by: [TENANT, LANDLORD],
    },
    {
      step: 4,
      title: 'Contract Generation',
      status: contractGenerate
        ? 'finish'
        : conversation && !isBookingCancelled && !underContract
        ? 'process'
        : 'wait',
      // by: [LANDLORD],
    },
    {
      step: 5,
      title: 'Deposit',
      status: deposited ? 'finish' : contractGenerate ? 'process' : 'wait',
      // by: [TENANT],
    },
    {
      step: 6,
      title: 'Contract Sign',
      status:
        landlordSigned && tenantSigned
          ? 'finish'
          : landlordSigned || tenantSigned || deposited
          ? 'process'
          : 'wait',
      // by: [TENANT, LANDLORD],
    },
    {
      step: 7,
      title: 'Release Rent',
      status: releaseRent
        ? 'finish'
        : landlordSigned && tenantSigned
        ? 'process'
        : 'wait',
      // by: [TENANT],
    },
    {
      step: 8,
      title: 'Handover',
      status: releaseRent ? 'finish' : releaseRent ? 'process' : 'wait',
      // by: [LANDLORD],
    },
  ];

  const Tag = ({ bgColor, children }) => (
    <StyledButton variant="tag" radius="round" bgColor={bgColor}>
      {children}
    </StyledButton>
  );

  const Tags = ({ status }: any) => {
    return (
      <div>
        {status === 'Processing' ? (
          <Tag bgColor={TAG_COLORS.processing}>Processing</Tag>
        ) : status === 'Cancelled' ? (
          <Tag bgColor={TAG_COLORS.error}>Cancelled</Tag>
        ) : status === 'Completed' ? (
          <Tag bgColor={TAG_COLORS.success}>Completed</Tag>
        ) : status === 'Declined' ? (
          <Tag bgColor={TAG_COLORS.error}>Declined</Tag>
        ) : status === 'Viewing Arranged' &&
          !hasBookingEventType('Accepted', bookingEvents) ? (
          <Tag bgColor={TAG_COLORS.processing}>Viewing Arranged</Tag>
        ) : status === 'Viewing Arranged' &&
          hasBookingEventType('Accepted', bookingEvents) ? (
          <Tag bgColor={TAG_COLORS.processing}>Selected</Tag>
        ) : status === 'Contract Issued' ? (
          <Tag bgColor={TAG_COLORS.processing}>Contract Issued</Tag>
        ) : status === 'Contract Issued' &&
          !hasBookingEventType('Signed', booking?.bookingEvents) &&
          hasBookingEventType('Deposit', booking?.bookingEvents) ? (
          <Tag bgColor={TAG_COLORS.processing}>Deposited</Tag>
        ) : status === 'Contract Issued' &&
          hasBookingEventType('Signed', booking?.bookingEvents) ? (
          <Tag bgColor={TAG_COLORS.processing}>Contract Signed</Tag>
        ) : status === 'Booking Requested' &&
          !['Reject', 'Accept'].includes(booking?.propertyViewRequest) ? (
          <Tag bgColor={TAG_COLORS.processing}>Booking Requested</Tag>
        ) : status === 'Booking Requested' &&
          ['Reject'].includes(booking?.propertyViewRequest) ? (
          <Tag bgColor={TAG_COLORS.processing}>Appointment Rejected</Tag>
        ) : status === 'Appointment Request' ? (
          <Tag bgColor={TAG_COLORS.processing}>Appointment Request</Tag>
        ) : status === 'Contract Uploaded' ? (
          <Tag bgColor={TAG_COLORS.processing}>Contract Uploaded</Tag>
        ) : status === 'Under Contract' ? (
          <Tag bgColor={TAG_COLORS.error}>Not Selected</Tag>
        ) : (
          ''
        )}
      </div>
    );
  };

  return (
    <BookingStepsStyled>
      <div className="booking-progress-container">
        <div className="title-wrapper">
          <Title variant="p">Booking Progress</Title>
          <Tags
            status={
              !booking?.contract?.isSystemGenerate &&
              booking?.status === 'Contract Issued'
                ? 'Contract Uploaded'
                : booking?.status
            }
          />
        </div>
        <SimpleBookingStepsWrapperStyled
          role="button"
          onClick={() => {
            setStepsVisibility(true);
          }}
          {...restProps}
        >
          {maxWidth510 ? (
            <Steps items={BookingSteps} />
          ) : (
            <StepsSmall items={BookingSteps} />
          )}
        </SimpleBookingStepsWrapperStyled>
      </div>

      {/* TIMELINE MODAL */}
      <Modal
        title="Booking Current Step"
        open={isStepsVisible}
        onClose={() => setStepsVisibility(false)}
        isCentered
        isFullWidth={!maxWidth769}
      >
        <div style={{ maxHeight: 'calc(100vh - 165px)', overflow: 'auto' }}>
          <Timeline propertyDetails={booking} />
        </div>
        <SaveButtonContainer>
          <OkayButton radius="round" onClick={() => setStepsVisibility(false)}>
            Okay
          </OkayButton>
        </SaveButtonContainer>
      </Modal>
    </BookingStepsStyled>
  );
};

export default SimpleBookingSteps;
