import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { useRoot } from '@/RootProvider';
import Calender from '@shared/web/components/Calendar';
import { useSelector } from 'react-redux';
import { Apis, Store, Utils } from '@shared/front';
import { NotificationHelper } from '@shared/front/utils';
import {
  Badge,
  Box,
  CalenderWrapper,
  CardsWrapperStyled,
  Feature,
  FeatureWrapper,
  Icon,
  MainWrapper,
  Title,
  Value,
} from './styles';

const AdminControls = () => {
  const navigation = useNavigate();
  const { auth } = useRoot();
  const role = auth?.role;
  const dashboard: any = useSelector(Store.Select.dashboard);
  console.log('dashboard', dashboard);
  const notifications: any = useSelector(Store.Select.notifications);
  const unReadNotificationCount =
    (!!notifications?.length &&
      notifications?.filter((notification: any) => !notification.isRead)) ||
    [];
  const Notification = NotificationHelper.countNotifications(
    unReadNotificationCount,
  );

  const handleRequest = async () => {
    await Utils.request(Apis.Account.getDashboard());
  };

  const stats: any = [
    ['tenant', 'landlord'].includes(role) && {
      title: role === 'landlord' ? 'My Properties' : 'Properties',
      value: dashboard?.properties || 0,
      icon: 'home-alt',
      bgColor: 'properties',
      textColor: 'properties',
      onClick: () => navigation('/properties'),
    },
    ['service'].includes(role) && {
      title: 'Jobs',
      value: dashboard?.jobs || 0,
      icon: 'file',
      bgColor: 'lightBlue',
      textColor: 'purpleBlue',
      onClick: () => navigation('/jobs'),
    },
    ['tenant', 'landlord'].includes(role) && {
      title: 'Rented',
      value: dashboard?.rented || 0,
      icon: 'check-circle',
      bgColor: 'rented',
      textColor: 'rented',
      onClick: () => {
        navigation('/bookings', { state: { isRented: true } });
      },
    },
    ['service'].includes(role) && {
      title: 'Contracts',
      value: dashboard?.jobContracts || 0,
      icon: 'check',
      bgColor: 'lightGreen',
      textColor: 'darkGreen',
      onClick: () => navigation('/bookings'),
    },
    ['landlord'].includes(role) && {
      title: 'Vacant',
      value: dashboard?.vacant || 0,
      icon: 'map-marker-alt',
      bgColor: 'vacant',
      textColor: 'vacant',
      onClick: () => {
        navigation('/properties', {
          state: { title: 'Vacant', listed: 'vacant' },
        });
      },
    },
    ['tenant'].includes(role) && {
      title: 'Watch List',
      value: dashboard?.vacant || 0,
      icon: 'eye',
      bgColor: 'deepOrange',
      textColor: 'deepOrange',
      // onClick: () => {
      //   navigation('/Bookmark', {
      //     state: { title: 'Watch List', watchList: true },
      //   });
      // },
    },
    {
      title: 'Attention',
      value: notifications?.filter(item => !item?.isRead)?.length,
      icon: 'flag',
      bgColor: 'attention',
      textColor: 'attention',
      onClick: () => {
        navigation('/notifications');
      },
    },
  ].filter(Boolean);
  const featureItems = [
    {
      title: 'Escrow',
      icon: 'wallet',
      color: 'lightBlue',
      onClick: () => navigation('/transactions'),
      notificationCount: Notification.transactionNotificationCount,
    },
    {
      title: 'Calendar',
      icon: 'calendar',
      color: 'lightBlue',
      onClick: () => navigation('/calendar'),
      notificationCount: 0,
    },
    ['tenant', 'landlord'].includes(role) && {
      title: role === 'tenant' ? 'Properties' : 'My Properties',
      icon: 'house-user',
      color: 'lightBlue',
      onClick: () => navigation('/properties'),
      notificationCount:
        role === 'landlord' ? Notification.propertyNotificationCount : 0,
    },
    ['landlord', 'tenant'].includes(role) && {
      title: 'Booking',
      color: 'lightBlue',
      icon: 'book',
      onClick: () => navigation('/bookings'),
      notificationCount: Notification.bookingNotificationCount,
    },
    {
      title: 'Report Issues',
      color: 'lightBlue',
      icon: 'box-tissue',
      onClick: () => navigation('/my-issues'),
      notificationCount: Notification.reportIssueNotificationCount,
    },
    ['service'].includes(role) && {
      title: 'Proposal',
      color: 'lightBlue',
      icon: 'file-contract',
      onClick: () => navigation('/bookings'),
      notificationCount: Notification.proposalNotificationCount,
    },
    ['landlord', 'service'].includes(role) && {
      title: 'Contracts',
      color: 'lightBlue',
      icon: 'file-contract',
      onClick: () => navigation('/jobContracts'),
      notificationCount: Notification.contractNotificationCount,
    },
    ['landlord'].includes(role) && {
      title: 'My Tenants',
      icon: 'person',
      color: 'lightBlue',
      onClick: () => navigation('/mytenant'),
      notificationCount: 0,
    },
    ['service', 'landlord'].includes(role) && {
      title: role === 'service' ? 'Browser Job' : 'Manage Jobs',
      icon: 'hammer',
      color: 'lightBlue',
      onClick: () => navigation('/jobs'),
      notificationCount: Notification.jobNotificationCount,
    },
    {
      title: 'Messages',
      icon: 'message',
      color: 'lightBlue',
      onClick: () => navigation('/chats'),
      notificationCount: Notification.chatNotificationCount,
    },
    ['service', 'tenant'].includes(role) && {
      title: 'Bookmark',
      color: 'lightBlue',
      icon: 'file-contract',
      onClick: () => navigation('/wishlist'),
      notificationCount: 0,
    },
  ].filter(Boolean);
  useEffect(() => {
    handleRequest();
  }, []);
  return (
    <>
      <MainWrapper>
        {stats.map((stat: any) => (
          <CardsWrapperStyled
            onClick={() => stat.onClick()}
            key={stat.title}
            bgColor={stat.bgColor}
            textColor={stat.textColor}
          >
            <Icon icon={stat.icon as IconName} color={stat.textColor} />
            <Value className="value" textColor={stat.textColor}>
              {stat.value}
            </Value>
            <Title textColor={stat.textColor}>{stat.title}</Title>
          </CardsWrapperStyled>
        ))}
      </MainWrapper>
      <CalenderWrapper>
        <Calender />
      </CalenderWrapper>
      <FeatureWrapper>
        {featureItems.map((item: any) => (
          <Feature>
            <Box
              key={item.title}
              hoverable={true}
              onClick={() => item.onClick()}
            >
              <Icon
                icon={item.icon as IconName}
                color={item.color}
                position="noposition"
                size="2x"
              />
            </Box>
            {item.notificationCount > 0 && (
              <Badge>{item.notificationCount}</Badge>
            )}
            <Title textColor={item.color}>{item.title}</Title>
          </Feature>
        ))}
      </FeatureWrapper>
    </>
  );
};

export default AdminControls;
