import ForgotPassword from '@/containers/Auth/ForgotPassword';
import Login from '@/containers/Auth/Login';
import Onboarding from '@/containers/Auth/Onboarding';
import ResetPassword from '@/containers/Auth/ResetPassword';
import SignUp from '@/containers/Auth/SignUp';
import VerifyOTP from '@/containers/Auth/VerifyOTP';
import Bookings from '@/containers/Bookings';
import Dashboard from '@/containers/Dashboard';
import DevMenu from '@/containers/DevMenu';
import Home from '@/containers/Home';
import JobContracts from '@/containers/JobContracts';
import JobContractView from '@/containers/JobContracts/ContractView';
import JobProposals from '@/containers/JobProposals';
import ProposalView from '@/containers/JobProposals/ProposalView';
import Jobs from '@/containers/Jobs';
import AddJob from '@/containers/Jobs/AddJob';
import JobView from '@/containers/Jobs/JobView';
import SearchJobs from '@/containers/Jobs/SearchJobs';
import Profile from '@/containers/Profile';
import Properties from '@/containers/Properties';
import SearchProperties from '@/containers/Properties/PropertyList';
import PropertyView from '@/containers/PropertyView';
import Settings from '@/containers/Settings';
import Transactions from '@/containers/Transactions';
import PrivateLayout from '@/newComponents/Layouts/PrivateLayout';
import PublicLayout from '@/newComponents/Layouts/PublicLayout';
import NotFoundPage from '@shared/web/components/NotFoundPage';
import BrowseTenants from './containers/BrowseTenants';
import BookingView from './containers/Bookings/BookingView';
import BrowseServiceProvider from './containers/BrowseServiceProvider';
import FullCalendarList from './containers/Calendar';
import Chats from './containers/Chats';
import GenerateContract from './containers/GenerateContract';
import MarketItemView from './containers/MarketPlaceItem';
import MarketPlaces from './containers/MarketPlaces';
import MyMarketPlaces from './containers/MyMarketPlace';
import AddProduct from './containers/MyMarketPlace/AddMarketPlace';
import MyTenant from './containers/MyTenant';
import MyTenantView from './containers/MyTenantView';
import Notifications from './containers/Notification';
import UserDesired from './containers/Profile/Desired';
import ServiceDesired from './containers/Profile/ServiceDesired';
import AddProperty from './containers/Properties/AddProperty';
import IssueDetails from './containers/ReportIssues/IssueDetails';
import MyIssues from './containers/ReportIssues/MyIssues';
import WishList from './containers/WishList';
import TermsAndPrivacy from './containers/TermsAndPrivacy';
import { USER_ROLES } from '@shared/common/constants/userRoles';
import { AuthRoleType } from '@shared/types';
import { PropsWithChildren } from 'react';
import DeleteAccount from './containers/DeleteAccount';
import DeleteAccountCancellation from './containers/DeleteAccountCancellation';

const { landlord, service, tenant } = USER_ROLES;

type ComponentType<P> = React.FC<P> | React.ComponentClass<P>;

const routes = (
  isAuthenticated: boolean,
): {
  component: ComponentType<any>;
  path: string;
  layout: React.FC<PropsWithChildren>;
  isPublic: boolean;
  allowedRoles?: AuthRoleType[];
}[] => [
  {
    component: Home,
    path: '/',
    layout: PublicLayout,
    isPublic: true,
  },
  {
    component: Login,
    path: '/login',
    layout: PublicLayout,
    isPublic: true,
  },
  {
    component: SignUp,
    path: '/signup',
    layout: PublicLayout,
    isPublic: true,
  },
  {
    component: VerifyOTP,
    path: '/verifyAccount',
    layout: PublicLayout,
    isPublic: true,
  },
  {
    component: ForgotPassword,
    path: '/forgotPassword',
    layout: PublicLayout,
    isPublic: true,
  },
  {
    component: ResetPassword,
    path: '/resetPassword',
    layout: PublicLayout,
    isPublic: true,
  },
  {
    component: DevMenu,
    path: '/devMenu',
    layout: isAuthenticated ? PrivateLayout : PublicLayout,
    isPublic: true,
  },
  {
    component: SearchJobs,
    path: '/search-jobs',
    layout: isAuthenticated ? PrivateLayout : PublicLayout,
    isPublic: true,
    allowedRoles: [service],
  },

  {
    component: MarketPlaces,
    path: '/marketplace',
    layout: isAuthenticated ? PrivateLayout : PublicLayout,
    isPublic: true,
  },
  {
    component: SearchProperties,
    path: '/search-properties',
    layout: isAuthenticated ? PrivateLayout : PublicLayout,
    isPublic: true,
    ...(isAuthenticated ? { allowedRoles: [tenant] } : {}),
  },
  {
    component: PropertyView,
    path: '/properties/:id',
    layout: isAuthenticated ? PrivateLayout : PublicLayout,
    isPublic: true,
  },
  // {
  //   component: PropertyView,
  //   path: '/search-properties',
  //   layout: isAuthenticated ? PrivateLayout : PublicLayout,
  //   isPublic: true,
  // },
  {
    component: JobView,
    path: '/jobs/:id',
    layout: isAuthenticated ? PrivateLayout : PublicLayout,
    isPublic: true,
  },
  {
    component: MarketItemView,
    path: '/marketplace/:id',
    layout: isAuthenticated ? PrivateLayout : PublicLayout,
    isPublic: true,
  },

  {
    component: Dashboard,
    path: '/dashboard',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [landlord, service, tenant] : [],
  },
  {
    component: GenerateContract,
    path: '/test',
    isPublic: false,
    layout: PrivateLayout,
    allowedRoles: isAuthenticated ? [landlord, tenant] : [],
  },
  {
    component: Onboarding,
    path: '/onboarding',
    layout: PublicLayout,
    isPublic: false,
  },
  {
    component: MyMarketPlaces,
    path: '/mymarketplace',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [landlord, service, tenant] : [],
  },
  {
    component: MarketItemView,
    path: '/mymarketplace/:id',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [landlord, service, tenant] : [],
  },
  {
    component: AddProduct,
    path: '/mymarketplace/add',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [landlord, service, tenant] : [],
  },
  {
    component: AddProduct,
    path: '/mymarketplace/edit/:id',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [landlord, service, tenant] : [],
  },
  {
    component: Properties,
    path: '/properties',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [landlord] : [],
  },
  {
    component: AddProperty,
    path: '/properties/add',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [landlord] : [],
  },
  {
    component: AddProperty,
    path: '/properties/edit/:id',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [landlord] : [],
  },
  {
    component: Jobs,
    path: '/jobs',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [landlord, tenant] : [],
  },
  {
    component: AddJob,
    path: '/jobs/add',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [landlord, tenant] : [],
  },
  {
    component: AddJob,
    path: '/jobs/edit/:id',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [landlord, tenant] : [],
  },
  {
    component: JobContracts,
    path: '/jobContracts',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [landlord, service, tenant] : [],
  },
  {
    component: Bookings,
    path: '/bookings',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [landlord, tenant] : [],
  },
  {
    component: BookingView,
    path: '/bookings/:id',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [landlord, tenant] : [],
  },
  {
    component: Profile,
    path: '/profile',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [landlord, tenant, service] : [],
  },
  {
    component: Profile,
    path: '/profile/:id/:role',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [landlord, tenant, service] : [],
  },
  {
    component: UserDesired,
    path: '/userDesired/add',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [tenant] : [],
  },
  {
    component: UserDesired,
    path: '/userDesired/edit',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [tenant] : [],
  },

  {
    component: ServiceDesired,
    path: '/serviceDesired/add',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [service] : [],
  },
  {
    component: ServiceDesired,
    path: '/serviceDesired/edit',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [service] : [],
  },
  {
    component: Chats,
    path: '/chats',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [landlord, tenant, service] : [],
  },
  {
    component: Chats,
    path: '/chats/:id',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [landlord, tenant, service] : [],
  },
  {
    component: Settings,
    path: '/settings',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [landlord, tenant, service] : [],
  },
  {
    component: WishList,
    path: '/wishlist',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [tenant, service] : [],
  },
  {
    component: Transactions,
    path: '/transactions',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [landlord, tenant, service] : [],
  },
  {
    component: JobProposals,
    path: '/proposals',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [landlord, tenant, service] : [],
  },
  {
    component: MyTenant,
    path: '/mytenant',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [landlord] : [],
  },
  {
    component: MyTenantView,
    path: '/mytenant/:id',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [landlord] : [],
  },
  {
    component: IssueDetails,
    path: '/issue/:id',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [landlord, tenant] : [],
  },
  {
    component: MyIssues,
    path: '/my-issues',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [landlord, tenant] : [],
  },
  {
    component: FullCalendarList,
    path: '/calendar',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [landlord, tenant, service] : [],
  },

  {
    component: JobContractView,
    path: '/jobContracts/:id',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [landlord, tenant, service] : [],
  },
  {
    component: ProposalView,
    path: '/proposals/:id',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [landlord, tenant, service] : [],
  },
  {
    component: Profile,
    path: '/proposals/:id/:role',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [landlord, tenant, service] : [],
  },
  {
    component: Notifications,
    path: '/notifications',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [landlord, tenant, service] : [],
  },
  {
    component: BrowseTenants,
    path: '/search-tenants',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [landlord] : [],
  },
  {
    component: BrowseServiceProvider,
    path: '/search-service-provider',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [landlord, tenant] : [],
  },
  {
    component: TermsAndPrivacy,
    path: '/terms-and-privacy',
    layout: PublicLayout,
    isPublic: true,
    allowedRoles: isAuthenticated ? [landlord, tenant, service] : [],
  },
  {
    component: DeleteAccount,
    path: '/profile/delete',
    layout: PrivateLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [landlord, tenant, service] : [],
  },
  {
    component: DeleteAccountCancellation,
    path: '/deactivated',
    layout: PublicLayout,
    isPublic: false,
    allowedRoles: isAuthenticated ? [landlord, tenant, service] : [],
  },
  {
    component: NotFoundPage,
    path: '*',
    layout: isAuthenticated ? PrivateLayout : PublicLayout,
    isPublic: true,
  },
];

export default routes;
