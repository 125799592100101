import React from 'react';
import styled from 'styled-components';
import Typography from './Typography';

const Select = ({ label, options, onChange, ...restProps }: SelectProps) => {
  return (
    <StyledContainer>
      {label && <StyledLabel variant="p">{label}</StyledLabel>}
      <SelectStyled
        onChange={e => {
          onChange(e.target.value);
        }}
      >
        {options.map((option: any) => (
          <OptionStyled
            id={option.id}
            selected={option.selected}
            disabled={option.disabled}
            key={option.value}
            value={option.value}
          >
            {option.label}
          </OptionStyled>
        ))}
      </SelectStyled>
    </StyledContainer>
  );
};

export default Select;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-family: 'Poppins, sans-seriff';
`;

const StyledLabel = styled(Typography)`
  pointer-events: none;
  margin: 0;
  padding: 0px 2px;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
`;

const SelectStyled = styled.select`
  width: 100%;
  height: 40px;
  border: 1px solid #e0e0e0;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-align: center;
  padding: 0 16px;
  font-family: 'Poppins';

  &:focus {
    outline: none;
    border: 1px solid #e0e0e0;
  }
`;

const OptionStyled = styled.option`
  padding: 8px 16px;
  color: #b3b3b3;
  cursor: pointer;
  font-family: 'Poppins';

  &:hover {
    background-color: #e0e0e0;
  }
`;

interface IOptions {
  id: string | number;
  value: string | number;
  label: string;
  selected?: boolean;
  disabled?: boolean;
}
interface SelectProps extends React.ComponentPropsWithoutRef<'select'> {
  style?: React.CSSProperties;
  text?: string;
  options: IOptions[];
  label?: string;
  onChange: (e) => void;
}
