import Button from '@/newComponents/Button';
import Typography from '@/newComponents/Typography';
import { devices } from '@/utils/theme';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media ${devices.laptop} {
    gap: 40px;
  }
`;

export const SlideImageWrapperStyled = styled.div`
  margin: 0px 3px;
`;
export const BookingDetailsStyled = styled.div`
  position: relative;
  height: auto;
  display: flex;
  align-items: flex-start;
  gap: 40px;
  flex-direction: column-reverse;
  padding-bottom: 100px;
  @media ${devices.laptop} {
    flex-direction: row;
  }
`;

export const LeftBox = styled.div`
  flex: 1;
  width: 100%;

  @media (min-width: 930px) {
  }

  & .booking-status-alert {
    margin-bottom: 20px;
  }
`;
export const RightBox = styled.div`
  text-align: center;
  position: -webkit-sticky;
  position: sticky;
  top: 60px !important;
  width: 100%;
  background: #fff;

  @media ${devices.laptop} {
    width: 240px;
  }
  @media ${devices.laptopXL} {
    width: 280px;
  }
`;

export const RightInnerBox = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 2px;
  padding: 20px;
  margin: 0 auto;
  background: green;
`;

export const LandlordAvatar = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border};
  background: ${({ theme }) => theme.colors.border};
  height: 40px;
  width: 40px;
  border-radius: 40px;
  margin-right: 10px;
`;

export const LocationContainer = styled.div`
  margin: 20px 0;
  padding: 20px 0;

  border-top: 1px solid ${({ theme }) => theme.colors.border};
`;

export const FeatureContainer = styled.div``;

export const ReviewContainer = styled.div``;

export const Review = styled.div`
  margin-top: 20px;
`;

export const TenantProfile = styled.div``;
export const Avatar = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 20px;
  object-fit: cover;
`;

export const BookingActionsStyled = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;

  & button {
    width: 100%;
    margin: 8px 5px;
  }

  @media (min-width: 930px) {
    align-items: stretch;
    flex-direction: column;
  }
`;
export const UserActionsStyled = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;

  & button {
    width: 100%;
    margin: 8px 5px;
  }
  @media (min-width: 992px) {
    align-items: stretch;
    flex-direction: column;
  }
`;

export const ButtonWrapperStyled = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0 50px;
`;

export const Info = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  line-height: 25px;
  color: #949494;
  @media ${devices.laptop} {
    font-size: 14px;
    line-height: 30px;
  }
`;

export const InfoLabel = styled(Info)`
  color: #333;
`;

export const StyledButton = styled(Button)`
  font-size: 12px;
  line-height: 25px;
  padding: 0 30px;
  width: fit-content;
  white-space: nowrap;

  @media ${devices.laptop} {
    font-size: 14px;
    line-height: 30px;
  }
`;

export const DescTitle = styled(InfoLabel)`
  font-weight: 500;
`;

export const SectionTitle = styled(Typography)`
  font-size: 14px;
  line-height: 30px;
  font-weight: 500;
  margin-bottom: 10px;

  @media ${devices.laptop} {
    font-size: 16px;
  }
`;

export const ProfileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
`;

export const ProfileInfoContainer = styled.div`
  display: flex;
  gap: 16px;
`;
