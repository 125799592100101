import styled, { css } from 'styled-components';
import { AnimationDirectionType, SizeType } from '.';
import {
  slideInCenter_bottom,
  slideInCenter_left,
  slideInCenter_right,
  slideInCenter_top,
  slideIn_bottom,
  slideIn_left,
  slideIn_right,
  slideIn_top,
  slideOutCenter_bottom,
  slideOutCenter_left,
  slideOutCenter_right,
  slideOutCenter_top,
  slideOut_bottom,
  slideOut_left,
  slideOut_right,
  slideOut_top,
} from './animationKeyframes';

const getAnimationKeyframes = (
  direction?: AnimationDirectionType,
  isCenter?: boolean,
) => {
  switch (direction) {
    case 'bottom':
      return {
        open: isCenter ? slideInCenter_bottom : slideIn_bottom,
        close: isCenter ? slideOutCenter_bottom : slideOut_bottom,
      };
    case 'top':
      return {
        open: isCenter ? slideInCenter_top : slideIn_top,
        close: isCenter ? slideOutCenter_top : slideOut_top,
      };
    case 'left':
      return {
        open: isCenter ? slideInCenter_left : slideIn_left,
        close: isCenter ? slideOutCenter_left : slideOut_left,
      };
    case 'right':
      return {
        open: isCenter ? slideInCenter_right : slideIn_right,
        close: isCenter ? slideOutCenter_right : slideOut_right,
      };

    default:
      return {
        open: isCenter ? slideInCenter_bottom : slideIn_bottom,
        close: isCenter ? slideOutCenter_bottom : slideOut_bottom,
      };
  }
};

export const getModalSize = (size: SizeType) => {
  if (typeof size === 'number') {
    return size + 'px';
  }
  switch (size) {
    case 'lg':
      return '78%';
    case 'md':
      return '56%';
    case 'sm':
      return '37%';

    default:
      return 137;
  }
};

const getAnimation = (
  direction?: AnimationDirectionType,
  isCentered?: boolean,
  isOpen?: boolean,
) => {
  return css`
    animation: ${() =>
        isOpen
          ? getAnimationKeyframes(direction, isCentered).open
          : getAnimationKeyframes(direction, isCentered).close}
      0.4s ease-in-out;
  `;
};

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const ModalTitle = styled.h4`
  font-size: 18px;
  margin: 0px;
`;

export const OverlayStyled = styled.div<{}>`
  position: fixed;
  z-index: 99999;
  background: black;
  opacity: 60%;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
`;
export const ModalStyled = styled.div<{
  size: SizeType;
  isFullWidth: boolean;
  isCentered?: boolean;
  isOpen?: boolean;
  $direction?: AnimationDirectionType;
  $noAnimation?: boolean;
}>`
  border-radius: 6px;
  position: fixed;
  background: white;
  top: ${({ isCentered, isFullWidth }) =>
    isCentered ? '50%' : isFullWidth ? '0px' : '100px'};
  left: 50%;

  transform: ${({ isCentered }) =>
    isCentered ? 'translate(-50%, -50%)' : 'translateX(-50%)'};

  padding: 10px 20px 30px 20px;
  height: ${({ isFullWidth }) => (isFullWidth ? 'calc(100vh - 0px)' : 'auto')};
  width: ${({ size, isFullWidth }) =>
    isFullWidth ? '100%' : getModalSize(size)};

  z-index: 99999;
  & .close {
    cursor: pointer;
    font-size: 35px;
    margin-left: auto;
  }
  ${({ $direction, isCentered, isOpen, $noAnimation }) =>
    $noAnimation ? null : getAnimation($direction, isCentered, isOpen)}
`;
