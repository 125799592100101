import { Field, Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';

import TextField from '@shared/web/components/TextField';
import Error from '@shared/web/components/Error';
import useAPI from '@/hooks/api';
import { toast } from 'react-toastify';
import Modal from '@/newComponents/Modal';
import {
  ButtonsContainer,
  Container,
  ModalMainText,
  StyledButton,
} from './modalStyles';
import Rating from 'react-rating';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  isReviewModal: string;
  onClose: Function;
  booking: any;
  user: any;
  getBooking: Function;
};

const FeedbackReviewModal = ({
  isReviewModal,
  onClose,
  booking,
  user,
  getBooking,
}: Props) => {
  const [makeReviewRequest, { loading: reviewLoading }] = useAPI();

  const handleClose = () => {
    onClose && onClose();
  };

  const handleReview = async values => {
    if (isReviewModal === 'property') {
      values.propertyId = booking?.propertyId;
    } else if (isReviewModal === 'landlord') {
      values.revieweeId = booking?.landlordId;
    } else if (isReviewModal === 'tenant') {
      values.revieweeId = booking?.tenantId;
    }

    values.bookingId = booking?.id;
    values.reviewerId = user.id;
    // if (user?.role === 'service') {
    //   values.reviewFor = 'landlord';
    //   values.landlordId = user.id;
    // } else {
    //   values.reviewFor = 'service';
    //   values.serviceProviderId = data.serviceProviderId;
    // }

    await makeReviewRequest({
      method: 'post',
      url: `/v1/common/reviews`,
      data: values,
    });
    handleClose();
    await getBooking();
    toast('Your review has been submitted successfully.');

    // navigate('/jobs');
  };
  return (
    <Modal title="Submit review" open={!!isReviewModal} onClose={handleClose}>
      <Formik
        initialValues={{ rating: 0 }}
        onSubmit={values => {
          handleReview(values);
        }}
        validationSchema={yup.object().shape({
          rating: yup.number().required('Please rate'),
          review: yup.string().required('Please provide review'),
        })}
      >
        {({
          isSubmitting,
          errors,
          touched,
          values,
          setFieldValue,
          handleSubmit,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Container>
                <ModalMainText variant="p">Rate</ModalMainText>
                <div>
                  {/* <Rate
                    style={{ marginBottom: 10 }}
                    onChange={val => setFieldValue('rating', val)}
                  /> */}
                  <Rating
                    initialRating={values?.rating}
                    emptySymbol={<FontAwesomeIcon icon={['far', 'star']} />}
                    fullSymbol={
                      <FontAwesomeIcon icon={['fas', 'star']} color="#efe410" />
                    }
                    onChange={val => setFieldValue('rating', val)}
                  />
                  {/* @ts-ignore */}
                  <Error message={errors?.rating} />
                </div>
                <Field component={TextField} label="Feedback" name="review" />
                <ButtonsContainer>
                  <StyledButton
                    variant="gray"
                    radius="round"
                    onClick={handleClose}
                  >
                    Cancel
                  </StyledButton>
                  <StyledButton
                    htmlType="submit"
                    radius="round"
                    isDisabled={reviewLoading || isSubmitting}
                    isLoading={reviewLoading || isSubmitting}
                  >
                    Submit
                  </StyledButton>
                </ButtonsContainer>
              </Container>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default FeedbackReviewModal;
