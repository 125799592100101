import moment from "moment";
moment.locale("en");

interface FormatFromToProps {
  startMonth: string | number;
  startYear: string;
  endMonth?: string | number;
  endYear?: string;
  isActive?: boolean;
}

export const getWeekDays = (date?: string) => {
  const momentDate = moment(date);
  const weekFirstDate = momentDate.startOf("week").add(1, "days");
  return new Array(7).fill(0).map((day, index) => {
    return weekFirstDate.clone().add(index, "days");
  });
};
export const getTodaysDay = () => {
  return moment();
};

export const formatFromTo = (item: FormatFromToProps) => {
  return `${moment().month(item.startMonth).format("MMM")} ${moment(
    item.startYear?.trim()
  ).year()} - ${
    item?.endMonth
      ? `${moment().month(item.endMonth).format("MMM")} ${moment(
          item?.endYear?.trim()
        ).year()}`
      : `Present`
  }`;
};
