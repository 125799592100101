import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../packages/web/src/newComponents/Button";
import Typography from "../../../packages/web/src/newComponents/Typography";
import CustomRate from "../../../packages/web/src/newComponents/CustomRate";
import { useRoot } from "@/RootProvider";

interface ISideProfile {
  profileData: any;
  isProfileViewable: boolean;
  role: string;
  user: any;
}

const SideProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 14px;
  padding: 18px 16px;
  background-color: #f0f0f0;
  width: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

const Avatar = styled.img`
  height: 56px;
  width: 56px;
  border-radius: 50%;
`;

const InnerContent = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
`;

const ProfileTitle = styled(Typography)`
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
`;

const ProfileTag = styled(Button)<{ $role: string }>`
  background-color: "";
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  line-height: 25px;
  margin-top: 5px;
  background-color: ${({ $role }) =>
    ($role === "landlord" || $role === "service") && "#2061F8"};
`;

const ProfileViewButton = styled(Button)`
  width: 100%;
`;

const SideProfile = ({
  profileData,
  isProfileViewable = false,
  role,
}: ISideProfile) => {
  const { auth: user } = useRoot();
  const navigate = useNavigate();
  console.log(profileData, "profile data");

  return (
    <SideProfileContainer>
      <ContentWrapper>
        <Avatar
          src={
            !!profileData?.avatar
              ? profileData?.avatar
              : "https://img.freepik.com/free-icon/user_318-159711.jpg"
          }
        />
        <InnerContent>
          <ProfileTitle variant="h2">
            {`${profileData?.firstName} ${profileData?.lastName}`}
          </ProfileTitle>
          <CustomRate value={profileData?.rating} disabled />
          <ProfileTag variant="tag" radius="round" $role={profileData?.role}>
            {profileData?.role}
          </ProfileTag>
        </InnerContent>
      </ContentWrapper>
      {isProfileViewable && (
        <ProfileViewButton
          onClick={() => {
            profileData?.id === user?.id
              ? navigate(`/profile`)
              : navigate(`/profile/${profileData?.id}/${role}`);
          }}
        >
          View profile
        </ProfileViewButton>
      )}
    </SideProfileContainer>
  );
};

export default SideProfile;
