import moment from "moment";
export function countNotifications(unReadNotifications: any[]) {
  let bookingNotificationCount = 0;
  let transactionNotificationCount = 0;
  let jobNotificationCount = 0;
  let proposalNotificationCount = 0;
  let propertyNotificationCount = 0;
  let reportIssueNotificationCount = 0;
  let contractNotificationCount = 0;
  let chatNotificationCount = 0;
  !!unReadNotifications?.length &&
    unReadNotifications?.map((item: any) => {
      if (!!item?.appRoute?.length) {
        const parseRoute = JSON.parse(item?.appRoute) || [];
        const route = parseRoute[0] || null;
        const params = parseRoute[1] || {};

        if (route === "BookingView") {
          bookingNotificationCount = bookingNotificationCount + 1;
        }

        if (
          (route === "ReportIssueDetails" && params?.propertyId) ||
          (route === "BookingView" &&
            item?.title === "You received an application for your property")
        ) {
          propertyNotificationCount = propertyNotificationCount + 1;
        }
        if (route === "ReportIssueDetails") {
          reportIssueNotificationCount = reportIssueNotificationCount + 1;
        }

        if (route === "TransactionView" || route === "Transactions") {
          transactionNotificationCount = transactionNotificationCount + 1;
        }
        if (
          (route === "ReportIssueDetails" && params?.serviceId) ||
          (route === "JobView" &&
            item?.title === "You received a proposal for your service job")
        ) {
          jobNotificationCount = jobNotificationCount + 1;
        }
        if (route === "ProposalsView") {
          proposalNotificationCount = proposalNotificationCount + 1;
        }
        if (route === "ContractView") {
          contractNotificationCount = contractNotificationCount + 1;
        }
        if (route === "Chats") {
          chatNotificationCount = chatNotificationCount + 1;
        }
      }
    });
  return {
    bookingNotificationCount,
    transactionNotificationCount,
    jobNotificationCount,
    proposalNotificationCount,
    propertyNotificationCount,
    reportIssueNotificationCount,
    contractNotificationCount,
    chatNotificationCount,
  };
}

export function getAttentionNotification(unReadNotifications: any[]) {
  let attentions: any[] = [];
  !!unReadNotifications?.length &&
    unReadNotifications?.map((notification: any) => {
      const route = JSON.parse(notification?.appRoute)
        ? JSON.parse(notification?.appRoute)[0]
        : null;
      if (route) {
        const notificationPropertyId =
          JSON.parse(notification?.appRoute)[1]?.propertyId ||
          JSON.parse(notification?.appRoute)[1]?.id;
        if (
          (route === "BookingView" || route === "PropertyView") &&
          notificationPropertyId
        ) {
          if (!attentions.includes(notificationPropertyId)) {
            attentions.push(notificationPropertyId);
          }
        }
      }
    });

  return attentions;
}

export function getPropertyNotification(unReadNotifications: any[]) {
  let propertyNotifications: any[] = [];

  unReadNotifications?.map((item: any) => {
    if (!!item?.appRoute?.length) {
      const route = JSON.parse(item?.appRoute)[0] || null;
      const notificationPropertyId =
        JSON.parse(item?.appRoute)[1]?.propertyId ||
        JSON.parse(item?.appRoute)[1]?.id;
      if (
        (route === "BookingView" || route === "ReportIssueDetails") &&
        notificationPropertyId
      ) {
        propertyNotifications.push({
          type: route === "BookingView" ? "Notification" : "Report",
          propertyId: notificationPropertyId,
          webRoute: item.webRoute,
          id: item.id,
        });
      }
    }
  });

  return propertyNotifications || [];
}

export function getServiceNotification(unReadNotifications: any[]) {
  let propertyNotifications: any[] = [];

  unReadNotifications?.map((item: any) => {
    if (!!item?.appRoute?.length) {
      const parseRoute = JSON.parse(item?.appRoute);
      const route = parseRoute[0] || null;
      const notificationServiceId =
        parseRoute[1]?.serviceId || parseRoute[1]?.id;
      if (
        (route === "JobView" || route === "ReportIssueDetails") &&
        notificationServiceId
      ) {
        propertyNotifications.push({
          type: route === "JobView" ? "Notification" : "Report",
          serviceId: notificationServiceId,
        });
      }
    }
  });

  return propertyNotifications || [];
}

export function getEventNotifications(notifications: any[]) {
  const filterNotification = notifications?.filter((notification) => {
    if (!!notification?.appRoute?.length) {
      const route = JSON.parse(notification?.appRoute)[0] || null;
      if (
        route === "BookingView" ||
        route === "PropertyView" ||
        route === "TransactionView" ||
        route === "JobsView" ||
        route === "ProposalView"
      ) {
        return notification;
      }
    }
  });

  const groupNotifications =
    filterNotification?.reduce((a: any, b: any) => {
      const date = moment(b.createdAt).format("YYYY-MM-DD");
      if (!a[date]) {
        a[date] = [];
      }
      a[date].push({ name: b.title });
      return a;
    }, {}) || {};

  return groupNotifications;
}
