import React from 'react';
import DeveloperSetting from '../DeveloperSetting';

const DevMenu = () => {
  return (
    <div style={{ display: 'flex', height: '90vh' }}>
      <DeveloperSetting />
    </div>
  );
};

export default DevMenu;
