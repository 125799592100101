import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useRoot } from '@/RootProvider';
import Button from '@/newComponents/Button';
import PropertyCardLarge from '@/newComponents/PropertyLargeCard';
import Typography from '@/newComponents/Typography';
import { devices } from '@/utils/theme';
import { Apis, Store, Utils } from '@shared/front';
import { NotificationHelper, request } from '@shared/front/utils';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import EmptyPage from '../Empty';
import PropertySkeleton from './PropertySkeleton';
import Flex from '@shared/web/components/Flex';
import { toast } from 'react-toastify';
import DeleteModal from '@/components/DeleteModal';

const TabWrapper = styled.div`
  display: flex;
  column-gap: 30px;
`;
const TabItem = styled.button<any>`
  padding: 15px 0px;
  background: none;
  border: 0px;
  color: ${({ active }) => (active ? '#000' : '#949494')};
  cursor: pointer;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 60px;
  background-color: white;
  z-index: 99;
  background: #fff;
  & .title {
    font-size: 1.6rem;
    font-weight: 500;
  }
  padding: 20px 0px 10px 0px;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 20px;
`;

const tabs = [
  {
    label: 'All',
    key: '',
  },
  {
    label: 'Booked',
    key: 'Booked',
  },
  {
    label: 'Active',
    key: 'Active',
  },
];

const OrderTableView = () => {
  const { auth } = useRoot();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState<string>('');

  const isListed = state?.listed ? 'vacant' : undefined;
  const [hasMore, setHasMore] = useState(true);
  const [deletingProperty, setDeletingProperty] = useState({
    status: false,
    id: 0,
    loading: false,
  });

  const role = auth.role;
  const [propertyStatus, setPropertyStatus] = useState('');

  const properties: any = useSelector<any>(Store.Select.properties);

  const notifications: any[] = useSelector(Store.Select.notifications);
  const pagination: any = useSelector<any>(Store.Select.pagination);
  let propertyNotifications: any[] = [];

  if (role === 'landlord') {
    propertyNotifications = NotificationHelper.getPropertyNotification(
      (!!notifications?.length &&
        notifications?.filter(item => !item?.isRead)) ||
        [],
    );
  }

  const dispatch = useDispatch();
  const theme = useTheme();
  const [params, setParams] = useState({
    limit: 10,
    page: 0,
    listed: '',
  });
  const test = () => {};
  useEffect(() => {
    getAllProperties();
  }, [params]);

  const getAllProperties = async () => {
    setLoading(true);
    try {
      if (params.page === 0) {
        await Utils.request(
          Apis.Properties.getProperties({ params, role, action: 'set' }),
        );
      } else {
        await Utils.request(
          Apis.Properties.getProperties({ params, role, action: 'update' }),
        );
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteProperty = async (propertyId: number) => {
    try {
      setDeletingProperty(prev => ({ ...prev, id: propertyId, loading: true }));
      const response = await request(
        Apis.Properties.deleteProperty(propertyId),
      );
      if (response) {
        Store.store.dispatch(Store.Actions.remove('properties', propertyId));
      }
      setDeletingProperty(prev => ({
        id: 0,
        loading: false,
        status: false,
      }));
    } catch (error) {
      toast.error('Failed to delete property.');
    }
  };

  const searchByAddress = event => {
    setParams({ ...params, address: event.target.value, page: params.page });
  };
  const debounceSearch = React.useRef(debounce(searchByAddress, 500)).current;

  const fetchMoreData = () => {
    if (properties.length >= pagination.count) {
      setHasMore(false);
      return;
    }
    setParams(prev => ({ ...prev, page: params.page + 1 }));
  };

  useEffect(() => {
    return () => {
      debounceSearch.cancel();
    };
  }, [params, propertyStatus]);

  const getColorStatus = status => {
    switch (status) {
      case 'Active':
        return theme.colors.statusColors.Active.bg;
      case 'Booked':
        return theme.colors.statusColors.Booked.bg;
      case 'Draft':
        return theme.colors.statusColors.Draft.bg;
      //add as needed
    }
  };

  const handleClickTab = (value: string) => {
    setActive(value);
    let listedValue = value === 'Active' ? 'vacant' : 'rented';
    if (!value) {
      listedValue = 'draft';
    }
    setParams(prev => ({
      ...prev,
      status: value,
      page: 0,
      listed: listedValue,
    }));
  };

  return (
    <>
      <TitleContainer>
        <Typography className="title" variant="p">
          My Properties
        </Typography>
        <Button
          variant="gray"
          radius="round"
          bgColor="red"
          onClick={() => navigate('/properties/add')}
        >
          <Typography variant={'p'} size={'sm'}>
            <FontAwesomeIcon
              icon="plus"
              style={{ marginRight: 5 }}
            ></FontAwesomeIcon>
            Add Property
          </Typography>
        </Button>
      </TitleContainer>
      <TabWrapper>
        {tabs?.map((item: any) => (
          <TabItem
            active={item?.key === active}
            key={item?.key}
            onClick={() => {
              handleClickTab(item.key);
            }}
          >
            {item.label}
          </TabItem>
        ))}
      </TabWrapper>
      {loading && params?.page === 0 && <PropertySkeleton />}
      {!loading && properties?.length === 0 && <EmptyPage />}
      <InfiniteScroll
        dataLength={properties.length}
        hasMore={hasMore}
        next={fetchMoreData}
        loader="Loading"
      >
        <ProductContainer>
          {properties?.length > 0 &&
            properties?.map(item => {
              return (
                <PropertyCardLarge
                  imageUrl={item?.images[0]?.URL}
                  address={item?.address}
                  createdDate={item?.createdAt}
                  //status remain work
                  tag={{
                    color: getColorStatus(item?.status),
                    label: item?.status,
                  }}
                  onClick={() => navigate(`/properties/${item?.id}`)}
                  rightComponent={
                    <Flex direction="column" width={'100px'} gap={'20px'}>
                      {item.status !== 'Booked' && (
                        <Button
                          variant="gray"
                          radius="round"
                          bgColor="red"
                          onClick={e => {
                            e.stopPropagation();
                            navigate(`/properties/edit/${item?.id}`);
                          }}
                        >
                          <FontAwesomeIcon
                            icon="pen"
                            style={{ marginRight: 5 }}
                          />
                          <Typography variant={'p'} size={'sm'}>
                            Edit
                          </Typography>
                        </Button>
                      )}
                      {item.status !== 'Booked' && (
                        <Button
                          isLoading={
                            deletingProperty?.loading &&
                            deletingProperty.id === item?.id
                          }
                          variant="danger"
                          radius="round"
                          bgColor="red"
                          onClick={e => {
                            e.stopPropagation();
                            setDeletingProperty(prev => ({
                              ...prev,
                              id: item?.id,
                              status: true,
                            }));
                          }}
                        >
                          <FontAwesomeIcon
                            icon="trash"
                            style={{ marginRight: 5 }}
                          />
                          <Typography variant={'p'} size={'sm'} color="#fff">
                            Delete
                          </Typography>
                        </Button>
                      )}
                    </Flex>
                  }
                />
              );
            })}
        </ProductContainer>
      </InfiniteScroll>
      {loading && params?.page > 0 && <PropertySkeleton />}
      <DeleteModal
        isVisible={deletingProperty.status}
        onClose={() => {
          setDeletingProperty(prev => ({
            ...prev,
            id: 0,
            loading: false,
            status: false,
          }));
        }}
        onAction={() => {
          handleDeleteProperty(deletingProperty.id);
        }}
        isLoading={deletingProperty.loading}
      >
        <Typography
          size="sm"
          fontWeight="bold"
          style={{ marginBottom: '10px' }}
        >
          Are you sure you want to delete this property from Draft?
        </Typography>
        <Typography size="sm" color="#aeaeae">
          You can edit property to make it Active.
        </Typography>
      </DeleteModal>
    </>
  );
};
export default OrderTableView;

interface Pagination {
  limit: number;
  lastPage: number;
  page: number;
}

const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 20px;
  @media ${devices.tablet} {
    gap: 0px;
  }
`;
