type bookingProcessActionButtonType = {
  isLandlord: boolean;
  isTenant: boolean;
  booking: any;
  bookingStatus: string;
  bookingCurrentStep: number;
  isSystemGenerate: boolean;
  declineLoading: boolean;
  cancelLoading: boolean;
  setContractGenerateVisible: Function;
  setDepositeVisible: Function;
  setManulGenerateUpdateVisible: Function;
  setSignModalVisibility: Function;
  setPaymentRentRelease: Function;
  setCancelModalVisibility: Function;
  setDeclineModalVisibility: Function;
};

const hasBookingEventType = (type: string, bEvents: any[]) => {
  const bEvent = bEvents.find(be => be?.type === type);
  if (bEvent?.id) {
    return true;
  }
  return false;
};

export default ({
  booking,
  bookingCurrentStep,
  bookingStatus,
  isLandlord,
  isSystemGenerate,
  isTenant,
  declineLoading,
  cancelLoading,
  setContractGenerateVisible,
  setDepositeVisible,
  setManulGenerateUpdateVisible,
  setSignModalVisibility,
  setPaymentRentRelease,
  setCancelModalVisibility,
  setDeclineModalVisibility,
}: bookingProcessActionButtonType) => {
  return [
    // isLandlord &&
    //   bookingCurrentStep < 2 &&
    //   bookingStatus == 'Booking Requested' &&
    //   !['Declined', 'Cancelled'].includes(bookingStatus) && {
    //     title: 'Accept Application',
    //     color: 'primaryButton',
    //     loading: acceptLoading,
    //     onClick: () => {
    //       handleAccept();
    //     },
    //   },

    isLandlord &&
      bookingCurrentStep < 2 &&
      !['Declined', 'Cancelled'].includes(bookingStatus) && {
        title: 'Decline Application',
        color: 'danger',
        loading: declineLoading,
        onClick: () => {
          setDeclineModalVisibility(true);
        },
      },

    isTenant &&
      ![
        'Cancelled',
        'Declined',
        'Contract Issued',
        'Under Contract',
        'Completed',
      ].includes(bookingStatus) && {
        title: 'Cancel Application',
        color: 'danger',
        loading: cancelLoading,
        onClick: () => {
          setCancelModalVisibility(true);
        },
      },

    isLandlord &&
      bookingCurrentStep === 2 &&
      !['Cancelled', 'Declined', 'Contract Issued', 'Completed'].includes(
        bookingStatus,
      ) && {
        title: 'Generate Contract',
        color: 'primaryButton',
        onClick: () => {
          setContractGenerateVisible(true);
        },
      },

    isTenant &&
      bookingCurrentStep === 3 && {
        title: 'Security Deposit',
        onClick: () => {
          setDepositeVisible(true);
        },
      },

    !isSystemGenerate &&
      isLandlord &&
      bookingCurrentStep === 4 &&
      !booking?.tenantSign &&
      !booking?.landlordSign &&
      ['Contract Issued'].includes(bookingStatus) && {
        title: 'Update Manual Contract',
        onClick: () => {
          setManulGenerateUpdateVisible(true);
        },
      },

    isTenant &&
      isSystemGenerate &&
      booking?.status !== 'Completed' &&
      bookingCurrentStep === 4 &&
      !booking?.tenantSign && {
        title: 'Sign contract',
        onClick: () => {
          setSignModalVisibility(true);
        },
      },
    isLandlord &&
      isSystemGenerate &&
      booking?.status !== 'Completed' &&
      bookingCurrentStep === 4 &&
      !booking?.landlordSign && {
        title: 'Sign contract',
        onClick: () => {
          setSignModalVisibility(true);
        },
      },
    isTenant &&
      bookingCurrentStep === 4 &&
      booking?.landlordSign &&
      booking?.tenantSign && {
        title: 'Release Rent',
        color: 'primaryButton',
        loading: false,
        onClick: async () => {
          setPaymentRentRelease(true);
        },
      },
    isTenant &&
      bookingCurrentStep === 4 &&
      ['Completed'].includes(bookingStatus) && {
        title: 'Release Rent',
        onClick: () => {
          setPaymentRentRelease(true);
        },
      },
  ].filter(Boolean);
};
