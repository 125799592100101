import * as React from "react";
import styled from "styled-components";

const NotFoundPage = () => {
  return (
    <Wrapper>
      <Title>
        4
        <span role="img" aria-label="Crying Face">
          😢
        </span>
        4
      </Title>
      <P>Page not found.</P>
    </Wrapper>
  );
};
export default NotFoundPage;

const Wrapper = styled.div`
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 320px;
`;

const Title = styled.div`
  margin-top: -8vh;
  font-weight: bold;
  color: black;
  font-size: 3.375rem;

  span {
    font-size: 3.125rem;
  }
`;

const P = styled.p`
  /* font-size: 1rem; */
  line-height: 1.5;
  color: black;
  margin: 0.625rem 0 1.5rem 0;
`;
