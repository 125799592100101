import React, { useEffect, useState } from 'react';
import { useRoot } from '@/RootProvider';
import { SectionHeaderRow, SectionTitle, SectionWrapper } from '../style';
import Button from '@/newComponents/Button';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Store, Apis, Utils } from '@shared/front';
import NoData from '@/newComponents/NoData';
import Typography from '@/newComponents/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Column, Row } from '@/newComponents/SpreadsheetTools';
import PolicyModal from './PolicyModal';
import Modal from '@/newComponents/Modal';
import { toast } from 'react-toastify';
import { ButtonEndWrapperStyled } from '@/styles/shareableStyle';

import { Image } from '../style';
import iconPropertyPolicy from '@shared/common/constants/iconPolicy';
import { useResponsive } from '@/hooks/useResponsive';
import EditIconNew from '../../../assets/edit-icon-new.svg';
import DeleteIconNew from '../../../assets/delete-icon-new.svg';

const PolicyWrapper = styled.div``;

const StyledButton = styled.button`
  padding: 0px 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  & + & {
    margin-left: 3px;
  }
`;
const InventoryActionWrapper = styled.div`
  display: flex;
`;

const PolicyList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
`;
const PolicyText = styled.div`
  width: 100%;
`;
const PolicyItem = styled.div`
  background: #f5f5f5;

  padding: 10px 20px;
  border-radius: 4px;
  display: flex;
  width: 100%;
  /* justify-content: space-between; */
`;

const StyledIconSpan = styled.span`
  cursor: pointer;
  /* Add any additional styling here if needed */
`;
type Props = {
  propertyId?: number;
  bookingId?: number;
  landlordId?: number;
};

const Policy = ({ propertyId, landlordId }: Props) => {
  const { auth: user } = useRoot();
  const { isTablet } = useResponsive();

  const policies: any[] = useSelector(Store.Select.policies);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [deleteLoading, setDeleteLoading] = useState<any>({
    id: null,
    status: false,
  });
  const [confirmDeletion, setConfirmDeletion] = useState<{
    status: boolean;
    data: any;
  }>({
    status: false,
    data: null,
  });
  const [open, setOpen] = useState<boolean>(false);
  // const [loading, setLoading] = useState<boolean>(false);

  // const getPolicies = async () => {
  //   await Utils.request(Apis.Properties.getPropertyById(propertyId, auth?.role));
  // };
  const hasAccess = user?.id === landlordId;

  const getPolicies = async () => {
    try {
      await Utils.request(Apis.Properties.getPolicies(propertyId));
    } catch (err) {
      console.log(err);
    }
  };
  const deletePolicy = async (categoryId: number) => {
    try {
      await Utils.request(Apis.Properties.deletePolicy(categoryId));
      toast.success('Deleted successfully');
      Store.store.dispatch(Store.Actions.remove('policies', categoryId));
      setDeleteLoading({ ...deleteLoading, status: false });
      closeDeleteModal();
    } catch (err) {
      toast.error('Failed');
      setDeleteLoading({ ...deleteLoading, status: false });
    }
  };
  useEffect(() => {
    getPolicies();
  }, []);

  const openDeleteModal = (data: any) => {
    setConfirmDeletion({
      status: true,
      data,
    });
  };

  const closeDeleteModal = () => {
    setConfirmDeletion({
      status: false,
      data: null,
    });
  };
  return (
    <SectionWrapper>
      <SectionHeaderRow>
        <SectionTitle>Policies</SectionTitle>
        {hasAccess && (
          <Button
            style={{ marginLeft: 'auto' }}
            variant="gray"
            size="sm"
            onClick={() => {
              setOpen(true);
            }}
          >
            Add
          </Button>
        )}
      </SectionHeaderRow>
      <PolicyWrapper>
        {!!policies?.length ? (
          <PolicyList>
            {policies?.map(category => (
              <PolicyItem key={category?.id}>
                <Image
                  style={{ height: 20, paddingRight: 10 }}
                  src={iconPropertyPolicy?.icon}
                />
                <PolicyText>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography variant="p">{category?.title}</Typography>
                    {hasAccess && (
                      <InventoryActionWrapper>
                        <StyledButton
                          onClick={() => {
                            setIsEditMode(true);
                            setSelectedItem(category);
                            setOpen(true);
                          }}
                        >
                          <FontAwesomeIcon icon="pencil" color="blue" />
                        </StyledButton>
                        <StyledButton
                          onClick={e => {
                            e.stopPropagation();
                            openDeleteModal(category);
                          }}
                        >
                          <img src={DeleteIconNew} alt="Delete" />
                        </StyledButton>
                      </InventoryActionWrapper>
                    )}
                  </div>
                  <Typography variant="p">{category?.desc}</Typography>
                </PolicyText>
              </PolicyItem>
            ))}
          </PolicyList>
        ) : (
          <NoData size="130" title="No Policies are available" />
        )}
      </PolicyWrapper>
      {hasAccess && (
        <PolicyModal
          propertyId={propertyId}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          setOpen={setOpen}
          open={open}
        />
      )}
      {hasAccess && (
        <Modal
          open={confirmDeletion.status}
          onClose={closeDeleteModal}
          title="Delete"
          isCentered={!isTablet}
          style={{ width: !isTablet ? '90vw' : 'auto' }}
        >
          Are you sure you want to delete {confirmDeletion?.data?.title} ?
          <br />
          <br />
          <ButtonEndWrapperStyled>
            <Button variant="gray" onClick={closeDeleteModal}>
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                deletePolicy(confirmDeletion?.data?.id);
              }}
              isLoading={deleteLoading.status}
              isDisabled={deleteLoading.status}
            >
              Delete
            </Button>
          </ButtonEndWrapperStyled>
        </Modal>
      )}
    </SectionWrapper>
  );
};

export default Policy;
