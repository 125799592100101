import { uniqBy, orderBy, flatten } from "lodash";
import { promisifyEmit } from "./helpers";
import Store from "../store";
import Utils from "../utils";
const store = Store.store;
let lastUpdatedMessage: any;
export function subscribers(socket: any) {
  socket.on("refreshChat", () => {
    socket.log("refresh chat");
    getConversations(socket);
  });
  socket.on("chats", (data: any) => {
    if (data?.pagination?.page > 0) {
      store.dispatch(
        Store.Actions.update("chats", (currentData: any) => {
          return [...currentData, ...data?.data];
        })
      );
    } else {
      store.dispatch(Store.Actions.set("chats", data?.data));
    }
    store.dispatch(Store.Actions.set("pagination", data?.pagination));
  });

  socket.on("conversations", (data: any) => {
    store.dispatch(Store.Actions.set("conversations", data));
    store.dispatch(
      Store.Actions.update("chats", (currentData: any) => {
        return orderBy(
          uniqBy(
            [...flatten(data.map((item: any) => item.chats)), ...currentData],
            "id"
          ),
          "id",
          "desc"
        );
      })
    );
    data.map((item: any) => {
      joinRoom(socket, `chatRoom-${item.id}`);
    });
  });

  socket.on("marketConversations", (data: any) => {
    store.dispatch(Store.Actions.set("marketConversations", data));
    store.dispatch(
      Store.Actions.update("chats", (currentData: any) => {
        return orderBy(
          uniqBy(
            [...flatten(data.map((item: any) => item.chats)), ...currentData],
            "id"
          ),
          "id",
          "desc"
        );
      })
    );
  });

  socket.on("getUserNotifications", (data: any) => {
    if (data?.pagination?.page > 0) {
      store.dispatch(
        Store.Actions.update("notifications", (currentData: any) => {
          return [...currentData, ...data?.data];
        })
      );
    } else {
      store.dispatch(Store.Actions.set("notifications", data?.data));
    }
    store.dispatch(
      Store.Actions.set("notificationPagination", data?.pagination)
    );
  });

  socket.on("getLatestNotification", (data: any) => {
    store.dispatch(
      Store.Actions.update("unReadNotifications", (currentData: any) => {
        return [...[data], ...currentData];
      })
    );
    store.dispatch(
      Store.Actions.update("notifications", (currentData: any) => {
        return [...[data], ...currentData];
      })
    );
  });

  socket.on("getUserUnreadNotification", (data: any) => {
    store.dispatch(Store.Actions.set("unReadNotifications", data));
  });

  socket.on("updateChatStatus", (data: any) => {
    store.dispatch(
      Store.Actions.update("conversations", (chats: any) => {
        let toUpdateArrayIndex = chats.findIndex((chat: any) => {
          if (data.tenant) {
            return chat.tenant === data.tenant;
          } else if (data.landlordId) {
            return chat.landlordId === data.landlordId;
          } else {
            return chat.serviceId === data.serviceId;
          }
        });

        if (toUpdateArrayIndex >= 0) {
          return chats.map((chat: any, index: number) => {
            if (index === toUpdateArrayIndex) {
              return {
                ...chat,
                isActive: !!data.isActive,
              };
            } else {
              return chat;
            }
          });
        } else {
          return chats;
        }
      })
    );
  });

  socket.on("addChat", (data: any) => {
    store.dispatch(
      Store.Actions.update("chats", (prevData: any) =>
        orderBy(uniqBy([data, ...prevData], "id"), "id", "desc")
      )
    );

    store.dispatch(
      Store.Actions.update("conversations", (prevData: any) => {
        const updatedPrev = prevData.map((x) => {
          if (x.id === data?.conversationId) {
            return {
              ...x,
              chats: [data],
            };
          }
          return x;
        });

        return updatedPrev;
      })
    );

    const currentRouteInfo =
      Utils.navigation.navigationRef?.current?.getCurrentRoute();

    if (
      !data.isDelivered &&
      ((currentRouteInfo?.name === "ChatView" &&
        currentRouteInfo?.params?.id !== data?.conversationId) ||
        currentRouteInfo?.name !== "ChatView")
    ) {
      updateChats(socket, [{ ...data, isDelivered: true }]);
    }
  });

  socket.on("updateChats", (data: any) => {
    store.dispatch(
      Store.Actions.update("chats", (prevData: any) =>
        orderBy(uniqBy([...data, ...prevData], "id"), "id", "desc")
      )
    );

    // socket?.getConversations(data[0]?.conversationId);
  });

  //do i need to emit funtion to get the updated chats
  //data can not parsed from here
}

export async function getConversationChats(socket: any, params: any) {
  return await promisifyEmit(socket, "getConversationChats", params);
}

export function getConversations(socket: any) {
  socket?.emit("getConversations");
}
export function getMarketConversations(socket: any) {
  socket?.emit("getMarketConversations");
}

export async function addChat(socket: any, data: any) {
  return await promisifyEmit(socket, "addChat", data);
}

export async function isAvailable(socket: any, data: any) {
  return await promisifyEmit(socket, "isAvailable", data);
}

export async function createGroupMessage(socket: any, data: any) {
  return await promisifyEmit(socket, "createGroupMessage", data);
}

export async function appointmentAcceptReject(socket: any, data: any) {
  return await promisifyEmit(socket, "appointmentAcceptReject", data);
}

export function updateChats(socket: any, data: any) {
  const toUpdateData = Array.isArray(data) ? data : [data];

  if (
    lastUpdatedMessage &&
    lastUpdatedMessage === JSON.stringify(toUpdateData)
  ) {
    return false;
  }
  socket?.emit("updateChats", toUpdateData);
  lastUpdatedMessage = JSON.stringify(toUpdateData);
}

export function joinRoom(socket: any, room: string) {
  socket?.emit("joinRoom", room);
}

export function leaveRoom(socket: any, room: string) {
  socket?.emit("leaveRoom", room);
}

export async function getUserNotifications(socket: any, params: any) {
  return await promisifyEmit(socket, "getUserNotifications", params);
}
export async function getUnreadNotifications(socket: any, params: any) {
  return await promisifyEmit(socket, "getUserUnreadNotification", params);
}

export async function updateUnReadNotifications(socket: any, ids: any[]) {
  return await promisifyEmit(socket, "updateBulkNotification", ids);
}
