
import AlarmIcon from "../constants/icon/alram.svg";
import BellIcon from "../constants/icon/bell.svg";
import HeaterIcon from "../constants/icon/heater.svg";


const iconAmenities = [
  {
    icon: AlarmIcon,
    title: "Alarm",
    totalSites: 120,
  },
  {
    icon: BellIcon,
    title: "Fitness Room/Equipment",
    totalSites: 120,
  },
  {
    icon: HeaterIcon,
    title: "Central Heating",
    totalSites: 120,
  },
];
export default iconAmenities;
