import React, { ReactNode } from 'react';
import styled from 'styled-components';
import Typography from './Typography';
import Button from './Button';
import { devices } from '../utils/theme';
import NotificationMarkedIcon from './assets/notification-marked.svg';
import InfoIcon from './assets/info-icon.svg';
import ImageWithFallBack from './ImageWithFallBack';
import moment from 'moment';

interface IPropertyCardLargeProps {
  imageUrl: string;
  address: string;
  tag: {
    color: string;
    label: string;
  };
  onClick?: () => void;
  rightComponent?: ReactNode;
  notificationStatus?: boolean;
  info?: string;
  createdDate?: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 10px;

  @media ${devices.mobileSM} {
    flex-direction: row;
    align-items: center;
    border: none;
    padding: 30px;
    border-bottom: 1px solid #e0e0e0;
    border-radius: 0;
  }
`;

const ContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media ${devices.mobileSM} {
    flex-direction: row;
    gap: 38px;
  }
`;

const Image = styled.div`
  width: 100%;
  height: 153px;
  border-radius: 10px;
  @media ${devices.mobileSM} {
    width: 240px;
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 10px;

  @media ${devices.mobileSM} {
    padding: 20px 0;
    gap: 16px;
  }
`;

const NotificationWrapper = styled.div`
  display: flex;
  gap: 10px;

  @media ${devices.mobileSM} {
    margin-top: 9px;
  }
`;

const Address = styled(Typography)`
  font-size: 18px;
  font-style: italic;
  font-weight: 500;
`;

const PropertyCardLarge = ({
  imageUrl,
  address,
  tag,
  notificationStatus,
  info,
  rightComponent,
  createdDate,
  onClick,
}: IPropertyCardLargeProps) => {
  return (
    <Container onClick={onClick}>
      <ContentLeft>
        <Image>
          <ImageWithFallBack src={imageUrl} />
        </Image>

        <Description>
          <Address variant="h2" color="#1f1f1f">
            {address}
          </Address>
          <Button
            variant="tag"
            bgColor={tag.color}
            radius="round"
            style={{ fontSize: '12px', width: 'fit-content' }}
          >
            {tag.label}
          </Button>
          <Typography>
            {moment(createdDate).format('DD MMM, YYYY hh:mm A')}
          </Typography>
          {/* <NotificationWrapper>
            <img src={NotificationMarkedIcon} alt="notification icon" />
            <img src={InfoIcon} alt="info icon" />
          </NotificationWrapper> */}
        </Description>
      </ContentLeft>
      {rightComponent}
    </Container>
  );
};

export default PropertyCardLarge;
