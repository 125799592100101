import useAPI from '@/hooks/api';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useRoot } from '@/RootProvider';
import Typography from '@/newComponents/Typography';
import { devices } from '@/utils/theme';
import moment from 'moment';
import numeral from 'numeral';
import Button from '@/newComponents/Button';

const Container = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  margin-bottom: 20px;
`;

const AvatarAndInfoWrapperStyled = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1;
`;

const ContractView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
  margin: 15px 0px;
  & + & {
    border-top: 1px solid #f0f0f0;
  }
`;

const UserAvatar = styled.img`
  height: 58px;
  width: 58px;
  border-radius: 50%;
  margin-right: 20px;
`;

const DataRowWrapperStyled = styled.div`
  & .label,
  & .value {
    font-size: 12px;
    font-weight: 400;
    line-height: 25px;
    color: #333333;

    @media ${devices.laptopXL} {
      font-size: 14px;
      line-height: 30px;
    }
  }
  & .label {
    margin-right: 15px;
  }
  & .value {
    color: #8a8a8a;
  }
`;

const ActionsContainerStyled = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 8px;
`;
type Props = {
  contracts: any;
};
const Contracts = ({ contracts }: Props) => {
  const [makeMessageRequest, { loading: messageLoading }] = useAPI();
  const { auth } = useRoot();
  const navigate = useNavigate();

  const handleMessage = async ({ serviceId, jobId }: any) => {
    const data = {
      [`${auth?.role}Id`]: auth?.id,
      chatType: 'job',
      jobId: contracts?.serviceId,
      serviceId: serviceId,
    };

    const messageId = await makeMessageRequest({
      method: 'post',
      url: `/v1/common/message`,
      data: {
        serviceId: serviceId,
        landlordId: auth?.role === 'landlord' ? auth?.id : null,
        tenantId: auth?.role === 'tenant' ? auth?.id : null,
        jobId: jobId ? jobId : null,
        chatType: 'job',
      },
    });

    navigate(`/chats/${messageId}`);
  };

  return (
    <Container>
      <Typography variant="h5" fontWeight="extrabold">
        Contracts (Hired)
      </Typography>
      {contracts?.map((contract: any) => (
        <ContractView key={contract.id}>
          <AvatarAndInfoWrapperStyled>
            <UserAvatar
              src={
                contract?.serviceProvider?.avatar
                  ? contract?.serviceProvider?.avatar
                  : 'https://img.freepik.com/free-icon/user_318-159711.jpg'
              }
            />
            <div>
              <Typography variant="h5" style={{ fontWeight: '500' }}>
                {contract.serviceProvider.fullName}
              </Typography>
              <DataRowWrapperStyled>
                <Typography variant="span" className="label">
                  Contract amount
                </Typography>
                <Typography variant="span" className="value">
                  €{numeral(contract.price).format('0,0.00')}
                </Typography>
              </DataRowWrapperStyled>
              <DataRowWrapperStyled>
                <Typography variant="span" className="label">
                  Paid amount
                </Typography>
                <Typography variant="span" className="value">
                  €{numeral(contract.paidAmount).format('0,0.00')}
                </Typography>
              </DataRowWrapperStyled>
              <DataRowWrapperStyled>
                <Typography variant="span" className="label">
                  Hired at
                </Typography>
                <Typography variant="span" className="value">
                  {moment(contract.createdAt).format('YYYY-MM-DD hh:mm a')}
                </Typography>
              </DataRowWrapperStyled>
            </div>
          </AvatarAndInfoWrapperStyled>
          <ActionsContainerStyled>
            <Button
              size="sm"
              variant="gray"
              onClick={() => navigate(`/jobContracts/${contract.id}`)}
            >
              View
            </Button>
            {/*issues on this message button*/}
            <Button
              size="sm"
              onClick={() =>
                handleMessage({
                  serviceId: contract.serviceProviderId,
                  jobId: contract?.serviceId,
                })
              }
            >
              Message
            </Button>
          </ActionsContainerStyled>
        </ContractView>
      ))}
    </Container>
  );
};

export default Contracts;
