import LoadingPlaceholder from '@shared/web/components/LoadingPlaceholder';
import useAPI from '@/hooks/api';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import BankAccount from './BankAccount';
import CreditCard from './CreditCard';
import Profile from './Profile';
import Documents from './Documents';
import DevMenu from './DevMenu';
import Stepper from '@/newComponents/Stepper';
const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  min-height: 600px;
  /* justify-content: center; */
  padding-top: 60px;
`;

const Content = styled.div`
  max-width: 640px;
  flex: 1;
  padding: 0 40px;
`;
const Settings = () => {
  const navigate = useNavigate();
  // @ts-ignore
  const { id: propertyId } = useParams();
  const [getPropertyRequest] = useAPI();
  const [step, setStep] = useState(0);
  const [validatedTab, setValidatedTab] = useState(3);
  const [initialValues, setInitialValues] = useState({
    features: [],
    images: [null],
    address: '',
  });

  const getProperty = async () => {
    const property = await getPropertyRequest({
      method: 'get',
      url: `/v1/properties/property/${propertyId}`,
    });
    setInitialValues(property);
  };

  useEffect(() => {
    if (propertyId) {
      getProperty();
    }
  }, []);

  const onSubmitStep = ({ data, isDraft }) => {
    if (isDraft) {
      navigate('/properties');
    } else {
      if (step < steps.length - 1) {
        setInitialValues(data);
        setStep(step + 1);
        setValidatedTab(prev => Math.max(step + 1, prev));
      } else {
        navigate('/properties');
      }
    }
  };

  const items = [
    { label: 'Documents', key: 'document', children: <Documents /> },
    { label: 'Credit Card', key: 'creditCard', children: <CreditCard /> },
    { label: 'Bank Account', key: 'bankAccount', children: <BankAccount /> },
  ];
  const steps = [
    {
      key: 0,
      children: <Documents />,
      label: 'Documents',
    },
    {
      key: 1,
      children: <CreditCard />,
      label: 'Credit Card',
    },
    {
      key: 2,
      children: <BankAccount />,
      label: 'Bank Account',
    },
  ];
  // @ts-ignore
  if (propertyId && !initialValues?.id) {
    return <LoadingPlaceholder />;
  }

  return (
    <Container>
      {/* <Tabs
        activeKey={`${step}`}
        tabPosition="left"
        onChange={key => {
          //@ts-ignore
          if (+key <= validatedTab) {
            setStep(+key);
          }
        }}
        tabBarStyle={{
          width: 200,
        }} */}
      {/* > */}
      {/* {steps.map((item, index) => (
          <Tabs.items
            tab={item.title}
            key={index}
            disabled={index > validatedTab}
          ></Tabs.items>
        ))} */}

      {/* </Tabs> */}
      {/* <Tabs items={items} tabPosition="left" style={{ width: '100%' }} /> */}
      <Stepper
        activeStep={step}
        stepItems={steps}
        enableTabClick
        onTabClick={data => {
          setStep(data?.key);
        }}
      />
    </Container>
  );
};

export default Settings;
