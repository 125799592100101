import React from 'react';
import { useNavigate } from 'react-router-dom';
import NoData from '@/newComponents/NoData';
import PropertyCard from '@/newComponents/PropertyCard';

const List = ({ property, paginationLength }: any) => {
  const navigate = useNavigate();

  return (
    <>
      {paginationLength == 0 ? (
        <NoData />
      ) : (
        <PropertyCard
          onClick={() => navigate(`/properties/${property.id}`)}
          address={property?.address}
          price={property?.price}
          image={
            property?.images?.length > 0
              ? property?.images[0]?.URL
              : 'fallback_image_url_here'
          }
        ></PropertyCard>
      )}
    </>
  );
};

export default List;
