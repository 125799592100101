import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { useRoot } from '@/RootProvider';
import { Apis, Utils } from '@shared/front';
import store from '@shared/front/store';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { PropertyTitle, SectionDescription } from './style';
const PropertyLists = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  column-gap: 30px;
  row-gap: 12px;
  margin-top: 30px;
  margin-bottom: 100px;
  & .property-info-wrapper {
    display: flex;
    align-items: center;

    & .title {
      width: 100px;
      font-weight: 500;
    }
    & .value {
      color: #666;
    }
  }
`;
const PropertyInfoWrapper = styled.div`
  display: flex;
`;

const PropertyInfo = ({
  area,
  id,
  bathrooms,
  bedrooms,
  propertyType,
  furnishedType,
  rentalMarket,
  address,

  berNo,
}: PropertyInfoProps) => {
  const dispatch = useDispatch();
  const myBookmarkProperties = useSelector(
    state => state.myBookmarks?.propertyIds,
  );
  const createBookMarked = async () => {
    try {
      await Utils.request(Apis.Bookmark.createBookmark({ propertyId: id }));
      dispatch(
        store.Actions.update('myBookmarks', {
          propertyIds: [...myBookmarkProperties, id],
        }),
      );
    } catch (err) {
      console.log(err);
    }
  };

  const deleteBookMarked = async () => {
    try {
      await Utils.request(Apis.Bookmark.deleteBookmark({ propertyId: id }));
      dispatch(
        store.Actions.update('myBookmarks', {
          propertyIds: [...myBookmarkProperties.filter(x => x !== id)],
        }),
      );
    } catch (err) {
      console.log(err);
    }
  };

  const toggleMarked = status => {
    if (status) {
      deleteBookMarked();
    } else {
      createBookMarked();
    }
  };
  const valueToShow = [
    {
      title: 'Area',
      value: area ? `${area} sq.ft` : 'N/A',
    },
    {
      title: 'Bedrooms',
      value: bedrooms ?? 'N/A',
    },

    {
      title: 'Bathrooms',
      value: bathrooms ?? 'N/A',
    },
    {
      title: 'Property Type',
      value: propertyType ?? 'N/A',
    },
    {
      title: 'Furnished Type',
      value: furnishedType ?? 'N/A',
    },
    {
      title: 'Rental Market',
      value: rentalMarket ?? 'N/A',
    },
    {
      title: 'Ber No',
      value: berNo ?? 'N/A',
    },
  ];

  const isBookmark = myBookmarkProperties?.includes(id);
  const root = useRoot();
  const { auth } = root;
  const renderRow = (title: string, value: string | number) => {
    return value ? (
      <PropertyInfoWrapper>
        <SectionDescription className="title">{title}</SectionDescription>

        <SectionDescription className="value">{value}</SectionDescription>
      </PropertyInfoWrapper>
    ) : null;
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <PropertyTitle>{address}</PropertyTitle>
        {!!auth && auth?.role === 'tenant' && (
          <FontAwesomeIcon
            icon={'heart'}
            stroke={isBookmark ? 'red' : 'gray'}
            strokeWidth={50}
            color={isBookmark ? 'red' : 'white'}
            style={{ width: 24, height: 24, cursor: 'pointer' }}
            onClick={() => toggleMarked(isBookmark)}
          />
        )}
      </div>

      <PropertyLists>
        {valueToShow &&
          valueToShow.length > 0 &&
          valueToShow.map(({ title, value }, idx) => renderRow(title, value))}
      </PropertyLists>
    </>
  );
};

export default PropertyInfo;
interface PropertyInfoProps {
  area: number;
  bathrooms: number;
  bedrooms: number;
  propertyType: string;
  furnishedType: string;
  rentalMarket: string;
  address: string;
  id: number;
  berNo: string;
}
