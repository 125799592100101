import useAPI from '@/hooks/api';
import FileUploader from '@shared/web/components/FileUploader';
import React from 'react';

const S3FileUploader = ({
  index,
  setFieldValue,
  values,
  item,
  deleteById,
  fieldName,
  photoLoading,
  setPhotoLoading,
}: any) => {
  const [handleS3Images, { loading: loadings3Images }] = useAPI();

  const uploadTo = async (file, presignedUrl) => {
    try {
      const response = await fetch(presignedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
        },
        body: file,
      });
    } catch (err) {
      console.log('Image upload failed:', err);
    }
  };
  const uploadToS3 = async file => {
    try {
      const { url, key } = await handleS3Images({
        method: 'get',
        url: '/v1/common/s3-signed-url',
      });
      await uploadTo(file, url);
      return key;
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <FileUploader
        key={index}
        accept="image/jpeg, image/jpg, image/png"
        onDelete={(id?: number) => {
          setFieldValue(
            fieldName,
            values?.[fieldName]?.filter(
              (_, prevIndex: any) => prevIndex !== index,
            ),
          );
          !!id && deleteById && deleteById(id); // Check if deleteById is defined before calling
        }}
        onFileUpload={files => {
          if (files && files.length > 0) {
            setPhotoLoading(true);
            const uploadNext = (fileIndex, imageUrls) => {
              if (fileIndex >= files.length) {
                setPhotoLoading(false);
                setFieldValue(fieldName, [
                  ...(values?.[fieldName] || []).filter(Boolean),
                  ...imageUrls,
                  null,
                ]);
                return;
              }

              const file = files[fileIndex];

              uploadToS3(file).then(imageUrl => {
                imageUrls.push(imageUrl);
                uploadNext(fileIndex + 1, imageUrls);
              });
            };
            uploadNext(0, []);
          }
        }}
        multiple
        data={item}
        deleteById={deleteById}
        loading={photoLoading}
      />
    </>
  );
};

export default S3FileUploader;
