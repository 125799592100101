const initialState = {
  loggedInUser: null,
  isAppReady: false,
  myCoords: {
    lat: null,
    lng: null,
  },
  propertyimages: [],
  properties: [],
  jobs: [],
  jobsPagination: {
    page: 0,
    limit: 15,
  },
  wishList: [],
  myProperties: [],
  booking: [],
  bookingPagination: [],
  pagination: [],
  activeTrips: [],
  trips: [],
  cards: [],
  favoriteAddresses: [],
  favoriteDrivers: [],
  activeSupports: [],
  supportCategories: [],
  tripMode: '',
  nearByCars: [],
  chats: [],
  conversations: [],
  similarProperties: [],
  authModal: { status: false, type: undefined },
  myBookmarks: { propertyIds: [], serviceIds: [] },
  itineraryDetails: {},
};

export default initialState;
