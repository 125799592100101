/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import { library } from '@fortawesome/fontawesome-svg-core';
import { Store } from '@shared/front';
import { Provider } from 'react-redux';

import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faCalendar,
  faMoneyBill1,
  faThumbsUp,
  far,
} from '@fortawesome/free-regular-svg-icons';
import {
  faAngleDown,
  faAngleUp,
  faArrowLeft,
  faArrowRight,
  faBan,
  faBath,
  faBed,
  faBell,
  faBiking,
  faBook,
  faBoxTissue,
  faCalendarCheck,
  faCarSide,
  faCartShopping,
  faChartArea,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircleChevronLeft,
  faCircleNotch,
  faCog,
  faCommentAlt,
  faCreditCard,
  faDrawPolygon,
  faFileContract,
  faFlag,
  faHandshake,
  faHeart,
  faHomeUser,
  faHouse,
  faHouseUser,
  faImage,
  faInfoCircle,
  faListAlt,
  faMap,
  faMapMarked,
  faMobileAlt,
  faMoneyBill,
  faPaperPlane,
  faPen,
  faPercentage,
  faPlus,
  faQuestionCircle,
  faReply,
  faRocket,
  faSackDollar,
  faScrewdriverWrench,
  faSearch,
  faShop,
  faStar,
  faTachometerAlt,
  faTimesCircle,
  faTrash,
  faUser,
  faUserTie,
  faUsers,
  faUsersCog,
  faWallet,
  fas,
} from '@fortawesome/free-solid-svg-icons';

// import 'antd/dist/antd.min.css';
import 'antd/dist/reset.css';
// Import root app
import { App } from '@/containers/App';
import * as React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// import * as ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import 'react-phone-input-2/lib/style.css';
// Use consistent styling
import * as serviceWorker from '@/serviceWorker';
import { theme } from '@/utils/theme';
import 'sanitize.css/sanitize.css';
import { ThemeProvider } from 'styled-components';

import { loadInital } from '@/utils/apiChangeHandler';
import 'react-toastify/dist/ReactToastify.css';

import RootProvider from '@/RootProvider';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStyle } from './styles/global-styles';
// dotenv.config();
library.add(
  fab,
  far,
  faUsersCog,
  faTachometerAlt,
  faMapMarked,
  faBiking,
  faInfoCircle,
  faCreditCard,
  faUsers,
  faUserTie,
  faHandshake,
  faBell,
  faChartArea,
  faChevronLeft,
  faChevronRight,
  faUser,
  faBell,
  faCircleNotch,
  faPlus,
  faTimesCircle,
  faSearch,
  faPen,
  faBan,
  faTrash,
  faChevronDown,
  faUserTie,
  faFlag,
  faMoneyBill,
  faPercentage,
  faDrawPolygon,
  faQuestionCircle,
  faInfoCircle,
  faCarSide,
  faMap,
  faHeart,
  faBed,
  faBath,
  faCog,
  faCommentAlt,
  faListAlt,
  faStar,
  faHouseUser,
  faTimesCircle,
  fas,
  faPaperPlane,
  faCheck,
  faCircleChevronLeft,
  faCalendar,
  faHomeUser,
  faThumbsUp,
  faBook,
  faRocket,
  faBell,
  faBoxTissue,
  faFileContract,
  faWallet,
  faSackDollar,
  faMoneyBill1,
  faChevronLeft,
  faMobileAlt,
  faImage,
  faCalendarCheck,
  faArrowRight,
  faArrowLeft,
  faCheckCircle,
  faReply,
  faShop,
  faHouse,
  faScrewdriverWrench,
  faCartShopping,
  faAngleUp,
  faAngleDown,
);

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
loadInital();

root.render(
  // <React.StrictMode>
  <Provider store={Store.store}>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <BrowserRouter basename={process.env.BASENAME}>
        <RootProvider>
          <App />
        </RootProvider>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>,
  // </React.StrictMode>,
);

serviceWorker.unregister();
