import NoData from '@/newComponents/NoData';
import Typography from '@/newComponents/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Apis, Store, Utils } from '@shared/front';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
const TabWrapper = styled.div`
  display: flex;
  column-gap: 30px;
  margin-bottom: 20px;
`;
const TabItem = styled.button<any>`
  padding: 15px 0px;
  background: none;
  border: 0px;
  color: ${({ active }) => (active ? '#000' : '#949494')};
`;
const MyTenantItems = styled.div<any>`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
`;
const MyTenantItem = styled.div<any>`
  display: flex;
  column-gap: 20px;
`;

const UserImage = styled.img<any>`
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;
const RightInfoWrapper = styled.div``;
const RowWrapper = styled.div`
  display: flex;
  column-gap: 5px;
  margin-top: 5px;
  align-items: center;
`;
const RowInfo = styled(Typography)`
  font-size: 12px;
  color: #1f1f1f;
`;
const Icon = styled(FontAwesomeIcon)`
  width: 20px;
  color: #ddd;
  font-size: 11px;
`;
const tabs = [
  {
    label: 'Current Tenants',
    key: 'Current',
  },
  {
    label: 'Past Tenants',
    key: 'Closed',
  },
];
const RatingWrapper = styled.div`
  display: flex;
  column-gap: 5px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 60px;
  background-color: white;
  z-index: 99;
  background: #fff;
  & .title {
    font-size: 1.6rem;
    font-weight: 500;
  }
  padding: 20px 0px 10px 0px;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 20px;
`;
const MyTenant = () => {
  const navigate = useNavigate();
  const myTenants: any[] = useSelector(Store.Select.myTenants);
  const [active, setActive] = useState('Current');
  const [loading, setLoading] = useState(false);
  const filterMyTenantsAccordingToActiveTab = myTenants?.filter(item => {
    const currentData = new Date();
    const endDate = new Date(item?.endDate);
    if (active === 'Current' && currentData < endDate) {
      return item;
    } else if (active === 'Closed' && currentData > endDate) {
      return item;
    }
  });

  const getMyTenants = async (tab: string) => {
    setLoading(true);
    try {
      await Utils.request(Apis.Contract.getMyTenants());
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };
  const handleClickTab = async (value: string) => {
    setActive(value);
  };

  useEffect(() => {
    getMyTenants(active);
  }, []);

  return (
    <>
      <TitleContainer>
        <Typography className="title" variant="p">
          My Tenant
        </Typography>
      </TitleContainer>{' '}
      <TabWrapper>
        {tabs?.map((item: any) => (
          <TabItem
            active={item?.key === active}
            key={item?.key}
            onClick={() => {
              handleClickTab(item.key);
            }}
          >
            {item.label}
          </TabItem>
        ))}
      </TabWrapper>
      {!!filterMyTenantsAccordingToActiveTab?.length ? (
        <MyTenantItems>
          {filterMyTenantsAccordingToActiveTab?.map((item: any) => {
            const data = JSON.parse(item?.data);
            const startDate = moment(`${item?.startDate}`).format('DD/MM/yyyy');
            const endDate = moment(`${item?.endDate}`).format('DD/MM/yyyy');
            return (
              <MyTenantItem
                key={item?.id}
                onClick={() => {
                  navigate(`/mytenant/${item?.id}`);
                }}
              >
                <UserImage src={item?.tenant?.avatar} />
                <RightInfoWrapper>
                  <Typography
                    variant="h2"
                    style={{ fontWeight: 600, marginBottom: 10 }}
                  >
                    {item?.tenant?.firstName} {item?.tenant?.lastName}
                  </Typography>
                  <RowWrapper>
                    <Icon icon="home" />
                    <RowInfo variant="p">{item?.property?.address}</RowInfo>
                  </RowWrapper>
                  <RowWrapper>
                    <Icon icon="file" />
                    <RowInfo variant="p">
                      {active === 'Current'
                        ? `${startDate} - ${endDate}`
                        : moment(item?.endDate).format('DD/MM/yyyy')}
                    </RowInfo>
                  </RowWrapper>
                  <RowWrapper>
                    <Icon icon="star" />
                    <RatingWrapper>
                      {Array(parseInt(item?.tenant?.rating))
                        .fill(0)
                        ?.map((i, index) => (
                          <FontAwesomeIcon
                            key={index}
                            icon="star"
                            color={
                              index <= item?.tenant?.rating ? '#2061F8' : '#ddd'
                            }
                            style={{ fontSize: 10 }}
                          />
                        ))}
                    </RatingWrapper>
                  </RowWrapper>
                </RightInfoWrapper>
              </MyTenantItem>
            );
          })}
        </MyTenantItems>
      ) : (
        <>
          <NoData />
        </>
      )}
    </>
  );
};

export default MyTenant;
