import {
  subscribers as allSubscribers,
  getConversationChats,
  getConversations,
  addChat,
  updateChats,
  isAvailable,
  joinRoom,
  leaveRoom,
  getUserNotifications,
  getUnreadNotifications,
  appointmentAcceptReject,
  updateUnReadNotifications,
  getMarketConversations,
  createGroupMessage,
} from "./functions";

import { io, Socket } from "socket.io-client";

import axios from "axios";
import { rootData } from "../utils";

let socket: undefined | Socket;

function connectSocket() {
  const SOCKET_URL = rootData.socketURL;
  try {
    const opts = {
      transports: ["websocket"],
      reconnectionDelay: 5000,
      reconnection: true,
      reconnectionAttempts: Infinity,
      auth: {
        token: axios.defaults.headers.Authorization.split(" ")[1],
      },
      cors: {
        origin: SOCKET_URL,
      },
    };

    socket = io(SOCKET_URL, opts);
    socket.on("connect", function () {
      // socket?.sendBuffer = [];
      console.log("socket connected");
      allSubscribers(socket);
    });
    socket.on("connect_error", function (err) {
      console.log("connect error", err);
    });
  } catch (err) {
    console.log("error", err);
  }

  return socket;
}

function disconnectSocket() {
  socket?.disconnect();
  socket?.close();
  socket = undefined;
}

export default {
  socket,
  connectSocket,
  disconnectSocket,
  getConversationChats: (...args) => getConversationChats(socket, ...args),
  getConversations: (...args) => getConversations(socket, ...args),
  getMarketConversations: (...args) => getMarketConversations(socket, ...args),
  addChat: (...args) => addChat(socket, ...args),
  createGroupMessage: (...args) => createGroupMessage(socket, ...args),
  updateChats: (...args) => updateChats(socket, ...args),
  joinRoom: (...args) => joinRoom(socket, ...args),

  isAvailable: (...args) => isAvailable(socket, ...args),
  leaveRoom: (...args) => leaveRoom(socket, ...args),
  getUserNotifications: (...args) => getUserNotifications(socket, ...args),
  getUserUnreadNotifications: (...args) =>
    getUnreadNotifications(socket, ...args),
  appointmentAcceptReject: (...args) =>
    appointmentAcceptReject(socket, ...args),
  updateUnReadNotifications: (...args) =>
    updateUnReadNotifications(socket, ...args),
};
