import React, { useState } from 'react';
import { Formik, Field } from 'formik';
import InputField from '@shared/web/components/InputField';
import Error from '@shared/web/components/Error';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import AuthWrapper from './AuthWrapper';
import useAPI from '@/hooks/api';
import { omit } from 'lodash';
import {
  ButtonsContainer,
  PageTitle,
  StyledButton,
  StyledForm,
} from './styles';

function useQuery() {
  const query = new URLSearchParams(useLocation().search);

  return {
    email: query.get('email'),
    verificationCode: query.get('verificationCode'),
  };
}
const loginValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Email is not valid')
    .required('Please enter your email'),
  code: yup.string().required('Please enter OTP code'),
  password: yup.string().required('Please enter your password'),
  confirmPassword: yup
    .string()
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value;
    }),
});

const ResetPassword = () => {
  const navigate = useNavigate();

  const [handleSubmitRequest, { loading, error }] = useAPI();
  const [rememberMe] = useState(false);
  const { email, verificationCode }: any = useQuery();
  const handleSubmit = async (values, formikHelpers) => {
    await handleSubmitRequest({
      method: 'post',
      url: '/v1/auth/reset-password',
      data: { ...omit(values, ['confirmPassword']), email: email },
    });
    toast(
      'Your password has been reset. Now you can login using new password.',
    );
    navigate('/login');
  };

  return (
    <AuthWrapper>
      <Formik
        initialValues={{
          email: email,
          code: verificationCode || '',
          password: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={loginValidationSchema}
      >
        {({ handleSubmit, isSubmitting, errors, touched }) => {
          return (
            <StyledForm onSubmit={handleSubmit}>
              <div>
                <PageTitle>Reset Password</PageTitle>
                <Field
                  component={InputField}
                  label="Email"
                  name="email"
                  disabled
                />
                <Field component={InputField} label="OTP code" name="code" />
                <Field
                  component={InputField}
                  name="password"
                  type="password"
                  label="Password"
                />
                <Field
                  component={InputField}
                  name="confirmPassword"
                  type="password"
                  label="Retype password"
                />
                <Error message={error} isFormError />
              </div>
              <ButtonsContainer>
                <StyledButton
                  radius="round"
                  variant="gray"
                  onClick={() => navigate('/login')}
                >
                  Back to Login
                </StyledButton>
                <Link to="/forgotPassword">Resend code</Link>
              </ButtonsContainer>
              <StyledButton
                htmlType="submit"
                disabled={loading || isSubmitting}
                loading={loading || isSubmitting}
              >
                Reset
              </StyledButton>
            </StyledForm>
          );
        }}
      </Formik>
    </AuthWrapper>
  );
};

export default ResetPassword;
