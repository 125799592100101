import React from 'react';
import IndividualUser from '../IndividualUser';
import Modal from '@/newComponents/Modal';
import {
  ButtonsContainer,
  ModalInfoContainer,
  ModalMainText,
  StyledButton,
} from '../../bookingModals/modalStyles';

type Props = {
  tenant: {
    firstName: string;
    lastName: string;
    avatar: string;
    id: number;
    bookingId: number;
    rating: number;
  };
  isOpen: boolean;
  onClose: () => void;
  onRejectBooking: (bookingId: number) => Promise<void>;
  isLoading: boolean;
};

const RejectInvitationModal = ({
  isOpen,
  onClose,
  onRejectBooking,
  tenant,
  isLoading,
}: Props) => {
  const handleCancel = () => {
    onClose();
  };
  const handleOk = async () => {
    await onRejectBooking(tenant.bookingId);
    handleCancel();
  };

  return (
    <Modal open={isOpen} onClose={handleCancel} title="Accept Booking">
      <ModalInfoContainer>
        <ModalMainText variant="h4">
          Are you sure you want to reject booking for selected tenant?
        </ModalMainText>
        <br />
        <br />
        <IndividualUser tenant={tenant} isReadOnly isSmall disableViewBtn />
      </ModalInfoContainer>
      <ButtonsContainer>
        <StyledButton
          variant="gray"
          radius="round"
          isDisabled={isLoading}
          onClick={handleCancel}
        >
          Cancel
        </StyledButton>
        <StyledButton
          variant="danger"
          radius="round"
          isLoading={isLoading}
          onClick={handleOk}
        >
          Reject
        </StyledButton>
      </ButtonsContainer>
    </Modal>
  );
};

export default RejectInvitationModal;
