import { devices } from '@/utils/theme';
import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  /* min-height: calc(100vh - 60px); */
`;

export const Content = styled.div`
  flex: 1;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding: 40px 15px;
  @media screen and (min-width: 576px) {
    padding: 30px;
  }
  @media screen and (min-width: 768px) {
    padding: 30px 45px;
  }
`;

const Logo = styled.img`
  height: 100px;
  display: block;
  margin: 0px auto 20px auto;
`;

const Illustration = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const IllustrationBox = styled.div`
  flex: 1;
  height: calc(100vh - 60px);
  display: none;
  @media ${devices.tablet} {
    display: block;
  }
`;

const InnerContent = styled.div`
  width: 100%;
`;
const AuthWrapper = ({ children, bgImg = '/login-bg.jpg' }) => {
  return (
    <Container>
      <Content>
        {/* <Logo src="/logo.svg" alt="Logo" /> */}
        <InnerContent>{children}</InnerContent>
      </Content>
      <IllustrationBox>
        <Illustration src={bgImg} />
      </IllustrationBox>
    </Container>
  );
};

export default AuthWrapper;
