import { devices } from '../../utils/theme';
import React, { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

type TypographySize = 'xsm' | 'sm' | 'md' | 'lg';
type TypographyFontWeight = 'thin' | 'normal' | 'bold' | 'extrabold';

interface ITypographyComponent {
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
  style?: CSSProperties;
  className?: string;
  children: ReactNode;
}

interface ITypography {
  size?: TypographySize;
  fontFamily?: string;
  fontWeight?: TypographyFontWeight;
  color?: string;
}

const getFontSizes = (size: TypographySize) => {
  switch (size) {
    case 'lg':
      return ['18', '20', '24', '32'];
    case 'md':
      return ['16', '18', '20', '24'];
    case 'sm':
      return ['10', '12', '14', '16'];
    case 'xsm':
      return ['10', '10', '10', '10'];
  }
};

const Tag = styled.div<ITypography>`
  color: ${({ color }) => color};
  font-size: ${({ size }) => `${getFontSizes(size)[0]}px`};
  font-weight: ${({ fontWeight }) => fontWeight};
  font-family: ${({ fontFamily }) => fontFamily};
  margin: 0;
  padding: 0;

  @media ${devices.mobileXS} {
    font-size: ${({ size }) => `${getFontSizes(size)[1]}px`};
  }

  @media ${devices.tablet} {
    font-size: ${({ size }) => `${getFontSizes(size)[2]}px`};
  }

  @media ${devices.laptopXL} {
    font-size: ${({ size }) => `${getFontSizes(size)[3]}px`};
  }
`;

const Typography = ({
  variant = 'p',
  size = 'sm',
  fontFamily = 'Poppins',
  fontWeight = 'normal',
  color = '#333',
  style,
  className,
  children,
  ...rest
}: ITypographyComponent & ITypography) => {
  return (
    <Tag
      as={variant}
      size={size}
      fontFamily={fontFamily}
      fontWeight={fontWeight}
      color={color}
      className={className}
      style={{ ...style }}
      {...rest}
    >
      {children}
    </Tag>
  );
};

export default Typography;
