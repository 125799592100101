import { Config, ReturnType } from "./types";
import callbackHandler from "./callbackHandlers";
export default {
  login(data: { email: string; password: string }): ReturnType {
    return {
      url: "/v1/auth/login",
      method: "post",
      data,
      config: {
        showErr: true,
        ...callbackHandler.login(data),
      },
    };
  },
  getMe(): ReturnType {
    return {
      method: "post",
      url: "/v1/auth/verify-token",
      config: {
        store: {
          action: "set",
          key: "loggedInUser",
        },
        ...callbackHandler.getMe(),
      },
    };
  },
  verifyMeStripe(): ReturnType {
    return {
      method: "post",
      url: "/v1/stripe/create-verification-session",
    };
  },
  checkPayoutBalance(): ReturnType {
    return {
      method: "get",
      url: "/v1/stripe/check-balance",
      config: {
        showErr: true,
      },
    };
  },
  payoutToBank(): ReturnType {
    return {
      method: "post",
      url: "/v1/stripe/payout",
      config: {
        showErr: true,
      },
    };
  },
  verifyMyBank(): ReturnType {
    return {
      method: "post",
      url: "/v1/stripe/create-stripe-account",
      config: {
        showErr: true,
      },
    };
  },
  canDeleteAccount(id?: number): ReturnType {
    return {
      method: "get",
      url: `/v1/auth/can-delete-account/${id}`,
    };
  },
  deleteAccount(id?: number): ReturnType {
    return {
      method: "delete",
      url: `/v1/auth/${id}`,
    };
  },
  register(data: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
  }): ReturnType {
    return {
      method: "post",
      url: "/v1/auth/register",
      data,
      config: {
        navigate: ["VerifyAccount", { email: data.email }],
        showErr: true,
        successMsg: "You have been successfully registered.",
      },
    };
  },

  verifyAccount(data: { code: string; email: string }): ReturnType {
    return {
      method: "post",
      url: "/v1/auth/verify",
      data,
      config: {
        showErr: true,
        successMsg: "Your account has been successfully verified",
        navigate: ["SignIn"],
      },
    };
  },

  addPhone(data: { countryCode: string; phone: string }): ReturnType {
    return {
      method: "post",
      url: "/v1/auth/add-phone",
      data,
    };
  },

  verifyPhone(data: { code: string }): ReturnType {
    return {
      method: "post",
      url: "/v1/auth/verify-phone",
      data,
    };
  },

  forgotPassword(data: { email: string }): ReturnType {
    return {
      method: "post",
      url: "/v1/auth/forgot-password",
      data,
      config: {
        navigate: ["ResetPassword", { email: data.email }],
      },
    };
  },

  logout(): ReturnType {
    return {
      method: "post",
      url: "/v1/auth/logout",
    };
  },

  changePassword(data: {
    email: string;
    password: string;
    code: string;
  }): ReturnType {
    return {
      method: "post",
      url: "/v1/auth/reset-password",
      data,
      config: {
        navigate: ["SignIn"],
        successMsg: "Password reset successfully.",
      },
    };
  },
  updateProfile(values: any): ReturnType {
    return {
      method: "put",
      url: "/v1/auth/profile",
      data: values,
      config: {
        showErr: true,
      },
    };
  },

  updateProfilePicture(data?: any): ReturnType {
    return {
      method: "put",
      url: "/v1/auth/documents",
      data: data,
      config: {
        successMsg: "Profile Picture updated successfully",
      },
    };
  },
  getProfile(
    id?: number,
    role?: "landlord" | "service" | "tenant"
  ): ReturnType {
    return {
      method: "get",
      url: `/v1/auth/${
        role === "landlord"
          ? "landlords"
          : role === "tenant"
          ? "tenants"
          : "service-providers"
      }/${id}`,
    };
  },

  uploadCertification(data?: any): ReturnType {
    return {
      method: "put",
      url: `/v1/auth/certification`,
      data: data,
      config: {
        successMsg: "Certification Uploaded successfully",
      },
    };
  },
  getTenantsFromMap(data?: any): ReturnType {
    return {
      method: "post",
      url: `/v1/auth/tenants/map`,
      data,
    };
  },

  getCertification(): ReturnType {
    return {
      method: "get",
      url: `/v1/services/certifications`,
    };
  },
  deleteCertification(id?: number): ReturnType {
    return {
      method: "delete",
      url: `/v1/auth/certifications/${id}`,
    };
  },

  searchProfile(params: any, action: "set" | "update"): ReturnType {
    return {
      method: "get",
      url: `/v1/auth/search-users`,
      params,
      config: {
        store: {
          key: "searchProfiles",
          action: action || "set",
        },
      },
    };
  },

  getUserDetailsById(id: number): ReturnType {
    return {
      method: "get",
      url: `/v1/auth/user-details/${id}`,
    };
  },

  getOwnUserDesires(): ReturnType {
    return {
      method: "get",
      url: `/v1/auth/my-desires`,
      config: {
        store: {
          action: "set",
          key: "desired",
        },
      },
    };
  },
  addOwnUserDesires(data: any): ReturnType {
    return {
      method: "post",
      url: `/v1/auth/desires`,
      data,
      config: {
        successMsg: "User Desired created successfully",
      },
    };
  },
  updateOwnDesires(data: any, id: number): ReturnType {
    return {
      method: "patch",
      url: `/v1/auth/my-desires/${id}`,
      data,
      config: {
        successMsg: "User desired updated successfully",
      },
    };
  },
  deleteOwnDesires(id: number): ReturnType {
    return {
      method: "delete",
      url: `/v1/auth/my-desires/${id}`,
      config: {
        successMsg: "User Desired deleted successfully",
      },
    };
  },
  deleteAccountRequest(data: { password: string }): ReturnType {
    return {
      method: "put",
      url: `/v1/auth/delete-request`,
      data,
    };
  },
  activateAccount(): ReturnType {
    return {
      method: "put",
      url: `/v1/auth/activate`,
    };
  },

  sendInvitation(data: any): ReturnType {
    return {
      method: "post",
      url: `/v1/common/invite`,
      data,
      config: {
        successMsg: "Invitation send successfully",
      },
    };
  },
};
