import EmptySvg from '@/assets/empty.svg';
import Typography from '@/newComponents/Typography';
import React from 'react';
import styled from 'styled-components';

const EmptyImage = styled.img<{ size: string }>`
  width: ${({ size }) => size}px;
  height: auto;
`;
const EmptyPageWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const EmptyPage = ({ type }: any) => {
  return (
    <EmptyPageWrapper>
      <EmptyImage src={EmptySvg} alt="Empty" size={'275'} />

      {type === 'message' ? (
        <Typography>No Message Yet</Typography>
      ) : (
        <Typography>No Data Found</Typography>
      )}
    </EmptyPageWrapper>
  );
};

export default EmptyPage;
