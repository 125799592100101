import useAPI from '@/hooks/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingPlaceholder from '@shared/web/components/LoadingPlaceholder';
import RentalHistory from '@shared/web/components/RentalHistory';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import NoData from '@/newComponents/NoData';
import {
  ButtonCardStyle,
  Container,
  Content,
  ProfileHeadContainer,
  SectionTitle,
  StyledButton,
} from './styles';
import Avatar from '@/newComponents/Avatar';
import { useResponsive } from '@/hooks/useResponsive';

const ButtonCard = () => {
  const buttons = [
    {
      title: 'Account',
      iconTypes: 'far ',
      iconSymbol: 'money-bill-1',
    },
    {
      title: 'References',
      iconTypes: 'far',
      iconSymbol: 'calendar',
    },
    {
      title: 'Rate Tenant',
      iconTypes: 'fas',
      iconSymbol: 'star',
    },
    {
      title: 'View Contract',
      iconTypes: 'far',
      iconSymbol: 'file',
    },
  ];
  return (
    <ButtonCardStyle>
      {buttons.map(item => {
        return (
          <StyledButton key={item.title} onClick={() => {}}>
            <FontAwesomeIcon
              icon={[`${item.iconTypes}`, `${item.iconSymbol}`]}
              style={{ marginRight: '10px' }}
            />
            {item.title}
          </StyledButton>
        );
      })}
    </ButtonCardStyle>
  );
};

const MyTenantView = () => {
  const [
    makeMySingleTenantRequest,
    { data: singleTenant, loading: myTenantLoading },
  ] = useAPI();
  const { id } = useParams();
  const { isLargeScreen } = useResponsive();
  const handleRequest = async () => {
    await makeMySingleTenantRequest({
      method: 'get',
      url: `/v1/contractGenerate/tenants/${id}/profile`,
    });
  };

  console.log('the data is====', singleTenant);
  useEffect(() => {
    handleRequest();
  }, [id]);

  const tenant = singleTenant?.data.tenant;
  return (
    <Container>
      {myTenantLoading && <LoadingPlaceholder />}
      {!myTenantLoading && !singleTenant && <NoData title="No Tenants" />}
      {!myTenantLoading && singleTenant && (
        <>
          <Content>
            <ProfileHeadContainer>
              <Avatar
                avatar={
                  !!tenant?.avatar
                    ? tenant?.avatar
                    : 'https://img.freepik.com/free-icon/user_318-159711.jpg'
                }
                size={isLargeScreen ? 'md' : 'lg'}
              />
              <SectionTitle variant="h1">{`${tenant.firstName} ${tenant.lastName}`}</SectionTitle>
            </ProfileHeadContainer>
            <ButtonCard />
          </Content>
          <RentalHistory tenantData={singleTenant?.pastContract} />
        </>
      )}
    </Container>
  );
};

export default MyTenantView;
