import { Field, Formik, Form } from 'formik';
import * as yup from 'yup';
import React, { useState } from 'react';
import Modal from '@/newComponents/Modal';
import { Apis, Hooks } from '@shared/front';
import DatePickerField from '@shared/web/components/DatePicker';
import S3presigned from '@shared/web/components/contractS3PresignedUrl';
import {
  ButtonsContainer,
  Container,
  ModalInfoContainer,
  StyledButton,
  StyledLabel,
} from './modalStyles';
import Checkbox from '@/newComponents/Checkbox';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Typography from '@/newComponents/Typography';
import DatePickerCustomized from '@/newComponents/DatePicker';

const ErrorMessage = styled(Typography)`
  color: rgb(240, 20, 0);
  font-size: 12px;
`;

type Props = {
  isVisible: boolean | undefined;
  onCancel: Function;
  booking: any;
  bookingId: string | undefined;
  getBooking: any;
};

const UpdateManualContractModal = ({
  booking,
  isVisible,
  onCancel,
  bookingId,
  getBooking,
}: Props) => {
  const validatedUpdateSchema = yup.object().shape({
    contract: yup.mixed().required('Please upload a file'),
    tenantSign: yup
      .boolean()
      .oneOf([true], 'Please check the tenant sign checkbox'),
    landLordSign: yup
      .boolean()
      .oneOf([true], 'Please check the landlord sign checkbox'),
    landlordSignDate: yup
      .date()
      .required('Please enter a date for the landlord sign'),
    tenantSignDate: yup
      .date()
      .required('Please enter a date for the tenant sign'),
  });

  const [makeRequest, { loading: loadingManualContract }] = Hooks.useAPI();

  const [updateManualContract, setUpdateManualContract] = useState<{
    contract: string;
    tenantSign: Boolean;
    landLordSign: Boolean;
  }>({
    contract: '',
    tenantSign: false,
    landLordSign: false,
  });

  const isCreated = !!(
    !booking?.contract?.isSystemGenerate &&
    booking?.contract?.contractGenerateURL
  );

  const handleCancel = () => {
    onCancel && onCancel();
  };

  const handleManualContractUpdate = async values => {
    try {
      const finalData = {
        contractURL: values.contract,
        tenantSignDate: values.tenantSignDate,
        landlordSignDate: values.landlordSignDate,
        uploadUpdateContractDate: new Date(),
      };

      await makeRequest(
        Apis.Contract.updateManualContract(finalData, booking?.contract?.id),
      );
      await getBooking();
    } catch (error) {
    } finally {
      handleCancel();
    }
  };

  return (
    <Modal
      title="Update Manual Contract"
      open={isVisible}
      onClose={handleCancel}
    >
      <Formik
        initialValues={updateManualContract}
        validationSchema={validatedUpdateSchema}
        enableReinitialize
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={(values, errors) => {
          handleManualContractUpdate(values);
        }}
      >
        {({ values, errors, touched, setFieldValue }) => {
          return (
            <Form>
              <Container>
                <div>
                  <Typography>Contract</Typography>
                  <div
                    style={{
                      borderRadius: '5px',
                      border: '1px solid #ddd',
                      height: '100px',
                      width: '150px',
                      position: 'relative',
                      backgroundColor: '#c2daf1',
                    }}
                  >
                    <a
                      href={booking?.contract?.contractGenerateURL}
                      target="_blank"
                      rel="noopener noreferrer"
                      //@ts-ignore
                      without
                    >
                      <div
                        style={{
                          position: 'absolute',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '100%',
                          height: '100%',
                        }}
                      >
                        <FontAwesomeIcon icon="file-pdf" size="4x" />
                      </div>
                    </a>
                  </div>
                </div>
                <div>
                  <StyledLabel>Upload signed contract</StyledLabel>
                  <Field
                    name="contract"
                    component={S3presigned}
                    accept=".pdf"
                    fileType="file"
                    isMultiple={false}
                  />
                </div>
                <Field
                  id="tenantSign"
                  component={Checkbox}
                  label="Tenant Sign"
                  name="tenantSign"
                  onChange={e => setFieldValue('tenantSign', e.target.checked)}
                />
                {touched.tenantSign && errors.tenantSign && (
                  <ErrorMessage>{errors?.tenantSign || ''}</ErrorMessage>
                )}{' '}
                <Field
                  id="landLordSign"
                  name="landLordSign"
                  component={Checkbox}
                  label="Landlord Sign"
                  onChange={e => {
                    setFieldValue('landLordSign', e.target.checked);
                  }}
                />
                {touched.landLordSign && errors.landLordSign && (
                  <ErrorMessage>{errors?.landLordSign || ''}</ErrorMessage>
                )}
                {/* </div> */}
                <div>
                  <StyledLabel>Landlord Signed On</StyledLabel>
                  <Field
                    type="date"
                    name="landlordSignDate"
                    component={DatePickerCustomized}
                  />
                </div>
                <div>
                  <StyledLabel>Tenant Signed On</StyledLabel>
                  <Field
                    type="date"
                    name="tenantSignDate"
                    component={DatePickerCustomized}
                  />
                </div>
                <ButtonsContainer>
                  <StyledButton
                    variant="gray"
                    radius="round"
                    onClick={handleCancel}
                  >
                    Cancel
                  </StyledButton>
                  <StyledButton
                    htmlType="submit"
                    radius="round"
                    isLoading={loadingManualContract}
                    isDisabled={loadingManualContract}
                  >
                    Update Contract
                  </StyledButton>
                </ButtonsContainer>
              </Container>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default UpdateManualContractModal;
