import React, {
  ComponentProps,
  ComponentPropsWithRef,
  CSSProperties,
  HTMLAttributes,
  MouseEventHandler,
  ReactNode,
} from "react";
import styled from "styled-components";

import { generalColors, ThemeType } from "../../front/theme";

type StyledButtonType = {
  backgroundColor: keyof typeof generalColors;
  border: CSSProperties["border"];
  spacing: keyof ThemeType["spacing"];
  textColor: keyof typeof generalColors;
  size?: "small" | "middle" | "large";
};

interface AppButtonType extends ComponentProps<"button"> {
  title?: string;
  backgroundColor?: keyof typeof generalColors;
  border?: CSSProperties["border"];
  spacing?: keyof ThemeType["spacing"];
  block?: boolean;
  textColor?: keyof typeof generalColors;
  size?: "small" | "middle" | "large";
  children: ReactNode;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

const StyledButton = styled.button<StyledButtonType>`
  &,
  :active,
  :focus {
    ${({ backgroundColor, textColor, theme }) =>
      backgroundColor &&
      `
    background-color: ${theme.colors.generalColors[backgroundColor]}!important;
    color: ${theme.colors.generalColors[textColor]} !important;
    `}

    border-radius: 10px;
    border: 1px solid #ddd;
    outline: none;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    margin: 15px 0;
    cursor: pointer;
  }
`;

const AppButton = ({
  border = "xxs",
  spacing = "sm",
  block = false,
  textColor = "white",
  size = "middle",
  children,
  ...props
}: AppButtonType) => {
  return (
    //@ts-ignore
    <StyledButton
      border={border}
      spacing={spacing}
      textColor={textColor}
      size={size}
      {...props}
    >
      {children}
    </StyledButton>
  );
};

export default AppButton;
