import Button from '@/newComponents/Button';
import React from 'react';
import styled from 'styled-components';
import { InitialValueTypes } from './FilterModel';
import FilterTag from '@/newComponents/FilterTags';

type Props = {
  filterInfo: [string, string | number][];
  onReset: () => void;
  onRemoveFilter: (key: string) => void;
};

const FilterInfo = ({ filterInfo, onReset, onRemoveFilter }: Props) => {
  return (
    <FilterInfoContainer>
      {filterInfo.length > 0 && (
        <ClearAllButton
          variant="gray"
          radius="round"
          onClick={() => {
            onReset();
          }}
        >
          Clear All
        </ClearAllButton>
      )}

      {filterInfo.length > 0 &&
        filterInfo?.map(item => {
          const key = item[0];
          const value = item[1];
          const getSortLabel = (value: string) => {
            if (value === 'price_desc') {
              return 'High to Low';
            } else if (value === 'price_asc') {
              return 'Low to High';
            } else {
              return value;
            }
          };
          return (
            <FilterTag
              filterType={key}
              filterSubType={getSortLabel(value as string)}
              onClose={() => {
                onRemoveFilter(key);
              }}
            />
          );
        })}
    </FilterInfoContainer>
  );
};

export default FilterInfo;

const FilterInfoContainer = styled.div`
  display: flex;
  align-items: center;

  gap: 8px;
  flex-wrap: wrap;
  margin: 8px 0px;
`;

const ClearAllButton = styled(Button)`
  color: #949494;
  height: auto;
  font-size: 12px;
  line-height: 25px;
  padding: 2px 12px;
  background-color: #ebebeb;
`;
