import { useRoot } from '@/RootProvider';
import Button from '@/newComponents/Button';
import PropertyCard from '@/newComponents/PropertyCard';
import Typography from '@/newComponents/Typography';
import { Apis, Store, Utils } from '@shared/front';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { HomeStyled } from './style';
import LandingSkeleton from './Skeleton';

const Home = () => {
  const [location, setLocation] = useState({ lat: 0, long: 0 });
  const properties = useSelector<any>(Store.Select.properties);
  const pagination = useSelector<any>(Store.Select.propertyPagination);
  const overAllPagination = useSelector<any>(Store.Select.pagination);
  const [loading, setLoading] = useState(false);
  const { auth } = useRoot();

  const [loadingMore, setLoadingMore] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(pos => {
      let lat = pos.coords.latitude;
      let long = pos.coords.longitude;
      if (lat && long) {
        setLocation({ lat: lat, long: long });
      }
    });
  }, []);
  useEffect(() => {
    if (auth && auth.token) {
      navigate('/dashboard');
    }
  }, [auth]);

  const handlePropertyRequest = async tempParams => {
    // setLoadingMore(true);
    setLoading(true);
    const params = {
      page: tempParams?.page,
      limit: tempParams?.limit,
    };

    if (tempParams?.search) {
      params.address = tempParams?.search;
    }

    console.log({ params });

    await Utils.request(
      Apis?.Properties?.getProperties({
        params,
        action: tempParams.page > 0 ? 'update' : 'set',
      }),
    );
    // setLoadingMore(false);
    setLoading(false);
  };

  useEffect(() => {
    return () => {
      // dispatch(Store.Actions['set']('properties', []));
      dispatch(
        Store.Actions['set']('propertyPagination', {
          ...pagination,
          page: 0,
        }),
      );
    };
  }, []);

  useEffect(() => {
    handlePropertyRequest(pagination);
  }, [pagination]);

  const handleLoadMore = () => {
    if (properties?.length < overAllPagination.count) {
      dispatch(
        Store.Actions['update']('propertyPagination', {
          ...pagination,
          page: pagination.page + 1,
        }),
      );
    }
  };

  return (
    <HomeStyled>
      <div className="header">
        <Typography variant="h2" size="md" color="#1F1F1F">
          Explore Ireland
        </Typography>
        <Typography variant="h5" size="sm" color="#949494">
          Discover the most sought-after properties in the heart of Irealand
        </Typography>
      </div>
      {loading && pagination?.page === 0 && <LandingSkeleton />}
      <div className="propertyContainer">
        {properties?.map((property: any) => {
          return (
            <div
              key={property?.id}
              onClick={() => {
                console.log('test');
                navigate(`properties/${property?.id}`);
              }}
              className="property"
            >
              <PropertyCard
                address={property?.address}
                image={property?.images[0]?.URL}
                price={property?.price}
              />
            </div>
          );
        })}
      </div>
      {loading && pagination?.page > 0 && <LandingSkeleton />}

      {properties?.length >= overAllPagination.count ? null : (
        <Button
          variant="primary"
          size="sm"
          radius="round"
          isLoading={loadingMore}
          className="exploreBtn"
          onClick={handleLoadMore}
          isDisabled={loadingMore}
        >
          Continue Exploring
        </Button>
      )}

      {/* <Footer /> */}
    </HomeStyled>
  );
};

export default Home;
