import Button from '@/newComponents/Button';
import Error from '@shared/web/components/Error';
import InputField from '@shared/web/components/InputField';
import TextField from '@shared/web/components/TextField';
import { Form, Field, Formik } from 'formik';
import useAPI from '@/hooks/api';
import { pick } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import validation from './validation';
import request from '@shared/front/utils/request';
import { capitalize } from 'lodash';
import { useRoot } from '@/RootProvider';
import styled from 'styled-components';
import SelectField from '@shared/web/components/ReactSearchField';
import { devices } from '@/utils/theme';
import { Constants } from '@shared/common';
import Typography from '@/newComponents/Typography';
const PropertyView = styled.div`
  margin-bottom: 2px;
  flex-direction: row;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0px 4px;
`;
const PropertyImage = styled.img`
  height: 40px;
  width: 60px;
  object-fit: cover;
  border-radius: 2px;
  margin-right: 10px;
`;
const StyledLabel = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 8px;
`;
const StyledOption = styled.option`
  text-align: center;
`;
const FormikSelectField = styled(Field)`
  display: block;
  width: 100%;
  height: 40px;
  margin: 6px 0px;
  outline: none;
  border: 1px solid ${({ theme }) => theme.colors.border};
  background-color: ${({ theme }) => theme.colors.lightBG};
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }

  &.disabled-hidden-option {
    color: transparent;
    filter: blur(2px);
    border: 1px solid red;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
`;

const StyledButton = styled(Button)`
  font-size: 12px;
  line-height: 25px;
`;
const Title = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  padding-bottom: 6px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;

  @media ${devices.mobileXS} {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 12px;
    margin-bottom: 18px;
    line-height: normal;
  }

  @media ${devices.laptop} {
    font-size: 18px;
    margin-bottom: 30px;
    padding-bottom: 18px;
  }
`;

const FieldStyleFix = styled.div`
  .react-select__control {
    height: 60px !important;
  }

  .react-select__single-value,
  input {
    height: 40px !important;
  }
`;

const BasicInfo = ({
  initialValues,
  onSubmitStep,
}: {
  initialValues: any;
  onSubmitStep: any;
}) => {
  const { auth: user } = useRoot();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [services, setProperties] = useState([]);
  const [targetButton, setTargetButton] = useState<string>('');
  const [handleSubmitRequest, { loading, error }] = useAPI();
  const handleSubmitForm = async (values: any, isDraft?: boolean) => {
    const url = '/v1/services';
    const parsedValues = pick(values, [
      'title',
      'desc',
      'propertyId',
      'isCallout',
    ]);
    const data = await handleSubmitRequest({
      method: 'post',
      url: initialValues.id ? `${url}/${initialValues.id}` : url,
      data: parsedValues,
    });

    onSubmitStep({ data, isDraft });
  };

  const getProperties = async () => {
    const res: any =
      user && user?.role === 'landlord'
        ? await request({
            method: 'get',
            url: '/v1/properties',
            params: {
              limit: 25,
              filter: 'active',
            },
          })
        : await request({
            method: 'get',
            url: '/v1/bookings',
            params: {
              limit: 25,
              status: 'Completed',
            },
          });
    setProperties(
      user && user?.role === 'landlord'
        ? res.data.map((item: any) => ({
            label: (
              <PropertyView>
                <PropertyImage src={item.images[0]?.URL} />
                <Typography variant="h1" style={{ marginBottom: 0 }}>
                  {item.address}
                </Typography>
              </PropertyView>
            ),
            value: item.id,
          }))
        : res.data.map((item: any) => ({
            label: (
              <PropertyView>
                <PropertyImage src={item?.property?.images[0]?.URL} />
                <Typography variant="h1" style={{ marginBottom: 0 }}>
                  {item?.property?.address}
                </Typography>
              </PropertyView>
            ),
            value: item?.property?.id,
          })),
    );
  };

  useEffect(() => {
    getProperties();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => {
        handleSubmitForm(values);
      }}
      validationSchema={validation.basicInfo}
    >
      {({
        isSubmitting,
        errors,
        touched,
        values,
        setFieldValue,
        handleSubmit,
      }) => {
        return (
          <>
            <Form onSubmit={handleSubmit}>
              <Title variant="h1">Basic Information</Title>
              <div>
                <StyledLabel>Title</StyledLabel>
                <Field
                  component={InputField}
                  name="title"
                  type="text"
                  value={values.title || ''}
                />
              </div>
              <FieldStyleFix>
                {state?.type === 'tenantCreateJob' ? (
                  <>
                    <Typography
                      name={'Property'}
                      size="md"
                      style={{ marginBottom: 5 }}
                    />
                    <Typography
                      name={state?.propertyAddress}
                      size="md"
                      color="primary"
                      style={{ marginBottom: 5 }}
                    />
                  </>
                ) : (
                  <>
                    <StyledLabel>Property</StyledLabel>
                    <Field
                      component={SelectField}
                      name="propertyId"
                      options={services}
                      setFieldValue={setFieldValue}
                      isSearchable={false}
                    />
                  </>
                )}
              </FieldStyleFix>
              <div>
                <StyledLabel>Request For Callout </StyledLabel>
                <FormikSelectField component="select" name="isCallout">
                  <StyledOption value="" disabled selected>
                    Select
                  </StyledOption>
                  {Constants.callOutDropdown()?.map(item => {
                    return (
                      <StyledOption label={item.label} value={item.value}>
                        {item.value}
                      </StyledOption>
                    );
                  })}
                </FormikSelectField>
              </div>

              <div>
                <StyledLabel>Description</StyledLabel>
                <Field component={TextField} name="desc" type="text" />
              </div>

              <Error message={error} isFormError />
              <ButtonsContainer>
                <StyledButton
                  type="button"
                  variant="gray"
                  radius="round"
                  onClick={() => navigate('/jobs')}
                >
                  Cancel
                </StyledButton>

                <div style={{ display: 'flex', gap: '20px' }}>
                  <StyledButton
                    radius="round"
                    variant="gray"
                    type="button"
                    isDisabled={targetButton === 'draft' ? loading : false}
                    isLoading={targetButton === 'draft' ? loading : false}
                    onClick={() => {
                      setTargetButton('draft');
                      handleSubmitForm(values, true);
                    }}
                  >
                    Save as draft
                  </StyledButton>
                  <StyledButton
                    radius="round"
                    type="submit"
                    disabled={targetButton === 'save' ? loading : false}
                    loading={targetButton === 'save' ? loading : false}
                    onClick={() => {
                      setTargetButton('save');
                    }}
                  >
                    Save
                  </StyledButton>
                </div>
              </ButtonsContainer>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default BasicInfo;
