import Button from '@/newComponents/Button';
import Map from '@/newComponents/Map';
import Typography from '@/newComponents/Typography';
import request from '@shared/front/utils/request';
import { devices } from '@/utils/theme';
import Error from '@shared/web/components/Error';
import { Form, Formik, FormikProps } from 'formik';
import { debounce } from 'lodash';
import React, { useRef, useState } from 'react';
import Select from 'react-select/async';
import styled from 'styled-components';
import * as validation from './validation';
import { ButtonEndWrapperStyled } from '@/styles/shareableStyle';
const DUBLIN = {
  lat: 53.34304171021489,
  lng: -6.264192673164263,
  address: 'DUBLIN',
};

// const ButtonsContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   margin-top: 40px;
// `;

const StyledButton = styled(Button)`
  font-size: 12px;
  line-height: 25px;
`;

const Title = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  padding-bottom: 6px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;

  @media ${devices.mobileXS} {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 12px;
    margin-bottom: 18px;
    line-height: normal;
  }

  @media ${devices.laptop} {
    font-size: 18px;
    margin-bottom: 30px;
    padding-bottom: 18px;
  }
`;

const Location = ({ google, desiredValues, onSubmitStep }: any) => {
  const formikRef = useRef<FormikProps<any>>(null);
  const [selectedCoordinates, setSelectedCoordinates] = useState<any>({
    latitude: desiredValues?.latitude || DUBLIN.lat,
    longitude: desiredValues?.longitude || DUBLIN.lng,
  });
  const [places, setPlaces] = useState([
    {
      label: 'Select',
      value: 'Select',
    },
  ]);

  const getLatLongFromPlaceId = async (placeId: string) => {
    try {
      const res = await request({
        method: 'get',
        url: '/v1/common/google-geocode',
        params: {
          placeId,
        },
      });
      // console.log(res, 'responseMarketPalace');
      const { lat, lng } = res.geometry.location;
      formikRef.current?.setFieldValue('address', res.formatted_address);
      formikRef.current?.setFieldValue('latitude', lat);
      formikRef.current?.setFieldValue('longitude', lng);
      setSelectedCoordinates({ latitude: lat, longitude: lng });
      return false;
    } catch (err) {
      console.log('Error fetching lat/long', err);
    }
  };

  const fetchPlaces = async (query: string) => {
    if (!query.trim()) {
      return [];
    }
    try {
      const res = await request({
        method: 'get',
        url: '/v1/common/google-places',
        params: {
          input: query.trim(),
        },
      });

      return res.map((place: any) => ({
        label: place.description,
        value: place.place_id,
      }));
    } catch (err) {
      return [];
    }
  };

  const handleSubmitForm = async (values: any) => {
    onSubmitStep({ data: values, dataFrom: 'location' });
  };

  const debounceSearch = React.useRef(debounce(fetchPlaces, 400)).current;

  const handleSelectChange = (selectedOption: string) => {
    if (selectedOption) {
      getLatLongFromPlaceId(selectedOption?.value);
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={desiredValues}
      onSubmit={values => {
        handleSubmitForm(values);
      }}
      validationSchema={validation.location}
      enableReinitialize
    >
      {({ errors, values, setFieldValue, handleSubmit }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <div style={{ marginBottom: '20px' }}>
              <Title variant="h1">Location123</Title>
              <Select
                placeholder={values?.address || 'Search address'}
                options={places}
                isClearable
                loadOptions={debounceSearch}
                onChange={e => {
                  handleSelectChange(e);
                }}
                onInputChange={query => debounceSearch(query)}
              />
            </div>
            <Map
              coordinates={[selectedCoordinates]}
              readOnly
              style={{ height: '50vh' }}
            />

            <Error message={errors?.address} isFormError />
            <ButtonEndWrapperStyled>
              <StyledButton
                type="submit"
                radius="round"
                block={true}
                variant="primary"
                style={{ marginTop: '30px' }}
              >
                Next
              </StyledButton>
            </ButtonEndWrapperStyled>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Location;
