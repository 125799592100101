import React from 'react';
import ListLoading from '../ListLoading';
import IndividualUser from '../IndividualUser';
import Typography from '@/newComponents/Typography';
import { TenantsListContainer } from '../styles';

const ViewingList = ({
  loading,
  disableActions,
  bookingRequested,
  selectedShortList,
  handleSelectTenantForShortList,
  setEnableAcceptBookingModal,
  setSelectedTenant,
  setEnableRejectBookingModal,
}) => {
  return (
    <div>
      {loading ? (
        <ListLoading number={4} />
      ) : (
        <>
          {bookingRequested &&
          bookingRequested?.tenants &&
          bookingRequested?.tenants.length > 0 ? (
            <TenantsListContainer>
              {bookingRequested?.tenants.map(tenant => (
                <IndividualUser
                  tenant={tenant}
                  isChecked={selectedShortList.includes(tenant.bookingId)}
                  onSelect={handleSelectTenantForShortList}
                  isReadOnly
                  button={
                    !disableActions
                      ? [
                          {
                            action: () => {
                              setEnableAcceptBookingModal(true);
                              setSelectedTenant(tenant);
                            },
                            label: 'Accept',
                          },
                          {
                            action: () => {
                              setEnableRejectBookingModal(true);
                              setSelectedTenant(tenant);
                            },
                            isDanger: true,
                            label: 'Reject',
                          },
                        ]
                      : null
                  }
                />
              ))}
            </TenantsListContainer>
          ) : (
            <Typography variant="p" size="sm" style={{ textAlign: 'center' }}>
              No request!
            </Typography>
          )}
        </>
      )}
    </div>
  );
};

export default ViewingList;
