import React from "react";
import styled from "styled-components";
import { devices } from "../../../../../packages/web/src/utils/theme";

const Content = styled.div`
  padding: 10px 0px;
  width: 100%;
  @media ${devices.mobileSM} {
    padding: 10px 20px;
  }
`;

const NotificationWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  width: 75px;
  justify-content: space-between;
  align-items: center;
`;

const NotificationBox = styled.div`
  position: relative;
`;

const Badge = styled.div`
  height: 20px;
  width: 20px;
  background-color: red;
  color: white;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  left: 9px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 13px;
`;
const BadgeCount = styled.div`
  color: ${({ theme }) => theme.colors.white};
`;

type Props = {
  children: React.ReactNode;
  onClick?: () => void;
};

const ListDescription = ({ children, onClick }: Props) => {
  return <Content onClick={onClick}>{children}</Content>;
};

export default ListDescription;
