import { Apis, Hooks, Store, Utils } from '@shared/front';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NoImage from '../../../assets/noimage.png';

import { useRoot } from '@/RootProvider';
import Button from '@/newComponents/Button';
import NoData from '@/newComponents/NoData';
import Typography from '@/newComponents/Typography';
import styled from 'styled-components';
import AddRefImagesModal from '../modals/AddRefImagesModal';
import CreateRoomModals from '../modals/CreateRoomModal';
import { SectionTitle } from '../style';

type Props = {
  propertyId: number;
  bookingId?: number;
};

const NewReferenceImages = ({ propertyId, bookingId }: Props) => {
  const { auth } = useRoot();
  const isLandlord = auth?.role === 'landlord';
  const [enableAddRoomModal, setEnableAddRoomModal] = useState(false);
  const [enableAddImagesModal, setEnableAddImagesModal] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState({});
  const [deletingRefImage, setDeletingRefImage] = useState([]);
  const [addingRefImage, setAddingRefImage] = useState(false);
  const [files, setFiles] = useState();
  const { property }: any = useSelector(Store.Select.property);
  const isActionAble = isLandlord && auth?.id === property?.landlordId;

  const [editRoom, setEditRoom] = useState({ status: false, data: {} });

  //API HOOKS
  const [requestCreateGroup] = Hooks.useAPI();

  // SELECTOR
  const propertyRooms: any[] = useSelector(Store.Select.propertiesRooms);

  useEffect(() => {
    if (propertyId) {
      handleGetPropertyRooms();
    }
  }, [propertyId]);

  useEffect(() => {
    if (
      selectedRoom &&
      selectedRoom?.room?.id &&
      Array.isArray(propertyRooms)
    ) {
      const pRoom = propertyRooms.find(x => x.id === selectedRoom?.room?.id);
      setSelectedRoom({
        room: pRoom,
        refGroupId: pRoom?.referenceImageGroup[0]?.id,
      });
    }
  }, [propertyRooms]);

  const handleCancelAddRoomModal = () => {
    setEnableAddRoomModal(false);
    setEditRoom({ data: {}, status: false });
  };
  const handleCancelAddImageModal = () => {
    setEnableAddImagesModal(false);
    setFiles([]);
  };

  const handleGetPropertyRooms = async () => {
    if (propertyId) {
      await Utils.request(
        Apis.Properties.getPropertyReferenceRoomByPropertyId(propertyId),
      );
    }
  };

  const createRoomRefImageGroup = async (currentSelectRoom: any) => {
    const resp = await requestCreateGroup(
      Apis.Booking.createReferenceImageGroup({
        bookingId: bookingId || null,
        propertyId: propertyId,
        propertyReferenceRoomId: currentSelectRoom.id,
      }),
    );
  };

  const handleRoomSelectionForView = async pRoom => {
    const referenceImageGroupLandlord = [...pRoom?.referenceImageGroup]?.find(
      grp => grp?.authId === property?.landlordId,
    );

    if (referenceImageGroupLandlord?.id) {
      setSelectedRoom({
        room: pRoom,
        refGroupId: referenceImageGroupLandlord?.id,
      });
      setEnableAddImagesModal(true);
    } else {
      const result = await createRoomRefImageGroup(pRoom);
      setSelectedRoom({
        room: pRoom,
        refGroupId: result?.id,
      });
      await handleGetPropertyRooms();
      setEnableAddImagesModal(true);
    }
  };

  const getRefGroupImage = (pRoom: any) => {
    const referenceImageGroup = pRoom?.referenceImageGroup.find((x: any) => {
      if (x.authId === auth?.id && x.role === auth?.role) {
        return true;
      }

      return false;
    });
    if (referenceImageGroup) {
      return referenceImageGroup.referenceImage[0]?.URL;
    }
    return null;
  };

  if (!auth) {
    return null;
  }

  return (
    <>
      <ReferenceImageStyled>
        <TitleStyled>
          <div>
            <SectionTitle>Reference Images</SectionTitle>
            {isLandlord && propertyRooms?.length > 0 && (
              <Typography variant="p">
                Click on room to add reference images.
              </Typography>
            )}
          </div>
          {isActionAble && (
            <Button
              variant="gray"
              size="sm"
              onClick={() => {
                setEnableAddRoomModal(true);
              }}
            >
              Add
            </Button>
          )}
        </TitleStyled>

        {propertyRooms?.length > 0 ? (
          <div className="rooms-container">
            {propertyRooms.map(pRoom => (
              <div className="room-wrapper">
                <RoomStyled
                  bgImage={getRefGroupImage(pRoom) || NoImage}
                  data-room={pRoom?.title}
                  onClick={() => {
                    handleRoomSelectionForView(pRoom);
                  }}
                ></RoomStyled>
                <div className="title">{pRoom.title}</div>
              </div>
            ))}
          </div>
        ) : (
          <>
            <NoData title="No reference images of room added yet." size={150} />
          </>
        )}
      </ReferenceImageStyled>
      <CreateRoomModals
        bookingId={bookingId}
        enableAddRoomModal={enableAddRoomModal}
        handleCancelAddRoomModal={handleCancelAddRoomModal}
        handleGetPropertyRooms={handleGetPropertyRooms}
        propertyId={propertyId}
        setEnableAddImagesModal={setEnableAddImagesModal}
        setSelectedRoom={setSelectedRoom}
        editRoom={editRoom}
      />
      <AddRefImagesModal
        enableAddImagesModal={enableAddImagesModal}
        handleGetPropertyRooms={handleGetPropertyRooms}
        isLandlord={isLandlord}
        onClose={() => setEnableAddImagesModal(false)}
        propertyId={propertyId}
        selectedRoom={selectedRoom}
        setSelectedRoom={setSelectedRoom}
        setEditRoom={data => {
          setEditRoom(data);
          setEnableAddRoomModal(true);
          setEnableAddImagesModal(false);
        }}
      />
    </>
  );
};

export default NewReferenceImages;

const ReferenceImageStyled = styled.div`
  padding-bottom: 40px;
  margin-bottom: 80px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  & .rooms-container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

    & .room-wrapper {
      width: inherit;
    }
  }

  & .title {
    text-align: center;
    font-size: 12px;
    margin: 3px 0px;
  }
  .gray-text {
    color: #666;
  }
`;
export const TitleStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
`;

export const RoomStyled = styled.div<{ bgImage?: string }>`
  background-color: #ecf3ff;
  background-image: ${({ bgImage }) => `url(${bgImage})`};
  background-size: cover;
  background-position: center;
  height: 100px;
  width: 100px;
  cursor: pointer;
  border-radius: 20px;
  overflow: hidden;

  position: relative;

  &::before {
    display: none;
    content: attr(data-room);
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    z-index: 10;
    transition: 0.3s;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &:hover {
    &::before {
      display: flex;
    }
  }
`;

export const ButtonWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  & button + button {
    margin-left: 10px;
  }
`;

export const TitleWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & svg.edit-icons {
    visibility: visible;
    height: 16px;
    width: 16px;
    cursor: pointer;
    margin-right: 5px;
  }

  & input[type='text'] {
    width: 50%;
  }
  & svg.cancel-icons {
    height: 16px;
    width: 16px;
    cursor: pointer;
    margin: 0px 10px;
    border: 1px solid #dbdbdb;
    padding: 1px;
  }
  & svg.check-icons {
    height: 16px;
    width: 16px;
    cursor: pointer;
    border: 1px solid #dbdbdb;
    padding: 1px;
  }
`;
