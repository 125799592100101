import RootProvider, { useRoot } from '@/RootProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Apis, Hooks, Utils } from '@shared/front';
import InputField from '@shared/web/components/InputField';
import Modal from '@/newComponents/Modal';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import * as yup from 'yup';
import {
  ButtonsContainer,
  SaveButtonContainer,
  StyledButton,
  StyledLabel,
  Title,
} from '../index.styles';
import Typography from '@/newComponents/Typography';
import Loading from '@/newComponents/Loading';

const CardDetails = styled.div`
  background-color: #fafafa;
  padding: 15px 20px;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  > .ant-typography {
    margin-bottom: 0;
  }
`;

const DeleteButton = styled(StyledButton)`
  background-color: #d32f2f;
  margin-left: 16px;
`;

const validation = yup.object().shape({
  city: yup.string().required('Please enter city'),
  state: yup.string().required('Please enter state'),
  postalCode: yup.string().required('Plese enter postal code'),
  addressLine: yup.string().required('Please enter address line'),
  name: yup.string().required('Please enter name'),
  email: yup.string().required('Please enter email'),
  bankName: yup.string().required('Please enter bank name'),
  accountNumber: yup.string().required('Please enter Account Number'),
});

const BankAccount = () => {
  const { auth, checkAuth } = useRoot();
  const [userDocuments, setUserDocuments] = useState([]);
  const [makeRequest, { loading: makeBankRequestLoading }] = Hooks.useAPI();
  const [makeGetRequest, { data: bankDetails, loading: requestLoading }] =
    Hooks.useAPI();
  const [deletePropertyRequest, { loading: deleteLoading }] = Hooks.useAPI();
  const [visible, setVisible] = React.useState(false);
  const getDocuments = async () => {
    const docs = await Utils.request(Apis.Account.getDocuments());
    setUserDocuments(docs);
    checkAuth();
  };

  const handleSubmitForm = async values => {
    try {
      if (!userDocuments?.length) {
        toast('Please upload your valid document first ');
        return;
      }
      await makeRequest(
        Apis.Account.addBankDetails({
          ...values,
          last4: values.accountNumber.substr(-4),
        }),
      );

      await Utils.request(Apis.Auth.getMe());
      await getBankDetails();
    } catch (error) {
      console.log('Error:', error);
      toast('An error occurred. Please try again later.');
    }
  };
  const getBankDetails = () => {
    makeGetRequest(Apis.Account.getBankDetails());
    checkAuth();
  };
  const handleDeleteRequest = async () => {
    await deletePropertyRequest(Apis.Account.deleteBankDetails());
    getBankDetails();
    setVisible(false);
  };
  useEffect(() => {
    getDocuments();
  }, []);

  useEffect(() => {
    getBankDetails();
  }, []);
  return (
    <div style={{ maxWidth: '450px' }}>
      <Modal
        title="Are you sure you want to delete?"
        open={visible}
        // onOk={handleDeleteRequest}
        // okType="danger"
        // okText="Delete"
        // confirmLoading={deleteLoading}
        onClose={() => setVisible(false)}
      >
        <StyledLabel>
          You card will be deleted, if you don't add card then you can't use
          some features.
        </StyledLabel>
        <SaveButtonContainer>
          <StyledButton
            radius="round"
            variant="gray"
            onClick={() => setVisible(false)}
          >
            Cancel
          </StyledButton>
          <DeleteButton radius="round" onClick={handleDeleteRequest}>
            Delete
          </DeleteButton>
        </SaveButtonContainer>
      </Modal>
      <Title variant="h1">Bank Account</Title>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {requestLoading && <Loading />}
      </div>

      {!requestLoading && bankDetails?.id && (
        <div>
          <CardDetails>
            <Typography variant="p" color="#949494">
              XXXX-XXXX-XXXX-${bankDetails?.last4}
            </Typography>

            <FontAwesomeIcon
              icon="trash"
              color="red"
              size="xl"
              style={{ marginLeft: '20px' }}
              onClick={() => setVisible(true)}
            />
          </CardDetails>
          {/* <IconWrapper
            transparent
            noShadow
            onPress={() => {
              !deleteLoading &&
                Alert.alert(
                  'Are you sure? ',
                  `Once deleted can't retrive data.`,
                  [
                    {
                      text: 'Cancel',
                      onPress: () => console.log('Cancel Pressed'),
                      style: 'cancel',
                    },
                    {
                      text: 'Delete',
                      style: 'destructive',

                      onPress: handleDeleteRequest,
                    },
                  ],
                );
            }}
          >
            <Icon name="trash" color="red" />
          </IconWrapper> */}
        </div>
      )}

      {!requestLoading && !bankDetails?.id && (
        <Formik
          initialValues={{
            name: auth?.fullName,
            email: auth?.email,
          }}
          onSubmit={values => {
            handleSubmitForm(values);
          }}
          validationSchema={validation}
          enableReinitialize
        >
          {({ values }) => {
            // console.log('the values ate====', values);
            return (
              <Form>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px',
                  }}
                >
                  <div>
                    <StyledLabel>Name</StyledLabel>
                    <Field name="name" component={InputField} />
                  </div>
                  <div>
                    <StyledLabel>City</StyledLabel>
                    <Field name="city" component={InputField} />
                  </div>
                  <div>
                    <StyledLabel>State</StyledLabel>
                    <Field name="state" component={InputField} />
                  </div>
                  <div>
                    <StyledLabel>Postal Code</StyledLabel>
                    <Field name="postalCode" component={InputField} />
                  </div>
                  <div>
                    <StyledLabel>Address Line</StyledLabel>
                    <Field name="addressLine" component={InputField} />
                  </div>
                  <div>
                    <StyledLabel>Bank Name</StyledLabel>
                    <Field name="bankName" component={InputField} />
                  </div>
                  <div>
                    <StyledLabel>IBAN</StyledLabel>
                    <Field name="accountNumber" component={InputField} />
                  </div>
                </div>
                <ButtonsContainer>
                  <StyledButton
                    variant="gray"
                    radius="round"
                    isDisabled={makeBankRequestLoading}
                    isLoading={makeBankRequestLoading}
                    onClick={() => {
                      handleSubmitForm({
                        accountNumber: 'IE29AIBK93115212345678',
                        addressLine: '123 Fake Street',
                        bankName: 'Test Bank',
                        city: 'Dublin',
                        email: 'ianantashrestha+1@gmail.com',
                        name: 'Ananta Tenant',
                        postalCode: 'D02 ABC1',
                        state: 'Co. Limerick',
                      });
                    }}
                  >
                    Use Test Bank
                  </StyledButton>
                  <StyledButton
                    radius="round"
                    htmlType="submit"
                    isDisabled={makeBankRequestLoading}
                    isLoading={makeBankRequestLoading}
                  >
                    ADD
                  </StyledButton>
                </ButtonsContainer>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default BankAccount;
