import { keyframes } from 'styled-components';

//^^^^^^^^^^^^^^^^^^^ BOTTOM ANIMATION
export const slideInCenter_bottom = keyframes`
  from {
    transform: translate(-50%, 100vh);
  }
  to {
    transform: translate(-50%,-50%);
  }
`;
export const slideIn_bottom = keyframes`
  from {
    transform: translate(-50%, 100vh);
  }
  to {
    transform: translate(-50%,0%);
  }
`;

export const slideOut_bottom = keyframes`
  from {
    transform: translate(-50%, 0%);
  }
  to {
    transform: translate(-50%,100vh);
  }
`;

export const slideOutCenter_bottom = keyframes`
  from {
    transform: translate(-50%, -50%);
  }
  to {
    transform: translate(-50%,100vh);
  }
`;

// vvvvvvvvvvvvvvvv TOP ANIMATION
export const slideInCenter_top = keyframes`
  from {
    transform: translate(-50%, -100vh);
  }
  to {
    transform: translate(-50%,-50%);
  }
`;
export const slideIn_top = keyframes`
  from {
    transform: translate(-50%, -100vh);
  }
  to {
    transform: translate(-50%,0%);
  }
`;

export const slideOut_top = keyframes`
  from {
    transform: translate(-50%, 0%);
  }
  to {
    transform: translate(-50%,-100vh);
  }
`;

export const slideOutCenter_top = keyframes`
  from {
    transform: translate(-50%, -50%);
  }
  to {
    transform: translate(-50%,-100vh);
  }
`;

//>>>>>>>>>>>>>>>>>>>>LEFT ANIMATION

export const slideIn_left = keyframes`
  from {
    transform: translate( -100vw,0%);
  }
  to {
    transform: translate(-50%,0%);
  }
`;

export const slideOut_left = keyframes`
  from {
    transform: translate(-50%, 0%);
  }
  to {
    transform: translate(-100vw, 0%);
  }
`;

export const slideInCenter_left = keyframes`
  from {
    transform: translate( -100vw,-50%);
  }
  to {
    transform: translate(-50%,-50%);
  }
`;

export const slideOutCenter_left = keyframes`
  from {
    transform: translate(-50%, -50%);
  }
  to {
    transform: translate(-100vw,-50%);
  }
`;

// RIGHT ANIMATION<<<<<<<<<<<<<<<<<

export const slideIn_right = keyframes`
  from {
    transform: translate( 100vw,0%);
  }
  to {
    transform: translate(-50%,0%);
  }
`;

export const slideOut_right = keyframes`
  from {
    transform: translate(-50%, 0%);
  }
  to {
    transform: translate(100vw, 0%);
  }
`;

export const slideInCenter_right = keyframes`
  from {
    transform: translate( 100vw,-50%);
  }
  to {
    transform: translate(-50%,-50%);
  }
`;

export const slideOutCenter_right = keyframes`
  from {
    transform: translate(-50%, -50%);
  }
  to {
    transform: translate(100vw,-50%);
  }
`;
