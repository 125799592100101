import AddressSearch from '@/newComponents/AddressSearch';
import { Constants } from '@shared/common';
import Modal from '@/newComponents/Modal';
import { Field, Form, Formik, FormikProps } from 'formik';
import React from 'react';
import Select from '@/newComponents/Select';
import { ButtonsContainer, StyledButton } from './styles';

export type InitialValueTypes = {
  bedrooms?: number;
  bathrooms?: number;
  address?: string;
  propertyType?: string;
  sort?: string;
  furnishedType?: string;
  rentalMarket?: string;
};

const INITIAL_VALUES = {
  address: undefined,
  propertyType: undefined,
};

type FilterModalProps = {
  isModalOpen: boolean;
  initialValues?: InitialValueTypes;
  onClose: () => void;
  onSubmit: (data: InitialValueTypes) => void;
  onReset: () => void;
  formikRef?: React.RefObject<FormikProps<any>>;
};

const FilterModel = ({
  isModalOpen,
  initialValues,
  onClose,
  onSubmit,
  onReset,
  formikRef,
}: FilterModalProps) => {
  const resetForm = () => {
    onReset && onReset();
    formikRef?.current?.resetForm();
    onClose && onClose();
  };

  const handleSubmit = (values: InitialValueTypes) => {
    onSubmit(values);
  };

  return (
    <>
      <Modal title="Filters" open={isModalOpen} onClose={onClose}>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues || INITIAL_VALUES}
          onSubmit={values => handleSubmit(values)}
          enableReinitialize
        >
          {({ values, errors, setFieldValue }) => {
            return (
              // @ts-ignore
              <Form autocomplete="off">
                <Field
                  name="address"
                  label="Address"
                  component={AddressSearch}
                  type="text"
                  placeholder="Address"
                  onSelect={(data: any) => {
                    setFieldValue('address', data?.address);
                  }}
                  value={values?.address}
                  error={errors?.address}
                />

                <Field
                  component={Select}
                  options={[
                    {
                      label: 'All',
                      value: '',
                      selected: true,
                    },
                    ...Constants.PropertyDropdown.propertyTypeOptions(),
                  ]}
                  label="Property Type"
                  name="propertyType"
                  htmlType="select"
                  value={values?.propertyType}
                  onChange={e => setFieldValue('propertyType', e)}
                />
                <ButtonsContainer>
                  <div style={{ display: 'flex', gap: '16px' }}>
                    <StyledButton
                      radius="round"
                      variant="gray"
                      onClick={onClose}
                    >
                      Cancel
                    </StyledButton>
                    <StyledButton
                      radius="round"
                      onClick={() => {
                        resetForm();
                      }}
                    >
                      Reset
                    </StyledButton>
                  </div>
                  <StyledButton htmlType="submit" radius="round">
                    Submit
                  </StyledButton>
                </ButtonsContainer>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default FilterModel;
