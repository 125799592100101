import React, { useEffect, useState } from 'react';
import { useRoot } from '@/RootProvider';
import { SectionHeaderRow, SectionTitle, SectionWrapper } from '../style';
import Button from '@/newComponents/Button';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Store, Apis, Utils } from '@shared/front';
import NoData from '@/newComponents/NoData';
import Typography from '@/newComponents/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Column, Row } from '@/newComponents/SpreadsheetTools';
import RulesModal from './PropertyRulesModal';
import iconRules from '@shared/common/constants/iconRule';
import { toast } from 'react-toastify';
import { Image } from '../style';
import { ButtonEndWrapperStyled } from '@/styles/shareableStyle';
import Modal from '@/newComponents/Modal';
import { useResponsive } from '@/hooks/useResponsive';
import EditIconNew from '../../../assets/edit-icon-new.svg';
import DeleteIconNew from '../../../assets/delete-icon-new.svg';
const PolicyWrapper = styled.div``;

const PolicyList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
`;
const PolicyText = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
`;
const PolicyItem = styled.div`
  background: #f5f5f5;
  padding: 10px 20px;
  border-radius: 4px;
  display: flex;
`;
const InventoryActionWrapper = styled.div`
  display: flex;
  margin-left: auto;
  column-gap: 10px;
`;
const StyledIconSpan = styled.span`
  cursor: pointer;
  /* Add any additional styling here if needed */
`;

type Props = {
  propertyId?: number;
  bookingId?: number;
  landlordId?: number;
  propertyRules: any[];
};

const Rules = ({ propertyId, landlordId, propertyRules }: Props) => {
  const { auth: user } = useRoot();
  const { isTablet } = useResponsive();
  console.log(user, 'usernnnnn');
  const property = useSelector(Store.Select.property);

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [deleteLoading, setDeleteLoading] = useState<any>({
    id: null,
    status: false,
  });
  const [confirmDeletion, setConfirmDeletion] = useState<{
    status: boolean;
    data: any;
  }>({
    status: false,
    data: null,
  });
  const [open, setOpen] = useState<boolean>(false);

  // const getPolicies = async () => {
  //   await Utils.request(Apis.Properties.getPropertyById(propertyId, auth?.role));
  // };
  const hasAccess = user?.id === landlordId;
  console.log(hasAccess, 'has access');

  // const getPolicies = async () => {
  //   try {
  //     await Utils.request(Apis.Properties.getPolicies(propertyId));
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  const deletePropertyRules = async (id: number) => {
    try {
      await Utils.request(Apis.Properties.deletePropertyRules(id));
      let updateProperty: any = property;
      let propertyInfo: any = updateProperty?.property;
      const currentPropertyRules = updateProperty?.property?.propertyRules;
      propertyInfo = {
        ...propertyInfo,
        propertyRules: currentPropertyRules?.filter(
          (item: any) => item.id !== id,
        ),
      };
      updateProperty = {
        ...updateProperty,
        property: propertyInfo,
      };
      console.log(currentPropertyRules, 'updateProperty');

      Store.store.dispatch(Store.Actions.update('property', updateProperty));
      toast.success('Deleted successfully');

      setDeleteLoading({ ...deleteLoading, status: false });
      closeDeleteModal();
    } catch (err) {
      toast.error('Failed');
      setDeleteLoading({ ...deleteLoading, status: false });
    }
  };
  // useEffect(() => {
  //   getPolicies();
  // }, []);
  const openDeleteModal = (data: any) => {
    setConfirmDeletion({
      status: true,
      data,
    });
  };

  const closeDeleteModal = () => {
    setConfirmDeletion({
      status: false,
      data: null,
    });
  };
  return (
    <SectionWrapper>
      <SectionHeaderRow>
        <SectionTitle>Rules</SectionTitle>
        {hasAccess && (
          <Button
            style={{ marginLeft: 'auto' }}
            variant="gray"
            size="sm"
            onClick={() => {
              setOpen(true);
            }}
          >
            Add
          </Button>
        )}
      </SectionHeaderRow>
      <PolicyWrapper>
        {!!propertyRules?.length ? (
          <PolicyList>
            {propertyRules?.map(category => (
              <PolicyItem key={category?.id}>
                <Image
                  style={{ height: 20, paddingRight: 10 }}
                  src={iconRules?.icon}
                />
                <Typography variant="p">{category?.rules}</Typography>

                <InventoryActionWrapper>
                  {hasAccess && (
                    <StyledIconSpan
                      onClick={e => {
                        e.stopPropagation();
                        openDeleteModal(category);
                      }}
                    >
                      <img src={DeleteIconNew} alt="Delete" />
                    </StyledIconSpan>
                  )}
                </InventoryActionWrapper>
              </PolicyItem>
            ))}
          </PolicyList>
        ) : (
          <NoData size="130" title="No Rules are available" />
        )}
      </PolicyWrapper>
      {hasAccess && (
        <RulesModal
          propertyId={propertyId}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          setOpen={setOpen}
          open={open}
        />
      )}
      <Modal
        open={confirmDeletion.status}
        onClose={closeDeleteModal}
        title="Delete"
        isCentered={!isTablet}
        style={{ width: !isTablet ? '90vw' : 'auto' }}
      >
        Are you sure you want to delete {confirmDeletion?.data?.title} ?
        <br />
        <br />
        <ButtonEndWrapperStyled>
          <Button variant="gray" onClick={closeDeleteModal}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              deletePropertyRules(confirmDeletion?.data?.id);
            }}
            isLoading={deleteLoading.status}
            isDisabled={deleteLoading.status}
          >
            Delete
          </Button>
        </ButtonEndWrapperStyled>
      </Modal>
    </SectionWrapper>
  );
};

export default Rules;
