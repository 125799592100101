import Modal from '@/newComponents/Modal';
import iconAmenities from '@shared/common/constants/iconAmenties';
import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { Image } from './style';
const FeatureItem = styled.div``;

const SectionDescription = styled.p`
  color: ${({ theme }: any) => theme?.colors?.webPrimaryTextColor};
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  margin: 0px;
`;
interface RoomModalProps {
  setOpen: any;
  open: boolean;
  propertyId: number;
  isEditMode?: boolean;
  setIsEditMode?: any;
}
const createRoomSchema = yup.object().shape({
  title: yup.string().required('Title is required.'),
});
const AmenitiesModal = ({
  setOpen,
  open,
  propertyAmenities,
}: RoomModalProps) => {
  return (
    <>
      {!!open && (
        <Modal
          isCentered
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          size={'sm'}
        >
          {propertyAmenities?.map((item: any) => (
            <FeatureItem key={item?.id}>
              <SectionDescription>
                <>
                  <Image style={{ height: 20 }} src={iconAmenities?.icon} />
                </>
                {item?.amenities}
              </SectionDescription>
            </FeatureItem>
          ))}
        </Modal>
      )}
    </>
  );
};

export default AmenitiesModal;
