import Error from '@shared/web/components/Error';
import InputField from '@shared/web/components/InputField';
import { Field, Formik } from 'formik';
import useAPI from '@/hooks/api';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import AuthWrapper from './AuthWrapper';
import { ButtonsContainer, StyledButton, StyledForm, Title } from './styles';

const loginValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Email is not valid')
    .required('Please enter your email'),
});

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [handleSubmitRequest, { loading, error }] = useAPI();

  const handleSubmit = async (values, formikHelpers) => {
    await handleSubmitRequest({
      method: 'post',
      url: '/v1/auth/forgot-password',
      data: { ...values },
    });
    navigate(`/resetPassword?email=${values.email}`);
  };

  return (
    <AuthWrapper>
      <Formik
        initialValues={{ email: '' }}
        onSubmit={handleSubmit}
        validationSchema={loginValidationSchema}
      >
        {({ handleSubmit, isSubmitting, errors, touched }) => {
          return (
            <StyledForm onSubmit={handleSubmit}>
              <div>
                <Title variant="h1">Forgot Password?</Title>
                <Field component={InputField} label="Email" name="email" />
                <Error message={error} isFormError />
              </div>
              <ButtonsContainer>
                <StyledButton
                  radius="round"
                  variant="gray"
                  onClick={() => navigate('/login')}
                >
                  Back to Login
                </StyledButton>
                <StyledButton
                  radius="round"
                  htmlType="submit"
                  disabled={loading || isSubmitting}
                  loading={loading || isSubmitting}
                >
                  Submit
                </StyledButton>
              </ButtonsContainer>
            </StyledForm>
          );
        }}
      </Formik>
    </AuthWrapper>
  );
};

export default ForgotPassword;
