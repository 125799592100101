import Checkbox from '@/newComponents/Checkbox';
import Error from '@shared/web/components/Error';
import { Formik, Form } from 'formik';
import useAPI from '@/hooks/api';
import { pick } from 'lodash';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import validation from './validation';
import { useNavigate, useLocation } from 'react-router-dom';
import { ButtonsContainer, StyledButton, Title } from './index.style';
import { devices } from '@/utils/theme';
import { Apis } from '@shared/front';

const AmenitiesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 12px;

  @media ${devices.laptop} {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }
`;

type JobCategoriesType = {
  id: number;
  title: string;
};

const Features = ({
  initialValues,
  onSubmitStep,
}: {
  initialValues: any;
  onSubmitStep: any;
}) => {
  const [handleSubmitRequest, { loading, error }] = useAPI();
  const [categories, setCategories] = useState(Array<JobCategoriesType>);
  const getAllJobsCategories = async () => {
    try {
      const response = await handleSubmitRequest(
        Apis.Services.getAllJobCategories(),
      );
      const features: Array<JobCategoriesType> =
        response && response?.map((item: JobCategoriesType) => item);
      setCategories(features);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getAllJobsCategories();
  }, []);
  const navigate = useNavigate();
  const [targetButton, setTargetButton] = useState<string>('');
  const handleSubmitForm = async (values: any, isDraft?: boolean) => {
    const url = '/v1/services/categories';
    const parsedValues = pick(values, ['categories']);
    await handleSubmitRequest({
      method: 'post',
      url: initialValues.id
        ? `/v1/services/${initialValues.id}categories`
        : url,
      data: parsedValues,
    });

    onSubmitStep({ isDraft });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => {
        handleSubmitForm(values);
      }}
      validationSchema={validation.categories}
    >
      {({
        isSubmitting,
        initialValues,
        errors,
        touched,
        values,
        setFieldValue,
        handleSubmit,
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Title variant="h1">Categories</Title>
            <AmenitiesGrid>
              {categories &&
                categories?.map(({ title, id }) => {
                  return (
                    <Checkbox
                      id={id}
                      label={title}
                      isChecked={values?.categories?.includes(title)}
                      onChange={e => {
                        if (e.target.checked) {
                          setFieldValue('categories', [
                            ...(values?.categories || []),
                            title,
                          ]);
                        } else {
                          setFieldValue(
                            'categories',
                            values?.categories.filter(i => i !== title),
                          );
                        }
                      }}
                    />
                  );
                })}
            </AmenitiesGrid>

            <Error message={error || errors?.categories} isFormError />
            <ButtonsContainer>
              <StyledButton
                type="button"
                variant="gray"
                radius="round"
                onClick={() => navigate('/jobs')}
              >
                Cancel
              </StyledButton>

              <div style={{ display: 'flex', gap: '20px' }}>
                <StyledButton
                  radius="round"
                  variant="gray"
                  type="button"
                  isDisabled={targetButton === 'draft' ? loading : false}
                  isLoading={targetButton === 'draft' ? loading : false}
                  onClick={() => {
                    setTargetButton('draft');
                    handleSubmitForm(values, true);
                  }}
                >
                  Save as draft
                </StyledButton>
                <StyledButton
                  radius="round"
                  type="submit"
                  disabled={targetButton === 'save' ? loading : false}
                  loading={targetButton === 'save' ? loading : false}
                  onClick={() => {
                    setTargetButton('save');
                  }}
                >
                  Save
                </StyledButton>
              </div>
            </ButtonsContainer>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Features;
