import Modal from '@/newComponents/Modal';
import React from 'react';
import IndividualUser from '../IndividualUser';
import {
  ButtonsContainer,
  ModalInfoContainer,
  ModalMainText,
  ModalSubText,
  StyledButton,
  TenantsContainerStyled,
} from '../../bookingModals/modalStyles';

type Props = {
  tenants: any;
  selectedTenants: number[];
  isOpen: boolean;
  onClose: () => void;
  onCreateShortList: () => void;
  isLoading: boolean;
};

const CreateShortListModal = ({
  isOpen,
  onClose,
  onCreateShortList,
  selectedTenants,
  tenants,
  isLoading,
}: Props) => {
  const handleCancel = () => {
    onClose();
  };
  const handleOk = async () => {
    await onCreateShortList();
    handleCancel();
  };

  const selectedUsers = tenants?.filter(x =>
    selectedTenants.includes(x.bookingId),
  );

  return (
    <Modal open={isOpen} onClose={handleCancel} title="Short List Profile">
      <ModalInfoContainer>
        {selectedTenants && selectedTenants.length > 0 ? (
          <>
            <ModalMainText variant="h4">
              Are you sure you want to move selected profile to short list?
            </ModalMainText>
            <ModalSubText variant="p">
              Make sure that you listed right profile for this list.
            </ModalSubText>
            <br />
            <br />
            <TenantsContainerStyled>
              {selectedUsers.map(tenant => (
                <IndividualUser
                  tenant={tenant}
                  isReadOnly
                  isSmall
                  disableViewBtn
                />
              ))}
            </TenantsContainerStyled>
          </>
        ) : (
          <ModalSubText variant="p">
            No tenants are selected. Please select tenants and try again.
          </ModalSubText>
        )}
      </ModalInfoContainer>
      {selectedTenants && selectedTenants.length > 0 && (
        <ButtonsContainer>
          <StyledButton
            variant="gray"
            radius="round"
            isDisabled={isLoading}
            onClick={handleCancel}
          >
            Cancel
          </StyledButton>
          <StyledButton radius="round" isLoading={isLoading} onClick={handleOk}>
            Create
          </StyledButton>
        </ButtonsContainer>
      )}
    </Modal>
  );
};

export default CreateShortListModal;
