import Modal from '@/newComponents/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Image } from '@shared/web/components/common';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

type Props = {
  files?: any;
  setFiles: Function;
};
const accept = ['image/jpg', 'image/png', 'image/jpeg', 'image/gif'];

const ImageBrowser = ({ setFiles }: Props) => {
  const _onChange = (event: any) => {
    const files = Array.from(event.target.files);

    setFiles((prev: any) =>
      Array.isArray(prev) ? [...prev, ...files] : files,
    );
  };

  return (
    <BrowseButtonWrapperStyled>
      <BrowseButtonStyled htmlFor="file-upload">
        <FontAwesomeIcon icon="plus" className="icon" />
      </BrowseButtonStyled>
      <FileInput
        type="file"
        onChange={_onChange}
        multiple={true}
        id="file-upload"
      />
    </BrowseButtonWrapperStyled>
  );
};

export default ImageBrowser;

export const ImageViewer = ({
  files,
  setFiles,
  isLoading,
  imagesURL,
  onRemove,
  isDeleting,
  callbackAfterDeletion,
  enableRemoveImage = true,
}: {
  files: any;
  setFiles: Function;
  isLoading?: boolean;
  imagesURL?: { URL: string; id: number }[];
  onRemove?: (id: number) => void;
  isDeleting?: number[];
  callbackAfterDeletion?: () => void;
  enableRemoveImage?: boolean;
}) => {
  const [showFullImageViewer, setShowFullImageViewer] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const onRemoveFilesFromArray = (index: number) => {
    files.splice(index, 1);
    setFiles([...files]);
  };

  const handleRemoveImages = (id: number) => {
    onRemove && onRemove(id);
    callbackAfterDeletion && callbackAfterDeletion();
  };
  if (
    Array.isArray(files) &&
    files.length === 0 &&
    Array.isArray(imagesURL) &&
    imagesURL.length === 0
  ) {
    return null;
  }
  return (
    <>
      <ImagesWrapperStyled>
        {Array.isArray(imagesURL) &&
          imagesURL.length > 0 &&
          imagesURL.map((img, idx) => (
            <div className="image-wrapper">
              {Array.isArray(isDeleting) &&
                !isDeleting?.includes(img.id) &&
                enableRemoveImage && (
                  <CloseIcon
                    icon="times-circle"
                    onClick={() => {
                      handleRemoveImages(img.id);
                    }}
                  />
                )}
              <Image
                src={img?.URL}
                alt="Image already uploaded."
                onClick={() => {
                  setSelectedImageIndex(idx);
                  setShowFullImageViewer(true);
                }}
                className="cursor-pointer"
              />
              {Array.isArray(isDeleting) && isDeleting?.includes(img.id) && (
                <div className="loader">
                  <FontAwesomeIcon icon="circle-notch" spin size="2x" />
                </div>
              )}
            </div>
          ))}
      </ImagesWrapperStyled>
      <ImagesWrapperStyled>
        {Array.isArray(files) &&
          files.length > 0 &&
          files.map((img, idx) => (
            <div className="image-wrapper">
              {!isLoading && (
                <CloseIcon
                  icon="times-circle"
                  onClick={() => {
                    onRemoveFilesFromArray(idx);
                  }}
                />
              )}
              <Image
                src={URL.createObjectURL(img)}
                alt="Image not uploaded yet."
              />
              {isLoading && (
                <div className="loader">
                  <FontAwesomeIcon icon="circle-notch" spin size="2x" />
                </div>
              )}
            </div>
          ))}
      </ImagesWrapperStyled>
      <FullPageImageViewer
        isVisible={showFullImageViewer}
        images={
          imagesURL?.reduce((acc, val) => {
            acc.push(val?.URL);
            return acc;
          }, [] as string[]) as string[]
        }
        onClose={() => {
          setShowFullImageViewer(false);
          setSelectedImageIndex(0);
        }}
        currentIndex={selectedImageIndex}
      />
    </>
  );
};

type FullPageImageViewerProps = {
  isVisible: boolean;
  images: string[];
  onClose: () => void;
  currentIndex: number;
};

const FullPageImageViewer = ({
  isVisible,
  onClose,
  currentIndex = 0,
  images = [],
}: FullPageImageViewerProps) => {
  const [activeIndex, setActiveIndex] = useState(currentIndex);

  useEffect(() => {
    setActiveIndex(currentIndex);
  }, [currentIndex]);
  const imgLen = images.length;
  const handlePrev = () => {
    setActiveIndex(prev => (prev === 0 ? imgLen - 1 : prev - 1));
  };
  const handleNext = () => {
    setActiveIndex(prev => (prev === imgLen - 1 ? 0 : prev + 1));
  };

  if (!Array.isArray(images)) {
    return null;
  }
  return (
    <Modal
      open={isVisible}
      onCancel={onClose}
      footer={null}
      width="100vw"
      centered
      bodyStyle={{
        padding: '0px',
      }}
    >
      <ImagesContainerStyled>
        {imgLen > 1 && (
          <div className="previous btn" onClick={handlePrev}>
            <FontAwesomeIcon icon="arrow-alt-circle-left" />
          </div>
        )}
        <Image src={images[activeIndex]} className="selectDisable" />
        {imgLen > 1 && (
          <div className="next btn" onClick={handleNext}>
            <FontAwesomeIcon icon="arrow-alt-circle-right" />
          </div>
        )}
      </ImagesContainerStyled>
    </Modal>
  );
};

const ImagesContainerStyled = styled.div`
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0px 35px;

  & .btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    & svg {
      height: 32px;
      color: #1230c9;
    }
  }

  & .btn.previous {
    left: 0px;
  }
  & .btn.next {
    right: 0px;
  }

  & > img {
    height: calc(100vh - 80px);
    object-fit: contain;

    &.selectDisable {
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -o-user-select: none;
      user-select: none;
    }
  }
`;

const BrowseButtonWrapperStyled = styled.div`
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid #dbdbdb;
  border-radius: 5px;
  margin: 10px;

  position: relative;
`;

const BrowseButtonStyled = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & .icon {
    height: 30px;
    width: 30px;
    color: #808080;
  }
`;

const FileInput = styled.input`
  display: none;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  color: red;
  background-color: white;
  border-radius: 20px;
  position: absolute;
  top: -5px;
  right: -5px;
  font-size: 20px;
  cursor: pointer;
`;

const ImagesWrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  margin: 20px 0px;

  & .image-wrapper {
    position: relative;
    & img {
      border: 1px solid #dbdbdb;
      border-radius: 10px;
      height: 100px;
      min-width: 100px;
    }

    & .loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      & svg {
        color: #fff;
      }
    }
  }

  & .cursor-pointer {
    cursor: pointer;
  }
`;
