import { useResponsive } from '@/hooks/useResponsive';
import Typography from '@/newComponents/Typography';
import store from '@/store';
import { devices } from '@/utils/theme';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import Hamburger from '../../assets/hamburger.svg';
import FullLogoSVG from '../../assets/rentisity-full-logo.svg';
import Button from '../Button';
import Divider from '../Divider';
import Drawer from '../Drawer';

// MenuContainer styles
const MenuContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  height: 50px;
  width: 40px;

  &.drawerLogo {
    width: 100px;
  }

  margin-right: 8px;
  @media ${devices.tablet} {
    height: 50px;
    width: 120px;
  }
  cursor: pointer;
`;

export const SmallScreenMenu = styled.div`
  position: relative;
  & .services-container {
    position: absolute;
    width: 200px;
    left: 50%;
    top: 45px;
    transform: translateX(calc(-50%));
    background: #fcfcfc;
    box-shadow: 0px 0px 5px 0px #ccc;
    border-radius: 10px;
    z-index: 100;
    border-top: 1px solid #ccc;
    padding: 10px 10px;

    &::before {
      content: '';
      position: absolute;
      top: 0px;
      left: 50%;
      transform-origin: 50% 50%;
      transform: translate(-50%, -9px) rotate(45deg);
      z-index: 200;
      height: 16px;
      width: 16px;
      border-radius: 4px;

      background: #fcfcfc;
      border-top: 1px solid #ccc;
      border-left: 1px solid #ccc;
      box-shadow: 0 -6px 5px -5px #ccc, -6px 0 5px -5px #ccc;
    }
  }
`;

const SmallScreenMenuItem = styled.div`
  cursor: pointer;
  padding: 3px 6px;
  border-radius: 3px;
  margin: 8px 0px;
  padding: 8px 30px;
  &:hover {
    background: #f0f0f0;
  }
`;

const ContentContainer = styled.div`
  padding: 10px 0px;
  & .auth-nav {
    margin: 30px 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    & button {
      font-size: 14px;
      &.register {
      }
    }
  }
`;

const MenuData = [
  {
    id: 1,
    title: 'Browse Properties',
    path: '/search-properties',
  },
  {
    id: 2,
    title: 'Browse Jobs',
    path: '/search-jobs',
  },
  {
    id: 3,
    title: 'Marketplace',
    path: '/marketplace',
  },
  // {
  //   id: 4,
  //   title: ' Dashboard',
  //   path: '/dashboard',
  // },
  {
    id: 5,
    title: 'DevMenu',
    path: '/devMenu',
  },
];

const MenuAuthData = [
  {
    id: 6,
    title: 'Sign In',
    path: '/login',
    type: 'login',
  },
  {
    id: 7,
    title: 'Register',
    path: '/signup',
    variant: 'primary',
    type: 'register',
    color: '#fff',
  },
];

//updates
const LGMenuContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  & .auth-nav {
    display: flex;
    align-items: center;
  }
`;

const HamburgerStyle = styled.div`
  margin-left: 20px;
  margin-right: 8px;
  cursor: pointer;
  border-radius: 50%;
  background: #f1f1f1;
  padding: 8px;
  & img {
    height: 24px;
    width: 24px;
  }
`;

const MenuOptions = () => {
  const { useMediaQuery, isTablet } = useResponsive();
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1100px' });
  const dropdownRef = useRef(null);
  const theme = useTheme();
  const { pathname } = useLocation();
  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const handleClick = () => {
    setIsOpenPopover(!isOpenPopover);
  };

  const openAuthModal = (type: string) => {
    dispatch(store.Actions.set('authModal', { status: true, type: type }));
  };

  const handleLogoClick = () => {
    handleClick();
    navigate('/dashboard');
  };

  const LargeScreenMenuList = () => {
    return (
      <LGMenuContainer>
        {MenuData?.map(item => {
          const isActive = pathname.includes(item.path);
          return (
            <Link to={item?.path}>
              <Typography
                variant="span"
                color={
                  isActive
                    ? theme?.colors?.primary
                    : theme?.colors?.webPrimaryTextColor
                }
              >
                {item?.title}
              </Typography>
            </Link>
          );
        })}
        <div className="auth-nav">
          {MenuAuthData?.map(item => {
            const isActive = pathname.includes(item.path);
            return (
              <Button
                onClick={() => {
                  openAuthModal(item?.type);
                }}
                variant={item?.variant || 'ghost'}
                radius="arc"
                style={{
                  marginLeft: '5px',
                  color: isActive
                    ? item?.color || theme?.colors?.primary
                    : theme?.colors?.webPrimaryTextColor,
                }}
              >
                {item?.title}
              </Button>
            );
          })}
        </div>
      </LGMenuContainer>
    );
  };

  const smallScreenContent = () => {
    return (
      <ContentContainer>
        {MenuData?.map(item => {
          const isActive = pathname.includes(item.path);
          return (
            <SmallScreenMenuItem
              key={item.id}
              onClick={() => {
                navigate(`${item.path}`);
                setIsOpenPopover(false);
              }}
            >
              <Typography
                variant="p"
                color={
                  isActive
                    ? theme?.colors?.primary
                    : theme?.colors?.webPrimaryTextColor
                }
                size="sm"
              >
                {item.title}
              </Typography>
            </SmallScreenMenuItem>
          );
        })}
        <Divider />
        <div className="auth-nav">
          {MenuAuthData?.map(item => {
            const isActive = pathname.includes(item.path);
            return (
              <Button
                onClick={() => {
                  openAuthModal(item?.type);
                  setIsOpenPopover(false);
                }}
                variant={item?.variant || 'ghost'}
                radius="arc"
                style={{
                  marginLeft: '5px',
                  color: isActive
                    ? item?.color || theme?.colors?.primary
                    : theme?.colors?.webPrimaryTextColor,
                }}
              >
                {item?.title}
              </Button>
              // <Link to={item?.path}>
              //   <Button
              //     variant={item?.variant || 'gray'}
              //     radius="arc"
              //     style={{
              //       color: isActive
              //         ? item?.color || theme?.colors?.primary
              //         : theme?.colors?.webPrimaryTextColor,
              //     }}
              //     className={item?.path === '/signup' ? 'register' : ''}
              //   >
              //     {item?.title}
              //   </Button>
              // </Link>
            );
          })}
        </div>
      </ContentContainer>
    );
  };

  return (
    <>
      <MenuContainer>
        {isLargeScreen ? (
          <LargeScreenMenuList />
        ) : (
          <>
            <SmallScreenMenu onClick={handleClick} ref={dropdownRef}>
              <HamburgerStyle onClick={handleClick}>
                <img src={Hamburger} />
              </HamburgerStyle>
            </SmallScreenMenu>
            <Drawer
              placement="left"
              onClose={handleClick}
              open={isOpenPopover}
              width={270}
              header={
                <Logo
                  src={FullLogoSVG}
                  alt="Logo"
                  onClick={handleLogoClick}
                  className="drawerLogo"
                />
              }
            >
              {smallScreenContent()}
            </Drawer>
          </>
        )}
      </MenuContainer>
    </>
  );
};

export default MenuOptions;
