import { RootType, useRoot } from '@/RootProvider';
import Button from '@/newComponents/Button';
import Typography from '@/newComponents/Typography';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import CancelDeleteAccountConfirmation from './CancelDeleteAccountConfirmation';
import { CancelDeletionContainerStyled } from './style';
import { useNavigate } from 'react-router-dom';
import { Apis, Utils } from '@shared/front';

const DeleteAccountCancellation = () => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [loadingCancellation, setLoadingCancellation] = useState(false);
  const { auth, checkAuth }: RootType = useRoot();
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth?.deleteRequested) {
      navigate('/');
    }
  }, [auth]);

  const onCloseModal = () => setShowConfirmationModal(false);

  const onCancelAccountDeletion = () => {
    setShowConfirmationModal(true);
  };

  const handleCancelAccountDeletion = async () => {
    setLoadingCancellation(true);
    try {
      await Utils.request(Apis.Auth.activateAccount());
      checkAuth();
    } catch (error) {
      console.log('Error on revoking Deletion:', error);
    }
    setLoadingCancellation(false);
  };

  if (!auth?.deleteRequested || !auth?.id) {
    return null;
  }

  const daysRemaining =
    moment(auth?.accountDeleteDueDate).diff(moment(), 'days') + 1;
  const dueDate = moment(auth?.accountDeleteDueDate).format(
    'YYYY MMM DD ,dddd',
  );

  return (
    <CancelDeletionContainerStyled>
      <Typography fontWeight="bold" style={{ marginBottom: '8px' }}>
        You have requested to delete this account.
      </Typography>
      <Typography
        style={{ fontSize: '14px', color: '#6a6a6a', marginBottom: '8px' }}
      >
        Note: You have until {dueDate} ({daysRemaining} day's remaining) to
        cancel your deletion request.
      </Typography>
      <Typography>
        If you want to cancel account deletion click on 'Continue to Rentisity'.
      </Typography>

      <Button
        variant="primary"
        onClick={onCancelAccountDeletion}
        style={{ marginTop: '30px' }}
        isDisabled={loadingCancellation}
        isLoading={loadingCancellation}
      >
        Continue to Rentisity
      </Button>
      <CancelDeleteAccountConfirmation
        isOpen={showConfirmationModal}
        onClose={onCloseModal}
        onYes={handleCancelAccountDeletion}
      />
    </CancelDeletionContainerStyled>
  );
};

export default DeleteAccountCancellation;
