import SkeletonLoading from '@/newComponents/Skeleton';
import React from 'react';
import styled from 'styled-components';

const BrowsePropertySkeleton = ({ ...rest }) => {
  return (
    <Wrapper>
      <div className="post">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(item => {
          return (
            <div className="card" key={item}>
              <SkeletonLoading
                height={200}
                borderRadius={10}
                style={{ marginBottom: '30px' }}
              />
              <SkeletonLoading
                height={20}
                width={180}
                style={{ marginTop: '12px', marginLeft: '20px' }}
              />
              <SkeletonLoading
                height={20}
                width={220}
                style={{
                  marginTop: '12px',
                  marginLeft: '20px',
                  marginBottom: '20px',
                }}
              />
              <div style={{ marginLeft: '20px' }}>
                <SkeletonLoading
                  height={20}
                  width={80}
                  style={{ marginTop: '12px' }}
                />
                {/* <SkeletonLoading
                  height={24}
                  width={100}
                  style={{ marginTop: '12px' }}
                /> */}
              </div>
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
};

export default BrowsePropertySkeleton;
const Wrapper = styled.div`
  padding: 10px;
  width: 100%;

  & .post {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    row-gap: 60px;
    column-gap: 20px;
    margin-bottom: 20px;
    width: 100%;
  }
`;
