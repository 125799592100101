import axios from 'axios';
import useAPI from '@/hooks/api';
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Socket, Utils } from '@shared/front';
import { RootAuthType } from '@shared/types';

export type RootType = {
  auth: RootAuthType | null;
  setAuth: React.Dispatch<React.SetStateAction<null | RootAuthType>>;
  checkAuth: () => void | Promise<void>;
};

export const RootContext = createContext<RootType>({
  auth: {},
  setAuth: () => {},
  checkAuth: () => {},
});

export const RootProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();
  const [auth, setAuth] = useState<RootAuthType | null>(null);
  const [handleCheckRequest] = useAPI();
  const [isReady, setReady] = useState(false);

  const checkAuth = async () => {
    const localUser = localStorage.getItem('user');
    const user = localUser && JSON.parse(localUser);

    if (user?.token) {
      axios.defaults.headers.Authorization = `Bearer ${user?.token}`;
      let data;
      try {
        data = await handleCheckRequest({
          method: 'post',
          url: `/v1/auth/verify-token`,
        });
      } catch (err) {}
      if (data) {
        axios.defaults.headers.Authorization = `Bearer ${data?.token}`;
        setAuth({ ...data, fullName: `${data.firstName} ${data.lastName}` });
        Socket.connectSocket();
        if (data?.onboardingStep < 4) {
          navigate('/onboarding', { replace: true });
        }
      }
    } else {
      setAuth(null);
    }
    setReady(true);
  };

  useEffect(() => {
    Utils.rootData.apiURL = localStorage.getItem('API') || process.env.API_URL;
    checkAuth();
  }, []);

  return (
    <RootContext.Provider
      value={{
        auth,
        setAuth,
        checkAuth,
      }}
    >
      {isReady ? children : null}
    </RootContext.Provider>
  );
};

export const useRoot = () => useContext(RootContext);

export default RootProvider;
