import { useRoot } from '@/RootProvider';
import useAPI from '@/hooks/api';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { StyledButton } from './styles';

const Login = ({ title }: { title: string }) => {
  const navigate = useNavigate();
  const { setAuth } = useRoot();
  const [
    handleSubmitLinkedIn,
    { loading: linkedInLoading, error: linkedInError },
  ] = useAPI();

  const handleSuccess = async data => {
    axios.defaults.headers.Authorization = `Bearer ${data.token}`;

    setAuth(data);
    await localStorage.setItem('user', JSON.stringify(data));

    if (data.onboardingStep < (data?.role === 'service' ? 5 : 4)) {
      // history.replace('/onboarding');
      navigate('/onboarding', { replace: true });
    } else {
      // history.replace('/dashboard');
      navigate('/dashboard', { replace: true });
    }
  };

  const getCodeFromWindowURL = url => {
    const popupWindowURL = new URL(url);
    return popupWindowURL.searchParams.get('code');
  };
  const handlePostMessage = async event => {
    if (event.data.type === 'code') {
      const { code } = event.data;
      const data = await handleSubmitLinkedIn({
        method: 'post',
        url: '/v1/auth/linkedin',
        data: {
          linkedInToken: code,
        },
      });

      handleSuccess(data);
    }
  };

  const showPopup = () => {
    const oauthUrl = `${'https://www.linkedin.com/oauth/v2/authorization?response_type=code'}&client_id=${
      process.env.LINKEDIN_CLIENT_ID
    }&scope=${'r_liteprofile%20r_emailaddress'}&redirect_uri=${
      process.env.LINKEDIN_REDIRECT_URI
    }`;
    const width = 450,
      height = 730,
      left = window.screen.width / 2 - width / 2,
      top = window.screen.height / 2 - height / 2;
    window.open(
      oauthUrl,
      'Linkedin',
      'menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=' +
        width +
        ', height=' +
        height +
        ', top=' +
        top +
        ', left=' +
        left,
    );
  };

  useEffect(() => {
    if (window.opener && window.opener !== window) {
      const code = getCodeFromWindowURL(window.location.href);
      window.opener.postMessage({ type: 'code', code: code }, '*');
      window.close();
    }
    window.addEventListener('message', handlePostMessage);

    return () => {
      window.removeEventListener('message', handlePostMessage);
    };
  }, []);

  return (
    <StyledButton
      onClick={showPopup}
      isLoading={linkedInLoading}
      isDisabled={linkedInLoading}
      variant="gray"
      style={{
        width: '100%',
      }}
      // block={true}
      // icon={
      //   <FontAwesomeIcon
      //     icon={['fab', 'linkedin']}
      //     color="white"
      //     style={{ marginRight: 10 }}
      //   />
      // }
      // size="large"
    >
      <FontAwesomeIcon
        icon={['fab', 'linkedin']}
        // color="blue"
        style={{ marginRight: 10 }}
      />

      {title}
    </StyledButton>
  );
};

export default Login;
