import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import useAPI from '@/hooks/api';

import Button from '@shared/web/components/Button';

import { useRoot } from '@/RootProvider';
import { toast } from 'react-toastify';
import { capitalize } from 'lodash';
import Typography from '@/newComponents/Typography';
import { Row } from '@shared/web/components/common';

const Container = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  margin-bottom: 20px;
`;
const ContractView = styled.div`
  margin-bottom: 20px;
`;

const UserAvatar = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 20px;
  margin-right: 20px;
`;

const Contracts = ({ contracts }) => {
  // @ts-ignore

  const [makeMessageRequest, { loading: messageLoading }] = useAPI();
  const { auth } = useRoot();
  const navigate = useNavigate();

  const handleMessage = async ({
    serviceProviderId,
    landlordId,
    tenantId,
  }: {
    serviceProviderId: number;
    landlordId?: number;
    tenantId?: number;
  }) => {
    const messageId = await makeMessageRequest({
      method: 'post',
      url: `/v1/common/message`,
      data: {
        serviceId: serviceProviderId,
        landlordId,
        tenantId,
      },
    });

    navigate(`/messages/${messageId}`);
  };

  return (
    <Container>
      <Typography size="md">Contracts</Typography>
      {contracts?.map(contract => (
        <ContractView key={contract.id}>
          <Row justify="space-between">
            <Row>
              <UserAvatar src={contract.serviceProvider.avatar} />
              <div>
                <Typography size="md" style={{ marginBottom: 0 }}>
                  {contract.serviceProvider.fullName}
                </Typography>
                {/* <Rate value={contract.serviceProvider.rating} /> */}
                <Typography style={{ marginBottom: 0, display: 'block' }}>
                  Contract amount: ${contract.price}
                </Typography>
                <Typography size="sm" style={{ marginBottom: 0 }}>
                  Paid amount: ${contract.paidAmount}
                </Typography>
              </div>
            </Row>
            <div>
              <Button
                onClick={() =>
                  handleMessage({
                    serviceProviderId: contract.serviceProviderId,
                    landlordId: auth.id,
                  })
                }
              >
                Message
              </Button>
              {/* {proposal.status === 'Active' && (
                          <Button
                            backgroundColor="primary"
                            onClick={() =>
                              handleHire({
                                serviceProviderId: proposal.providerId,
                                serviceId: data.id,
                                price: proposal.price,
                                proposalId: proposal.id,
                              })
                            }
                          >
                            Hire
                          </Button>
                        )} */}
            </div>
          </Row>
        </ContractView>
      ))}
    </Container>
  );
};

export default Contracts;
