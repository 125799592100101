import React from "react";
import styled from "styled-components";
import { capitalize } from "lodash";

export const TagWrapper = styled.div<{ type: string }>`
  text-align: center;
  border-radius: 500px;
  margin: 0px 0px 0px;
  display: inline-flex;
  align-items: center;
  padding: 3px 10px;
  justify-content: center;
  background-color: ${({ type, theme }) => {
    if (type) {
      return `${theme.colors.statusColors[type].bg}`;
    } else {
      return `${theme.colors.statusColors.Success.bg}`;
    }
  }};
`;
const Text = styled.span<{ textColor: string }>`
  font-weight: 600;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
  color: ${({ textColor }) => textColor || "#000"};
`;

const ReuseTag = ({ type, children, textColor = "#000" }) => {
  console.log("the type", type);
  return (
    <TagWrapper type={type}>
      <Text textColor={textColor}>{children} </Text>
    </TagWrapper>
  );
};

export default ReuseTag;
