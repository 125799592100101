import { useRoot } from '@/RootProvider';
import InputField from '@/components/InputField';
import Button from '@/newComponents/Button';
import { ButtonEndWrapperStyled } from '@/styles/shareableStyle';
import { Apis, Socket, Utils } from '@shared/front';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

type Props = {
  product: any;
  getMarketProductId: (id: string | undefined) => void;
};

const MessageOwner = ({ product, getMarketProductId }: Props) => {
  const navigate = useNavigate();
  const { id: productId } = useParams();
  const { auth } = useRoot();
  const userId = auth?.id;

  const [sendMessageLoading, setSendMessageLoading] = useState<boolean>(false);
  const createMessageIdAndSendMessage = async (message: string) => {
    const data = {
      sellerId: product?.owner?.id,
      buyerId: userId,
      chatType: 'marketPlace',
      productId: product?.id,
    };
    setSendMessageLoading(true);
    const messageId = await Utils.request(Apis.Chat.getMessageId(data));
    if (messageId) {
      const chatData = {
        message: message,
        conversationId: messageId,
        messageBy: 'buyer',
      };
      await Socket.addChat(chatData);
    }
    setSendMessageLoading(false);
    await getMarketProductId(productId);
  };

  return (
    !!auth && (
      <div>
        {userId !== product?.owner?.id &&
          (!product?.isChatCreated ? (
            <Formik
              initialValues={{
                message: 'Hi,is this available?',
              }}
              onSubmit={values => {
                createMessageIdAndSendMessage(values.message);
              }}
            >
              {({ values }) => {
                return (
                  <Form>
                    <Field
                      component={InputField}
                      name="message"
                      placeholder="Chat to the owner..."
                    />
                    <ButtonEndWrapperStyled>
                      <Button
                        isLoading={sendMessageLoading}
                        isDisabled={sendMessageLoading}
                        htmlType="submit"
                      >
                        Send
                      </Button>
                    </ButtonEndWrapperStyled>
                  </Form>
                );
              }}
            </Formik>
          ) : (
            <ButtonEndWrapperStyled style={{ flex: 1 }}>
              <Button
                style={{ width: '100%' }}
                variant="primary"
                onClick={() => navigate(`/chats/${product?.conversationId}`)}
              >
                Go to message
              </Button>
            </ButtonEndWrapperStyled>
          ))}
      </div>
    )
  );
};

export default MessageOwner;
