import * as yup from 'yup';

const schema = {
  basicInfo: yup.object().shape({
    propertyId: yup.string().required('Please select property'),
    title: yup.string().required('Please enter title'),
    desc: yup.string().required('Please enter description'),
  }),
  categories: yup.object().shape({
    categories: yup
      .array()
      .min(1, 'Please select categories')
      .required('Please select categories'),
  }),

  photos: yup.object().shape({
    images: yup
      .array()
      .min(1, 'Please upload photos')
      .required('Please upload photos'),
  }),
  pricing: yup.object().shape({
    priceType: yup.string().nullable().required('Please select pricing type'),
    price: yup.string().when('priceType', {
      is: (value: string) => value !== 'quote',
      then: yup.string().nullable().required('Please enter price'),
      otherwise: yup.string().nullable(),
    }),
  }),
};
export default schema;
