import { Checkbox, DatePicker } from "antd";
import { FormikErrors, FormikTouched } from "formik";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { ThemeType } from "@/utils/theme";
import Error from "./Error";
import { Typography } from "antd";

// const { RangePicker } = DatePicker;
export const DATE_FORMAT = "YYYY-MM-DD";

type FieldType = {
  name: string;
  value: any;
  onChange: (e: React.ChangeEvent<any>) => void;
  onBlur: (e: React.FocusEvent<any>) => void;
  [rest: string]: any;
};

type MetaType = {
  errors?: FormikErrors<object> | undefined;
  touched?: FormikTouched<object> | undefined;
  setFieldValue: Function;
};

type DateFieldType = {
  field: FieldType;
  form: MetaType;
  format?: string;
  border?: keyof typeof ThemeType.sizes;
  placeholder: string;
  background?: string;
  picker: string;
  onChange?: any;
  label?: string;
};

type StyledDateFieldType = {
  border: keyof typeof ThemeType.sizes;
  hasError: boolean;
  background: string;
};

const StyledDateField = styled.div<StyledDateFieldType>`
  .ant-picker-dropdown {
    z-index: 9999999;
  }

  .ant-picker {
    background: ${({ background }) => background};
    z-index: 9999999;
    width: 100%;
    padding: 15px 15px;
    border: 1px solid #e1e1e1;
    backdrop-filter: blur(40px);

    border-color: white;
    height: 40px;
  }
  /* .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: ${({ background }) => background};
    border: 1px solid #e1e1e1;
    border-radius: ${({ border, theme }) => `${theme.sizes[border]}px`};
    border-color: ${({ hasError }) => (hasError ? "red" : "white")};
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 50px;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-item {
    padding: 4px;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding: 4px;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
    padding-top: 4px;
  }

  margin-bottom: ${({ theme }) => `${theme.sizes["sm"]}px`}; */
`;

const DatePickerField: React.FC<DateFieldType> = ({
  field: { name, value, onBlur, onChange: fomikHandleChange },
  form: { errors, touched, setFieldValue, submitCount },
  border = "md",
  placeholder = "",
  format,
  picker,
  onChange,
  background = "rgb(242, 242, 242, 1)",
  label,
}: DateFieldType) => {
  const handleChange = (date: any, dataString: any) => {
    setFieldValue(name, dataString);
  };
  console.log("the errorsw", errors, touched);
  const hasError = errors?.[name] && (touched?.[name] || !!submitCount);

  return (
    <StyledDateField
      border={border}
      placeholder={placeholder}
      hasError={hasError}
      background={background}
    >
      <Typography.Text>{label}</Typography.Text>
      <DatePicker
        onBlur={onBlur}
        name={name}
        onChange={onChange || handleChange}
        format={DATE_FORMAT}
      />
      {hasError && <Error message={errors && errors?.[name]} />}
    </StyledDateField>
  );
};
export default DatePickerField;
