import Modal from '@/newComponents/Modal';
import { Formik } from 'formik';
import * as yup from 'yup';
import React, { useEffect, useState } from 'react';
import FileUploader from '@shared/web/components/FileUploader';
import useAPI from '@/hooks/api';
import { useRoot } from '@/RootProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AddButton,
  SaveButtonContainer,
  StyledButton,
  Title,
  UnStyledButton,
} from '../index.styles';

const Certificate = () => {
  const { auth, checkAuth } = useRoot();
  const [uploadFiles, setUploadFiles] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [handleSubmitRequest, { loading: changeRequestLoading }] = useAPI();
  const [getCertificateRequest, { loading, data: certificate }] = useAPI();

  const getCertificate = async () => {
    await getCertificateRequest({
      method: 'get',
      url: `/v1/services/certifications`,
    });
  };
  useEffect(() => {
    getCertificate();
  }, []);
  const handleSubmit = async values => {
    let formData = new FormData();
    if (values.url) {
      formData.append('images', values.url, 'url');
    }
    if (values.identity) {
      formData.append('images', values.identity, 'identity');
    }

    await handleSubmitRequest({
      method: 'post',
      url: `/v1/auth/updateCertification`,
      data: formData,
    });
    checkAuth();
    getCertificate();
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        title="Upload Certification"
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <Formik
          initialValues={{
            avatar: certificate?.url,
          }}
          enableReinitialize
          onSubmit={handleSubmit}
          validationSchema={yup.object().shape({
            avatar: yup
              .mixed()
              .required('Please upload a picture of your certificate.'),
          })}
        >
          {({
            isSubmitting,
            errors,
            touched,
            values,
            setFieldValue,
            handleSubmit,
            enableReinitialize,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <FileUploader
                  accept="image/jpeg, image/jpg, image/png"
                  onDelete={() => {
                    setFieldValue('avatar', null);
                  }}
                  onFileUpload={files => setFieldValue('avatar', files[0])}
                  data={values.avatar}
                />

                <SaveButtonContainer>
                  <StyledButton
                    htmlType="submit"
                    radius="round"
                    isDisabled={
                      !values?.avatar || isSubmitting || changeRequestLoading
                    }
                    isLoading={changeRequestLoading || isSubmitting}
                  >
                    Upload
                  </StyledButton>
                </SaveButtonContainer>
              </form>
            );
          }}
        </Formik>
      </Modal>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title variant="p">Certification</Title>
        <AddButton variant="gray" onClick={() => setIsModalOpen(true)}>
          <FontAwesomeIcon icon="fa-solid fa-plus" />
        </AddButton>
        {certificate?.url}
      </div>
    </>
  );
};

export default Certificate;
