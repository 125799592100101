import React, { useState, useEffect } from 'react';
import { Popover } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import { Apis, Socket, Store, Utils } from '@shared/front';
import { useSelector } from 'react-redux';
import { Common } from '@shared/common';
import { Link } from 'react-router-dom';
import Typography from '../Typography';
import { devices } from '@/utils/theme';
import { useRoot } from '@/RootProvider';

export const NotificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  min-height: 200px;
  width: 360px;
  min-width: 160px;
  flex-shrink: 0;
  max-height: 400px;
  overflow-y: auto;

  &.scroll {
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
      border-radius: 30px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #aaa;
      border-radius: 20px;
    }
  }
`;

export const NotificationBox = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  padding-bottom: 5px;
  cursor: pointer;
  position: relative;

  &:last-child {
    border-bottom: none;
  }
`;

const Badge = styled.div`
  height: 20px;
  width: 20px;
  background-color: red;
  color: white;
  border-radius: 15px;
  position: absolute;
  top: -10px;
  left: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const Notify = styled.div`
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 15px;
  position: absolute;
  bottom: 10px;
  right: 5px;
`;

const NotificationCount = styled(Typography)`
  color: #fff;
  font-size: 12px;
`;

const DateTypography = styled(Typography)`
  font-size: 10px;
  color: #555;

  @media ${devices.tablet} {
    font-size: 12px;
  }
`;

const TimeTypography = styled(Typography)`
  font-size: 10px;
  color: #949494;
`;

const NotificationTitle = styled(Typography)`
  font-size: 12px;
  color: #333;

  @media ${devices.tablet} {
    font-size: 14px;
  }
`;

const Notification = () => {
  const navigate = useNavigate();
  const { auth } = useRoot();
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = () => {
    if (auth?.deleteRequested) {
      return;
    }
    setVisible(!visible);
  };

  const notifications: any = useSelector<any>(Store.Select.notifications);
  const unReadNotification: any = useSelector<any>(
    Store.Select.unReadNotifications,
  );
  const groupNotifications = Common.groupNotifications(
    notifications.filter((x: any) => x.authId === auth?.id),
  );
  const readNotification = async (id: number) => {
    await Utils.request(
      Apis.Notification.updateNotification(
        {
          isRead: true,
        },
        id,
      ),
    );
    updateReadNotifications(id);
  };

  const updateReadNotifications = (notId: number) => {
    const updatedNotifications = notifications.map((nt: any) => {
      if (notId === nt.id) {
        return {
          ...nt,
          isRead: true,
        };
      }
      return nt;
    });
    Store.store.dispatch(
      Store.Actions.set('notifications', updatedNotifications),
    );
    Store.store.dispatch(
      Store.Actions.set(
        'unReadNotifications',
        unReadNotification.filter((unNot: any) => unNot?.id !== notId),
      ),
    );
  };

  useEffect(() => {
    Socket.getUserNotifications({
      page: 0,
      limit: 10,
    });
    Socket.getUserUnreadNotifications();
  }, []);

  const unReadNotificationsCount = unReadNotification.filter(
    (x: any) => x.authId === auth?.id,
  ).length;

  const content = (
    <>
      <NotificationWrapper className="scroll">
        {notifications?.length > 0 ? (
          Object.entries(groupNotifications).map(([date, items], index) => {
            return (
              <div key={index}>
                <DateTypography>{date}</DateTypography>
                {items?.map((item: any) => {
                  const ago = moment.utc(item.createdAt).local().fromNow();
                  return (
                    <NotificationBox
                      onClick={() => {
                        readNotification(item?.id);
                        handleVisibleChange();
                        navigate(item?.webRoute);
                      }}
                      key={item?.id}
                    >
                      <NotificationTitle>{item.title}</NotificationTitle>
                      <TimeTypography>{ago}</TimeTypography>
                      {!item.isRead && <Notify />}
                    </NotificationBox>
                  );
                })}
              </div>
            );
          })
        ) : (
          <Typography
            style={{
              marginTop: '20px',
              textAlign: 'center',
            }}
          >
            No new notifications!
          </Typography>
        )}
      </NotificationWrapper>
      <div
        style={{
          textAlign: 'center',
          padding: '5px 0px',
          borderTop: '1px solid #f0f0f0',
        }}
      >
        <Link to="/notifications" onClick={() => handleVisibleChange()}>
          View all notifications
        </Link>
      </div>
    </>
  );

  return (
    <Popover
      style={{ width: '344' }}
      content={content}
      trigger="click"
      open={visible}
      onOpenChange={handleVisibleChange}
      placement="bottomLeft"
    >
      <div style={{ position: 'relative' }}>
        {unReadNotification?.length > 0 && (
          <Badge>
            <NotificationCount>
              {unReadNotificationsCount || 0}
            </NotificationCount>
          </Badge>
        )}
        <FontAwesomeIcon
          icon="bell"
          size="lg"
          color="#B3B3B3"
          style={{ marginRight: '10px' }}
        />
      </div>
    </Popover>
  );
};
export default Notification;
