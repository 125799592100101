import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
* {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {

  overflow-y: inherit;
  box-sizing: border-box;
  font-size: 14px;
  font-family: 'Poppins';
  background-color: white;
  scroll-behavior:smooth;
}

  body {
    font-family: 'Poppins','Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  overflow-x:hidden;
  color:#333;

  }

  #root {
    min-height: 100%;
    max-width: 100%;
  }
  
  a{
    text-decoration:none;
  }

  p,
  label {
    line-height: 1.5em;
  }

  input, select {
    font-family: inherit;
    font-size: inherit;
  }


  th.ant-table-cell {
    font-weight: 400 !important;
    font-size: 12px;
    color: #8C94A2;
  }


  .highcharts-container, .highcharts-container svg {
     width: 100% !important;
}


 .ant-tabs-tab-active .ant-tabs-tab-btn{
  color:#2857FF !important;
}
.ant-tabs-ink-bar {
  background-color:#2857FF !important;
}
.ant-tabs-tab:hover {
  color:#2857FF !important;
}
.ant-tabs-tab:hover {
  color:#2857FF !important;
}

.ant-switch-checked {
    background-color: #2857FF !important;
}


a {
  color:#2857FF !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.ant-scroll-number-only {
  transition: 0s;
}
.ant-popover {
  position: fixed;
} 
.ant-typography+h4{
margin-top:0px !important;
}
.ant-picker-dropdown {
    z-index: 9999999;
  }
`;
