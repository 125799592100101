import InputField from '@shared/web/components/InputField';
import TextField from '@shared/web/components/TextField';
import MaskInputField from '@shared/web/components/MaskInputField';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import useAPI from '@/hooks/api';
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import validation from './validation';
import { Constants } from '@shared/common';
import { Apis, Hooks } from '@shared/front';
import DatePickerField from '@shared/web/components/DatePicker';
import styled from 'styled-components';
import {
  ButtonsContainer,
  StyledButton,
  StyledLabel,
  Title,
} from './index.styles';

const FormikSelectField = styled(Field)`
  display: block;
  width: 100%;
  height: 40px;
  margin: 6px 0px;
  outline: none;
  border: 1px solid ${({ theme }) => theme.colors.border};
  background-color: ${({ theme }) => theme.colors.lightBG};
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }

  &.disabled-hidden-option {
    color: transparent;
    filter: blur(2px);
    border: 1px solid red;
  }
`;

const StyledOption = styled.option`
  text-align: center;
`;

const ErrorMessageStyle = styled(ErrorMessage)`
  color: red;
`;

const BasicInfo = ({ initialValues, onSubmitStep }) => {
  const navigate = useNavigate();
  const formikRef = useRef<FormikProps<any>>(null);
  const [handleGetCategories, { data: categories }] = Hooks.useAPI();
  const [
    handleGetSubCategories,
    { data: subCategories, loading: loadingSubCategories },
  ] = Hooks.useAPI();
  const [handleSubmitRequest, { loading, error }] = useAPI();
  const handleSubmitForm = async (values, isDraft?: boolean) => {
    const url = '/v1/marketPlace/products';
    const updateUrl = '/v1/marketPlace/products';
    try {
      const data = await handleSubmitRequest({
        method: !!initialValues.id ? 'patch' : 'post',
        url: !!initialValues.id ? `${updateUrl}/${initialValues.id}` : url,
        data: values,
      });

      onSubmitStep({ data, isDraft });
    } catch (error) {
      console.log('Error submitting form', error);
    }
  };
  const getCategories = () => {
    handleGetCategories(Apis.Market.getMarketCategory());
  };

  useEffect(() => {
    getCategories();
  }, []);

  const getSubCategories = (categoryId: number) => {
    handleGetSubCategories(Apis.Market.getMarketSubCategory(categoryId));
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmitForm(values);
      }}
      validationSchema={validation.basicInfo}
      enableReinitialize
    >
      {({
        isSubmitting,
        enableReinitialize,
        errors,
        touched,
        values,
        setFieldValue,
        handleSubmit,
        setSubmitting,
        handleChange,
      }) => {
        return (
          <div>
            <Form>
              <Title variant="h1">Basic Information</Title>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '12px',
                }}
              >
                <div>
                  <StyledLabel>Title</StyledLabel>
                  <Field
                    component={InputField}
                    name="title"
                    placeholder="Title of product"
                  />
                </div>
                <div>
                  <StyledLabel>Select Listing</StyledLabel>
                  <FormikSelectField
                    component={'select'}
                    name="listType"
                    label="Listing"
                    onChange={e => {
                      const listType = e.target.value;
                      if (listType === 'Buy') {
                        setFieldValue('listType', 'Buy');
                        setFieldValue('listDesc', 'I want to buy this product');
                      } else {
                        setFieldValue('listType', 'Sell');
                        setFieldValue(
                          'listDesc',
                          'I want to sell this product',
                        );
                      }
                    }}
                  >
                    <StyledOption value="" disabled selected>
                      Choose an Listing option
                    </StyledOption>

                    {Constants?.productDropdown?.productListing()?.map(item => {
                      return (
                        <StyledOption label={item.label} value={item.value}>
                          {item.value}
                        </StyledOption>
                      );
                    })}
                  </FormikSelectField>
                  <ErrorMessageStyle name="listType" component="div" />
                </div>

                {values.listType !== '' && (
                  <div>
                    <StyledLabel>Select Listing Descriptions</StyledLabel>
                    <FormikSelectField
                      component={'select'}
                      name="listDesc"
                      label="Listing Descriptions"
                    >
                      <StyledOption
                        label={values.listDesc}
                        value={values.listDesc}
                      >
                        {values?.listDesc}
                      </StyledOption>
                    </FormikSelectField>
                  </div>
                )}

                <div>
                  <StyledLabel>Category</StyledLabel>
                  <FormikSelectField
                    component={'select'}
                    label="Category"
                    name="categoryId"
                    onChange={(e: any) => {
                      const value = e.target.value;
                      setFieldValue('categoryId', value);
                      getSubCategories(value);
                    }}
                  >
                    <StyledOption value="" disabled selected>
                      Select an option
                    </StyledOption>
                    {!!categories?.length
                      ? categories?.map((item: any) => {
                          return (
                            <StyledOption value={item.id} label={item.title}>
                              {item?.title}
                            </StyledOption>
                          );
                        })
                      : []}
                  </FormikSelectField>
                  <ErrorMessageStyle name="categoryId" component="div" />
                </div>
                <div>
                  <StyledLabel>Sub Category</StyledLabel>
                  <FormikSelectField
                    component={'select'}
                    label="Sub Category"
                    name="subCategoryId"
                    onChange={(e: any) => {
                      const value = e.target.value;
                      setFieldValue('subCategoryId', value);
                    }}
                  >
                    <StyledOption value="" disabled selected>
                      Select an option
                    </StyledOption>
                    {!!subCategories?.length
                      ? subCategories?.map((item: any) => {
                          return (
                            <StyledOption value={item.id} label={item.title}>
                              {item?.title}
                            </StyledOption>
                          );
                        })
                      : []}
                  </FormikSelectField>
                </div>
                <div>
                  <StyledLabel>Price</StyledLabel>
                  <Field
                    component={MaskInputField}
                    name="price"
                    decimalSeparator="."
                    displayType="input"
                    type="text"
                    thousandSeparator={true}
                    prefix={'€'}
                    setFieldValue={setFieldValue}
                  />
                </div>

                <div>
                  <StyledLabel>Condition</StyledLabel>
                  <FormikSelectField
                    component={'select'}
                    name="condition"
                    label="Condition"
                  >
                    <StyledOption value="" disabled selected>
                      Choose one condition
                    </StyledOption>
                    {Constants.productDropdown
                      .productCondtionOptions()
                      ?.map((item: any) => {
                        return (
                          <StyledOption label={item.label} value={item.value}>
                            {item.value}
                          </StyledOption>
                        );
                      })}
                  </FormikSelectField>
                  <ErrorMessageStyle name="condition" component="div" />
                </div>

                <div>
                  <StyledLabel>Delivery</StyledLabel>
                  <FormikSelectField
                    component={'select'}
                    name="delivery"
                    label="Delivery"
                    defaultValue=""
                  >
                    <StyledOption value="" disabled selected>
                      Choose a delivery option
                    </StyledOption>
                    {Constants.productDropdown
                      .productDeliveryOptions()
                      ?.map(item => {
                        return (
                          <StyledOption label={item.label} value={item.value}>
                            {item.value}
                          </StyledOption>
                        );
                      })}
                  </FormikSelectField>
                  <ErrorMessageStyle name="delivery" component="div" />
                </div>

                <div>
                  <StyledLabel>Negotiable</StyledLabel>
                  <FormikSelectField
                    component={'select'}
                    name="negotiable"
                    label="Negotiable"
                  >
                    <StyledOption value="" disabled selected>
                      Choose one negotiation
                    </StyledOption>
                    {Constants.productDropdown
                      .productNegotiable()
                      ?.map(item => {
                        return (
                          <StyledOption label={item.label} value={item.value}>
                            {item.value}
                          </StyledOption>
                        );
                      })}
                  </FormikSelectField>
                  <ErrorMessageStyle name="negotiable" component="div" />
                </div>
                <div>
                  <StyledLabel>Ad Expiry Date</StyledLabel>
                  <Field name="adsExpiry" component={DatePickerField} />
                </div>
                <StyledLabel>Description</StyledLabel>
                <Field component={TextField} name="desc" />
              </div>
              <ButtonsContainer>
                <StyledButton
                  variant="gray"
                  radius="round"
                  onClick={() => navigate('/mymarketplace')}
                >
                  Cancel
                </StyledButton>
                <div style={{ display: 'flex', gap: '20px' }}>
                  <StyledButton
                    onClick={() => {
                      handleSubmitForm(values, true);
                    }}
                    radius="round"
                    variant="gray"
                    isDisabled={loading}
                    isLoading={loading}
                  >
                    Save as draft
                  </StyledButton>
                  <StyledButton
                    type="submit"
                    radius="round"
                    isDisabled={loading}
                    isLoading={loading}
                  >
                    Save
                  </StyledButton>
                </div>
              </ButtonsContainer>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default BasicInfo;
