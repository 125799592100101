import { ReturnType } from "./types";
export default {
  getBookmark: (): ReturnType => {
    return {
      method: "get",
      url: "/v1/common/bookmarks",
      config: {
        store: {
          action: "set",
          key: "bookmarks",
        },
      },
    };
  },

  createBookmark: (obj: any): ReturnType => {
    return {
      method: "post",
      url: "/v1/common/bookmarks",
      data: obj,
      config: {
        successMsg: "Property added to your watchlist",
        store: {
          action: "update",
          key: "bookmarks",
        },
      },
    };
  },

  deleteBookmark: (obj: any): ReturnType => {
    return {
      method: "delete",
      url: `/v1/common/bookmarks`,
      data: obj,
      config: {
        successMsg: "Property removed from watchlist",
      },
    };
  },
  checkBookmark: (action: "set" | "update"): ReturnType => {
    return {
      method: "get",
      url: `/v1/common/check-bookmark`,
      config: {
        store: {
          action: action || "set",
          key: "myBookmarks",
        },
      },
    };
  },
};
