import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import Typography from './Typography';

// Icons
import SuccessIcon from './assets/success-icon-filled.svg';
import InfoIcon from './assets/info-icon-filled.svg';
import WarningIcon from './assets/warning-icon-filled.svg';
import ErrorIcon from './assets/error-icon-filled.svg';

type AlertType = 'info' | 'success' | 'warning' | 'error';

interface IAlertProps {
  description: string;
  type?: AlertType;
  showIcon?: boolean;
  className?: string;
  style?: CSSProperties;
}

const Alert = ({
  description,
  type = 'info',
  showIcon,
  className,
  style,
  ...rest
}: IAlertProps) => {
  return (
    <Container
      $type={type}
      className={className}
      style={{ ...style }}
      {...rest}
    >
      {showIcon && <Icon src={getIcon(type)}></Icon>}
      <Description variant="p">{description}</Description>
    </Container>
  );
};

export default Alert;

const Container = styled.div<{ $type: AlertType }>`
  padding: 14px 25px;
  display: flex;
  align-items: center;
  gap: 18px;
  background-color: ${({ $type }) => getBGColor($type)};
`;

const Icon = styled.img`
  height: 30px;
  width: 30px;
`;

const Description = styled(Typography)`
  font-size: 12px;
  line-height: 25px;
`;

const getBGColor = (type: AlertType) => {
  switch (type) {
    case 'info':
      return '#3498db2b';
    case 'success':
      return '#07bc0c2b';
    case 'warning':
      return '#f1c40f2b';
    case 'error':
      return '#e74c3c2b';
  }
};

const getIcon = (type: AlertType) => {
  switch (type) {
    case 'info':
      return InfoIcon;
    case 'success':
      return SuccessIcon;
    case 'warning':
      return WarningIcon;
    case 'error':
      return ErrorIcon;
  }
};
