import React from 'react';
import styled from 'styled-components';

type AvatarSizeType = 'sm' | 'md' | 'lg' | number;

type Props = {
  avatar: string;
  size?: AvatarSizeType;
};

const Avatar = ({ avatar, size = 'md', ...restProps }: Props) => {
  return <AvatarStyled src={avatar} alt="Avatar" size={size} />;
};

export default Avatar;

const AvatarStyled = styled.img<{ size: AvatarSizeType }>`
  height: ${({ size }) => getSize(size)};
  width: ${({ size }) => getSize(size)};
  object-fit: cover;
  border-radius: 50%;
`;

const getSize = (size: AvatarSizeType) => {
  if (typeof size === 'number') {
    return size + 'px';
  }
  switch (size) {
    case 'sm': {
      return '36px';
    }
    case 'md': {
      return '48px';
    }
    case 'lg': {
      return '56px';
    }
    default: {
      return '56px';
    }
  }
};
