import React, { CSSProperties, useRef, useState } from 'react';
import styled from 'styled-components';
import TriangleDown from './assets/triangle-down.svg';

const DropdownMenu = ({
  items,
  onItemClick,
  className,
  style,
  isModal = false,
  modalState,
  setModalState,
  params,
  setParams,
  expandedItem,
  setExpandedItem,
  hasMore,
  setHasMore,
}: any) => {
  const heightRef = useRef<HTMLDivElement>(null);

  const toggleItem = (itemId: string | number) => {
    if (expandedItem === itemId) {
      setExpandedItem(null);
    } else {
      setExpandedItem(itemId);
    }
  };

  return (
    <MenuContainer className={className} style={{ ...style }}>
      {items?.map(item => (
        <React.Fragment key={item.id}>
          <TitleButton
            onClick={() => {
              toggleItem(item.id);
              if (isModal) {
                setHasMore(true);
                setModalState((prev: any) => ({
                  ...prev,
                  page: 0,
                  categoryId: item?.id,
                  subCategoryId: undefined,
                  item: { ...item, category: item?.title },
                }));
              } else {
                onItemClick({
                  item: { ...item, category: item?.title },
                });
              }
            }}
            $isActive={
              isModal
                ? modalState?.categoryId === item?.id
                : item.id === params.categoryId
            }
          >
            {item?.title}
            <TriangleIcon
              src={TriangleDown}
              $isExpanded={expandedItem === item.id}
              alt="dropdown pointer"
            />
          </TitleButton>
          <DropdownContainer
            style={{
              maxHeight:
                expandedItem === item.id ? heightRef.current?.clientHeight : 0,
            }}
          >
            <div ref={heightRef}>
              {item?.subCategories?.map(subItem => {
                return (
                  <SubItemButton
                    key={subItem.id}
                    isActive={
                      isModal
                        ? subItem.id === modalState?.subCategoryId
                        : subItem.id === params.subCategoryId
                    }
                    onClick={() => {
                      if (isModal) {
                        setHasMore(true);
                        setModalState((prev: any) => ({
                          ...prev,
                          page: 0,
                          categoryId: subItem?.categoryId,
                          subCategoryId: subItem?.id,
                          subItem: { ...subItem, category: item?.title },
                        }));
                      } else {
                        onItemClick({
                          subItem: { ...subItem, category: item?.title },
                        });
                      }
                    }}
                  >
                    {subItem.title}
                  </SubItemButton>
                );
              })}
            </div>
          </DropdownContainer>
        </React.Fragment>
      ))}
    </MenuContainer>
  );
};

export default DropdownMenu;

export interface IMenuProps {
  items: {
    id: string | number;
    label: string;
    subItems?: {
      id: string | number;
      label: string;
    }[];
  }[];
  onItemClick: () => void;
  isModal: boolean;
  modalState?: any; //typescript
  setModalState?: any;
  setParams?: any;
  className?: string;
  params: any;
  style?: CSSProperties;
  expandedItem?: any;
  setExpandedItem?: any;
}

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleButton = styled.button<{ $isActive: boolean }>`
  border: none;
  outline: none;
  padding: 0 5px;
  // background-color: transparent;
  background-color: ${({ $isActive }) => ($isActive ? '#e0e0e0' : 'white')};

  height: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 10px;
`;

const TriangleIcon = styled.img<{ $isExpanded: boolean }>`
  transition: transform 0.4s ease;
  transform: ${({ $isExpanded }) => ($isExpanded ? 'rotate(180deg)' : '')};
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  transition: max-height 0.4s ease;
  overflow: hidden;
  padding-left: 15px;
`;

const SubItemButton = styled.button<{ isActive: boolean }>`
  border: none;
  outline: none;
  padding: 0 5px;
  background-color: transparent;
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 6px;
  background-color: ${({ isActive }) => (isActive ? '#e0e0e0' : 'white')};

  &:hover {
    background-color: #e0e0e0;
  }
`;
