import * as yup from "yup";
import moment from "moment";
import { phoneRegExp } from "../../common/constants/validation";
interface GenerateForm {
  Components: any;
  initialValues: any;
}
interface FieldProps {
  label?: string;
  fieldKey?: string;
  type?: string;
  activeValue?: string | null;
  options?: any;
  Component?: any;
  dropdown?: any;
  placeholder?: string;
  editable?: boolean;
}

export function generateSteps({ Components, initialValues }: GenerateForm) {
  const steps: any = [
    {
      label: "The Agreement",
      lists: [
        'The Landlord and Tenant agree to enter into a residential tenancy agreement on the terms set out below (the "Agreement") / (the "Tenancy")',
      ],
      fields: <Array<FieldProps>>[
        {
          label: "Property Address",
          fieldKey: "propertyAddress",
          type: "input",
          Component: Components.Input,
          placeholder: "Property Address",
          editable: false,
        },
        {
          label: "Full Name",
          fieldKey: "tenantFullName",
          type: "input",
          Component: Components.Input,
          placeholder: "Full Name",
          editable: false,
        },
        {
          label: "Phone No",
          fieldKey: "tenantPhoneNo",
          type: "input",
          Component: Components.Input,
          placeholder: "Phone No",
          editable: false,
        },
        {
          label: "Email",
          fieldKey: "tenantEmail",
          type: "input",
          Component: Components.Input,
          placeholder: "Email",
          editable: false,
        },
      ],
      validations: yup.object().shape({
        propertyAddress: yup
          .string()
          .min(2, "Too Short!")
          .required("Property Address is required"),
        tenantFullName: yup
          .string()
          .min(2, "Too Short!")
          .required("Full Name is required"),
        tenantPhoneNo: yup.string().required("Phone No  required"),
        tenantEmail: yup
          .string()
          .email("Invalid email")
          .required("Email is required"),
      }),
    },
    {
      label: "Lease Term Data",
      fields: <Array<FieldProps>>[
        {
          label: "",
          fieldKey: "leaseTermData",
          type: "radioBoxGroup",
          activeValue: initialValues["leaseTermData"] ?? null,
          options: ["Fixed Term", "Month to Month", "Year to Year"],
          Component: Components.RadioBoxGroup,
        },
        initialValues["leaseTermData"] &&
        initialValues["leaseTermData"] !== "Fixed Term" && {
          label: "Start Date",
          fieldKey: "startLeaseTermDate",
          type: "date",
          placeholder: "Start Date",
          Component: Components.Date,
          editable: true,
        },
        initialValues["leaseTermData"] &&
        initialValues["leaseTermData"] == "Fixed Term" && {
          label: "End Date",
          fieldKey: "endLeaseTermDate",
          type: "date",
          placeholder: "End Date",
          Component: Components.Date,
          editable: true,
        },
      ].filter(Boolean),
      validations: yup.object().shape({
        leaseTermData: yup.string().required("Please select one"),
        startLeaseTermDate:
          initialValues["leaseTermData"] !== "Fixed Term"
            ? yup.string().required("Start Date is required")
            : yup.string().nullable(),
        endLeaseTermDate:
          initialValues["leaseTermData"] === "Fixed Term"
            ? yup.string().required("End Date is required")
            : yup.string().nullable(),
      }),
    },
    {
      label: "Rent Payment",
      fields: <Array<FieldProps>>[
        {
          label: "Rent Amount",
          fieldKey: "rentAmount",
          type: "input",
          Component: Components.Input,
          placeholder: "Rent Amount",
        },
        {
          label: "Date of Payment",
          fieldKey: "rentPaymentTime",
          type: "radioBoxGroup",
          activeValue: initialValues["rentPaymentTime"] ?? null,
          options: ["Weekly", "Every Two weeks", "Monthly"],
          Component: Components.RadioBoxGroup,
        },
      ],
      validations: yup.object().shape({
        rentAmount: yup.number().typeError("Please enter valid number").required("Rent Amount is required"),
        rentPaymentTime: yup.string().required("Please select one"),
      }),
    },
    {
      label: "Annual Rent Review",
      fields: <Array<FieldProps>>[
        {
          label: "",
          fieldKey: "annualRentReview",
          type: "radioBoxGroup",
          activeValue: initialValues["annualRentReview"] ?? null,
          options: ["Yes", "No"],
          Component: Components.RadioBoxGroup,
        },
      ].filter(Boolean),
      validations: yup.object().shape({
        annualRentReview: yup.string().required("Please select one"),
      }),
    },
    {
      label: "Security Deposit",
      fields: <Array<FieldProps>>[
        {
          label: "Security deposit amount",
          fieldKey: "securityDepositAmount",
          type: "input",
          Component: Components.Input,
          placeholder: "Security Deposit Amount",
        },
      ].filter(Boolean),
      validations: yup.object().shape({
        securityDepositAmount: yup.number().required("Please enter the amount"),
      }),
    },
    {
      label: "Utilities Details",
      fields: <Array<FieldProps>>[
        {
          label: "who will be responsible for ongoing utility costs ? ",
          fieldKey: "responsibleForGoingUtilityCosts",
          type: "radioBoxGroup",
          activeValue: initialValues["responsibleForGoingUtilityCosts"] ?? null,
          options: ["Tenant pays", "Landlord pays"],
          Component: Components.RadioBoxGroup,
        },
      ],
      validations: yup.object().shape({
        responsibleForGoingUtilityCosts: yup
          .string()
          .required("Please select one"),
      }),
    },
    {
      label: "Agreement Date",
      fields: [
        {
          label: "Start Date",
          fieldKey: "startDate",
          type: "date",
          Component: Components.Date,
          placeholder: "Select Start Date",
        },
        {
          label: "End Date",
          fieldKey: "endDate",
          type: "date",
          Component: Components.Date,
          placeholder: "Select Days",
        },
      ],
      validations: yup.object().shape({
        startDate: yup.string().required("Start Date is required"),
        endDate: yup.string().required("End Date is required"),
      }),
    },
    {
      label: "Payment Type",
      fields: <Array<FieldProps>>[
        {
          label: "Choose Payment Type",
          fieldKey: "paymentMood",
          type: "radioBoxGroup",
          activeValue: initialValues["paymentMood"] ?? null,
          options: ["Manual", "System"],
          Component: Components.RadioBoxGroup,
        },
        // initialValues["paymentMethod"] === "Via Bank" && {
        //   label: "Bank Name",
        //   fieldKey: "bankName",
        //   fiedType: "input",
        //   Component: Components.Input,
        //   placeholder: "Bank Name",
        // },
        // initialValues["paymentMethod"] === "Via Bank" && {
        //   label: "Account Holder Name",
        //   fieldKey: "accountHolderName",
        //   fiedType: "input",
        //   Component: Components.Input,
        //   placeholder: "Account Holder Name",
        // },
        // initialValues["paymentMethod"] === "Via Bank" && {
        //   label: "IBAN",
        //   fieldKey: "iban",
        //   fiedType: "input",
        //   Component: Components.Input,
        //   placeholder: "IBAN",
        // },
        // initialValues["paymentMethod"] === "Via Bank" && {
        //   label: "BIC",
        //   fieldKey: "bic",
        //   fiedType: "input",
        //   Component: Components.Input,
        //   placeholder: "BIC",
        // },
      ].filter(Boolean),

      validations: yup.object().shape({
        paymentMood: yup.string().required("Please select one"),
      }),
    },
  ];

  return steps;
}
