import Typography from '@/newComponents/Typography';
import Button from '@/newComponents/Button';
import useAPI from '@/hooks/api';
import { capitalize } from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useRoot } from '@/RootProvider';
import numeral from 'numeral';
import moment from 'moment';
import ImageDescWrapper from '@shared/web/components/ListItem/Wrapper';
import { devices } from '@/utils/theme';
import ListDescription from '@shared/web/components/ListItem/ListDescription';
import { Row } from '@shared/web/components/common';

const Container = styled.div`
  flex-direction: column;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  @media ${devices.mobileSM} {
    flex-direction: row;
  }
`;
const Content = styled.div`
  padding: 10px 20px;
  flex: 1;
`;

const Actions = styled.div`
  margin-left: auto;
  > button {
    display: block;
    width: 100px;
    margin: 4px 0 !important;
  }
`;

const PropertyView = ({ contract, refreshData }) => {
  const { auth } = useRoot();
  const [visible, setVisible] = React.useState(false);
  const navigate = useNavigate();
  const [deletePropertyRequest, { loading: deleteLoading }] = useAPI();

  // console.log('the contract', contract);

  const handleDeleteRequest = async () => {
    await deletePropertyRequest({
      method: 'delete',
      url: `/v1/services/${contract.id}`,
    });
    refreshData();
    setVisible(false);
  };

  return (
    <Container onClick={() => navigate(`/jobContracts/${contract.id}`)}>
      <ImageDescWrapper>
        <ListDescription onClick={() => {}}>
          <Row justify="space-between">
            <div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  minWidth: '250px',
                  justifyContent: 'space-between',
                  marginBottom: '12px',
                }}
              >
                <Typography
                  variant="p"
                  color="#1F1F1F"
                  style={{ fontSize: '16px' }}
                >
                  {contract?.service?.title}
                </Typography>

                <Button variant="tag" bgColor="#00DE90" radius="round">
                  {' '}
                  {capitalize(contract?.service?.priceType)}
                </Button>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                  marginBottom: '20px',
                }}
              >
                <Typography>
                  <span style={{ color: '#333' }}>Contract rate</span>: €
                  {numeral(contract.price).format('0,0.00')}
                </Typography>
                <Typography>
                  <span style={{ color: '#333' }}>Paid amount </span>: €
                  {numeral(contract.paidAmount).format('0,0.00')}
                </Typography>
                <Typography>
                  <span style={{ color: '#333' }}>Start at:</span>{' '}
                  {moment.utc(contract.createdAt).local().fromNow()}
                </Typography>
              </div>
              <div>
                <Button
                  variant="tag"
                  radius="round"
                  bgColor={
                    contract?.status == 'Completed' ? '#43BF57' : '#00DE90'
                  }
                >
                  {capitalize(contract.status)}
                </Button>
              </div>

              <Row>
                {contract?.service?.categories?.map(category => (
                  <Button variant="tag">{category}</Button>
                ))}
              </Row>
            </div>
          </Row>
        </ListDescription>
      </ImageDescWrapper>
      {/* <Actions>
        {contract.status !== 'draft' && (
          <Button
            type="text"
            color="black"
            onClick={() => navigate(`/jobContracts/${contract.id}`)}
          >
            View
          </Button>
        )}
      </Actions> */}
    </Container>
  );
};

export default PropertyView;
