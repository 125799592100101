import bookingSteps from './bookinSteps';

export default {
  bookingSteps,
};

export const TRANSACTION_TYPE = {
  Rent: 'Rent',
  'Security Deposit': 'Security Deposit',
  Service: 'Service',
  Refund: 'Refund',
};
