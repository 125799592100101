export const productCondtionOptions = () => {
  return [
    {
      label: "Brand New",
      value: "Brand New",
    },
    {
      label: "Used",
      value: "Used",
    },
    {
      label: "Like New",
      value: "Like New",
    },
  ];
};
export const productDeliveryOptions = () => {
  return [
    {
      label: "Available",
      value: "Available",
    },
    {
      label: "Not Available",
      value: "Not Available",
    },
  ];
};

export const productNegotiable = () => {
  return [
    {
      label: "Negotiable",
      value: "Negotiable",
    },
    {
      label: "Not Negotiable",
      value: "Not Negotiable",
    },
  ];
};

export const productListing = () => {
  return [
    {
      label: "Buy",
      value: "Buy",
    },
    {
      label: "Sell",
      value: "Sell",
    },
  ];
};

export const productListingDescription = () => {
  return [
    {
      label: "I want to buy the product",
      value: "I want to buy the product",
    },
    {
      label: "I want to sell the product",
      value: "I want to sell the product",
    },
  ];
};
