import Modal from '@/newComponents/Modal';
import Typography from '@/newComponents/Typography';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Apis, Utils } from '@shared/front';
import store from '@shared/front/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  Image,
  Content,
  Actions,
  StyledButton,
  WishItemTitle,
  WishListItemContainer,
} from './styles';
import { useResponsive } from '@/hooks/useResponsive';

const WishListView = ({ propertyWishItem, refreshData }: any) => {
  const id = propertyWishItem?.id;
  const [visible, setVisible] = React.useState(false);
  const navigate = useNavigate();
  const { isTablet } = useResponsive();
  const dispatch = useDispatch();
  const myBookmarkProperties = useSelector(
    state => state.myBookmarks?.propertyIds,
  );
  const handleDeleteRequest = async () => {
    await deleteBookMarked();
    refreshData();
    setVisible(false);
  };

  const deleteBookMarked = async () => {
    try {
      await Utils.request(Apis.Bookmark.deleteBookmark({ propertyId: id }));
      dispatch(
        store.Actions.update('myBookmarks', {
          propertyIds: [...myBookmarkProperties.filter(x => x !== id)],
        }),
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <WishListItemContainer>
      <Image src={propertyWishItem?.images[0]?.URL} />
      <Content>
        <WishItemTitle variant="h2" size="md">
          {propertyWishItem?.address}
        </WishItemTitle>
        <Actions>
          <StyledButton
            variant="gray"
            radius="round"
            onClick={() => navigate(`/properties/${propertyWishItem.id}`)}
          >
            View
          </StyledButton>
          <StyledButton
            variant="danger"
            radius="round"
            onClick={() => setVisible(true)}
          >
            Delete
          </StyledButton>
        </Actions>
      </Content>
      <Modal
        title="Delete?"
        open={visible}
        isCentered={true}
        onClose={() => setVisible(false)}
        style={{ maxWidth: isTablet ? '600px' : '100%', width: '100%' }}
      >
        <Typography variant="p">
          Are you sure want to remove this property from wishlist ?{' '}
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <StyledButton
            variant="danger"
            radius="round"
            onClick={handleDeleteRequest}
          >
            Delete
          </StyledButton>
        </div>
      </Modal>
    </WishListItemContainer>
  );
};

export default WishListView;
