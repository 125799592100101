import React from 'react';
import StepItem, { IStepItem } from './StepItem';
import styled from 'styled-components';
import { devices } from '@/utils/theme';
import { useMediaQuery } from 'react-responsive';

interface IStepsProps {
  items: IStepItem[];
}

const Steps = ({ items }: IStepsProps) => {
  const isWidth922 = useMediaQuery({ query: `(max-width:992px)` });
  const totalSteps = items?.length;
  const completedSteps = items.filter(x => x?.status === 'finish').length;
  const totalStepsToDivide =
    isWidth922 || completedSteps === totalSteps ? totalSteps : totalSteps - 1;
  return (
    <Container>
      {items.map((item, index) => (
        <>
          <Line $completedWidth={(completedSteps / totalStepsToDivide) * 100} />
          <StepItem {...item} />
        </>
      ))}
    </Container>
  );
};

export default Steps;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
`;

const Line = styled.div<{ $completedWidth: number }>`
  position: absolute;
  top: 4px;
  width: 100%;
  height: 2px;
  margin-top: auto;
  margin-bottom: auto;
  border-top: 1px solid #e0e0e0;
  border-width: 2px;

  @media ${devices.laptop} {
    width: calc(100% - 60px);
  }

  &::before {
    content: '';
    position: absolute;
    left: 0px;
    width: ${({ $completedWidth }) => $completedWidth + '%'};
    bottom: 0px;
    height: 2px;
    background: #00de90;

    @media ${devices.laptop} {
      width: calc(${({ $completedWidth }) => $completedWidth + '%'} + 5px);
    }
  }
`;
