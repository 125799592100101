import React, { useState } from 'react';
import styled from 'styled-components';
import Description from './Description';
import Commnet from './Comment';
import Locations from './Location';
import Button from '@/newComponents/Button';
import { capitalize } from 'lodash';
import Typography from '@/newComponents/Typography';
import Tabs, { TabsPaneType } from '@/newComponents/Tabs';
const Container = styled.div``;

const ProductInfo = ({ productInfo }: { productInfo: any }) => {
  const [activeKey, setActiveKey] = useState('1');
  const handleChange = (key: string) => {
    setActiveKey(key);
  };

  const items: Array<TabsPaneType> = [
    {
      key: '1',
      label: `Descriptions`,
      children: <Description productInfo={productInfo} />,
    },
    {
      key: '2',
      label: `Comment`,
      children: <Commnet productInfo={productInfo} />,
    },
    {
      key: '3',
      label: `Location`,
      children: <Locations productInfo={productInfo} />,
    },
  ];

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          marginBottom: '24px',
        }}
      >
        <Typography
          variant="h4"
          style={{
            textTransform: 'uppercase',
            fontWeight: '500',
          }}
          size="lg"
        >
          {productInfo?.title}
        </Typography>
        {!!productInfo?.listType && (
          <Button variant="tag" radius="round">
            {capitalize(productInfo?.listType)}
          </Button>
        )}
      </div>
      {productInfo?.listDesc && (
        <Button variant="tag" bgColor="rgba(151, 71, 255, 0.54)">
          {productInfo?.listDesc}
        </Button>
      )}

      <Tabs activeKey={activeKey} items={items} onChange={handleChange} />
    </Container>
  );
};

export default ProductInfo;
