import React from 'react';
import styled from 'styled-components';

interface Props {
  name: string;
  data: Array<{ label: string; value: string }>;
  onChange: (data: string) => void;
  selected: string;
  style: React.CSSProperties;
}

const SolidRadio = ({
  name,
  data,
  selected,
  onChange,
  ...restProps
}: Props) => {
  return data.map((iData: { label: string; value: string }) => (
    <LabelStyled $isActive={selected === iData.value}>
      <RadioInputStyled
        type="radio"
        name={name}
        value={iData?.value}
        onClick={() => onChange(iData.value)}
        {...restProps}
      />
      {iData?.label}
      <div className="radio-label">{iData?.label}</div>
    </LabelStyled>
  ));
};

export default SolidRadio;

const RadioInputStyled = styled.input``;

const LabelStyled = styled.label<{ $isActive: boolean }>`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  position: relative;
  height: 36px;
  width: fit-content;
  & .radio-label {
    cursor: pointer;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    padding: 6px 3px;
    border-radius: 6px;
    text-align: center;
    border: 1px solid #dbdbdb;
    background: ${({ theme, $isActive }) =>
      $isActive ? theme?.colors?.primary : theme?.colors?.white};
    color: ${({ theme, $isActive }) =>
      $isActive ? theme?.colors?.white : theme?.colors?.black};
  }
`;
