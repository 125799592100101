import Typography from '@/newComponents/Typography';
import Map from '@/newComponents/Map';
// import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import React, { useState } from 'react';
import styled from 'styled-components';

const Locations = ({ productInfo, google }) => {
  // console.log('the productt info', productInfo);
  const [isMapReady, setMapReady] = useState(false);
  return (
    <LocationStyled>
      <Typography variant="h5" fontWeight="bold" size="md">
        Location
      </Typography>
      <Typography
        variant="p"
        fontWeight="normal"
        style={{ marginBottom: '18px' }}
      >
        {productInfo.address}
      </Typography>
      <div>
        <Map
          coordinates={[
            {
              latitude: productInfo.latitude,
              longitude: productInfo.longitude,
            },
          ]}
          style={{
            height: '400px',
          }}
        />
      </div>
    </LocationStyled>
  );
};

// export default GoogleApiWrapper({
//   apiKey: process.env.GOOGLE_MAPS_API || '',
// })(Locations);

export default Locations;

const LocationStyled = styled.div`
  padding: 10px 0px 80px;
`;
