import SkeletonLoading from '@/newComponents/Skeleton';
import React from 'react';
import styled from 'styled-components';

const SearchTenantSkeleton = ({
  noWrapper = false,
  count,
  ...rest
}: {
  count?: number;
  noWrapper?: boolean;
}) => {
  if (noWrapper) {
    return Array.from({ length: count || 11 }, (_, i) => i).map(item => {
      return (
        <div className="card" key={item}>
          <SkeletonLoading
            height={150}
            borderRadius={10}
            style={{ marginBottom: '8px' }}
          />
          <SkeletonLoading
            height={20}
            style={{
              marginBottom: '8px',
            }}
          />
          <SkeletonLoading
            height={20}
            style={{
              marginBottom: '8px',
            }}
          />
          <div style={{}}>
            <SkeletonLoading
              height={20}
              width={140}
              style={{
                marginBottom: '8px',
              }}
            />
          </div>
        </div>
      );
    });
  }

  return (
    <Wrapper>
      <div className="post">
        {Array.from({ length: count || 11 }, (_, i) => i).map(item => {
          return (
            <div className="card" key={item}>
              <SkeletonLoading
                height={150}
                borderRadius={10}
                style={{ marginBottom: '8px' }}
              />
              <SkeletonLoading
                height={20}
                style={{
                  marginBottom: '8px',
                }}
              />
              <SkeletonLoading
                height={20}
                style={{
                  marginBottom: '8px',
                }}
              />
              <div style={{}}>
                <SkeletonLoading
                  height={20}
                  width={140}
                  style={{
                    marginBottom: '8px',
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
};

export default SearchTenantSkeleton;

const Wrapper = styled.div`
  width: 100%;

  .post {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 40px 30px;
  }
  margin-bottom: 50px;
`;
