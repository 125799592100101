import React, { useState, useRef } from "react";
import useAPI from "@/hooks/api";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import Typography from "../../../packages/web/src/newComponents/Typography";

const Container = styled.div`
  position: relative;
  display: inline-block;
  background-color: #fafafa;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  margin: 5px;
`;

const UploaderButton = styled.div`
  height: 100px;
  width: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const FileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100px;
  overflow: hidden;
  opacity: 0;
  z-index: 9999;
  font-size: 100px;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  color: red;
  background-color: white;
  border-radius: 20px;
  position: absolute;
  top: -5px;
  right: -5px;
  font-size: 20px;
  cursor: pointer;
`;

const Image = styled.img`
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 2px;
`;

const S3presigned = ({
  field: { name: fieldName, value, onChange, onBlur, disabled },
  form: { errors, touched, submitCount, setFieldValue },
  isMultiple = true,
  label,
  accept,
  fileType,
}: InputFieldType) => {
  const inputRef = useRef(null);
  const [handleS3Images, { loading: loadings3Images }] = useAPI();
  const uploadPromises = [];
  const tempUrls = [];
  const [uploadedFile, setUploadedFile] = useState();
  const [uploading, setUploading] = useState(false);
  let uploadedFiles = 0;
  const data = value;

  function findSrc(singleData) {
    let src = "";
    let id: number;

    if (typeof singleData === "string") {
      src = singleData;
    } else {
      if (singleData?.URL) {
        src = singleData.URL;
        id = singleData.id;
      } else {
        try {
          src = URL.createObjectURL(singleData);
        } catch (err) {}
      }
    }
    return src;
  }

  const uploadTo = async (file, presignedUrl) => {
    try {
      const response = await fetch(presignedUrl, {
        method: "PUT",
        headers: {
          "Content-Type": file.type,
        },
        body: file,
      });
      // toast.success("Pdf file is uploaded.");
    } catch (err) {
      console.log("Image upload failed:", err);
    }
  };
  const uploadToS3 = async (file) => {
    try {
      const { url, key } = await handleS3Images({
        method: "get",
        url: "/v1/common/s3-signed-url",
      });

      await uploadTo(file, url);
      return key;
    } catch (err) {
      console.log("uplaod to s3", err?.response?.data?.message);
    }
  };
  const handleChange = async (e) => {
    const files = Array.from(e.target.files);

    try {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        const uploadPromise = new Promise(async (resolve, reject) => {
          try {
            // Fetch the pre-signed URL from your server for each file
            const { url, key } = await handleS3Images({
              method: "get",
              url: "/v1/common/s3-signed-url",
              body: JSON.stringify({
                fileName: file.name,
                fileType: file.type,
              }),
            });

            // if (!response.ok) {
            //   throw new Error(
            //     `Failed to get S3 signed URL for file ${file.name}`
            //   );
            // }

            // Upload the current file to S3 using the presigned URL
            const uploadResponse = await fetch(url, {
              method: "PUT",
              body: file,
              headers: {
                "Content-Type": file.type,
              },
            });

            if (!uploadResponse.ok) {
              throw new Error(`Failed to upload file ${file.name} to S3`);
            }

            // Increment the count of uploaded files
            uploadedFiles++;

            // Resolve the promise after successful upload
            resolve(`File ${file.name} uploaded successfully`);
            tempUrls.push(key);
          } catch (error) {
            console.error(`Error uploading file ${file.name}:`, error);
            // Reject the promise in case of error
            reject(error);
          }
        });

        uploadPromises.push(uploadPromise);
      }
      await Promise.all(uploadPromises);
      setFieldValue(fieldName, [...value, ...tempUrls]);
    } catch (error) {}
  };

  const handleFileChange = async (e) => {
    const uploadFile = e.target.files[0];
    setUploading(true);
    await uploadToS3(uploadFile)
      .then((fileURL) => {
        setFieldValue(fieldName, fileURL);
      })
      .catch((error) => console.log("error uploading", error));
    await setUploadedFile(uploadFile);
    setUploading(false);
  };

  const returnShowUploadingPDF = (isLoading: boolean, fileName: string) => {
    return (
      <div
        style={{
          borderRadius: "5px",
          border: "1px solid #ddd",
          height: "100px",
          width: "150px",
          margin: " 10px 0px",
          position: "relative",
          backgroundColor: "#c2daf1",
        }}
      >
        <div
          style={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            gap: "5px",
            flexDirection: "column",
          }}
        >
          <FontAwesomeIcon icon='file-pdf' size='4x' />
          <Typography
            style={{
              maxHeight: "18px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              maxWidth: "90%",
            }}
          >
            {isLoading ? "Uploading ..." : fileName}
          </Typography>
        </div>
        {isLoading && (
          <div
            style={{
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
              gap: "5px",
              flexDirection: "column",
            }}
          >
            <FontAwesomeIcon icon='spinner' spin size='4x' color='#fcfcfc' />
          </div>
        )}
        {!isLoading && (
          <CloseIcon
            icon='times-circle'
            onClick={() => {
              setFieldValue(fieldName, "");
              if (inputRef.current) {
                inputRef.current.value = null;
              }
            }}
          />
        )}
      </div>
    );
  };

  return (
    <>
      <div>{label}</div>
      <div style={{ padding: !!label ? "10px 0px" : "0px" }}>
        {fileType === "file" && !isMultiple ? (
          <>
            {value &&
              !uploading &&
              returnShowUploadingPDF(false, uploadedFile?.name)}
            {!!uploading && returnShowUploadingPDF(true, uploadedFile?.name)}
            {!value && !uploading && (
              <Container>
                <UploaderButton>
                  <FontAwesomeIcon icon='plus' />
                </UploaderButton>
                <FileInput
                  ref={inputRef}
                  type='file'
                  accept={accept}
                  multiple={isMultiple}
                  onChange={handleFileChange}
                />
              </Container>
            )}
          </>
        ) : (
          <Container>
            {!!data?.length ? (
              data?.map((item: any) => {
                const src = findSrc(item);
                return (
                  <>
                    <Image src={src} />
                    <CloseIcon
                      icon='times-circle'
                      onClick={() => {
                        setFieldValue(fieldName, []);
                      }}
                    />
                  </>
                );
              })
            ) : (
              <>
                <UploaderButton>
                  <FontAwesomeIcon icon='plus' />
                </UploaderButton>
                <FileInput
                  type='file'
                  onChange={handleChange}
                  accept={accept}
                  multiple={isMultiple}
                />
              </>
            )}
          </Container>
        )}
      </div>
    </>
  );
};
export default S3presigned;
type InputFieldType = {
  field: FieldType;
  form: MetaType;
  border?: keyof typeof ThemeType.sizes;
  type?: string;
  placeholder?: string;
  label?: string;
  background?: string;
  options?: { label: string; value: string }[];
  onSelectOption?: (value: string) => void;
  style?: any;
  prepend?: any;
  append?: any;
  isMultiple?: Boolean;
  accept?: string;
  fileType?: string;
};

type FieldType = {
  name: string;
  value: any;
  onChange: (e: React.ChangeEvent<any>) => void;
  onBlur: (e: React.FocusEvent<any>) => void;
  [rest: string]: any;
};

type MetaType = {
  errors?: FormikErrors<object> | undefined;
  touched?: FormikTouched<object> | undefined;
  submitCount: number;
};
