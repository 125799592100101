import Button from '@/newComponents/Button';
import NoData from '@/newComponents/NoData';
import Typography from '@/newComponents/Typography';
import store from '@/store';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

type Props = {};

const NotAuthorized = (props: Props) => {
  const dispatch = useDispatch();

  const onSignIn = () => {
    dispatch(store.Actions.set('authModal', { status: true, type: 'login' }));
  };

  return (
    <NotAuthorizedStyled>
      <Typography
        fontWeight="bold"
        size="lg"
        style={{ maxWidth: '400px', textAlign: 'center', lineHeight: '46px' }}
      >
        Sorry,
      </Typography>
      <NoData title=" " size="150" />
      <Typography
        fontWeight="bold"
        size="md"
        style={{ maxWidth: '400px', textAlign: 'center', lineHeight: '46px' }}
      >
        You do not have permission to access this page.
      </Typography>

      <ButtonWrapperStyled>
        <Button size="lg" onClick={onSignIn}>
          Sign In
        </Button>
      </ButtonWrapperStyled>
    </NotAuthorizedStyled>
  );
};

export default NotAuthorized;
const NotAuthorizedStyled = styled.div`
  display: flex;
  align-items: center;
  min-height: calc(100vh - 80px);
  margin-top: 100px;
  flex-direction: column;
`;

const ButtonWrapperStyled = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  max-width: 400px;
  width: 100%;
  gap: 20px;
  flex-wrap: wrap;
`;
