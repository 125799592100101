import Button from '@/newComponents/Button';
import CustomRate from '@/newComponents/CustomRate';
import Typography from '@/newComponents/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Apis, Hooks, Store, Utils } from '@shared/front';
import Modal from '@/newComponents/Modal';
import { Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import * as yup from 'yup';
import Tabs from '@/newComponents/Tabs';
import IssuesList from './IssuesList';
import { ButtonsWrapper, StyledButton, StyledForm, StyledTag } from './styles';

import Input from '@/newComponents/Input';
import Select from '@/newComponents/Select';
import { useResponsive } from '@/hooks/useResponsive';

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 60px;
  background-color: white;
  z-index: 99;
  background: #fff;
  & .title {
    font-size: 1.6rem;
    font-weight: 500;
  }
  padding: 20px 0px 10px 0px;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 20px;
`;
const MyIssues = () => {
  const [handleCreateReport, { loading }] = Hooks.useAPI();
  const [activeTab, setActiveTab] = useState('inProgress');
  const [isAddIssueModalOpen, setIsAddIssueModalOpen] = useState(false);
  const { isTablet } = useResponsive();

  const initialValues = {
    title: '',
    desc: '',
    reportType: 'property',
    propertyId: null,
    ownerId: null,
  };

  const reportIssueCategoryOptions = [
    {
      id: 'property-issue',
      value: 'property',
      label: 'Property',
    },
    {
      id: 'services-issue',
      value: 'service',
      label: 'Service',
    },
    {
      id: 'chat-issue',
      value: 'chat',
      label: 'Chat',
    },
    {
      id: 'calendar-issue',
      value: 'calendar',
      label: 'Calendar',
    },
    {
      id: 'contract-issue',
      value: 'contract',
      label: 'Contract',
    },
    {
      id: 'transaction-issue',
      value: 'transaction',
      label: 'Transaction',
    },
  ];

  const columns = [
    { key: 'reportId', title: 'ReportID' },
    { key: 'title', title: 'Title' },
    { key: 'description', title: 'Description' },
    { key: 'issueType', title: 'Issue Type' },
    { key: 'rating', title: 'Rating' },
    { key: 'status', title: 'Status' },
  ];

  const issues: any[] = useSelector(Store.Select.myReportIssues);

  const data = issues.map(issue => ({
    reportId: issue.id,
    title:
      issue.title.length > 14
        ? issue.title.substring(0, 14) + '...'
        : issue.title,
    description:
      issue.desc.length > 30 ? issue.desc.substring(0, 30) + '...' : issue.desc,
    issueType: issue?.reportType,
    rating: <CustomRate value={issue?.reportUser?.rating}></CustomRate>,
    status: (
      <StyledTag
        variant="tag"
        radius="round"
        bgColor={issue?.status === 'In_progress' ? '#2061f8' : 'green'}
      >
        {issue?.status === 'In_progress' ? 'Processing' : 'Resolved'}
      </StyledTag>
    ),
  }));

  const tabItems = [
    {
      key: 'inProgress',
      label: 'In Progress',
      children: (
        <IssuesList loading={loading} issues={data} columns={columns} />
      ),
    },
    {
      key: 'resolved',
      label: 'Resolved',
      children: (
        <IssuesList loading={loading} issues={data} columns={columns} />
      ),
    },
  ];

  const getMyIssue = async (status: 'Completed' | 'In_Progress') => {
    await Utils.request(
      Apis.ReportIssue.getMyReportIssue({
        type: status,
      }),
    );
  };

  const handleSubmit = async (values: any) => {
    await handleCreateReport(Apis.ReportIssue.createReportIssue(values));
    getMyIssue('In_Progress');
  };

  useEffect(() => {
    getMyIssue('In_Progress');
  }, []);

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    if (tab === 'inProgress') {
      getMyIssue('In_Progress');
    }
    if (tab === 'resolved') {
      getMyIssue('Completed');
    }
  };

  return (
    <>
      <TitleContainer>
        <Typography className="title" variant="p">
          My Issues
        </Typography>
        <Button
          variant="gray"
          radius="round"
          bgColor="red"
          onClick={() => setIsAddIssueModalOpen(true)}
        >
          <Typography variant="p" size="sm">
            <FontAwesomeIcon icon="plus" style={{ marginRight: 5 }} /> Add Issue
          </Typography>
        </Button>
      </TitleContainer>
      <Tabs
        activeKey={activeTab}
        onChange={handleTabChange}
        items={tabItems}
        activeTabColor="#2061f8"
      />

      <Modal
        title="Report new Issue"
        open={isAddIssueModalOpen}
        onClose={() => setIsAddIssueModalOpen(false)}
        style={{
          maxWidth: isTablet ? '600px' : '100%',
          width: '100%',
        }}
      >
        <Formik
          onSubmit={async (values, { resetForm }) => {
            await handleSubmit(values);
            resetForm();
            setIsAddIssueModalOpen(false);
          }}
          initialValues={initialValues}
          enableReinitialize
          validationSchema={yup.object().shape({
            title: yup.string().required('Please enter a title.'),
            desc: yup.string().required('Please enter description.'),
          })}
        >
          {({ values, errors, isSubmitting, setFieldValue }) => {
            return (
              <StyledForm>
                <div>
                  <Field
                    component={Input}
                    label="Title"
                    name="title"
                    htmlType="text"
                    value={values.title}
                    onChange={e => setFieldValue('title', e.target.value)}
                    error={errors.title}
                  />

                  <Field
                    component={Select}
                    options={reportIssueCategoryOptions}
                    label="Category"
                    name="reportType"
                    htmlType="select"
                    value={values.reportType}
                    onChange={e => setFieldValue('reportType', e)}
                  />

                  <Field
                    component={Input}
                    label="Description"
                    name="desc"
                    htmlType="text"
                    value={values.desc}
                    type="textarea"
                    onChange={e => setFieldValue('desc', e.target.value)}
                    style={{ height: 'unset' }}
                    rows={7}
                    error={errors.desc}
                  />
                </div>
                <ButtonsWrapper>
                  <StyledButton
                    variant="gray"
                    radius="round"
                    onClick={() => setIsAddIssueModalOpen(false)}
                  >
                    Cancel
                  </StyledButton>
                  <StyledButton
                    htmlType="submit"
                    radius="round"
                    isDisabled={loading || isSubmitting}
                    isLoading={loading || isSubmitting}
                  >
                    Submit
                  </StyledButton>
                </ButtonsWrapper>
              </StyledForm>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default MyIssues;
