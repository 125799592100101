import { devices } from '@/utils/theme';
import React, { CSSProperties, ChangeEvent } from 'react';
import styled from 'styled-components';

interface ICheckboxProps {
  id: string;
  label?: string;
  isChecked: boolean;
  onChange: (event: ChangeEvent) => void;
  className?: string;
  style?: CSSProperties;
}

const StyledContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const StyledCheckbox = styled.input`
  border-radius: 3px;
  border: 1px solid #e0e0e0;
  background: #ffffff;
  height: 20px;
  width: 20px;
  margin: 0;

  @media ${devices.laptop} {
    height: 30px;
    width: 30px;
  }
`;

const StyledLabel = styled.label`
  font-size: 12px;
  line-height: 25px;
  font-weight: 400;
  white-space: nowrap;

  @media ${devices.laptop} {
    font-size: 14px;
    line-height: 30px;
  }
`;

const Checkbox = ({
  id,
  label,
  isChecked,
  onChange,
  className,
  style,
  ...rest
}: ICheckboxProps) => {
  return (
    <StyledContainer>
      <StyledCheckbox
        id={id}
        type="checkbox"
        checked={isChecked}
        className={className}
        style={{ ...style }}
        onChange={onChange}
        {...rest}
      />
      {label && <StyledLabel htmlFor={id}>{label}</StyledLabel>}
    </StyledContainer>
  );
};

export default Checkbox;
