import { useRoot } from '@/RootProvider';
import useAPI from '@/hooks/api';
import Button from '@/newComponents/Button';
import GoBack from '@/newComponents/GoBack';
import Modal from '@/newComponents/Modal';
import Typography from '@/newComponents/Typography';
import { devices } from '@/utils/theme';
import { Apis, Hooks } from '@shared/front';
import { localeNumberFormat } from '@shared/front/utils/numberFormat';
import DatePickerField from '@shared/web/components/DatePicker';
import LoadingPlaceholder from '@shared/web/components/LoadingPlaceholder';
import MaskInputField from '@shared/web/components/MaskInputField';
import SideProfile from '@shared/web/components/SideProfile';
import { Field, Form, Formik } from 'formik';
import { capitalize } from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ShowMoreText from 'react-show-more-text';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import * as yup from 'yup';
import Contracts from './Contracts';
import { ButtonEndWrapperStyled } from '@/styles/shareableStyle';
import { useResponsive } from '@/hooks/useResponsive';

const Content = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: column-reverse;

  @media ${devices.laptop} {
    flex-direction: row;
  }
`;
const LeftBox = styled.div`
  flex: 1;
`;
const RightBox = styled.div`
  padding: 20px;
  text-align: center;
  width: 100%;

  display: flex;
  flex-direction: column;

  @media ${devices.tablet} {
    width: 100%;
    gap: 20px;
    flex-direction: row;
  }

  @media ${devices.laptop} {
    width: 320px;
    flex-direction: column;
  }
`;

const RightInnerBox = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 2px;
  padding: 20px;
  margin: 0 auto;

  width: 100%;
  gap: 20px;
  & > button {
    width: 100%;
  }
`;

const DescContainer = styled.div`
  margin: 20px 0;
  padding: 20px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`;

const JobsDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const CallOutInfoWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const TitleWithActionStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  @media ${devices.tablet} {
    flex-direction: column;
    align-items: start;

    & button {
      align-self: end;
    }
  }
  @media ${devices.laptop} {
    flex-direction: row;
    align-items: center;
  }
  margin-bottom: 30px;
  gap: 10px;
`;

type Props = {};

const PropertyView = ({}: Props) => {
  const { id: jobId } = useParams();
  const { isTablet } = useResponsive();
  const [acceptCalloutModal, setAcceptCalloutModal] = useState<{
    status: boolean;
    data: any;
  }>({ status: false, data: null });

  const [requestForCallout, { loading: requestForCalloutLoading }] =
    Hooks.useAPI();
  const [makeMessageRequest, { loading: messageLoading }] = useAPI();
  const [requestCalloutAccept, { loading: acceptLoading }] = Hooks.useAPI();
  const [callOutModal, setCallOutModal] = useState(false);
  const [confirmCallOutModal, setConfirmCallOutModal] = useState(false);
  const [isMapReady, setMapReady] = useState(false);
  const [getPropertyRequest, { loading, data }] = useAPI();
  const [makeWithdrawRequest, { loading: withdrawLoading }] = useAPI();
  const { auth } = useRoot();
  const navigate = useNavigate();
  const getProperty = async () => {
    getPropertyRequest({
      method: 'get',
      url: `/v1/services/proposals/${jobId}`,
    });
  };

  useEffect(() => {
    getProperty();
  }, [jobId]);

  const onAcceptCallOut = () => {
    setAcceptCalloutModal({
      status: true,
      data: '',
    });
  };

  const onCloseAcceptCallOutModal = () => {
    setAcceptCalloutModal({
      status: false,
      data: null,
    });
  };

  const handleWithdraw = async () => {
    await makeWithdrawRequest({
      method: 'post',
      url: `/v1/services/proposals/${jobId}/withdraw`,
    });

    getProperty();
    toast('Your proposal has been withdrawn successfully.');
  };

  const handleRequestForCallout = async (values: any) => {
    try {
      if (!jobId) return;
      await requestForCallout(
        Apis.Proposal.updateProposal(
          {
            calloutFee: values?.calloutFee,
            calloutDate: values?.calloutDate,
            isRequestCallout: true,
          },
          parseInt(jobId, 10),
        ),
      );
      setCallOutModal(false);
      toast.success('Callout request has been successfully sent');
      getProperty();
    } catch (error) {}
  };
  const handleAcceptForCallout = async () => {
    try {
      await requestCalloutAccept(Apis.Proposal.acceptCallout(data?.id));
      toast.success('Callout request has been successfully sent');
      await getProperty();
      onCloseAcceptCallOutModal();
    } catch (error) {}
  };
  return (
    <div>
      <GoBack />

      {callOutModal && (
        <Modal
          title="CallOut"
          open={callOutModal}
          onClose={() => setCallOutModal(false)}
        >
          <Formik
            initialValues={{}}
            onSubmit={values => {
              handleRequestForCallout(values);
            }}
            validationSchema={yup.object().shape({
              calloutFee: yup.number().required('Please enter callout fees'),
              calloutDate: yup.string().required('Please select callout date'),
            })}
          >
            {({
              values,
              setFieldValue,
              isSubmitting,
              errors,
              isValid,
              dirty,
            }) => {
              return (
                <Form>
                  <Field
                    component={MaskInputField}
                    name="calloutFee"
                    label="CallOut Fee"
                    decimalSeparator="."
                    displayType="input"
                    type="text"
                    thousandSeparator={true}
                    prefix={'€'}
                    setFieldValue={setFieldValue}
                  />
                  <Field
                    component={DatePickerField}
                    name="calloutDate"
                    label="CallOut Date"
                  />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: '20px',
                    }}
                  >
                    <div>
                      <Button style={{ marginRight: '10px' }}>Cancel</Button>
                    </div>
                    <div>
                      <Button
                        htmlType="submit"
                        type="primary"
                        lisLoading={requestForCalloutLoading}
                        isDisabled={requestForCalloutLoading || !isValid}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal>
      )}

      {loading && (
        <div
          style={{
            height: 100,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <LoadingPlaceholder />
        </div>
      )}
      <>
        <Content>
          <LeftBox>
            <JobsDetailsContainer>
              <div style={{ flex: 1 }}>
                <TitleWithActionStyled>
                  <Typography variant="h5" fontWeight="bold">
                    Job details
                  </Typography>
                  <div>
                    {(auth?.role === 'landlord' || auth?.role === 'tenant') &&
                      data?.id &&
                      !data?.isRequestCallout &&
                      data?.status !== 'Hired' &&
                      data?.status === 'Active' && (
                        <Button onClick={() => setCallOutModal(true)} size="sm">
                          Request for Callout
                        </Button>
                      )}

                    {auth?.role === 'service' &&
                      data?.id &&
                      data?.isRequestCallout &&
                      !data?.isServiceProviderAcceptCallout && (
                        <Button onClick={onAcceptCallOut} size="sm">
                          {`Accept for Callout (${localeNumberFormat(
                            data?.calloutFee,
                          )})`}
                        </Button>
                      )}
                  </div>
                </TitleWithActionStyled>
                <Typography
                  variant="h5"
                  fontWeight="extrabold"
                  style={{ marginBottom: '10px' }}
                >
                  {data?.service?.title}
                </Typography>

                <Typography
                  variant="h5"
                  fontWeight="extrabold"
                  style={{ marginBottom: '10px' }}
                  size="sm"
                >
                  {data?.service?.address}
                </Typography>
                <Typography
                  variant="h5"
                  fontWeight="extrabold"
                  style={{ marginBottom: '10px' }}
                  size="sm"
                >
                  Type : {capitalize(data?.service?.priceType)}
                </Typography>
                <Typography
                  variant="h5"
                  fontWeight="extrabold"
                  style={{ marginBottom: '10px' }}
                  size="sm"
                >
                  Price : &euro;{data?.service?.price}
                </Typography>

                <ShowMoreText
                  lines={3}
                  more="Show more"
                  less="Show less"
                  truncatedEndingComponent={'... '}
                >
                  <Typography variant="p">{data?.service?.desc}</Typography>
                </ShowMoreText>
              </div>
            </JobsDetailsContainer>
            {data?.isRequestCallout && !data?.isServiceProviderAcceptCallout ? (
              <Typography
                variant="p"
                style={{
                  background: '#163d83',
                  color: '#fff',
                  padding: '3px 5px',
                  borderRadius: '3px',
                  margin: '10px 0px',
                }}
              >
                Request for CallOut has been sent.
              </Typography>
            ) : data?.isRequestCallout &&
              data?.isServiceProviderAcceptCallout ? (
              <Typography
                variant="p"
                style={{
                  background: '#00af8f',
                  color: '#fff',
                  padding: '3px 5px',
                  borderRadius: '3px',
                  margin: '10px 0px',
                }}
              >
                Request for CallOut has been accepted.
              </Typography>
            ) : null}
            <DescContainer>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: '20px',
                }}
              >
                <Typography variant="h5" fontWeight="bold">
                  Proposal details
                </Typography>
                <Button
                  size="sm"
                  radius="round"
                  style={{
                    cursor: 'default',
                    background:
                      data?.status.toLowerCase() === 'active'
                        ? '#047104'
                        : '#1687e3',
                  }}
                >
                  {capitalize(data?.status)}
                </Button>
              </div>
              <Typography variant="h5" style={{ marginBottom: '10px' }}>
                Proposed rate : €{numeral(data?.price).format('0,0.00')}
              </Typography>
              <Typography variant="h5" style={{ marginBottom: '10px' }}>
                Proposed date : {moment(data?.createdAt).format('YYYY, MMM DD')}
              </Typography>

              {data?.isRequestCallout &&
                data?.isServiceProviderAcceptCallout && (
                  <CallOutInfoWrapperStyled>
                    <Typography variant="p" fontWeight="extrabold">
                      Callout Fee : &euro;{data?.calloutFee}
                    </Typography>
                    <Typography variant="p" fontWeight="extrabold">
                      Callout Date :{' '}
                      {moment(data?.calloutDate).format('YYYY, MMM DD')}
                    </Typography>
                  </CallOutInfoWrapperStyled>
                )}
              <div style={{ marginTop: '50px' }}></div>
              <ShowMoreText
                lines={10}
                more={'Show more'}
                less="Show less"
                truncatedEndingComponent={'...'}
              >
                <Typography variant="p">{data?.desc}</Typography>
              </ShowMoreText>
            </DescContainer>
            {auth?.id == data?.landlordId && !!data?.contracts?.length && (
              <Contracts contracts={data?.contracts} />
            )}
          </LeftBox>

          <RightBox>
            <SideProfile
              profileData={
                auth?.role === 'service'
                  ? data?.service?.landlord
                  : data?.provider
              }
              isProfileViewable
              role={
                auth?.role === 'service'
                  ? data?.service?.landlord?.role
                  : 'service'
              }
            />
            <RightInnerBox>
              <Button
                style={{ margin: '10px auto' }}
                onClick={() => navigate(`/jobs/${data?.service?.id}`)}
              >
                View Job
              </Button>
              {auth?.role === 'service' && data?.status === 'Active' && (
                <Button
                  isLoading={withdrawLoading}
                  isDisabled={withdrawLoading}
                  variant="primary"
                  onClick={() => handleWithdraw()}
                  style={{ width: '100%', marginBottom: '16px' }}
                >
                  Withdraw proposal
                </Button>
              )}

              <Button
                isLoading={messageLoading}
                isDisabled={messageLoading}
                variant="primary"
                style={{ width: '100%' }}
                onClick={async () => {
                  const serviceId = data?.provider?.id;
                  const jobId = data?.service?.id;
                  let landlordId;
                  let tenantId;
                  if (data?.service?.landlord.role === 'landlord') {
                    landlordId = data?.service.landlord.id;
                  } else if (data?.service?.landlord.role === 'tenant') {
                    tenantId = data?.service.landlord.id;
                  }
                  const payload = {
                    serviceId: serviceId,
                    landlordId: landlordId ? landlordId : null,
                    tenantId: tenantId ? tenantId : null,
                    jobId: jobId ? jobId : null,
                    chatType: 'job',
                  };

                  const messageId = await makeMessageRequest({
                    method: 'post',
                    url: `/v1/common/message`,
                    data: payload,
                  });
                  navigate(`/chats/${messageId}`);
                }}
              >
                Message
              </Button>
            </RightInnerBox>
          </RightBox>
        </Content>
      </>
      <Modal
        open={acceptCalloutModal.status}
        title="Callout"
        onClose={onCloseAcceptCallOutModal}
        style={{ width: isTablet ? '500px' : '94vw' }}
      >
        <Typography variant="h4" fontWeight="extrabold">
          You want to accept for this callout?
        </Typography>
        <br />
        <Typography variant="p">
          When clicked the OK button, you will accept the callout and visit the
          site and know about your work
        </Typography>
        <br />
        <br />
        <ButtonEndWrapperStyled>
          <Button
            variant="gray"
            isDisabled={acceptLoading}
            onClick={onCloseAcceptCallOutModal}
          >
            Cancel
          </Button>
          <Button
            isLoading={acceptLoading}
            isDisabled={acceptLoading}
            onClick={handleAcceptForCallout}
          >
            Accept
          </Button>
        </ButtonEndWrapperStyled>
      </Modal>
    </div>
  );
};

export default PropertyView;
