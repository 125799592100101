import React, {
  CSSProperties,
  InputHTMLAttributes,
  ReactNode,
  useEffect,
  useRef,
} from 'react';
import styled from 'styled-components';
import SearchIcon from './assets/search-icon.svg';
import CloseIcon from './assets/close-button.svg';
import FilterIcon from './assets/Filter_alt.svg';
import { useResponsive } from '@/hooks/useResponsive';
import { devices } from '@/utils/theme';

interface ISearchFilterInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  filterModal?: ReactNode;
  name: string;
  prefilledValue?: any;
  inputType?: 'search' | 'filter';
  placeholder: string;
  value?: string;
  style?: CSSProperties;

  onChange?: (e) => void;
  onSearch?: (param: any) => void;
  onFilter?: () => void;
  onCancel: () => void;
}

const Container = styled.div`
  display: flex;
  gap: 8px;
  height: 40px;
  width: 100%;

  @media ${devices.laptopXL} {
    gap: 20px;
  }
`;

const InputWrapper = styled.div`
  width: 100%;
  border-radius: 24px;
  background: #ebebeb;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledInput = styled.input`
  border: none;
  outline: none;
  background-color: transparent;
  padding-left: 30px;
  width: calc(100% - 40px);
`;

const SearchOrCancelButton = styled.button<{ $isDisabled: boolean }>`
  border: none;
  outline: none;
  background-color: ${({ $isDisabled }) =>
    $isDisabled ? '#5d5d5d' : '#2061f8'};
  border-radius: 50%;
  height: 37px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};

  &:hover {
    background-color: ${({ $isDisabled }) =>
      $isDisabled ? '#5d5d5d' : '#1c52d1'};
  }
`;

const FilterButton = styled.button`
  border: none;
  outline: none;
  background-color: #2061f8;
  color: #ffffff;
  cursor: pointer;
  height: 37px;
  width: 100%;
  max-width: 37px;
  border-radius: 50%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${devices.tablet} {
    border-radius: 37px;
    padding: 6px 20px;
    max-width: none;
    width: auto;
  }

  &:hover {
    background-color: #1c52d1;
  }
`;

const SearchFilterInput = ({
  name,
  filterModal,
  prefilledValue,
  inputType = 'filter',
  placeholder,
  value,
  style,
  onChange,
  onSearch,
  onFilter,
  onCancel,
  ...rest
}: ISearchFilterInputProps) => {
  const searchRef = useRef(null);
  const { isTablet } = useResponsive();
  useEffect(() => {
    if (prefilledValue) {
      searchRef.current.value = prefilledValue;
      onSearch(prefilledValue);
    }
  }, [prefilledValue]);

  return (
    <Container style={{ ...style }}>
      {filterModal}
      <InputWrapper>
        <StyledInput
          ref={searchRef}
          type="text"
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          {...rest}
        />
        <SearchOrCancelButton
          onClick={onCancel}
          disabled={!value}
          $isDisabled={!value}
        >
          <img
            src={SearchIcon}
            alt={inputType === 'search' ? 'Search icon' : 'Close icon'}
          />
        </SearchOrCancelButton>
      </InputWrapper>
      {inputType === 'filter' && (
        <FilterButton onClick={onFilter}>
          {isTablet ? 'Filter' : <img src={FilterIcon} alt="Filter icon" />}
        </FilterButton>
      )}
    </Container>
  );
};

export default SearchFilterInput;
