import Typography from '@/newComponents/Typography';
import Modal from '@/newComponents/Modal';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useResponsive } from '@/hooks/useResponsive';
import utils from '@shared/front/utils';
import { Apis, Store } from '@shared/front';
import { useDispatch, useSelector } from 'react-redux';
import {
  Actions,
  Content,
  StyledButton,
  WishItemDescription,
  WishItemTitle,
  WishListItemContainer,
} from './styles';

const WishListServiceView = ({ propertyWishItem, refreshData }: any) => {
  const id = propertyWishItem?.id;
  const [visible, setVisible] = React.useState(false);
  const navigate = useNavigate();
  const { isTablet } = useResponsive();
  const dispatch = useDispatch();
  const handleDeleteRequest = async () => {
    await deleteBookMarked();
    refreshData();
    setVisible(false);
  };
  const myBookmarksJobs = useSelector(state => state.myBookmarks?.serviceIds);
  const deleteBookMarked = async () => {
    try {
      await utils.request(Apis.Bookmark.deleteBookmark({ serviceId: id }));
      dispatch(
        Store.Actions.update('myBookmarks', {
          serviceIds: [...myBookmarksJobs.filter(x => x !== parseInt(id, 10))],
        }),
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <WishListItemContainer>
      <Content>
        <div>
          <WishItemTitle variant="h2">
            {propertyWishItem?.title?.length > 30
              ? propertyWishItem?.title?.substring(0, 30) + '...'
              : propertyWishItem?.title}
          </WishItemTitle>
          <WishItemDescription>
            {propertyWishItem?.desc?.length > 90
              ? propertyWishItem?.desc?.substring(0, 90) + '...'
              : propertyWishItem?.desc}
          </WishItemDescription>
        </div>

        <Actions>
          <StyledButton
            variant="gray"
            radius="round"
            onClick={() => navigate(`/jobs/${id}`)}
          >
            View
          </StyledButton>
          <StyledButton
            variant="danger"
            radius="round"
            onClick={() => setVisible(true)}
          >
            Delete
          </StyledButton>
        </Actions>
      </Content>
      <Modal
        title="Delete?"
        open={visible}
        isCentered={true}
        size={isTablet ? 'md' : 'lg'}
        style={{ maxWidth: isTablet ? '600px' : '100%', width: '100%' }}
        onClose={() => setVisible(false)}
      >
        <Typography variant="p">
          Are you sure you want to remove this job from wishlist?
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <StyledButton
            variant="danger"
            radius="round"
            onClick={handleDeleteRequest}
          >
            Delete
          </StyledButton>
        </div>
      </Modal>
    </WishListItemContainer>
  );
};

export default WishListServiceView;
