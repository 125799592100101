import Typography from '@/newComponents/Typography';
import Error from '@shared/web/components/Error';
import { Form, Formik } from 'formik';
import useAPI from '@/hooks/api';
import React, { useState } from 'react';
// import validation from './validation';
import S3FileUploader from '../../Properties/S3FileUploader';
import { Apis, Utils } from '@shared/front';
import { SaveButtonContainer, StyledButton, Title } from './index.styles';
import styled from 'styled-components';
import { devices } from '@/utils/theme';

const Photos = ({ initialValues, onSubmitStep }) => {
  const [handleSubmitRequest, { loading, error }] = useAPI();
  const [photoLoading, setPhotoLoading] = useState(false);

  const handleUpdatesStatus = async () => {
    const updateUrl = '/v1/marketPlace/products';
    try {
      await handleSubmitRequest({
        method: 'patch',
        url: !!initialValues.id && `${updateUrl}/${initialValues.id}`,
        data: { status: 'active' },
      });
    } catch (error) {
      console.log('Error submitting form', error);
    }
  };
  const handleSubmitForm = async (values, isDraft?: boolean) => {
    // console.log('the values ateoooooo', values);
    const url = '/v1/marketPlace/products';
    const updateUrl = '/v1/marketPlace/products';
    const { productImage } = values;
    const filterImages = productImage.filter(Boolean);

    const urlArray = [];

    for (const item of filterImages) {
      if (typeof item === 'string') {
        urlArray.push(item);
      } else if (typeof item === 'object' && item.hasOwnProperty('url')) {
        urlArray.push(item.url);
      }
    }
    // console.log('the urlArray', urlArray);

    try {
      const data = await handleSubmitRequest({
        method: !!initialValues.id ? 'post' : 'patch',
        url: !!initialValues.id
          ? `${updateUrl}/${initialValues.id}/images`
          : url,
        data: { images: urlArray },
      });
      handleUpdatesStatus();
      onSubmitStep({ data, isDraft });
    } catch (error) {
      console.log('Error submitting form', error);
    }
  };

  const handleDeleteProductImages = async id => {
    await Utils.request(Apis.Market.deleteMarketProductImage(id));
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => {
        // console.log('the raw');
        handleSubmitForm(values);
      }}
      // validationSchema={validation.photos}
      enableReinitialize
    >
      {({
        isSubmitting,
        errors,
        touched,
        values,
        setFieldValue,
        handleSubmit,
      }) => {
        // console.log('the values are ====', values);
        return (
          <Form onSubmit={handleSubmit}>
            <Title variant="h1">Images</Title>
            <DescriptionContainer>
              <StyledDescription variant="p">
                Upload product photos
              </StyledDescription>
              <StyledDescription variant="p">
                Drag images into box to add an image / dragging in multiple
                images at once is also possible.
              </StyledDescription>
            </DescriptionContainer>
            {/* {console.log('the values wiof ', values.productImage)} */}
            {(values.productImage?.length ? values.productImage : [null]).map(
              (item: any, index: any) => (
                <S3FileUploader
                  index={index}
                  setFieldValue={setFieldValue}
                  values={values}
                  fieldName={'productImage'}
                  item={item}
                  deleteById={id => {
                    handleDeleteProductImages(id);
                  }}
                  photoLoading={photoLoading}
                  setPhotoLoading={setPhotoLoading}
                />
              ),
            )}

            <StyledNote variant="p">
              <span style={{ color: '#2061f8' }}>Note:</span> PNG, JPG and JPEG
              are supported
            </StyledNote>
            <Error message={error || errors?.images} isFormError />
            {/* {errors.images && touched.images && <div>{errors?.images}</div>} */}

            <SaveButtonContainer>
              <StyledButton
                radius="round"
                htmlType="submit"
                isDisabled={photoLoading}
                isLoading={photoLoading}
              >
                Save
              </StyledButton>
            </SaveButtonContainer>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Photos;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 20px;
`;

const StyledDescription = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  line-height: 25px;

  @media ${devices.laptop} {
    font-size: 14px;
    line-height: 30px;
  }
`;

const StyledNote = styled(StyledDescription)`
  margin-top: 20px;
  color: #949494;
`;
