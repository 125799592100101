import { useDetectOutsideClick } from '@/hooks/useDetectOutsideClick';
import request from '@shared/front/utils/request';
import { debounce } from 'lodash';
import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import Map from './Map';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Typography from './Typography';

type Props = {
  onChange?: (data: any) => void;
  onSelect: (data: any) => void;
  value?: string;
  showMap?: boolean;
  inputClassName?: string;
  placeholder?: string;
  label?: string;
};
const DUBLIN = {
  lat: 53.34304171021489,
  lng: -6.264192673164263,
  address: 'DUBLIN',
};
const AddressSearch = ({
  onChange,
  value = '',
  onSelect,
  showMap = false,
  inputClassName,
  placeholder,
  label,
  ...restProps
}: Props) => {
  const [address, setAddress] = useState(value);
  const [selectedCoordinates, setSelectedCoordinates] = useState<any>({
    latitude: DUBLIN.lat,
    longitude: DUBLIN.lng,
  });

  const [places, setPlaces] = useState([
    {
      label: 'Search',
      value: '',
    },
  ]);
  const [loading, setLoading] = useState<{
    status: boolean;
    type: 'search' | 'select' | undefined;
    id?: string;
  }>({
    status: false,
    type: undefined,
    id: undefined,
  });
  const containerRef = useRef(null);
  const [showOptions, setShowOptions] = useDetectOutsideClick(
    containerRef,
    false,
  );

  const delayedSearchAddress = useCallback(
    debounce(search => {
      fetchPlaces(search);
    }, 1000),
    [],
  );

  const getLatLongFromPlaceId = async (placeId: string) => {
    try {
      const res = await request({
        method: 'get',
        url: '/v1/common/google-geocode',
        params: {
          placeId,
        },
      });
      const { lat, lng } = res.geometry.location;

      return { latitude: lat, longitude: lng };
      return false;
    } catch (err) {
      console.log('Error fetching lat/long', err);
    }
  };

  const fetchPlaces = async (query: string) => {
    if (!query.trim()) {
      setPlaces([
        {
          label: 'Search',
          value: '',
        },
      ]);
    }
    setLoading({
      status: true,
      type: 'search',
    });

    try {
      const res = await request({
        method: 'get',
        url: '/v1/common/google-places',
        params: {
          input: query.trim(),
        },
      });

      const optionsMade = res.map((place: any) => ({
        label: place.description,
        value: place.place_id,
      }));

      setPlaces(optionsMade);
    } catch (err) {
      return [];
    } finally {
      setLoading({
        status: false,
        type: undefined,
      });
    }
  };

  const onSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setAddress(value);
    delayedSearchAddress(value);
    onChange && onChange(value);
  };

  const handleSelectChange = async (data: any) => {
    try {
      setLoading({
        status: true,
        type: 'select',
        id: data?.value,
      });
      if (!!data?.value) {
        const result = await getLatLongFromPlaceId(data?.value);
        setSelectedCoordinates(result);
        setShowOptions(false);
        onSelect({
          ...result,
          address: data?.label,
        });
        setAddress(data?.label);
      }
    } catch (error) {
    } finally {
      setLoading({
        status: false,
        type: undefined,
        id: undefined,
      });
    }
  };

  const handleShowOptions = () => {
    setShowOptions(true);
  };

  return (
    <>
      {!!label && (
        <Typography variant="p" style={{ margin: '10px 0px' }}>
          {label}
        </Typography>
      )}
      <AddressSelectContainer
        onClick={handleShowOptions}
        ref={containerRef}
        {...restProps}
      >
        <SelectInput
          placeholder={address || placeholder || 'Search address'}
          onChange={onSearchTextChange}
          value={address}
          name="address"
          className={inputClassName}
        />
        {loading?.status && loading?.type === 'search' && (
          <div className="search-spinner">
            <FontAwesomeIcon icon="circle-notch" spin color="green" />
          </div>
        )}

        {showOptions && (
          <SelectOptionsContainer>
            {places &&
              Array.isArray(places) &&
              places.map((place: any) => (
                <div
                  key={place.value}
                  className={`option ${
                    place.label === 'Search' ? 'disabled' : ''
                  }`}
                  onClick={() => handleSelectChange(place)}
                >
                  {place.label}
                  {loading?.status &&
                    loading?.type === 'select' &&
                    loading?.id === place?.value && (
                      <div className="select-spinner">
                        <FontAwesomeIcon
                          icon="circle-notch"
                          spin
                          color="green"
                        />
                      </div>
                    )}
                </div>
              ))}
          </SelectOptionsContainer>
        )}
        {showMap && (
          <div className="map-container">
            <Map coordinates={[selectedCoordinates]} readOnly />
          </div>
        )}
      </AddressSelectContainer>
    </>
  );
};

export default AddressSearch;

const AddressSelectContainer = styled.div`
  position: relative;
  z-index: 1;

  & .map-container {
    margin-top: 20px;
  }

  & .search-spinner {
    position: absolute;
    right: 8px;
    top: 10px;
  }
`;

const SelectInput = styled.input`
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 6px 20px 6px 12px;
  font-size: 1rem;
  padding-right: 30px;
  &:focus {
    outline-color: #e0e0e0;
  }
`;

const SelectOptionsContainer = styled.div`
  width: 100%;
  border: 1px solid #e0e0e0;
  background: #fff;

  position: absolute;
  left: 0px;
  right: 0px;
  top: calc(40px + 0px);
  z-index: 9;
  padding: 12px 0px;

  & .option {
    position: relative;
    padding: 5px 20px;
    margin: 0px 0px 3px 0px;
    cursor: pointer;
    width: 100%;

    &:hover {
      background: #dbdbbd;
    }

    &.disabled {
      &:hover {
        background: none;
      }
      cursor: default;
    }

    & .select-spinner {
      position: absolute;
      right: 8px;
      top: 5px;
    }
  }
`;
