import LoadingPlaceholder from '@/components/LoadingPlaceholder';
import CustomTable from '@/newComponents/CustomTable';
import NoData from '@/newComponents/NoData';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const IssuesList = ({ loading, issues, columns }) => {
  const navigate = useNavigate();

  const rowAction = row => {
    navigate(`/issue/${row.reportId}`);
  };

  return (
    <div>
      {!!loading && <LoadingPlaceholder />}
      {issues?.length ? (
        <CustomTable columns={columns} data={issues} rowAction={rowAction} />
      ) : (
        <NoData />
      )}
    </div>
  );
};

export default IssuesList;
