import Modal from '@/newComponents/Modal';
import Error from '@shared/web/components/Error';
import { Form, Formik } from 'formik';
import useAPI from '@/hooks/api';
import { pick } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import validation from './validation';
import { Apis } from '@shared/front';
import { ButtonsContainer, StyledButton, Title } from './index.styles';
import Checkbox from '@/newComponents/Checkbox';
import { devices } from '@/utils/theme';
import Button from '@/newComponents/Button';
import Input from '@/newComponents/Input';
import { toast } from 'react-toastify';

const AmenitiesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 12px;

  @media ${devices.laptop} {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }
`;

const Amenities = ({ initialValues, onSubmitStep, step }) => {
  const formikref = useRef();
  const [handleSubmitRequest, { loading, error }] = useAPI();
  const [getFeatures, { data: features }] = useAPI();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalInput, setModalInput] = useState('');
  const [featuresList, setFeaturesList] = useState([]);
  const [modalError, setModalError] = useState('');
  const [targetButton, setTargetButton] = useState<string>('');

  const handleSubmitForm = async (values, isDraft?: boolean) => {
    const url = '/v1/properties';
    const parsedValues = pick(values, ['amenities']);

    try {
      const data = await handleSubmitRequest({
        method: 'post',
        url: initialValues.id ? `${url}/${initialValues.id}/amenities` : url,
        data: {
          amenities: [
            ...parsedValues?.amenities.filter((x: string) =>
              isContainedInFeatures(x),
            ),
            ...featuresList,
          ],
        },
      });

      const updateAmenities = {
        propertyAmenities: data,
      };

      onSubmitStep({ data: updateAmenities, isDraft });
    } catch (error) {}
  };

  const fetchFeature = async () => {
    const response = await getFeatures(Apis.Properties.getAmenities());
    const reponseAmenities = response.map(x => x.title);
    const propertyAmenities = initialValues?.propertyAmenities.map(
      x => x.amenities,
    );

    if (propertyAmenities.length > 0) {
      const featureValue = propertyAmenities.reduce((acc, val) => {
        if (!reponseAmenities.includes(val)) {
          acc.push(val);
          return acc;
        }
        return acc;
      }, []);
      setFeaturesList(featureValue);
    }
  };

  useEffect(() => {
    if (!!initialValues?.propertyAmenities) {
      formikref?.current?.setFieldValue('amenities', [
        ...initialValues?.propertyAmenities.map(items => items.amenities),
      ]);
    }
    fetchFeature();
  }, [step]);

  const handleAddNewAmenity = e => {
    e.preventDefault();
    setIsModalVisible(true);
  };

  const isContainedInFeatures = (text: string) => {
    const reponseAmenities = features.map(x => x.title);
    return reponseAmenities.includes(text);
  };

  const addNewAmenityToFeatures = e => {
    e.preventDefault();
    if (modalInput) {
      const reponseAmenities = features.map(x => x?.title.toLowerCase());
      const featureListAmenities = featuresList.map(x => x?.toLowerCase());
      if (
        reponseAmenities?.includes(modalInput.toLowerCase()) ||
        featureListAmenities?.includes(modalInput.toLowerCase())
      ) {
        setModalError('Amenity already exists.');
      } else {
        setModalError('');
        formikref?.current?.setFieldValue('amenities', [
          ...(formikref?.current?.values?.amenities || []),
          modalInput,
        ]);
        setFeaturesList([...featuresList, modalInput]);
        setModalInput('');
        setIsModalVisible(false);
      }
    }
  };

  const removeNewAmenity = (amenity: string) => {
    setFeaturesList(prev => {
      return prev.filter(x => x !== amenity);
    });
    formikref?.current?.setFieldValue(
      'amenities',
      formikref?.current?.values?.amenities.filter(i => i !== amenity),
    );
  };
  const handleModalCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <Formik
      innerRef={formikref}
      // initialValues={initialValues}
      initialValues={{ amenities: initialValues?.amenities || [] }}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmitForm(values);
        setSubmitting(false);
      }}
      validationSchema={validation.amenities}
      enableReinitialize
    >
      {({
        isSubmitting,
        errors,
        touched,
        values,
        setFieldValue,
        handleSubmit,
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Title variant="h1">Amenities</Title>
            <AmenitiesGrid>
              {!!features?.length &&
                features?.map(({ title, id }) => {
                  return (
                    <Checkbox
                      id={id}
                      label={title}
                      isChecked={values?.amenities?.includes(title)}
                      onChange={e => {
                        if (e.target.checked) {
                          setFieldValue('amenities', [
                            ...(values?.amenities || []),
                            title,
                          ]);
                        } else {
                          setFieldValue(
                            'amenities',
                            values?.amenities.filter(i => i !== title),
                          );
                        }
                      }}
                    />
                  );
                })}
              {featuresList?.map(newAmenity => {
                return (
                  <Checkbox
                    id={newAmenity}
                    key={newAmenity}
                    label={newAmenity}
                    isChecked={true}
                    onChange={e => {
                      removeNewAmenity(newAmenity);
                    }}
                  />
                );
              })}
              <Button
                size="md"
                variant="primary"
                onClick={handleAddNewAmenity}
                style={{ height: '40px', width: '40px', fontSize: '30px' }}
              >
                +
              </Button>
            </AmenitiesGrid>
            <Modal
              title="Add New Amenity"
              open={isModalVisible}
              isCentered={true}
              onClose={handleModalCancel}
              style={{ width: '400px', paddingBottom: '18px' }}
            >
              <Input
                type="text"
                placeholder="Enter a new amenity"
                value={modalInput}
                onChange={e => setModalInput(e.target.value)}
              />
              {modalError && <div style={{ color: 'red' }}>{modalError}</div>}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: '10px',
                  marginTop: '10px',
                }}
              >
                <Button
                  onClick={handleModalCancel}
                  radius="round"
                  variant="gray"
                >
                  Cancel
                </Button>
                <Button onClick={addNewAmenityToFeatures} radius="round">
                  Add
                </Button>
              </div>
            </Modal>

            <Error message={error || errors?.amenities} isFormError />
            <ButtonsContainer>
              <StyledButton
                htmlType="button"
                variant="gray"
                radius="round"
                block={true}
                isDisabled={
                  targetButton === 'draft' ? loading || isSubmitting : false
                }
                isLoading={
                  targetButton === 'draft' ? loading || isSubmitting : false
                }
                onClick={() => {
                  handleSubmitForm(values, true);
                }}
              >
                Save as draft
              </StyledButton>
              <StyledButton
                htmlType="submit"
                radius="round"
                isDisabled={
                  targetButton === 'save' ? loading || isSubmitting : false
                }
                isLoading={
                  targetButton === 'save' ? loading || isSubmitting : false
                }
                onClick={() => {
                  setTargetButton('save');
                }}
              >
                Save
              </StyledButton>
            </ButtonsContainer>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Amenities;
