import SkeletonLoading from '@/newComponents/Skeleton';
import { devices } from '@/utils/theme';
import React from 'react';
import styled from 'styled-components';
import Divider from '@/newComponents/Divider';
import { useResponsive } from '@/hooks/useResponsive';
const LandingSkeleton = ({ ...rest }) => {
  const { isTablet } = useResponsive();
  return (
    <Wrapper>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(item => {
        return (
          <div>
            <div className="card" key={item}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: isTablet ? 'column' : 'column',
                  gap: isTablet ? '20px' : '10px',
                }}
              >
                <SkeletonLoading width={300} height={250} borderRadius={10} />
                <div>
                  <SkeletonLoading
                    height={30}
                    width={250}
                    style={{ marginTop: isTablet ? '12px' : '4px' }}
                  />
                  <SkeletonLoading
                    borderRadius={10}
                    height={30}
                    width={90}
                    style={{ marginTop: isTablet ? '20px' : '5px' }}
                  />
                </div>
              </div>

              {/* {isTablet && (
                <SkeletonLoading height={30} width={80} borderRadius={10} />
              )} */}
            </div>
            {/* {isTablet && <Divider style={{ marginTop: '20px' }} />} */}
          </div>
        );
      })}
    </Wrapper>
  );
};

export default LandingSkeleton;

const Wrapper = styled.div`
  display: grid;
  gap: 60px 20px;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  margin-bottom: 60px;
  @media ${devices.tablet} {
    /* flex-direction: column; */
    gap: 20px;
  }

  .card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
`;
