import InputField from '@/components/InputField';
import Button from '@/newComponents/Button';
import Modal from '@/newComponents/Modal';
import { Apis, Hooks } from '@shared/front';
import TextField from '@shared/web/components/TextField';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import { ButtonWrapperStyled } from '../ReferenceImage';
import { SectionTitle } from '../style';
import { useResponsive } from '@/hooks/useResponsive';
import styled from 'styled-components';
import { request } from '@shared/front/utils';

type Props = {
  enableAddRoomModal: boolean;
  handleCancelAddRoomModal: () => void;
  propertyId: number;
  bookingId: number | undefined;
  setSelectedRoom: (value: any) => void;
  setEnableAddImagesModal: Function;
  handleGetPropertyRooms: () => void;
  editRoom: { status: boolean; data: any };
};

const createRoomSchema = yup.object().shape({
  title: yup.string().required('Title is required.'),
  notes: yup.string().required('Description is required.'),
});

const CreateRoomModals = ({
  enableAddRoomModal,
  handleCancelAddRoomModal,
  propertyId,
  setSelectedRoom,
  setEnableAddImagesModal,
  handleGetPropertyRooms,
  bookingId,
  editRoom,
  setEditRoom,
}: Props) => {
  const { isTablet } = useResponsive();
  const [editLoading, setEditLoading] = useState(false);
  const [requestCreateGroup] = Hooks.useAPI();
  const [requestCreateReferenceRoom, { loading: createRoomLoading }] =
    Hooks.useAPI();

  const createRoomRefImageGroup = async (currentSelectRoom: any) => {
    const resp = await requestCreateGroup(
      Apis.Booking.createReferenceImageGroup({
        bookingId: bookingId || null,
        propertyId: propertyId,
        propertyReferenceRoomId: currentSelectRoom.id,
      }),
    );
  };

  const handleCreateRoom = async (values: any) => {
    const createdRoom = await requestCreateReferenceRoom(
      Apis.Properties.createPropertyReferenceRoom({
        title: values.title,
        notes: values.notes,
        propertyId: propertyId,
      }),
    );

    const result = await createRoomRefImageGroup(createdRoom);
    setSelectedRoom({
      room: createdRoom,
      refGroupId: result?.id,
    });
    setEnableAddImagesModal(true);
    await handleGetPropertyRooms();

    handleCancelAddRoomModal();
  };

  const handleEditRoomName = async (
    roomName: string,
    roomNotes: string,
    roomId: number,
  ) => {
    if (!roomName.trim()) {
      return;
    }
    setEditLoading(true);
    const updatedRoom = await request(
      Apis.Properties.updatePropertyReferenceRoom(
        { title: roomName, notes: roomNotes },
        roomId,
      ),
    );

    setEditLoading(false);

    setSelectedRoom(prev => ({
      ...prev,
      room: updatedRoom,
    }));

    setEnableAddImagesModal(true);
    await handleGetPropertyRooms();

    handleCancelAddRoomModal();
  };

  return (
    <Modal
      open={enableAddRoomModal}
      onClose={handleCancelAddRoomModal}
      size={'lg'}
      isCentered
      style={{ width: !isTablet ? '95vw' : '80vw' }}
    >
      <MainContainerStyled>
        <SectionTitle>
          {editRoom?.status ? 'Edit Room' : 'Create Room'}
        </SectionTitle>
        <Formik
          onSubmit={async (values, { resetForm }) => {
            console.log(values, 'form data');
            if (editRoom?.status) {
              await handleEditRoomName(
                values?.title,
                values?.notes,
                editRoom?.data?.id,
              );
            } else {
              await handleCreateRoom(values);
            }
            resetForm();
          }}
          initialValues={{
            title: (editRoom?.status && editRoom?.data?.title) || '',
            notes: (editRoom?.status && editRoom?.data?.notes) || '',
          }}
          validationSchema={createRoomSchema}
          enableReinitialize
        >
          {({ values, handleSubmit, resetForm }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Field
                  component={InputField}
                  label="Title"
                  name="title"
                  type="text"
                  value={values?.title}
                />
                <Field
                  component={TextField}
                  label="Notes"
                  name="notes"
                  type="textarea"
                  value={values?.notes}
                  className="textarea-notes"
                  maxLength="250"
                />
                <div style={{ textAlign: 'right' }}>
                  {values.notes.length}/250
                </div>

                <ButtonWrapperStyled style={{ marginTop: '30px' }}>
                  <Button
                    onClick={() => {
                      handleCancelAddRoomModal();
                      resetForm();
                      if (editRoom?.status) {
                        setEnableAddImagesModal(true);
                      }
                    }}
                    isDisabled={createRoomLoading || editLoading}
                  >
                    Close
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    isDisabled={createRoomLoading || editLoading}
                    isLoading={createRoomLoading || editLoading}
                  >
                    {editRoom?.status ? 'Edit Room' : 'Create Room'}
                  </Button>
                </ButtonWrapperStyled>
              </Form>
            );
          }}
        </Formik>
      </MainContainerStyled>
    </Modal>
  );
};

export default CreateRoomModals;

const MainContainerStyled = styled.div`
  max-height: calc(100vh - 120px);
  overflow-y: auto;
  min-height: 50vh;
`;
