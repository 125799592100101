import LoadingPlaceholder from '@shared/web/components/LoadingPlaceholder';
import useAPI from '@/hooks/api';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import BasicInfo from './BasicInfo';
import Location from './Location';
import Photos from './Photos';
import { toast } from 'react-toastify';
import request from '@shared/front/utils/request';
import Amenities from './Amenities';
import UtilityProvider from './Utility';
import Stepper from '@/newComponents/Stepper';

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 600px;
  padding-top: 60px;
`;

const AddProperty = () => {
  const navigate = useNavigate();
  const { id: propertyId } = useParams();
  const [getPropertyRequest, { data: property, error: propertyError }] =
    useAPI();
  const [step, setStep] = useState(0);
  const [validatedTab, setValidatedTab] = useState(0);

  const [initialValues, setInitialValues] = useState<any>({
    features: propertyId
      ? [
          ...((!!property?.property?.propertyFeatures?.length &&
            property?.property?.propertyFeatures?.map(
              item => item?.features,
            )) ||
            []),
        ]
      : [],
    data: propertyId
      ? [
          ...(property?.property?.utilityProviders.map(item => {
            return {
              utilityProvider: item?.provider,
              utilityProviderId: item?.provider?.id,
              utilityCategoryId: item?.categories?.id,
              category: item?.category,
              fields: JSON.parse(item?.fields),
            };
          }) || []),
        ]
      : [],
    images: propertyId
      ? [
          ...(property?.property?.images?.map(image => ({
            ...image,
            key: image?.URL,
          })) || []),
          null,
        ]
      : [null],
    address: propertyId ? property?.property?.address : '',
    id: propertyId ? property?.property?.id : '',
    bedrooms: propertyId
      ? property?.property?.bedrooms !== null
        ? property?.property?.bedrooms
        : ''
      : '',
    bathrooms: propertyId
      ? property?.property?.bathrooms !== null
        ? property?.property?.bedrooms
        : ''
      : '',
    area: propertyId
      ? property?.property?.area !== null
        ? property?.property?.area
        : ''
      : '',
    berNo: propertyId
      ? property?.property?.berNo !== null
        ? property?.property?.berNo
        : ''
      : '',
    price: propertyId
      ? property?.property?.price !== null
        ? property?.property?.price
        : ''
      : '',
    desc: propertyId
      ? property?.property?.desc !== null
        ? property?.property?.desc
        : ''
      : '',
    latitude: propertyId ? property?.property?.latitude : '',
    longitude: propertyId ? property?.property?.longitude : '',
    furnishedType: propertyId ? property?.property?.furnishedType : '',
    propertyType: propertyId ? property?.property?.propertyType : '',
    rentalMarket: propertyId ? property?.property?.rentalMarket : '',
  });

  const getProperty = async () => {
    const property = await getPropertyRequest({
      method: 'get',
      url: `/v1/properties/${propertyId}`,
    });
    setInitialValues({
      ...property.property,
      images: [...property.property.images, null],
      data: [
        ...property?.property?.utilityProviders.map(item => {
          return {
            utilityProvider: item?.provider,
            utilityProviderId: item?.provider?.id,
            utilityCategoryId: item?.categories?.id,
            category: item?.category,
            fields: JSON.parse(item?.fields),
          };
        }),
      ],
    });
    setValidatedTab(property?.property?.completedStep);
  };

  useEffect(() => {
    if (propertyId !== 'add' && +propertyId) {
      getProperty();
    }
  }, []);
  const onSubmitStep = async ({
    data,
    isDraft,
    skipStep,
  }: OnSubmitStepParams) => {
    if (isDraft) {
      navigate('/properties');
      toast('Your property is saved as draft you can continue on it later');
    } else {
      const url = '/v1/properties';

      if (skipStep) {
        // Update step without submitting data
        setStep(step + 1);
        setValidatedTab(prev => Math.max(step + 1, prev));
      } else if (step < steps.length - 1) {
        setInitialValues(prev => ({
          ...prev,
          ...data,
          id: prev.id || data.id,
        }));
        setStep(step + 1);
        setValidatedTab(prev => Math.max(step + 1, prev));

        await request({
          method: 'put',
          url: `${url}/${initialValues?.id || data?.id}`,
          data: { completedStep: step + 1 },
        });
      } else {
        try {
          await request({
            method: 'put',
            url: `${url}/${initialValues?.id || data?.id}`,
            data: { status: 'active' },
          });
          navigate('/properties');
          toast('Your property creation has been completed');
        } catch (err) {}
      }
    }
  };

  const childProps = {
    initialValues,
    setInitialValues,
    setStep,
    setValidatedTab,
    step,
    onSubmitStep,
  };
  const steps = [0, 1, 2, 3, 4];
  const items = [
    {
      label: 'Location',
      key: 0,
      children: <Location {...childProps} />,
    },
    {
      label: 'Basic Information',
      key: 1,
      children: <BasicInfo {...childProps} />,
    },
    {
      label: 'Amenities',
      key: 2,
      children: <Amenities {...childProps} />,
    },
    {
      label: 'Utility Provider',
      key: 3,
      children: <UtilityProvider {...childProps} />,
    },
    {
      label: 'Photos',
      key: 4,
      children: <Photos {...childProps} />,
    },
  ];
  // @ts-ignore
  if (propertyId && !initialValues?.id) {
    return <LoadingPlaceholder />;
  }
  return (
    <Container>
      <Stepper
        activeStep={step}
        stepItems={items}
        enableTabClick={!!propertyId}
        onTabClick={data => {
          setStep(data?.key);
        }}
      />
    </Container>
  );
};

export default AddProperty;

interface PropertyData {
  id: number;
}
interface OnSubmitStepParams {
  data: PropertyData;
  isDraft: boolean;
  skipStep?: boolean; // Optional parameter to indicate whether to skip the step
}
