import React, { useState, useEffect } from 'react';
import { Theme, Utils, Apis, Socket } from '@shared/front';
import { RootType, useRoot } from '@/RootProvider';
import { DEV_USERS } from './users';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Button from '@/newComponents/Button';
import { AuthRoleType } from '@shared/types';

const DeveloperSetting = () => {
  const { auth }: RootType = useRoot();

  const [baseUrl, setBaseUrl] = useState<string>(Utils?.rootData?.apiURL || '');

  const [socketBaseUrl, setSocketBaseUrl] = useState<string>(
    Utils?.rootData?.apiURL?.startsWith('http://')
      ? `ws:${Utils?.rootData?.apiURL?.replace(/http:|\/api$/g, '')}`
      : `wss:${Utils?.rootData?.apiURL?.replace(/https:|\/api$/g, '')}`,
  );
  const [isUsersVisible, setIsUserVisible] = useState(false);

  const [activeUser, setActiveUser] = useState<
    typeof DEV_USERS[0] & { role?: AuthRoleType }
  >();

  const [isLoginLoading, setIsLoginLoading] = useState(false);

  const { setAuth } = useRoot();

  useEffect(() => {
    if (auth?.email) {
      const _activeUser = DEV_USERS.find(item =>
        [item.landlord, item.service, item.tenant].includes(auth.email || ''),
      );

      if (_activeUser?.name) {
        setActiveUser({ ..._activeUser, role: auth?.role });
        setIsUserVisible(false);
      }
    } else {
      setIsUserVisible(true);
    }
    const base = localStorage.getItem('API');
    if (base) {
      console.log(base, 'base');
      Utils.rootData.apiURL = base;
      setBaseUrl(base);
      Socket.connectSocket();
    }
  }, [auth]);
  const navigate = useNavigate();
  const handleLogin = async (e: any) => {
    e.preventDefault();
    if (!activeUser?.role) {
      return;
    }
    setIsLoginLoading(true);
    try {
      localStorage.removeItem('user');
      const user: any = await Utils.request(
        Apis.Auth.login({
          email: activeUser[activeUser.role],
          password: '12345',
        }),
      );
      axios.defaults.headers.Authorization = `Bearer ${user.token}`;
      setAuth(user);
      localStorage.setItem('user', JSON.stringify(user));
      Socket.connectSocket();
      navigate('/dashboard');
    } catch (err) {
      console.log('err', err);
    }
    setIsLoginLoading(false);
  };

  const handleApiConfigSubmit = async (value: string) => {
    localStorage.removeItem('API');
    localStorage.setItem('API', value);
    Utils.rootData.apiURL = String(value);
    setSocketBaseUrl(
      value.startsWith('http://')
        ? `ws:${value.replace(/http:|\/api$/g, '')}`
        : `wss:${value.replace(/https:|\/api$/g, '')}`,
    );
    setBaseUrl(value);
    Socket.connectSocket();
  };
  const API_LIST = [
    {
      title: 'AWS',
      url: 'https://api.rentisity.com/api',
      onClick: () => {
        handleApiConfigSubmit('https://api.rentisity.com/api');
      },
      color: 'blue',
    },
    {
      title: 'Office-Server',
      url: 'https://api-rentisity.intosoft.com/api',
      onClick: () => {
        handleApiConfigSubmit('https://api-rentisity.intosoft.com/api');
      },
      color: 'blue',
    },
  ];

  const USER_ROLES = [
    { title: 'Landlord', key: 'landlord' },
    { title: 'Tenant', key: 'tenant' },
    { title: 'Service Provider', key: 'service' },
  ];

  return (
    <div style={{ width: '66%', padding: '0 20px', paddingTop: '40px' }}>
      <section>
        <Title>API URL</Title>
        <div>
          <Input type="url" value={baseUrl} readOnly />
          <Title>Socket URL</Title>
          <Input
            type="url"
            value={socketBaseUrl}
            readOnly
            style={{ marginTop: 10 }}
          />
          <ButtonWrapper>
            {API_LIST.map(item => {
              return (
                <ButtonStyle
                  type="button"
                  key={item.title}
                  color={
                    item.url === baseUrl
                      ? Theme.materialColors.blue.text
                      : 'white'
                  }
                  onClick={item.onClick}
                >
                  <TagText
                    color={
                      item.url === baseUrl
                        ? 'white'
                        : Theme.materialColors.blue.text
                    }
                  >
                    {item.title}
                  </TagText>
                </ButtonStyle>
              );
            })}
          </ButtonWrapper>
        </div>
      </section>

      <section>
        <Title>Current User</Title>
        <div>
          {!auth ? (
            '  No user selected; select user and role from below for fast login'
          ) : (
            <p
              style={{
                width: '100%',
                padding: '0 10px',
              }}
            >
              {auth.fullName}
            </p>
          )}
          {auth?.role && (
            <TagStyle onClick={() => setIsUserVisible(!isUsersVisible)}>
              <TagText color={Theme.materialColors.blue.text}>
                Change User
              </TagText>
            </TagStyle>
          )}
        </div>
      </section>

      {isUsersVisible && (
        <UserBox>
          {DEV_USERS.map(user => (
            <UserCardName
              active={user.name === activeUser?.name}
              onClick={() => setActiveUser(user)}
            >
              {user.name}
            </UserCardName>
          ))}
        </UserBox>
      )}

      <section>
        <Title>Roles</Title>
        {!!activeUser?.name && (
          <ButtonWrapper>
            {USER_ROLES.map(item => (
              <TagStyle
                color={
                  activeUser?.role === item.key
                    ? Theme.materialColors.blue.text
                    : 'white'
                }
                onClick={() =>
                  setActiveUser(prev => ({ ...prev, role: item?.key }))
                }
              >
                <TagText
                  color={
                    activeUser.role === item.key
                      ? 'white'
                      : Theme.materialColors.blue.text
                  }
                >
                  {item.title}
                </TagText>
              </TagStyle>
            ))}
          </ButtonWrapper>
        )}
      </section>

      {activeUser?.role &&
        (isUsersVisible || auth?.role !== activeUser?.role) && (
          <Button
            variant="primary"
            htmlType="button"
            onClick={handleLogin}
            isLoading={isLoginLoading}
            style={{
              marginLeft: '10px',
            }}
          >
            Login
          </Button>
        )}
    </div>
  );
};

export default DeveloperSetting;

const TagStyle = styled.button`
  background-color: ${({ color }) => color || 'white'};
  border: 1px solid ${({ theme }) => theme?.colors?.materialColors?.blue.text};
  height: 28px;
  width: auto;
  padding: 2px 14px;
  border-radius: 2px;
  margin: 8px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ButtonStyle = styled.button`
  background-color: ${({ color }) => color || 'white'};
  border: 1px solid ${props => props.theme.colors.materialColors?.blue.text};
  height: 28px;
  width: auto;
  padding: 2px 14px;
  border-radius: 2px;
  margin: 8px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const TagText = styled.span`
  color: ${({ color }) => color || 'black'};
  font-weight: 700;
`;
export const ApiConfigWrapper = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
`;
export const Title = styled.p`
  font-size: 18px;
  color: black;
  margin-bottom: 5px;
  padding-top: 20px;
`;
export const Input = styled.input`
  border: 1px solid grey;
  width: 70%;
  margin-left: 10px;
  border-radius: 4px;
  padding-left: 10px;
  height: 40px;
`;
export const ButtonWrapper = styled.div`
  margin-bottom: 15px;
  flex-direction: row;
`;

const UserCardName = styled.div<{ active?: boolean }>`
  height: 40px;
  border: 1px solid
    ${({ theme, active }) =>
      active ? theme.colors.primary : theme.colors.border};
  background-color: ${({ active, theme }) =>
    active ? `${theme.colors.primary}` : 'white'};
  color: ${({ active }) => (active ? 'white' : 'black')};
  margin: 2px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    border: 1px solid
      ${({ theme, active }) =>
        active ? theme.colors.primaryMedium : theme.colors.primaryMedium};
  }
`;
const UserBox = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: initial;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 16px;
`;

const Container = styled.div`
  margin-left: 50px;
`;
