import React from 'react';
import Typography from '../Typography';
import styled from 'styled-components';
import { devices } from '@/utils/theme';
import Divider from '../Divider';

const ListingHeader = ({ title, children }: any) => {
  return (
    <Wrapper>
      <HeaderWrapper>
        <Typography variant="h3" size={'md'}>
          {title}
        </Typography>
        {children}
      </HeaderWrapper>
      <Divider style={{ marginTop: '18px' }}></Divider>
    </Wrapper>
  );
};

export default ListingHeader;
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  @media ${devices.tablet} {
    padding: 12px;
  }
`;
const Wrapper = styled.div`
  position: sticky;
  top: 60px;
  background-color: white;
  z-index: 999;
`;
