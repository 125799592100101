import React, { useEffect, useRef, useState } from 'react';
import {
  GroupItem,
  GroupList,
  GroupItemWrapper,
  UserImage,
  GroupLeftItem,
  GroupRightItem,
  MarketCircle,
  UserInitialText,
} from './style';
// import DefaultUser from '../../assets/defaultuser.png';
import Typography from '@/newComponents/Typography';
import { Utils } from '@shared/front';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRoot } from '@/RootProvider';
import moment from 'moment';
import ConversationList from './ConversationList';
interface GroupConversationListProps {
  conversations: any;
  currentConversation: any;
  handleSelectConversation: (conversation: any) => void;
}
const GroupConversationList = ({
  conversations,
  currentConversation,
  handleSelectConversation,
}: GroupConversationListProps) => {
  const { auth: user } = useRoot();
  const [activeIndex, setActiveIndex] = useState(0);
  const [autoScrollConversationIndex, setAutoScrollConversationIndex] =
    useState(0);

  const myRef = useRef<HTMLDivElement>(null);
  const executeScroll = () => {
    if (myRef?.current) {
      myRef?.current?.scrollIntoView();
    }
  };

  useEffect(() => {
    if (!!autoScrollConversationIndex) {
      executeScroll();
    }
  }, [autoScrollConversationIndex, myRef.current]);

  useEffect(() => {
    if (currentConversation) {
      const index = Object.keys(conversations).findIndex(x => {
        const key = currentConversation.propertyId
          ? `${currentConversation.propertyId}-property`
          : currentConversation.serviceId
          ? `${currentConversation.serviceId}-service`
          : 'market';
        if (key === x) {
          return true;
        }
        return false;
      });
      setActiveIndex(index);
    }
  }, [currentConversation]);

  return (
    <GroupList>
      {!!Object?.keys(conversations)?.length &&
        Object.entries(conversations)?.map(
          ([key, conversation]: any, index) => {
            const latestChat = conversation[0];
            const checkJsonOrNot = Utils.isJsonString(
              latestChat?.chats[0]?.message,
            );
            const message = !!checkJsonOrNot?.length
              ? checkJsonOrNot
              : latestChat?.chats[0]?.message;
            const hasSeen =
              latestChat?.chats[0]?.chatBy !== user?.role
                ? latestChat?.chats[0]?.isRead
                : true;

            return (
              <GroupItem
                key={key}
                $isActive={activeIndex === index}
                ref={activeIndex === index ? myRef : null}
              >
                <GroupItemWrapper
                  onClick={() => {
                    setActiveIndex(index);
                    setAutoScrollConversationIndex(index + 1);
                    handleSelectConversation(latestChat);
                  }}
                  $isActive={activeIndex === index}
                >
                  <GroupLeftItem>
                    {key === 'market' ? (
                      <>
                        <MarketCircle>
                          <FontAwesomeIcon
                            icon="shopping-cart"
                            style={{ color: 'white' }}
                          />
                        </MarketCircle>
                      </>
                    ) : (
                      <>
                        {/* <UserImage src={DefaultUser} /> */}
                        <UserInitialText
                          $isActive={activeIndex === index}
                          $color={
                            !!latestChat?.propertyAddress
                              ? '#1677FF'
                              : '#12BCC9'
                          }
                        >
                          {key === 'market'
                            ? 'Market Place'
                            : latestChat?.propertyAddress?.charAt(0)
                            ? latestChat?.propertyAddress?.charAt(0)
                            : latestChat?.serviceTitle?.charAt(0)}
                        </UserInitialText>
                      </>
                    )}
                  </GroupLeftItem>
                  <GroupRightItem>
                    <Typography
                      variant="h4"
                      size="sm"
                      color={
                        activeIndex === index
                          ? '#fff'
                          : hasSeen
                          ? 'inherit'
                          : '#000'
                      }
                      fontWeight={hasSeen ? 'normal' : 'bold'}
                    >
                      {key === 'market'
                        ? 'Market Place'
                        : latestChat?.propertyAddress
                        ? latestChat?.propertyAddress
                        : latestChat?.serviceTitle}
                    </Typography>
                    {key === 'market' ? (
                      <>
                        <Typography
                          variant="p"
                          style={{
                            fontSize: 12,
                            marginTop: 3,
                          }}
                          color={activeIndex === index ? '#fff' : '#949494'}
                        >
                          {`Last Message : ${
                            typeof message === 'string'
                              ? message
                              : !!message?.length
                              ? 'Sent photo'
                              : 'Appointment Request'
                          }`}
                        </Typography>
                        {latestChat?.chats[0]?.createdAt && (
                          <Typography
                            variant="p"
                            style={{ fontSize: 12, marginTop: 3 }}
                            color={activeIndex === index ? '#fff' : '#949494'}
                          >
                            {moment(latestChat?.chats[0]?.createdAt)
                              .utc()
                              .fromNow(true)}
                          </Typography>
                        )}
                      </>
                    ) : (
                      <>
                        <Typography
                          variant="p"
                          style={{
                            fontSize: 12,
                            marginTop: 3,
                            fontStyle: !message ? 'oblique' : 'normal',
                            overflow: 'hidden',
                            width: '200px',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            height: '14px',
                          }}
                          color={activeIndex === index ? '#fff' : '#949494'}
                        >
                          {`${
                            latestChat?.chats[0]?.chatBy === user?.role
                              ? 'You: '
                              : `${latestChat?.fullName}: `
                          }${
                            !!message
                              ? typeof message === 'string'
                                ? message
                                : message?.length
                                ? 'Sent photo'
                                : 'Appointment Request'
                              : 'No Messages'
                          }`}
                        </Typography>
                        {latestChat?.chats[0]?.createdAt && (
                          <Typography
                            variant="p"
                            style={{ fontSize: 12, marginTop: 3 }}
                            color={activeIndex === index ? '#fff' : '#949494'}
                          >
                            {moment(latestChat?.chats[0]?.createdAt)
                              .utc()
                              .fromNow(true)}
                          </Typography>
                        )}
                      </>
                    )}
                  </GroupRightItem>
                </GroupItemWrapper>
                {activeIndex === index && (
                  <ConversationList
                    conversations={conversation}
                    handleSelectConversation={handleSelectConversation}
                    currentConversation={currentConversation}
                    child={true}
                  />
                )}
              </GroupItem>
            );
          },
        )}
    </GroupList>
  );
};

export default GroupConversationList;
